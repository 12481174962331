<div class="mat-elevation-z8">
  <div class="header" fxLayoutAlign="flex-end">
    <button mat-stroked-button fxLayout="row" [routerLink]="['../create']">
      <mat-icon>add</mat-icon>
      <span>{{ 'ADD' | translate }}</span>
    </button>
  </div>
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ID' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SPECIFICATION.NAME' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="positionTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'POSITION' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.positionTitle}}</td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.company?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ADDRESS' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.address}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data.value.length"
      [pageIndex]="0"
      [pageSize]="50"
      [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
