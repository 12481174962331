import {Component, EventEmitter, Input, Output} from '@angular/core';
import {COMPANY_COLORS,} from '../../graphql/graphql';
import {NotificationService} from '../notification.service';
import {UpdateCompanyRelayGQL} from './color-update-graphql';
import {GetChangeColorPermissionGQL} from '../../../generated/graphql';

@Component({
  selector: 'app-company-color-switch',
  templateUrl: './company-color-switch.component.html',
  styleUrls: ['./company-color-switch.component.scss']
})
export class CompanyColorSwitchComponent  {
  disabledColorChange = true;
  @Input() companyId: string;
  @Input() color: COMPANY_COLORS;

  @Output() colorChanged = new EventEmitter<COMPANY_COLORS>();


  companyColors = COMPANY_COLORS;

  options = [
    COMPANY_COLORS.GREEN_A, COMPANY_COLORS.GREEN_B,
    COMPANY_COLORS.GREEN_C, COMPANY_COLORS.YELLOW, COMPANY_COLORS.RED, COMPANY_COLORS.BLUE]


  constructor(
    private updateCompanyGQL: UpdateCompanyRelayGQL,
    private notify: NotificationService,
    private checkPermissionGQL: GetChangeColorPermissionGQL
  ) {
    this.checkPermissions();
  }

  updateColor(colorValue: COMPANY_COLORS) {
    this.updateCompanyGQL.mutate(
      {id: this.companyId, color: colorValue},
      {refetchQueries: ['getPaginatedOrder']}
      ).subscribe(res => {
      if (res.data.updateCompanyRelay.ok) {
        this.notify.notify('UPDATED_SUCCESFULLY', 0);
        this.colorChanged.emit(colorValue);
      } else {
        this.notify.notify('CANNOT_UPDATE_COLOR_RELOAD', 1);
      }

    }, err => {
      this.notify.notify('Klaida bandant išsaugoti įmonės naują spalvą', 2);
    });
  }

  private checkPermissions() {
    this.checkPermissionGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(
      value => {this.disabledColorChange = !value.data.hasPermission; }
    );
  }
}
