import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { take } from 'rxjs/operators';
import {
  CandidateRequirementsEvaluationRecordType,
  GetCandidatesEvaluationsGQL,
  OrderLanguage
} from '../../../generated/graphql';
import { CopyToClipboardService } from '../../shared/copy-to-clipboard.service';

@Component({
  selector: 'app-candidate-evaluations',
  templateUrl: './candidate-evaluations.component.html',
  styleUrls: ['./candidate-evaluations.component.scss']
})
export class CandidateEvaluationsComponent implements OnInit {
  @Input() orderId: string; //OrderMType Id
  requirements: any[] = [];
  candidates: any[] = [];
  displayedColumns: string[] = [];
  private language: OrderLanguage;

  constructor(
    private translate: TranslateService,
    private getCandidatesEvaluationsGQL: GetCandidatesEvaluationsGQL,
    private copyService: CopyToClipboardService
  ) {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.getCandidatesEvaluationsGQL.fetch({ orderId: this.orderId })
      .subscribe(result => {
        this.language = result.data.orderModel.language;
        this.processData(result.data.candidateEvaluations.edges);
        this.processRequirements(result.data.orderModel.specification?.mandatoryRequirements);
        this.updateDisplayedColumns();
      });
  }

  processData(edges: any[]): void {
    const candidatesMap = new Map();

    edges.forEach(edge => {
      const node = edge.node;
      const candidateId = node.offerProposition.candidate.id;

      if (!candidatesMap.has(candidateId)) {
        candidatesMap.set(candidateId, {
          fullName: `${node.offerProposition.candidate.firstName} ${node.offerProposition.candidate.lastName}`,
          workExperience: '',
          additionalInfo: '',
          startsWorking: '',
          comments: {}
        });
      }

      const candidate = candidatesMap.get(candidateId);

      if (node.recordType === CandidateRequirementsEvaluationRecordType.WorkExperience) {
        candidate.workExperience = node.description;
      } else if (node.recordType === CandidateRequirementsEvaluationRecordType.OtherInfo) {
        candidate.additionalInfo = node.description;
      } else if (node.recordType === CandidateRequirementsEvaluationRecordType.Requirement) {
        candidate.comments[node.requirement.id] = node.description;

        // if (!this.requirements.some(req => req.id === node.requirement.id)) {
        //   this.requirements.push({
        //     id: node.requirement.id,
        //     requirement: node.requirement.requirement
        //   });
        // }
      } else if (node.recordType === CandidateRequirementsEvaluationRecordType.StartsWorking) {
        candidate.startsWorking = node.description;
      }
    });

    this.candidates = Array.from(candidatesMap.values());
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = [
      'fullName',
      'workExperience',
      ...this.requirements.map((_, index) => 'requirement' + index),
      'additionalInfo',
      'startsWorking',
      'actions'
    ];
  }

  private processRequirements(mandatoryRequirements: Array<{
    __typename?: 'RequirementType';
    id: string;
    requirement?: string | null
  }> | undefined) {
    this.requirements = mandatoryRequirements;
  }

  getLivasCandidateUrl(livasId: number): string {
    return `https://livas.biuro.lt/interviu.php?id=${livasId}`;
  }


  async copyToClipboard(candidate, requirements) {

    let fullNameHeader = this.translate.instant('FULL_NAME');
    let workExperienceHeader = this.translate.instant('WORK_EXPERIENCE');
    let additionalInfoHeader = this.translate.instant('ADDITIONAL_INFO_ABOUT_CANDIDATE');
    let startsWorkingHeader = this.translate.instant('STARTS_WORKING');
    let complianceHeaders = this.translate.instant('COMPLIANCE_WITH_REQUIREMENTS');
    if (this.language === OrderLanguage.LanguageEn) {
      const enTranslation = await this.translate.getTranslation('en').toPromise();
      workExperienceHeader = enTranslation['WORK_EXPERIENCE'];
      fullNameHeader = enTranslation['FULL_NAME'];
      additionalInfoHeader = enTranslation['ADDITIONAL_INFO_ABOUT_CANDIDATE'];
      startsWorkingHeader = enTranslation['STARTS_WORKING'];
      complianceHeaders = enTranslation['COMPLIANCE_WITH_REQUIREMENTS'];
    }
    console.log(this.language, fullNameHeader, workExperienceHeader, additionalInfoHeader, startsWorkingHeader);
    const header = `
      <tr>
        <td rowspan='2'><strong>'${fullNameHeader}'</strong></td>
        <td colspan='${this.requirements.length + 3}' style='text-align: center;'>${complianceHeaders}</td>
      </tr>
      <tr>
        <td>${workExperienceHeader}</td>
        ${this.requirements.map((requirement) => '<td>' + requirement.requirement + '</td>')}
        <td><strong>${additionalInfoHeader}</strong></td>
        <td><strong>${startsWorkingHeader}</strong></td>
      </tr>
    `;

    const columnStyle = 'style="max-width: 150px; width: 150px"';
    const content = `
      <tr>
        <td ${columnStyle}>${candidate.fullName}</td>
        <td ${columnStyle}>${candidate.workExperience || ''}</td>
        ${requirements.map((requirement) => `<td ${columnStyle}>` + (candidate.comments[requirement.id] || '') + '</td>')}
        <td ${columnStyle}>${candidate.additionalInfo || ''}</td>
        <td ${columnStyle}>${candidate.startsWorking || ''}</td>
    `;
    const htmlTable = `
      <table border='1' cellpadding='5' cellspacing='0' style='font-size: 12px;'>
        ${header}
        ${content}

      </table>`;
    this.copyService.copyToClipboard(htmlTable, true);

  }
}
