import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, of as observableOf, merge, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Region } from '../../graphql/graphql';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { GetPaginatedOrderForTableGQL, OrdersList2FieldsFragment } from '../../../generated/graphql';

export interface OrdersFilterI {
  company_Name_Icontains?: string;
  unseenProcessedOrders?: boolean;
  covidPolicy?: string[];
}
/**
 * Data source for the OrderList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class OrderListDataSource extends DataSource<OrdersList2FieldsFragment> {
  data = new BehaviorSubject<OrdersList2FieldsFragment[]>([]);
  filters = new BehaviorSubject<OrdersFilterI>({});
  orderIds = new BehaviorSubject<string[]>([]);
  paginator: MatPaginator;
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;

  regions = new BehaviorSubject<Region[]>([]);

  totalCount = 0;

  loadingCompleteSubject = new Subject<boolean>();
  isLoading = true;

  ordersSubscription: Subscription;
  ordersWatch;
  private dataSubject = new BehaviorSubject<OrdersList2FieldsFragment[]>([]);
  constructor(
    private ordersGQL: GetPaginatedOrderForTableGQL,
    private companyId
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OrdersList2FieldsFragment[]> {
  // Combine everything that affects the rendered data into one update
  // stream for the data-table to consume.
  const dataMutations = [
    this.filters.asObservable(),
    this.paginator.page,
    this.sort.sortChange
  ];

  const fetchData = () => {
    this.isLoading = true;

    const unseenProcessedOrders = this.filters.value.unseenProcessedOrders;
    const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
    const orderBy = this.getOrderByParam(this.sort);
    const queryParams = Object.assign(
      {},
      { first: this.paginator.pageSize, after: endCursor },
      this.filters.value, unseenProcessedOrders, orderBy
    );

    return this.ordersGQL.watch(queryParams, { fetchPolicy: 'cache-and-network' }).valueChanges.pipe(
      filter(r => r.data !== undefined),
      tap(r => {
        this.data.next(this.parseEdges(r.data.ordersPaginated));
        this.totalCount = r.data.ordersPaginated.totalCount;
        this.endCursor = r.data.ordersPaginated.pageInfo.endCursor;
        this.orderIds.next(this.data.value.map(it => it.id));
        this.loadingCompleteSubject.next(r.loading);
        this.isLoading = false;
      }),
      map(r => this.parseEdges(r.data.ordersPaginated))
    );
  };

  const data$ = merge(...dataMutations).pipe(
    debounceTime(300),
    switchMap(fetchData)
  );

  data$.subscribe(this.dataSubject);

  return this.dataSubject.asObservable();
}

  private parseEdges(input) {
    return input.edges.map(it => {
/*       const node = it.node;
      node.id = atob(node.id).split(':')[1]; */
      return JSON.parse(JSON.stringify(it.node));
    });
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.ordersSubscription?.unsubscribe?.();
  }

  private getOrderByParam(sort: MatSort) {
    let orderBy = {
        orderBy: 'priority,company__color,company__name'
      };

    if (this.sort.active) {
      if (['id', 'specification__position_title', 'company__name', 'shortage'].indexOf(this.sort.active) > -1) {
        orderBy = {
          orderBy: (this.sort.direction === 'desc' ? '-' : '') + this.sort.active
        };
      } else {
        orderBy = {
          orderBy: 'company__color' + (this.sort.active ? (this.sort.direction === 'desc' ? ',-' : ',') + this.sort.active : '')
        };
      }
    }

    return orderBy;
  }

  refetch() {
    this.filters.next(this.filters.getValue());
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
