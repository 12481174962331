import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import moment from 'moment/moment';

import { SidenavService } from '../shared/sidenav.service';
import { RequestActivitiesService } from './request-activities.service';
import { ToolbarService } from '../navigation/toolbar.service';
import { GetRequestActivitiesGQL, RequestsActivityType } from '../../generated/graphql';
import { RequestActivitiesDatasource } from './request-activities.datasource';
import { FilterInterface } from '../shared/filter-bar/filter-bar.component';

@Component({
  selector: 'app-request-activities',
  templateUrl: './request-activities.component.html',
  styleUrls: ['./request-activities.component.scss']
})
export class RequestActivitiesComponent implements OnInit, AfterViewInit {
  moment = moment;

  dataSource: RequestActivitiesDatasource;
  displayedColumns = ['created_at', 'created_by', 'contact', 'phone', 'company', 'reason', 'comment'];
  filters = ['projectManager', 'dateRange', 'phone'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<RequestsActivityType>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private sidenav: SidenavService,
    private toolbarService: ToolbarService,
    private requestActivitiesService: RequestActivitiesService,
    private getRequestActivitiesGQL: GetRequestActivitiesGQL
  ) {
    this.toolbarService.hide();
    this.sidenav.close();
  }

  ngOnInit(): void {
    this.dataSource = new RequestActivitiesDatasource(this.getRequestActivitiesGQL);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  onFilterChanges(event: FilterInterface) {
    const filters = { };
    if (event.phone) { filters['phone'] = event.phone; }
    if (event.projectManager) { filters['createdBy'] = event.projectManager; }
    if (event.dateRange?.dateFrom && event.dateRange?.dateTo) {
      filters['createdAt_Gte'] = moment(event.dateRange.dateFrom).format('YYYY-MM-DD');
      filters['createdAt_Lte'] = moment(event.dateRange.dateTo).format('YYYY-MM-DD');
    }

    this.dataSource.filters.next(filters);
  }

  getContactName(activity: RequestsActivityType) {
    return activity.contact
      ? `${ activity.contact.firstName || '' } ${ activity.contact.lastName || '' }`
      : `${ activity.clientContact?.firstName || '' } ${ activity.clientContact?.lastName || '' }`;
  }
}
