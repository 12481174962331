<div fxLayout="row wrap" [fxLayoutGap]="schedule? '' : '16px grid'">
  <div [fxFlex]="flexVal" class="widget-container">
    <app-schedule-widget [formControl]="form?.get('scheduleTypes')"></app-schedule-widget>
  </div>

  <div class="section " *ngIf="!readonly; else readOnlyTpl" [fxFlex]="flexVal">
    <mat-form-field class="free-text" appearance="outline">
      <mat-label>{{'SPECIFICATION.SCHEDULE_FREE' | translate }}</mat-label>
      <textarea matInput fxFlex="100" rows="6" (input)="onShiftDataChange()" [formControl]="form.get('scheduleText')">
        </textarea>
    </mat-form-field>
  </div>


  <ng-template #readOnlyTpl>
    <div [ngSwitch]="inputMode">
      <div *ngSwitchCase="InputModes.structure">
        <div fxLayout="column">
          <div class="shift-info-readonly">
            <span>
              {{('SPECIFICATION.' + scheduleType === 0 ? 'ONLY_ON_WEEKDAYS' : 'SHIFT_WORK') | translate}}.
              {{'SPECIFICATION.SHIFT_DURATION' | translate}} {{shiftDuration}}
              {{'SPECIFICATION.HRS' | translate}}.,
              {{'SPECIFICATION.BREAK_DURATION' | translate}} - {{breakDuration}}
              {{'SPECIFICATION.MIN' | translate}}.
            </span>
          </div>
          <div fxLayout="row" fxLayoutGap="12px">
            <div fxLayout="row wrap">
              <div *ngFor="let shift of shiftsStarts" fxLayout="row">
                <app-schedule-visual-element *ngFor="let n of arrayOne(shift?.count)" [shift]="shift"
                  [duration]="shiftDuration">
                </app-schedule-visual-element>
              </div>
            </div>
            <div>
              <div class="square work no-margin">{{'SPECIFICATION.WORKING' | translate}}</div>
              <div class="square free no-margin">{{'SPECIFICATION.NOT_WORKING' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="InputModes.freetext">
        {{form.get('scheduleText')?.value}}
      </div>
    </div>
  </ng-template>
</div>
