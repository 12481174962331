import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment/moment';

import { OrdersService } from '../orders.service';
import { OrderErrorCategoryType, OrderErrorType } from '../../../generated/graphql';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-order-data-error-reports',
  templateUrl: './order-data-error-reports.component.html',
  styleUrls: ['./order-data-error-reports.component.scss']
})
export class OrderDataErrorReportsComponent implements OnInit {
  errorCategories: OrderErrorCategoryType[] = [];
  errorReportForm: FormGroup;
  moment = moment;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, orderErrors: OrderErrorType[]},
    private dialogRef: MatDialogRef<OrderDataErrorReportsComponent>,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.errorReportForm = this.fb.group({
      categoryId: ['', Validators.required],
      comment: ['', Validators.required]
    });
    this.getErrorCategories();
  }

  getErrorCategories() {
    this.ordersService.getOrderErrorCategories().subscribe(res => {
      this.errorCategories = res.data.orderErrorCategories.edges.map(edge => edge.node as OrderErrorCategoryType);
    });
  }

  createReport() {
    this.ordersService.createOrderError(
      this.data.orderId,
      this.errorReportForm.get('categoryId').value,
      this.errorReportForm.get('comment').value
    ).subscribe(
      resp => {
        this.notificationService.notify(this.translateService.instant('CREATED_SUCCESSFULLY'));
        this.closeDialog(resp.data.createOrderError.orderError);
      },
      () => this.notificationService.notify(this.translateService.instant('COMMON.ERRORS.GENERAL'))
    );
  }

  closeDialog(value?) {
    this.dialogRef.close(value);
  }
}
