<div class="mat-elevation-z0">
  <div fxLayout="row" fxLayoutAlign="cenet center" class="filter-bar-stats">
    <app-filter-bar fxFlexAlign="end" *ngIf="!hideFilters" [style.margin-right]="'-50px'" [filters]="['dateRange']" (filterChanges)="onFilterChange($event)"></app-filter-bar>

    <button *ngIf="dateRange.length && !filters" mat-icon-button (click)="sendOrderSummaries()">
      <mat-icon>send</mat-icon>
    </button>

    <app-order-stats [hidden]="!dateRange[0]" style="width: 100%;" [dateRange]="dateRange" [orderId]="orderId"></app-order-stats>
  </div>

  <table mat-table class="full-width-table" matSort aria-label="Elements" >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th class="borderless" mat-header-cell *matHeaderCellDef mat-sort-header="candidate__first_name,candidate__last_name">{{'ORDER.NAME' | translate}}</th>
      <td mat-cell *matCellDef='let row'>
        <a href='{{getCandidateUrl(row)}}' target='_blank' mat-icon-button inline>
          {{row.name}}
          <mat-icon inline>open_in_new</mat-icon>
        </a>
      </td>
    </ng-container>

    <!-- Livas Status Column -->
    <ng-container matColumnDef="livas-status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="candidate__livas_status__title_lt" >{{'LIVAS_STATUS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{row.livasStatus}}
      </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>{{ 'PROGRESS.TITLE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            <app-progress-component [events]="row" [isSegmentClickable]="false"></app-progress-component>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef >{{'COMMENT' | translate}}</th>
      <td mat-cell *matCellDef="let row" >
        <div style="justify-content: space-between; align-items: center; width: 100%; display: flex;" >
          <span>{{row.offerpropositioncommentSet.totalCount ? row.offerpropositioncommentSet.edges[0]?.node.text : ''}}</span>
          <button *ngIf="row.offerpropositioncommentSet.totalCount"
                  mat-icon-button
                  >
            <mat-icon
                  [matBadgeSize]="'small'"
                  [matBadgeColor]="'accent'"
                  [matBadge]="'+' + row.offerpropositioncommentSet.totalCount"
                  [matBadgeHidden]="row.offerpropositioncommentSet.totalCount == 1"
                  (click)="openCommentDialog(row)"
                  inline="true"

              >comment</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Cancellation Reasons Column -->
    <ng-container matColumnDef="cancellation-reasons">
      <th mat-header-cell *matHeaderCellDef >{{ 'REASONS' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let cr of row.cancellationReasons" class="cancellation-reason">
          {{cr?.cancellationReason?.title}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <h4 style="text-align: center; margin-bottom: 0;">
          {{ 'NO_ONE_WAS_OFFERED_THIS_POSITION' | translate }}
        </h4>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.totalCount"
      [pageIndex]="0"
      [pageSize]="50"
      [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
