import { Injectable } from '@angular/core';
import {CovidLabelsGQL, SaveCompanyLabelsGQL, UpdateCompanyTagsInExternalSysGQL} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  constructor(
    private covidLabelsGQL: CovidLabelsGQL,
    private saveCompanyLabelsGql: SaveCompanyLabelsGQL,
    private updateCompanyTagsInExternalSysGQL: UpdateCompanyTagsInExternalSysGQL
  ) { }

  getLabels() {
    return this.covidLabelsGQL.watch({}, {fetchPolicy: 'cache-and-network'}).valueChanges;
  }

  saveCompanyLabels(companyId: string, labelIds: string[]) {
    return this.saveCompanyLabelsGql.mutate(
      {companyId, labels: labelIds},
      { refetchQueries: [ 'company' ] }
      );
  }

  updateProjectsInExternalSysyems(companyId, date, updateLivas, updateWorkis) {
    return this.updateCompanyTagsInExternalSysGQL
      .mutate({companyId, dateFrom: date, updateLivas, updateWorkis});
  }
}
