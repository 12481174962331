import {Component, Input, OnInit} from '@angular/core';
import {Certificate, Requirement, RiskFactor, Specification, OrdersGQL, Order} from '../../graphql/graphql';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ScheduleChangeEvent, InputModes, ScheduleTypes} from '../schedule-setup/schedule-setup.component';
import {Tag, TagAssignment, TagGroup} from '../tag-assignment-table/tag';
import {TagAssignmentTableItem} from '../tag-assignment-table/tag-assignment-table-datasource';
import {GroupByPipe} from 'ngx-pipes';
import {SpecificationEditGQL} from './graphql';
import {PersonalProtectiveEquipment} from '../ppe-issuance-table/ppe';
import {PPESelectionEvent} from '../ppe-issuance-table/ppe-issuance-table.component';
import { MatDialog } from '@angular/material/dialog';
import {AddRequirementDialogComponent} from '../add-requirement-dialog/add-requirement-dialog.component';
import { AgeRestriction } from './age-restriction/age-restriction.component';
import { OrderTypeEnum } from 'src/app/shared/types/order-types';
import { NotificationService } from 'src/app/shared/notification.service';
import { TranslateService } from '@ngx-translate/core';

export enum TagGroupKey {
  workplaceBenefits = 'group1',
  workplaceInto = 'group2',
  transportInfo = 'transport',
  provision = 'provision'
}

export enum IntensityOptions {
  stationary = 'STATIONARY',
  stationaryFast = 'STATIONARY_FAST',
  sedentary = 'SEDENTARY',
  sedentaryFast = 'SEDENTARY_FAST',
  sedentaryAndStationary = 'SEDENTARY_STATIONARY'
}

export enum ExperienceTypes {
  same = 'SAME',
  similar = 'SIMILAR',
  irrelevant = 'IRRELEVANT'
}

const TagGroupsMap = TagGroupKey;



@Component({
  selector: 'app-specification-edit',
  templateUrl: './specification-edit.component.html',
  styleUrls: ['./specification-edit.component.scss'],
  providers: [GroupByPipe]
})
export class SpecificationEditComponent implements OnInit {
  private spec: Specification;

  tagGroupMap = TagGroupsMap;

  specForm: FormGroup;
  schedule: ScheduleChangeEvent;

  intensityOptions = [
    IntensityOptions.stationary,
    IntensityOptions.stationaryFast,
    IntensityOptions.sedentary,
    IntensityOptions.sedentaryFast,
    IntensityOptions.sedentaryAndStationary,
  ];

  trainingsDuration = ['Iki 1d', '1d', '2-3 d.', '3-7d', 'iki 30d', 'virš 30d'];
  preselectCertificates: Certificate[];
  preselectRiskFactors: RiskFactor[] = [];
  private tagsGroups$: TagGroup[];
  tagsGroupDict: {[key: string]: Tag[]} = {};
  tagAssignments: TagAssignment[];
  personalProtectiveEquipment: PersonalProtectiveEquipment[];
  ppeIssuedByClient: string[];
  ppeIssuedByAgency: string[];
  scheduleInputMode: InputModes;
  relatedOrders: Order[];
  orderTypes = OrderTypeEnum;
  experienceTypes = ExperienceTypes;
  errorsVisible = false;


  @Input()
  set specificationId(specId: number) {
    this.loadSpecificationData(specId);
    // this.loadRelatedOrders(specId);
  }

  @Input() isNew;

  @Input() orderType: OrderTypeEnum = OrderTypeEnum.staffing;
  @Input() lockLevel;
  isEmptySpec = false;
  country: any;

  get ageFrom() {return this.specForm && this.specForm.get('ageFrom'); }
  get ageTo() {return this.specForm && this.specForm.get('ageTo');  }

  constructor(
    private specificationGQL: SpecificationEditGQL,
    private ordersGQL: OrdersGQL,
    private fb: FormBuilder,
    private groupBy: GroupByPipe,
    private dialog: MatDialog,
    private notify: NotificationService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {

  }

  private loadSpecificationData(specId: number) {
    this.specificationGQL.fetch({id: specId}, {fetchPolicy: 'no-cache'}).subscribe(
      resp => {
        const data = resp.data;
        this.spec = data.specification;
        data.tagsGroups.forEach(group => {
          this.tagsGroupDict[group.key] = group.tagSet;
        });

        this.isEmptySpec = (data.specification.riskFactors.length === 0);
        this.personalProtectiveEquipment = data.personalProtectiveEquipment;
        this.$initSpecForm();
      }
    );
  }

  private $initSpecForm() {
    let shiftPattern = [];
    try {
      shiftPattern = JSON.parse(this.spec.shiftPattern);
    } catch (e) {
      console.error(e);
    }
    this.specForm = this.fb.group({
      positionTitle: [this.spec.positionTitle, [Validators.required]],
      workFunctions: [this.spec.workFunctions || '', [Validators.required]],
      allFunctionsFromFirstDay: this.spec.allFunctionsFromFirstDay,
      address: [this.spec.address || '', [Validators.required]],
      //
      intensity: [this.spec.intensity, [Validators.required]],
      onboardingDuration: [this.spec.onboardingDuration, [Validators.required]],
      // maxWeight: '',
      //
      ageFrom: this.spec.ageFrom,
      ageTo: this.spec.ageTo,
      male: this.spec.male,
      female: this.spec.female,

      minExperienceMonths: this.spec.minExperienceMonths || 0,
      experienceType: this.spec.experienceType || ExperienceTypes.same,


      certificates: new FormControl(this.spec.certificates),
      riskFactors: new FormControl(this.spec.riskFactors),

      mandatoryRequirements: new FormControl(this.spec.mandatoryRequirements),
      optionalRequirements: new FormControl(this.spec.optionalRequirements),

      shiftDuration: this.spec.shiftDuration,
      breakDuration: this.spec.breakDuration,
      scheduleType: this.spec.scheduleType,
      shiftPattern,
      scheduleText: this.spec.scheduleText,
      scheduleTypes: new FormControl(this.spec.scheduleTypes),

      isWorkplaceVideoAllowed: this.spec.isWorkplaceVideoAllowed,
      isWorkplaceVisitAllowed: this.spec.isWorkplaceVisitAllowed,
      isLogoPublicAllowed: this.spec.isLogoPublicAllowed,
      isCompanyModern: this.spec.isCompanyModern,
      isCompanyInternational: this.spec.isCompanyInternational,

      isSecurityPostPresent: this.spec.isSecurityPostPresent,
      isMagneticKeyIssued: this.spec.isMagneticKeyIssued,
      whoIssuesMagneticKey: this.spec.whoIssuesMagneticKey,

      assignmentTags: new FormControl(this.transformAssignments(this.spec.tagassignmentSet)),

      personalProtectiveEquipmentBiuro: new FormControl(this.spec.personalProtectiveEquipmentBiuro.map(it => it.id)),
      personalProtectiveEquipmentClient: new FormControl(this.spec.personalProtectiveEquipmentClient.map(it => it.id)),
      /* ageRestriction: [] */

    });

    this.ppeIssuedByAgency = this.spec.personalProtectiveEquipmentBiuro.map( it => it.id);
    this.ppeIssuedByClient = this.spec.personalProtectiveEquipmentClient.map( it => it.id);

    this.tagAssignments = this.spec.tagassignmentSet;
    this.schedule = {
      shiftDuration: this.spec.shiftDuration,
      breakDuration: this.spec.breakDuration,
      scheduleType: this.spec.scheduleType,
      shiftPattern,
      scheduleText: this.spec.scheduleText,
      scheduleTypes: this.spec.scheduleTypes
    };
    this.scheduleInputMode = InputModes.freetext;

    this.preselectCertificates = this.spec.certificates;
    this.preselectRiskFactors = this.spec.riskFactors;

    if (this.lockLevel) {
      this.specForm.get('address').disable();
      this.specForm.get('isSecurityPostPresent').disable();
      this.specForm.get('isMagneticKeyIssued').disable();
      this.specForm.get('whoIssuesMagneticKey').disable();
      if (this.lockLevel === 'full') {
        this.notify.notify('Specifikacija buvo patvirtinta kitame užsakyme ir jos redaguoti nebegalima. Norėdami redaguoti, spauskite "Dublikuoti specifikaciją"')
      } else {
        this.notify.notify('Užsakymas yra patvirtintas, todėl kai kurie specifikacijos laukeliai yra neredaguojami')
      }
    }
  }

  private transformAssignments(tagassignmentSet: TagAssignment[]): {[groupKey: string]: TagAssignmentTableItem[]} {
    const tagAssignmetTableItems = tagassignmentSet.map(
      ta =>  {
        return {tagId: ta.tag.id, comment: ta.comment, title: '', hint: '', groupKey: ta.tag.group.key};
      });

    return this.groupBy.transform(tagAssignmetTableItems, 'groupKey');

  }

  onCertificatesUpdate(certs: Certificate[]) {
    this.specForm.get('certificates').setValue(certs);
  }

  onRequirementsSelect($event: Requirement[], formKey: string) {

    this.specForm.get(formKey).setValue($event);

  }

  onScheduleChange($event: ScheduleChangeEvent & {inputMode: InputModes}) {
    const form = this.specForm;
    this.scheduleInputMode = $event.inputMode;
    const shiftDuration = form.get('shiftDuration');
    const breakDuration = form.get('breakDuration');
    const scheduleType = form.get('scheduleType');
    const shiftPattern = form.get('shiftPattern');
    const scheduleText = form.get('scheduleText');
    const scheduleTypes = form.get('scheduleTypes');

    // if ($event.inputMode === InputModes.structure) {
    //   shiftDuration.setValue($event.shiftDuration);
    //   // shiftDuration.setValidators([Validators.required]);
    //   shiftDuration.updateValueAndValidity();
    //   breakDuration.setValue($event.breakDuration);
    //   // breakDuration.setValidators([Validators.required]);
    //   breakDuration.updateValueAndValidity();
    //   scheduleType.setValue($event.scheduleType);
    //   scheduleType.updateValueAndValidity();
    //   shiftPattern.setValue($event.shiftPattern);
    //   // shiftPattern.setValidators([Validators.required]);
    //   shiftPattern.updateValueAndValidity();
    //   scheduleText.setValue(null);
    //   scheduleText.clearValidators();
    //   scheduleText.updateValueAndValidity();
    // } else {
    //   shiftDuration.setValue(null);
    //   shiftDuration.clearValidators();
    //   shiftDuration.updateValueAndValidity();
    //   breakDuration.setValue(null);
    //   breakDuration.clearValidators();
    //   breakDuration.updateValueAndValidity();
    //   scheduleType.setValue(null);
    //   scheduleType.clearValidators();
    //   scheduleType.updateValueAndValidity();
    //   shiftPattern.setValue(null);
    //   shiftPattern.clearValidators();
    //   shiftPattern.updateValueAndValidity();
    //   scheduleText.setValue($event.scheduleText);
    //   scheduleText.setValidators([Validators.required]);
    //   scheduleText.updateValueAndValidity();
    // }
    scheduleText.setValue($event.scheduleText);
    scheduleTypes.setValue($event.scheduleTypes);
  }

  onRiskFactorsUpdate($event: RiskFactor[]) {
    this.specForm.get('riskFactors').setValue($event);
  }

  onTagsSelection($event: TagAssignmentTableItem[], groupKey: string) {

    const assignments = this.specForm.get('assignmentTags').value;
    assignments[groupKey] = $event;
  }

  onSelectedChange($event: PPESelectionEvent) {
    this.specForm.get('personalProtectiveEquipmentBiuro').setValue($event.agency.map(it => it.id));
    this.specForm.get('personalProtectiveEquipmentClient').setValue($event.client.map(it => it.id));
  }

  addRequirement() {
    this.dialog.open(AddRequirementDialogComponent);
  }

  checkErrors() {
    this.specForm.markAllAsTouched();
    this.errorsVisible = true;
  }

  numberInputControl(input) {
    const regex = /[0-9]/;
    if (!regex.test(input.key)) {
      input.preventDefault();
    }
  }

  onAgeRestrictionChange(restrictions: AgeRestriction) {
    this.ageFrom && this.ageFrom.setValue(restrictions.from);
    this.ageTo && this.ageTo.setValue(restrictions.to);
  }

  navigateToOrder(orderId) {
    window.open(`./orders/edit/${orderId}`, '_blank');
  }

  private loadRelatedOrders(specId: number) {
    this.ordersGQL.fetch(
      {specification : specId},
      {fetchPolicy: 'no-cache'}
    ).subscribe(
      r => {
        const result = r.data.ordersPaginated.edges.map(it => {
          const node = it.node;
          const oid = atob(node.id).split(':')[1];
          node.id = oid;
          return node;
        });
        this.relatedOrders = result;
      });
  }

  showErrorForSection(sectionId: number) {
    switch (sectionId) {
      case 0:
        return this.specForm.get('positionTitle').hasError('required') ||
               this.specForm.get('workFunctions').hasError('required') ||
               this.specForm.get('intensity').hasError('required') ||
               this.specForm.get('onboardingDuration').hasError('required')
      case 1:
        return this.specForm.get('address').hasError('required')
      case 2:
        return false;
        // if (this.scheduleInputMode === InputModes.structure) {
        //   return (
        //     !this.specForm.get('shiftDuration').value ||
        //     !(this.specForm.get('breakDuration').value != null) ||
        //     !(this.specForm.get('scheduleType').value !== undefined) ||
        //     !this.specForm.get('shiftPattern').value
        //   )
        // } else {
        //   return (!this.specForm.get('scheduleText').value)
        // }
      default:
        return false;
    }
  }

  hasUnfilledFields(sectionId: number) {
    switch (sectionId) {
      case 3:
        return !this.specForm.get('assignmentTags').value[this.tagGroupMap.transportInfo] || this.specForm.get('assignmentTags').value[this.tagGroupMap.transportInfo].length === 0;
      case 4:
        return (
                 (!this.specForm.get('mandatoryRequirements').value || this.specForm.get('mandatoryRequirements').value.length === 0) &&
                 (!this.specForm.get('optionalRequirements').value || this.specForm.get('optionalRequirements').value.length === 0)
               ) ||
               (this.orderType !== OrderTypeEnum.selection && (!this.specForm.get('riskFactors').value || this.specForm.get('riskFactors').value.length === 0))
      case 5:
        return (!this.specForm.get('personalProtectiveEquipmentBiuro').value || this.specForm.get('personalProtectiveEquipmentBiuro').value.length === 0) &&
               (!this.specForm.get('personalProtectiveEquipmentClient').value || this.specForm.get('personalProtectiveEquipmentClient').value.length === 0)
      case 6:
        return (!this.specForm.get('assignmentTags').value[this.tagGroupMap.workplaceBenefits] || this.specForm.get('assignmentTags').value[this.tagGroupMap.workplaceBenefits].length === 0)
      case 7:
        return (!this.specForm.get('assignmentTags').value[this.tagGroupMap.workplaceInto] || this.specForm.get('assignmentTags').value[this.tagGroupMap.workplaceInto].length === 0)
      case 8:
        return !this.specForm.get('isWorkplaceVideoAllowed').value &&
               !this.specForm.get('isWorkplaceVisitAllowed').value &&
               !this.specForm.get('isLogoPublicAllowed').value &&
               !this.specForm.get('isCompanyModern').value &&
               !this.specForm.get('isCompanyInternational').value
      default:
        return false;
    }
  }

  isPartialLockActive() {
    return this.lockLevel === 'full' || this.lockLevel === 'partial';
  }

}
