import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { RiskFactorsTableDatasource } from './risk-factors-table-datasource';
import { SelectionModel } from '@angular/cdk/collections';
import { RiskFactor } from '../../graphql/graphql';
import { FormControl } from '@angular/forms';
import { CountryType, GetRiskFactorsGQL } from 'src/generated/graphql';
import { OrderCreateService } from 'src/app/order/order-create/order-create.service';

@Component({
  selector: 'app-risk-factors-table',
  templateUrl: './risk-factors-table.component.html',
  styleUrls: ['./risk-factors-table.component.scss']
})
export class RiskFactorsTableComponent implements AfterViewInit, OnInit {
  preselectIds: string[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<RiskFactor>;

  @Output() selected = new EventEmitter<RiskFactor[]>();
  @Input() markDefault;
  @Input()
  set preselect(value: RiskFactor[]) {
    this.preselectIds = value.map(it => it.id);
    if (this.dataSource && this.dataSource.data && this.dataSource.data.value) {
      this.updateSelection(this.dataSource.data.value);
    }
  }
  @Input() disabled;

  dataSource: RiskFactorsTableDatasource;
  selection = new SelectionModel<RiskFactor>(true, []);
  filterControl = new FormControl('');
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'name'];
  hideUnselected = true;
  riskFactors: RiskFactor[];

  constructor(private riskFactorsGQL: GetRiskFactorsGQL, private orderCreateServive: OrderCreateService) {

  }

  ngOnInit() {
    this.dataSource = new RiskFactorsTableDatasource();

    this.getRiskFactors(this.orderCreateServive.getCountry());

    this.filterControl.valueChanges.subscribe(() => {
      this.dataSource.filter.next(this.filterControl.value);
      if (this.filterControl.value === '') {
        this.hideUnselectedToggle(true);
      }
    });

    this.selection.changed.subscribe(v => {
      this.selected.emit(this.selection.selected);
      this.selectedOnTop(this.selection.selected);
    });

    this.orderCreateServive.selectedCountry
      .subscribe(country => {
        this.getRiskFactors(country);
      });
  }

  getRiskFactors(country: string) {
    if (country) {
      this.riskFactorsGQL.watch({ country }).valueChanges.subscribe(v => {
        this.riskFactors = v.data.riskFactors;
        this.dataSource.data.next(v.data.riskFactors);
        this.updateSelection(v.data.riskFactors);
      });
    }
  }

  hideUnselectedToggle(checked: boolean) {
    this.hideUnselected = checked;
    this.selectedOnTop(this.selection.selected);
  }

  searchType(value: string) {
    if(value == '') return this.hideUnselectedToggle(true);
    this.hideUnselected = false;
    this.dataSource.data.next(this.riskFactors);
    this.selectedOnTop(this.selection.selected);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.value.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all onSelect; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.value.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  private updateSelection(riskFactors: RiskFactor[]) {
    this.selection.clear();
    if (this.markDefault) {
      riskFactors.forEach(it => {
        if (it.isDefault) {
          this.selection.select(it);
        }
      });
    } else if (this.preselectIds && this.preselectIds.length > 0) {
      riskFactors.forEach(it => {
        if (this.preselectIds.indexOf(it.id) > -1) {
          this.selection.select(it);
        }
      });
    }
    this.selectedOnTop(this.selection.selected);
  }

  selectedOnTop(selected: RiskFactor[]) {
    if (this.riskFactors) {
      if (this.hideUnselected) {
        const selectedFactors = this.riskFactors.filter(factor => selected.map(s => s.id).includes(factor.id));
        this.dataSource.data.next(selectedFactors);
      } else {
        const selectedFactors = this.riskFactors.filter(factor => selected.map(s => s.id).includes(factor.id));
        const unSelectedFactors = this.riskFactors.filter(factor => !selected.map(s => s.id).includes(factor.id));
        this.dataSource.data.next([...selectedFactors, ...unSelectedFactors]);
      }
    }
  }

}
