<div *ngIf='data.comment' class="comment" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-form-field>
    <mat-label>{{ 'COMMENT' | translate }}</mat-label>
    <textarea matInput [(ngModel)]="commentText" ></textarea>
  </mat-form-field>

  <button mat-icon-button matTooltip="Saugoti" [matDialogClose]="commentText">
    <mat-icon>send</mat-icon>
  </button>
</div>

<div *ngIf='data.propositionID || data.orderID || getCommentsByID' class="multiple-comment" fxLayout="column" >
  <div fxLayout="row" fxLayoutAlign="space-between center" [style]="{padding: '0px 16px'}">
    <h4>{{ 'COMMENTS' | translate }}</h4>
    <button *ngIf='!getCommentsByID' mat-icon-button [matDialogClose]="">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [style]="{padding: '0px 16px'}">
    <mat-form-field>
      <mat-label>{{ 'COMMENT' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="commentText" ></textarea>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="16px" [style]="{padding: '0px 16px'}">
    <button mat-icon-button [disabled]="!commentText || isLoading" (click)="refresh()">
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="(idOfListEditItem ? !isCommentChangingPossible() : !commentText) || isLoading"
      (click)="idOfListEditItem ? updateComment() : createComment()"
    >
      {{(idOfListEditItem ? 'UPDATE_COMMENT' : 'POST_COMMENT') | translate}}
    </button>
  </div>

  <mat-spinner diameter="64" *ngIf="isLoading" [style.margin]="' 0 0 16px 80px'"></mat-spinner>

  <mat-list *ngIf='!isLoading' role="list" fxLayout="column" fxLayoutGap="24px">
    <mat-list-item *ngFor="let comment of comments" role="listitem">
      <mat-card>
        <mat-card-content>
          <p class="text">{{comment.node.text || comment.node.comment}}</p>
          <mat-divider [style]="{position: 'initial'}"></mat-divider>
        </mat-card-content>

        <mat-card-actions>
          <div [style]="{padding: '0px 8px'}" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <div class="text">{{comment.node.createdBy.user.firstName + ' ' + comment.node.createdBy.user.lastName}}</div>
            <div class="text">{{moment(comment.node.createdAt).format('YYYY-MM-DD HH:mm')}}</div>
            <button mat-icon-button [disabled]="!!data.orderID" (click)="idOfListEditItem = comment.node.id; commentText = comment.node.text">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </mat-list-item>
  </mat-list>
</div>
