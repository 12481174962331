<mat-grid-list cols="6" rowHeight="80px">

  <mat-grid-tile colspan="1">
    <div class="tooltip-data info">
      <span placement="right" delay="0" [tooltip]="'ORDER.SALARY_EXPLANATION' | translate"><mat-icon>info</mat-icon></span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="2"> {{'ORDER.GROSS' | translate}} </mat-grid-tile>
  <mat-grid-tile colspan="2"> {{'ORDER.NET' | translate}} </mat-grid-tile>
  <mat-grid-tile colspan="1"> {{'ORDER.EXPORT_TO_DOCUMENT' | translate}} </mat-grid-tile>

  <mat-grid-tile colspan="1" class="border-bottom"> {{'ORDER.HOURLY_SALARY' | translate}} </mat-grid-tile>

  <mat-grid-tile colspan="2"  class="border-bottom">
    <app-salary-range-input [values]="hourlyBrutto"
                            (valueChange)="onInputValueChange($event, salaryTypes.hourlyBruto)"
    ></app-salary-range-input>
  </mat-grid-tile>

  <mat-grid-tile colspan="2"  class="border-bottom border-left">
    <app-salary-range-input [values]="hourlyNetto"
                            (valueChange)="onInputValueChange($event, salaryTypes.hourlyNetto)"
    ></app-salary-range-input>
  </mat-grid-tile>

  <mat-grid-tile colspan="1" rowspan="2"  >
    <mat-radio-group [(ngModel)]="valueForDocument" (ngModelChange)="setControlValues()" fxLayout="column" fxFlex="100" >
      <mat-radio-button [value]="periods.Hourly" color="primary" class="border-bottom" style="padding:24px 16px ; "></mat-radio-button>
      <mat-radio-button [value]="periods.Monthly" color="primary" style="padding:24px 16px ;"></mat-radio-button>
    </mat-radio-group>

  </mat-grid-tile>



  <mat-grid-tile colspan="1"> {{'ORDER.MONTHLY_SALARY' | translate}} </mat-grid-tile>

  <mat-grid-tile colspan="2">
      <app-salary-range-input [values]="monthlyBrutto"
                              (valueChange)="onInputValueChange($event, salaryTypes.monthlyBruto)"
      ></app-salary-range-input>
  </mat-grid-tile>

  <mat-grid-tile colspan="2" class="border-left">
      <app-salary-range-input [values]="monthlyNetto"
                              (valueChange)="onInputValueChange($event, salaryTypes.monthlyNetto)"
      > </app-salary-range-input>
  </mat-grid-tile>


</mat-grid-list>
<mat-error *ngIf="salaryMatrix.errors?.requiredBrutto">{{'ORDER.REQUIRED_BRUTTO_ERROR' | translate}}</mat-error>
<mat-error *ngIf="!salaryMatrix.errors?.requiredBrutto && salaryMatrix.errors?.requiredBruttoForUseInDoc">{{'ORDER.REQUIRED_BRUTTO_FOR_USE_IN_DOC_ERROR' | translate}}</mat-error>
