import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginCallbackComponent } from './auth/login-callback/login-callback.component';
import { NagivationComponent } from './navigation/nagivation/nagivation.component';

import { CompanyImporterDialogComponent } from './insightly/company-importer-dialog/company-importer-dialog.component';
import { ContractImporterComponent } from './contract/contract-importer/contract-importer.component';
import { SpecificationNameDialogComponent } from './specification/specification-name-dialog/specification-name-dialog.component';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from './shared/shared.module';
import { PlannerModule } from './planner/planner.module';
import { ContactCreateDialogComponent } from './orders/contact-create-dialog/contact-create-dialog.component';
import { OnDutyDashboardModule } from './on-duty-dashboard/on-duty-dashboard.module';
import { UploaderModule } from './uploader/uploader.module';
import { AddonsImportModule } from './addons-import/addons-import.module';
import { FreeVacanciesModule } from './free-vacancies/free-vacancies.module';

import { OrdersModule } from './orders/orders.module';

import { InterviewWidgetModule } from './interview-widget/interview-widget.module';
import { CommentDialogComponent } from './interview-widget/comment-dialog/comment-dialog.component';
import { PmStatsDashboardModule } from './pm-stats-dashboard/pm-stats-dashboard.module';
import { CompaniesModule } from './companies/companies.module';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';

import { AbsencesModule } from './absences/absences.module';
import { AbsencesImportComponent } from './absences/absences-import/absences-import.component';
import { AbsencesCalendarComponent } from './absences/absences-calendar/absences-calendar.component';
import { AbsencesRevisionComponent } from './absences/absences-revision/absences-revision.component';
import { AbsencesTableComponent } from './absences/absences-table/absences-table.component';
import { AbsencesTableColumnComponent } from './absences/absences-table-column/absences-table-column.component';
import { AbsencesDialogComponent } from './absences/absences-dialog/absences-dialog.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { RecruitmentDashboardModule } from './recruitment-dashboard/recruitment-dashboard.module';

import { CalendarModule } from './calendar/calendar.module';
import { CalendarComponent } from './calendar/calendar.component';
import { OfferPropositionWidgetModule } from './offer-proposition-widget/offer-proposition-widget.module';
import { JobAdsModerationModule } from './job-ads-moderation/job-ads-moderation.module';
import { CandidatesModule } from './candidates/candidates.module';
import { ExcelWidgetModule } from './excel-widget/excel-widget.module';
import { RequestActivitiesModule } from './request-activities/request-activities.module';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginCallbackComponent,
    NagivationComponent,
    CompanyImporterDialogComponent,
    ContractImporterComponent,
    ContactCreateDialogComponent,
    ConfirmationDialogComponent,
    AbsencesDialogComponent,
    AbsencesRevisionComponent,
    AbsencesTableComponent,
    AbsencesTableColumnComponent,
    AbsencesImportComponent,
    AbsencesCalendarComponent,
    CalendarComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    MatSlideToggleModule,
    GraphQLModule,
    HttpClientModule,
    NgPipesModule,
    FreeVacanciesModule,
    InterviewWidgetModule,
    OfferPropositionWidgetModule,
    PlannerModule,
    AppRoutingModule,
    OnDutyDashboardModule,
    UploaderModule,
    AddonsImportModule,
    AbsencesModule,
    OrdersModule,
    CompaniesModule,
    PmStatsDashboardModule,
    RecruitmentDashboardModule,
    MatStepperModule,
    FullCalendarModule,
    CalendarModule,
    JobAdsModerationModule,
    CandidatesModule,
    ExcelWidgetModule,
    RequestActivitiesModule
  ],
  providers: [TranslateService],
  bootstrap: [AppComponent],
  entryComponents: [
    CompanyImporterDialogComponent,
    ContractImporterComponent,
    SpecificationNameDialogComponent,
    ContactCreateDialogComponent,
    CommentDialogComponent,
  ],
  exports: [
    TranslateModule,
    ContractImporterComponent
  ]
})
export class AppModule {}
