<mat-form-field appearance="outline" fxFlex="100">
  <mat-label>{{'CONTRACT' | translate}}</mat-label>
  <mat-select [formControl]="contract" (ngModelChange)="onChange($event)">
    <mat-option (click)="importContract()" selected>
      <mat-icon>add</mat-icon> {{'IMPORT_CONTRACT' | translate }}
    </mat-option>
    <mat-option *ngFor="let c of contracts | orderBy:'-validFromDate'"
                [value]="c.id" [ngClass]="{'red-bg-option': c.validTillDate < today}">
      {{ c.validFromDate }} - {{ c.validTillDate}} <img src="{{c.contractor?.logoUrl}}" class="contractor-ico">
    </mat-option>
  </mat-select>
</mat-form-field>
