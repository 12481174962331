import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

// creating a dummy component. instead of using different guard for each component
// this protects against browser refresh and closing the tab as well
export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(public translate: TranslateService){}
  canDeactivate(component: CanComponentDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate()
      ? true
      : confirm(this.translate.instant('NAVIGATE-WITHOUT-SAVE-WARNING'));
  }
}
