import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import {FreeVacanciesTableDataSource} from './free-vacancies-table-datasource';
import {FreeVacanciesTableGQL, FreeVacanciesTableItem} from '../graphql/graphql';
import {GroupByPipe} from 'ngx-pipes';
import {IntensityOptions, TagGroupKey} from 'src/app/specification/specification-edit/specification-edit.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FilterInterface} from 'src/app/shared/filter-bar/filter-bar.component';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {OrderTrafficGeneration} from '../../shared/types/order-traffic-generation';

import * as moment from 'moment';
import {Moment} from 'moment';
import {FvPlanningDialogComponent} from '../fv-planning-dialog/fv-planning-dialog.component';
import {ActionItemStatus, FvPlaningDialogActionItem} from '../fv-planing-dialog-action-item/fv-planing-dialog-action-item';
import {DEMO_CATEGORIES} from '../fv-planing-dialog-action-item/fv-planing-dialog-action-item.component';
import {FvOrderChangesDialogComponent} from '../fv-order-changes-dialog/fv-order-changes-dialog.component';
import {first} from 'rxjs/operators';
import {FvOrderChangesTableItem} from '../fv-order-changes-table/fv-order-changes-table-datasource';
import { FreeVacanciesListFieldsFragment, GetFreeVacanciesOrderGQL } from '../../../generated/graphql';
import {
  JobAdCreationDialogComponent
} from '../../job-ads-moderation/job-ad-creation-dialog/job-ad-creation-dialog.component';

export enum FVMode {
  marketing = 'marketing',
  recruitment = 'recruitment',
}

export interface DateColumnObject {
  date: string;
  isSaturday: boolean;
  isSunday: boolean;
  isToday: boolean;
}

@Component({
  selector: 'app-free-vacancies-table',
  templateUrl: './free-vacancies-table.component.html',
  styleUrls: ['./free-vacancies-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '637px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeVacanciesTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<FreeVacanciesListFieldsFragment>;

  @Input() public mode: FVMode;

  orderId: string;
  dataSource: FreeVacanciesTableDataSource;
  datesColumns: DateColumnObject[] = [];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['position', 'actions', 'salary', 'netto', 'functions', 'requirements', 'clientContact', 'trafficGeneration', ];
  // displayedColumns = ['position', ];

  expandedElement: FreeVacanciesTableItem;

  categories = DEMO_CATEGORIES;

  orderTrafficGeneration = OrderTrafficGeneration;

  filterSubject = new Subject<FilterInterface>();

  dataSub: Subscription;
  FVMode = FVMode;



  momentFrom = moment().subtract(14, 'days');
  momentTo = moment().add(30, 'days');
  initialFilter: any;

  filters = ['search', 'region', 'sex', 'isLongTerm', 'nettoFrom', 'nettoTo',
    'orderType', 'covidPolicy', 'scheduleTypes', 'canEmployUa', 'country'];

  constructor(
    private tableGQL: FreeVacanciesTableGQL,
    private table2GQL: GetFreeVacanciesOrderGQL,
    private groupBy: GroupByPipe,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.orderId = this.route.snapshot.queryParamMap.get('orderId');
    if (this.orderId) {
      this.initialFilter = {search: `id:${this.orderId}`};
    }

    this.dataSource = new FreeVacanciesTableDataSource(this.groupBy, this.table2GQL, this.mode,  this.momentFrom, this.momentTo);

    if (this.mode === FVMode.marketing) {
      this.displayedColumns = ['position', 'actions', 'shortage'];
      this.datesColumns = this.generateDateColumns(this.momentFrom, this.momentTo);
      this.displayedColumns = this.displayedColumns.concat(...this.datesColumns.map(el => el.date), 'star');
      /* this.dataSource.loading.subscribe(res => {
        if (res === false) {
          document.querySelector('div.table-container').scrollBy(350, 0);
        }
      }) */
    }

    // console.log(this.displayedColumns)
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  onFilterChanges(filters: FilterInterface) {
    this.dataSource.filters.next(this.filtersForQuery(filters));
  }

  sliceRequirements(requirements) {
    if (requirements && requirements.length) {
      return requirements.slice(0, 3);
    }
    return [];
  }

  urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (!text) {return '';}
    else {
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    }
  }

  openEventsDialog(project, day) {

    const dialogRef = this.dialog.open(FvPlanningDialogComponent, {
      data: {
        project,
        day
      },
      width: '90vw',
      maxWidth: '90vw'
    });

    dialogRef.afterClosed().subscribe(
      ev => {
        this.cdr.detectChanges();
        this.dataSource.filters.next(this.filtersForQuery(this.dataSource.filters.value));
      }
    );
  }

  filtersForQuery(filters: FilterInterface) {
    let orderId;
    const trafficGeneration = [this.orderTrafficGeneration.activeWithMarketing];
    if (this.mode === FVMode.recruitment) {
      trafficGeneration.push(this.orderTrafficGeneration.activeNoMarketing);
    }else if (this.mode === FVMode.marketing) {
      trafficGeneration.push(this.orderTrafficGeneration.marketingOnly);
    }

    let search = filters.search;
    if (filters.search.indexOf('id:')  === 0) {
      orderId = filters.search.split('id:')[1];
      search = '';
    }

    return {
      id: orderId,
      search: search,
      region: filters.region,
      sex: filters.sex,
      trafficGeneration: trafficGeneration.join(','),
      isLongTerm: filters.isLongTerm,
      nettoFrom: filters.nettoFrom,
      nettoTo: filters.nettoTo,
      orderType: filters.orderType,
      scheduleTypes: filters.scheduleTypes,
      covidPolicy: filters.covidPolicy,
      canEmployUa: filters.canEmployUa,
      country: filters.country,
      // specification_Male: filters.male,
      // specification_Female: filters.female,
    };
  }

  private generateDateColumns(start: Moment, end: Moment): DateColumnObject[] {
    const dates: DateColumnObject[] = [];
    if (end.isAfter(start)) {
      let dt = start.clone();
      dates.push(generateDateObject(dt));
      while (dt.isBefore(end)) {
        dt = dt.clone();
        dt.add(1, 'days');
        dates.push(generateDateObject(dt));
      }
    }

    return dates;

    function generateDateObject(date: Moment): DateColumnObject {
      return {
        date: date.format('YYYY-MM-DD'),
        isSaturday: date.day() === 6,
        isSunday: date.day() === 0,
        isToday: moment().isSame(date, 'day')
      }
    }

  }

  getAction(plannedActions: {node: FvPlaningDialogActionItem}[], col: string): FvPlaningDialogActionItem[]|undefined {
    if (plannedActions && plannedActions.length) {
      const matches = plannedActions.filter(it => it.node.date === col);
      const matchedNode = matches.length > 0 ? matches.map(e => e.node) : undefined;
      return matchedNode;
    }
  }

  getCategoryIcon(category: any) {
    return this.categories.find(it => it.id === category).icon;
  }

  openChangeLogDialog(row: FreeVacanciesTableItem, $event: MouseEvent) {
    $event.stopPropagation();
    const orderId = atob(row.id).split(':')[1];
    const dialogRef = this.dialog.open(
      FvOrderChangesDialogComponent,
      {data: {orderId}}
    );

    dialogRef.afterClosed().pipe(first()).subscribe(
      reviewed => {
        if (reviewed === true) {
          row.toBeReviewed = false;
          this.cdr.detectChanges();
        }
      }
    );
  }

  getRawId(id: string) {
    return atob(id).split(':')[1];
  }

  getIfOrderHasNoActions(plannedActions: {node: FvPlaningDialogActionItem}[]) {
    if (plannedActions && plannedActions.length) {
      return plannedActions.filter(e => moment(e.node.date).isSameOrAfter(this.momentFrom, 'day') && moment(e.node.date).isSameOrBefore(this.momentTo, 'day')).length === 0
    }
    return true;
  }

  calculateColSpan(): number {
    const sidebarWidth = 200;
    const positionHeaderWidth = 220;
    const dayColWidth = 50;
    return Math.floor((screen.width - sidebarWidth - positionHeaderWidth) / 50);
  }

  getCompanyLabelsText(edges: any) {
    return edges.map(it => it.node?.title).join(', ');
  }

  openJobAdDialog(order) {
    this.dialog.open(JobAdCreationDialogComponent, {
      autoFocus: false,
      data: {
        orderId: order.id,
        orderCountry: order.company.country.isoCode,
        salarySpec: order.specification.salary
      }
    });
  }

  getAppPrefix(): string {
    return window.location.origin.includes('livas.biuro') ? '/app' : '';
  }
}
