import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ldu-forms',
  templateUrl: './ldu-forms.component.html',
  styleUrls: ['./ldu-forms.component.scss']
})
export class LduFormsComponent {

  columnsToDisplay = ['name', 'isNew'];
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      extraValues: {
        name: string,
        isNew: boolean,
      },
      title: string
    }
  ) {
    this.title = data.title;
  }
}
