import { Injectable } from '@angular/core';
import { OffersTableItem, ResponseTypes } from './offers-table/offers-table-datasource';
import { GroupByPipe } from 'ngx-pipes';
import {
  EventEventType,
  EventResolutionStatus,
  InterviewWidgetOffersGQL,
  OfferPropositionLongListed
} from '../../generated/graphql';
import { ProgressBarEvent } from './offers-table/progress-component/types';
import { OrderTrafficGeneration } from '../shared/types/order-traffic-generation';
import { map } from 'rxjs/operators';
import { RelayIdService } from '../shared/relay-id.service';

@Injectable({
  providedIn: 'root'
  // prividers: [GroupByPipe]
})
export class OffersTableDataServiceService {

  constructor(
    private groupBy: GroupByPipe,
    private offersTableGQL: InterviewWidgetOffersGQL,
    private relay: RelayIdService
  ) {
  }

  private findEvent(edges, eventType) {
    return edges.find((edge) => edge.node.eventType === eventType)?.node;
  }

  public fetchOffers(sex, personId, livasRegionId, country, search?: string, includeTrialDayInProcess = false) {
    return this.offersTableGQL
      .watch(
        {
          sex,
          personId,
          region_ExternalId: parseInt(livasRegionId, 10),
          country,
          search,
          trafficGeneration: [
            OrderTrafficGeneration.activeNoMarketing,
            OrderTrafficGeneration.activeWithMarketing
          ].join(',')
        },
        { fetchPolicy: 'no-cache' }
      )
      .valueChanges
      .pipe(
        map((data) => {
          return this.processResponse(data, includeTrialDayInProcess);
        })
      );
  }

  private getProgressBarEvent(events, eventType, index) {
    const event = this.findEvent(events, eventType);
    return this.getProgressBarEventFromEvent(events, eventType, index);
  }

  private getProgressBarEventFromEvent(events, value: EventEventType, index: number): ProgressBarEvent | null {
    const eventNode = events.find(n => n.node.eventType === value)?.node;

    const icons = {
      [EventEventType.Interview]: 'meeting_room',
      [EventEventType.ToBePresented]: 'co_present',
      [EventEventType.ClientInterview]: 'event',
      [EventEventType.HealthCheckPending]: 'fact_check',
      [EventEventType.Employment]: 'receipt_long',
      [EventEventType.TrialDay]: 'forklift',
    };

    const statusMap = {
      [EventResolutionStatus.Complete]: 'SUCCESS',
      [EventResolutionStatus.Failed]: 'FAILED',
      [EventResolutionStatus.Cancelled]: 'FAILED',
      [EventResolutionStatus.Rejected]: 'REJECTED'
    };

    const status = eventNode ? (eventNode.resolutionStatus ? statusMap[eventNode.resolutionStatus] : 'PENDING') : null;

    return { status, icon: icons[value], index, id: eventNode?.id, startDatetime: eventNode?.startDatetime };
  }

  private getLongListedEvent(value): ProgressBarEvent | null {
    const statusMap = {
      [OfferPropositionLongListed.Included]: 'SUCCESS',
      [OfferPropositionLongListed.RejectedWithoutCall]: 'FAILED'
    };

    const status = value in statusMap ? statusMap[value] : null;

    return { status, icon: 'playlist_add_circle', index: 0 };
  }

  private getOfferEvent(value: ResponseTypes, onCall: boolean): ProgressBarEvent | null {
    const statusMap = {
      [ResponseTypes.agreed]: 'SUCCESS',
      [ResponseTypes.undecided]: 'PENDING',
      [ResponseTypes.notInterested]: 'FAILED'
    };
    const icon = onCall ? 'check_circle_outline' : 'thumb_up';
    return { status: value ? statusMap[value] : null, icon, index: onCall ? 1 : 3 };
  }

  private processResponse(resp: any, includeTrialDayInProcess = false) {
    const offers = resp.data.offers.edges.map((it) => it.node);
    const grouped = this.groupBy.transform(offers, 'order.id');
    const orders: OffersTableItem[] = resp.data.ordersPaginated.edges.map((it) => {
      const response = grouped[it.node.id] || [{}];
      const re = response[0];
      const node = it.node;
      const events = re.candidateEvents?.edges || [];
      const spec = node.specification;

      const healthCheckindex = includeTrialDayInProcess ? 7 : 6;
      const employmentIndex = includeTrialDayInProcess ? 8 : 7;

      const refactoredMap = {
        companyName: spec?.company?.name,
        companyStatus: spec?.company?.status,
        color: spec?.company?.color,
        longListed: re.longListed,
        longListEvent: this.getLongListedEvent(re.longListed),
        introductionCallEvent: this.getOfferEvent(re.interestedOnCall, true),
        officeInterviewInvitationEvent: this.getProgressBarEvent(events, EventEventType.Interview, 2),
        officeInterviewEvent: this.getOfferEvent(re.interested, false),
        presentToClientEvent: this.getProgressBarEvent(events, EventEventType.ToBePresented, 4),
        clientInterviewEvent: this.getProgressBarEvent(events, EventEventType.ClientInterview, 5),
        trialDay: this.getProgressBarEvent(events, EventEventType.TrialDay, 6),
        healthcheckEvent: this.getProgressBarEvent(events, EventEventType.HealthCheckPending, healthCheckindex),
        employmentEvent: this.getProgressBarEvent(events, EventEventType.Employment, employmentIndex),
        interestedOnCall: re.interestedOnCall,
        officeInterview: this.findEvent(events, EventEventType.Interview),
        interested: re.interested,
        presentingToClientEvent: null,
        lastSegmentIdx: 1,
        comment: re.comment,
        id: it.node.id,
        title: spec?.positionTitle,
        responseId: this.relay.parseId(re.id),
        signed: re.signed,
        candidate: re?.candidate?.id,
        order: it.node.id,
        orderType: it.node.orderType,
        candidateEvent: re?.candidateEvents?.edges,
        healthCheckPendingEvent: this.findEvent(events, EventEventType.HealthCheckPending),
        cancellationreasonassignmentSet: re?.cancellationreasonassignmentSet?.edges.map((it) => it.node),
        offerpropositioncommentSet: re.offerpropositioncommentSet,
        offerPropositionId: re.id
      };


      refactoredMap['lastSegmentIdx'] = Math.max(
        refactoredMap.officeInterviewInvitationEvent.status ? 2 : 1,
        refactoredMap.officeInterviewEvent.status ? 3 : 1,
        refactoredMap.presentToClientEvent.status ? 4 : 1,
        refactoredMap.clientInterviewEvent.status ? 5 : 1,
        refactoredMap.trialDay.status ? 6 : 1,
        refactoredMap.healthcheckEvent.status ? healthCheckindex : 1,
        refactoredMap.employmentEvent.status ? employmentIndex : 1
      );

      return refactoredMap;
    });
    return orders;
    // this.originalDS = orders;
    // this.dataSource.data.next(orders);
  }

}
