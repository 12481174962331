import { Component, ViewEncapsulation, Input, OnInit, OnChanges } from '@angular/core';
import { AbsencesService } from '../absences.service';

@Component({
  selector: 'app-absences-table',
  templateUrl: './absences-table.component.html',
  styleUrls: ['./absences-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AbsencesTableComponent implements OnInit, OnChanges {
  displayedColumns = [];
  dataSource = [];
  range = [];

  @Input() period: any;
  @Input() rows: any;
  @Input() openAbsencesDialog: any;

  constructor(
    private absencesService: AbsencesService,
  ) {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.period.from || !this.period.to) {
      return;
    }

    this.range = this.absencesService.getDatesRange(this.period.from, this.period.to);

    this.displayedColumns = ['name', ...Object.keys(this.range)];

    this.dataSource = [ ...this.dataSource, ...this.rows];
  }
}
