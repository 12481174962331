import { Component, EventEmitter, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UploadOutput, UploadInput, UploadFile, UploaderOptions, } from 'ngx-uploader';

@Component({
  selector: 'app-files-uploader',
  templateUrl: './files-uploader.component.html',
  styleUrls: ['./files-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilesUploaderComponent implements OnInit {
  isLoading = false;

  @Input() accept: string;
  @Input() onReaderLoad: any;

  options: UploaderOptions;
  file: UploadFile;
  uploadInput: EventEmitter<UploadInput>;

  constructor() {
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  onUploadOutput(output: UploadOutput): void {

    if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.isLoading = true;
      this.file = output.file;

      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.onReaderLoad(reader.result);
        this.isLoading = false;
      };

      reader.readAsDataURL(output.file.nativeFile);
    }
  }

  ngOnInit(): void {
  }
}
