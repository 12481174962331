<!-- date-customfield.component.html -->
<div class='container' *ngIf='valueController' [formGroup]='valueController'>
  <mat-form-field appearance='outline' style='width: 100%;'>
    <mat-label>{{label}}</mat-label>
    <input matInput [matDatepicker]='datepicker' [placeholder]='hint'
           [formControlName]="'dateValue'" />
    <mat-datepicker-toggle matSuffix [for]='datepicker'></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
</div>
