
<div *ngIf="(absences | json) != '{}'">
  <div *ngFor="let employer of absences | keyvalue">
    <div *ngFor="let absence of absences[employer.key]"
      class="absence"
      [ngClass]="getAbsenceCSS(absence, employer.key, warnings)"
      [matTooltip]="getAbsenceTooltip({ employerId: employer.key })"
      matTooltipClass="custom-tooltip"
      (click)="openAbsencesDialog({ employeeId: employeeId, employerId: employer.key, date: date, absence: absence })"
    >
      <div class="absence-label">{{ absence.absence_type }}</div>
      <div *ngIf="absence.unpaid == false" class="material-icons-outlined">euro</div>
      <div *ngIf="shifts && shifts[employer.key]" class="material-icons-outlined">today</div>
    </div>
  </div>
</div>

<div *ngIf="(absences | json) == '{}'">
  <div *ngIf="content && content.date && content.expected_absence !== false"
    class="absence absence--theme-missing"
    [matTooltip]="getAbsenceTooltip({})"
    matTooltipClass="custom-tooltip"
    (click)="openAbsencesDialog({ employeeId: employeeId, date: content.date })"
  >
    <div class="absence-label">-</div>
  </div>
</div>
