<!--The content below is only a placeholder and can be replaced.-->

<div class="rd-container">
  <div fxLayout="row">

    <mat-form-field>
      <input matInput [matDatepicker]="picker" placeholder="Choose a date" [min]="today"
        (dateInput)="onDateChange($event)" [value]="today">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div fxLayout="column" fxFlex="100" class="header-info">
      <div fxLayout="row">
        <div>
          <p> Vilnius Workio dienos</p>
          <p> II ir IV</p>
          <p> 16:00</p>
        </div>

        <div>
          <p> Kaunas Workio dienos</p>
          <p> II ir IV</p>
          <p> 15:00</p>
        </div>

        <div>
          <p> Šiauliai Workio dienos </p>
          <p> II ir IV</p>
          <p> 16:00</p>
        </div>
      </div>
    </div>
  </div>

  <app-invited [selectedDate]="selectedDate"></app-invited>

</div>