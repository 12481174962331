import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { GetUnreadOrdersGQL, UnreadOrdersResponse, ProcessedOrderViewGQL } from '../graphql/graphql';

const fetchIntervalMs = 10000;

@Injectable({
  providedIn: 'root'
})
export class UnseenOrdersService {

  private intervalRef;

  unreadMessageSubject = new BehaviorSubject<UnreadOrdersResponse>({unseenAccepted: 0, unseenRejected: 0});
  registered = false;

  constructor(
    private unreadOrdersGQL : GetUnreadOrdersGQL,
    private processedOrderViewGQL: ProcessedOrderViewGQL
  ) { }

  getSubject(): Subject<UnreadOrdersResponse> {
    if (!this.registered) {
      this.registered = true;
      this.emitData();
      // this.intervalRef = setInterval(() => {
      //   this.emitData();
      // }, fetchIntervalMs)
    }
    return this.unreadMessageSubject;
  }

  stopInterval() {
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
      this.registered = false;
    }
  }

  emitData() {
    if (localStorage.getItem('token')) {
      this.unreadOrdersGQL.fetch(undefined, {fetchPolicy: 'no-cache'}).subscribe(res => {
        const data = res.data;
        this.unreadMessageSubject.next({unseenAccepted: data.unseenAccepted, unseenRejected: data.unseenRejected})
      });
    }
  }

  sendReviewedDate(): Observable<any> {
    return this.processedOrderViewGQL.mutate();
  }
}
