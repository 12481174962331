import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {GetChangeLogGQL} from './graphql';
import {FvOrderChangesTableItem} from '../fv-order-changes-table/fv-order-changes-table-datasource';
import {UpdateOrderGQL} from '../../graphql/graphql';

@Component({
  selector: 'app-fv-order-changes-dialog',
  templateUrl: './fv-order-changes-dialog.component.html',
  styleUrls: ['./fv-order-changes-dialog.component.scss']
})
export class FvOrderChangesDialogComponent implements OnInit {
  tableData: FvOrderChangesTableItem[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {orderId: string},
    private getChangeLogGql: GetChangeLogGQL,
    private updateOrderGQL: UpdateOrderGQL,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FvOrderChangesDialogComponent>
  ) { }

  ngOnInit() {
    this.getChangeLogGql.watch(
      {orderId: this.data.orderId},
      {fetchPolicy: 'cache-and-network'}
      ).valueChanges.subscribe(
      resp => {
        if (resp.data) {
          this.tableData = resp.data.orderChangeLog;
        }
      }
    );
  }

  makeReview() {
    this.updateOrderGQL.mutate({id: this.data.orderId, order: {toBeReviewed: false}}).subscribe(
      r => {
        this.snackBar.open('Reviewed successfully', undefined, {duration: 3000});
        this.dialogRef.close(true);
      }
    );
  }
}
