import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderPeriod} from '../../graphql/graphql';
import {FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-order-period',
  templateUrl: './order-period.component.html',
  styleUrls: ['./order-period.component.scss']
})
export class OrderPeriodComponent implements OnInit {
  period$: OrderPeriod;
  periodForm;

  @Input()
  set period(value: OrderPeriod) {
    this.period$ = value;
    this.initForm(value);
  }

  @Input() disabled = false;

  get period() { return this.period$; }

  @Output() removed = new EventEmitter<boolean>();
  @Output() change = new EventEmitter<boolean>();


  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    if (! this.periodForm) {
      this.initForm();
    }
  }

  private initForm(value?: OrderPeriod) {
  //   if (value) {
  //     this.periodForm = this.fb.group({
  //       dateFrom: value.dateFrom || '',
  //       dateTo: value.dateTo || '',
  //       demand: value.demand || 0
  //     });
  //   } else {
  //     this.periodForm = this.fb.group({
  //       dateFrom:  '',
  //       dateTo: '',
  //       demand:  0
  //     });
  //   }
  //
  }

  remove() {
    this.removed.emit(true);
  }

  demandInputControl(input) {
    const regex = /[0-9]/;
    if (!regex.test(input.key)) {
      input.preventDefault();
    }
    this.change.emit();
  }
}
