<button
  [ngStyle]="bcColor ? { 'background-color': bcColorMap[bcColor], color: textColorMap[bcColor] } : {}"
  (click)="openDatepicker(picker, $event); $event.stopPropagation()"
>
  {{ initValue ? moment(initValue).format('DD/MM/YYYY') : ('NO_DATA' | translate) }}
</button>

<input readonly [value]="moment(initValue)" [matDatepicker]="picker" (dateInput)="valueChange.emit($event.value)">

<mat-datepicker #picker></mat-datepicker>
