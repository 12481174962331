import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { JobAdModerationService } from '../job-ad-moderation.service';
import {
  JobAdAiContentStatus,
  MutationCreateJobAdLivasArgs,
  SpecificationSalaryPeriodEnum
} from '../../../generated/graphql';
import { environment } from '../../../environments/environment';
import {
  InputValueStatus,
  SalaryRangeValues,
  SalarySizeTypes
} from '../../orders/order-create/salary-matrix/salary-matrix-values';

export const SalaryTypeDescription = {
  0: 'bruto_hour',
  1: 'neto_hour',
  2: 'bruto_month',
  3: 'neto_month'
};

@Component({
  selector: 'app-job-ad-creation-dialog',
  templateUrl: './job-ad-creation-dialog.component.html',
  styleUrls: ['./job-ad-creation-dialog.component.scss']
})
export class JobAdCreationDialogComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  ads: any[] = [];
  selectedAd: any = {};
  selectedIndex = {};
  Object = Object;
  SalarySizeTypes = SalarySizeTypes;
  SalaryTypeDescription = SalaryTypeDescription;
  loading = true;
  adCreationLoading = false;
  variantsFromAiLoading = false;
  createdAdId = '';
  errAdCreation = false;
  variantsFromAiCount = 0;
  workingHoursVariants = [
    { key: 'fulltime', title: 'fulltime' },
    { key: 'parttime', title: 'parttime' },
    { key: 'workInShifts', title: 'work in shifts' }
  ];
  categories: {title: string, id: number}[];
  locations: {title: string, id: number}[];
  selectedOptions: FormGroup;
  salaryRanges: SalaryRangeValues[];
  selectedSalaryRange: SalaryRangeValues;
  getAiTryCount = 0;

  constructor(
    public dialogRef: MatDialogRef<JobAdCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, orderCountry: string, salarySpec: any },
    private jobAdModerationService: JobAdModerationService,
    public dom: DomSanitizer,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.jobAdModerationService.getJobAdCat().subscribe(({ data }) => {
      this.categories = data.jobCategories.edges.map(item => ({ title: item.node.title, id: item.node.externalId }));
      this.locations = data.cities.edges.map(item => ({ title: item.node.title, id: item.node.externalId }));
    });

    let globalId = this.data.orderId;
    // globalId = 'T3JkZXJNVHlwZTo1MDI=';
    this.jobAdModerationService.getLivasJobAds(globalId).subscribe(ads => {
        const adsList = [...ads.data.livasJobAds];

        if (adsList.length) {
          if (adsList.length > 1) {
            const lastAd = this.sortAdsByDate(adsList)[0];

            let bestAds = adsList.sort((ad1, ad2) => ad2.contactsCount - ad1.contactsCount);
            bestAds = bestAds.filter(ad => ad.contactsCount === bestAds[0].contactsCount);
            let bestAd;

            if (bestAds.length === 1) {
              bestAd = bestAds[0];
            } else {
              bestAd = this.sortAdsByDate(bestAds)[0];
            }

            this.selectedOptions.controls.workingHours.setValue(lastAd.workingHours);
            this.selectedOptions.controls.categoryId.setValue(lastAd.categoryId);
            this.selectedOptions.controls.locationId.setValue(lastAd.locationId);

            this.ads.push(this.convertJobAdsFotTable(lastAd));
            this.ads.push(this.convertJobAdsFotTable(bestAd));
          } else {
            this.ads.push(this.convertJobAdsFotTable(adsList[0]));
            this.ads.push(this.convertJobAdsFotTable(adsList[0]));
          }
        }

        this.loading = false;
      },
      err => {
        console.log(err);
        this.loading = false;
      });

    this.getAiData();

    this.selectedOptions = this.fb.group({
      workingHours: [null, Validators.required],
      categoryId: [null, Validators.required],
      locationId: [null, Validators.required],
      salaryType: [null, Validators.required]
    });

    this.initSalaryInputs();
  }

  getAiData() {
    this.getAiTryCount++;
    this.variantsFromAiLoading = true;
    this.subscription.add(
      this.jobAdModerationService.getJobAds(this.getRawId(this.data.orderId)).subscribe(({ data }) => {
        if (data.jobAdsContentAi?.status === JobAdAiContentStatus.Completed) {
          const adsFromAI = JSON.parse(data.jobAdsContentAi.response);
          const selectedIDXs = Object.keys(this.selectedIndex);

          this.variantsFromAiCount = adsFromAI.length;

          if (this.ads.length > 2) {
            this.ads = this.ads.slice(this.ads.length - 2, this.ads.length);

            if (selectedIDXs.length) {
              selectedIDXs.forEach(idx => {
                if (this.selectedIndex[idx] < 2) {
                  delete this.selectedIndex[idx];
                }
              });
            }
          } else if (selectedIDXs.length) {
            selectedIDXs.forEach(idx => this.selectedIndex[idx] = this.selectedIndex[idx] + this.variantsFromAiCount);
          }

          this.ads.unshift(...adsFromAI);
          this.variantsFromAiLoading = false;
        } else if (this.getAiTryCount < 10) {
          window.setTimeout(() => this.getAiData(), 5000);
        } else {
          this.variantsFromAiLoading = false;
        }
      },
      err => {
        console.log(err);
        this.variantsFromAiLoading = false;
      })
    );
  }

  initSalaryInputs() {
    const salarySpec = this.data.salarySpec;

    this.salaryRanges = [
      {
        fromValue: salarySpec?.hourlyBruttoFrom,
        toValue: salarySpec?.hourlyBruttoTo,
        fromEdited: InputValueStatus.clean,
        toEdited: InputValueStatus.clean
      },
      {
        fromValue: salarySpec?.hourlyNettoFrom,
        toValue: salarySpec?.hourlyNettoTo,
        fromEdited: InputValueStatus.clean,
        toEdited: InputValueStatus.clean
      },
      {
        fromValue: salarySpec?.monthlyBruttoFrom,
        toValue: salarySpec?.monthlyBruttoTo,
        fromEdited: InputValueStatus.clean,
        toEdited: InputValueStatus.clean
      },
      {
        fromValue: salarySpec?.monthlyNettoFrom,
        toValue: salarySpec?.monthlyNettoTo,
        fromEdited: InputValueStatus.clean,
        toEdited: InputValueStatus.clean
      }
    ];

    this.subscription.add(
      this.selectedOptions.controls.salaryType.valueChanges.subscribe(value => {
        this.selectedSalaryRange = this.salaryRanges[value];
      })
    );

    this.selectedOptions.controls.salaryType.setValue(
      this.data.salarySpec?.useInTemplate === SpecificationSalaryPeriodEnum.Hourly ? 0 : 2
    );
  }

  sortAdsByDate(data) {
    return data.sort((ad1, ad2) => {
      const ad1date = Number(ad1.updatedAt.split('-').join(''));
      const ad2date = Number(ad2.updatedAt.split('-').join(''));
      return ad2date - ad1date;
    });
  }

  convertJobAdsFotTable(data) {
    return {
      title: data.advertTitle,
      summary: data.description2,
      description: data.descriptionDsc,
      requirements: data.requirementsDsc,
      weOffer: data.offerDsc,
      seoTitle: data.seoTitle,
    };
  }

  getRawId(id: string): number {
    return Number(atob(id).split(':')[1]);
  }

  selectSegment(columnIndex: number, segment: string) {
    this.selectedAd[segment] = this.ads[columnIndex][segment];
    this.selectedIndex[segment] = columnIndex;
  }

  quillInput(event: any, columnIndex: number, segment: string) {
    if (this.selectedIndex[segment] === columnIndex && event.source === 'user') {
      this.selectedAd[segment] = event.html;
    }
  }

  onSubmit() {
    const input: MutationCreateJobAdLivasArgs = {
      orderId: this.data.orderId,
      advertTitle: this.selectedAd.title,
      summary: this.selectedAd.summary,
      descriptionDsc: this.selectedAd.description,
      requirementsDsc: this.selectedAd.requirements,
      offerDsc: this.selectedAd.weOffer,
      seoTitle: this.selectedAd.seoTitle,
      salaryFrom: this.selectedSalaryRange.fromValue,
      salaryTo: this.selectedSalaryRange.toValue,
      salaryType: SalaryTypeDescription[this.selectedOptions.value.salaryType],
      workingHours: this.selectedOptions.value.workingHours,
      categoryId: this.selectedOptions.value.categoryId,
      locationId: this.selectedOptions.value.locationId
    };

    this.adCreationLoading = true;
    this.jobAdModerationService.createLivasJobAd(input).subscribe(res => {
      this.adCreationLoading = false;
      this.createdAdId = JSON.parse(res.data.createJobAdLivas.response).id;
    },
      () => {
        this.errAdCreation = true;
        this.adCreationLoading = false;
      }
    );
  }

  generateAdLink(): string {
    return `${environment.edlaAPIBase}/${this.data.orderCountry}/skelbimai.php?id=${this.createdAdId}`;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
