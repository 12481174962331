import {Injectable} from '@angular/core';
import {Mutation} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({providedIn: 'root'})
export class UpdateCompanyRelayGQL extends Mutation<{
  updateCompanyRelay: {ok: boolean}
}> {
  document = gql`
    mutation updateCompanyRelay(
          $id: ID!,
          $description: String,
          $color: CompanyLights
    ){
      updateCompanyRelay(id: $id, description: $description, color: $color) {
        ok
      }
    }
  `;
}
