import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  headers: {};

  private locale: string;
  private apiBase: string;
  private apiBases: { lv: string, ee: string, lt: string, gigroup: string };

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.apiBases = {
      lv: environment.livasAPIBaseLVV3,
      ee: environment.livasAPIBaseEEV3,
      lt: environment.livasAPIBaseLTV3,
      gigroup: environment.livasAPIBaseGiGroupV3
    };

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.headers = {
        Authorization: `LivasSession ${params.sessionId ? params.sessionId : (cookieService.get('PHPSESSIONID') || cookieService.get('PHPSESSID') || '') }`,
      };

      this.locale = params.locale || '';
      this.apiBase = this.apiBases[params.country] || this.apiBases.lt;
    });
  }

  setMonthLock = (month: string, locked: number, locale = this.locale) => {
    return this.http.put<any>(`${this.apiBase}/calendar.php?lang=${locale}`, { month, locked, action: 'monthLock' },
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getMonthLock = (month = moment().format('YYYY-MM')) => {
    return this.http.get<object>(`${this.apiBase}/calendar.php?get=monthLock&month=${month}`, { headers: this.headers }).pipe(
        map((res: any) => {
          return res;
        }));
  }

  getCalendarDays = (month = moment().format('YYYY-MM')) => {
    return this.http.get<object>(`${this.apiBase}/calendar.php?get=calendarDays&month=${month}`, { headers: this.headers }).pipe(
        map((res: any) => {
          return res;
    }));
  }

  getCalendarDaysInPeriod = (from: string, to: string) => {
    return this.http.get<object>(`${this.apiBase}/calendar.php?get=calendarDays&date_from=${from}&date_to=${to}`, { headers: this.headers }).pipe(
        map((res: any) => {
          return res;
    }));
  }

  getTypeColor = (type: any) => {
    switch (type) {
    case '':
      return 'rgba(255, 255, 255, .3)';
    case '0':
      return 'rgba(0, 255, 0, .3)';
    default:
      return 'rgba(255, 0, 0, .3)';
    }
  }

  getDayTypes = (locale = this.locale) => {
    return this.http.get<object>(`${this.apiBase}/calendar.php?get=dayTypes&lang=${locale}`, { headers: this.headers }).pipe(
        map((res: any) => {
          const types = [];

          for (const value in res) {
            if (res[value]) {
              types.push({ label: res[value], value, color: this.getTypeColor(value) });
            }
          }
          return types;
        })
      );
  }

  parseUpdateCalendarData = (periods: any, dayType: string) => {
    return periods.reduce((res, period) => {
      const from = moment(period.startStr);
      const to = moment(period.endStr);
      const diff = to.diff(from, 'days');

      for (let i = 0; i < diff; i++) {
        res[moment(from).add(i, 'days').format('YYYY-MM-DD')] = dayType;
      }

      return res;
    }, {});
  }

  updateCalendarDays = (periods: any, dayType: string, locale = this.locale) => {
    const data = this.parseUpdateCalendarData(periods, dayType);

    return this.http.put<any>(`${this.apiBase}/calendar.php?lang=${locale}`, { data, action: 'calendarDays' },
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
}
