
import gql from 'graphql-tag';
export const MetaData = {
    fragments: {
      regionsListFields: gql`
        fragment RegionsListFields on RegionType {
          id
          title
          regionGroup {
            id
            title
            priority
          }
        }
      `
    }
  }

  export const OrderData = {
    fragments: {
      ordersListFields: gql`
        fragment OrdersListFields on OrderType {
          id
          hourRateBruttoPermanent
          orderNumber
          subtype
          orderPeriodFrom
          orderPeriodTo
          isLongTerm
          demand
          orderType
          trafficGeneration
          status
          nettoFrom
          nettoTo
          shortage
          specification {
            id
            positionTitle
            address
          }
          createdBy {
            id
            user{
              id
              email
              firstName
              lastName
            }
          }
          clientContact {
            id
            email
            firstName
            lastName
          }
          company {
            id
            status
            name
            color
          }
        }
      `
    }
  };
