import { Component, OnInit, ViewChild } from '@angular/core';
import { AllCompaniesGQL, Company, UpdateSpecificationGQL, CompaniesPaginatedResponse } from 'src/app/graphql/graphql';
import { SpecificationEditComponent } from '../specification-edit/specification-edit.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { Router } from '@angular/router';
import {CreateSpecificationGQL} from '../../../generated/graphql';

@Component({
  selector: 'app-specification-create',
  templateUrl: './specification-create.component.html',
  styleUrls: ['./specification-create.component.scss']
})
export class SpecificationCreateComponent implements OnInit {

  @ViewChild(SpecificationEditComponent) specificationView;

  companies: Company[] = [];
  selectedCompanyId: string = null;

  newSpecificationName: string;
  newSpecification;

  constructor(
    private allCompaniesGQL: AllCompaniesGQL,
    private createSpecGQL: CreateSpecificationGQL,
    private updateSpecGQL: UpdateSpecificationGQL,
    private notify: NotificationService,
    private router: Router
  ) {
    this.allCompaniesGQL
      .watch()
      .valueChanges
      .subscribe(res => {
        this.companies = this.parseEdges(res.data.companies);
      });
  }

  ngOnInit() {
  }

  onCompanyChange(company: Company) {
    if (company) {
      this.selectedCompanyId = company.id;
    } else {
      this.selectedCompanyId = null;
    }
  }

  onCompanyAdd(company: Company) {
    this.companies.push(company);
    this.onCompanyChange(company.id);
  }

  createSpecification() {
    this.createSpecGQL.mutate({input: {
        positionTitle: this.newSpecificationName,
        name: this.newSpecificationName
      }, companyId: this.selectedCompanyId}).subscribe(
       resp => this.newSpecification = resp.data.createSpecification.specification
    );
  }

  updateSpecification() {
    if (this.specificationView && this.specificationView.specForm) {
      const specData = this.specificationView.specForm.getRawValue();

      specData.mandatoryRequirements = specData.mandatoryRequirements.map(it => it.id);
      specData.optionalRequirements = specData.optionalRequirements.map(it => it.id);
      specData.certificates = specData.certificates.map(it => it.id);
      specData.riskFactors = specData.riskFactors.map(it => it.id);
      specData.scheduleTypes = specData.scheduleTypes.map(it => it.id);
      specData.shiftPattern = specData.shiftPattern  ? JSON.stringify(specData.shiftPattern) : '[]';

      const tagAssignments = [].concat(...Object.values(specData.assignmentTags));

      specData.assignmentTags = tagAssignments.map( t => {
        return {tagId: t.tagId, comment: t.comment};
      });


      this.updateSpecGQL.mutate({id: this.newSpecification.id, specification: specData})
        .subscribe(resp => {
          this.router.navigate(['specifications/list']);
          this.notify.notify('Specification successfully created');
        }, err => {
          this.notify.notify(`Error on creation: ${err}`);
        });
    }
  }

  private parseEdges(input: CompaniesPaginatedResponse) {
    return input.edges.map(it => {
      const node = it.node;
      const oid = atob(node.id).split(':')[1];
      node.id = oid;
      return node;
    });
  }

}
