import {Component, Inject, OnInit} from '@angular/core';
import {CovidLabelsGQL} from '../../../generated/graphql';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {LabelsService} from '../../shared/labels.service';
import moment, {Moment} from 'moment';


class Label {
  id: string;
  title: string;
}

@Component({
  selector: 'app-assign-company-labels-dialog',
  templateUrl: './assign-company-labels-dialog.component.html',
  styleUrls: ['./assign-company-labels-dialog.component.scss']
})
export class AssignCompanyLabelsDialogComponent implements OnInit {
  labels = [];
  selection = new SelectionModel<string>(true, []);
  // selection: MatSelection
  updateOrdersInWorkis = false;
  updateOrdersInLivas = false;
  dateFrom: Moment = moment();

  errors: string;
  tooltip2 = 'Jeigu klientui tinka bet kuris Covid dokumentas (Covid testas, antikūnių testas, sirgimo pasas, skiepo pasas) žymėkite VISUS variantus. Tačiau jei klientui tinka tik pasiskiepiję ir prasirgę - reikėtų rinktis atitinkamai';
  tooltip = 'Atnaujinus Workis užsakymus, kandidatai, kurie neturės įkėlę vieno iš 4 aukščiau minimų dokumentų, nebegalės būti patvirtinti į poziciją. Kandidaduoti galės, tačiau PV matys, kad trūksta Covid dokumento ir patvirtinti negalės.\n' +
    '\n' +
    'Atnaujinus Livas užsakymus - žmonės esantys užsakyme ir neturintys bent vieno iš reikalingų požymių atsiras https://redash.workis.online/queries/698 ir bus matomi kaip "probleminiai" iki kol gaus priskirtą bent vieną požymį';

  constructor(
    private labelsService: LabelsService,
    private dialogRef: MatDialogRef<AssignCompanyLabelsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {companyId: string, labels: {id: string, title: string}[]}
  ) {
    data.labels.forEach(label => this.selection.select(label.id));
  }

  ngOnInit(): void {
    this.loadLabels();
  }

  private loadLabels() {
    this.labelsService.getLabels().subscribe(
      resp => {
        if (resp.data) {
          this.labels = resp.data.labels.edges.map(it => it.node);
        }
      }
    );
  }

  changeAll() {
    if (this.selection.selected.length === this.labels.length) {
      this.selection.clear();
    } else {
      this.labels.forEach(lbl => this.selection.select(lbl.id));
    }
  }

  isIndeterminate() {
    return this.selection.selected
      && this.selection.selected.length > 0
      && (this.selection?.selected.length !== this.labels.length) ;
  }

  saveSelections() {
    //FixMe: why is this companyModelType
    const companyId = btoa(atob(this.data.companyId).replace('CompanyModelType', 'CompanyType'));

    this.labelsService.saveCompanyLabels(companyId, this.selection.selected).subscribe(
      saved => {
        if (saved.data.updateCompanyModel.company) {

          this.labelsService.updateProjectsInExternalSysyems(companyId,
              this.dateFrom.format('yyyy-MM-DD'),
                this.updateOrdersInLivas, this.updateOrdersInWorkis
          ).subscribe(
            updated => {
              this.dialogRef.close();
            }
          );
        } else {
          this.errors = 'COMPANY_CANNOT_BE_UPDATED';
        }
      }
    );
  }
}
