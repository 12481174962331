import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OrderType } from '../../../../generated/graphql';

export interface WarningMap {
  conversions: boolean;
  offers: boolean;
  lifetime: boolean;
}

@Component({
  selector: 'app-metrics-popup',
  templateUrl: './metrics-popup.component.html',
  styleUrls: ['./metrics-popup.component.scss']
})
export class MetricsPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      order: OrderType,
      warningMap: WarningMap
    }
  ) { }
}
