<div fxLayout="row" fxLayoutAlign="space-between">
      <table #summary>
        <tr class="header-row">
          <td><b>{{'COPY_SPEC.COMPANY_DESCRIPTION' | translate}}</b></td>
        </tr>
        <tr>
          <td>
            <div class="text-with-spaces">{{this.descriptionCtrl?.value}}</div>
          </td>
        </tr>

        <tr class="header-row">
          <td><b>{{'workFunctions' | translate}}</b></td>
        </tr>
        <tr>
          <td><div class="text-with-spaces">{{specificationCtrl?.get('workFunctions')?.value}}</div></td>
        </tr>

        <tr class="header-row">
          <td><b>{{'requirements' | translate}}</b></td>
        </tr>
        <tr>
          <td>
            <div class="text-with-spaces">{{specificationCtrl?.get('requirements')?.value}}</div>
            <div class="text-with-spaces">{{specificationCtrl?.get('competences2')?.value}}</div>
            <div class="text-with-spaces">{{requirementsMap[specificationCtrl?.get('mandatoryRequirements')?.value]}}</div>
            <div class="text-with-spaces">{{requirementsMap[specificationCtrl?.get('optionalRequirements')?.value]}}</div>
          </td>
        </tr>

        <tr class="header-row">
          <td><b>{{'workConditions' | translate}}</b></td>
        </tr>
        <tr>
          <td>
            <div class="text-with-spaces">{{specificationCtrl?.get('workConditions')?.value}}</div>
            <div class="text-with-spaces">{{specificationCtrl?.get('careerPossibilities')?.value}}</div>
            <div class="text-with-spaces">{{specificationCtrl?.get('scheduleText')?.value}}</div>
          </td>
        </tr>

        <tr class="header-row">
          <td><b>{{'selectionProcess' | translate}}</b></td>
        </tr>
        <tr>
          <td>
            <div class="text-with-spaces">{{specificationCtrl?.get('selectionProcess')?.value}}</div>
          </td>
        </tr>

        <tr class="header-row">
          <td><b>{{'agencyProcessStandart' | translate}}</b></td>
        </tr>
        <tr>
          <td>
            <div class="text-with-spaces">{{specificationCtrl?.get('agencyProcessStandart')?.value}}</div>
          </td>
        </tr>

      </table>

      <button mat-icon-button (click)="copyTable(summary)"><mat-icon>content_copy</mat-icon> </button>
    </div>
