import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { AuthService } from './auth/auth.service';
import { environment } from 'src/environments/environment';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';


const uri = `${environment.url}/graphql/`; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink, auth: AuthService) {
  const http = httpLink.create({ uri });

  const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : ''
      }
    });

    return forward(operation);
  });


  const errlink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError.name} ${networkError.message}`);
      if (networkError.message.indexOf('401 ') > -1 ){
        localStorage.removeItem('token');
        auth.adminLogin();
      }
    }
  });

  return {
    link: ApolloLink.from([errlink, authLink, http]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {

}
