import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-scheduled-task-create-notification',
  templateUrl: './scheduled-task-create-notification.component.html',
  styleUrls: ['./scheduled-task-create-notification.component.scss']
})
export class ScheduledTaskCreateNotificationComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
