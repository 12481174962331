import { Component, Input } from '@angular/core';

import { OperationsTaskFieldsFragment } from '../../../../generated/graphql';
import { convertMinutesToTime } from '../../../shared/functions/time-functions';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent {
  convertMinutesToTime = convertMinutesToTime;

  @Input() task: OperationsTaskFieldsFragment;
}
