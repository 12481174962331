import {Component, Input, OnInit} from '@angular/core';
import {OrderStatsFieldsFragment, OrderStatsGQL} from "../../../generated/graphql";


@Component({
  selector: 'app-order-stats',
  templateUrl: './order-stats.component.html',
  styleUrls: ['./order-stats.component.scss']
})
export class OrderStatsComponent implements OnInit {
  stats: OrderStatsFieldsFragment = {
    interestedCount: 0,
    notInterestedCount: 0,
    signed: 0,
    totalOffered: 0,
    interestedCallCount: 0,
    notInterestedCallCount: 0
  };
  @Input() orderId;
  @Input() set dateRange(range: string []) {
    // if (range.length == 2) {
      this.fetchStats(range[0], range[1])
    // }
  }
  constructor(
    private orderStatsGql: OrderStatsGQL
  ) { }

  ngOnInit() {
    this.fetchStats();
  }

  fetchStats(dateFrom?, dateTo?) {
    this.orderStatsGql.fetch({orderId: this.orderId, dateFrom, dateTo}).subscribe(
      resp => {
        this.stats = resp.data.offerPropositionStats;
      }
    );
  }

}
