import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ContactsListDatasource } from './contacts-list.datasource';
import { FilterBarComponent, FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { AllContactsGQL, ContactType, UpdateContactGQL } from '../../../generated/graphql';
import { ContactCreateDialogComponent } from '../../orders/contact-create-dialog/contact-create-dialog.component';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ContactType>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('filterBar') filterBar: FilterBarComponent;
  dataSource: ContactsListDatasource;
  displayedColumns = ['name', 'company', 'phone', 'email', 'insightlyId', 'comment', 'visibility'];

  constructor(
    private contactsGQL: AllContactsGQL,
    private dialog: MatDialog,
    private notify: NotificationService,
    private updateContactGQL: UpdateContactGQL,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.dataSource = new ContactsListDatasource(this.contactsGQL);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    window.setTimeout(() => {
      this.route.queryParams.subscribe(params => {
        if (params?.id) {
          this.filterBar.filterForm.get('search').setValue(`id:${ params.id }`);
        }
      });
    }, 0);
  }

  onFilterChanges(event: FilterInterface) {
    const filters = {};
    if (event.search) {
      if (event.search.slice(0, 3) === 'id:') {
        filters['id'] = event.search.slice(3);
      } else {
        filters['search'] = event.search;
      }
    }
    if (event.phone) { filters['phone'] = event.phone; }
    if (event.company) { filters['company'] = event.company.id; }
    // if (typeof event.canUseInOrder === 'boolean') { filters['canUseInOrder'] = event.canUseInOrder; }
    this.dataSource.filters.next(filters);
  }

  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactCreateDialogComponent);

    dialogRef.afterClosed().subscribe((contact) => {
      if (contact) {
        this.dataSource.connect();
        this.notify.notify('CONTACT_SUCCESSFULLY_CREATED');
      }
    });
  }

  openEditContactDialog(contactId: string) {
    const dialogRef = this.dialog.open(ContactCreateDialogComponent, {data: {contactId}});

    dialogRef.afterClosed().subscribe((contact) => {
      if (contact) {
        this.dataSource.connect();
        this.notify.notify('CONTACT_SUCCESSFULLY_EDITED');
      }
    });
  }

  updateContactVisibility(contact, value) {
    this.updateContactGQL.mutate(
      {
        id: Number(atob(contact.id).split(':')[1]),
        input: {
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phone: contact.phone,
          companyId: contact.companyId,
          comment: contact.comment,
          canUseInOrder: value
        }
      },
    ).subscribe();
  }
}
