import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { OrderCreateService } from '../../order/order-create/order-create.service';
import { Company } from '../../graphql/graphql';
import { ContractsMetaFieldsFragment } from '../../../generated/graphql';
import { ContractImporterComponent } from '../../contract/contract-importer/contract-importer.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-contract-select-field',
  templateUrl: './contract-select-field.component.html',
  styleUrls: ['./contract-select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractSelectFieldComponent),
      multi: true,
    },
  ],
})
export class ContractSelectFieldComponent
  implements OnInit, ControlValueAccessor {
  private $contract: FormControl;
  private $contracts: ContractsMetaFieldsFragment[];
  @Input() set contract(value: FormControl) {
    this.$contract = value;
  }
  get contract() {
    return this.$contract;
  }

  @Input() set contracts(value: ContractsMetaFieldsFragment[]) {
    this.$contracts = value;
  }
  get contracts() {
    return this.$contracts;
  }

  @Output() refetchContracts = new EventEmitter();

  disabled = false;
  today = moment().format('YYYY-MM-DD');
  value = 0;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private dialog: MatDialog,
    private orderCreateService: OrderCreateService
  ) {}

  ngOnInit(): void {}

  importContract() {
    const dialogRef = this.dialog.open(ContractImporterComponent, {
      data: { company: this.orderCreateService.selectedCompany.value },
    });

    dialogRef.afterClosed().subscribe((c: Company) => {
      if (c) {
        this.orderCreateService.contractSet.next(c.contractSet);
      }
      this.refetchContracts.emit();
    });
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
