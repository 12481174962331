<div  fxLayoutGap="16px"  fxLayout="row" >
<!--  <mat-checkbox [(ngModel)]="period$.longTerm" [disabled]="disabled"> Ilgalaikis </mat-checkbox>-->

  <mat-form-field appearance="outline" fxFlex="50">
    <mat-label>{{'ORDER.PERIOD_DATE_RANGE' | translate}}</mat-label>
    <mat-date-range-input [rangePicker]="picker" >
      <input [(ngModel)]="period$.dateFrom" matStartDate placeholder="{{'ORDER.START_DATE' | translate}}">
      <input [(ngModel)]="period$.dateTo" matEndDate placeholder="{{'ORDER.END_DATE' | translate}}">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field fxFlex="25" appearance="outline">
    <mat-label>{{'ORDER.DEMAND' | translate}}</mat-label>
    <input matInput type="number"
           [(ngModel)]="period$.demand"
           [disabled]="disabled"  placeholder=""  >
  </mat-form-field>

  <div fxFlex="16" fxLayout="row" fxLayoutAlign="end">
    <button mat-icon-button >
      <mat-icon *ngIf="!disabled" (click)="remove()">delete</mat-icon>
    </button>
  </div>

</div>
<!--      <mat-form-field  fxFlex="200px">-->
<!--        <mat-label>Užsakymas NUO</mat-label>-->

<!--        <input matInput [(ngModel)]="period$.dateFrom"-->
<!--               [matDatepicker]="pickerFrom"-->
<!--               (click)="!disabled ? pickerFrom.open() : ''"-->
<!--               placeholder="Užsakymo data NUO"-->
<!--               readonly>-->
<!--        <mat-datepicker-toggle *ngIf="!disabled" matSuffix [for]="pickerFrom"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #pickerFrom></mat-datepicker>-->
<!--      </mat-form-field>-->

<!--      <mat-form-field fxFlex="200px" [fxHide]="period$.longTerm">-->
<!--        <mat-label>Užsakymas IKI</mat-label>-->

<!--        <input matInput [(ngModel)]="period$.dateTo"-->
<!--               [matDatepicker]="pickerTo"-->
<!--               [min]="period.dateFrom"-->
<!--               (click)="!disabled ? pickerTo.open() : ''"-->
<!--               placeholder="Užsakymo data IKI"-->
<!--               readonly>-->
<!--        <mat-datepicker-toggle *ngIf="!disabled" matSuffix [for]="pickerTo"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #pickerTo></mat-datepicker>-->
<!--      </mat-form-field>-->


