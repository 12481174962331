import { Component, OnInit, Input } from '@angular/core';
import { DemandGQL } from './graphql';
import { OrderTypeEnum } from 'src/app/shared/types/order-types';
import { BehaviorSubject } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-demand-chart',
  templateUrl: './demand-chart.component.html',
  styleUrls: ['./demand-chart.component.scss']
})
export class DemandChartComponent implements OnInit {
  periodCounts;

  colorScheme = {domain: ['#ffc107', '#000000']};

  ticks = [];
  orderTypes = OrderTypeEnum;
  orderType = OrderTypeEnum.selection;

  @Input() filterSubject: BehaviorSubject<{dateFrom: Moment, dateTo: Moment}>

  constructor(
    private demandGQL: DemandGQL
  ) { }

  ngOnInit() {
    this.filterSubject.subscribe(res => {
      this.fetchStats(res.dateFrom, res.dateTo);
    })
  }



  fetchStats(dateFrom: Moment, dateTo: Moment) {
    this.demandGQL.fetch({dateFrom: moment(dateFrom).format('YYYY-MM-DD'), dateTo: moment(dateTo).format('YYYY-MM-DD')}, {fetchPolicy: 'no-cache'}).subscribe(resp => {
      let data = [
        {
          name: 'Atrankos',
          series: []
        },
        {
          name: 'Įdarbinimas',
          series: []
        },
      ];
      let ticks = [];
      for (let i = 0; i<resp.data.statsGetAggregatedDemandSelection.length; i++) {
        data[0].series.push({name: resp.data.statsGetAggregatedDemandSelection[i].name, value: resp.data.statsGetAggregatedDemandSelection[i].value});
        data[1].series.push({name: resp.data.statsGetAggregatedDemandStaffing[i].name, value: resp.data.statsGetAggregatedDemandStaffing[i].value});
        if (i % Math.ceil(resp.data.statsGetAggregatedDemandSelection.length/30) === 0) {
          ticks.push(data[0].series[i].name)
        }
      }
      this.ticks = ticks; 
      this.periodCounts = data;
    });
  }
}
