import {Component, Input, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {SaCategoryGroupsFieldsFragment} from '../../../../generated/graphql';


interface CategoryNode {
  expandable: boolean;
  title: string;
  level: number;
  hint?: string;
  children?: CategoryNode[];
}

@Component({
  selector: 'app-action-categories-tree',
  templateUrl: './action-categories-tree.component.html',
  styleUrls: ['./action-categories-tree.component.scss']
})
export class ActionCategoriesTreeComponent implements OnInit {
  @Input() data;
  //
  // treeControl = new NestedTreeControl<CategoryNode>(
  //   node => node.children);


  // dataSource = new MatTreeNestedDataSource<SaCategoryGroupsFieldsFragment>();

  // treeFlattener = new MatTreeFlattener(
  //     this.transformer,
  //     node => node.level,
  //     node => node.expandable,
  //     node => node.sacategorySet.edges
  // );

  private _transformer = (node: CategoryNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      title: node.title,
      level,
      hint: node.hint
    };
  }

  treeControl = new FlatTreeControl<CategoryNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {}

  hasChild = (_: number, node: CategoryNode) => node.expandable;

  ngOnInit(): void {
    function parseCategoryGroupNode(node) {
      return {
        title: node.title,
        expandable: true,
        children: node.sacategorySet?.edges.map(it => it.node)
      };
    }

    this.dataSource.data = this.data.map(it => parseCategoryGroupNode(it));
  }

}
