import {Injectable} from '@angular/core';
import {Mutation, Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Order, OrdersResponse, PageInfoResponse, Specification, Requirement} from '../../graphql/graphql';
import { TagAssignment } from 'src/app/specification/tag-assignment-table/tag';
import { TagGroupKey } from 'src/app/specification/specification-edit/specification-edit.component';
import { ScheduleTypes } from 'src/app/specification/schedule-setup/schedule-setup.component';
import {FvPlaningDialogActionItem} from '../fv-planing-dialog-action-item/fv-planing-dialog-action-item';
import {FreeVacanciesListFieldsFragment} from '../../../generated/graphql';



interface FreeVacanciesSpecification {
  male: boolean;
  female: boolean;
  positionTitle: string;
  address?: string;
  tagassignmentSet: TagAssignment[];
  workFunctions: string;
  mandatoryRequirements: Requirement[];
  optionalRequirements: Requirement[];
  groupedAssignments?: {[key in TagGroupKey]: TagAssignment}[];
  shiftDuration: number;
  breakDuration: number;
  scheduleType: ScheduleTypes;
  shiftPattern: string;
  allFunctionsFromFirstDay: boolean;
  onboardingDuration: string;
  minExperienceMonths: number;
  experienceType: string;
  certificates: {title: string}[];
  riskFactors: {title: string}[];
  isWorkplaceVideoAllowed: boolean;
  isWorkplaceVisitAllowed: boolean;
  // isSalaryPublicAllowed: boolean;
  isLogoPublicAllowed: boolean;
  isCompanyModern: boolean;
  isCompanyInternational: boolean;
  scheduleText: string;
  salaryInfo?: string;
  nettoFrom?: number;
  nettoTo?: number;
  ageFrom?: number;
  ageTo?: number;
}

type FreeVacanciesOrder = Pick<
    Order, 'id'| 'shortage' | 'hourRateBruttoPermanent' | 'trafficGeneration' | 'orderNumber' | 'company' | 'orderperiodSet' | 'createdBy' | 'salaryInfo'
    > & {demand: number, toBeReviewed: boolean, nettoFrom: number, nettoTo: number};

// export interface FreeVacanciesTableItem extends FreeVacanciesOrder {
//   specification: FreeVacanciesSpecification;
//   eventSet?: {edges: {node: FvPlaningDialogActionItem}[]};
// }

export interface FreeVacanciesTableItem extends FreeVacanciesListFieldsFragment {
  transportation?: string;
}

const SpecificationFV = {
  fragments: {
    eventsFields: gql`
      fragment EventsSetFields on EventType {
        id
        date
        status
        adId
        externalPlatformId
        channels {
          id
          title
          icon
        }
        category {
          id
          title
          icon
        }
      }`,
    specificationFields: gql`
      fragment FreeVacanciesSpecification on SpecificationType {
        male
        female
        positionTitle
        address
        ageFrom
        ageTo
        tagassignmentSet {
          tag {
            id
            title
            order
            group {
              id
              key
            }
          }
          comment
        },
        workFunctions
        mandatoryRequirements {
          requirement
        }
        optionalRequirements {
          requirement
        }
        isCompanyInternational
        isCompanyModern
        isLogoPublicAllowed
#        isSalaryPublicAllowed
        isWorkplaceVideoAllowed
        isWorkplaceVisitAllowed
        shiftDuration
        breakDuration
        scheduleType
        scheduleTypes {
          id
          title
        }
        scheduleText
        shiftPattern
        intensity
        allFunctionsFromFirstDay
        onboardingDuration
        minExperienceMonths
        experienceType
        certificates {
          title
        }
        riskFactors {
          title
        }
      }
    `
  }
};


export const FreeVacanciesData = {
  fragments: {
    listFields: gql`
    fragment FreeVacanciesOrdersListFields on OrderType {
      id
      toBeReviewed
      hourRateBruttoPermanent
      salaryInfo
      orderNumber
      orderPeriodFrom
      orderPeriodTo
      isLongTerm
      demand
      status
      trafficGeneration
      nettoFrom
      nettoTo
      shortage
      createdBy {
        user {
          id
          email
          firstName
          lastName
        }
      }
      region {
        id
      }
      specification {
        ...FreeVacanciesSpecification
      }
      company {
        id
        name
        color
      }
      orderperiodSet {
        demand
        dateTo
        dateFrom
        longTerm
      }
      eventSet {
        edges {
          node {
            ...EventsSetFields
          }
        }
      }
    }
    ${SpecificationFV.fragments.specificationFields}
    ${SpecificationFV.fragments.eventsFields}
    `
  }
};

interface DataPaginatedResponse {
  edges: {node: FreeVacanciesTableItem}[];
  pageInfo: PageInfoResponse;
  totalCount: number;
}

@Injectable({providedIn: 'root'})
export class CreateEventGQL extends Mutation<{event: any}> {
  document = gql`
    mutation createEvent($input: EventMutationInput!) {
      event(input: $input) {
        event {
          ...EventsSetFields
        }
      }
    }
    ${SpecificationFV.fragments.eventsFields}
  `;
}

@Injectable({providedIn: 'root'})
export class UpdateEventStatusGQL extends Mutation<{changeEventStatus: any}>{
  document = gql`
    mutation updateEvent($id: ID!, $transition: String!) {
      changeEventStatus(id: $id, transition: $transition) {
        event {
          id
          status
        }
      }
    }
  `;

}

@Injectable({providedIn: 'root'})
export class DeleteEventGQL extends Mutation<{}>{
  document = gql`
    mutation deleteEvent($id: ID!) {
      deleteEvent(id: $id) {
        ok
      }
    }
  `;
}

export interface MetaI {
  id: number;
  title: string;
  icon: string;
}

@Injectable({providedIn: 'root'})
export class PlannerMetadataGQL extends Query<{plannerCategories: MetaI[], plannerChannels: MetaI[]}> {
  document = gql`
    query categories {
      plannerCategories {
        id
        title
        icon
      }
      plannerChannels {
        id
        title
        icon
      }
    }
  `;
}



export interface  PlannerCalendarEvent extends FvPlaningDialogActionItem {
  order: {
    id: string,
    specification: {
      positionTitle: string
    }
    region: {
      title: string
    }
  };
}

@Injectable({providedIn: 'root'})
export class PlannerCalendarEventsGQL extends Query<{plannerEvents: {edges: {node: PlannerCalendarEvent}[]}}> {
  document = gql`
    query calendarEvents {
      plannerEvents {
        edges {
          node {
            order {
              id
              specification{
                positionTitle
              }
            }
            ...EventsSetFields
          }
        }
      }
    }
    ${SpecificationFV.fragments.eventsFields}
  `;
}

export interface OrderPropositionsStats {
  interestedCount: number;
  notInterestedCount: number;
}

@Injectable({providedIn: 'root'})
export class OrderPropositionGQL extends Query<{offerPropositionStats: OrderPropositionsStats}> {
  document = gql`
    query getOrderPropositionStats($orderId: ID) {
      offerPropositionStats(orderId: $orderId) {
        interestedCount
        notInterestedCount
        signed
      }
    }
  `
}


@Injectable({providedIn: 'root'})
export class FreeVacanciesTableGQL extends Query<{ordersPaginated: DataPaginatedResponse}> {
  document = gql`
  query getPaginatedOrder(
          $status: String,
          $first: Int,
          $after: String,
          $search: String,
          $region: String,
          $sex: String,
          $trafficGeneration: String,
          $id: ID
          ) {
    ordersPaginated(
             status: $status,
             first: $first,
             after: $after,
             search: $search,
             region: $region,
             sex: $sex,
             id: $id,
             trafficGeneration: $trafficGeneration,
             orderBy: "company__color"
             ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...FreeVacanciesOrdersListFields
        }
      }
    }
  }
  ${FreeVacanciesData.fragments.listFields}
  `;
}
