<div [ngClass]="{'mat-elevation-z2': mode===orderListViewModes.standalone, 'order-list': true}">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <app-filter-bar fxFlex="100" (filterChanges)="onFilterChanges($event)"
                    [filters]="showSelectionStatusFilter
                      ? [ 'search', 'region', 'statuses', 'orderType', 'keyAccountManager', 'covidPolicy', 'selectionStatuses', 'country']
                      : [ 'search', 'region', 'statuses', 'orderType', 'keyAccountManager', 'covidPolicy', 'country']">
    </app-filter-bar>
    <button mat-stroked-button
            *ngIf="(unseenMessages && !unseenMessagesSectionActive) || unseenMessagesSectionActive "
            [matBadge]="unseenMessages && !unseenMessagesSectionActive ? unseenMessages : ''"
            (click)="showConfirmedRejected()"
            class="new-items-button">
      {{unseenMessagesSectionActive ? 'Užsakymai' : 'Pranešimai'}}
    </button>

  </div>

  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.isLoading" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <table mat-table class="full-width-table" matSort aria-label="Orders">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ID' | translate}}</th>
      <td mat-cell *matCellDef="let order" class="company-color" [ngClass]="order.company?.color">
        <span >{{parseOrderId(order.id)}}</span>
        <img src="/assets/dokobit-favicon.png"
            [matTooltip]="'DOKOBIT_SIGNING_INITIALIZED' | translate"
            width="12" height="12"
            style="margin-left: 8px;" *ngIf="order.lastDocument?.confirmationMethod=='DOKOBIT'">
      </td>
    </ng-container>


    <!-- Comment Column -->
    <ng-container matColumnDef="trafficGeneration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDER.SELECTION_STATUS' | translate }}</th>
      <td mat-cell *matCellDef="let order" (click)="$event.stopPropagation();">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="edit-cell"
          (mouseenter)="showEditTrafficGenerationIcon[order.id] = true"
          (mouseleave) ="showEditTrafficGenerationIcon[order.id] = false">
          <span
            *ngIf="!editFieldMap['trafficGeneration'][order.id]">
            <mat-icon [matTooltip]="trafficGenerationOptionsDisplayMap[order.trafficGeneration] | translate" [ngClass]="{
              'red': [trafficGenerationOptions.inactive, trafficGenerationOptions.marketingOnly].includes(order.trafficGeneration),
              'green': [trafficGenerationOptions.activeNoMarketing, trafficGenerationOptions.activeWithMarketing].includes(order.trafficGeneration)
            }">domain</mat-icon>
            <mat-icon [matTooltip]="trafficGenerationOptionsDisplayMap[order.trafficGeneration] | translate" [ngClass]="{
              'red': [trafficGenerationOptions.inactive, trafficGenerationOptions.activeNoMarketing].includes(order.trafficGeneration),
              'green': [trafficGenerationOptions.marketingOnly, trafficGenerationOptions.activeWithMarketing].includes(order.trafficGeneration)
            }">campaign</mat-icon>
          </span>
          <span *ngIf="!editFieldMap['trafficGeneration'][order.id] && showEditTrafficGenerationIcon[order.id]">
            <mat-icon (click)="$event.stopPropagation(); toggleEditTrafficGeneration(order);">edit</mat-icon>
          </span>

          <mat-select
            *ngIf="editFieldMap['trafficGeneration'][order.id]"
            [formControl]="trafficGenerationCtl[order.id]"
            matInput
            (selectionChange)="changeField(order, 'trafficGeneration', trafficGenerationCtl[order.id].value)"
            name="trafficGeneration" >
            <mat-option [value]="trafficGenerationOptions.inactive">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.DO_NOT_GENERATE_TRAFFIC' | translate}}</mat-option>
            <mat-option [value]="trafficGenerationOptions.activeNoMarketing">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_WITHOUT_MARKETING' | translate}}</mat-option>
            <mat-option [value]="trafficGenerationOptions.activeWithMarketing">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_AND_RUN_MARKETING_CAMPAIGNS' | translate}}</mat-option>
          </mat-select>
        </div>
      </td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMENT' | translate }}</th>
      <td mat-cell *matCellDef="let order" >
        <div fxLayout="row" fxLayoutAlign="start center ">
          <div class="comment" *ngIf="order.ordercommentSet.edges.length > 0">
            {{ order.ordercommentSet.edges[0]?.node.comment }}
          </div>
          <button mat-icon-button (click)="$event.stopPropagation(); addComment(order)">
            <mat-icon inline="true">add_comment</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="orderType">
      <th mat-header-cell *matHeaderCellDef >{{'ORDER.ORDER_TYPE' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <mat-icon
          *ngIf="order.orderType === orderTypes.staffing"
          matTooltip="{{ 'ORDER.ORDER_TYPE_OPTIONS.STAFFING' | translate }}"
        >person_pin</mat-icon>

        <mat-icon
          *ngIf="order.orderType === orderTypes.selection"
          matTooltip="{{ 'ORDER.ORDER_TYPE_OPTIONS.SELECTION' | translate }}"
        >search</mat-icon>

        <mat-icon
          *ngIf="order.orderType === orderTypes.partialSelection"
          matTooltip="{{ 'ORDER.ORDER_TYPE_OPTIONS.PARTIAL_SELECTION' | translate }}"
        >person_outline</mat-icon>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="positionTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="specification__position_title">{{'POSITION' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <div fxLayout="column">
          <a *ngIf="order.company" routerLink="{{order.id}}">
            {{order.specification?.positionTitle}}
          </a>
          <span *ngIf="order.specification && order.specification.address" class="mat-hint">
            <mat-icon inline>location_on</mat-icon> {{order.specification?.address}}
          </span>
        </div>

      </td>
    </ng-container>

    <!-- Company Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="company__name" >{{'COMPANY' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <a *ngIf="order.company" routerLink="/companies/{{order.company.id}}/orders">
          {{order.company.name}}
          <mat-icon inline *ngIf="order.company.labels?.edges.length > 0"
                    [matTooltip]="getCompanyLabelsText(order.company.labels?.edges)"
          >coronavirus</mat-icon>
        </a>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="periodFrom">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="date__from" >{{'PERIOD' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <div *ngIf="order.isLongTerm; else range" [matTooltipPosition]="'above'" [matTooltip]="order.dateFrom | date:'yyyy/MM/dd'">
          <mat-label class="long-term-label">{{'COMMON.FROM' | translate }}:</mat-label> {{order.dateFrom | date:'MM/dd/yy'}}
        </div>
        <ng-template #range>
          <div [matTooltipPosition]="'above'" [matTooltip]="order.dateFrom | date:'yyyy/MM/dd'">
            <mat-label class="long-term-label">{{'COMMON.FROM' | translate }}:</mat-label> {{order.dateFrom | date:'MM/dd/yy'}}<br/>
          </div>
          <div [matTooltipPosition]="'above'" [matTooltip]="order.dateTo | date:'yyyy/MM/dd'">
            <mat-label class="long-term-label">{{'COMMON.TO' | translate }}:</mat-label> {{order.dateTo | date:'MM/dd/yy'}}<br/>
          </div>
<!--          {{order.dateFrom | date:'MM/dd'}} - {{order.dateTo | date:'MM/dd'}}-->
        </ng-template>

      </td>
    </ng-container>

    <!-- Name Column -->
<!--    <ng-container matColumnDef="periods">-->
<!--      <th mat-header-cell *matHeaderCellDef >{{'PERIODS' | translate}}</th>-->
<!--      <td mat-cell *matCellDef="let order">-->
<!--        <div fxLayout="column">-->
<!--          <span *ngFor="let period of order.orderPeriod">-->
<!--            {{}}-->
<!--          </span>-->
<!--        </div>-->
<!--      </td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="shortage" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header="shortage">{{'SHORTAGE' | translate}}</th>
      <td mat-cell *matCellDef="let order" (click)="$event.stopPropagation();">
        <div
          fxLayout="row"
          [ngClass]="{'edit-cell': true, 'shortage-cell': true, 'shortage-cell-border': !editFieldMap['shortage'][order.id]}"
          (mouseenter)="showEditShortageIcon[order.id] = true"
          (mouseleave) ="showEditShortageIcon[order.id] = false">
          <span
            *ngIf="!editFieldMap['shortage'][order.id]">
            {{order.shortage}}
          </span>
          <span class="edit-icon" *ngIf="!editFieldMap['shortage'][order.id] && showEditShortageIcon[order.id]" fxFlex fxLayoutAlign="end end">
            <mat-icon (click)="$event.stopPropagation(); toggleEditShortage(order);">edit</mat-icon>
          </span>

          <mat-form-field *ngIf="editFieldMap['shortage'][order.id]">
            <input
              type="number"
              [formControl]="shortageCtl[order.id]"
              (focusout)="changeField(order, 'shortage', shortageCtl[order.id].value)"
              matInput>
          </mat-form-field>
          <span *ngIf="editFieldMap['shortage'][order.id]" fxFlex fxLayoutAlign="end end">
            <mat-icon>save</mat-icon>
          </span>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="responsible" >
      <th mat-header-cell *matHeaderCellDef >{{'RESPONSIBLE' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <div fxLayout="column">
          <div >PV: {{order.projectManager?.user.firstName}} {{order.projectManager?.user?.lastName[0]}}</div>
          <div >
            AS: {{order.recruiter?.user.firstName}} {{order.recruiter?.user?.lastName[0]}}
            <button mat-icon-button
                    [disableRipple]="true"
                    (click)="$event.stopPropagation(); changeUserprofile(order, 'recruiter');"
                    class="change-responsible"
            >
              <mat-icon inline="true">add_circle_outline</mat-icon>
            </button>
          </div>
<!--          <span fxFlex="33">{{order.demand}}</span>-->

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="hourRate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDER.HOURLY_SALARY' | translate}}</th>
      <td mat-cell *matCellDef="let order">
        <app-salary-cell [salary]="order?.specification?.salary"></app-salary-cell>
<!--        <div  fxLayout="column">-->
<!--          <span *ngIf="order.hourRateBruttoPermanent">{{order.hourRateBruttoPermanent}}</span>-->
<!--          <span *ngIf="order.hourRateBruttoPermanent" class="long-term-label">eur/val</span>-->
<!--        </div>-->
      </td>
    </ng-container>

    <ng-container matColumnDef="specification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SPECIFICATION.SPECIFICATION' | translate}}</th>
      <td mat-cell *matCellDef="let order" >
        <div fxLayout="column">
          <span>{{order.specification?.positionTitle}}</span>
          <span *ngIf="order.specification && order.specification.address" class="mat-hint">
            <mat-icon inline>location_on</mat-icon> {{order.specification?.address}}
          </span>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td mat-cell *matCellDef='let order'>
        <button mat-icon-button *ngIf='order.priority !== priorities.Low'
                [ngClass]='order.priority'
                [matTooltip]='"PRIORITY_HIGH" | translate'
                (click)='$event.stopPropagation(); updateOrderPriority(order, priorities.High)'
        >
          <mat-icon class='priority-icon'>priority_high</mat-icon>
        </button>
        <button mat-icon-button *ngIf='order.priority !== priorities.High'
                [ngClass]='order.priority'
                [matTooltip]='"PRIORITY_LOW" | translate'
                (click)='$event.stopPropagation(); updateOrderPriority(order, priorities.Low)'
        >
          <mat-icon class='priority-icon'>close</mat-icon>
        </button>
    </ng-container>

    <ng-container matColumnDef="lastUpdated">
      <th mat-header-cell *matHeaderCellDef>{{'LAST_UPDATED' | translate}}</th>
      <td mat-cell *matCellDef="let order; let i = index">
        <app-popup-select-complete
          [selected]="[getOrderLastClientContacted(order)?.createdBy || null]"
          [addAvailable]='false'
          [removeAvailable]='false'
        ></app-popup-select-complete>

        <app-custom-date-picker
          [initValue]="getOrderLastClientContacted(order)?.date || ''"
          (valueChange)='onLastUpdatedChange($event, order, i)'
        ></app-custom-date-picker>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">{{'STATUS' | translate}}</th>
      <td mat-cell *matCellDef="let order" >
        <div fxLayout="column" fxLayoutAlign="center center">
          <span class="status {{order.status}}"
              [matTooltip]="('STATUS_EXPLANATION_' + order.status | uppercase) | translate">
            {{order.status.toUpperCase() | translate}}
          </span>


          <a href="{{order.lastDocument?.signingUrl}}" target="_blank" fxLayout="row"
            (click)="$event.stopPropagation();"
            style="text-decoration: none"
            *ngIf="order.status === statuses.PendingSignature && order.lastDocument" >
            <mat-icon inline="true" [class]="order.lastDocument?.isSignedBiuro ? 'green' : 'red'"
                      [matTooltip]="(order.lastDocument?.isSignedBiuro ? 'PM_SIGNATURE_STATUS_DONE' : 'PM_SIGNATURE_STATUS_WAIT') | translate "
            >note_alt</mat-icon>
            <mat-icon inline="true" [class]="order.lastDocument?.isSignedClient ? 'green': 'red '"
                      [matTooltip]="(order.lastDocument?.isSignedClient ? 'CLIENT_SIGNATURE_STATUS_DONE' : 'CLIENT_SIGNATURE_STATUS_WAIT') | translate ">note_alt</mat-icon>
<!--            <mat-icon inline="true">invcentory</mat-icon>-->
          </a>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="stats">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header="shortage">{{'STATS' | translate}}</th>
      <td mat-cell *matCellDef="let order" >
<!--        {{ orderStats[order?.id] | json }}-->
        <div fxLayout="column" style="margin-top: 8px; margin-bottom: 8px" class="stats-data">
          <div  [ngClass]="{'red-text': order.shortage}">
            <span class="stat-label"> {{'SHORTAGE' | translate}}:</span>
            <span style="margin-right: 8px" matTooltip="{{'SHORTAGE' | translate}}">
              {{ order.shortage || 0 }}
            </span>
          </div>

          <div class="interview-item" (click)="openInterviewDialog(order, eventTypes.Interview, $event)" [ngClass]="{'green-text': orderStats[order?.id]?.toBeInterviewed}">
            <span class="stat-label"> {{'INTERVIEW' | translate}}:</span>
            <span style="margin-right: 8px" matTooltip="{{'INTERVIEW' | translate}}">
              {{orderStats[order?.id]?.toBeInterviewed || 0}}
            </span>
          </div>

          <div class="interview-item" (click)="openInterviewDialog(order, eventTypes.ToBePresented, $event)"
              [matTooltip]="getPresentedClientTooltip(order?.id)"
              [ngClass]="{'green-text': orderStats[order?.id]?.presentedClient}">
            <span class="stat-label"> {{'PRESENTED_TO_CLIENT' | translate}}:</span>
            <span style="margin-right: 8px" >
              {{orderStats[order?.id]?.presentedClient || 0}}
            </span>
          </div>

          <div class="interview-item" (click)="openInterviewDialog(order, eventTypes.ClientInterview, $event)" [ngClass]="{'green-text': orderStats[order?.id]?.toBeInterviewedClient}">
            <span class="stat-label"> {{'CLIENT_INTERVIEW' | translate}}:</span>
            <span style="margin-right: 8px" matTooltip="{{'CLIENT_INTERVIEW' | translate}}">
              {{orderStats[order?.id]?.toBeInterviewedClient || 0}}
            </span>
          </div>

          <div class='interview-item'
               (click)='openInterviewDialogForOrdersWithinLast7Days(order, eventTypes.Interview, $event)'
               [ngClass]="{'green-text': orderStats[order?.id]?.offeredLast7Days}">
            <span class='stat-label'> {{'OFFERED_LAST_7_DAYS' | translate}}:</span>
            <span style='margin-right: 8px' matTooltip="{{'OFFERED_LAST_7_DAYS_TOOLTIP' | translate: {last_7days: orderStats[order?.id]?.offeredLast7Days || 0, last_workday: orderStats[order?.id]?.offeredLast1Day || 0} }}">
              {{orderStats[order?.id]?.offeredLast7Days || 0}}/{{orderStats[order?.id]?.offeredLast1Day || 0}}
            </span>
          </div>

          <div
            class='interview-item'
            matTooltipClass="tooltip-multiline"
            matTooltip="{{
              ('LONG_LISTED_LAST_7_DAYS_HELP' | translate) + '\n\n' +
              ('LONG_LISTED_LAST_7_DAYS_TOOLTIP' | translate: { last_7days: orderStats[order?.id]?.longListedLast7Days || 0, last_workday: orderStats[order?.id]?.longListedLast1Day || 0 })
            }}"
            (click)='openInterviewDialogForOrdersWithinLast7Days(order, eventTypes.Interview, $event)'
            [ngClass]="{'green-text': orderStats[order?.id]?.longListedLast7Days}">
            <span class='stat-label'> {{'LONG_LISTED_LAST_7_DAYS' | translate}}:</span>
            <span style='margin-right: 8px'>
              {{orderStats[order?.id]?.longListedLast7Days || 0}}/{{orderStats[order?.id]?.longListedLast1Day || 0}}
            </span>
          </div>

          <div class='interview-item' (click)='openInterviewDialog(order, eventTypes.Employment, $event)'
               [ngClass]="{'green-text': orderStats[order?.id]?.toBeEmployed}">
            <span class='stat-label'> {{'EMPLOYMENT' | translate}}:</span>
            <span style='margin-right: 8px' matTooltip="{{'EMPLOYMENT' | translate}}">
              {{orderStats[order?.id]?.toBeEmployed || 0}}
            </span>
          </div>

          <div class='interview-item' (click)='openInterviewDialog(order, eventTypes.HealthCheckPending, $event)'
               [ngClass]="{'orange-text': orderStats[order?.id]?.healthCheckPending}">
            <span class='stat-label'> {{'HEALTH_CHECK_PENDING' | translate}}:</span>
            <span style="margin-right: 8px" matTooltip="{{'HEALTH_CHECK_PENDING' | translate}}">
              {{orderStats[order?.id]?.healthCheckPending || 0}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="alert">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let order">
        <div class="alert">
          <button
            *ngIf="isOrderMetricsWarning(order)"
            mat-icon-button
            matTooltipPosition="above"
            [matTooltip]="'CLICK_TO_SHOW_MORE' | translate"
            (click)="$event.stopPropagation(); showOrderMetrics($event, order)"
          >
            <mat-icon>report</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ACTIONS' | translate}}</th>
      <td mat-cell *matCellDef='let order'>
        <button mat-icon-button
                (click)='$event.stopPropagation(); orderActionLogService.openChangeLogDialog(order.id, order.company.country.id);'>
          <mat-icon>list</mat-icon>
        </button>

        <button mat-icon-button
                (click)='$event.stopPropagation(); downloadOrder(order.id, order.specification?.positionTitle)'
                *ngIf='order.status === statuses.Sent'>
          <mat-icon>cloud_download</mat-icon>
        </button>

        <!-- <button mat-icon-button (click)="$event.stopPropagation(); sendOrder(order)" *ngIf="order.status === statuses.sent">
          <mat-icon>send</mat-icon>
        </button> -->
        <span [matTooltip]="((!order.clientContact || !order.contract) ? 'ORDER.DISABLE_SEND_TOOLTIP' : '') | translate">
<!--          <button *ngIf="order.status === statuses.New"-->
<!--                  (click)="$event.stopPropagation()"-->
<!--                  [matMenuTriggerFor]="sendingOptions"-->
<!--                  [disabled]="!order.clientContact || !order.contract"-->
<!--                  mat-icon-button>-->
<!--            <mat-icon>send</mat-icon>-->
<!--          </button>-->

          <app-order-document-create-btn *ngIf="order.status === statuses.New"
                                         [disabled]="!order.clientContact || !order.contract"
                                         [orderId]="order.id" [orderDate]="order.orderDate">
          </app-order-document-create-btn>
        </span>
        <button *ngIf="order.status === statuses.Proposal"
                (click)="$event.stopPropagation(); convertToNew(order)"
                matTooltip="CONVERT_TO_NEW"
                mat-icon-button>
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>

        <mat-menu #sendingOptions="matMenu">
          <button mat-menu-item
                  (click)="downloadOrder(order.id, order.specification?.positionTitle); markOrderAsSent(order)">
            <mat-icon>cloud_download</mat-icon>{{'DOWNLOAD_PDF' | translate}}
          </button>

          <button mat-menu-item
                  (click)="uploadForSigning(order);">
            <img src="/assets/dokobit-favicon.png" width="24" height="24" style="padding-top: 12px; margin-right: 16px">
            <span style="vertical-align: top">{{'ELECTRONIC_SIGN_PDF' | translate}}</span>
          </button>


          <button mat-menu-item (click)="sendOrder(order)">
            {{'SEND_EMAIL_FOR_CONFIRMATION' | translate}}
          </button>
        </mat-menu>

        <button mat-icon-button (click)="$event.stopPropagation(); confirmOrder(order)" *ngIf="order.status === statuses.Sent">
          <mat-icon>check</mat-icon>
        </button>

        <button mat-icon-button (click)="$event.stopPropagation(); completeOrder(order)"
                matTooltip="{{'MARK_COMPLETED' | translate}}"
                *ngIf="[statuses.Filled].indexOf(order.status) > -1">
          <mat-icon>done_all</mat-icon>
        </button>

        <button mat-icon-button (click)="$event.stopPropagation(); makeOrderFilled(order)"
                matTooltip="{{'MARK_FILLED' | translate}}"
                *ngIf="[statuses.Confirmed].indexOf(order.status) > -1">
          <mat-icon>done_all</mat-icon>
        </button>

        <button mat-icon-button (click)="$event.stopPropagation(); restoreOrder(order)"
                *ngIf="order.status !== statuses.New  && order.status !== statuses.Proposal">
          <mat-icon svgIcon="restore" matTooltip="{{'RESTORE' | translate}}"></mat-icon>
        </button>

        <button mat-icon-button (click)="$event.stopPropagation(); cancelOrder(order)"
                *ngIf="order.status !== statuses.Rejected && order.status!== statuses.Cancelled"
                color="background"
        >
          <mat-icon matTooltip="{{'CANCEL' | translate}}">cancel</mat-icon>
        </button>

        <button mat-icon-button (click)="$event.stopPropagation(); makeOrderLost(order)"
                *ngIf="[statuses.Confirmed].indexOf(order.status) > -1">
          <mat-icon matTooltip="{{'MARK_LOST' | translate}}">block</mat-icon>

        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" (click)="openNew(row.id)"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.totalCount"
      [pageIndex]="0"
      [pageSize]="50"
      [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
