<div *ngIf="!hide" class="user-group">
  <div
    *ngFor="let profile of selectedProfiles; let index = index"
    class="avatar"
    [class.large-space]="largeSpace"
    [matTooltip]="profile.fullName || (profile.user?.firstName + ' ' + profile.user?.lastName)"
    [ngStyle]="{'margin-left': index > 0 ? (largeSpace ? '-8px' : '-20px'): 0, 'z-index': 100 - index, 'background-color': profile.profileImageUrl ? 'transparent' : profile?.color}"
  >
    <span *ngIf="!avatarsByUserId[profile.id]">{{getAvatarLetters(profile.fullName || profile.user?.firstName)}}</span>
    <img *ngIf="avatarsByUserId[profile.id]" class="avatar-img" [src]="avatarsByUserId[profile.id]" alt="user-avatar"/>
    <mat-icon *ngIf="removeAvailable" class="remove-avatar" (click)="removeProfile(profile)">close</mat-icon>
  </div>

  <button *ngIf="addAvailable" mat-icon-button class="add-new-person" [disabled]='disabled' [matMenuTriggerFor]="menu">
    <mat-icon>person_add</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <mat-form-field class="search-input">
    <mat-label>{{'SEARCH' | translate}}</mat-label>
    <mat-icon matPrefix inline>search</mat-icon>
    <input matInput [(ngModel)]="search"  (ngModelChange)="onModelChange($event)" (click)="$event.stopPropagation()">
  </mat-form-field>

  <div style="overflow: scroll; max-height: 200px">
    <div *ngFor="let profile of filteredProfiles" class="name-row" (click)="addProfile(profile)">
      <div class="avatar" [ngStyle]="{'background-color': profile.profileImageUrl ? 'transparent' : profile?.color}">
        <span *ngIf="!avatarsByUserId[profile.id]">{{getAvatarLetters(profile.user?.firstName)}}{{getAvatarLetters(profile.user?.lastName)}}</span>
        <img *ngIf="avatarsByUserId[profile.id]" class="avatar-img" [src]="avatarsByUserId[profile.id]" alt="user-avatar"/>
      </div>

      <span class="name">{{profile.user?.firstName}} {{profile.user?.lastName}}</span>
    </div>
  </div>
</mat-menu>
