<div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px">
  <app-filter-bar fxFlex="100" (filterChanges)="onFilterChanges($event)"
                  [initialValue]="initialFilter"
                  [filters]="filters"
  >

  </app-filter-bar>
<!--  <app-free-vacancies-filter (changed)="onLocalFilterChange($event)"></app-free-vacancies-filter>-->
</div>
<div class="mat-elevation-z2" class="table-container">
  <mat-progress-bar *ngIf="dataSource.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  <table mat-table class="full-width-table" matSort aria-label="Elements" multiTemplateDataRows>
    <!-- Id Column -->
    <ng-container matColumnDef='id'>
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef='let row'>
        {{row.id}}
      </td>

    </ng-container>

     <ng-container matColumnDef="position" sticky>
      <th mat-header-cell *matHeaderCellDef  > {{'ORDER.ORDER' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="position-name"
          [ngClass]="[mode === FVMode.marketing && getIfOrderHasNoActions(row.eventSet.edges) ? 'noActions' : '', row.company.color]">
        <div fxLayout="row" fxLayoutAlign="space-between center" >
          <div fxLayout="column">

            <div *ngIf="row.trafficGeneration != 'MARKETING_ONLY'; then regularRow else workisRow"></div>
            <ng-template #regularRow>
              <span>
                <span>{{row.specification?.positionTitle || '-'}}</span>
                <img *ngIf='row.specification?.canEmployUa' class='flag' src='assets/flags/ua.svg' alt='UA'>
              </span>
              <span class='company-title'>
                {{row.company?.name || '-'}}
                <mat-icon inline *ngIf='row.company.labels?.edges.length > 0'
                          [matTooltip]='getCompanyLabelsText(row.company.labels?.edges)'
                >coronavirus</mat-icon>
              </span>
              <span class='adress-title'>
                <a href='https://www.google.com/maps/search/?api=1&query={{row.specification?.address}}'
                   target='_blank'>
                  {{row.specification?.address || '-'}}
                </a>
              </span>
            </ng-template>
            <ng-template #workisRow>
              <img src="/assets/workislogo.png" class="workis-logo" />
              <span class="workis-region">{{row.region.title}}</span>
            </ng-template>
          </div>
          <a href="{{getAppPrefix()}}/orders/edit/{{getRawId(row.id)}}" target="_blank" class='edit-btn-wrapper' (click)='$event.stopPropagation()'>
            <mat-icon class="edit-btn">edit</mat-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.padding-left]='"12px"'>{{ 'ACTIONS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div class="order-icons" fxLayout='row' fxLayoutAlign='center center'>
          <div style="width: 24px; display: inline-block">
            <mat-icon (click)="$event.stopPropagation(); openChangeLogDialog(row, $event)"
                      matTooltip="Yra pakeitimų, reikia peržiūrėti"
                      class="order-extra-info-icon"
                      inline="true"
                      [fxHide]='!row.toBeReviewed'>warning
            </mat-icon>
          </div>

          <mat-icon svgIcon="{{row.isLogoPublicAllowed ? 'eye' : 'eye-off'}}"
                    matTooltip="{{row.isLogoPublicAllowed ? 'Užsakovą galima skelbti viešai' : 'Užsakovo negalima skelbti viešai'}}"
                    class='order-extra-info-icon'
                    (click)='$event.stopPropagation()'
                    [class.can-show]='row.isLogoPublicAllowed'>
          </mat-icon>

          <!--             button to create AI based job ad -->
          <button mat-icon-button (click)='$event.stopPropagation(); openJobAdDialog(row)' *ngIf='mode === FVMode.marketing'>
            <mat-icon inline>note_add</mat-icon>
          </button>

        </div>
      </td>
    </ng-container>

     <!-- <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="column">
          <span>{{row.specification?.address || '-'}}</span>
        </div>

      </td>
     </ng-container> -->

    <ng-container matColumnDef="salary">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SPECIFICATION.BRUTTO_HOURLY' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span>{{row.hourRateBruttoPermanent}} €/val</span>
          <mat-icon class="salary-info" *ngIf="row.salaryInfo"
                    [inline]="true"
                    [matTooltip]="row.salaryInfo">info</mat-icon>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
              <mat-icon svgIcon="file-outline" matTooltip="Brutto"></mat-icon>
              <div fxLayout="column">
                <span class="hourly-rate">{{row.hourRateBruttoPermanent}} €/val</span>
                <span>~{{row.hourRateBruttoPermanent * 168 | number:'1.0-2'}} €/mėn</span>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
              <mat-icon svgIcon="currency-eur" matTooltip="Netto"></mat-icon>
              <div fxLayout="column">
                <span>~{{row.hourRateBruttoPermanent * 168 * .604 | number:'1.0-2'}} €/mėn</span>
              </div>
            </div>
          </div>
        </div> -->

      </td>
     </ng-container>

     <ng-container matColumnDef="netto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Netto</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <app-salary-info [salaryInfo]="row?.specification?.salary" [mode]="'short'"></app-salary-info>
        </div>
      </td>
     </ng-container>

     <ng-template #noNetto>Nenurodytas</ng-template>

    <ng-container matColumnDef="functions">
      <th mat-header-cell *matHeaderCellDef >{{'FUNCTIONS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="column">
          <p style="white-space: pre-wrap; display: block; unicode-bidi: embed "
             [innerHTML]="urlify(row.specification?.workFunctions) || '-'">


          </p>
        </div>

      </td>
    </ng-container>


    <ng-container *ngFor="let col of datesColumns" matColumnDef="{{col.date}}">
      <th mat-header-cell *matHeaderCellDef
          [class.today]="col.isToday"
          [class.saturday]="col.isSaturday"
          [class.sunday]="col.isSunday"
          class="day-column">
        {{col.date.substr(5).replace('-', '.')}}
      </th>

      <td mat-cell *matCellDef="let row"
          class="day-column"
          (click)="openEventsDialog(row, col.date); $event.stopImmediatePropagation()"
          [class.today]="col.isToday"
          [class.saturday]="col.isSaturday"
          [class.sunday]="col.isSunday">

        <ng-container
          *ngIf="getAction(row.eventSet.edges, col.date); let action"
        >
            <mat-icon *ngIf="action.length==1 && action[0]?.category?.icon; else multipleAction"
                      matTooltip="{{action[0].channelsString}}"
                      ngClass="{{action[0].status}}">
              {{action[0]?.category?.icon}}
            </mat-icon>

          <ng-template #multipleAction>
            <div
            class="multiple-actions"
            matTooltip="Keli kanalai"
          >
            {{action.length}}
          </div>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="requirements">
      <th mat-header-cell *matHeaderCellDef>{{ 'ORDER.REQUIREMENTS' | translate}}</th>
      <td mat-cell *matCellDef="let row">

        <div fxLayout="column">
          <app-candidate-requirements *ngIf="row.specification?.mandatoryRequirements?.length > 0; else noRequirements"
                                      [initialize]="sliceRequirements(row.specification?.mandatoryRequirements)"
                                      [readMoreCount]="row.specification?.mandatoryRequirements.length - 3 || 0"
                                      [readonly]="true"
                                      fxFlex="100">
          </app-candidate-requirements>
          <ng-template #noRequirements>
            <span>{{'NO_REQUIREMENTS_SPECIFIED'| translate}}</span>
          </ng-template>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="clientContact" sticky>
      <th mat-header-cell *matHeaderCellDef>{{'CONTACT' |translate}}</th>
      <td mat-cell *matCellDef="let row">
        <app-contact-bubble [contact]="c.node" *ngFor="let c of row.additionalClientContacts.edges"></app-contact-bubble>
      </td>
     </ng-container>



    <ng-container matColumnDef="trafficGeneration" sticky>
     <th mat-header-cell *matHeaderCellDef>{{'BRIEFING' | translate}}</th>
     <td mat-cell *matCellDef="let row">
       <app-marketing-icons [order]="row"></app-marketing-icons>

     </td>
    </ng-container>

    <ng-container matColumnDef="demand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'DEMAND' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="column">
          <span class="demand">{{row.demand || '0'}}</span>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="shortage" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SHORTAGE' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="column" fxLayoutAlign="center center">
          <span class="demand">{{row.shortage || '0'}}</span>
        </div>

      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail" sticky>
        <td mat-cell *matCellDef="let row" [attr.colspan]="calculateColSpan()">

          <div class="extra-info-detail {{mode === FVMode.marketing ? 'marketing' : ''}}"
               [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">

          <app-free-vacancies-detail *ngIf="row === expandedElement"
                                       [order]="row"
                                       [marketingData]="mode === FVMode.marketing"
                                       class="{{mode === FVMode.marketing ? 'marketing' : ''}}">
            </app-free-vacancies-detail>
          </div>

        </td>
      </ng-container>

    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon>more_vert</mat-icon>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
    </tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="expandedElement = expandedElement === row ? null : row"
        class="table-row {{expandedElement === row ? 'expanded' : ''}}">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="extra-info-row"
        class="extra-element-row"
        [class.extra-expanded-row]="expandedElement === row"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.totalCount"
      [pageIndex]="0"
      [pageSize]="20"
      [pageSizeOptions]="[10, 20, 50, 100]">
  </mat-paginator>
</div>
