import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CandidateRequirementsComponent} from './candidate-requirements/candidate-requirements.component';
import {CertificatesTableComponent} from './certificates-table/certificates-table.component';
import {PPEIssuanceTableComponent} from './ppe-issuance-table/ppe-issuance-table.component';
import {RiskFactorsTableComponent} from './risk-factors-table/risk-factors-table.component';
import {ScheduleSetupComponent} from './schedule-setup/schedule-setup.component';
import {SpecificationEditComponent} from './specification-edit/specification-edit.component';
import {SpecificationNameDialogComponent} from './specification-name-dialog/specification-name-dialog.component';
import {SpecificationsListComponent} from './specifications-list/specifications-list.component';
import {TagAssignmentTableItem} from './tag-assignment-table/tag-assignment-table-datasource';
import {TagAssignmentTableComponent} from './tag-assignment-table/tag-assignment-table.component';
import {ScheduleShiftElementComponent} from './schedule-setup/schedule-shift-element/schedule-shift-element.component';
import {ScheduleVisualElementComponent} from './schedule-setup/schedule-visual-element/schedule-visual-element.component';
import {AppMaterialModule} from '../app-material/app-material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import { FormsModule} from '@angular/forms';
import { AddRequirementDialogComponent } from './add-requirement-dialog/add-requirement-dialog.component';
import {SpecificationsRoutingModule} from './specifications-routing.module';
import {SpecificationsListGQL} from './specifications-list/graphql';
import { SpecificationCreateComponent } from './specification-create/specification-create.component';
import { SharedModule } from '../shared/shared.module';
import { AgeRestrictionComponent } from './specification-edit/age-restriction/age-restriction.component';
import { UnfilledFieldIndicatorComponent } from './specification-edit/unfilled-field-indicator/unfilled-field-indicator.component';
import {I18nModule} from '../shared/i18n.module';
import { ScheduleWidgetComponent } from './schedule-widget/schedule-widget.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CandidateRequirementsComponent,
    CertificatesTableComponent,
    PPEIssuanceTableComponent,
    RiskFactorsTableComponent,
    ScheduleSetupComponent,
    SpecificationEditComponent,
    SpecificationNameDialogComponent,
    SpecificationsListComponent,
    TagAssignmentTableComponent,
    ScheduleShiftElementComponent,
    ScheduleVisualElementComponent,
    AddRequirementDialogComponent,
    SpecificationCreateComponent,
    AgeRestrictionComponent,
    UnfilledFieldIndicatorComponent,
    ScheduleWidgetComponent,



  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    SpecificationsRoutingModule,
    SharedModule,
    I18nModule,
    MatSlideToggleModule,
    TranslateModule
  ],
    exports: [
        CandidateRequirementsComponent,
        CertificatesTableComponent,
        PPEIssuanceTableComponent,
        RiskFactorsTableComponent,
        ScheduleSetupComponent,
        SpecificationEditComponent,
        SpecificationNameDialogComponent,
        SpecificationsListComponent,
        TagAssignmentTableComponent,
        ScheduleShiftElementComponent,
        ScheduleVisualElementComponent,
        SpecificationCreateComponent,
        UnfilledFieldIndicatorComponent,
        AgeRestrictionComponent,
        ScheduleWidgetComponent,
    ],
  entryComponents: [AddRequirementDialogComponent],
  providers: [SpecificationsListGQL]
})
export class SpecificationsModule { }
