<div class="dialog-wrapper">
  <div class="head" fxLayout fxLayoutAlign="space-between center">
    <h4>{{ 'ERROR_REPORTS' | translate }}</h4>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>

  <div class="content">
    <div class="crate-new" fxLayout="column" [formGroup]="errorReportForm">
      <mat-form-field>
        <mat-label>{{ 'CATEGORY' | translate }}</mat-label>
        <mat-select formControlName="categoryId">
          <mat-option *ngFor="let category of errorCategories" [value]="category.id">
            {{ category.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'COMMENT' | translate }}</mat-label>
        <textarea
          type="text"
          matInput
          formControlName="comment"
        ></textarea>
      </mat-form-field>

      <button mat-flat-button color="primary" [disabled]="!errorReportForm.valid" (click)="createReport()">
        {{ 'CONFIRM' | translate }}
      </button>
    </div>

    <div class="errors-wrapper" fxLayout="column" fxLayoutGap="8px">
      <div *ngFor="let error of data.orderErrors" class="error-item">
        <div> {{ error.comment }} </div>
        <div class="errors-wrapper-second-line"> category: {{ error.category.title }}, date: {{ moment(error.createdAt).format('YYYY.MM.DD') }} </div>
      </div>
    </div>
  </div>
</div>
