<div fxLayout="row" fxLayoutGap="8px">
  <mat-form-field appearance="{{appearance || 'standard' }}" fxFlex="120px">
    <mat-label >{{'COMMON.FROM' | translate}}</mat-label>
    <input matInput [(ngModel)]="ageFrom" (ngModelChange)="onAgeRestrictionChanges()">
  </mat-form-field>

  <mat-form-field appearance="{{appearance || 'standard' }}" fxFlex="120px">
    <mat-label>{{'COMMON.TO' | translate}}</mat-label>
    <input matInput [(ngModel)]="ageTo"  (ngModelChange)="onAgeRestrictionChanges()">
  </mat-form-field>
</div>
<!--  <mat-select [(ngModel)]="item.type">-->
<!--    <mat-option value="0" [disabled]="restrictionDisabled('0')">Nuo</mat-option>-->
<!--    <mat-option value="1" [disabled]="restrictionDisabled('1')">Iki</mat-option>-->
<!--  </mat-select>-->
<!--  </mat-form-field>-->
<!--  <div>-->
<!--    <mat-form-field>-->
<!--      <mat-label>Amžius (metais)</mat-label>-->
<!--      <input matInput type="number" (input)="onInput(item)" [(ngModel)]="item.value">-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <button mat-icon-button (click)="removeRestriction(item)">-->
<!--    <mat-icon svgIcon="close"></mat-icon>-->
<!--  </button>-->
<!--</div>-->
<!--<button mat-stroked-button (click)="addRestriction()" [disabled]="ageRestriction.length === 2">-->
<!--  <div fxLayout="row" fxLayoutAlign="start center">-->
<!--    <mat-icon svgIcon="plus"></mat-icon>-->
<!--    <span>Pridėti apribojimą</span>-->
<!--  </div>-->
<!--</button>-->
