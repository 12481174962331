import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {InputValueStatus, SalaryMatrixValues, SalaryRangeChangeEvent, SalaryRangeValues, SalaryTypes} from './salary-matrix-values';
import { FormControl } from '@angular/forms';
import { BaseOrderFieldsFragment, SpecificationSalaryInput, SpecificationSalaryPeriodEnum, SpecificationSalaryType, SpecificationSalaryUseInTemplate } from '../../../../generated/graphql';
import { Subscription } from 'rxjs';
import {SalaryCalculatorService} from '../salary-matric/salary-calculator.service';
export interface SalaryMatrixValue {
  hourlyBrutto: SalaryRangeValues;
  hourlyNetto: SalaryRangeValues;
  monthlyBrutto: SalaryRangeValues;
  monthlyNetto: SalaryRangeValues;
  valueForDocument: SpecificationSalaryPeriodEnum;
}
@Component({
  selector: 'app-salary-matrix',
  templateUrl: './salary-matrix.component.html',
  styleUrls: ['./salary-matrix.component.scss']
})
export class SalaryMatrixComponent implements OnInit , OnDestroy {
  orderSub: Subscription;

  periods = SpecificationSalaryPeriodEnum;
  valueForDocument = this.periods.Hourly;

  salaryTypes = SalaryTypes;
  matrixValues = new SalaryMatrixValues(this.salaryCalculatorSerivce);

  hourlyBrutto: SalaryRangeValues;
  hourlyNetto: SalaryRangeValues;
  monthlyBrutto: SalaryRangeValues;
  monthlyNetto: SalaryRangeValues;

  private $salaryMatrix: FormControl;
  @Input() set salaryMatrix(control: FormControl) {
    this.$salaryMatrix = control;
    this.initMatrixValues(control.value);
  }
  get salaryMatrix(){
    return this.$salaryMatrix;
  }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    this.initMatrixValues(val?.specification?.salary);
    this.setControlValues();
  };

  countryISO$: string;
  @Input()
  set countryISO(value: string) {
    if (value && value != SalaryCalculatorService.countryISO) {
      this.countryISO$ = value;
      SalaryCalculatorService.countryISO = value;
      // this.initMatrixValues(this?.$order?.specification?.salary);
      // this.setControlValues();
    }
  }

  get order(){
    return this.$order;
  }

  constructor(
    private salaryCalculatorSerivce: SalaryCalculatorService
  ) { }

  ngOnInit(): void {
    this.setLocalValues();
  }

  initMatrixValues(val) {
    this.matrixValues = new SalaryMatrixValues(
      this.salaryCalculatorSerivce,
      val?.hourlyBruttoFrom,
      val?.hourlyBruttoTo,
      val?.hourlyNettoFrom,
      val?.hourlyNettoTo,
      val?.monthlyBruttoFrom,
      val?.monthlyBruttoTo,
      val?.monthlyNettoFrom,
      val?.monthlyNettoTo,
      val?.hourlyBruttoFromFilled,
      val?.hourlyBruttoToFilled,
      val?.hourlyNettoFromFilled,
      val?.hourlyNettoToFilled,
      val?.monthlyBruttoFromFilled,
      val?.monthlyBruttoToFilled,
      val?.monthlyNettoFromFilled,
      val?.monthlyNettoToFilled
    );
    this.valueForDocument = (val?.useInTemplate === SpecificationSalaryUseInTemplate.Hourly) ?
    this.periods.Hourly :
    this.periods.Monthly;
    this.setLocalValues();
  }

  setLocalValues() {
    this.hourlyBrutto = this.matrixValues.getInputValues(this.salaryTypes.hourlyBruto);
    this.hourlyNetto = this.matrixValues.getInputValues(this.salaryTypes.hourlyNetto);
    this.monthlyBrutto = this.matrixValues.getInputValues(this.salaryTypes.monthlyBruto);
    this.monthlyNetto = this.matrixValues.getInputValues(this.salaryTypes.monthlyNetto);
  }

  getValues(): SalaryMatrixValue {
    return {
      hourlyBrutto: this.matrixValues.getInputValues(this.salaryTypes.hourlyBruto),
      hourlyNetto: this.matrixValues.getInputValues(this.salaryTypes.hourlyNetto),
      monthlyBrutto: this.matrixValues.getInputValues(this.salaryTypes.monthlyBruto),
      monthlyNetto: this.matrixValues.getInputValues(this.salaryTypes.monthlyNetto),
      valueForDocument: this.valueForDocument
    };
  }

  onInputValueChange($event: SalaryRangeChangeEvent, salaryType: SalaryTypes) {
    this.matrixValues.updateValues($event.lastChangedValue, true, salaryType, $event.lastChangedType);
    this.setControlValues();
  }

  setControlValues() {
    this.setLocalValues();
    const matrix = this.getValues();
    this.salaryMatrix.patchValue(this.getSalaryMatrixData(matrix));
    this.salaryMatrix.updateValueAndValidity();
    this.setInputStatus();
  }

  setInputStatus() {
    if(!this.salaryMatrix.hasError('requiredBrutto') && this.salaryMatrix.hasError('requiredBruttoForUseInDoc')) {
      if(this.valueForDocument === SpecificationSalaryPeriodEnum.Hourly) {
        this.hourlyBrutto.fromEdited = InputValueStatus.error;
        this.hourlyBrutto.toEdited = InputValueStatus.error;
      }
      if(this.valueForDocument === SpecificationSalaryPeriodEnum.Monthly) {
        this.monthlyBrutto.fromEdited = InputValueStatus.error;
        this.monthlyBrutto.toEdited = InputValueStatus.error;
      }
    }
  }

  getSalaryMatrixData(matrix): SpecificationSalaryInput{
    return {
      hourlyBruttoFrom: matrix.hourlyBrutto.fromValue,
      hourlyBruttoTo: matrix.hourlyBrutto.toValue,
      hourlyNettoFrom: matrix.hourlyNetto.fromValue,
      hourlyNettoTo: matrix.hourlyNetto.toValue,
      monthlyBruttoFrom: matrix.monthlyBrutto.fromValue,
      monthlyBruttoTo: matrix.monthlyBrutto.toValue,
      monthlyNettoFrom: matrix.monthlyNetto.fromValue,
      monthlyNettoTo: matrix.monthlyNetto.toValue,

      hourlyBruttoFromFilled: !!matrix?.hourlyBrutto?.fromEdited,
      hourlyBruttoToFilled: !!matrix?.hourlyBrutto?.toEdited,
      hourlyNettoFromFilled: !!matrix?.hourlyNetto?.fromEdited,
      hourlyNettoToFilled: !!matrix?.hourlyNetto?.toEdited,
      monthlyBruttoFromFilled: !!matrix?.monthlyBrutto?.fromEdited,
      monthlyBruttoToFilled: !!matrix?.monthlyBrutto?.toEdited,
      monthlyNettoFromFilled: !!matrix?.monthlyNetto?.fromEdited,
      monthlyNettoToFilled: !!matrix?.monthlyNetto?.toEdited,

      useInTemplate: matrix?.valueForDocument
    };
  }

  ngOnDestroy(): void {
    if (this.orderSub){
      this.orderSub?.unsubscribe?.();
    }
    SalaryCalculatorService.countryISO = '';
  }
}
