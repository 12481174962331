<div class="alert-container" *ngIf="showAlert">
  <span class="content" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!(isHandset$ | async)">
    <span>{{'REMINDER_RECONCILIATION_OF_BAILIFFS_LETTERS' | translate}}</span>
    <button mat-stroked-button (click)="onNotificationDismiss()">
      Atlikta
    </button>
  </span>
</div>

<mat-toolbar *ngIf="showToolbar$ | async" color="primary">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="toggleSidenav()"
    *ngIf="isHandset$ | async"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div
    fxLayout="row"
    fxFlex.gt-sm="124px"
    fxLayoutAlign="center center"
    class="mat-typography title"
    fxLayoutGap="12px"
  >
    <h4 style="margin: 0 auto">BORIS</h4>
    <!--    <mat-hint style="font-size: 8px; line-height: 8px">Biuro Order Registration Information System</mat-hint>-->
  </div>
  <div class="info" *ngIf="toolbarText" [innerHTML]="toolbarText"></div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">

  <mat-sidenav
    #sidenav
    class="sidenav"
    *ngIf="showToolbar$ | async"
    fixedInViewport
    [fixedTopGap]="(isHandset$ | async) ? 56 : 64"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="isOpened$ | async"
  >

    <mat-nav-list>
      <a mat-list-item routerLink="/orders/create">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">add</mat-icon>{{ 'NEW_ORDER' | translate }}
        </div>
      </a>

      <a mat-list-item routerLink="/orders/list">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">euro</mat-icon>
          <span>{{'ORDERS' | translate}}</span>
          <div
            *ngIf="unseenMessages"
            class="unseen-indicator"
            [matTooltip]="'Yra neperskaitytų pranešimų'"
          ></div>
        </div>
      </a>

      <a mat-list-item routerLink="/specifications/list">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">list_alt</mat-icon>{{'SPECIFICATION.SPECIFICATION' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/companies">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">domain</mat-icon>{{'COMPANIES' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/dashboard">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">access_time</mat-icon>{{'STANDBY' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/standups">
        <div fxLayoutAlign="start center">
          <mat-icon svgIcon='groups-icon' inline="true"></mat-icon>{{'STANDUP_DASHBOARD' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/free-vacancies/list">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">filter_list</mat-icon>{{'FREE_VACANCIES.TITLE' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/pm-dashboard">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">show_chart</mat-icon>{{'STATISTICS' | translate}}
        </div>
      </a>

      <a *ngIf="env === 'local'" mat-list-item routerLink="/addons-import">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">import_export</mat-icon>{{'ADDON_IMPORT' | translate}}
        </div>
      </a>

      <a *ngIf="env === 'local'" mat-list-item routerLink="/absences/calendar">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">date_range</mat-icon>{{'ABSENCE_CALENDER' | translate}}
        </div>
      </a>

      <a *ngIf="env === 'local'" mat-list-item routerLink="/absences/import">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">import_export</mat-icon>{{'ABSENCE_IMPORT' | translate}}
        </div>
      </a>

      <a *ngIf="env === 'local'" mat-list-item routerLink="/absences/revision">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">import_export</mat-icon>{{'ABSENCE_REVISION' | translate}}
        </div>
      </a>

      <a mat-list-item routerLink="/planner">
        <div fxLayoutAlign="start center">
          <mat-icon inline="true">task_alt</mat-icon>{{'TASKS' | translate}}
        </div>
      </a>

<!--      <a mat-list-item routerLink="/candidates">-->
<!--        <div fxLayoutAlign="start center">-->
<!--          <mat-icon inline="true">people_outline</mat-icon>{{'CANDIDATES_SECTION.CANDIDATES' | translate}}-->
<!--        </div>-->
<!--      </a>-->

    </mat-nav-list>

    <footer>
      <div class="flag-container">
        <img [ngStyle]="{filter: compareLanguage(item.name) ? 'drop-shadow(0 0 0.2rem black)' : null}"
             *ngFor="let item of languages" class="flag" [src]="item.path" alt="" (click)="changeLanguage(item.name)">
      </div>
    </footer>

  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
