import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-lister-dialog',
  templateUrl: './lister-dialog.component.html',
  styleUrls: ['./lister-dialog.component.scss']
})
export class ListerDialogComponent implements OnInit {

  title: string;
  list: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {title: string, list: string[]}
  ) {
    this.title = data.title;
    this.list = data.list;
  }

  ngOnInit() {
  }

}
