import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import moment from 'moment';
import { Addon, IAddonResponse } from './addon.class';
import { Client, IClientResponse } from './client.class';
import { ClientContract, IClientContractResponse } from './clientContract.class';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const getMonthsList = () => {
  const months = [];
  const dateStart = moment().subtract(19, 'month');
  const dateEnd = moment().add(1, 'month');

  while (dateEnd.diff(dateStart, 'months') > 0) {
    months.push(dateEnd.format('YYYY-MM'));
    dateEnd.subtract(1, 'month');
  }

  return months;
};

@Injectable({
  providedIn: 'root'
})
export class AddonsImportService {
  month: string;
  months: Array<string>;
  headers: {};

  private apiBase: string;
  private apiBases: { lv: string, ee: string, lt: string };

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {

    this.month = moment().format('YYYY-MM');
    this.months = getMonthsList();

    this.apiBases = {
      lv: environment.livasAPIBaseLVV3,
      ee: environment.livasAPIBaseEEV3,
      lt: environment.livasAPIBaseLTV3,
    };

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.headers = {
        Authorization: `LivasSession ${params.sessionId ? params.sessionId : 'cuc5ktg2tno3kjaets6qakiel4'}`
      };

      this.apiBase = this.apiBases[params.country] || this.apiBases.lt;
    });

  }

  getMonth() {
    return this.month;
  }

  getMonths() {
    return this.months;
  }

  filterClients(filter: { name: string } = { name: '' }, page = 1): Observable<IClientResponse> {
    return this.http.get<IClientResponse>(`${this.apiBase}/client.php?name=${filter.name}`, { headers: this.headers })
      .pipe(
        tap((response: IClientResponse) => {
          response.results = response.results
            .map(client => new Client(client.id, client.name));
          return response;
        })
      );
  }

  getClientContracts(clientId: number): Observable<IClientContractResponse> {
    return this.http.get<IClientContractResponse>(`${this.apiBase}/clientContract.php?client_id=${clientId}&page=1&page_size=1000&signed_up=1`,
      { headers: this.headers }).pipe(
      tap((response: IClientContractResponse) => {
        response.results = response.results
          .map(contract => new ClientContract(contract.id, contract.title, contract.expired));
        return response;
      })
    );
  }

  getContractPositions(contractId: number): Observable<{results: []}> {
    return this.http.get<object>(`${this.apiBase}/position.php?contractId=${contractId}&page=1&page_size=1000`,
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getPositionIdByTitle(title, positions = []): number {
    const position = positions.filter(p => p.title && title && p.title.toString().toLowerCase() === title.toString().toLowerCase())[0];

    return position ? position.id : null;
  }

  getDataSource(userfile: any, positions: Array<object>): Observable<IAddonResponse> {
    const body = { userfile };

    return this.http.post<IAddonResponse>(`${this.apiBase}/projectAddons.php?action=upload`, body, { headers: this.headers }).pipe(
      tap((response: IAddonResponse) => {
        response.results = response.results
          .map(addon => {
            const positionId = this.getPositionIdByTitle(addon.position_title, positions);

            return new Addon(
              addon.id,
              addon.full_name,
              addon.addon_amount,
              addon.addon_comment,
              addon.position_title,
              positionId,
              addon.addon_number,
              addon.project_name,
              addon.project_number,
              addon.project_external_id,
              addon.project_external_id2,
              addon.project_address
            );
          });
        return response;
      })
    );
  }

   getMissingEmployeeIds(clientId: number, month: string, data: object) {
    return this.http.post<any>(
      `${this.apiBase}/projectAddons.php?action=getEmployeeId&client_id=${clientId}&month=${month}`,
      data,
      { headers: this.headers }).toPromise();
  }

  sendAddonsForm(data: object, action: string): Observable<any> {
    return this.http.post<any>(`${this.apiBase}/projectAddons.php?action=${action}`, data, { headers: this.headers }).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
}
