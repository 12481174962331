import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment';

import { CreateTaskScheduleGQL, UpdateTaskScheduleGQL } from '../../../generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class TaskSchedulingService {
  constructor(
    private createScheduleGQL: CreateTaskScheduleGQL,
    private updateScheduleGQL: UpdateTaskScheduleGQL,
  ) { }

  saveSchedule(taskId: string, scheduleId: string, scheduleForm: any): Observable<any> {
    const input = {
      endDate: scheduleForm.endDate ? moment(scheduleForm.endDate).format('YYYY-MM-DD') : null,
      recurrencePeriod: scheduleForm.recurrencePeriod.toUpperCase(),
      intervalCount: scheduleForm.intervalCount,
      dayOfWeek: scheduleForm.dayOfWeek,
      weekOfMonth: scheduleForm.weekOfMonth,
      monthInterval: scheduleForm.monthInterval
    };

    return scheduleId
      ? this.updateScheduleGQL.mutate({ input: { ...input, id: scheduleId } })
      : this.createScheduleGQL.mutate({ input: { ...input, parentTask: taskId } }, {refetchQueries: ['GetOperationsTasks']});
  }
}
