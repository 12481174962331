<div>
  <mat-form-field fxFlex="200px" >
          <mat-label>{{ 'COMMON.TYPE' | translate }}</mat-label>
          <select matNativeControl [(ngModel)]="fileType" name="contact">
            <option value="{{uploaderFileTypes.medical}}" selected>{{ 'MEDICAL' | translate }}</option>
            <option value="{{uploaderFileTypes.hygiene}}" selected>{{ 'HYGIENE' | translate }}</option>
            <option value="{{uploaderFileTypes.other}}" selected>{{ 'COMMON.OTHER' | translate }}</option>
          </select>
  </mat-form-field>
</div>

<div>
  <mat-form-field  fxFlex="200px" *ngIf="(fileType === uploaderFileTypes.medical) || (fileType=== uploaderFileTypes.hygiene) ">
      <mat-label>{{ 'COMMON.SAVE' | translate }} {{'THE DOCUMENT IS VALID UNTILL'| translate}}</mat-label>

      <input matInput [(ngModel)]="nextHealthcheck"
             [matDatepicker]="pickerFrom"
             (click)="pickerFrom.open()" disabled
             placeholder="Užsakymo data NUO">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
  </mat-form-field>
</div>

<mat-action-row align="end">
  <button mat-stroked-button
          [mat-dialog-close]="{type: fileType, date: nextHealthcheck}"
          [disabled]="(fileType === uploaderFileTypes.medical) && !nextHealthcheck" >{{ 'COMMON.SAVE' | translate }}</button>
</mat-action-row>



