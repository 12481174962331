import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-bar-segment',
  templateUrl: './progress-bar-segment.component.html',
  styleUrls: ['./progress-bar-segment.component.scss']
})
export class ProgressBarSegmentComponent implements OnInit {
  @Input() icon: string;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() status: boolean;
  @Input() clickable: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
