import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

export class ShiftInSchedule {
  constructor(
    public type: number,
    public count: number,
    public start: string

  ) {}
}

export enum ScheduleTypes {
  workdays = 0,
  shifts = 1
}

export interface ScheduleChangeEvent {
  shiftDuration: number;
  breakDuration: number;
  scheduleType: ScheduleTypes;
  shiftPattern: ShiftInSchedule[];
  scheduleText: string;
  scheduleTypes: {id: string, title: string}[];
}

export enum InputModes {
  freetext = 'freetext',
  structure = 'structure'
}

@Component({
  selector: 'app-schedule-setup',
  templateUrl: './schedule-setup.component.html',
  styleUrls: ['./schedule-setup.component.scss']
})
export class ScheduleSetupComponent implements OnInit, OnChanges {
  scheduleType: ScheduleTypes = 0;
  shiftDuration: number;
  breakDuration: number;
  shiftPattern: string;

  shiftsStarts: ShiftInSchedule[] = [new ShiftInSchedule(1, 2, '08:00')];
  InputModes = InputModes;
  inputMode: InputModes;

  scheduleTypes = [];
  scheduleTypesMap: any = {};

  @Output() changed = new EventEmitter<ScheduleChangeEvent & {inputMode: InputModes}>();
  @Input() schedule: ScheduleChangeEvent;
  @Input() form: FormGroup;
  @Input() readonly = false;

  flexVal: number;
  constructor(
    private fb: FormBuilder,
  ) { }
  
  ngOnChanges(changes: SimpleChanges): void {
    if('schedule' in changes) {
      
      this.shiftDuration = this.schedule?.shiftDuration;
      this.breakDuration = this.schedule?.breakDuration;
      this.scheduleType = this.schedule?.scheduleType;
      this.shiftsStarts = this.schedule?.shiftPattern || [];
      this.shiftsStarts = Array.isArray(this.shiftsStarts) ? this.shiftsStarts : [this.shiftsStarts];
      if (!this.form) {
        this.form = this.fb.group({
          scheduleText: [this.schedule?.scheduleText],
          scheduleTypes: [this.schedule?.scheduleTypes?.map(st => parseInt(st.id))]
        });
      }
      this.inputMode = this.schedule?.scheduleText ? InputModes.freetext : InputModes.structure;
    }
    if(this.readonly) {
      this.form.get('scheduleText').disable();
      this.form.get('scheduleTypes').disable();
    }
  }

  ngOnInit() {
    this.flexVal = this.readonly ? 100 : 50;
  }

  addShift() {
    this.shiftsStarts.push(new ShiftInSchedule(0, 2, undefined));
    this.onShiftDataChange();
  }

  arrayOne(n) {
    return Array(n);
  }

  onShiftDataChange() {
    this.changed.emit({
      shiftDuration: this.shiftDuration,
      breakDuration: this.breakDuration,
      scheduleType: this.scheduleType,
      shiftPattern: this.shiftsStarts,
      scheduleText: this.form.get('scheduleText').value,
      scheduleTypes: this.form.get('scheduleTypes').value,
      inputMode: this.inputMode,
    });
  }

  removeItem(i: number) {
    this.shiftsStarts.splice(i, 1);
    this.onShiftDataChange();
  }

  numberInputControl(input) {
    const regex = /[0-9]/;
    if (!regex.test(input.key)) {
      input.preventDefault();
    }
  }
}
