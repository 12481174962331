<div class="head" fxLayout="row" fxLayoutAlign="space-between center">
  <h4>{{ 'ASSIGN_MANAGERS' | translate }}</h4>

  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="content" [formGroup]="selectedManagers">
  <div class="selected-companies">
    {{ 'SELECTED_COMPANIES' | translate }}: {{ getSelectedCompaniesRow() }}
  </div>

  <div fxLayout="row" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.PROJECT_MANAGER' | translate }} 1</mat-label>
        <mat-select matNativeControl formControlName="projectManager1" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['pm1']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'pm1' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['projectManager1'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{'CLIENT.PROJECT_MANAGER' | translate}} 2</mat-label>
        <mat-select matNativeControl formControlName="projectManager2" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['pm2']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'pm2' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['projectManager2'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.SUBSTITUTE_MANAGER' | translate }} 1</mat-label>
        <mat-select matNativeControl formControlName="substituteManager1" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['sm1']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'sm1' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['substituteManager1'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.SUBSTITUTE_MANAGER' | translate }} 2</mat-label>
        <mat-select matNativeControl formControlName="substituteManager2" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['sm2']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'sm2' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['substituteManager2'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.RECRUITER' | translate }} 1</mat-label>
        <mat-select matNativeControl formControlName="recruiter1" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['r1']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'r1' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['recruiter1'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.RECRUITER' | translate }} 2</mat-label>
        <mat-select matNativeControl formControlName="recruiter2" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['r2']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'r2' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['recruiter2'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="24px">
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.SUBSTITUTE_RECRUITER' | translate }} 1</mat-label>
        <mat-select matNativeControl formControlName="substituteRecruiter1" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['sr1']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'sr1' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['substituteRecruiter1'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLIENT.SUBSTITUTE_RECRUITER' | translate }} 2</mat-label>
        <mat-select matNativeControl formControlName="substituteRecruiter2" name="contact">
          <mat-option>
            <ngx-mat-select-search [formControl]="managerFilters.controls['sr2']" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let userprofile of (filterTarget === 'sr2' ? filteredManagers : managers)" [value]="userprofile.id">
            {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="selectedManagers.controls['substituteRecruiter2'].setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>

  <button mat-flat-button color="primary" [disabled]="isLoading || isSaveDisable()" (click)="save()">{{ 'SAVE' | translate }}</button>
</div>
