import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {CompaniesResponse} from '../graphql/graphql';
import {UploaderFileTypes} from './uploader/uploader-file-types';

export enum DOCUMENT_TYPES {
  medical = 'MEDICAL',
  hygiene = 'HYGIENE',
  other = 'OTHER',
}

export interface UploadedImage {
  id: number;
  userId: string;
  docType: UploaderFileTypes;
  imageUrl: string;
}



@Injectable({
  providedIn: 'root',
})
export class GetUploadedImagesGQL extends Query<{images: UploadedImage[]}> {
  document = gql`
    query images($userId: Int!) {
      images(userId: $userId) {
        id
        userId
        docType
        imageUrl
      }
    }
  `;
}


