import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeSelectInputModule } from 'mat-tree-select-input';

import { SharedModule } from '../shared/shared.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { CustomfieldsModule } from '../customfields/customfields.module';
import { CandidatesRoutingModule } from './candidates-routing.module';
import { CandidatesComponent } from './candidates/candidates.component';
import { CandidatesTableComponent } from './candidates-table/candidates-table.component';

@NgModule({
  declarations: [
    CandidatesComponent,
    CandidatesTableComponent
  ],
  imports: [
    CommonModule,
    CandidatesRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    SharedModule,
    AppMaterialModule,
    MatDialogModule,
    MatTreeSelectInputModule,
    CustomfieldsModule
  ]
})
export class CandidatesModule { }
