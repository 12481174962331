import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  headline: string;
  records: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { title?: string, headline?: string, records?: string[] }
  ) {
    this.title = data.title;
    this.headline = data.headline;
    this.records = data.records;
  }

  ngOnInit(): void {
  }
}

