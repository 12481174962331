<div class="mat-elevation-z2 d">
  <div class="interview-pill" *ngIf="clientInterview">
    <mat-hint>
      {{ 'INTERVIEW_PLANNED_FOR_CLIENT' | translate }}
      <b>{{ clientInterview.relatedOffer?.order.company.name }}</b> at
      <b>{{ clientInterview.startDatetime | date: 'yyyy-MM-dd HH:mm' }}</b>
    </mat-hint>
  </div>

  <mat-divider></mat-divider>

  <h5 class="heading">{{'OFFERS_HISTORY_LOG' | translate}}</h5>
  <div class="filter-container" [class.with-tip]="clientInterview">
    <mat-form-field class="filter">
      <button *ngIf="filterValue" mat-button matSuffix mat-icon-button type="button"
        (click)="filterValue = ''; filterTable()">
        <mat-icon matSuffix>close</mat-icon>
      </button>
      <mat-icon matSuffix>search</mat-icon>
      <input matInput placeholder="{{ 'COMMON.SEARCH' | translate }}" [(ngModel)]="filterValue"
        (ngModelChange)="filterTable()" />
    </mat-form-field>
  </div>

  <table mat-table class="full-width-table offers-table" matSort matSortActive="createdAt" aria-label="Offers" >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" >{{'DATE' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div>{{dateFormatFirstPart(row?.createdAt)}}</div>
        <div>{{dateFormatSecondPart(row?.createdAt)}}</div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'WORK' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" [ngClass]="row.color" class="company-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <!-- <a href="/free-vacancies/list/?orderId={{ row.id }}" target="_blank" class="grey-icon">
            <mat-icon class="rem-08">open_in_new</mat-icon>
          </a> -->
          <div fxLayout="column">
            <span>{{ row.title }}</span>
            <span class="company-name">{{ row.companyName }} </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="offerOnCallStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'PHONE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon class="icon" [ngClass]="{
          green: row.interestedOnCall === offerPropositionInterestedOnCall.Interested,
          red: row.interestedOnCall === offerPropositionInterestedOnCall.NotInterested,
          blue: row.interestedOnCall === offerPropositionInterestedOnCall.Undecided
          }" [matTooltip]="getTooltipText(row)">phone</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>{{ 'PROGRESS.TITLE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div >
            <app-progress-component [countryIso]='$country' [events]='row'
                                    [personId]='personId'></app-progress-component>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="longList">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'LONG_LIST' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-icon
            fontSet="material-icons-outlined"
            [ngClass]="{
              'grey-icon': true,
              'green-icon': row.longListed === offerPropositionLongListed.Included,
              'grey-icon-30-op': row.longListed === offerPropositionLongListed.RejectedWithoutCall
            }"
            [matTooltip]="'OFFER.LONG_LIST' | translate"
            >playlist_add_circle</mat-icon
          >

          <mat-icon
            [ngClass]="{
              'grey-icon': true,
              'red-icon': row.longListed === offerPropositionLongListed.RejectedWithoutCall,
              'grey-icon-30-op': row.longListed === offerPropositionLongListed.Included
            }"
            [matTooltip]="'OFFER.REJECT_WITHOUT_CALL' | translate"
            >playlist_remove</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Offer status Column -->
    <ng-container matColumnDef="offer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'DURING_THE_INTERVIEW' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">

          <ng-container [ngSwitch]="row.interested">
            <mat-icon class="icon" *ngSwitchCase="offerPropositionInterested.Interested" [ngClass]="{
                'grey-icon': true,
                'green-icon': row.interested === offerPropositionInterested.Interested
              }" [matTooltip]="'OFFER.POSITION_OFFERED_DOMINA' | translate">thumb_up_alt</mat-icon>

            <mat-icon class="icon" *ngSwitchCase="offerPropositionInterested.NotInterested" [ngClass]="{
                'grey-icon': true,
                'red-icon': row.interested === offerPropositionInterested.NotInterested
              }" [matTooltip]="'OFFER.POSITION_OFFERED_NEDOMINA' | translate">thumb_down_alt</mat-icon>

            <mat-icon class="icon" *ngSwitchDefault [ngClass]="{
                'grey-icon': true}" [matTooltip]="'OFFER.POSITION_WAS_NOT_OFFERED_IN_OFFICE' | translate">front_hand</mat-icon>
          </ng-container>

          <mat-icon class="icon"  *ngIf="row.signed" [matTooltip]="
          row.signed ? ('OFFER.EMPLOYED_HERE' | translate) : ('OFFER.PLANNED_RECRUITMENT' | translate)"
            [ngClass]="{ 'grey-icon': !row.signed, 'green-icon': row.signed }">assignment_turned_in</mat-icon>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="cancellationReasons">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'REASONS' | translate }}
      </th>
      <td mat-cell *matCellDef='let row'>
        <app-cancellation-reasons-icon-with-tooltip
          [cancellationReasonsAssignments]='row.cancellationReasonsAssignmentSet'>

        </app-cancellation-reasons-icon-with-tooltip>
        <!--        <mat-icon *ngIf="row.cancellationReasonsAssignmentSet?.edges?.length" (mouseenter)="showCancellationReasonsTooltip($event.currentTarget, row?.id)">-->
        <!--          info-->
        <!--        </mat-icon>-->
        <!--        <div [id]="row?.id" style="display: none;">-->
        <!--          <span *ngFor="let cr of row.cancellationReasonsAssignmentSet?.edges" class="cancellation-reason">-->
        <!--            {{cr?.node?.cancellationReason?.title}}-->
        <!--          </span>-->
        <!--        </div>-->
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'COMMENT' | translate }}
      </th>
      <td mat-cell *matCellDef='let row'>
        <div style='display: inline-block; width: 65px;'>
          <button mat-icon-button (click)='openCommentDialog($event, row)' [matTooltip]="getCommentsTooltip(row) || (('ADD' | translate) + ' ' + ('COMMENT' | translate))">
            <mat-icon inline *ngIf='!row.offerpropositioncommentSet.totalCount'>add_comment</mat-icon>
            <mat-icon inline *ngIf='row.offerpropositioncommentSet.totalCount' color='accent'>comment</mat-icon>
          </button>

          <app-candidate-evaluations-btn
            [personId]='personId'
            [offerPropositionId]='row.responseId'
            [order]='row.originalOrderId'
          ></app-candidate-evaluations-btn>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef='displayedColumns; sticky: true' [class.with-tip]='clientInterview'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns'></tr>

    <tr class='mat-row no-data-row' *matNoDataRow>
      <td class='mat-cell' colspan='7'>{{'NOTHING_WAS_OFFERED_TO_THIS_PERSON' | translate}}</td>
    </tr>
  </table>

  <mat-paginator #paginator [length]="0" [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
