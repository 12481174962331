import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupByPipe } from 'ngx-pipes';

@Component({
  selector: 'app-selected-by-client',
  templateUrl: './selected-by-client.component.html',
  styleUrls: ['./selected-by-client.component.scss'],
  providers: [GroupByPipe]
})
export class SelectedByClientComponent implements OnInit {

  columnsToDisplay = ['company', 'job', 'fullName'];
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {extraValues: any, title: string},
    private groupBy: GroupByPipe
  ) { }

  ngOnInit() {
    this.title = this.data.title;
    this.data = this.groupBy.transform(this.data.extraValues, 'shiftDate');
  }

  openLink(link: string) {
    window.open(link, '_blank');
  }

}
