import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
  CandidateType, CandidateTypeEdge, CompanyType, CompanyTypeEdge, MetricValueCommentType
} from '../../../../generated/graphql';
import {
  StandupDashboardServiceService
} from '../../../pm-stats-dashboard/standup-boards/standup-dashboard-service.service';
import { RelayIdService } from '../../relay-id.service';
import { RegionService } from '../../../recruitment-dashboard/region.service';

@Component({
  selector: 'app-reasons-dialog',
  templateUrl: './reasons-dialog.component.html',
  styleUrls: ['./reasons-dialog.component.scss']
})
export class ReasonsDialogComponent implements OnInit {
  commentsGroup: FormGroup;
  isSaving = false;
  candidatesDict: { [key: string]: CandidateType[] } = { };
  clientsDict: { [key: string]: CompanyType[] } = { };

  get comments(): FormArray { return this.commentsGroup.get('comments') as FormArray; }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reasonSet: MetricValueCommentType[],
      managerName: string,
      metricGroup: string,
      metricValueCandidate: { firstName: string, lastName: string, livasId: number, employeeLivasId: number },
      metricValueClient: { name: string, id: string, livasId: number }
    },
    private dashboardService: StandupDashboardServiceService,
    private dialogRef: MatDialogRef<ReasonsDialogComponent>,
    private fb: FormBuilder,
    public relayIdService: RelayIdService,
    public regionService: RegionService
  ) { }

  ngOnInit(): void {
    this.commentsGroup = this.fb.group({
      comments: this.fb.array([])
    });

    this.data.reasonSet.forEach(reason => {
      this.comments.push(new FormControl(reason.comment || ''));
      this.candidatesDict[reason.id] = this.getCandidates(reason);
      this.clientsDict[reason.id] = this.getClients(reason);
    });
  }

  getCandidates(reason: MetricValueCommentType): CandidateType[] {
    const listFromReasons = reason.candidates.edges
      .filter((candidate: CandidateTypeEdge) => candidate.node.firstName || candidate.node.lastName)
      .map((candidate: CandidateTypeEdge) => candidate.node);

    return listFromReasons?.length
      ? listFromReasons
      : this.data.metricValueCandidate
        ? ([this.data.metricValueCandidate as CandidateType])
        : null;
  }

  getClients(reason: MetricValueCommentType): CompanyType[] {
    const listFromReasons = reason.clients.edges
      .filter((clients: CompanyTypeEdge) => clients.node.name)
      .map((clients: CompanyTypeEdge) => clients.node);

    return listFromReasons?.length
      ? listFromReasons
      : this.data.metricValueClient
        ? ([this.data.metricValueClient as CompanyType])
        : null;
  }

  saveComment(reason: MetricValueCommentType, comment: string) {
    this.isSaving = true;
    this.dashboardService.updateMetricValueComment(reason.id, comment).subscribe(
      () => {
        this.isSaving = false;
        const fromList = this.data.reasonSet.find(r => r.id === reason.id);
        if (fromList) {
          fromList.comment = comment;
        }
      },
      () => this.isSaving = false
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
