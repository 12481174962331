import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {UserProfile} from '../graphql/graphql';


@Injectable({
  providedIn: 'root',
})
export class UserProfileGQL extends Query<{userprofile: UserProfile}> {
  document = gql`
    query getUserProfile {
      userprofile {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  `;
}
