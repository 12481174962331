<mat-form-field>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let pm of selectedPms"
      [selectable]="false"
      [removable]="selectedPms.length > 1"
      (removed)="removePm(pm.id)">
      {{pm.user.firstName}} {{pm.user.lastName}}
      <mat-icon matChipRemove *ngIf="selectedPms.length > 1">cancel</mat-icon>
    </mat-chip>
    <input
      #pmInput
      placeholder="Įrašykite daugiau PV filtravimui"
      [formControl]="pmCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
    <mat-option *ngFor="let pm of filteredPms | async" [value]="pm">
      {{pm.user.firstName}} {{pm.user.lastName}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>