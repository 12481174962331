<div [formGroup]="form" *ngIf="form">
  <div fxLayout="row" fxLayoutGap="16px" >
    <app-company-selector
      fxFlex="calc(33%-12px)"
      [companyCtrl]="companyCtrl"
      [country]="country?.id"
      [order]="order"
      (companyChange)="onCompanyChange($event)">
    </app-company-selector>

    <ng-container *ngIf="companyCtrl.valid || this.editOrderId">
      <div [fxHide]="!(isOrderShown | async)" fxFlex="66">
        <app-order-select-list *ngIf="selectedOrderCtrl"
          (newSelection)="onOrderSelect()"
          [orderCtrl]="selectedOrderCtrl"
          (newOrders)="onNewOrders($event)"
          (newOrder)="onNewOrder($event)"
          [companyId]="companyCtrl.value?.id"
          [orderType]="orderType"
          [order]="order">
        </app-order-select-list>
      </div>

      <div [fxHide]="(isOrderShown | async)" fxFlex="66" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>{{'CLIENT.REGION' | translate}}</mat-label>
            <mat-select formControlName='region'>
              <mat-option *ngFor='let region of regions'
                          [value]='region.id'
                          [disabled]='region.isActive === false'
              >{{region.title}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="50" appearance="outline" >
          <mat-label>{{'CLIENT.PROJECT_MANAGER' | translate}}</mat-label>
            <mat-select matNativeControl formControlName="projectManager" name="contact">
              <mat-option>
                <ngx-mat-select-search [formControl]="managerFilterCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let userprofile of selectedManagers" [value]="userprofile.id">
                   {{userprofile?.user?.firstName}} {{userprofile?.user?.lastName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="selectedOrderCtrl?.value || this.editOrderId">
<!--    second row-->
    <div fxLayout="row" fxLayoutGap="16px">
      <app-contract-select-field [contract]="contactCtrl"
                                 [contracts]="contracts"
                                 fxFlex="33"

                                 (refetchContracts)="refetchCompanyContracts()">
      </app-contract-select-field>

      <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>{{'CLIENT.ADDITIONAL_REGIONS' | translate}}</mat-label>
        <mat-select formControlName='additionalRegions' multiple>
          <mat-option *ngFor='let region of regions'
                      [value]='region.id'
                      [disabled]='region.isActive === false'>{{region.title}}</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field fxFlex="33" appearance="outline">
        <mat-label>{{'CLIENT.RECRUITER' | translate}}</mat-label>
          <mat-select matNativeControl formControlName="recruiter" name="contact">
            <mat-option>
              <ngx-mat-select-search [formControl]="managerFilterCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let userprofile of selectedManagers" [value]="userprofile.id">
                 {{userprofile?.user?.firstName}} {{userprofile?.user?.lastName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="16px" style="margin-bottom: 24px">
      <mat-form-field fxFlex="33" appearance="outline">
        <mat-label>{{'CLIENT.BIURO_SIGNEE' | translate}}</mat-label>
          <mat-select matNativeControl formControlName="biuroSignee" name="contact">
            <mat-option>
              <ngx-mat-select-search [formControl]="managerFilterCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let userprofile of selectedManagers" [value]="userprofile.id">
                 {{userprofile?.user?.firstName}} {{userprofile?.user?.lastName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>

      </mat-form-field>



      <mat-form-field fxFlex="33" appearance="outline">
        <mat-label>{{'CLIENT.CLIENT_CONTACT' | translate}}</mat-label>
          <mat-select matNativeControl formControlName="clientContact" #clientContactSelect name="contact">
<!--              <mat-option (click)="openAddContactDialog()" selected>-->
<!--                <mat-icon>add</mat-icon> {{'ADD_CONTACT' | translate }}-->
<!--              </mat-option>-->
            <mat-select-trigger>{{getSelectedValue(form.get('clientContact').value)}}</mat-select-trigger>
            <mat-option (click)="$event.stopImmediatePropagation(); openAddContactDialog()">
              <mat-icon>add</mat-icon>
              {{'ADD_NEW_CONTACT' | translate}}
            </mat-option>
<!--              <mat-option [value]=""></mat-option>-->
            <mat-option *ngFor="let contact of contacts" [value]="contact.id" >
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{contact.firstName}} {{contact.lastName}} </span>
                <mat-icon inline="true" (click)="$event.stopImmediatePropagation(); openEditContactDialog(contact.id)"

                >info</mat-icon>
              </div>
            </mat-option>
          </mat-select>
          <mat-hint>{{'CLIENT.CLIENT_CONTACT_HINT' | translate }}</mat-hint>
<!--          <button mat-icon-button matPrefix-->
<!--                  (click)="openEditContactDialog(); $event.stopImmediatePropagation()"-->
<!--                  *ngIf="selectedContactCtrl.value; else addContact">-->
<!--            <mat-icon>edit</mat-icon>-->
<!--          </button>-->
        <ng-template #addContact>
          <button mat-icon-button matPrefix (click)="openAddContactDialog();">
            <mat-icon>add_box</mat-icon>
          </button>
        </ng-template>

          <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="33" appearance="outline">
        <mat-label >{{'CLIENT.ADDITIONAL_CONTACTS' | translate}}</mat-label>
          <mat-select matNativeControl formControlName="additionalContacts" name="contact" [multiple]="true"  >
            <mat-select-trigger>{{getSelectedValue(form.get('additionalContacts').value)}}</mat-select-trigger>
            <mat-option *ngFor="let contact of contacts" [value]="contact.id">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span>{{contact.firstName}} {{contact.lastName}}</span>
                  <mat-icon inline="true" (click)="$event.stopImmediatePropagation(); openEditContactDialog(contact.id)">info</mat-icon>
                </div>
            </mat-option>
          </mat-select>

          <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>
          <mat-hint>{{'CLIENT.ADDITIONAL_CLIENT_CONTACT_HINT' | translate }}</mat-hint>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <div fxLayout="column" fxFlex="calc(33%-8px)" class="checkboxes">
        <mat-label class="checkbox-title"> {{'CLIENT.COMPANY_ADDITIONAL_INFO' | translate }}</mat-label>
        <mat-checkbox color="primary" formControlName="isLogoPublicAllowed">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.LOGO_CAN_BE_USED' | translate}}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isWorkplaceVisitAllowed">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.WORKPLACE_VISIT_ALLOWED' | translate}}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isCompanyInternational">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.INTERNATIONAL_COMPANY' | translate}}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="isCompanyModern">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.MODERN_COMPANY' | translate}}</mat-checkbox>
      </div>

      <app-company-description fxFlex="67" [description]="descriptionCtrl"></app-company-description>
    </div>

    <div fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="100" [style.margin-bottom]="'10px'">
        <mat-label>{{'CLIENT.SALES_QUESTIONNAIRE_LABEL' | translate}}</mat-label>
        <textarea matInput formControlName="salesQuestionnaire" rows="7"></textarea>
        <mat-hint>{{'CLIENT.SALES_QUESTIONNAIRE_HINT' | translate}}</mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="!isSelection && !isPartialSelection" fxLayout="row" fxLayoutGap="16px">
      <mat-form-field appearance="outline" fxFlex="calc(33%-8px)">
        <mat-label>{{'CLIENT.CANCELATION_PERIOD' | translate}}</mat-label>
        <input type="number" class="number-input" formControlName="cancellationPeriod" matInput>
        <span matSuffix class="days">{{'DAYS' | translate}}</span>
        <mat-hint>{{'CANCELATION_PERIOD_HINT' | translate }}</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="67">
        <mat-label>{{ 'CLIENT.COMPENSATION_FOR_RECRUITEMENT' | translate}}</mat-label>
        <textarea matInput type="text"
                    rows="6"
                    formControlName="compensationForRecruitment"></textarea>
        <mat-hint>{{'CLIENT.SALES_ARE_RESPONSIBLE_FOR_THIS' | translate }}</mat-hint>
      </mat-form-field>
    </div>

    <div fxLayout="row"  fxLayoutAlign="end">
      <div fxFlex="33"></div>
      <div fxLayout="column" fxFlex="66" fxLayoutGap="16px">
        <mat-form-field *ngIf="!isSelection && !isPartialSelection" appearance="outline" fxFlex="50" >
          <mat-label>{{'CLIENT.OTHER_INFO_LABEL' | translate}}</mat-label>
          <textarea matInput formControlName="otherInfo"></textarea>
          <mat-hint>{{'CLIENT.OTHER_INFO_HINT' | translate}}</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="!isSelection && !isPartialSelection" appearance="outline" fxFlex="50" >
          <mat-label>{{'CLIENT.MIN_VSDF_PAYMENT_LABEL' | translate}}</mat-label>
          <textarea matInput formControlName="minVsdfPayment"></textarea>
          <mat-hint>{{'CLIENT.MIN_VSDF_PAYMENT_HINT' | translate}}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50"  *ngIf="!isSelection && !isPartialSelection">
          <mat-label>{{'CLIENT.WORK_TIME_REGISTRATION_SYSTEM_LABEL' | translate}}</mat-label>
          <textarea matInput formControlName="workTimeRegistrationSystem"></textarea>
          <mat-hint>{{'CLIENT.WORK_TIME_REGISTRATION_SYSTEM_HINT' | translate}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</div>
