import { Component, Input, OnInit } from '@angular/core';
import {SalaryRangeValues} from '../../../orders/order-create/salary-matrix/salary-matrix-values';

@Component({
  selector: 'app-salary-range',
  templateUrl: './salary-range.component.html',
  styleUrls: ['./salary-range.component.scss']
})
export class SalaryRangeComponent implements OnInit {

  @Input() value: SalaryRangeValues;
  constructor() { }

  ngOnInit(): void {
  }

}
