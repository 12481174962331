import * as moment from 'moment';
import { Moment } from 'moment';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectManagerGQL, OrderStatsResponse, StatsForOrdersGQL, StatsForCandidateOrdersGQL, OrderCandidateStatsResponse } from './graphql';
import { UserProfile } from '../graphql/graphql';

@Component({
  selector: 'app-pm-stats-dashboard',
  templateUrl: './pm-stats-dashboard.component.html',
  styleUrls: ['./pm-stats-dashboard.component.scss']
})
export class PmStatsDashboardComponent implements OnInit {

  dateFrom = moment().startOf('month');
  dateTo = moment().endOf('month');
  startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
  endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
  filterSubject = new BehaviorSubject<{dateFrom: Moment, dateTo: Moment}>({dateFrom: this.dateFrom, dateTo: this.dateTo});

  currentUser: UserProfile;
  selectedManager: UserProfile;
  projectManagers: UserProfile[];

  stats: OrderStatsResponse = {confirmed : 0, sent: 0, created: 0, cancelled: 0};
  candidateStats: OrderCandidateStatsResponse = {total: 0, interested: 0, signed: 0};

  constructor(
    private projectManagerGQL: ProjectManagerGQL,
    private statsForOrdersGQL: StatsForOrdersGQL,
    private statsForCandidateOrdersGQL: StatsForCandidateOrdersGQL
  ) { }

  ngOnInit() {
    this.projectManagerGQL.fetch().subscribe(res => {
      this.currentUser = res.data.currentUser;
      this.selectedManager = res.data.currentUser;
      this.projectManagers = res.data.userprofiles;
    })
    this.loadCandidateStatsData();
  }

  emitDateChange() {
    this.filterSubject.next({dateFrom: this.dateFrom, dateTo: this.dateTo});
    this.loadCandidateStatsData();
  }

  loadWeekStatsData(pms: UserProfile[]) {
    this.statsForOrdersGQL.fetch({dateFrom: this.startOfWeek, dateTo: this.endOfWeek, projectManagerIds: pms.map(e => e.id)}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.stats = res.data.statsOrderStatsForPeriod;
    })
  }

  private loadCandidateStatsData() {
    this.statsForCandidateOrdersGQL.fetch({dateFrom: this.dateFrom.format('YYYY-MM-DD'), dateTo: this.dateTo.format('YYYY-MM-DD')}).subscribe(res => {
      this.candidateStats = res.data.statsOrderCandidateStatsForPeriod;
    })
  }

}
