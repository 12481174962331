<mat-form-field appearance='outline' style='width: 100%;'>
  <mat-label>{{'CATEGORY' | translate}}</mat-label>
  <input type='text'
         matInput
         [formControl]='categoryControl'
         required
         [matAutocomplete]='categoryAutocomplete'>
  <mat-autocomplete #categoryAutocomplete='matAutocomplete'
                    [class]='customAutocompleteClass'
                    [displayWith]='displayCategory'
                    (optionSelected)='selectedChange.emit($event.option.value)'>
    <mat-optgroup *ngFor='let group of selectedCategories' [label]='getGroupTitle(group)'>
      <mat-option *ngFor='let category of group.children' [value]='category'>
        {{category.title}}
        <mat-icon *ngIf='category.hint' [matTooltip]='category.hint'
                  class='category-option-icon'>info
        </mat-icon>
      </mat-option>
    </mat-optgroup>
    <mat-divider></mat-divider>
    <mat-option [disabled]='true'>
      <mat-slide-toggle inline='true' color='primary'
                        class='options-slider'
                        [matTooltip]='"SHOW_ALL_CATEGORIES_INFO" | translate'
                        [(ngModel)]='showAllOptions'
                        (ngModelChange)='updateFilteredCategories(lastSearchedCategory)'
      >
        {{'SHOW_ALL_CATEGORIES' | translate}}
      </mat-slide-toggle>
    </mat-option>


  </mat-autocomplete>
</mat-form-field>
