import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {CalendarEvent, CalendarEventAction, CalendarMonthViewEventTimesChangedEvent} from 'angular-calendar';
import {addDays, addHours, endOfDay, endOfMonth, isSameDay, isSameMonth, subDays, startOfDay} from 'date-fns';
import {FreeVacanciesTableGQL, PlannerCalendarEventsGQL} from '../graphql/graphql';
import {ActionItemStatus} from '../fv-planing-dialog-action-item/fv-planing-dialog-action-item';
import { MatDialog } from '@angular/material/dialog';
import {FvPlanningDialogComponent} from '../fv-planning-dialog/fv-planning-dialog.component';
import {TaskStatus, FreeVacanciesCalendarEventsGQL} from '../../../generated/graphql';
import * as moment from 'moment';


const   colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },
    green: {
      primary: '#cddc39',
      secondary: '#e6ee9c'
    }
  };
@Component({
  selector: 'app-fv-marketing-calendar',
  templateUrl: './fv-marketing-calendar.component.html',
  styleUrls: ['./fv-marketing-calendar.component.scss']
})
export class FvMarketingCalendarComponent implements OnInit {
  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  events: CalendarEvent[] = [];
  activeDayIsOpen = false;

  constructor(
    private eventGql: FreeVacanciesCalendarEventsGQL,
    private ordersGql: FreeVacanciesTableGQL,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const dateFrom = moment().startOf('week').format('YYYY-MM-DD');
    this.eventGql.fetch({dateFrom}).subscribe(
      data => {
        this.events = data.data.plannerEvents.edges.map(
          it => {
            return {
              start: startOfDay(it.node.date),
              end: endOfDay(it.node.date),
              title: it.node.order.specification.positionTitle,
              color: it.node.status ===  TaskStatus.Done ? colors.green : colors.yellow,
              actions: this.actions,
              id: it.node.order.id
            };
          }
        );
      }
    );
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }) {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(clicked: string, event: any) {

    this.ordersGql.fetch({id: event.id}).subscribe(
      resp => {
        this.dialog.open(FvPlanningDialogComponent, {
          data: {
            project: resp.data.ordersPaginated.edges[0].node
          },
          width: '90vw',
          maxWidth: '90vw'
        });
      }
    );
  }

  eventTimesChanged($event: CalendarMonthViewEventTimesChangedEvent<any, any>) {

  }
}
