export class Addon {
    constructor(
        public id: number,
        public full_name: string,
        public addon_amount: number,
        public addon_comment: string,
        public position_title: string,
        public position_id: number,
        public addon_number: number,
        public project_name: string,
        public project_number: string,
        public project_external_id: string,
        public project_external_id2: string,
        public project_address: string,
    ) { }
}

export interface IAddonResponse {
    results: Addon[];
}
