import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OrdersComponent} from './orders/orders.component';
import {OrderCreateComponent} from './orders/order-create/order-create.component';
import {LoginCallbackComponent} from './auth/login-callback/login-callback.component';
import {OrderListComponent} from './orders/order-list/order-list.component';
import {OrderEditComponent} from './orders/order-edit/order-edit.component';

const routes: Routes = [
  {path: 'login-callback', component: LoginCallbackComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
