import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-schedule-widget',
  templateUrl: './schedule-widget.component.html',
  styleUrls: ['./schedule-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleWidgetComponent),
      multi: true
    }
  ]
})
export class ScheduleWidgetComponent implements OnInit, ControlValueAccessor {

  val: number[] = [];
  scheduleTypesMap = {
    weekday: 6,
    morningShifts: 11,
    dailyShifts: 7,
    nightShifts: 8,
    weekend: 9,
    workisFreeSchedule: 10
  };

  onChange: any = () => {};
  onTouch: any = () => {};

  disabled = false;

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(){
    return this.val;
  }

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(value): void {
    this.val = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setScheduleTypes(typeId: number) {
    if(!this.disabled) {
      if (this.val?.includes(typeId)) {
        this.val = this.val.filter(x => x !== typeId)
      } else {
        if(!this.val) {
          this.val = [];
        }
        this.val.push(typeId);
      }
      this.value = this.val;
    }
  }

  isSelected(weekday: number) {
    return this.val?.includes(weekday);
  }
}

