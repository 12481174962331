import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { TaskServiceService } from '../../../planner/task-service.service';
import { CustomFieldDefinitionFieldType } from '../../../../generated/graphql';
import { StandupDashboardServiceService } from '../standup-dashboard-service.service';

@Component({
  selector: 'app-task-multiple-create-dialog',
  templateUrl: './task-multiple-create-dialog.component.html',
  styleUrls: ['./task-multiple-create-dialog.component.scss']
})
export class TaskMultipleCreateDialogComponent implements OnInit {
  form: FormGroup;
  isButtonLock = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      uniqueActions: any[],
      uniqueMetricValues: any[],
      dashboardId: string,
      user: any
    },
    private dialogRef: MatDialogRef<TaskMultipleCreateDialogComponent>,
    private dashboardService: StandupDashboardServiceService,
    private taskService: TaskServiceService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      nextActionCategory: [null, Validators.required],
      comment: [null, Validators.required]
    });
  }

  formSubmit() {
    this.isButtonLock = true;
    forkJoin(
      this.data.uniqueMetricValues.map(value => {
        const task = {
          ...this.form.getRawValue(),
          date: value.date,
          category: value.taskCategory.id,
          dashboard: this.data.dashboardId,
          completedBy: [this.data.user.id],
          country: this.data.user.region?.country.id,
          customFields: this.getCustomFieldsObj(value.taskCategory.customFields.edges),
          status: 'DONE'
        };

        return this.taskService.createTask(task);
      })
    ).subscribe(
      respArr => {
        forkJoin(
          this.data.uniqueMetricValues.map((value, idx: number) => {
            return this.dashboardService.setMetricValueNextTask(value.id, respArr[idx].data.createOperationsTask.task.id)
          })
        ).subscribe(() => this.dialogRef.close(true));
      },
      error => {
        console.error('Error occurred', error);
      }
    );
  }

  private getCustomFieldsObj(edges) {
    const customFields = { };

    edges.forEach(edge => {
      switch (edge.node.fieldType.fieldType) {
        case CustomFieldDefinitionFieldType.RatingWithComment:
          customFields[edge.node.id] = {
            numericValue: null,
            textValue: null
          };
          break;
        case CustomFieldDefinitionFieldType.Boolean:
          customFields[edge.node.id] = {
            boolValue: null
          };
          break;
        case CustomFieldDefinitionFieldType.Date:
          customFields[edge.node.id] = {
            dateValue: null
          };
          break;
        case CustomFieldDefinitionFieldType.Heperlink:
          customFields[edge.node.id] = {
            urlValue: null,
            textValue: null
          };
          break;
        case CustomFieldDefinitionFieldType.Numeric:
          customFields[edge.node.id] = {
            numericValue: null
          };
      }
    });

    return customFields;
  }
}
