import { NgModule } from '@angular/core';
import { CompaniesComponent } from './companies.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { CompaniesRoutingModule } from './companies-routing.module';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompanyOrdersComponent } from './company-detail/company-orders/company-orders.component';
import { CompanyAdsComponent } from './company-detail/company-ads/company-ads.component';
import { CompanyEffortsComponent } from './company-detail/company-efforts/company-efforts.component';
import { OrdersModule } from '../orders/orders.module';
import { ColorChangeLogDialogComponent } from './company-detail/color-change-log-dialog/color-change-log-dialog.component';
import { AssignCompanyLabelsDialogComponent } from './assign-company-labels-dialog/assign-company-labels-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { ManagerAssignComponent } from './manager-assign/manager-assign.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AppMaterialModule,
    CompaniesRoutingModule,
    OrdersModule,
    MatDialogModule,
    TranslateModule
  ],
  exports: [CompanyAdsComponent],
  declarations: [
    CompaniesComponent,
    CompanyDetailComponent,
    CompanyOrdersComponent,
    CompanyAdsComponent,
    CompanyEffortsComponent,
    ColorChangeLogDialogComponent,
    AssignCompanyLabelsDialogComponent,
    CompaniesListComponent,
    ContactsListComponent,
    ManagerAssignComponent
  ],
  providers: [],
  entryComponents: [ColorChangeLogDialogComponent]

})
export class CompaniesModule { }
