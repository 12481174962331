import {Component, Input, OnInit} from '@angular/core';
import {ShiftInSchedule} from '../schedule-setup.component';

@Component({
  selector: 'app-schedule-visual-element',
  templateUrl: './schedule-visual-element.component.html',
  styleUrls: ['./schedule-visual-element.component.scss']
})
export class ScheduleVisualElementComponent implements OnInit {
  offsetTop: number;
  height = 10;

  @Input() shift: ShiftInSchedule;
  @Input()
  set duration(value: number) {
    this.height = value * 1.5;
  }

  constructor() { }

  ngOnInit() {
    this.offsetTop = parseInt(this.shift?.start, 10) * 1.5;
  }


  getOffset(start) {
    return parseInt(start, 10 ) * 1.5;
  }
}
