import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidenavService } from '../../shared/sidenav.service';
import { ToolbarService } from '../../navigation/toolbar.service';
import { OffersTableComponent } from '../offers-table/offers-table.component';
import { MatDialog } from '@angular/material/dialog';
import { UnseenOrdersService } from 'src/app/orders/unseen-orders.service';
import { V } from '@angular/cdk/keycodes';
// import {CommentDialogComponent} from '../comment-dialog/comment-dialog.component';

export enum WidgetTypes {
  office = 0,
  call = 1,
}

const SUPPORTED_LOCATIONS = ['lt', 'lv', 'ee'];

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements OnInit {
  personId: string;
  regionName: string;
  sex: string;

  // officeInterviewColumns = ['name', 'offerOnCallStatus', 'offer','interview','darbinimas', 'comment'];
  // callInterviewColumns = ['name', 'offerCall', 'client-interview', 'comment'];

  officeInterviewColumns = ['name', 'progress', 'comment'];
  callInterviewColumns = ['name', 'longList', 'offerCall', 'comment'];


  columnsToDisplay = this.officeInterviewColumns;
  country = 'lt';
  constructor(
    private route: ActivatedRoute,
    private sidenav: SidenavService,
    private toolbarService: ToolbarService,
    private dialog: MatDialog,
    private unseenOrdersService: UnseenOrdersService
  ) {
    this.toolbarService.hide();
  }

  ngOnInit() {
    this.unseenOrdersService.stopInterval();
    this.route.queryParams.subscribe((v) => {
      if (v['sex']) { this.sex = v['sex'] == 0 ? 'male' : 'female'; }

      if (v.country && SUPPORTED_LOCATIONS.indexOf(v.country)) {
        this.country = v.country;
      }
    });
    this.route.params.subscribe((v) => {
      this.personId = v.personId;
      this.regionName = v.regionName;
    });

    this.route.data.subscribe((v) => {
      if (v.type === WidgetTypes.call) {
        this.columnsToDisplay = this.callInterviewColumns;
      }
    });
    this.sidenav.close();
  }

  close() {}
}
