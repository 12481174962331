<mat-form-field>
    <mat-label> {{ 'COMPANY_COLOR' | translate }}</mat-label>

    <mat-select [(ngModel)]="color" (ngModelChange)="updateColor(color)" [disabled]="disabledColorChange">
       <mat-option *ngFor="let opt of options "
                   [value]="opt" class="{{opt}}">{{opt}}</mat-option>
<!--      <mat-option [value]="companyColors.GREEN" class="GREEN">{{companyColors.GREEN}}</mat-option>-->
<!--      <mat-option [value]="companyColors.GREEN" class="GREEN">{{companyColors.GREEN}}</mat-option>-->
<!--      <mat-option [value]="companyColors.GREEN" class="GREEN">{{companyColors.GREEN}}</mat-option>-->
<!--      <mat-option [value]="companyColors.GREEN" class="GREEN">{{companyColors.GREEN}}</mat-option>-->
<!--      <mat-option [value]="companyColors.YELLOW" class="YELLOW">{{companyColors.YELLOW}}</mat-option>-->
<!--      <mat-option [value]="companyColors.RED" class="RED">{{companyColors.RED}}</mat-option>-->
<!--      <mat-option [value]="companyColors.BLUE" class="BLUE">{{companyColors.BLUE}}</mat-option>-->
    </mat-select>
</mat-form-field>
