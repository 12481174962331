<div fxLayout="row" fxLayoutAlign="space-between center" class="close-container">
  <h3>{{title}}</h3>
  <button mat-dialog-close mat-icon-button>
    <mat-icon >close</mat-icon>
  </button>
</div>
<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="8px" *ngFor="let item of data | keyvalue">
    <h3>{{item.key}}</h3>
    <mat-table [dataSource]="item.value">

      <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef> {{ 'COMPANY' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let jobData"> {{jobData.company}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="job">
        <mat-header-cell *matHeaderCellDef> {{'SPECIFICATION.NAME' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let jobData"> {{jobData.job}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <mat-header-cell *matHeaderCellDef> {{ 'CANDIDATE' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let jobData"> {{jobData.fullName}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="openLink(row.url)"></mat-row>

    </mat-table>
  </div>
</div>
