import { Component, OnInit, Input } from '@angular/core';
import { ContactFieldsShortFragment } from 'src/generated/graphql';
import {ContactSelectDialogComponent} from './contact-select-dialog/contact-select-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-contact-bubble',
  templateUrl: './contact-bubble.component.html',
  styleUrls: ['./contact-bubble.component.scss']
})
export class ContactBubbleComponent {



  @Input() contact: ContactFieldsShortFragment;

  constructor(
    private dialog: MatDialog
  ) { }

  getTooltip(): string {
    return `${this.contact.firstName || ''} ${this.contact.lastName || ''}; ${this.contact.email || ''}; ${this.contact.phone || ''}`;
  }

  getHref(): string {
    if (this.contact.phone) {
      return `tel:${this.contact.phone}`;
    }
    if (this.contact.email) {
      return `mailto:${this.contact.email}`;
    }
    return '';
  }


  openDetailsDialog($event: MouseEvent) {
    this.dialog.closeAll();
    this.dialog.open(ContactSelectDialogComponent, {
      hasBackdrop: true ,
      data: {
        email: this.contact.email,
        phone: this.contact.phone
      },
      position: {
        left: `${$event.clientX}px`,
        top: `${$event.clientY}px`,
      },
      closeOnNavigation: true,
      panelClass: 'padding-12'
    });
  }
}
