<div fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="8px" >
  <mat-form-field fxFlex="100 85" appearance="outline">
    <mat-label>{{'COMPANY_DESCRIPTION' | translate}}</mat-label>
    <textarea matInput rows="4" [formControl]="description"></textarea>

    <mat-hint>{{ 'COMPANY_DESCRIPTION_HINT' | translate }}</mat-hint>
  </mat-form-field>
  <button fxFlex="15" mat-flat-button *ngIf="!saved" (click)="updateCompanyDescription()">{{ 'SAVE' | translate }}</button>
</div>

