<div class="filter-container">
  <app-filter-bar
    menuView="true"
    [initialValue]="filters"
    [filters]="filtersConfig"
    [style]="'bordered-filters'"
    (filterChanges)="updateFilters($event)"
  ></app-filter-bar>
</div>

<div class="table-container">
  <app-candidates-table [filters]="filterObservable | async"></app-candidates-table>
</div>
