import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CreateEventGQL, DeleteEventGQL, FreeVacanciesTableItem, UpdateEventStatusGQL} from '../graphql/graphql';
import {ActionItemStatus, FvPlaningDialogActionItem} from '../fv-planing-dialog-action-item/fv-planing-dialog-action-item';
import {LivasService} from '../../livas/livas.service';
import {merge} from 'rxjs';

@Component({

  selector: 'app-fv-planning-dialog',
  templateUrl: './fv-planning-dialog.component.html',
  styleUrls: ['./fv-planning-dialog.component.scss'],
})
export class FvPlanningDialogComponent implements OnInit {
  events: {node?: FvPlaningDialogActionItem}[];
  totalCount = 0;

  newEvent = {date: '', channels: [], category: '', status: ActionItemStatus.new };
  order: FreeVacanciesTableItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {project: FreeVacanciesTableItem, day: string},
    private createEvent: CreateEventGQL,
    private updateEventStatus: UpdateEventStatusGQL,
    private deleteEvent: DeleteEventGQL,
    private snackBar: MatSnackBar,
    private livas: LivasService
  ) {
    this.newEvent.date = this.data.day;
    this.events = this.data.project.eventSet.edges || [];

    this.order = this.data.project;
    this.countAdvertStats();
  }

  ngOnInit() {


  }

  onSave($event) {
    const input = {
      id: $event.id ? atob($event.id).split(':')[1] : undefined,
      order: atob(this.order.id).split(':')[1],
      category: ($event.category && $event.category.id) ? $event.category.id : $event.category,
      channels: $event.channels ? $event.channels.map(it => it.id ? it.id : it) : [],
      adId: $event.adId,
      externalPlatformId: $event.externalPlatformId,
      comment: $event.comment,
      date: $event.date,
      price: $event.price,
      durationMinutes: $event.durationMinutes,
      language: $event.language,
      //completedBy supports primary keys instead of global ids
      completedBy: $event.completedBy
    };

    this.createEvent.mutate({input}, {refetchQueries: ['getPlannerEventsByOrder']}).subscribe(resp => {
      this.snackBar.open('Changes saved successfully', '', {duration: 3000});
    });
  }

  onDone($event: FvPlaningDialogActionItem) {
    const id = atob($event.id).split(':')[1];
    const done = 'done';
    this.updateEventStatus.mutate({id, transition: done, completedBy: $event.completedBy ?? []}, {refetchQueries: ['getPlannerEventsByOrder']}).subscribe(
      resp => $event.status = resp.data.changeEventStatus.event.status
    );
  }

  onDelete($event: FvPlaningDialogActionItem) {
    const id = atob($event.id).split(':')[1];
    this.deleteEvent.mutate({id}, {refetchQueries: ['getPlannerEventsByOrder']}).subscribe(
      resp => {
        const idx = this.events.findIndex(it => it.node.id === $event.id);
        this.events.splice(idx, 1);

      }
    );
  }

  countAdvertStats() {
    this.totalCount = 0;
    const requests = this.events.map(it => it.node.adId)
                                .filter(it => it)
                                .map(it => this.livas.getContactCountByLivasAdId(it));

    merge(...requests).subscribe(resp => {
      this.totalCount += resp.total_count;
    });
  }
}
