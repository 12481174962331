import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { MeFieldsFragment } from '../../../generated/graphql';
import { ca } from '@fullcalendar/core/internal-common';
import { debug } from 'logrocket';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent implements OnInit {

  showAllOptions: boolean;
  selectedCategories: any;

  private $categories;
  private currentUser: MeFieldsFragment;
  categoryControl = new FormControl();
  public lastSearchedCategory: string;

  @Input() set selected(value) {
    this.categoryControl.setValue(value);
  }

  @Output() selectedChange = new EventEmitter();

  @Input() set user(value: MeFieldsFragment) {
    this.currentUser = value;
    this.updateFilteredCategories();
  }

  @Input() set categories(value) {
    this.$categories = value;
    this.updateFilteredCategories();
  }

  @Input() set disabled(value: boolean) {
    if (value) {
      this.categoryControl.disable();
    }
  }

  @Input() customAutocompleteClass: string;

  constructor() {
  }

  ngOnInit(): void {
    this.categoryControl.valueChanges.subscribe(value => {
      this.selectedCategories = this._filterGroup(value);
    });
  }

  displayCategory(category) {
    return category?.title;
  }

  getGroupTitle(group: any): string {
    if (group.department) {
      return `${group.title} (${group.department})`;
    } else {
      return group.title;
    }
  }

  private _filterGroup(value: string) {
    this.lastSearchedCategory = value;
    let categories = this.$categories;
    if (this.currentUser) {
      categories = categories?.filter(group => group.country?.id === this.currentUser.region?.country.id);
      categories = this.showAllOptions ?
        categories :
        categories?.filter(group => group.department === this.currentUser?.department);
    }

    if (value) {
      return categories?.map(group => ({
        title: group.title,
        children: this.categoryFilter(group.children, value)
      })).filter(group => group.children?.length > 0);
    }
    return categories;
  }

  private categoryFilter(opt, value: string): string[] {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return opt.filter(item => item.title.toLowerCase().includes(filterValue));
    }
  }

  updateFilteredCategories(value?: string) {
    this.selectedCategories = this._filterGroup(value || '');
  }
}
