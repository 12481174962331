<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="hour">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Valanda</th>
      <td mat-cell *matCellDef="let row">{{row.hour}}</td>
    </ng-container>

    <!-- Name Column -->

    <ng-container [matColumnDef]="c" *ngFor="let c of displayedColumns.slice(1)">
      <th mat-header-cell *matHeaderCellDef>{{c}}</th>
      <td mat-cell *matCellDef="let row" [matTooltip]="'Pakviesta: ' + row[c].invited + '   Dirba PV: ' +row[c].pms"
        [ngClass]="row[c].classes">
        <!-- {{row[c].msg}} -->
        <div class="aligner" *ngIf="!row[c].msg">
          <div class="text">{{row[c].invited}}</div>
          <div class="icon">
            <span style="visibility: hidden;" class="material-icons event-icon">event</span>
          </div>
        </div>
        <div class="aligner" *ngIf="row[c].msg">
          <div class="text">{{row[c].msg == null ? row[c].invited : row[c].invited > 0 ? row[c].invited : ' '}}</div>
          <div class="icon">
            <span *ngIf="row[c].msg" class="material-icons event-icon" [matTooltip]="row[c].msg" matTooltipPosition="above">event</span>
          </div>
        </div>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!--  <mat-paginator #paginator-->
  <!--      [length]="dataSource?.data.length"-->
  <!--      [pageIndex]="0"-->
  <!--      [pageSize]="50"-->
  <!--      [pageSizeOptions]="[25, 50, 100, 250]">-->
  <!--  </mat-paginator>-->
</div>


<div class="cities">
  <div *ngFor="let c of cities">
    <mat-form-field appearance="outline">
      <mat-label>{{c}}</mat-label>
      <input matInput [(ngModel)]="pms[c]">
    </mat-form-field>
    <button mat-button (click)="save(c)">Saugoti</button>
  </div>
</div>