<div>
  <mat-dialog-content>
    <p>
      {{'IMPORTING_CONTRACTS_FROM_LIVAS_FOR_COMPANY' | translate}} {{ company.status }} "{{
        company.name
      }}"
    </p>
    <div fxLayoutGap="12px">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedContractor">
          <mat-option
            *ngFor="let contractor of contractors"
            [value]="contractor"
          >
            {{ contractor.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{'SEARCH_TERM' | translate}} </mat-label>
        <input matInput [(ngModel)]="companyTitle" />
      </mat-form-field>
      <button
        mat-stroked-button
        (click)="searchCompany(companyTitle)"
        color="primary"
        type="button"
      >
        {{'SEARCH_LIVAS'| translate}}
      </button>

      <mat-progress-bar
        *ngIf="loading"
        class="example-margin"
        [color]="'primary'"
        [mode]="mode"
      >
      </mat-progress-bar>

      <div style="overflow-y: scroll;">
        <div
          *ngFor="let c of companies; let odd = odd"
          fxLayoutAlign="space-between center"
          [ngClass]="{ grey: odd, company: true }"
        >
          <h5 fxFlex="50">{{ c.title }}({{ c.id }})</h5>
          <span *ngIf="c.contracts?.length === 0; else contractList"
            >{{'THERE ARE NO CONTACTS'| translate}}</span
          >
          <ng-template #contractList>
            <div fxFlex="40">
              <span>Sutartys:</span>
              <div fxLayout="column">
                <span *ngFor="let c1 of c.contracts" class="contract">
                  ID: {{ c1.id }} galioja iki {{ c1.valid }};</span
                >
              </div>
            </div>
          </ng-template>

          <button (click)="importContract(c)" mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      [mat-dialog-close]="selectedCompany"
    >
      {{'CLOSE' | translate}}
    </button>
  </mat-dialog-actions>
</div>
