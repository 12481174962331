import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FreeVacanciesListComponent} from './free-vacancies-list/free-vacancies-list.component';

const routes: Routes = [
  {path: 'free-vacancies', children: [
    {path: 'list/marketing-matrix', component: FreeVacanciesListComponent},
    {path: 'list/calendar', component: FreeVacanciesListComponent},
    {path: 'list', component: FreeVacanciesListComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FreeVacanciesRoutingModule { }
