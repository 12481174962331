import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {TaskSchedulingService} from './task-scheduling.service';


@Component({
  selector: 'app-task-scheduling-dialog',
  templateUrl: './task-scheduling-dialog.component.html',
  styleUrls: ['./task-scheduling-dialog.component.scss'],
})
export class TaskSchedulingDialogComponent {
  scheduleForm: FormGroup;
  todayDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<TaskSchedulingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      taskId: string,
      schedule: any,
      date: Date,
      isEditable: boolean
    },
    private fb: FormBuilder,
    private taskSchedulingService: TaskSchedulingService
  ) {
    this.scheduleForm = this.fb.group({
      endDate: [(data.schedule?.endDate || null), Validators.required],
      recurrencePeriod: [(data.schedule?.recurrencePeriod || null), Validators.required],
      intervalCount: [(data.schedule?.intervalCount || null), Validators.required],
      dayOfWeek: [data.schedule?.dayOfWeek || null],
      weekOfMonth: [data.schedule?.weekOfMonth || null],
      monthInterval: [data.schedule?.monthInterval || null]
    });
    if (!data.isEditable) {
      this.scheduleForm.disable();
    }
  }

  recurrencePeriodChanged() {
    this.scheduleForm.controls['intervalCount'].setValue(null);
    this.scheduleForm.controls['dayOfWeek'].setValue(null);
    this.scheduleForm.controls['weekOfMonth'].setValue(null);
    this.scheduleForm.controls['monthInterval'].setValue(null);
  }

  onSave() {
    if (this.scheduleForm.valid) {
      this.taskSchedulingService
        .saveSchedule(this.data.taskId, this.data.schedule?.id, this.scheduleForm.value)
        .subscribe((result) => {
          if (this.data.schedule) {
            this.dialogRef.close(result.data.updateTaskSchedule.schedule);
          } else {
            this.dialogRef.close(result.data.createTaskSchedule.schedule);
          }
        });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
