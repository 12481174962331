import { OrderTypeEnum } from "src/app/shared/types/order-types";

export type ProgressBarEvent = {
  status: string | null, //'SUCCESS'|'FAIL'|'PENDING'|'REJECTED'|'CANCELLED'
  icon: string,
  id?: string,
  // isFirst: boolean,
  // isLast: boolean,
  // hasSegmentsAfter: boolean,
  index: number
  startDatetime?: Date
};

export enum ProgressStages {
  longListEvent = 'longListEvent',
  introductionCallEvent = 'introductionCallEvent',
  officeInterviewInvitationEvent = 'officeInterviewInvitationEvent',
  officeInterviewEvent = 'officeInterviewEvent',
  presentToClientEvent = 'presentToClientEvent',
  clientInterviewEvent = 'clientInterviewEvent',
  trialDay = 'trialDay',
  healthcheckEvent = 'healthcheckEvent',
  employmentEvent = 'employmentEvent'
}

export type ProgressBarData = {
  responseId: string,
  [ProgressStages.longListEvent]: ProgressBarEvent | null,
  [ProgressStages.introductionCallEvent]: ProgressBarEvent | null,
  [ProgressStages.officeInterviewInvitationEvent]: ProgressBarEvent | null,
  [ProgressStages.officeInterviewEvent]: ProgressBarEvent | null,
  [ProgressStages.presentToClientEvent]: ProgressBarEvent | null,
  [ProgressStages.clientInterviewEvent]: ProgressBarEvent | null,
  [ProgressStages.trialDay]: ProgressBarEvent | null,
  [ProgressStages.healthcheckEvent]: ProgressBarEvent | null,
  [ProgressStages.employmentEvent]: ProgressBarEvent | null,
  lastSegmentIdx: number,
  order: string,
  orderType: OrderTypeEnum,
  candidate: string,
  offerPropositionId?: string
};
