<div class="mat-elevation-z0">
  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <mat-table [dataSource]="dataSource" >
    <ng-container matColumnDef="manager">
      <mat-header-cell *matHeaderCellDef>{{'CLIENT.PROJECT_MANAGER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">{{ node.manager }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="client">
      <mat-header-cell *matHeaderCellDef>{{'CLIENT.CLIENT' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">
        <div fxLayout="column">
          <div>{{ node.companyName }}</div>
          <div>{{ node.companyAddress }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="candidate">
      <mat-header-cell *matHeaderCellDef>{{'CANDIDATE' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">{{ node.candidate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="interviewEvent">
      <mat-header-cell *matHeaderCellDef>{{'INTERVIEW' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">
        <div
          *ngIf="node.interviewEvent"
          class="event"
          fxLayoutAlign="center center"
          [ngClass]="node.interviewEvent.resolutionStatus ? getEventStatus(node.interviewEvent.resolutionStatus) : ''"
        >
          <p>{{ moment(node.interviewEvent.startDatetime).format('YYYY-MM-DD HH:mm') }}</p>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="healthcheckEvent">
      <mat-header-cell *matHeaderCellDef>{{'HEALTHCHECK_HEADER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">
        <div
          *ngIf="node.healthcheckEvent"
          class="event"
          fxLayoutAlign="center center"
          [ngClass]="node.healthcheckEvent.resolutionStatus ? getEventStatus(node.healthcheckEvent.resolutionStatus) : ''"
        >
          <p>{{ moment(node.healthcheckEvent.startDatetime).format('YYYY-MM-DD HH:mm') }}</p>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="employmentEvent">
      <mat-header-cell *matHeaderCellDef>{{'EMPLOYMENT_HEADER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node">
        <div
          *ngIf="node.employmentEvent"
          class="event"
          fxLayoutAlign="center center"
          [ngClass]="node.employmentEvent.resolutionStatus ? getEventStatus(node.employmentEvent.resolutionStatus) : ''"
        >
          <p>{{ moment(node.employmentEvent.startDatetime).format('YYYY-MM-DD HH:mm') }}</p>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef>{{'COMMENTS' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let node" fxLayoutAlign="center center">
        <button
          mat-icon-button
          [disabled]="!node.comments"
          [matTooltip]="getCommentsTooltip(node) || (('ADD' | translate) + ' ' + ('COMMENT' | translate))"
          (click)="openCommentDialog($event, node)"
        >
          <mat-icon *ngIf="!node.comments.length" inline>add_comment</mat-icon>
          <mat-icon *ngIf="node.comments.length" color="accent" inline>comment</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator #paginator
                 [length]="dataSource?.totalCount"
                 [pageIndex]="0"
                 [pageSize]="50"
                 [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
