import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export interface EmployeeAbsencesDataInput {
  fullNames: string[];
  month: string;
  companyId: string;
}

export interface EmployeeAbsencesDataResp {
  [key: string]: { employeesIds: number[] };
}

export interface EmployeeWorkedTimeDataInput {
  employeeIds: number[];
  dateFrom: string;
  dateTo: string;
  companyId: string;
}

export interface EmployeeWorkedTimeDataResp {
  [key: string]: {
    shift_id: string;
    totalWorked: number;
    total_planned_hours: number;
    shiftStatus: string;
    date: string,
    time_management_type: '0' | '1',
    workhours: EmployeeWorkedTimeWorkHours
  }[];
}

export interface EmployeeWorkedTimeWorkHours {
  day: number;
  fact_lunch_end: string;
  fact_lunch_start: string;
  fact_time_end: string;
  fact_time_start: string;
  holiday_day: number;
  holiday_night: number;
  night: number;
  overtime_day: number;
  overtime_holiday_day: number;
  overtime_holiday_night: number;
  overtime_night: number;
  paid_lunch_time: number;
}

export interface SingleShiftDataResp {
  project_id: number;
  project_title: string;
  client_id: number;
  client_title: string;
  position_id: number;
  position_title: string;
  shift_title: string;
  shift_start: string;
  shift_end: string;
  shift_lunch_start: string;
  shift_lunch_end: string;
  paid_lunch_time: string;
  rate_normal_day: number;
}

export interface MonthInfoByClientInput {
  employeeIds: number[];
  month: string;
  companyId: number;
  confirmed: number;
}

export interface MonthInfoByClientResp {
  [key: string]: {
    saved?: {
      day: number;
      night: number;
      holiday_day: number;
      holiday_night: number;
      overtime_day: number;
      overtime_night: number;
      overtime_holiday_day: number;
      overtime_holiday_night: number;
    },
    comfirmed?: {
      day: number;
      night: number;
      holiday_day: number;
      holiday_night: number;
      overtime_day: number;
      overtime_night: number;
      overtime_holiday_day: number;
      overtime_holiday_night: number;
    }
  };
}

export interface CreateNewShiftsFromExistByEmployeesDatesInput {
  shift_id: number;
  employees_id: number[];
  dates: string[];
}

export interface WorkedHoursByShiftCrateUpdateInput {
  employee_id: number;
  start_datetime?: string;
  end_datetime?: string;
  lunch_start_datetime?: string;
  lunch_end_datetime?: string;
  normal_day?: number;
  normal_night?: number;
  holiday_day?: number;
  holiday_night?: number;
  normal_day_overtime?: number;
  normal_night_overtime?: number;
  holiday_day_overtime?: number;
  holiday_night_overtime?: number;
  type: 'hours' | 'absence' | 'worked hours';
  source: number;
}

export interface WorkedHoursByShiftEmployeeData {
  shift_id: number;
  employee_id: number;
  fact_time_start: string;
  fact_time_end: string;
  fact_lunch_start: string;
  fact_lunch_end: string;
  paid_lunch_time: string;
  worked_hours_normal_day: string;
  worked_hours_normal_night: string;
  worked_hours_holiday_day: string;
  worked_hours_holiday_night: string;
  worked_hours_normal_day_overtime: string;
  worked_hours_normal_night_overtime: string;
  worked_hours_holiday_day_overtime: string;
  worked_hours_holiday_night_overtime: string;
  rest_day: number;
}

export interface WorkedHoursByShiftResp {
  results: WorkedHoursByShiftEmployeeData[];
  total_items: number;
  last_page: number;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelWidgetService {
  private apiBase: string;
  private apiV3Base: string;
  private headers: { };

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
    const apiBases = {
      lv: environment.livasAPIBaseLV,
      ee: environment.livasAPIBaseEE,
      lt: environment.livasAPIBaseLT
    };
    const apiV3Bases = {
      lv: environment.livasAPIBaseLVV3,
      ee: environment.livasAPIBaseEEV3,
      lt: environment.livasAPIBaseLTV3,
      gigroup: environment.livasAPIBaseGiGroupV3
    };

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const host = window.location.host;
      let country = '';

      if (params.country) {
        country = params.country;
      } else if (host.includes('.lt')) {
        country = 'lt';
      } else if (host.includes('.lv')) {
        country = 'lv';
      } else if (host.includes('.ee')) {
        country = 'ee';
      } else {
        country = 'lt';
      }

      this.headers = {
        Authorization: `LivasSession ${ params.sessionId ? params.sessionId : (cookieService.get('PHPSESSIONID') || cookieService.get('PHPSESSID') || '') }`
      };

      this.apiBase = apiBases[country];
      this.apiV3Base = apiV3Bases[country];
    });
  }

  configureUrlWithClientCountry(companyCountry: string): string {
    const url = this.apiV3Base.split('/jobs/');
    url[0] = url[0].slice(0, url[0].length - 2) + companyCountry;
    return url.join('/jobs/');
  }

  unicodeEscape(str) {
    return str.replace(/[\u007F-\uFFFF]/g, character => {
      return '\\u' + ('0000' + character.charCodeAt(0).toString(16)).slice(-4);
    });
  }

  getEmployeesByNames(paramsData: EmployeeAbsencesDataInput, companyCountry: string): Observable<EmployeeAbsencesDataResp> {
    return this.http.get<EmployeeAbsencesDataResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/employeeCenter.php?action=findEmployees&base64data=${ btoa(this.unicodeEscape(JSON.stringify(paramsData))) }`,
      { headers: this.headers }
    );
  }

  getEmployeesWorkedTime(paramsData: EmployeeWorkedTimeDataInput, companyCountry: string): Observable<EmployeeWorkedTimeDataResp> {
    return this.http.get<EmployeeWorkedTimeDataResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/employeeCenter.php?action=getTimes&base64data=${ btoa(this.unicodeEscape(JSON.stringify(paramsData))) }`,
      { headers: this.headers }
    );
  }

  getShiftById(shiftId: number, companyCountry: string): Observable<SingleShiftDataResp> {
    return this.http.get<SingleShiftDataResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shift.php?id=${ shiftId }`,
      { headers: this.headers }
    );
  }

  createNewShiftsFromExistByEmployeesDates(data: CreateNewShiftsFromExistByEmployeesDatesInput, companyCountry: string): Observable<any> {
    return this.http.post<any>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shift.php?action=replicate`,
      data,
      { headers: this.headers }
    );
  }

  getMonthInfoByClient(paramsData: MonthInfoByClientInput, companyCountry: string): Observable<MonthInfoByClientResp> {
    return this.http.get<MonthInfoByClientResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/employeeCenter.php?action=getSumTimes&base64data=${ btoa(this.unicodeEscape(JSON.stringify(paramsData))) }`,
      { headers: this.headers }
    );
  }

  createWorkedHoursByShift(data: WorkedHoursByShiftCrateUpdateInput, shiftId: number, companyCountry: string): Observable<any> {
    return this.http.post<any>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shifthours.php?id=${ shiftId }`,
      JSON.stringify(data),
      { headers: this.headers }
    );
  }

  updateWorkedHoursByShift(data: WorkedHoursByShiftCrateUpdateInput, shiftId: number, companyCountry: string): Observable<any> {
    return this.http.patch<any>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shifthours.php?id=${ shiftId }`,
      JSON.stringify(data),
      { headers: this.headers }
    );
  }

  getWorkedHoursByShift(shiftId: number, companyCountry: string): Observable<WorkedHoursByShiftResp> {
    return this.http.get<WorkedHoursByShiftResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shifthours.php?id=${ shiftId }`,
      { headers: this.headers }
    );
  }

  setWorkedHoursAsConfirmed(shiftId: number, companyCountry: string): Observable<any> {
    return this.http.patch<any>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/shifthours.php?id=${ shiftId }`,
      JSON.stringify({ lock: 1 }),
      { headers: this.headers }
    );
  }

  openEmployeeCard(employeeId: number, companyCountry: string): void {
    window.open(`${ this.apiBase }/${ companyCountry }2/personalas.php?id=${ employeeId }`, '_blank');
  }

  openOrderCard(orderId: number, companyCountry: string): void {
    window.open(`${ this.apiBase }/${ companyCountry }/pamainos2.php?id=${ orderId }`, '_blank');
  }
}
