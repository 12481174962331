import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-multiple-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownMultipleFilterComponent implements OnInit {

  @Input() data: {title: string, id: number}[];
  @Input() set preselected(ids: number[]) {
    if (ids && ids.length) {
      this.selection = ids;
    }
  }
  @Input() placeholder: string;
  @Output() change = new EventEmitter<number[]>();

  selection: number[];

  constructor() { }

  ngOnInit() {
  }

}
