<mat-form-field [class.filter-bar-view]="filterBarView">
  <mat-label *ngIf="!filterBarView">{{'CATEGORY' | translate}}</mat-label>
  <mat-select [formControl]="categoryCtrl" multiple="true">
    <div fxLayout="row" fxLayoutGap="16px" [style.padding]="'16px 16px 8px 16px'">
      <mat-checkbox class="example-margin" [(ngModel)]="selection.allSelections" (change)="onGroupSelectionChanged()">
        {{'ALL_SELECTIONS' | translate}}
      </mat-checkbox>
      <mat-checkbox class="example-margin" [(ngModel)]="selection.allOperations" (change)="onGroupSelectionChanged()">
        {{'ALL_OPERATIONS' | translate}}
      </mat-checkbox>
    </div>

    <mat-optgroup *ngFor="let group of categories" [label]="group.title" [class.filled]="group.children?.length" class="category-select-group">
      <mat-option *ngFor="let category of group.children" [value]="category.id">
        {{category.title}}
      </mat-option>
    </mat-optgroup>
  </mat-select>

  <button mat-icon-button matSuffix>
    <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter.emit()" matSuffix>close</mat-icon>
  </button>
</mat-form-field>
