import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { OffersTableDataSource, OffersTableItem } from './offers-table-datasource';
import { CommentDialogComponent } from '../comment-dialog/comment-dialog.component';
import { GroupByPipe } from 'ngx-pipes';
import { ScheduleEventDialogComponent } from '../schedule-event-dialog/schedule-event-dialog.component';
import {
  EventEventType,
  EventResolutionStatus,
  InterviewWidgetOffersGQL,
  OfferEventFieldsFragment,
  OfferPropositionCreateGQL,
  OfferPropositionInterested,
  OfferPropositionInterestedOnCall,
  OfferPropositionLongListed,
  OfferPropositionUpdateGQL
} from '../../../generated/graphql';
import { CancellationReasonDialogComponent } from '../cancellation-reason-dialog/cancellation-reason-dialog.component';
import { debounceTime, first, switchMap } from 'rxjs/operators';
import { CancellationServiceService } from '../cancellation-reason-dialog/cancellation-service.service';
import { OffersTableDataServiceService } from '../offers-table-data-service.service';
import { Subject } from 'rxjs';

const CALL_OFFER_RESPONSES = {
  [OfferPropositionInterestedOnCall.Interested]: 'Susidomėjo skambučio metu',
  [OfferPropositionInterestedOnCall.NotInterested]: 'Siūlė, bet nesudomino skambučio metu',
  [OfferPropositionInterestedOnCall.Undecided]: 'Skambučio metu neturėjo nuomonės'
};

const WAS_NOT_OFFERED = 'Nepasiūlyta skambučio metu';

@Component({
  providers: [GroupByPipe],
  selector: 'app-offers-table',
  templateUrl: './offers-table.component.html',
  styleUrls: ['./offers-table.component.scss'],
})
export class OffersTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OffersTableItem>;
  dataSource: OffersTableDataSource;

  clientInterview: OfferEventFieldsFragment;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'longList', 'offerOnCallStatus', 'offer', 'comment'];

  offerPropositionInterested = OfferPropositionInterested;
  offerPropositionInterestedOnCall = OfferPropositionInterestedOnCall;
  offerPropositionLongListed = OfferPropositionLongListed;
  eventType = EventEventType;
  private searchTerm$ = new Subject<string>();

  filterValue = '';
  originalDS: OffersTableItem[];

  @Input() livasRegionId: string;
  @Input() sex: string;
  private $country: string;
  public loading = true;
  @Input() set country(value: string) {
    this.$country = value;
  };
  @Input() personId: number;
  @Input() set columns(value: string[]) {
    if (value) {
      this.displayedColumns = value;
    }
  }

  constructor(
    private dialog: MatDialog,
    private offersTableGQL: InterviewWidgetOffersGQL,
    private offerPropositionCreateGQL: OfferPropositionCreateGQL,
    private offerPropositionUpdateGQL: OfferPropositionUpdateGQL,
    private groupBy: GroupByPipe,
    private cancellationService: CancellationServiceService,
    private offerDataService: OffersTableDataServiceService
  ) {
    this.searchTerm$
      .pipe(
        debounceTime(300),
        switchMap((search) => {
          this.loading = true;
          return this.offerDataService.fetchOffers(
            this.sex, this.personId, this.livasRegionId, this.$country, search
          );
        })
      )
      .subscribe(
        (data) => {
          this.dataSource.data.next(data);
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.dataSource = new OffersTableDataSource();

    this.getRows();

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  openCommentDialog($event, row) {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      panelClass: ['padding-16-dialog', 'width-650-dialog'],
      data: {
        propositionID: row.offerPropositionId
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.searchTerm$.next(this.filterValue.toLowerCase());
    });
  }

  getRows(search?: string) {

    this.searchTerm$.next(search);
  }

  setAnswer(
    row: OffersTableItem,
    resp: OfferPropositionInterested,
    comment?: string,
    signed?: boolean,
    longListed?: OfferPropositionLongListed
  ) {
    const input = {
      id: row.responseId ? btoa('OfferPropositionType:' + row.responseId) : undefined,
      comment: comment ? comment : row.comment,
      order: row.id,
      personId: this.personId,
      interested: (resp && resp) || undefined,
      interestedOnCall:
        (row.interestedOnCall && row.interestedOnCall) ||
        undefined,
      signed: signed === undefined ? row.signed : signed,
      longListed: longListed === undefined ? row.longListed : longListed
    };

    this.updateResponse(row, input);
  }

  private updateResponse(row: OffersTableItem, input) {
    input.id ? this.updateOfferProposition(row, input) : this.createOfferProposition(row, input);
  }

  private createOfferProposition(row: OffersTableItem, input) {
    this.offerPropositionCreateGQL.mutate({ input }).subscribe((change) => {
      const prop = change.data.offerPropositionCreate?.offerProposition;
      this.parseOfferPropositionResponse(row, prop);
    });
  }

  private updateOfferProposition(row: OffersTableItem, input) {
    this.offerPropositionUpdateGQL.mutate({ input }).subscribe((change) => {
      const prop = change.data.offerPropositionUpdate?.offerProposition;
      this.parseOfferPropositionResponse(row, prop);
    });
  }

  parseOfferPropositionResponse(row: OffersTableItem, prop) {
    row.comment = prop.comment;
    row.interested = prop.interested;
    row.interestedOnCall = prop.interestedOnCall;
    row.responseId = this.getId(prop.id);
    row.offerPropositionId = prop.id;
    row.signed = prop.signed;
    row.candidate = prop.candidate?.id;
    row.longListed = prop.longListed;
  }

  private getId(a: string) {
    return atob(a).split(':')[1];
  }

  setEmployed(row: any, signed: boolean) {
    this.setAnswer(row, row.interested, row.comment, !signed);
  }

  setCallAnswer(
    row: OffersTableItem,
    resp: OfferPropositionInterestedOnCall,
    comment?: string,
    signed?: boolean,
    longListed?: OfferPropositionLongListed,
  ) {
    const input = {
      id: row.responseId ? btoa('OfferPropositionType:' + row.responseId) : undefined,
      comment: comment ? comment : row.comment,
      order: row.id,
      personId: this.personId,
      interestedOnCall: resp,
      interested: (row.interested && row.interested) || undefined,
      signed: signed === undefined ? row.signed : signed,
      longListed: longListed === undefined ? row.longListed : longListed
    };

    this.updateResponse(row, input);
  }

  getTooltipText(row: OffersTableItem) {
    return CALL_OFFER_RESPONSES[row.interestedOnCall] || WAS_NOT_OFFERED;
  }
  isCancelled(events: any, interviewType: string) {
    let data = events?.find(
      (node: any) => node.node.eventType === interviewType
    );
    if (data) {
      if ([EventResolutionStatus.Cancelled,
        EventResolutionStatus.Failed,
        EventResolutionStatus.Rejected
      ].indexOf(data.node.resolutionStatus) > -1) {
        return '#FF5722';
      } else {
        return '#7cb342';
      }
    }
    return '';
  }

  scheduleEvent(row: OffersTableItem, $event, interviewType: string) {
    const dialogRef = this.dialog.open(ScheduleEventDialogComponent, {
      width: '600px',
      data: {
        comment: row.comment,
        relatedOffer: btoa('OfferPropositionType:' + row.responseId),
        candidate: this.personId,
        candidateId: row.candidate,
        interviewType,
        candidateEvent: row.candidateEvent,
        employmentEvent: row?.employmentEvent,
        healthCheckPendingEvent: row?.healthCheckPendingEvent,
        interviewEvent: row?.clientInterviewEvent,
        companyName: row.companyName,
        companyStatus: row.companyStatus
      },
    });

    dialogRef.afterClosed().subscribe((newDate) => {

    });
  }

  filterTable() {
    this.getRows(this.filterValue.toLowerCase());
  }

  private openCancellationReasonDialog(offerPropositionId?: number) {
    return this.dialog.open(CancellationReasonDialogComponent, {
      data: { offerPropositionId, country: this.$country },
      width: '80vw'
    });
  }

  setAnswerWithCancellationReasons(row, response, onCall, longListed?: OfferPropositionLongListed) {
    if (onCall) {
      this.setCallAnswer(row, response);
    } else {
      this.setAnswer(row, response, undefined, undefined, longListed);
    }
    if (row.responseId) {
      this.openCancellationReasonDialog(row.responseId);
    } else {
      const dialog = this.openCancellationReasonDialog();
      dialog.afterClosed().pipe(first()).subscribe(
        selectedIds => {
          if (selectedIds?.length >= 0) {
            this.cancellationService.saveAssignments(row.responseId, selectedIds).subscribe();
          }
        }
      );
    }
  }

  getCommentsTooltip(row: any): string {
    return row.offerpropositioncommentSet?.edges.reduce((acc, comment, idx) => acc + `\n\n#${idx + 1}  ${comment.node.text};`, '') || '';
  }
}
