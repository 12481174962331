import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, merge, BehaviorSubject, interval, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { Company, AllCompaniesGQL, CompaniesPaginatedResponse } from '../../graphql/graphql';

export interface OrdersFilterI {
  company_Name_Icontains?: string;
  unseenProcessedOrders?: boolean;
}
/**
 * Data source for the OrderList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CompaniesListDatasource extends DataSource<Company> {
  data = new BehaviorSubject<Company[]>([]);
  paginator: MatPaginator;
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  filters = new BehaviorSubject<OrdersFilterI>({});
  loadingSubscription: Subscription;
  totalCount = 0;
  loading = new BehaviorSubject<boolean>(true);
  filterLangeCache: number;

  constructor(
    private companiesGQL: AllCompaniesGQL,
    private selectionList: Company[]
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<Company[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.filters.asObservable(),
      this.sort.sortChange,
      this.paginator.page
    ];

    merge(...dataMutations).pipe(debounce(() => interval(500))).subscribe(
      () => {
        this.loading.next(true);
        let endCursor;

        if (this.filterLangeCache >= 0 && this.filterLangeCache !== Object.keys(this.filters.value).length) {
          endCursor = btoa(`arrayconnection:-1`);
          this.paginator.pageIndex = 0;
        } else {
          endCursor = btoa(`arrayconnection:${ (this.paginator.pageIndex) * this.paginator.pageSize - 1 }`);
        }

        this.filterLangeCache = Object.keys(this.filters.value).length;

        const queryParams = Object.assign(
          { }, { first: this.paginator.pageSize, after: endCursor }, this.filters.value
        );

        if ( this.loadingSubscription ) {
          this.loadingSubscription?.unsubscribe?.();
        }

        this.loadingSubscription = this.companiesGQL.watch(
          queryParams, { fetchPolicy: 'cache-and-network' }
        ).valueChanges.subscribe(r => {
          if (r.data && !r.loading) {
            const companies = this.parseEdges(r.data.companies);

            this.selectionList.forEach(selectedCompany => {
              if (!companies.some(company => company.id === selectedCompany.id)) {
                companies.unshift(selectedCompany);
              }
            });

            this.data.next(companies);
            this.totalCount = r.data.companies.totalCount;
            this.endCursor = r.data.companies.pageInfo.endCursor;
            this.loading.next(false);
          }
        });
      }
    );
    return this.data.asObservable();
  }

  private parseEdges(input: CompaniesPaginatedResponse) {
    return input.edges.map(it => it.node);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }
}
