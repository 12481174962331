import { Injectable } from '@angular/core';

import { CheckSuperuserRightsGQL, GetMeGQL } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  constructor(
    private getMeGQL: GetMeGQL,
    private checkSuperuserRightsGQL: CheckSuperuserRightsGQL
  ) {}

  getMe() {
    return this.getMeGQL.watch().valueChanges;
  }

  checkSuperuserRights() {
    return this.checkSuperuserRightsGQL.watch().valueChanges;
  }
}
