<div class="container">
  <div mat-dialog-title fxLayout='row' class='dialog-header' fxLayoutAlign='space-between center'>
    <h6>{{ 'CREATE_TASK' | translate }}</h6>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div [formGroup]="form" fxLayout="column">
    <mat-form-field appearance='outline'>
      <mat-label>{{ 'Select action category' }}</mat-label>
      <mat-select formControlName="nextActionCategory">
        <mat-option *ngFor='let nextAction of data.uniqueActions' [value]='nextAction.id'>
          {{ nextAction.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance='outline'>
      <mat-label>{{ 'COMMENT' | translate }}</mat-label>
      <textarea matInput formControlName='comment'></textarea>
    </mat-form-field>
  </div>

  <div fxLayout fxLayoutAlign='end center'>
    <button mat-flat-button color='accent' [disabled]="form.invalid || isButtonLock" (click)="formSubmit()">
      {{ 'CREATE' | translate }}
    </button>
  </div>
</div>
