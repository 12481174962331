// rating-with-comment-input.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rating-with-comment-input',
  templateUrl: './rating-with-comment-input.component.html',
  styleUrls: ['./rating-with-comment-input.component.scss']
})
export class RatingWithCommentInputComponent implements OnInit {
  @Input() hint: string;
  @Input() label: string;
  @Input() valueController: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
  }

  updateRating(rating: number) {
    this.valueController.get('numericValue').setValue(rating);
  }
  protected readonly FormControl = FormControl;
}
