import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inventa-private-employees',
  templateUrl: './inventa-private-employees.component.html',
  styleUrls: ['./inventa-private-employees.component.scss']
})
export class InventaPrivateEmployeesComponent implements OnInit {

  columnsToDisplay = ['firstName', 'lastName', 'confirmedInOther'];
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      extraValues: {
        firstName: string,
        lastName: string,
        confirmedInOtherCompany: boolean
      },
      title: string
    }
  ) {
    this.title = data.title;
  }

  ngOnInit() {
  }

}
