import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-with-error',
  templateUrl: './image-with-error.component.html',
  styleUrls: ['./image-with-error.component.scss']
})
export class ImageWithErrorComponent implements OnInit {
  canImageBeRendered = true;
  documentContent: string = '';
  isPdf = false;
  @Input()
  set document(document: string) {
    this.documentContent = document;

  }

  @Output() click = new EventEmitter();

  @Output() invalidType = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onImageError($event) {
    if (this.documentContent) {
      this.canImageBeRendered = false;
    }
  }

  onClick() {
    this.click.emit();
  }

}
