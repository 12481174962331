

<div class="container1">
  <div class="container2">
    <div fxLayout="row" class="container3">
      <div fxFlex="50" fxLayout="column">
        <div
          fxFlex="25" fxLayout="row"
          class="schedule-type"
          [ngClass]="{ selectedType: isSelected(scheduleTypesMap.weekday) }"
          (click)="setScheduleTypes(scheduleTypesMap.weekday)">
          <span>{{'SCHEDULE.WEEKDAYS' | translate}}</span>
        </div>
        <div fxFlex="25" fxLayout="row"
          class="schedule-type"
          [ngClass]="{ selectedType: isSelected(scheduleTypesMap.morningShifts) }"
          (click)="setScheduleTypes(scheduleTypesMap.morningShifts)">
          <span>{{'SCHEDULE.MORNING_SHIFTS' | translate}}</span>
        </div>
        <div fxFlex="25" fxLayout="row"
          class="schedule-type"
          [ngClass]="{ selectedType: isSelected(scheduleTypesMap.dailyShifts) }"
          (click)="setScheduleTypes(scheduleTypesMap.dailyShifts)">
          <span>{{'SCHEDULE.DAILY_SHIFTS' | translate}}</span>
        </div>
        <div fxFlex="25" fxLayout="row"
          class="schedule-type"
          [ngClass]="{ selectedType: isSelected(scheduleTypesMap.nightShifts) }"
          (click)="setScheduleTypes(scheduleTypesMap.nightShifts)">
          <span>{{'SCHEDULE.NIGHT_SHIFTS' | translate}}</span>
        </div>
      </div>
      <div fxFlex="25" fxLayout="row"
        class="schedule-type"
        [ngClass]="{ selectedType: isSelected(scheduleTypesMap.weekend) }"
        (click)="setScheduleTypes(scheduleTypesMap.weekend)">
        <span fxFlexAlign="center">{{'SCHEDULE.WEEKENDS' | translate}}</span>
      </div>
      <div fxFlex="25" fxLayout="row"
        class="schedule-type"
        [ngClass]="{ selectedType: isSelected(scheduleTypesMap.workisFreeSchedule) }"
        (click)="setScheduleTypes(scheduleTypesMap.workisFreeSchedule)">
        <span>{{'SCHEDULE.FREE_SCHEDULE' | translate}}</span>
      </div>
    </div>
  </div>

</div>
