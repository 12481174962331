import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompaniesComponent } from './companies.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompanyAdsComponent } from './company-detail/company-ads/company-ads.component';
import { CompanyOrdersComponent } from './company-detail/company-orders/company-orders.component';
import { CompanyEffortsComponent } from './company-detail/company-efforts/company-efforts.component';

const routes: Routes = [
  {path: 'companies/contacts', component: CompaniesComponent},
  {path: 'companies', component: CompaniesComponent},
  {path: 'companies/:id', component: CompanyDetailComponent, children: [
      { path: 'orders', component: CompanyOrdersComponent },
      { path: 'ads', component: CompanyAdsComponent },
      { path: 'efforts', component: CompanyEffortsComponent },
      { path: '', redirectTo: 'orders', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CompaniesRoutingModule { }
