<div class="mat-elevation-z0">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->

    <ng-container matColumnDef="selectAgency">
      <th mat-header-cell *matHeaderCellDef  >
        {{'SPECIFICATION.OFFICE_ISSUED' | translate}}
      </th>
      <td mat-cell *matCellDef="let row"  >
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggle(row, selectionAgency, selectionClient) : null"
                      [checked]="selectionAgency.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                      [disabled]="disabled">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="selectClient">
      <th mat-header-cell *matHeaderCellDef  >
        {{'SPECIFICATION.CLIENT_ISSUED' | translate}}
      </th>
      <td mat-cell *matCellDef="let row"  >
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggle(row, selectionClient, selectionAgency) : null"
                      [checked]="selectionClient.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                      [disabled]="disabled">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name" >
      <th mat-header-cell *matHeaderCellDef  >{{'SPECIFICATION.MEASURE' | translate}}</th>
      <td mat-cell *matCellDef="let row" >{{row.title}}</td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
