import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {ExperienceTypes} from '../../../../specification/specification-edit/specification-edit.component';
import {Tag, TagAssignment} from '../../../../specification/tag-assignment-table/tag';
import {AgeRestriction} from '../../../../specification/specification-edit/age-restriction/age-restriction.component';
import {Certificate, Requirement} from '../../../../graphql/graphql';
import {TagAssignmentTableItem} from '../../../../specification/tag-assignment-table/tag-assignment-table-datasource';
import {TagGroupKey} from '../../../../shared/types/order-types';
import {OrderCreateService} from '../../../../order/order-create/order-create.service';
import { BaseOrderFieldsFragment } from 'src/generated/graphql';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-worker-description',
  templateUrl: './worker-description.component.html',
  styleUrls: ['./worker-description.component.scss']
})
export class WorkerDescriptionComponent implements OnInit, OnDestroy {

  ageFromInit = 18;
  ageToInit = 100;
  tagGroupMap = TagGroupKey;
  mRequirements: Requirement[] = [];
  pRequirements: Requirement[] = [];
  tagAssignments: TagAssignment[] = [];
  get ageFrom() {return this.form && this.form.get('ageFrom'); }
  get ageTo() {return this.form && this.form.get('ageTo');  }
  get passMandatoryRequirementsCtrl() { return this.clientAndContactForm.controls['exportFreetextRequirements'] as FormControl; }

  @Input() isRequirementsCheckboxVisible: boolean;
  @Input() clientAndContactForm: FormGroup;
  @Input() onTagsSelection: ($event: TagAssignmentTableItem[], groupKey: string) => {};
  @Input() transportInfoTags: Tag[];
  private $form: any;
  @Input() set form(value: FormGroup) {
    this.$form = value;
  }

  get form() { return this.$form; }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (val?.specification) {
      this.ageFromInit = val.specification.ageFrom;
      this.ageToInit = val.specification.ageTo;
      this.pRequirements = val.specification.optionalRequirements;
      this.mRequirements = val.specification.mandatoryRequirements;
      this.tagAssignments =  val.specification.tagassignmentSet;
    }
  };
  get order(){
    return this.$order;
  }

  experienceTypes = ExperienceTypes;

  selectedLang: string = '';
  selectedCountry: string = '';
  protected _onDestroy = new Subject<void>();

  constructor(private createOrderService: OrderCreateService) {
    this.createOrderService.selectedLanguage
    .pipe(takeUntil(this._onDestroy))
    .subscribe(l => this.selectedLang = l);

    this.createOrderService.selectedCountry
    .pipe(takeUntil(this._onDestroy))
    .subscribe(l => this.selectedCountry = l);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    if (this.ageFrom && this.ageTo){
      this.onAgeRestrictionChange({from: this.ageFrom.value, to: this.ageTo.value});
    }
  }
  numberInputControl(input) {
    const regex = /[0-9]/;
    if (!regex.test(input.key)) {
      input.preventDefault();
    }
  }
  onAgeRestrictionChange(restrictions: AgeRestriction) {
    if (this.form ) {
       this.form.get('ageFrom').setValue(restrictions.from? restrictions.from : null);
       this.form.get('ageTo').setValue(restrictions.to? restrictions.to: null);
    }
  }
  onRequirementsSelect($event: Requirement[], formKey: string) {
    const arr = [];
    $event.forEach((req) => {
      arr.push(req.id);
    });
    this.form.get(formKey).setValue(arr);
  }
  onCertificatesUpdate(certs: Certificate[]) {
    const arr = [];
    certs.forEach((cer) => {
      arr.push(cer.id);
    });
    this.form.get('certificates').setValue(arr);
  }

  requirementsSelectionChange(event) {
    if (event.checked) {
      this.passMandatoryRequirementsCtrl.setValue(false);
    }
  }
}
