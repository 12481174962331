<h2 mat-dialog-title *ngIf="data.eventType === eventTypes.Interview">
  {{'ORDER.INTERVIEW_EVENTS.TO_BE_INTERVIEWED' | translate}}
</h2>
<h2 mat-dialog-title *ngIf="data.eventType === eventTypes.ClientInterview">
  {{'ORDER.INTERVIEW_EVENTS.TO_BE_INTERVIEWED_CLIENT' | translate}}
</h2>
<h2 mat-dialog-title *ngIf="data.eventType === eventTypes.Employment">
  {{'ORDER.INTERVIEW_EVENTS.TO_BE_EMPLOYED' | translate}}
</h2>
<h2 mat-dialog-title *ngIf="data.eventType === eventTypes.HealthCheckPending">
  {{'ORDER.INTERVIEW_EVENTS.HEALTH_CHECK_PENDING' | translate}}
</h2>
<h2 mat-dialog-title *ngIf="data.eventType === 'offeredInLast7Days'">
  {{'ORDER.INTERVIEW_EVENTS.OFFERED_IN_LAST_7DAYS' | translate}}
</h2>

<mat-dialog-content>
  <ng-container *ngIf="data.eventType == 'offeredInLast7Days'; else otherEvents">
    <app-old-order-offer-stats-table *ngIf="data.orderId"  (click)="$event.stopImmediatePropagation()"
    [orderId]="orderIdInNumber()" [hideFilters]="data?.hideFilters" [filters]="data?.filters">
    </app-old-order-offer-stats-table>
  </ng-container>

</mat-dialog-content>

<ng-template #otherEvents>
  <h4 *ngIf="presentData.length">{{'ACTIVE_RECORDS' | translate}} ({{presentData.length}}) </h4>
  <mat-list>
    <mat-list-item *ngFor="let row of presentData; let odd=odd" [ngClass]="{'selected': odd}">
      <div flexLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
        <div fxFlex="60" style="display: inline-block">
            {{row.candidate.firstName}} {{row.candidate.lastName}}
            <a href="{{urlPrefix + data.country + urlSuffix + row.candidate.livasId}}"
               target="_blank" mat-icon-button inlist="true" tabIndex="-1">
              <mat-icon inline="true">open_in_new</mat-icon>
            </a>
          {{row.resolutionStatus}}
        </div>
        <span fxFlex="40" class="date-col">{{ row.startDatetime | date:'yyyy-MM-dd HH:mm' }}</span>
      </div>
    </mat-list-item>
  </mat-list>
  <mat-divider [inset]="true" *ngIf="pastData?.length > 0"></mat-divider>
  <div class="past-events" *ngIf="pastData?.length > 0">
    <div class="prev-interviewed">
      {{'PAST_RECORDS' | translate}} ({{pastData.length}})
    </div>
    <mat-list>
      <mat-list-item *ngFor="let row of pastData; let odd=odd" [ngClass]="{'selected': odd, 'failed': isFailed(row)}">
        <div flexLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
          <div fxFlex="60" style="display: inline-block">
            {{row.candidate.firstName}} {{row.candidate.lastName}}
            <a href="{{urlPrefix + data.country + urlSuffix + row.candidate.livasId}}" target="_blank" mat-icon-button tabIndex="-1">
              <mat-icon inline="true">open_in_new</mat-icon>
            </a>
          </div>
          <span fxFlex="40" class="date-col">{{ row.startDatetime | date:'yyyy-MM-dd HH:mm' }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</ng-template>
