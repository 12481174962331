<div mat-dialog-title>
  <h3 [style.margin-top]='0'>{{'JOB_AD_DIALOG.COMPOSE_JOB_ADS' | translate }}</h3>
</div>
<div mat-dialog-content>
  <div *ngIf='loading' [style.width]='"100%"' fxLayout='column' fxLayoutAlign='center center' fxLayoutGap='10px'>
    <mat-spinner  mode='indeterminate'></mat-spinner>
  </div>

  <div *ngIf='!loading && !errAdCreation && !createdAdId'>
    <div fxLayout='row' fxLayoutGap='24px' fxLayoutAlign='start start' [formGroup]='selectedOptions'>
      <div [style.font-weight]='700' [style.align-self]="'center'">{{ "JOB_AD_DIALOG.SELECT_OPTIONS" | translate }}:</div>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'CATEGORY' | translate }}</mat-label>
        <mat-select formControlName="categoryId">
          <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.title }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'JOB_AD_DIALOG.CITY' | translate }}</mat-label>
        <mat-select formControlName="locationId">
          <mat-option *ngFor="let location of locations" [value]="location.id">{{ location.title }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'JOB_AD_DIALOG.WORKING_HOURS' | translate }}</mat-label>
        <mat-select formControlName="workingHours">
          <mat-option *ngFor="let variant of workingHoursVariants" [value]="variant.key">{{ variant.title }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="standard">
        <mat-label>{{ 'JOB_AD_DIALOG.SALARY_TYPE' | translate }}</mat-label>
        <mat-select formControlName="salaryType">
          <mat-option *ngFor="let variant of Object.values(SalaryTypeDescription), let i = index" [value]="i">
            {{ variant.replace('_', ' ') }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-salary-range-input [values]='selectedSalaryRange' [disabled]='true' appearance='standard'></app-salary-range-input>
    </div>

    <div *ngIf='variantsFromAiLoading' fxLayout='row' fxLayoutGap='24px' fxLayoutAlign='start center' style="margin-bottom: 16px; font-weight: 600">
      <div>{{ 'JOB_AD_DIALOG.AI_STILL_WORKING' | translate }}</div>
      <mat-spinner mode='indeterminate' diameter='30'></mat-spinner>
    </div>

    <div *ngIf='!variantsFromAiLoading' style='height: 30px; line-height: 30px; margin-bottom: 16px'>
      <button mat-flat-button color="primary" (click)='getAiTryCount = 0; getAiData()'>
        {{ 'JOB_AD_DIALOG.REFRESH_AI' | translate }}
      </button>
    </div>

    <table *ngIf='ads.length'>
      <thead>
      <tr>
        <th></th>
        <th *ngFor='let ad of ads; let index=index'>
          {{ index < variantsFromAiCount ? 'Version ' + (index + 1) : index === variantsFromAiCount ? ('JOB_AD_DIALOG.LAST_AD' | translate) : ('JOB_AD_DIALOG.BEST_AD' | translate) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        *ngFor="let segment of ['title',  'weOffer', 'requirements', 'description',  'seoTitle', 'summary'], let i = index"
        [class.second]='i % 2 !== 0'>
        <td>{{ 'JOB_AD_DIALOG.' + segment | translate }}</td>
        <td *ngFor='let ad of ads; let columnIndex = index' [style.height]="'100%'">
          <div
            class='ad-segment'
            [class.selected]='selectedIndex[segment] === columnIndex'
            (click)='selectSegment(columnIndex, segment)'
          >
            <quill-editor [modules]='{toolbar: false}' [(ngModel)]='ad[segment]' (onContentChanged)='quillInput($event, columnIndex, segment)'></quill-editor>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf='createdAdId'>
    <div>{{ 'JOB_AD_DIALOG.JOB_AD_CREATED' | translate }}</div>
    <a [href]='dom.bypassSecurityTrustUrl(generateAdLink())' target='_blank'>
      {{ generateAdLink() }}
    </a>
  </div>

  <div *ngIf='errAdCreation' class='job-ad-creation-err'>
    {{ 'JOB_AD_DIALOG.JOB_AD_CREATION_ERR' | translate }}
  </div>
</div>

<div mat-dialog-actions align='end'>
  <button (click)='dialogRef.close()' mat-button>{{(createdAdId ? 'CONFIRM' : 'CANCEL') | translate}}</button>
  <button
    *ngIf='!createdAdId'
    mat-button
    [disabled]='loading || adCreationLoading || errAdCreation || !selectedOptions.valid || (Object.keys(selectedIndex).length < 6)'
    (click)='onSubmit()'
  >
    {{'SUBMIT' | translate}}
  </button>
</div>
