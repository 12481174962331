<button mat-icon-button
        (click)="loadDocuments()"
                [matTooltip]="'DOCUMENTS_FOR_THIS_ORDER' | translate"
                [matMenuTriggerFor]="documentsMenu">
          <img src="/assets/dokobit-favicon.png" width="24" height="24">
        </button>


<mat-menu #documentsMenu="matMenu" xPosition="before" >

  <div *ngIf="loadingDocuments" fxLayoutAlign="center center" style="width: 300px">
    <mat-spinner [diameter]="20" ></mat-spinner>
  </div>
  <div *ngIf="!loadingDocuments && !documents.length" style="height: 50px; padding-inline: 10px">
    <span style="word-break: break-word;">{{'NO_DOCUMENTS' | translate}}</span>
  </div>

  <button mat-menu-item *ngFor="let document of documents">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div style="width: 24px">
        <img src="/assets/dokobit-favicon.png" *ngIf="document.node.confirmationMethod==='DOKOBIT'" width="12" height="12" style="margin-right: 12px">
      </div>

      <span>{{document.node.createDate | date:'yyyy-MM-dd'}}</span>
      <div  style="padding-left: 24px">
        <mat-icon style="margin-right: 0" inline="true" [class]="document.node.isSignedBiuro ? 'green' : 'red'"
                  [matTooltip]="(document.node.isSignedBiuro ? 'PM_SIGNATURE_STATUS_DONE' : 'PM_SIGNATURE_STATUS_WAIT') | translate "
        >note_alt</mat-icon>
        <mat-icon inline='true' [class]="document.node.isSignedClient ? 'green': 'red '"
                  [matTooltip]="(document.node.isSignedClient ? 'CLIENT_SIGNATURE_STATUS_DONE' : 'CLIENT_SIGNATURE_STATUS_WAIT') | translate ">
          note_alt
        </mat-icon>

      </div>

      <a mat-icon-button href='{{document.node.signingUrl}}' target='_blank'
         *ngIf='!(document.node.isSignedBiuro && document.node.isSignedClient)'>
        <mat-icon inline>open_in_new</mat-icon>
      </a>


      <button mat-icon-button
              *ngIf='(document.node.isSignedBiuro && document.node.isSignedClient)'
              (click)='downloadFile(document.node.signedDocument, positionTitle)'>
        <mat-icon inline>download</mat-icon>
      </button>

      <button [disabled]='document.node.isSignedClient' [ngStyle]="{'opacity': document.node.isSignedClient ? 0.5 : 1}"
              mat-icon-button (click)='resendOrderSigning(document.node)'>
        <mat-icon inline>attach_email</mat-icon>
      </button>


    </div>

  </button>
</mat-menu>
