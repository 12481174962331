<div>
  <mat-dialog-content>
    <mat-horizontal-stepper #stepper [linear]="true">
      <mat-step [formGroup]="importerForm" label="import company" [stepControl]="importerForm">

        <mat-form-field style="margin-right: 16px">
          <mat-label>{{'COMMON.SEARCH'|translate}}</mat-label>
          <input matInput #companySearch formControlName="companySearch">
        </mat-form-field>

        <button mat-stroked-button (click)="searchCompany(companySearch.value)" type="button">
          {{"LOOKUP_INSIGHTLY" | translate}}
        </button>

        <mat-progress-bar
          *ngIf="loading"
          class="example-margin"
          [color]="'primary'"
          [mode]="mode">
        </mat-progress-bar>

        <div style="overflow-y: scroll">
          <div *ngFor="let c of companies" fxLayoutAlign="space-between center">
            <h4 style="width: 200px;">{{c.status}} "{{c.name}}"</h4>
            <span style="width: 200px;"> {{c.country}}</span>
            <button (click)="importCompany(c)" mat-icon-button matTooltip="{{'IMPORT_COMPANY' | translate}} " >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step label="import contract">
        <p> {{'IMPORTING_CONTRACTS_FROM_LIVAS_FOR_COMPANY' | translate}} {{importedCompany?.value?.status}} "{{importedCompany?.value.name}}"</p>
        <div fxLayoutGap="12px">
          <mat-form-field>
            <mat-select [(ngModel)]="selectedContractor">
              <mat-option *ngFor="let contractor of contractors" [value]="contractor">
                {{contractor.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>  {{'COMMON.SEARCH' | translate}} </mat-label>
            <input matInput [(ngModel)]="companyContractTitle">
          </mat-form-field>
          <button mat-stroked-button (click)="searchCompanyContracts(companyContractTitle)"
                  color="primary"
                  type="button">{{'SEARCH_LIVAS' | translate}}</button>

          <mat-progress-bar
            *ngIf="loading"
            class="example-margin"
            [color]="'primary'"
            [mode]="mode">
          </mat-progress-bar>

          <div style="overflow-y: scroll" >


            <div *ngFor="let c of contractCompanies; let odd=odd" fxLayoutAlign="space-between center" [ngClass]="{'grey': odd, 'company' : true}">
              <h5 fxFlex="50">{{c.title}}({{c.id}})</h5>
              <span *ngIf="c.contracts?.length===0; else contractList">{{'CONTRACTS_NOT_FOUND' | translate}}</span>
              <ng-template #contractList>
                <div fxFlex="40">
                  <span>{{'CONTRACTS' | translate}}:</span>
                  <div fxLayout="column">
                    <span *ngFor="let c1 of c.contracts" class="contract"> ID: {{c1.id}} {{'VALID_TILL' | translate }} {{c1.valid}};</span>
                  </div>
                </div>

              </ng-template>

              <button (click)="importContract(c)" mat-icon-button color="primary" ><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </div>

      </mat-step>
    </mat-horizontal-stepper>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary"  [mat-dialog-close]="selectedCompany">{{'CLOSE' | translate}}</button>
  </mat-dialog-actions>
</div>
