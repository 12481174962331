import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {Requirement} from '../../graphql/graphql';
import {BehaviorSubject, Subject} from 'rxjs';
import {CreateRequirementGQL, RequirementsGQL} from '../../../generated/graphql';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
// import {CreateRequirementGQL} from '../specification-edit/graphql';

@Component({
  selector: 'app-add-requirement-dialog',
  templateUrl: './add-requirement-dialog.component.html',
  styleUrls: ['./add-requirement-dialog.component.scss']
})
export class AddRequirementDialogComponent implements OnInit, OnDestroy {
  localValue = new FormControl('', [Validators.required]);
  englishValue = new FormControl('', []);
  requirements = new BehaviorSubject<Requirement[]>([]);
  created: string;
  inputChanges = new BehaviorSubject<string>('');
  protected _onDestroy = new Subject<void>();

  constructor(
    private requirementsGQL: RequirementsGQL,
    private createRequirementGQL: CreateRequirementGQL,
    private dialogRef: MatDialogRef<AddRequirementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {selectedLang: string, selectedCountry: string}
    // private apollo: Apollo
  ) { }

  ngOnInit() {
    this.onInputChangeListener();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onInputChangeListener() {

    // this.requirementsGQL.watch({requirement: value}, {fetchPolicy: 'cache-and-network'}).valueChanges.subscribe(
    //   v => this.requirements.next(v.data.requirements)
    // );

    this.localValue.valueChanges
    .pipe(
      takeUntil(this._onDestroy),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((val) => this.requirementsGQL.watch(
        {
          requirement: val,
          country: this.data.selectedCountry,
          language: this.data.selectedLang
        }, {fetchPolicy: 'cache-and-network'})
        .valueChanges)
    ).subscribe((v) => {
      this.requirements.next(v.data.requirements);
    });

    this.englishValue.valueChanges
    .pipe(
      takeUntil(this._onDestroy),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((val) => this.requirementsGQL.watch(
        {
          requirement: val,
          country: this.data.selectedCountry,
          language: this.data.selectedLang
        }, {fetchPolicy: 'cache-and-network'})
        .valueChanges)
    ).subscribe((v) => {
      this.requirements.next(v.data.requirements);
    });
  }


  createRequirement() {
    this.createRequirementGQL.mutate(
      {englishValue: this.englishValue.value, localValue: this.localValue.value, country: this.data.selectedCountry},
      {
        refetchQueries: [{query: this.requirementsGQL.document}],
      })
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
      value => {
        this.created = value.data.createRequirement.requirement.requirement;
        this.dialogRef.close(this.created);
      }
    );
  }
}
