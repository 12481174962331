import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Moment} from 'moment';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';


export enum LivasStatuses {

  didntPickup = 2,
  interviewInOffice = 3,
  interviewRemote = 4,
  wontOffer = 6, // Nesiūlysime darbo
  currentlyNotActive = 18, // Šiuo metu neaktualu
  candidateWaitsOffer = 19, // Laukia pasiūlymo
  clientInterview = 14,
  healthCheckInProgress = 15,
  healthCheckCompleted = 16,
  waitingDecision = 35, // Laukiama sprendimo (staginge buvo 31 keičiam nes prode 35)

  trialDay = 26,

  cannotContact = 34, // Nepavyko susisiekti
  introducingToClient = 36,
  employedByClientStaffing = 20,
  employedByClientSelections = 27,
  employed = 17, // Dirba
  redirectedToWorkis = 25

}

export class LivasShift {
  constructor(
    public id: string,
    public date: string,
    public position: string,
    public startTime: string,
    public endTime: string,
    public lunchStart: string,
    public lunchDuration: string,
  ) {}

}
@Injectable({
  providedIn: 'root'
})
export class LivasService {

  prefix = `${environment.url}`

  constructor(
    private http: HttpClient
  ) { }




  getCompanyWithContracts(title, contractorId, country) {
    const url = `${this.prefix}/api/v1/livas/company_list/`;
    return this.http.get(url, {params: {title, contractor: contractorId, country}});
  }

  getProjectShifts(projectId) {
    const data = {url: `https://edla.biuro.lt/_dev/lt/pamainos2.php?action=list&output=json&id=${projectId}&filter-attr=&filter-attr-exclude=&filter-sort-by=data&filter-sort-order=desc`}
    const url = `${this.prefix}/api/v1/livas/proxy/`;
    return this.http.post<{data: [][]}>(url, data).pipe(
      map(d => d.data.map(this.parseProjectShift))
    );
  }

  updateUserHealthcheck(userId, date: string, fileType: string) {
    const url = `${this.prefix}/api/v1/livas/update_next_healthcheck/`;
    return this.http.post(url, {livas_user_id: userId, valid_till: date, file_type: fileType});
  }

  getLivasEmployeeData(userId) {
    const url = `${this.prefix}/api/v1/livas/${userId}/user/`;
    return this.http.get(url);
  }

  getContactCountByLivasAdId(adId): Observable<{total_count: number}> {

    const url = `${this.prefix}/api/v1/livas/contacts_count_by_ad/`;
    return this.http.get<{total_count: number}>(url, {params: {ad_id: adId}});
  }

  private parseProjectShift(shift): LivasShift {

    return new LivasShift(shift[0], shift[1], shift[2], shift[7], shift[8], shift[9], shift[10])

    ;
  }

}
