import { Component, OnInit } from '@angular/core';
import {GetUserProfilesGQL, UserProfileFieldsFragment} from '../../../generated/graphql';

@Component({
  selector: 'app-select-person-dialog',
  templateUrl: './select-person-dialog.component.html',
  styleUrls: ['./select-person-dialog.component.scss']
})
export class SelectPersonDialogComponent implements OnInit {
  userprofiles: UserProfileFieldsFragment[] = []

  constructor(
    private getUserProfilesGQ: GetUserProfilesGQL
  ) { }

  ngOnInit(): void {
    this.getUserProfilesGQ.watch({}, {fetchPolicy: 'cache-and-network'}).valueChanges.subscribe(
      resp => {
        if (resp.data) {
          this.userprofiles = resp.data.userprofiles;
        }
      }
    );
  }


}
