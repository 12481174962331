<div fxLayout="row" fxLayoutAlign="space-between center">
  <app-filter-bar (filterChanges)="onFilterChanges($event)" [filters]="['search', 'company', 'phone']" #filterBar></app-filter-bar>
  <button mat-stroked-button fxLayout="row" [style.margin-right]="'12px'" (click)='openAddContactDialog()'>
    <mat-icon>add</mat-icon>
    <span>{{ 'ADD' | translate }}</span>
  </button>
</div>

<div [style.position]="'relative'">
  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <table mat-table class="full-width-table" matSort>
    <!-- FULL_NAME Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'FULL_NAME' | translate}}</th>
      <td mat-cell *matCellDef="let contact" class="company-title">
        <span>{{contact.firstName}} {{contact.lastName}}</span>
      </td>
    </ng-container>

    <!-- COMPANY Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <span>{{contact.company.name}} </span>
      </td>
    </ng-container>

    <!-- PHONE Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PHONE' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <div [style.display]="'inline-block'" (click)="$event.stopPropagation()">
          <a href="tel:{{contact.phone}}" mat-icon-button [disabled]="!contact.phone">
            <mat-icon>phone</mat-icon>
          </a>
        </div>

        <span>{{contact.phone}} </span>
      </td>
    </ng-container>

    <!-- EMAIL Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER.EMAIL' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <div [style.display]="'inline-block'" (click)="$event.stopPropagation()">
          <a href="mailto:{{contact.email}}" mat-icon-button target="_blank" [disabled]="!contact.email">
            <mat-icon>mail</mat-icon>
          </a>
        </div>

        <span>{{contact.email}} </span>
      </td>
    </ng-container>

    <!-- INSIGHTLY_ID Column -->
    <ng-container matColumnDef="insightlyId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'INSIGHTLY_ID' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <mat-icon [style.color]="contact.insightlyId ? '#7CB342' : '#FF5722'">
          {{ contact.insightlyId ? 'check_circle' : 'cancel' }}
        </mat-icon>
      </td>
    </ng-container>

    <!-- COMMENT Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMMENT' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <span>{{contact.comment}} </span>
      </td>
    </ng-container>

    <!-- VISIBILITY Column -->
    <ng-container matColumnDef="visibility">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'VISIBILITY' | translate}}</th>
      <td mat-cell *matCellDef="let contact">
        <div
          *ngIf="contact.canUseInOrder"
          class="can-use-in-order-container"
          fxLayout="row"
          fxLayoutAlign="start center"
          (click)="$event.stopPropagation(); updateContactVisibility(contact, false)"
        >
          <mat-icon>visibility</mat-icon>
        </div>

        <div
          *ngIf="!contact.canUseInOrder"
          class="can-use-in-order-container"
          fxLayout="row"
          fxLayoutAlign="start center"
          (click)="$event.stopPropagation(); updateContactVisibility(contact, true)"
        >
          <mat-icon>visibility_off</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
        (click)='openEditContactDialog(row.id)'
    ></tr>
  </table>
</div>

<mat-paginator [length]="dataSource?.totalCount"
               [pageIndex]="0"
               [pageSize]="50"
               [pageSizeOptions]="[10, 25, 50, 100]">
</mat-paginator>
