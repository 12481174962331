<form [formGroup]="contactForm" class="mat-typography" *ngIf="contactForm">
  <div fxLayout="column" fxLayoutGap="16px">
    <p>{{data?.contactId ? 'Edit contact' : 'Create new contact'}}</p>

    <div *ngIf='!data' fxFlex>
      <app-company-selector
        fxFlex="100"
        appearance="legacy"
        clearable="true"
        hidePrefix="true"
        (companyChange)="selectCompany($event)"
      ></app-company-selector>
    </div>

    <div fxFlex fxLayoutGap="16px">
      <mat-form-field  fxFlex="50" appearance="outline">
        <mat-label>{{'USER.NAME' | translate}}</mat-label>
        <input matInput formControlName="firstName">
      </mat-form-field>

      <mat-form-field  fxFlex="50" appearance="outline">
        <mat-label>{{'USER.SURNAME' | translate}}</mat-label>
        <input matInput formControlName="lastName">
      </mat-form-field>
    </div>

    <div fxFlex fxLayoutGap="16px">
      <mat-form-field  fxFlex="50" appearance="outline">
        <mat-label>{{'USER.EMAIL' | translate}}</mat-label>
        <input matInput formControlName="email" type="email">
        <a href="mailto:{{contactForm.get('email').value}}" mat-icon-button matSuffix target="_blank">
          <mat-icon>mail</mat-icon>
        </a>
      </mat-form-field>

      <mat-form-field  fxFlex="50" appearance="outline">
        <mat-label>{{'USER.PHONE' | translate}}</mat-label>
        <input matInput formControlName="phone">
        <a href="tel:{{contactForm.get('phone').value}}" mat-icon-button matSuffix>
          <mat-icon >phone</mat-icon>
        </a>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field  fxFlex appearance="outline">
        <mat-label>{{'COMMENT' | translate}}</mat-label>
        <input matInput formControlName="comment">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="space-between center">
      <a href="https://crm.na1.insightly.com/list/Contact/?blade=/details/contact/{{contact?.insightlyId}}"
         target="_blank" mat-icon-button [disabled]="!contact?.insightlyId" matTooltip="{{'OPEN_IN_INSIGHTLY' | translate}}">
          <mat-icon>open_in_new</mat-icon>
      </a>

      <button
        mat-flat-button
        color="primary"
        [disabled]="!contactForm.valid"
        (click)="createContact()"
      >
        {{(data?.contactId ? 'COMMON.SAVE' : 'COMMON.CREATE') | translate}}
      </button>
    </div>
  </div>
</form>
