import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

import { CandidatesTableDataGQL, CandidatesTableDataQuery } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  constructor(
    private candidatesTableDataGQL: CandidatesTableDataGQL
  ) {}

  getTableData(filters?): Observable<ApolloQueryResult<CandidatesTableDataQuery>> {
    return this.candidatesTableDataGQL.watch(filters, { fetchPolicy: 'no-cache' }).valueChanges;
  }
}
