<div style="margin-top:16px; margin-bottom: 4px;">
  <mat-hint>{{tagGroup | translate}}</mat-hint>
</div>

<div fxLayout="row" fxLayoutGap="10px grid" class="ti-container" >
    <mat-form-field [fxFlex]="showHints ? 50: 100" appearance="outline" >
        <textarea matInput [(ngModel)]="value" [readonly]="readonly"></textarea>
    </mat-form-field>
    <div fxLayout="column" fxFlex="50" [fxShow]="showHints" fxHide>
        <mat-checkbox
          color="primary"
          *ngFor="let tag of tags"
          [value]="tag"
          [(ngModel)]="selectedTag[tag.id]"
          [ngClass]="{'stricken-text': selectedTag[tag.id]}">
          {{tag.title}}
        </mat-checkbox>
    </div>
</div>
