<div fxLayout="row" fxLayoutAlign="space-between center" class="close-container">
  <h3>{{title}}</h3>
  <button mat-dialog-close mat-icon-button>
    <mat-icon >close</mat-icon>
  </button>
</div>
<div fxLayout="column" fxLayoutGap="16px">
    <mat-table [dataSource]="data.extraValues">

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> {{'USER.NAME' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let employee"> {{employee.firstName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> {{'USER.SURNAME' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let employee"> {{employee.lastName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedInOther">
        <mat-header-cell *matHeaderCellDef> {{'APPROVED_BY_ANOTHER_COMPANY' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let employee"> {{employee.confirmedInOtherCompany ? 'Taip' : 'Ne'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>

    </mat-table>
</div>
