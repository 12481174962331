<div style="display: inline-block">
<!--  <span class="hint">{{'COMMON.FROM' | translate}}</span>-->
  <span [ngClass]="{'manually-edited': value.fromEdited, 'value': true}"
        [matTooltip]=" (value.fromEdited? 'MANUALLY_ENTERED' : 'CALCULATED' ) | translate ">
    {{value.fromValue | number:'1.2-2'}}
  </span>
<!--  <span class="hint">{{'COMMON.TO' | translate}}</span>-->
  <span *ngIf="value.toValue"> - </span>
  <span [ngClass]="{'manually-edited': value.toEdited, 'value': true}"
        [matTooltip]=" (value.fromEdited? 'MANUALLY_ENTERED' : 'CALCULATED' ) | translate ">
    {{value.toValue | number:'1.2-2'}}
  </span>
</div>
