<div fxLayout="column">


  <div fxLayout="row wrap" fxLayoutAlign="center" class="main-container">
    <mat-card *ngFor="let key of cardsOrder; let i = index"
              [ngClass]="classPicker(cardsData[key])"
              (click)="handleCardClick(cardsData[key])"
              class="dashboard-card"
              fxLayout="column"
              id="card-id-{{i}}">
      <div fxLayout="column" id="card-id-{{i}}-content" *ngIf="!cardsData[key].extraVisible">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <span>{{cardsData[key].title}}</span>
          <a class="help-icon" matTooltip="Nuoroda į procesą" target="_blank" (click)="$event.stopPropagation()" *ngIf="cardsData[key].processLink" href={{cardsData[key].processLink}}>
            <mat-icon>help_outline</mat-icon>
          </a>
        </div>
        <mat-card-content class="dashboard-card-content">
          <div class="item-count" *ngIf="cardsData[key].value !== undefined; else spinner">{{cardsData[key].value}}</div>
        </mat-card-content>
        <ng-template #spinner>
          <div fxLayout="row" fxLayoutAlign="center center" style="height: 120px;">
            <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
          </div>
        </ng-template>
      </div>

    </mat-card>
  </div>
  <div class="mat-hint mat-typography" *ngIf="lastRefresh" fxLayout="row" fxLayoutAlign="flex-start center">
    <span>{{'LAST_REFRESH' | translate}} {{lastRefresh | date:'HH:mm'}}</span>
    <mat-icon class="refresh-icon" (click)="fetchStats()">refresh</mat-icon>
  </div>


</div>
