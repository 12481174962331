import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';


export interface DataSeriesObject {
  name: string;
  value: number;
}

@Injectable({providedIn: 'root'})
export class DemandGQL extends Query<{statsGetAggregatedDemandStaffing: DataSeriesObject[], statsGetAggregatedDemandSelection: DataSeriesObject[]}> {
  document = gql`
    query($dateFrom: Date!, $dateTo: Date!) {
      statsGetAggregatedDemandStaffing(dateFrom: $dateFrom, dateTo: $dateTo) {
        name
        value
      }
      statsGetAggregatedDemandSelection(dateFrom: $dateFrom, dateTo: $dateTo) {
        name
        value
      }
    }
  `;
}
