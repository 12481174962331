import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsencesRoutingModule } from './absences-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AbsencesRoutingModule,
    SharedModule,
    FullCalendarModule,
    TranslateModule
  ]
})
export class AbsencesModule { }
