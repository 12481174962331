import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FiledDownloaderService {

  constructor(
    private http: HttpClient
  ) { }

  downloadPdfFile(url: string, filename?: string): void {
    this.http.get(url, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename ? `${filename}.pdf` : 'downloaded-file.pdf';
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    });
  }
}
