import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { UserProfile } from 'src/app/graphql/graphql';
import { map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-project-manager-chip-selector',
  templateUrl: './project-manager-chip-selector.component.html',
  styleUrls: ['./project-manager-chip-selector.component.scss']
})
export class ProjectManagerChipSelectorComponent implements OnInit {

  private _pms;

  selectedPms: UserProfile[] = [];
  filteredPms: Observable<UserProfile[]>;

  pmCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('pmInput') pmInput: ElementRef<HTMLInputElement>;
  @Input()
  set pms(pms: UserProfile[]) {
    this._pms = pms;
    this.filteredPms = this.pmCtrl.valueChanges
      .pipe(
        map(value => this._filter(value))
      );
  }
  get pms(): UserProfile[] {
    return this._pms;
  }

  @Input()
  set preselectPm(pm: UserProfile) {
    this.selectedPms.push(pm);
    this.selectedManagerChange.emit(this.selectedPms);
  }

  @Output() selectedManagerChange = new EventEmitter<UserProfile[]>();

  constructor(
  ) { }

  ngOnInit() {
  }

  removePm(id: number): void {
    // this.selectedPms = this.selectedPms.filter(e => e.id !== id);
    this.selectedManagerChange.emit(this.selectedPms);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedPms.push(event.option.value);
    this.pmInput.nativeElement.value = '';
    this.pmCtrl.setValue(null);
    this.selectedManagerChange.emit(this.selectedPms);
  }

  displayFn(pm?: UserProfile): string | undefined {
    return pm ? `${pm.user.firstName} ${pm.user.lastName}` : undefined;
  }

  private _filter(value): UserProfile[] {
    if (value && !value.id) {
      return this.pms.filter(pm => 
        `${pm.user.firstName} ${pm.user.lastName}`.toLowerCase().includes(value.toLowerCase()) && !this.selectedPms.map(e => e.id).includes(pm.id));
    }
    return this.pms.filter(e => !this.selectedPms.map(e => e.id).includes(e.id));
  }

}
