import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {
  imageUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {imageUrl: string}
  ) {
    this.imageUrl = data.imageUrl;
  }

  ngOnInit() {
  }

}
