import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {
  InputValueStatus,
  SalaryRangeChangeEvent,
  SalaryRangeTypes,
  SalaryRangeValues
} from '../../orders/order-create/salary-matrix/salary-matrix-values';

@Component({
  selector: 'app-salary-range-input',
  templateUrl: './salary-range-input.component.html',
  styleUrls: ['./salary-range-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SalaryRangeInputComponent implements OnInit {
  inputStatuses = InputValueStatus;
  fromEdited = InputValueStatus.clean;
  toEdited = InputValueStatus.clean;
  salaryForm: FormGroup;

  private $value: SalaryRangeValues;

  @Input() disabled = false;
  @Input() appearance: 'standard' | 'outline' = 'outline';
  @Input() set values(value: SalaryRangeValues) {

    if (value) {
      this.fromEdited = value.fromEdited;
      this.toEdited = value.toEdited;
      this.$value = value;

      if (this.salaryForm) {
        this.salaryForm.get('from').setValue(value.fromValue, { emitEvent: false });
        this.salaryForm.get('to').setValue(value.toValue, { emitEvent: false });
      }
    }

  }

  @Output() valueChange = new EventEmitter<SalaryRangeChangeEvent>();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
  }


  private initForm() {
    this.salaryForm = this.fb.group({
      from: new FormControl(this.$value ? this.$value.fromValue : undefined, [Validators.min(0)]),
      to: new FormControl(this.$value ? this.$value.toValue : undefined, [Validators.min(0)])
    });
    if (this.disabled) { this.salaryForm.disable(); }
    this.salaryForm.get('from').valueChanges.pipe(debounceTime(1000)).subscribe(
      newValue  => {
          this.fromEdited = this.salaryForm.get('from').dirty ? InputValueStatus.edited : this.fromEdited;
          this.valueChange.emit({
            value: {
                fromValue: parseFloat(this.salaryForm.get('from').value),
                toValue: parseFloat(this.salaryForm.get('to').value),
                fromEdited: this.fromEdited,
                toEdited: this.toEdited,
            },
            lastChangedType: SalaryRangeTypes.from,
            lastChangedValue: newValue
        });
    });

    this.salaryForm.get('to').valueChanges.pipe(debounceTime(1000)).subscribe(
      newValue  => {
          this.toEdited = this.salaryForm.get('to').dirty ? InputValueStatus.edited : this.toEdited;
          this.valueChange.emit({
            value: {
                fromValue: parseFloat(this.salaryForm.get('from').value),
                toValue: parseFloat(this.salaryForm.get('to').value),
                fromEdited: this.fromEdited,
                toEdited: this.toEdited,
            },
            lastChangedType: SalaryRangeTypes.to,
            lastChangedValue: parseFloat(newValue)
        });
    });


    // this.salaryForm.valueChanges.subscribe(
    //   value => {
    //     this.fromEdited = this.salaryForm.get('from').dirty || this.fromEdited;
    //     this.toEdited = this.salaryForm.get('to').dirty || this.toEdited;
    //     console.log(value);
    //     // this.valueChange.emit({value: {
    //     //   fromValue: parseFloat(this.salaryForm.get('from').value),
    //     //   toValue: parseFloat(this.salaryForm.get('to').value),
    //     //   fromEdited: this.fromEdited,
    //     //   toEdited: this.toEdited,
    //     // });
    //   }
    // );
  }

  getTooltipText(status: InputValueStatus) {
    return status ? (status === InputValueStatus.warning ? 'SPECIFICATION.CHECK_IF_EDIT_NEEDED' : 'SPECIFICATION.VALUE_WAS_EDITED')
                  : 'SPECIFICATION.AUTOMATICALLY_CALCULATED';
  }
}
