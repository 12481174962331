<div class="container send-report-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="head">
    <span>{{ 'ENTER_EMAILS_TO_SEND' | translate }}</span>
    <mat-icon (click)="dialog.closeAll()">close</mat-icon>
  </div>

  <mat-form-field>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let email of emails" [selectable]="false" [removable]="true" (removed)="remove(email)">
        {{email}}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
      <input placeholder="{{ !emails.length ? ('ENTER_EMAIL' | translate) : '' }}"
             #input
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="true"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <button mat-icon-button matSuffix (click)="customAdd()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="end center" class="actions">
    <button mat-raised-button [disabled]="!emails.length" (click)="send()">{{ 'SEND_REPORT' | translate }}</button>
  </div>
</div>
