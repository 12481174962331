import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ActionItemStatus,
  FvPlaningDialogActionItem,
} from './fv-planing-dialog-action-item';
import { PlannerMetadataGQL } from '../graphql/graphql';
import * as moment from 'moment';

export const DEMO_CATEGORIES = [
  { id: 1, title: 'kita', icon: 'business' },
  { id: 2, title: 'Sandėliai', icon: 'business' },
];

@Component({
  selector: 'app-fv-planing-dialog-action-item',
  templateUrl: './fv-planing-dialog-action-item.component.html',
  styleUrls: ['./fv-planing-dialog-action-item.component.scss'],
})
export class FvPlaningDialogActionItemComponent implements OnInit {
  channels = [];
  // {id: 1, title: 'Facebook'},
  // {id: 2, title: 'Facebook Groups'},
  // {id: 3, title: 'Google'},
  // {id: 4, title: 'CV Online'},
  // ];

  categories = [];

  @Input() public action: FvPlaningDialogActionItem;
  @Input() public new: boolean;

  @Output() save = new EventEmitter<FvPlaningDialogActionItem>();
  @Output() done = new EventEmitter<FvPlaningDialogActionItem>();
  @Output() delete = new EventEmitter<FvPlaningDialogActionItem>();
  statuses = ActionItemStatus;
  unsavedChanges = false;
  selectedUsersForNewAction: string[] = [] ;

  constructor(private metaGQL: PlannerMetadataGQL) {}

  ngOnInit() {
    this.metaGQL.fetch().subscribe((v) => {
      this.channels = v.data.plannerChannels;
      this.categories = v.data.plannerCategories;
    });
  }

  getTitle(c: number, categories: { id: number; title: string }[]) {
    const matches = categories.filter((it) => it.id === c);
    return matches.length > 0 ? matches[0].title : 'NA';
  }

  onSave() {
    this.action.date = moment(this.action.date).format('YYYY-MM-DD');
    this.action.completedBy = this.selectedUsersForNewAction.map(it => atob(it).split(':')[1]);
    this.save.emit(this.action);
  }


  onDelete() {
    this.delete.emit(this.action);
  }

  onOpenNew() {
    window.open(
      `https://app.workis.online/client/orders?orderId=${this.action?.workisJobId}`,
      '_blank'
    );
  }

  onUserChange(userIds: string[]) {
    this.selectedUsersForNewAction = userIds.map(it => btoa('UserProfileMType:' + it));
  }
}
