<mat-dialog-content class='reduced-component'>
  <form [formGroup]='newMetricValueForm'>
    <div class='first-row' fxLayout='row' fxLayoutGap='16px'>
      <!-- date field -->
      <mat-form-field appearance='outline'>
        <input matInput [matDatepicker]='picker' placeholder='Choose a date' formControlName='date'>
        <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="first-row" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign='space-between'>
      <!-- client field -->
      <app-company-selector
        fxFlex="50"
        [selectedCompany]='selectedCompanyWithOldId'
        (companyChange)="this.orderCtrl.setValue(null); this.orderCtrl.enable(); onCompanyChange($event)"
      ></app-company-selector>

      <!-- order field select -->
      <mat-form-field appearance='outline' [style.max-width]="'calc(50% - 8px)'">
        <mat-label>{{'ORDER.ORDER' | translate}}</mat-label>
        <input type="text" matInput formControlName="order" [matAutocomplete]="orderAutocomplete">
        <mat-autocomplete #orderAutocomplete="matAutocomplete" [displayWith]="orderDisplayFn">
          <mat-option *ngFor="let order of foundOrders" [value]="order">
            {{ 'ID: ' + decode(order?.id).split(':')[1] + ' - ' + (order?.specification?.positionTitle || '') + '  (' + order?.company?.name + ')' }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- manager field select -->
    <mat-form-field appearance='outline'>
      <mat-label>{{ 'CLIENT.PROJECT_MANAGER' | translate }}</mat-label>
      <mat-select formControlName="manager">
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="{{ 'COMMON.SEARCH' | translate }}"
            [formControl]="searchProjectManagerCtrl"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor='let userprofile of projectManagers' [value]='userprofile.id'>
          {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- comment field text area -->
    <mat-form-field appearance='outline'>
      <mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
      <textarea matInput formControlName='comment'></textarea>
    </mat-form-field>

    <section>
      <mat-checkbox formControlName="isImportant">{{ 'IS_IMPORTANT' | translate }}</mat-checkbox>
    </section>
  </form>

  <mat-dialog-actions style='justify-content: space-between; align-content: center; width: 100%'>
    <button mat-flat-button class='rounded-btn' color='accent' [disabled]='newMetricValueForm.invalid' (click)='onSubmit()'>
      {{ 'CREATE' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
