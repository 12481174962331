import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { AllUserProfilesGQL, Region, RegionsGQL, TagGroupsGQL, UserProfile } from '../../graphql/graphql';
import { IntensityOptions, TagGroupKey } from 'src/app/specification/specification-edit/specification-edit.component';
import { OrderTrafficGeneration } from '../types/order-traffic-generation';
import {
  CompanySelectFieldsFragment,
  CovidLabelsFieldsFragment,
  FilterMetadataGQL,
  GetCountriesGQL,
  OrderStatus,
  RegionsList2FieldsFragment,
  TaskStatus
} from 'src/generated/graphql';
import { RelayIdService } from '../relay-id.service';
import { Moment } from 'moment';
import { takeUntil } from 'rxjs/operators';
import { OrderActionsLogService } from 'src/app/orders/order-actions-log/order-actions-log.service';
import { ProjectManagerGQL } from 'src/app/pm-stats-dashboard/graphql';
import { GroupByPipe } from 'ngx-pipes';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

type RegionGroup = {
  title: string;
  priority: number;
  regions: any[];
};

export interface FilterInterface {
  search?: string;
  region?: number[];
  statuses?: OrderStatus[];
  selectionStatuses?: OrderTrafficGeneration[];
  male?: boolean;
  female?: boolean;
  canEmployUa?: boolean | null;
  sex?: string;
  intensity?: string[];
  transport?: number[];
  perspectives?: number[];
  duration?: string[];
  scheduleTypes?: string;
  filterFilters?: string[];
  isLongTerm?: boolean | null;
  nettoFrom?: number;
  nettoTo?: number;
  orderType?: 'STAFFING' | 'SELECTION';
  keyAccountManager?: string;
  covidPolicy?: string[];
  country?: string;
  firstTimeCall?: boolean;
  dateRange?: {
    dateFrom?: Moment;
    dateTo?: Moment;
  };
  company?: CompanySelectFieldsFragment;
  category?: string;
  owners?: string[];
  taskStatuses?: TaskStatus[];
  phone?: string;
  canUseInOrder?: boolean;
  projectManager?: string;
  substituteManager?: string;
  recruiter?: string;
  substituteRecruiter?: string;
}

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY MMMM',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  providers: [GroupByPipe, { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class FilterBarComponent implements OnInit, OnDestroy {

  filterForm: FormGroup;

  changesSubscription: Subscription;

  orderStatuses = OrderStatus;
  selectionStatuses = OrderTrafficGeneration;
  availableStatuses = Object.keys(OrderStatus);
  availableSelectionStatuses = Object.keys(OrderTrafficGeneration);

  regions: Region[] = [];
  accountManagers: UserProfile[] = [];
  projectManagers: UserProfile[] = [];
  substituteManagers: UserProfile[] = [];
  recruiters: UserProfile[] = [];
  substituteRecruiters: UserProfile[] = [];

  managers: UserProfile[] = [];

  orderTrafficGeneration = OrderTrafficGeneration;

  intensity = [
    {id: IntensityOptions.stationary, title: IntensityOptions.stationary},
    {id: IntensityOptions.stationaryFast, title: IntensityOptions.stationaryFast},
    {id: IntensityOptions.sedentary, title: IntensityOptions.sedentary},
    {id: IntensityOptions.sedentaryFast, title: IntensityOptions.sedentaryFast}
  ];
  transport = [];
  perspectives = [];
  duration = [{id: 'longterm', title: 'Ilgalaikis'}, {id: 'shorterm', title: 'Trumpalaikis'}];

  scheduleTypes = [];
  preselectScheduleTypes = [];

  covidLabels: CovidLabelsFieldsFragment[] = [];
  private $initialValue: FilterInterface;
  @Input() filters = ['search', 'region', 'statuses', 'selectionStatuses'];
  public allRegions: any;
  private selectedRegions = new SelectionModel<RegionsList2FieldsFragment>(true);
  regionGroups: any;

  @Input() set initialValue(value: FilterInterface) {
    this.$initialValue = value;
    if (value && this.filterForm?.pristine) {
      this.filterForm.patchValue(value);
    }
  }
  get initialValue() {return this.$initialValue; }
  @Input() smallNewDateRange: boolean;
  @Input() style: string;
  @Input() menuView: false;
  @Input() preventString = false;
  @Input() preventFiltersStore = false;
  @Output() filterChanges = new EventEmitter<FilterInterface>();
  trafficGenOpts = [
    {title: this.orderTrafficGeneration.inactive, name: 'Nesiūlyti'},
    {title: this.orderTrafficGeneration.activeNoMarketing, name: 'Siūlyti kandidatams, be marketingo'},
    {title: this.orderTrafficGeneration.activeWithMarketing, name: 'Siūlyti kandidatams, su marketingu'},
  ];
  get regionCtrl() { return this.filterForm.get('region'); }
  get companyCtrl() { return this.filterForm.get('company'); }
  get taskStatusCtrl() { return this.filterForm.get('taskStatuses'); }
  get canUseInOrderCtrl() { return this.filterForm.get('canUseInOrder'); }
  get categoryCtrl(): FormControl { return this.filterForm.get('category') as FormControl; }
  keyAccountManagerCtrl = new FormControl('');
  searchProjectManagerCtrl = new FormControl('');
  searchSubstituteManagersCtrl = new FormControl('');
  searchRecruiterCtrl = new FormControl('');
  firstTimeCallCtrl = new FormControl('');
  searchSubstituteRecruiterCtrl = new FormControl('');
  managerSubscription;
  projectManagerSubscription;
  substituteManagerSubscription;
  covidLabelsIds = [];
  get dateRange() {
    return this.filterForm.get('dateRange');
  }

  get filterKey() {
    return `filter::${window.location.pathname}`;
  }

  countries = [];
  get searchInput() { return this.filterForm.get('search'); }
  get phoneInput() { return this.filterForm.get('phone'); }

  userProfiles: UserProfile[] = [];
  selectedOwners: UserProfile[] = [];
  ownerFilterCtrl: FormControl = new FormControl();
  taskStatusInputCtrl: FormControl = new FormControl('');
  @ViewChild('taskStatusInput') taskStatusInput: ElementRef<HTMLInputElement>;
  taskStatuses = Object.values(TaskStatus);
  selectedTaskStatuses = [];
  selectedCompany;
  totalAppliedFilters = 0;
  protected _onDestroy = new Subject<void>();
  constructor(
    private fb: FormBuilder,
    private regionGQL: RegionsGQL,
    private allUserProfilesGQL: AllUserProfilesGQL,
    private tagsGroupsGQL: TagGroupsGQL,
    private filterMetadataGQL: FilterMetadataGQL,
    private countriesGQL: GetCountriesGQL,
    private relayIdService: RelayIdService,
    private actionLogService: OrderActionsLogService,
    private projectManagerGQL: ProjectManagerGQL,
    private groupByPipe: GroupByPipe
  ) { }

  ngOnInit() {
    this.initFilterForm();
    if (!this.preventFiltersStore) {
      this.getStoredFiltersValue();
    } else {
      this.filterChanges.emit(this.filterFilters(this.filterForm.value));
    }
    if (this.filterConfigured('owners')) {
      this.getUserProfiles();
      this.setOwnerFilterListener();
    }
    if (this.filterConfigured('taskStatuses')) {
      this.getTaskStatuses();
    }
    this.regionGQL.fetch({}).subscribe(resp => {
      this.regions = resp.data.regions;

      this.regionGroups = this.getRegionGroups(this.regions);
    });

    if (this.filterConfigured('transport') || this.filterConfigured('perspectives')) {
      this.tagsGroupsGQL.fetch().subscribe(res => {
        this.transport = res.data.tagsGroups.filter(e => e.key === TagGroupKey.transportInfo)[0].tagSet;
        this.perspectives = res.data.tagsGroups.filter(e => e.key === TagGroupKey.workplaceInto)[0].tagSet;
      });
    }

    this.filterMetadataGQL.fetch({}, {fetchPolicy: 'cache-first'})
      .subscribe(res => {
        if (res.data) {
          this.scheduleTypes = res.data.scheduleTypes;

          this.covidLabels = res.data.labels.edges.map(it => it.node);
          this.covidLabelsIds = this.covidLabels.map(it => it.id);
        }
    });

    if (this.filterConfigured('keyAccountManager')) {
      this.allUserProfilesGQL.fetch({
        "departments": ["KEY_ACCOUNT_MANAGERS"]
      }).subscribe(resp => {
        this.managers = resp.data.userprofiles;
        this.accountManagers = [...resp.data.userprofiles];
      });
    }

    if (
      this.filterConfigured('projectManager') ||
      this.filterConfigured('substituteManager') ||
      this.filterConfigured('recruiter') ||
      this.filterConfigured('substituteRecruiter')
    ){
      this.allUserProfilesGQL.fetch().subscribe(resp => {
        this.managers = resp.data.userprofiles;
        this.projectManagers = [...resp.data.userprofiles];
        this.substituteManagers = [...resp.data.userprofiles];
        this.recruiters = [...resp.data.userprofiles];
        this.substituteRecruiters = [...resp.data.userprofiles];
      });
    }

    this.managerSubscription = this.keyAccountManagerCtrl.valueChanges
      .subscribe(() => {
        this.search();
      });

    this.projectManagerSubscription = this.searchProjectManagerCtrl.valueChanges
      .subscribe(() => {
        this.projectManagerSearch();
      });

    this.substituteManagerSubscription = this.searchSubstituteManagersCtrl.valueChanges
      .subscribe(() => {
        this.substituteManagerSearch();
      });

    this.countriesGQL.fetch()
      .subscribe((v) => {
        this.countries = v.data.countries.edges.map(n => n.node);
      });
  }

  search() {
    let filter = this.keyAccountManagerCtrl.value?.toLowerCase() || '';
    this.accountManagers = this.managers.filter(option => {
      return option?.user?.firstName?.toLowerCase().includes(filter) ||
      option?.user?.lastName?.toLowerCase().includes(filter);
    });
  }

  projectManagerSearch() {
    const filter = this.searchProjectManagerCtrl.value?.toLowerCase() || '';
    this.projectManagers = this.managers.filter(option => {
      return option?.user?.firstName?.toLowerCase().includes(filter) ||
        option?.user?.lastName?.toLowerCase().includes(filter);
    });
  }

  substituteManagerSearch() {
    const filter = this.searchSubstituteManagersCtrl.value?.toLowerCase() || '';
    this.substituteManagers = this.managers.filter(option => {
      return option?.user?.firstName?.toLowerCase().includes(filter) ||
        option?.user?.lastName?.toLowerCase().includes(filter);
    });
  }

  getRegionGroups(regions): RegionGroup[] {
    const groups: { [key: string]: RegionGroup } = {};

    regions.forEach(region => {
      const groupTitle = region.regionGroup?.title || 'Other';
      if (!groups[groupTitle]) {
        groups[groupTitle] = {
          title: groupTitle,
          priority: region.regionGroup?.priority ?? 10,
          regions: []
        };
      }
      groups[groupTitle].regions.push(region);
    });

    return Object.values(groups).sort((a: RegionGroup, b: RegionGroup) => a.priority - b.priority);
  }


  regionsByGroup(group): Region[] {
    return this.allRegions.filter(region => region.regionGroup.title === group.title);
  }

  toggleAllRegionsInGroup(group): void {

    const regionIds = group.regions.map(region => region.id);

    // Check if all regions in the group are selected
    const allSelected = regionIds.every(regionId => this.selectedRegions.isSelected(regionId));

    // If all regions are selected, deselect all. Otherwise, select all.
    if (allSelected) {
      this.selectedRegions.deselect(...regionIds);
    } else {
      this.selectedRegions.select(...regionIds);
    }

    // Update the form control with the new selected regions
    // Assuming your form group is named form and the control name is region
    this.filterForm.get('region').setValue(this.selectedRegions.selected);
  }

  private updateSelectedRegions(region) {
    if (region) {
      this.selectedRegions.select(...region);
    }
  }

  getUserProfiles() {
    if (!(this.userProfiles?.length > 0)) {
      this.projectManagerGQL.fetch({}).subscribe(res => {
        const profiles = [...res.data.userprofiles];
        if (!profiles.find(profile => profile.id === res.data.currentUser.id)) {
          profiles.push(res.data.currentUser);
        }
        this.userProfiles = profiles;
        this.selectedOwners = [...this.userProfiles];
      });
    }
  }

  getTaskStatuses() {
    const taskStatuses = this.filterForm?.value.taskStatuses;
    if (!taskStatuses || !taskStatuses?.length) {
      this.filterForm.controls['taskStatuses'].setValue([TaskStatus.Done, TaskStatus.Cancelled, TaskStatus.InProgress, TaskStatus.Planned]);
    }
  }

  setOwnerFilterListener() {
    this.ownerFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.searchOwners();
    });
  }

  searchOwners() {
    let filter = this.ownerFilterCtrl.value?.toLowerCase() || '';
    this.selectedOwners = this.userProfiles.filter((option) => {
      return (
        (option?.user?.firstName?.toLowerCase() + ' ' + option?.user?.lastName?.toLowerCase()).includes(filter)
      );
    });
  }

  private _filter(value: string) {
    const filterValue = value?.toLowerCase() || '';
    return this.taskStatuses.filter(status => {
        return status.toLowerCase().includes(filterValue) && !(this.taskStatusCtrl?.value?.includes(status));
      }
    );
  }

  selectCompany(company) {
    this.companyCtrl.setValue(company);
  }

  private initFilterForm() {
    const region = JSON.parse(sessionStorage.getItem('region'));
    this.filterForm = this.fb.group({
      search: new FormControl(''),
      region: new FormControl(region),
      owners: new FormControl([]),
      category: new FormControl(),
      company: new FormControl(),
      taskStatuses: new FormControl([TaskStatus.Done, TaskStatus.Cancelled, TaskStatus.InProgress, TaskStatus.Planned]),
      statuses: new FormControl([OrderStatus.New, OrderStatus.Sent, OrderStatus.Confirmed, OrderStatus.PendingSignature], []),
      selectionStatuses: new FormControl([
        OrderTrafficGeneration.activeWithMarketing,
        OrderTrafficGeneration.activeNoMarketing,
        OrderTrafficGeneration.marketingOnly,
        OrderTrafficGeneration.inactive
      ], []),
      intensity: [],
      transport: [],
      perspectives: [],
      duration: [],
      scheduleTypes: [],
      sex: '',
      trafficGeneration: [],
      isLongTerm: null,
      nettoFrom: null,
      nettoTo: null,
      orderType: '',
      keyAccountManager: '',
      country: '',
      firstTimeCall: '',
      dateRange: this.fb.group({
        dateFrom: '',
        dateTo: ''
      }),
      canEmployUa: [null],
      phone: '',
      canUseInOrder: true,
      projectManager: '',
      substituteManager: '',
      recruiter: '',
      substituteRecruiter: ''
    });

    if (this.initialValue) {
      this.filterForm.patchValue(this.initialValue);
    }

    this.changesSubscription = this.filterForm.valueChanges.subscribe(
      change => {
        this.dateRange.get('dateFrom').setErrors(null);
        this.dateRange.get('dateTo').setErrors(null);
        this.filterChanges.emit(this.filterFilters(change));
        this.storeFilters(change);
        this.setRegions(change.region);
        this.updateSelectedRegions(change.region);
      }
    );
  }

  ngOnDestroy() {
    if (this.changesSubscription && this.changesSubscription?.unsubscribe) {
      this.changesSubscription?.unsubscribe?.();
    }

    if (this.managerSubscription && this.managerSubscription?.unsubscribe) {
      this.managerSubscription?.unsubscribe?.();
    }

    if (this.projectManagerSubscription && this.projectManagerSubscription?.unsubscribe) {
      this.projectManagerSubscription?.unsubscribe?.();
    }

    if (this.substituteManagerSubscription && this.substituteManagerSubscription?.unsubscribe) {
      this.substituteManagerSubscription?.unsubscribe?.();
    }

    this._onDestroy.next();
    this._onDestroy.complete();
  }

  clearFilter(control: string) {
    this.filterForm.get(control).setValue('');
  }

  filterConfigured(filter: string) {
    return this.filters.indexOf(filter) > -1;
  }

  private storeFilters(value) {
    if (!this.preventString) {
      sessionStorage.setItem(this.filterKey, JSON.stringify(value));
    }
  }

  private setRegions(region) {
    if (!this.preventString) {
      sessionStorage.setItem('region', JSON.stringify(region));
      if (region) {
        Object.keys(sessionStorage)
          .filter(filter => filter.includes('filter::'))
          .forEach(filter => {
            const f = JSON.parse(sessionStorage[filter]);
            f.region = region;
            sessionStorage.setItem(filter, JSON.stringify(f));
          });
      }
    }
  }

  private getStoredFiltersValue() {
    const filterValue = sessionStorage.getItem(this.filterKey);
    if (filterValue && !this.preventString) {
      const filters = JSON.parse(filterValue);
      try {
        if (this.filterConfigured('owner') && !filters.owner) {
          this.getCurrentUser(filters);
          return;
        } else {
          this.setFiltersVal(filters);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      if (this.filterConfigured('owner')) {
        this.getCurrentUser({});
      } else {
        this.filterChanges.emit(this.filterFilters(this.filterForm.value));
      }
    }
  }

  private getCurrentUser(filters) {
    this.projectManagerGQL.fetch().subscribe(res => {
      const profiles = [...res.data.userprofiles];
      if (!profiles.find(profile => profile.id === res.data.currentUser.id)) {
        profiles.push(res.data.currentUser);
      }
      this.userProfiles = profiles;
      filters.owner = [res.data.currentUser.id];
      this.setFiltersVal(filters);
    });
  }

  setFiltersVal(filters) {
    this.filterForm.patchValue(filters);
    this.selectedCompany = this.companyCtrl.value;
    this.selectedTaskStatuses = this._filter('');
  }

  private filterFilters(filters: FilterInterface): FilterInterface {
    const filtered = {};
    Object.keys(filters).forEach(e => {
      if (this.filters.indexOf(e) > -1) {
        Object.assign(filtered, {[e]: filters[e]});
      }
    });
    this.totalAppliedFilters = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (this.filterConfigured(key)) {
        if (value) {
          if (key !== 'dateRange') {
            if ((Array.isArray(value) && value.length == 0)
              || (typeof value === 'object' && Object.keys(value).length === 0)) {
              continue;
            }
          } else {
            if (!value.dateFrom && !value.dateTo) {
              continue;
            }
          }
          this.totalAppliedFilters++;
        }
      }
    }
    return filtered;
  }
}
