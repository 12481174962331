<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayoutAlign="center center" fxLayout="column" class="stat-item">
    <h1>{{this.stats.totalOffered}}</h1>
    <mat-label>{{ 'WE_OFFERED_EVERYTHING' | translate }}</mat-label>
  </div>

  <div fxLayoutAlign="center center" fxLayout="column" class="stat-item"
       matTooltip="Interview interested: {{this.stats.interestedCount}}, on call: {{this.stats.interestedCallCount}}">
    <h1 >
      {{this.stats.totalInterested || 0}}

    </h1>
    <mat-label>{{ 'HE_BECAME_INTRESTED'| translate }}</mat-label>
  </div>

  <div fxLayoutAlign="center center" fxLayout="column" class="stat-item {{this.stats.signed > 0 ? 'green': ''}}" >
    <h1 >{{this.stats.signed}}</h1>
    <mat-label>{{ 'CONCLUDED_A_CONTRACT' | translate }}</mat-label>
  </div>

  <div fxLayoutAlign="center center" fxLayout="column" class="stat-item {{this.stats.signed > 0 ? 'yellow': ''}}">
    <h1 matTooltip="Interview not interested: {{this.stats.notInterestedCount}}, on call: {{this.stats.notInterestedCallCount}}">
      {{this.stats.totalNotInterested || 0}}
    </h1>
    <mat-label>{{ 'NOT_INTRESTED' | translate }}</mat-label>
  </div>
</div>
