import {MarketingEventsFieldsFragment} from '../../../generated/graphql';

export enum ActionItemStatus {
  done = 'DONE',
  planned = 'PLANNED',
  new = 'NEW'
}

export interface FvPlaningDialogActionItem extends MarketingEventsFieldsFragment {
  channelsString?: string;
  completedBy?: string[];

}
// {
//   id?: string;
//   date: string;
//   channels: number[];
//   category: number;
//   adId?: number;
//   status: ActionItemStatus;
// }
