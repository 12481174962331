<div fxLayout="row" fxLayoutAlign="space-between center">
  <h3>{{title}}</h3>
  <button mat-dialog-close mat-icon-button>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div>
  <ul fxLayout="column" fxLayoutGap="4px">
    <li *ngFor="let item of list">
      {{item}}
    </li>
  </ul>
</div>
