import {Component, EventEmitter, OnInit} from '@angular/core';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {DOCUMENT_TYPES, GetUploadedImagesGQL, UploadedImage} from '../graphql';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {PreviewDialogComponent} from './preview-dialog/preview-dialog.component';
import {DateTypeDialogComponent} from './date-type-dialog/date-type-dialog.component';
import {UploaderFileTypes} from './uploader-file-types';
import {LivasService} from '../../livas/livas.service';
import {NotificationService} from '../../shared/notification.service';
import {GroupByPipe} from 'ngx-pipes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
    providers: [GroupByPipe]

})
export class UploaderComponent implements OnInit {
  options: UploaderOptions;
  uploadInput = new EventEmitter<UploadInput>();
  files: UploadFile[] = [];
  dragOver: boolean;
  userId: string;

  user: object;
  imagesGroups = {};

  docTypes = UploaderFileTypes;

  uploaderFileTypes = UploaderFileTypes;
  images: UploadedImage[] = [];


  constructor(
    private uploadedGql: GetUploadedImagesGQL,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private livas: LivasService,
    private notify: NotificationService,
    private groupBy: GroupByPipe,
    // private authService: AuthService,
    // private logger: LoggerService
  ) { }

  ngOnInit() {
    this.options = {concurrency: 1};
    this.userId = this.route.snapshot.paramMap.get('id');
    this.loadImages(this.userId);

    this.livas.getLivasEmployeeData(this.userId).subscribe(
      data => {
        this.user = data;
      }
    );
  }

  private loadImages(userId) {
    this.uploadedGql.fetch({userId}, {fetchPolicy: 'no-cache'}).subscribe(
      resp => {
        this.imagesGroups = this.groupBy.transform(resp.data.images, 'docType');
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    const livasUserId = '0';

    if (output.type === 'allAddedToQueue') {

      const dialogRef = this.dialog.open(DateTypeDialogComponent);

      dialogRef.afterClosed().subscribe(
        resp => {
          const fileType = resp.type;
          const nextHC =  (resp.date && resp.date.format('YYYY-MM-DD')) || undefined;
          const event: UploadInput = {
            type: 'uploadAll',
            url: `${environment.url}/api/v1/uploader/` ,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            method: 'POST',
            fieldName: 'med_image',
            data: {
              user_id: this.userId,
              valid_till: (resp.date && resp.date.format('YYYY-MM-DD')) || undefined,
              doc_type: resp.type.toLowerCase()
            }
          };
          if (! event.data.valid_till) {
            delete event.data.valid_till;
          }
          // delete event.data.next_health_check;
          this.uploadInput.emit(event);

          if ([this.uploaderFileTypes.medical, this.uploaderFileTypes.hygiene].indexOf(fileType) > -1) {
            this.livas.updateUserHealthcheck(this.userId, nextHC, fileType).subscribe(
            ok => {
              this.notify.notify('LIVAS updated successfully');
            },
              err => {
              this.notify.notify('LIVAS was NOT updated. ERRORR!!!!! You know what to do :)');
              }
          );
          }

        }
      );
      //
    } else if (output.type === 'addedToQueue'  && typeof output.file !== 'undefined') { // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {

      this.loadImages(this.userId);

    }
  }


  openPreview(imageUrl: string) {
    this.dialog.open(PreviewDialogComponent, {
      data: {imageUrl},
      width: '80vw',
      height: '80vh'
    });
  }
}
