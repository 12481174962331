import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  selectedTabIndex = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleService.setTitle('Companies');
  }

  ngOnInit() {
    if (this.route.snapshot.routeConfig.path === 'companies') {
      this.selectedTabIndex = 0;
    } else if (this.route.snapshot.routeConfig.path === 'companies/contacts') {
      this.selectedTabIndex = 1;
    }
  }

  tabsNavigator(event) {
    if (event === 0) {
      this.router.navigate(['companies']).then();
    } else if (event === 1) {
      this.router.navigate(['companies/contacts']).then();
    }
  }
}
