import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewTaskDialogComponent } from '../new-task-dialog/new-task-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { PlannerTableComponent } from '../planner-table/planner-table.component';
import { ProjectManagerGQL } from '../../pm-stats-dashboard/graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskDetailViewFieldsFragment } from '../../../generated/graphql';
import { TaskServiceService } from '../task-service.service';
import { Location } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import { TaskBucketsComponent } from '../task-buckets/task-buckets.component';

export interface CreateTaskFromPrefill {
  category: string;
  order: string;
  company: string;
  title: string;
  country: string;
  employeeLivasId?: number;
  personLivasId?: number;
  customFields: any[];
}

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit {
  filtersConfig = [
    'search', 'company', 'owners', 'dateRange', 'country', 'category', 'taskStatuses', 'firstTimeCall'
  ];
  filters: FilterInterface = {};
  filtersSubject = (new BehaviorSubject<FilterInterface>(this.filters));
  filterObservable = this.filtersSubject.pipe(debounceTime(500));

  @ViewChild(PlannerTableComponent) plannerTableComponent: PlannerTableComponent;

  constructor(
    private dialog: MatDialog,
    private pmGQL: ProjectManagerGQL,
    private taskService: TaskServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams?.newTask) {
      const prefillObj: CreateTaskFromPrefill = {
        category: queryParams.category,
        order: queryParams.order,
        company: queryParams.company,
        title: queryParams.title,
        country: queryParams.country,
        customFields: JSON.parse(queryParams.customFields?.slice(1, queryParams.customFields.length - 1))
      };
      this.openNewTaskDialog(null, prefillObj);
    }

    if (queryParams?.plannerFilters) {
      this.filters = JSON.parse(queryParams.plannerFilters);
    } else {
      this.getCurrentProjectManager();
    }

    this.route.paramMap.subscribe(params => {
      const taskId = params.get('id');
      if (taskId) {
        this.taskService.getTask(taskId).subscribe(
          (task) => {
            this.openNewTaskDialog(task.data.task);
          }
        );
      }
    });
  }

  openNewTaskDialog(task?: TaskDetailViewFieldsFragment, prefill?: CreateTaskFromPrefill) {
    const config: MatDialogConfig<any> = {
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '84vh'
    };
    if (task) {
      config.data = { task };
      this.location.replaceState(`/planner/${encodeURIComponent(task.id)}`);
    }
    if (prefill) {
      config.data = { prefill };
    }
    this.dialog.open(NewTaskDialogComponent, config).afterClosed().subscribe(res => {
      if (res?.id) {
        this.plannerTableComponent.addTaskToDatasource(res);
      }
      this.location.replaceState('/planner');
    });
  }

  openTaskBuckets() {
    const config: MatDialogConfig<any> = {
      panelClass: ['rounded-dialog-0', 'no-padding-dialog'],
      width: '100dvw',
      height: '100dvh',
      autoFocus: false,
    };

    this.dialog.open(TaskBucketsComponent, config);
  }

  updateFilters(event: FilterInterface) {
    if (!event.firstTimeCall) {
      delete event.firstTimeCall;
    }
    this.router.navigate([], {queryParams: {plannerFilters: JSON.stringify(event)}});
    this.filtersSubject.next(event);
  }


  getCurrentProjectManager() {
    this.pmGQL.fetch().subscribe(res => {
      this.filters = {owners: [res.data.currentUser.id]};
    });
  }
}
