<app-form-container headline="CALENDAR" [isLoading]="isLoading">

    <div class="container">
        <div class="calendar">
            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
        </div>

        <div class="additional" fxLayout="column" fxLayoutAlign="space-between">
            <app-month-picker [date]="date" [updateDate]="updateDate"></app-month-picker>
            <div>
                <mat-slide-toggle class="toggle" [checked]="isLocked" [disabled]="isLoading" (click)="toggleIsLocked()">
                    {{ 'MONTH_IS_LOCKED' | translate }}
                </mat-slide-toggle>

                <label class="label">{{ 'SAVE_CHECKED_DAYS_AS' | translate }}:</label>
                <mat-radio-group class="radios">
                    <mat-radio-button class="radio" *ngFor="let type of types" [value]="type.value" [checked]="dayType === type.value" (change)="setDayType(type.value)" [disabled]="isLoading || isLocked">
                        <span class="color" [style.background-color]="type.color"></span>{{ type.label }}
                    </mat-radio-button>
                </mat-radio-group>
                <div fxLayoutAlign="flex-end">
                    <button mat-raised-button color="primary" (click)="updateCalendarDays()" [disabled]="isLoading || isLocked">{{ 'UPDATE_CALENDAR_DAYS' | translate }}</button>
                </div>
            </div>
        </div>
    </div>

</app-form-container>
