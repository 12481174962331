import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { OrderOfferStatsTableItem } from '../order-offer-stats-table/types';
import { EventEventType, OffersGQL } from '../../../../generated/graphql';

/**
 * Data source for the OrderOfferStatsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */

export interface OrderOfferStatsFilter {
  createdAt_Gte?: string;
  createdAt_Lte?: string;
}

export class OldOrderOfferStatsTableDataSource extends DataSource<OrderOfferStatsTableItem> {
  data = new BehaviorSubject<OrderOfferStatsTableItem[]>([]);
  paginator: MatPaginator;
  filters = new BehaviorSubject<OrderOfferStatsFilter>({});
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  totalCount = 0;
  public country: string | undefined;

  constructor(
    private offersGql: OffersGQL,
    private orderId,
    private lang= 'lt'
  ) {
    super();
    const supportedLangs = ['lt', 'lv', 'et'];
    if (supportedLangs.indexOf(this.lang) < 0) {
      this.lang = supportedLangs[0];
    }
    if (this.lang === 'et') {
      this.lang = 'ee';
    }
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OrderOfferStatsTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.

    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page,
      this.sort.sortChange
    ];

    this.getStats();
    merge(...dataMutations).subscribe(
      mutation => {
        this.getStats();
      }
    );

    return this.data.asObservable();
  }

  getStats() {
    const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);

    const orderBy = this.getSort(this.sort);
    const queryParams =
      {
        orderId: btoa('OrderMType:' + this.orderId),
        first: this.paginator.pageSize,
        after: endCursor,
        ...orderBy,
        ...this.filters.value
      };

    const sub = this.offersGql.watch(
      queryParams,
      {fetchPolicy: 'cache-and-network'}
    ).valueChanges.subscribe(
      r => {
        if ( r.data ) {
          this.data.next(this.parseEdges(r.data.offers));
          this.country = r.data.orderModel?.company?.country?.isoCode;
          this.totalCount = r.data.offers?.totalCount;
          this.endCursor = r.data.offers?.pageInfo.endCursor;
        }
        if (!r.loading && sub?.unsubscribe) {
          sub?.unsubscribe?.();
        }
      }
    );
  }

  private parseEdges(input): OrderOfferStatsTableItem[] {
    return input?.edges?.map(
      edge => {
        const node = edge.node;

        const interview = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.Interview)[0];

        const clientInterview = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.ClientInterview)[0];

        const employment = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.Employment)[0];

        return {
          candidateId: node.candidate?.livasId,
          name: node.candidate ? `${node.candidate.firstName} ${node.candidate.lastName}` : 'Not found',
          livasStatus: (node.candidate?.livasStatus) ? node.candidate?.livasStatus['title' + this.lang.charAt(0).toUpperCase() + this.lang.slice(1).toLowerCase()] : 'NA',
          interestedOnCall: node.interestedOnCall,
          interested: node.interested,
          comment: node.comment,
          interviewDatetime: interview ? interview.startDatetime : null,
          clientInterviewDatetime: clientInterview ? clientInterview.startDatetime : null,
          clientInterviewStatus: clientInterview ? clientInterview.resolutionStatus : null,
          clientTitle: 'Klientas',
          signed: node.signed,
          signedAt: node.signedAt,
          employmentDatetime: employment ? employment.startDatetime : null,
          id: node.id,
          cancellationReasons: node.cancellationreasonassignmentSet?.edges?.map(it => it.node) || [],
          offerpropositioncommentSet: node.offerpropositioncommentSet
        };
      }
    );
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: OrderOfferStatsTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSort(sort: MatSort) {
    let orderBy = {
      orderBy: 'candidate__first_name,candidate__last_name'
    };

    if (this.sort?.active) {
      orderBy = {
        orderBy: `${this.sort.direction === 'desc' ? '-' : ''}${this.sort.active}`
      };
    }
    // orderBy = {
    //   orderBy:  orderByStr
    // };
    // console.log(this.sort.active);
    // if (['name', 'interview',  'contract'].indexOf(this.sort?.active) > -1) {
    //   let orderByStr = this.sort.direction === 'desc' ? '-' : '';
    //   if ('name' === this.sort.active) {
    //     orderByStr += 'candidate__first_name,candidate__last_name';
    //   } else if ('interview' === this.sort.active) {
    //     orderByStr += 'event__start_datetime';
    //   } else if ('contract' === this.sort.active) {
    //     orderByStr += 'signed_at';
    //   }
    //   orderBy = {
    //     orderBy:  orderByStr
    //   };
    // }
    return orderBy;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
