import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RecruitmentDashboardComponent } from './recruitment-dashboard.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BusinessTableComponent } from './business-table/business-table.component';
import { InvitedComponent } from './invited/invited.component';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RecruitmentDashboardRoutingModule } from './recruitment-dashboard-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    RecruitmentDashboardComponent,
    BusinessTableComponent,
    InvitedComponent
  ],
  imports: [
    RecruitmentDashboardRoutingModule,
    CommonModule,
    /* NoopAnimationsModule, */
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    HttpClientModule,
    MatTooltipModule,
    FormsModule,
    MatButtonModule,
    MatSnackBarModule,
    FlexLayoutModule,
    TranslateModule
  ],
  providers: []
})
export class RecruitmentDashboardModule { }
