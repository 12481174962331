import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  isOpenToolbar$ = new BehaviorSubject<boolean>(true);

  constructor() { }

  show() {
    this.isOpenToolbar$.next(true);
  }

  hide() {
    this.isOpenToolbar$.next(false);
  }
}
