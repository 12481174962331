import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {WidgetComponent, WidgetTypes} from './widget/widget.component';

const routes: Routes = [
  {path: 'interview', children: [
    {path: 'widget/:personId/:regionName', component: WidgetComponent, data: {type: WidgetTypes.office}},
    {path: 'call-widget/:personId/:regionName', component: WidgetComponent, data: {type: WidgetTypes.call}},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InterviewWidgetRoutingModule { }
