<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)='tabsNavigator($event)'>
  <mat-tab [label]="'COMPANIES' | translate">
    <ng-template matTabContent>
      <app-companies-list></app-companies-list>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'CONTACTS' | translate">
    <ng-template matTabContent>
      <app-contacts-list></app-contacts-list>
    </ng-template>
  </mat-tab>
</mat-tab-group>
