import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { CertificatesTableDataSource, CertificatesTableItem } from './certificates-table-datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {Certificate} from '../../graphql/graphql';
import {OrderCreateService} from '../../order/order-create/order-create.service';
import { BaseOrderFieldsFragment, CertificatesGQL, CertificateFragment } from 'src/generated/graphql';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-certificates-table',
  templateUrl: './certificates-table.component.html',
  styleUrls: ['./certificates-table.component.scss']
})
export class CertificatesTableComponent implements AfterViewInit, OnInit {
  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Certificate>;

  @Output() selected = new EventEmitter<Certificate[]>();
  @Input() preselect: CertificateFragment[];
  @Input() disabled;

  $order: BaseOrderFieldsFragment;
  private country: string;

  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (val?.specification) {
      this.preselect = val.specification.certificates;
      this.initSelected();
    }
  };
  get order(){
    return this.$order;
  }

  dataSource: CertificatesTableDataSource;

  selection: SelectionModel<Certificate>;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['select', 'name'];

  constructor(private certificatesGQL: CertificatesGQL,
              private createOrderService: OrderCreateService) {

  }

  ngOnInit() {

    this.selection = new SelectionModel<Certificate>(true, []);
    this.dataSource = new CertificatesTableDataSource();
    combineLatest([this.createOrderService.selectedCountry, this.createOrderService.selectedLanguage])
    .subscribe(([c, l]) => {
      if (c && l) {
        this.country = c;
        this.loadData(this.country, l);
      }
    })

    this.selection.changed.subscribe(
      v => this.selected.emit(this.selection.selected)
    );


  }
  initSelected(){
    const preselectedIds = this.preselect.map(it => it.id);
    this.dataSource.data.value.forEach(it => {
      if (preselectedIds.indexOf(it.id) > -1) {
        this.selection.select(it);
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.value.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all onSelect; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.value.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  private loadData(country, language) {
    this.certificatesGQL.watch({country, language}).valueChanges.subscribe(
      v => {
        this.dataSource.data.next(v.data.certificates as Certificate[]);
        this.initSelected();
      }
    );
  }
}
