<div [formGroup]="form" *ngIf="form">
  <app-salary-matrix [salaryMatrix]="salaryMatrix" [order]="order" [countryISO]="countryISO"></app-salary-matrix>

  <div fxLayout="row" fxLayoutGap="16px" class="additional-salary-settings">
      <mat-form-field appearance="outline" fxFlex="66">
      <mat-label>{{'ORDER.ADDITIONAL_SALARY_COMMENT' | translate}}</mat-label>
      <textarea matInput [formControl]="salaryMatrix.get('description')" rows="6" type="text"></textarea>
    </mat-form-field>

    <div fxLayout="column" fxFlex="calc(33%-8px)" >
      <mat-label class="checkbox-title"> {{'ORDER.SALARY_SETTINGS' | translate }}</mat-label>
      <mat-checkbox color="primary" [formControl]="salaryMatrix.get('passDescriptionToTemplate')">{{'ORDER.INCLUDE_DESCRIPTION_IN_ORDER' | translate}}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="canIncreaseSalary">{{'ORDER.CAN_INCREASE_SALARY' | translate}}</mat-checkbox>
      <mat-checkbox color="primary" formControlName="canGetBonuses">{{'ORDER.CAN_ACCESS_ACCESSORIES' | translate}}</mat-checkbox>
    </div>
  </div>

  <div style="border-bottom: 1px solid; border-color: rgba(0,0,0,.16);margin-bottom: 32px;">
  <!--  <span class="periods-header">{{'PERIODS' | translate }}</span>-->
  </div>

  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="start start" class="periods-container" fxFlex="100">
      <div fxLayout="column" fxFlex="calc(100- 32px)">
        <div fxLayout="row" *ngFor="let period of periods; let $last=last" style="width: 100%; background-color: rgba(0,0,0,.0)">
          <app-order-period
            fxFlex="calc(100-32px)"
            [period]="period"
            (removed)="onRemove(period)"
            (change)="onPeriodChange()"
          ></app-order-period>
        </div>
      </div>

      <div  fxFlex="32px">
        <button mat-icon-button (click)="addPeriod()">
          <mat-icon>add</mat-icon>
          <!--            <span>{{'ADD_PERIOD' | translate}}</span>-->
        </button>
      </div>
    </div>

    <div fxLayout="row" fxFlex="33">
      <div fxFlex="50">
        <mat-form-field appearance="outline">
          <mat-label>{{'ORDER.TOTAL_SHORTAGE' | translate}}</mat-label>
          <input formControlName="shortage" matInput>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="50">
        <mat-checkbox color="primary" formControlName="opportunityToEmployDirectly">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.OPPORTUNITY_TO_EMPLOY_DIRECTLY' | translate}}</mat-checkbox>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
          <mat-checkbox color="primary" formControlName="orderCanBeExtended">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.ORDER_CAN_BE_EXTENDED' | translate}}</mat-checkbox>

          <mat-form-field>
            <input matInput formControlName="orderCanBeExtendedComment">
          </mat-form-field>
        </div>

        <mat-checkbox color="primary" formControlName="canEmployUa">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.CAN_EMPLOY_UKRAINIANS' | translate}}</mat-checkbox>

        <mat-form-field appearance="outline" *ngIf="orderType === orderTypes.selection" fxFlexOffset="10px">
          <mat-label>{{'ORDER.CANDIDATES_TO_BE_PROVIDED' | translate}}</mat-label>
          <input formControlName="candidatesToBeProvided" matInput>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
