import { Injectable } from '@angular/core';
import {ActivatedRoute, Route} from '@angular/router';
import {Constants} from '../constants';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UserProfileGQL} from './graphql';

export interface  TokenAPI {
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,


  ) { }

  adminLogin(): void {
    let stateParams = {
      'backend' : Constants.azureBackend,
      'href': location.href.replace(document.baseURI, '')
    };


    // const redirectHost = location.host + (location.host.indexOf('workis.lt') > 0 ? '/app' : '' ) + '/login-callback';
    const redirectHost = document.baseURI + 'login-callback';

    // redirectHost = redirectHost.replace(/\/\//g, '/');
    const redirectProtocol = location.protocol;

    const redirectURI = ['https://login.microsoftonline.com/biurobaltic.onmicrosoft.com/oauth2/v2.0/authorize?',
        `client_id=${Constants.clientId}`,
        '&response_type=id_token+token',
        '&redirect_uri=' + redirectHost,
        '&scope=openid%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read',
        '&response_mode=fragment',
        '&state=' + btoa(JSON.stringify(stateParams)),
        '&nonce=678910'].join('');

    window.location.href = redirectURI;

  }


  exchangeToken(token: string, backend: string): Observable<any> {
    const data = [
        'grant_type=convert_token',
        'backend=' + backend,
        'token=' + token,
        'client_id=' + environment.oauth_id,
        'client_secret=' + environment.oauth_secret
      ].join('&');


    return this.http.post<TokenAPI>(`${environment.url}/auth2/convert-token?${data}`, undefined)
      .pipe(
        tap(resp => {
          this.saveAccessToken(resp['access_token'], resp['expires_in'], resp.refresh_token, backend);
        })
      );
  }

  saveAccessToken(token, expiration, refreshToken, backend?: string) {
    localStorage.setItem('token', token);
  }

}
