import { Component, Input, OnInit } from '@angular/core';
import {
    SalaryMatrixValues,
    SalaryRangeTypes,
    SalaryRangeValues,
    SalaryTypes
} from 'src/app/orders/order-create/salary-matrix/salary-matrix-values';
import { SpecificationSalaryType } from 'src/generated/graphql';
import {SalaryCalculatorService} from '../../../orders/order-create/salary-matric/salary-calculator.service';

export enum SalaryInfoModes {
  short = 0,
  long = 1
}

@Component({
  selector: 'app-salary-info',
  templateUrl: './salary-info.component.html',
  styleUrls: ['./salary-info.component.scss']
})
export class SalaryInfoComponent implements OnInit {


  salaryTypes = SalaryTypes;
  matrixValues = new SalaryMatrixValues(this.salaryCalculatorService);

  hourlyBrutto: SalaryRangeValues;
  monthlyBrutto: SalaryRangeValues;
  monthlyNetto: SalaryRangeValues;

  private $mode = SalaryInfoModes.long;
  modes = SalaryInfoModes;

  @Input() canIncreaseSalary = undefined;
  @Input() canGetBonuses = undefined;

  @Input() set mode(value: SalaryInfoModes) {
    if (value) {
      this.$mode = value;
    }
  }
  get mode() {return this.$mode; }

  @Input() comment;
  info: SpecificationSalaryType;

  @Input() set salaryInfo(val: SpecificationSalaryType) {
    this.info = val;
    this.matrixValues = new SalaryMatrixValues(
      this.salaryCalculatorService,
      val?.hourlyBruttoFrom,
      val?.hourlyBruttoTo,
      val?.hourlyNettoFrom,
      val?.hourlyNettoTo,
      val?.monthlyBruttoFrom,
      val?.monthlyBruttoTo,
      val?.monthlyNettoFrom,
      val?.monthlyNettoTo,

      val?.hourlyBruttoFromFilled,
      val?.hourlyBruttoToFilled,
      val?.hourlyNettoFromFilled,
      val?.hourlyNettoToFilled,
      val?.monthlyBruttoFromFilled,
      val?.monthlyBruttoToFilled,
      val?.monthlyNettoFromFilled,
      val?.monthlyNettoToFilled,
    );
    this.setLocalValues();
  }

  constructor(
    private salaryCalculatorService: SalaryCalculatorService
  ) { }

  ngOnInit(): void {
  }

  setLocalValues() {
    this.hourlyBrutto = this.matrixValues.getInputValues(this.salaryTypes.hourlyBruto);
    this.monthlyBrutto = this.matrixValues.getInputValues(this.salaryTypes.monthlyBruto);
    this.monthlyNetto = this.matrixValues.getInputValues(this.salaryTypes.monthlyNetto);
  }

  isTypeBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }
}
