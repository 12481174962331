// date-customfield.component.ts
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-customfield',
  templateUrl: './date-customfield.component.html',
  styleUrls: ['./date-customfield.component.scss']
})
export class DateCustomfieldComponent {
  @Input() label: string;
  @Input() hint: string;
  @Input() valueController: FormGroup;
}
