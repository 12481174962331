import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { CandidateEventsForOrderGQL, CreateOrderClientContactedGQL, CreateOrderErrorGQL, GetOrderErrorCategoriesGQL } from 'src/generated/graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private http: HttpClient,
    private orderCandidateGQL: CandidateEventsForOrderGQL,
    private createOrderClientContactedGQL: CreateOrderClientContactedGQL,
    private getOrderErrorCategoriesGQL: GetOrderErrorCategoriesGQL,
    private createOrderErrorGQL: CreateOrderErrorGQL
  ) { }

  downloadContract(orderId) {
    const url = `${environment.url}/api/v1/orders/${orderId}/document/`;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    });

    return this.http.get(url, {headers, responseType: 'blob'});
  }

  getOrderCandidates(orderId, eventType) {
    return this.orderCandidateGQL.watch({orderId, eventType}, {fetchPolicy: 'cache-and-network'}).valueChanges;
  }

  createOrderClientContacted(orderId: string, date: string): Observable<any> {
    return this.createOrderClientContactedGQL.mutate({ order: orderId, date });
  }

  getOrderErrorCategories() {
    return this.getOrderErrorCategoriesGQL.fetch({ isActive: true });
  }

  createOrderError(orderId: string, categoryId: string, comment: string) {
    return this.createOrderErrorGQL.mutate({ order: orderId, category: categoryId, comment });
  }
}
