import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { map } from 'rxjs/operators';
import {Observable, of as observableOf, merge, BehaviorSubject, forkJoin} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import { GetReservedTimesGQL, GetReservedTimesQuery } from 'src/generated/graphql';
import { ApolloQueryResult } from '@apollo/client/core';
import { Region } from '../region.service';
import * as moment from 'moment';

export class ReservedTime {
  constructor(
    public dayOfTheWeek: string,
    public region: Region,
    public timeFrom: string,
    public timeTo: string,
    public title: string
  ){}
};

export const PMsDict = {
  'Vilnius' : 2,
  'Kaunas' : 2,
  'Alytus': 1,
  'Klaipėda' : 2,
  'Šiauliai' : 2,
  'Plungė' : 2,
  'Panevėžys' : 2,
  'Tauragė' : 2,
  'Ukmergė' : 2,
  'Mažeikiai' : 2,
  'Marijampolė' : 2,
  'Utena' : 2,
  'Šilutė' : 2,
}


export interface CellItem {
  invited: number;
  pms: number;
  classes?: string[];
  msg?: string;
}
// TODO: Replace this with your own data model type
export interface InvitedItem {
  hour: string;

  'Vilnius': CellItem;
  'Kaunas': CellItem;
  'Klaipėda': CellItem;
  'Šiauliai': CellItem;
  'Plungė': CellItem;
  'Panevėžys': CellItem;
  'Tauragė': CellItem;
  'Ukmergė': CellItem;
  'Mažeikiai': CellItem;
  'Marijampolė': CellItem;
  'Utena': CellItem;
  'Šilutė': CellItem;
  Alytus: CellItem;
  'Užsienis': CellItem;
}

// TODO: replace this with real data from your application
const EXAMPLE_DATA: InvitedItem[] = [
  {hour: '10:00',
    Vilnius: {invited: 0, pms: 12},
    Kaunas: {invited: 0, pms: 12},
    Klaipėda: {invited: 10, pms: 2},
    Šiauliai: {invited: 10, pms: 3},
    Plungė: {invited: 10, pms: 12},
    Panevėžys: {invited: 10, pms: 2},
    Tauragė: {invited: 10, pms: 2},
    Ukmergė: {invited: 10, pms: 2},
    Marijampolė: {invited: 10, pms: 2},
    Mažeikiai: {invited: 10, pms: 2},
    Utena: {invited: 10, pms: 2},
    Šilutė: {invited: 10, pms: 2},
    Alytus: {invited: 10, pms: 2},
    Užsienis: {invited: 10, pms: 2}
  },

];

/**
 * Data source for the Invited view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class InvitedDataSource extends DataSource<InvitedItem> {
  data: BehaviorSubject<InvitedItem[]> = new BehaviorSubject(EXAMPLE_DATA);
  paginator: MatPaginator;
  sort: MatSort;

  allData: InvitedItem[] = [];

  selectedDate: string;
  reservedTimes: ReservedTime[] = [];

  constructor(
    private http: HttpClient,
    private getReservedTimesGQL: GetReservedTimesGQL
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<InvitedItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data.asObservable(),
      // this.paginator.page,
      this.sort.sortChange
    ];

    this.loadData();

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data.getValue()]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  changeDate(date) {
    this.selectedDate = date;
    this.data.next(this.filterData());
    this.setCellClasses(this.data.value);
  }


  updateCity(city) {
    this.allData.forEach(it => it[city].pms = PMsDict[city]);
  }


  private filterData() {
    let newData: InvitedItem[] = [];

    if (this.selectedDate) {
      newData = this.allData.filter(dt => dt.hour.indexOf(this.selectedDate) === 0);
    } else {
      newData = this.allData;
    }

    return this.setTotal(newData);
  }

  private setTotal(data: InvitedItem[]) {
    const totalItem = this.getEmptyInvitedItem('Viso');
    const keys = Object.keys(totalItem).filter(k => k !== 'hour' && k !== 'classes');

    keys.forEach(key => {
      for (let i = 0; i < data.length; i++) {
        totalItem[key].invited += data[i][key].invited;
      };
      totalItem[key].classes = this.getBackgroundClasses(totalItem[key].invited, key);
    });

    data.push(totalItem);
    return data;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: InvitedItem[]) {
    // const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data;
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: InvitedItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }
  
    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'hour':
          return this.compareDateTimes(a.hour, b.hour, isAsc);
        default:
          return 0;
      }
    });
  }

  private compareDateTimes(a: string, b: string, isAsc: boolean): number {
    if (!a || !b) {
      return 0;
    }
  
    const dateTimeA = moment(a, 'YYYY-MM-DD HH:mm', true);
    const dateTimeB = moment(b, 'YYYY-MM-DD HH:mm', true);
  
    if (!dateTimeA.isValid() || !dateTimeB.isValid()) {
      return 0;
    }
  
    if (dateTimeA.isBefore(dateTimeB)) {
      return isAsc ? -1 : 1;
    } else if (dateTimeA.isAfter(dateTimeB)) {
      return isAsc ? 1 : -1;
    } else {
      return 0;
    }
  }

  private loadData() {
    forkJoin({
      dashboardDataResp: this.http.get(
        'https://boris-api.workis.lt/api/v1/pms/counts/dashboard_data/'
      ),
      reservedTimeResp: this.getReservedTimesGQL.fetch({})
    }).subscribe(res => {
      this.processReservedTimeResponse(res.reservedTimeResp);

      let data: InvitedItem[] =  [];
      const rows = res.dashboardDataResp['query_result']['data']['rows'];

      rows.forEach((r, index) => {
        const f = data.filter(it => it.hour === r.dfmt);
        if (f.length > 0) {
          if (f[0] && f[0][r.name]) {
            f[0][r.name].invited = r.invited;
          } else {
            console.error('Cannot find', r.name, f[0]);
          }
        } else {
          const newData = this.getEmptyInvitedItem(r.dfmt);
          newData[r.name].invited = r.invited;
          data.push(newData);
        }
      });

      this.setCellClasses(data);
      this.allData = data;
      this.data.next(this.filterData());
    });
  }

  private setCellClasses(data: InvitedItem[]) {
    data.forEach( (row, index) => {
      Object.keys(PMsDict).forEach(region => {
        let classes: string[] = [];
        let reservedTimeData = this.getReservedTimeInfo(data, region, index);
        classes = classes.concat(reservedTimeData.classes);
        classes = classes.concat(this.getBackgroundClasses(data[index][region].invited, region));
        row[region].classes = [...classes];
        row[region].msg = reservedTimeData.msg;
      });
    });
  }

  private getReservedTimeInfo(data: InvitedItem[], region: string, index: number): {
    classes: string[],
    msg: string
  } {
    let reservedData = {
      classes: [],
      msg: null
    };
    const currCellTime = data[index].hour;
    const prevCellTime = data[index - 1] ? data[index - 1].hour : null;
    const nextCellTime = data[index + 1] ? data[index + 1].hour : null;

    let crd = this.getReservedTimeInfoForItem(currCellTime, region);
    if (crd.status) {
      reservedData.classes.push('side-border');
      reservedData.msg = crd.msg;
      if (!prevCellTime || !this.getReservedTimeInfoForItem(prevCellTime, region).status) {
        reservedData.classes.push('top-border');
      } else {
        reservedData.msg = crd.msg;
      }
      if (!nextCellTime || !this.getReservedTimeInfoForItem(nextCellTime, region).status) {
        reservedData.classes.push('bottom-border');
        reservedData.msg = crd.msg;
      }
    }
    return reservedData;
  }

  private getBackgroundClasses(invited: number, region: string): string[] {
    let classes: string[] = [];

    if (invited === 0) {
      return classes;
    } else if (invited < PMsDict[region] * 2) {
      classes.push('green');
    } else if (invited  < PMsDict[region] * 4) {
      classes.push('orange');
    } else {
      classes.push('red');
    }

    return classes;
  }

  private getReservedTimeInfoForItem(time: string, region: string): { status: boolean, msg: string} {
    for (let i = 0; i < this.reservedTimes.length; i++) {
      const reservedTime = this.reservedTimes[i];
      let t = moment(time, 'YYYY-MM-DD HH:mm');
      const timeFrom = moment(time.substr(0, 11) + reservedTime.timeFrom, 'YYYY-MM-DD HH:mm');
      const timeTo = moment(time.substr(0, 11) + reservedTime.timeTo, 'YYYY-MM-DD HH:mm');

      if (
        t.isBetween(timeFrom, timeTo, null, '[]') &&
        ((region === reservedTime.region?.region_title) || ! reservedTime.region.region_title)  &&
        t.format("ddd").toLowerCase() === reservedTime.dayOfTheWeek.toLowerCase()
      ) {

        return { status: true, msg: `${reservedTime.title} ${reservedTime.timeFrom} - ${reservedTime.timeTo}`};
      }
    }
    return { status: false, msg: null};
  }

  private processReservedTimeResponse(res: ApolloQueryResult<GetReservedTimesQuery>) {
    this.reservedTimes = res.data.reservedTimes.edges.map(d => {
      const node = d.node;
      let region: Region = {
        region_title: node.region?.title,
        region: node.region ? parseInt(node.region?.id): null
      };
      let reserveTime = new ReservedTime(
        node.dayOfTheWeek,
        region,
        node.timeFrom,
        node.timeTo,
        node.title
      );
      return reserveTime;
    });
  }

  private getEmptyInvitedItem(dateTime: string): InvitedItem {
    const obj: InvitedItem = {
      hour: dateTime,
      'Vilnius': {invited: 0, pms: 0},
      'Kaunas': {invited: 0, pms: 0},
      'Klaipėda': {invited: 0, pms: 0},
      'Šiauliai': {invited: 0, pms: 0},
      'Plungė': {invited: 0, pms: 0},
      'Panevėžys': {invited: 0, pms: 0},
      'Tauragė': {invited: 0, pms: 0},
      'Ukmergė': {invited: 0, pms: 0},
      'Mažeikiai': {invited: 0, pms: 0},
      'Marijampolė': {invited: 0, pms: 0},
      'Utena': {invited: 0, pms: 0},
      'Šilutė': {invited: 0, pms: 0},
      'Alytus': {invited: 0, pms: 0},
      'Užsienis': {invited: 0, pms: 0}
    };

    [
      'Vilnius',
      'Kaunas',
      'Klaipėda',
      'Šiauliai',
      'Plungė',
      'Panevėžys',
      'Tauragė',
      'Ukmergė',
      'Mažeikiai',
      'Marijampolė',
      'Utena',
      'Šilutė'].forEach( ct => obj[ct] = {invited: 0, pms: PMsDict[ct]});

    return obj;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
