import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RelayIdService {

  constructor() { }

  parseId(value: string): number {
    try {
      return parseInt(atob(value).split(':')[1], 10);
    } catch (e) {
      return null;
    }
  }
  parseIdAsCompanyType(value: string, type = 'CompanyType'): string {
    try {
      const num = parseInt(atob(value).split(':')[1], 10);
      return btoa(`${type}:${num}`);
    } catch (e) {
      return null;
    }
  }
  decodeIdForType(arr: number [], typename: string): string [] {
    if (arr) {
      const result: string [] = [];
      for (const item of arr) {
        result.push(btoa(`${typename}:${item}`));
      }
      return result;
    }
    return null;
  }

  decodeSingleIdForType(id: number|string, typename: string): string {
    if (id) {
      return btoa(`${typename}:${id}`);
    }
    return null;
  }

  convertRelayTypes(id, newType) {
    id = atob(id).split(':')[1];
    return btoa(`${newType}:${id}`);
  }

  getRelayId(id: number, type: string) {
    return btoa(`${type}:${id}`);
  }

  getNumeric(id: string) {
    return atob(id).split(':')[1];
  }
}
