import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { UserprofileService } from '../../../shared/userprofile.service';
import { SendOrderSummariesMutationGQL } from '../../../../generated/graphql';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-order-summeries-sender',
  templateUrl: './order-summaries-sender.component.html',
  styleUrls: ['./order-summaries-sender.component.scss']
})
export class OrderSummariesSenderComponent implements OnInit {
  userEmail: string;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  formControl = new FormControl('');

  constructor(
    private userprofileService: UserprofileService,
    private sendOrderSummariesMutationGQL: SendOrderSummariesMutationGQL,
    private notificationSerivce: NotificationService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {dateRange: any[], orderId: number, companyId: string}
  ) { }

  ngOnInit() {
    this.userprofileService.getMe().subscribe(me => this.emails.push(me.data.me.user.email));
  }

  add(event): void {
    const value = (event.value || '').trim();

    if (value) {
      this.emails.push(value);
    }

    event.chipInput!.clear();
  }

  customAdd(): void {
    const value = this.formControl.value;

    if (value) {
      this.emails.push(value);
      this.formControl.reset();
    }
  }

  remove(key: string): void {
    const index = this.emails.indexOf(key);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  send(): void {
    this.sendOrderSummariesMutationGQL.mutate({
      companyId: this.data.companyId,
      dateFrom: this.data.dateRange[0],
      dateTo: this.data.dateRange[1],
      emails: this.emails,
      orderIds: [btoa('OrderMType:' + this.data.orderId)],
    }).subscribe(resp => {
      if (resp.data.sendOrderSummaries.ok) {
        this.notificationSerivce.notify('Sent successfully!');
      }
    });
  }
}
