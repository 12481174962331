import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  CandidateEventsFieldsFragment,
  CandidateEventTypeEdge,
  EventEventType,
  EventResolutionStatus
} from 'src/generated/graphql';
import {OrdersService} from '../../orders.service';
import moment from 'moment';
import {environment} from '../../../../environments/environment';
import { FilterInterface } from 'src/app/shared/filter-bar/filter-bar.component';

@Component({
  selector: 'app-order-candidate',
  templateUrl: './order-candidate.component.html',
  styleUrls: ['./order-candidate.component.scss']
})
export class OrderCandidateComponent implements OnInit {
  candidateUrl = '';
  urlPrefix = environment.livasAPIBase + '/';
  urlSuffix = '/kontaktai.php?id=';
  candidateData = [];
  pastData = [];
  presentData = [];
  eventTypes = EventEventType;

  constructor(
    private orderService: OrdersService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      eventType: EventEventType | string;
      hideFilters?: boolean;
      filters?: FilterInterface;
      country?: string
    }
  ) {

  }

  ngOnInit(): void {
    if (!['offeredInLast7Days'].includes(this.data.eventType)) {
      this.orderService.getOrderCandidates(
        this.data.orderId,
        this.data.eventType).subscribe(res => {

          if (res.data ){
            this.presentData.length = 0;
            this.pastData.length = 0;

            this.clasifyEvents(this.data.eventType, res.data.candidateEvents.edges);
          }
        });
    }
  }

  isFailed(data: CandidateEventsFieldsFragment) {
    return [EventResolutionStatus.Failed, EventResolutionStatus.Cancelled].indexOf(data.resolutionStatus) > -1;
  }

  isRejected(data: CandidateEventsFieldsFragment) {
    return [EventResolutionStatus.Rejected, 'CLIENT_REJECTED'].indexOf(data.resolutionStatus) > -1;
  }

  isCompleted(data: CandidateEventsFieldsFragment) {
    return [EventResolutionStatus.Complete, EventResolutionStatus.Cancelled, EventResolutionStatus.Failed].indexOf(data.resolutionStatus) > -1;
  }

  orderIdInNumber(): number {
    return +(atob(this.data.orderId).split(":")[1]);
  }

  private clasificatorHealthcheck(node) {
    if (!this.isCompleted(node.node) && !this.isRejected(node.node)){
      this.presentData.push(node.node);
    } else {
      this.pastData.push(node.node);
    }
  }

  private clasifyEvents(eventType, edges) {
    if (eventType === this.eventTypes.HealthCheckPending) {
      edges.forEach(this.clasificatorHealthcheck.bind(this));
    } else {
      edges.forEach(entry => {
        const node = entry.node;
        if (moment(node.startDatetime) > moment().startOf('day') && !this.isFailed(node) && !this.isRejected(node)) {
          this.presentData.push(node);
        } else {
          this.pastData.push(node);
        }
      });
    }
  }
}
