import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { TagAssignmentTableDataSource, TagAssignmentTableItem } from './tag-assignment-table-datasource';
import {RiskFactor, RiskFactorsGQL} from '../../graphql/graphql';

import {SelectionModel} from '@angular/cdk/collections';

import {Tag, TagAssignment} from './tag';

@Component({
  selector: 'app-tag-assignment-table',
  templateUrl: './tag-assignment-table.component.html',
  styleUrls: ['./tag-assignment-table.component.scss']
})
export class TagAssignmentTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TagAssignmentTableItem>;


  dataSource = new TagAssignmentTableDataSource();
  private assignedDict$: {[id: string]: string} = {};
  selection = new SelectionModel<TagAssignmentTableItem>(true, []);


  @Output() selected = new EventEmitter<TagAssignmentTableItem[]>();
  @Input()
  set assigned(value: TagAssignment[]) {
    this.assignedDict$ = {};
    value.forEach(tagAssignment => this.assignedDict$[tagAssignment.tag.id] = tagAssignment.comment);
    this.updateSelection();
  }

  @Input()
  set tags(value: Tag[]) {
    if (value) {
      this.dataSource.data.next(value.map(t => { return {tagId: t.id, title: t.title, hint: t.hint, comment: ''}; }));
    }

    this.updateSelection();
  }

  displayedColumns = ['select', 'name', 'comment'];

  private updateSelection() {
    this.dataSource.data.value.forEach(tag => {
      if (tag.tagId in this.assignedDict$) {
        tag.comment = this.assignedDict$[tag.tagId];
        this.selection.select(tag);
      }
    });
  }




  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */


  constructor() {

  }

  ngOnInit() {

    // this.riskFactorsGQL.watch().valueChanges.subscribe(
    //   v => {
    //     this.dataSource.data.next(v.data.riskFactors);
    //     const preselectedIds = this.preselect.map(it => it.id);
    //
    //     v.data.riskFactors.forEach(it => {
    //       if (preselectedIds.indexOf(it.id) > -1) {
    //         this.selection.select(it);
    //       }
    //     });
    //   }
    //
    // );

    this.selection.changed.subscribe(
      v => this.emitChanges()
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.value.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all onSelect; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.assignedDict$ = {};
    } else {
      this.dataSource.data.value.forEach((row) => {
        this.selection.select(row);
        this.assignedDict$[row.tagId] = row?.comment ?? '';
      });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onInputFocus() {
    // bind parent form to these objects to track all future changes on comments
    this.emitChanges();
  }

  private emitChanges() {
    this.selected.emit(this.selection.selected);
  }

  onTagSelectionChange(event) {
    this.selection.toggle(event);
    if (event.tagId in this.assignedDict$) {
      delete this.assignedDict$[event.tagId];
    } else {
      this.assignedDict$[event.tagId] = event?.comment ?? '';
    }
  }

  commentInputChanges(event, row) {
    this.assignedDict$[row.tagId] = event;
  }
}
