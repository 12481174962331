<div class="filter-row" fxLayout="row" fxLayoutAlign="space-between center">
  <app-filter-bar
    [filters]="['search', 'country', 'projectManager', 'substituteManager', 'recruiter', 'substituteRecruiter']"
    (filterChanges)="onFilterChanges($event)"
  ></app-filter-bar>
</div>

<div [style.position]="'relative'">
  <div *ngIf="selectionList.length" class='action-wrapper'>
    <button mat-flat-button color="accent" (click)="openManagerAssign()">
      {{ 'ASSIGN_MANAGERS_&_RECRUITERS' | translate }}
    </button>
  </div>

  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <table mat-table class="full-width-table" matSort>
    <!-- Id Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY' | translate}}</th>
      <td mat-cell *matCellDef="let company" [ngClass]="[company.color ]" class="company-title">
        <span>{{company.status}} {{company.name}}</span>
      </td>
    </ng-container>

    <!-- Manager Column -->
    <ng-container matColumnDef="manager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MANAGER' | translate }}</th>
      <td mat-cell *matCellDef="let company">
        <div fxLayout="column" fxLayoutGap="2px">
          <span *ngIf="company.projectManager1">#1 {{ (company.projectManager1?.user.firstName + ' ' + company.projectManager1?.user.lastName) || ('NO_NAME' | translate ) }}</span>
          <span *ngIf="company.projectManager2">#2 {{ (company.projectManager2?.user.firstName + ' ' + company.projectManager2?.user.lastName) || ('NO_NAME' | translate ) }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Substitute Manager Column -->
    <ng-container matColumnDef="substituteManager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLIENT.SUBSTITUTE_MANAGER' | translate }}</th>
      <td mat-cell *matCellDef="let company">
        <div fxLayout="column" fxLayoutGap="2px">
          <span *ngIf="company.substituteManager1">#1 {{ (company.substituteManager1?.user.firstName + ' ' + company.substituteManager1?.user.lastName) || ('NO_NAME' | translate ) }}</span>
          <span *ngIf="company.substituteManager2">#2 {{ (company.substituteManager2?.user.firstName + ' ' + company.substituteManager2?.user.lastName) || ('NO_NAME' | translate ) }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Recruiter Column -->
    <ng-container matColumnDef="recruiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLIENT.RECRUITER' | translate }}</th>
      <td mat-cell *matCellDef="let company">
        <div fxLayout="column" fxLayoutGap="2px">
          <span *ngIf="company.recruiter1">#1 {{ (company.recruiter1?.user.firstName + ' ' + company.recruiter1?.user.lastName) || ('NO_NAME' | translate ) }}</span>
          <span *ngIf="company.recruiter2">#2 {{ (company.recruiter2?.user.firstName + ' ' + company.recruiter2?.user.lastName) || ('NO_NAME' | translate ) }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Substitute Recruiter Column -->
    <ng-container matColumnDef="substituteRecruiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLIENT.SUBSTITUTE_RECRUITER' | translate }}</th>
      <td mat-cell *matCellDef="let company">
        <div fxLayout="column" fxLayoutGap="2px">
          <span *ngIf="company.substituteRecruiter1">#1 {{ (company.substituteRecruiter1?.user.firstName + ' ' + company.substituteRecruiter1?.user.lastName) || ('NO_NAME' | translate ) }}</span>
          <span *ngIf="company.substituteRecruiter2">#2 {{ (company.substituteRecruiter2?.user.firstName + ' ' + company.substituteRecruiter2?.user.lastName) || ('NO_NAME' | translate ) }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_COLOR' | translate}}</th>
      <td mat-cell *matCellDef="let company">
        <span>{{company.color}} </span>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="last-color-change">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CHANGED_COLOR' | translate}}</th>
      <td mat-cell *matCellDef="let company">
        <span>{{company.lastColorChange | date}} </span>
      </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ACTIONS' | translate }}</th>
      <td mat-cell *matCellDef="let company" (click)="$event.stopPropagation(); selectRow(company)">
        <mat-checkbox
          [checked]="isSelected(company)"
          (click)="$event.stopPropagation()"
          (change)="selectRow(company)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="row"
        routerLink="/companies/{{row.id}}/orders"></tr>
  </table>
</div>

<mat-paginator #paginator
               [length]="dataSource?.totalCount"
               [pageIndex]="0"
               [pageSize]="50"
               [pageSizeOptions]="[10, 25, 50, 100]">
</mat-paginator>
