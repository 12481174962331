import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderActionsLogService } from '../order-actions-log.service';
import * as moment from 'moment';
import { GetMeGQL, SelectionActionType } from 'src/generated/graphql';
import { BehaviorSubject } from 'rxjs';
import { PopupSelectCompleteComponent } from './popup-select-complete/popup-select-complete.component';
import { UserProfile } from '../../../graphql/graphql';
import { debug } from 'logrocket';

@Component({
  selector: 'app-actions-log-dialog',
  templateUrl: './actions-log-dialog.component.html',
  styleUrls: ['./actions-log-dialog.component.scss']
})
export class ActionsLogDialogComponent implements OnInit {
  private nameInput: any;
  selectedUsersForNewAction: string[] = [];
  private currentUser;
  showAllOptions = false;
  categiesOptionsSliderDisabled = false;
  lastSearchedCategory: string = '';

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      orderId: string, country: string
    },
    private actionLogService: OrderActionsLogService,
    private getMeGql: GetMeGQL
  ) {
  }

  comment: any;
  completed: Array<UserProfile>;
  categoriesData: any;
  toggleMarketingEvents = false;
  colors = ['#3b88e1', '#3BE13EFF', '#d23a2b', '#4c8d11', '#88640FFF', '#840F88FF'];
  categories = [];
  selectedCategory;
  filteredOptionsSubject = new BehaviorSubject([]);
  durationMinutes: number;

  filteredOptions = this.filteredOptionsSubject.asObservable();
  users = [];
  selectedUsers = [];
  selectedUsersForShow = [];
  displayFn = (it) =>  it?.title;


  ngOnInit(): void {
    this.getSACategoryGroups(this.data.country);
    this.getMeGql.fetch().subscribe(it => {
      this.currentUser = it.data.me;
      if (this.currentUser?.department && ['SELECTIONS', 'OPERATIONS'].indexOf(this.currentUser?.department) === -1) {
        this.categiesOptionsSliderDisabled = true;
        this.showAllOptions = true;
      }
    });
  }

  saveAction() {
    this.actionLogService
      .createSelectionActionsListItem(
        this.data.orderId,
        this.comment,
        moment().format('YYYY-MM-DD'),
        this.selectedCategory?.id,
        this.selectedUsersForNewAction,
        this.durationMinutes
      ).subscribe();
  }

  onCategoryChange(category: SelectionActionType|string) {
    if (typeof category === 'string') {
      this.lastSearchedCategory = category;
      this.filteredOptionsSubject.next(this._filter(category));
    } else {
      this.durationMinutes = category.durationMinutes;
    }
  }

  private _filter(value: string) {
    const filterValue = value?.toLowerCase() || '';
    let groups = this.categoriesData;
    if (this.currentUser?.department
      && ['SELECTIONS', 'OPERATIONS'].indexOf(this.currentUser?.department) > -1
      && !this.showAllOptions
    ) {
      groups = this.categoriesData?.filter(
        cg => cg.department === this.currentUser?.department
      ) || [];
    }


    return groups.map(cg => {
      if (cg.title.toLowerCase().includes(filterValue)) {
        return cg.sacategorySet.edges.map(it2 => it2.node);
      } else {
        return cg.sacategorySet.edges.map(it2 => it2.node).filter(option => option.title.toLowerCase().includes(filterValue));
      }
    }).reduce((flat, next) => flat.concat(next), []);
  }


  getSACategoryGroups(country) {
    this.actionLogService.getSACategoriesGroupsList(country).subscribe(
      value => {
        if (value.data) {
          this.categoriesData = value.data.selectionActionCategoryGroups.edges.map(it => it.node);
        }
      }
    );
  }

  eventsPlannerToggle(value) {
    this.toggleMarketingEvents = value;
  }

  openPopup() {
    const dialogRef = this.dialog.open(PopupSelectCompleteComponent, {
      data: {
        name: this.nameInput,
        users: this.users,
        selectedUsers: this.selectedUsers
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let Zindex = result.length;
      this.selectedUsersForShow = result.map((user, idx) => {
        const data = {
          title: `${user.user.firstName.charAt(0)}${user.user.lastName.charAt(0)}`,
          data: user,
          index: Zindex,
          color: this.colors[idx]
        };
        Zindex -= 1;
        return data;
      });
      this.selectedUsers = result.map(value => value.id);
    });
  }

  removeFromSelected(user) {
    this.selectedUsersForShow = this.selectedUsersForShow.filter(value => value !== user);
  }

  onUserChange(userIds: string[]) {
    this.selectedUsersForNewAction = userIds;
  }
}

