<div fxFill fxLayout="column" class="container">

  <mat-spinner class="container-loader" diameter="36" *ngIf="isLoading"></mat-spinner>

  <div [ngClass]="{'is-loading': isLoading}">
    <div class="headline">
      <h3>{{ 'PROJECT' | translate }}</h3>
      <div class="validation-status validation-error" *ngIf="projectFormStatus.status === 'error'">
        <mat-icon aria-hidden="false">error_outline</mat-icon>
        <span>{{projectFormStatus.message}}</span>
      </div>
      <div class="validation-status validation-success" *ngIf="projectFormStatus.status === 'success'">
        <mat-icon aria-hidden="false">done</mat-icon>
        <span>{{projectFormStatus.message}}</span>
      </div>

    </div>

    <mat-card>
      <form [formGroup]="projectForm" fxLayout="column"  fxLayout.gt-xs="row wrap" fxLayoutGap="32px grid">

        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field>
            <mat-label>{{ 'MONTH' | translate }}</mat-label>
            <mat-select formControlName="month" required>
              <mat-option *ngFor="let month of months" value="{{ month }}">{{ month }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field>
            <input matInput placeholder="{{ 'CLIENT.CLIENT' | translate }}" [matAutocomplete]="auto" formControlName='clientInput'>
            <mat-spinner class="select-spinner" diameter="20" *ngIf="isLoadingFilteredClients"></mat-spinner>
          </mat-form-field>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFilteredClients" (optionSelected)='getClientContracts($event.option.value)'>
            <ng-container *ngIf="!isLoadingFilteredClients">
              <mat-option *ngFor="let client of filteredClients" [value]="client">{{ client.name }}</mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>

        <div fxFlex="50%" fxFlex.gt-md="25%">
            <mat-form-field>
              <mat-label>{{ 'CONTRACT' | translate }}</mat-label>
              <mat-select formControlName="client_contract_id" required (selectionChange)="getContractPositions($event.value)">
                <mat-option *ngFor="let contract of clientContracts" [value]="contract.id" [ngClass]="{'expired-contract': contract.expired}">{{ contract.title }}</mat-option>
              </mat-select>
              <mat-spinner class="select-spinner" diameter="20" *ngIf="isLoadingClientContracts"></mat-spinner>
            </mat-form-field>
        </div>

        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field>
            <mat-label>{{ 'WORK CLOTHING PROVIDED BY' | translate }}</mat-label>
            <mat-select formControlName="clothes_supplier" required>
              <mat-option [value]="1">{{ 'CLIENT.CLIENT' | translate }}</mat-option>
              <mat-option [value]="2">Biuro</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </form>
    </mat-card>

    <div class="headline">
      <h3>{{ 'ADDONS' | translate }}</h3>
      <div class="validation-status validation-error" *ngIf="addonsFormStatus.status === 'error'">
        <mat-icon aria-hidden="false">error_outline</mat-icon>
        <span>{{addonsFormStatus.message}}</span>
      </div>
      <div class="validation-status validation-success" *ngIf="addonsFormStatus.status === 'success'">
        <mat-icon aria-hidden="false">done_all</mat-icon>
        <span>{{addonsFormStatus.message}}</span>
      </div>
    </div>

    <ng-template #uploadAddonsTemplate>
      <label ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" class="upload-card">
          <p *ngIf="!isLoadingAddons">{{file ? file.name : 'DRAG FILES HERE OR CLICK TO BROWSE TO UPLOAD' | translate}}</p>
          <mat-spinner diameter="36" *ngIf="isLoadingAddons"></mat-spinner>
          <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" accept=".xls, .xlsx, .csv" >
      </label>
    </ng-template>

    <ng-template #uploadedAddonsTemplate>
      <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="48" footerHeight="48" footerEnabled="true" style="height: 400px;" class="wrapper mat-elevation-z2">
        <table mat-table [dataSource]="dataSource">

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

        <ng-container matColumnDef="id" sticky >
            <th class="column-id" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td class="column-id" mat-cell *matCellDef="let element;">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element.i) : null" [checked]="selection.isSelected(element.i)"></mat-checkbox>
            </td>
            <th class="column-id" mat-footer-cell *matFooterCellDef></th>
        </ng-container>

          <ng-container matColumnDef="full_name" sticky>
            <th class="column-name" mat-header-cell *matHeaderCellDef>{{ 'EMPLOYEE' | translate }}</th>
            <td class="column-name" mat-cell *matCellDef="let element">
              <div class="column-name-inner">
                <span class="column-name--data" (click)="selection.toggle(element.i)">{{element.full_name}}</span>
                <span class="column-name--id">
                  <input matInput placeholder="{{ 'ENTER ID' | translate }}" type="number" [value]="element.id" [(ngModel)]="element.id">
                </span>
                <span class="column-name--icon">
                  <mat-icon matTooltip="{{addonsFormErrors[element.id] || addonsFormErrors[element.full_name]}}" matTooltipClass="addons-tooltip" *ngIf="addonsFormErrors[element.id] || addonsFormErrors[element.full_name]">error_outline</mat-icon>
                </span>
              </div>
            </td>
            <th class="column-name" mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="addon_amount">
            <th class="column-amount" mat-header-cell *matHeaderCellDef>{{ 'AMOUNT' | translate }}</th>
            <td class="column-amount" mat-cell *matCellDef="let element">
              <input matInput placeholder="10.00" type="number" [value]="element.addon_amount" [(ngModel)]="element.addon_amount">
            </td>
            <th class="mat-footer-cell column-amount" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="10.00" type="number" [value]="" (input)="updateTableRows('addon_amount', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="addon_comment">
            <th mat-header-cell *matHeaderCellDef>{{ 'COMMENT' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.addon_comment" [(ngModel)]="element.addon_comment">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'COMMENT' | translate }}" value="" (input)="updateTableRows('addon_comment', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="position_id">
            <th class="column-position" mat-header-cell *matHeaderCellDef>{{ 'POSITION' | translate }}</th>
            <td class="column-position" mat-cell *matCellDef="let element">
            <mat-select [(ngModel)]="element.position_id">
              <mat-option matTooltip="{{ position.title }}" matTooltipPosition="before" *ngFor="let position of positions" [value]="position.id">{{ position.title }}</mat-option>
            </mat-select>
            </td>
            <th class="mat-footer-cell column-position" mat-footer-cell *matFooterCellDef>
              <mat-select (selectionChange)="updateTableRows('position_id', $event.value)">
                <mat-option matTooltip="{{ position.title }}" matTooltipPosition="before" *ngFor="let position of positions" [value]="position.id">{{ position.title }}</mat-option>
              </mat-select>
            </th>
          </ng-container>

          <ng-container matColumnDef="addon_number">
            <th class="column-no" mat-header-cell *matHeaderCellDef>{{ 'NO.' | translate }}</th>
            <td class="column-no" mat-cell *matCellDef="let element">
            <mat-select [(ngModel)]="element.addon_number">
              <mat-option *ngFor="let number of addonNumbers" [value]="number.value">{{ number.title }}</mat-option>
            </mat-select>
            </td>
            <th class="mat-footer-cell column-no" mat-footer-cell *matFooterCellDef>
              <mat-select (selectionChange)="updateTableRows('addon_number', $event.value)">
                <mat-option *ngFor="let number of addonNumbers" [value]="number.value">{{ number.title }}</mat-option>
              </mat-select>
            </th>
          </ng-container>

          <ng-container matColumnDef="project_name">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT NAME' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.project_name" [(ngModel)]="element.project_name">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'PROJECT NAME' | translate }}" value="" (input)="updateTableRows('project_name', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="project_number">
            <th mat-header-cell *matHeaderCellDef>{{ 'PROJECT NUMBER' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.project_number" [(ngModel)]="element.project_number">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'PROJECT NUMBER' | translate }}" value="" (input)="updateTableRows('project_number', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="project_external_id">
            <th mat-header-cell *matHeaderCellDef>{{ 'EXTERNAL ID' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.project_external_id" [(ngModel)]="element.project_external_id">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'EXTERNAL ID' | translate }}" value="" (input)="updateTableRows('project_external_id', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="project_external_id2">
            <th mat-header-cell *matHeaderCellDef>{{ 'EXTERNAL ID 2' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.project_external_id2" [(ngModel)]="element.project_external_id2">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'EXTERNAL ID 2' | translate }}" value="" (input)="updateTableRows('project_external_id2', $event.target.value)">
            </th>
          </ng-container>

          <ng-container matColumnDef="project_address">
            <th mat-header-cell *matHeaderCellDef>{{ 'ADDRESS' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <input matInput placeholder="{{ 'CLICK TO TYPE' | translate }}" type="text" [value]="element.project_address" [(ngModel)]="element.project_address">
            </td>
            <th class="mat-footer-cell" mat-footer-cell *matFooterCellDef>
              <input matInput placeholder="{{ 'ADDRESS' | translate }}" value="" (input)="updateTableRows('project_address', $event.target.value)">
            </th>
          </ng-container>

        </table>
      </cdk-virtual-scroll-viewport>
    </ng-template>

    <div *ngIf="!this.dataSource; then uploadAddonsTemplate; else uploadedAddonsTemplate"></div>
  </div>

  <br>

  <div fxLayout="row" fxLayoutAlign="space-between center" [ngClass]="{'is-loading': isLoading}">
      <div>
        <button mat-stroked-button (click)="confirmRemoveRecords()" [disabled]="!selection.selected.length" class="remove-records">{{ 'REMOVE RECORDS' | translate }}</button>
        <button mat-stroked-button (click)="reuploadAddons()" [disabled]="!this.file">{{ 'REUPLOAD ADDONS XLS FILE' | translate }}</button>
        <a href="assets/addons-import-template.xlsx" class="addons-template">{{ 'ADDONS TEMPLATE' | translate }}</a>
      </div>
      <div>
        <span *ngIf="totalProjects > 0">{{ 'PROJECTS WILL BE CREATED' | translate }}: <b>{{totalProjects}}</b></span>
        <button class="action-button" mat-raised-button color="primary" (click)="submitAddonsForm()" [disabled]="!this.isFormValid">{{ 'UPLOAD ADDONS' | translate }}</button>
        <button class="action-button" mat-raised-button color="primary" (click)="validateAddonsForm()" [disabled]="!this.dataSource">{{ 'VALIDATE ADDONS' | translate }}</button>
      </div>
  </div>

</div>
