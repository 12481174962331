<app-form-container headline="ABSENCES_REVISION" [isLoading]="isLoading">

    <mat-card class="mat-card">
      <form [formGroup]="revisionForm" fxLayout="column"  fxLayout.gt-xs="row wrap" fxLayoutGap="32px grid">

          <div fxFlex="50%" fxFlex.gt-md="25%">
              <mat-form-field appearance="standard" style="width: 100%">
                <mat-label>{{ 'FROM' | translate }}</mat-label>
                <input
                  formControlName="from"
                  matInput
                  [matDatepicker]="pickerFrom"
                  (focus)="pickerFrom.open()"
                  [max]="this.revisionForm.get('to').value"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>
          </div>
          <div fxFlex="50%" fxFlex.gt-md="25%">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>{{ 'TO' | translate }}</mat-label>
              <input
                formControlName="to"
                matInput
                [matDatepicker]="pickerTo"
                (focus)="pickerTo.open()"
                [min]="this.revisionForm.get('from').value"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerTo"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxFlex.gt-md="25%">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>{{ 'EMPLOYER' | translate }}</mat-label>
              <mat-select formControlName="employerId" required>
                <mat-option
                  *ngFor="let employer of employers"
                  [value]="employer.value"
                  >{{ employer.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxFlex.gt-md="25%">
            <mat-form-field appearance="standard" style="width: 100%">
              <mat-label>{{ 'REGION' | translate }}</mat-label>
              <mat-select formControlName="regionId">
                <mat-option value="">{{ 'ALL_REGIONS' | translate }}</mat-option>
                <mat-option
                  *ngFor="let region of regions"
                  [value]="region.id"
                  >{{ region.title }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

      </form>
    </mat-card>

    <app-absences-table [period]="period" [rows]="rows" [openAbsencesDialog]="openAbsencesDialog"></app-absences-table>

    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="this.rows.length" class="actions">
      <div>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-end center">
        <div *ngIf="isUpdated" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-icon aria-hidden="false" color="warn">warning</mat-icon>
          <span class="warning">{{ 'EXIST_UNSAVED_CHANGED' | translate }}</span>
        </div>
        <button mat-raised-button color="primary" (click)="importAbsences()">{{ 'CREATE_ABSENCES' | translate }}</button>
      </div>
    </div>
</app-form-container>
