
<div *ngIf="specForm">

  <form [formGroup]="specForm" [class.disabled]="lockLevel === 'full'">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="showErrorForSection(0)"></app-unfilled-field-indicator>
            <span>{{'WORK'| translate}}</span>
            <mat-icon class="schedule-warn" *ngIf="showErrorForSection(0) && errorsVisible" matTooltip="Užpildykite visus laukus">info</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex="200px" >
            <mat-label>{{'ORDER.POSITION_TITLE'| translate}}</mat-label>
            <input matInput formControlName="positionTitle">
            <mat-error *ngIf="specForm.get('positionTitle').hasError('required')">{{'COMMON.REQUIRED_FIELDS'| translate}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="50" >
            <mat-label>Ką darbuotojas darys?(Ar supras kandidatas?)</mat-label>
            <textarea matInput formControlName="workFunctions" cdkTextareaAutosize></textarea>
            <mat-error *ngIf="specForm.get('workFunctions').hasError('required')">{{'COMMON.REQUIRED_FIELDS'| translate}}</mat-error>
          </mat-form-field>

          <mat-checkbox class="example-margin" formControlName="allFunctionsFromFirstDay">{{'PERFORMED_FROM_DAY_ONE'| translate}}</mat-checkbox>


        </div>
        <div fxLayout="row" fxLayout="start center">
          <mat-form-field fxFlex="200px">
            <mat-label>{{'WHAT IS THE POSTURE, INTENSITY OF WORK?'| translate}} </mat-label>
            <select matNativeControl formControlName="intensity" name="spec">
            <option *ngFor="let i of intensityOptions " [value]="i">
              {{('ORDER.WORK_POSE_OPTIONS.' + i) | translate}}
            </option>
          </select>
          <mat-error *ngIf="specForm.get('intensity').hasError('required')">{{'COMMON.REQUIRED_FIELDS'| translate}}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'WHAT IS THE DURATION OF TRAINING IN THE COMPANY?' | translate}} </mat-label>
              <select matNativeControl formControlName="onboardingDuration" name="spec">
                <option *ngFor="let i of trainingsDuration " [value]="i">
                    {{i}}
                </option>
              </select>
              <mat-error *ngIf="specForm.get('onboardingDuration').hasError('required')">{{'COMMON.REQUIRED_FIELDS'| translate}}</mat-error>
            </mat-form-field>


          <div fxLayout="column">
            <mat-hint style="font-size: 10px; line-height: 1.25">{{'ELIGIBLE_STAFF'| translate}}</mat-hint>
            <div fxLayout="row">
              <mat-checkbox class="example-margin" formControlName="female">{{'GENDER.WOMEN'| translate}}</mat-checkbox>
              <mat-checkbox class="example-margin" formControlName="male">{{'GENDER.MEN'| translate}}</mat-checkbox>
            </div>


          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="showErrorForSection(1)"></app-unfilled-field-indicator>
            <span>{{'WORKPLACE'| translate}}</span>
            <mat-icon class="schedule-warn" *ngIf="showErrorForSection(1) && errorsVisible" matTooltip="Užpildykite visus laukus">info</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row">
          <mat-form-field fxFlex="300px" >
            <mat-label>{{'ADDRESS'| translate}}</mat-label>
            <input matInput formControlName="address">
            <mat-error *ngIf="specForm.get('address').hasError('required')">{{'COMMON.REQUIRED_FIELDS'| translate}}</mat-error>
          </mat-form-field>

          <div fxLayout="column">
            <mat-checkbox formControlName="isSecurityPostPresent">{{'ORDER.SECURITY_POST_PRESENT'| translate}}</mat-checkbox>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox formControlName="isMagneticKeyIssued">{{'ORDER.MAGNETIC_CARD'| translate}}</mat-checkbox>
              <mat-form-field *ngIf="specForm.get('isMagneticKeyIssued').value">
                <mat-label>{{'ORDER.WHO_BETRAYS'| translate}}</mat-label>
                <input matInput formFieldControl="whoIssuesMagneticKey">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="showErrorForSection(2)"></app-unfilled-field-indicator>
            <span>{{'WORK_SCHEDULE'| translate}}</span>
            <mat-icon class="schedule-warn" *ngIf="showErrorForSection(2) && errorsVisible" matTooltip="Užpildykite visus laukus">info</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-schedule-setup
          [form]="specForm"
          [schedule]="schedule"
          (changed)="onScheduleChange($event)"
          >
        </app-schedule-setup>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(3)"></app-unfilled-field-indicator>
            <span>{{'TRANSPORT'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-tag-assignment-table [tags]="tagsGroupDict[tagGroupMap.transportInfo]"
                                (selected)="onTagsSelection($event, tagGroupMap.transportInfo)" [assigned]="tagAssignments" >
        </app-tag-assignment-table>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(4)"></app-unfilled-field-indicator>
            <span>{{'LOOKING FOR AN EMPLOYEE'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div fxFlex="row">
            <div fxFlex="column">
              <h5>{{'ORDER.EXPERIENCE'| translate}} </h5>
              <div fxLayoutAlign="start center">
                <mat-form-field fxFlex="200px" >
                  <mat-label>{{'MINIMUM EXPERIENCE REQUIRED IN MONTHS'| translate}}</mat-label>
                  <input matInput type="number" (keypress)="numberInputControl($event)" formControlName="minExperienceMonths">
                </mat-form-field>

                <mat-radio-group  formControlName="experienceType">
                  <mat-radio-button value="{{experienceTypes.same}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.ANALOGOUS'| translate}}</mat-radio-button>
                  <mat-radio-button value="{{experienceTypes.similar}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.SIMILAR'| translate}}</mat-radio-button>
                  <mat-radio-button value="{{experienceTypes.irrelevant}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.DOES_NOT_MATTER'| translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div fxFlex="column">
              <h5>
                {{'ORDER.AGE_RESTRICTION'| translate}} </h5>
              <div fxLayoutAlign="start center">
                <app-age-restriction [ageFrom]="ageFrom?.value" [ageTo]="ageTo?.value" (restrictionChange)="onAgeRestrictionChange($event)">
                </app-age-restriction>
              </div>
            </div>
          </div>

          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50">
              <h5>{{'SPECIFICATION.CERTIFICATE'| translate}} </h5>
              <app-certificates-table fxFlex="100"
                                      (selected)="onCertificatesUpdate($event)"
                                      [preselect]="preselectCertificates"
                                      [disabled]="isPartialLockActive()"
                                      [country]="country"

              >

              </app-certificates-table>
            </div>

            <div fxLayout="column" fxFlex="50">
              <h5>{{'ORDER.REQUIREMENTS'| translate}} </h5>
              <button style="width: 177px" mat-stroked-button (click)="addRequirement()">
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon svgIcon="plus"></mat-icon>
                  <span>{{'ORDER.CREATE_REQUIREMENTS'| translate}}</span>
                </div>
              </button>
              <app-candidate-requirements title="Privalomi reikalavimai"
                                          [initialize]="specForm.get('mandatoryRequirements').value"
                                          (selected)="onRequirementsSelect($event, 'mandatoryRequirements')"
                                          fxFlex="100">

              </app-candidate-requirements>
              <app-candidate-requirements title="Pageidaujami reikalavimai"
                                          [initialize]="specForm.get('optionalRequirements').value"
                                          (selected)="onRequirementsSelect($event, 'optionalRequirements')"

                                          fxFlex="100">
              </app-candidate-requirements>

            </div>
          </div>
          <h5>{{'RISK_FACTORS'| translate}} </h5>
          <div fxLayout="row">
            <app-risk-factors-table fxFlex="50" (selected)="onRiskFactorsUpdate($event)"
                                    [markDefault]="isNew || isEmptySpec"
                                    [preselect]="preselectRiskFactors"
                                    [disabled]="isPartialLockActive()"></app-risk-factors-table>

            <app-tag-assignment-table fxFlex="50" [tags]="tagsGroupDict[tagGroupMap.provision]"
                                      (selected)="onTagsSelection($event, tagGroupMap.provision)" [assigned]="tagAssignments" >
            </app-tag-assignment-table>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(5)"></app-unfilled-field-indicator>
            <span>{{'ORDER.WORK_CLOTHING'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-ppe-issuance-table [ppes]="personalProtectiveEquipment"
                              [issuedAgency]="ppeIssuedByAgency"
                              [issuedClient]="ppeIssuedByClient"
                              (selected)="onSelectedChange($event)"
                              fxFlex="50" fxFlex.lt-md="100"
                              [disabled]="isPartialLockActive()">
        </app-ppe-issuance-table>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(6)"></app-unfilled-field-indicator>
            <span>{{'PECULIARITIES OF THE WORKPLACE'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-tag-assignment-table [tags]="tagsGroupDict[tagGroupMap.workplaceBenefits]"
                                (selected)="onTagsSelection($event, tagGroupMap.workplaceBenefits)" [assigned]="tagAssignments" ></app-tag-assignment-table>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(7)"></app-unfilled-field-indicator>
            <span>{{'EMPLOYEE_PERSPECTIVE'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-tag-assignment-table [tags]="tagsGroupDict[tagGroupMap.workplaceInto]"
                                (selected)="onTagsSelection($event, tagGroupMap.workplaceInto)" [assigned]="tagAssignments" >
        </app-tag-assignment-table>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-unfilled-field-indicator [unfilled]="hasUnfilledFields(8)"></app-unfilled-field-indicator>
            <span>{{'ADDITIONAL_INFORMATION'| translate}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <mat-checkbox  formControlName="isWorkplaceVideoAllowed">{{'IT IS POSSIBLE TO FILM JOBS AND SHOW VIDEOS TO CANDIDATES'| translate}}</mat-checkbox>
          <mat-checkbox  formControlName="isWorkplaceVisitAllowed">{{'IT IS POSSIBLE TO INSPECT THE WORKPLACES BEFORE EMPLOYMENT'| translate}}</mat-checkbox>
          <mat-checkbox  formControlName="isLogoPublicAllowed">{{'THE LOGO CAN BE DISPLAYED PUBLICLY'| translate}}</mat-checkbox>
          <mat-checkbox  formControlName="isCompanyModern">{{'THE COMPANY IS MODREN'| translate}}</mat-checkbox>
          <mat-checkbox  formControlName="isCompanyInternational">{{'TTHE COMPANY IS INTERNATIONAL'| translate}}</mat-checkbox>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="relatedOrders?.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'RELATED_ORDERS'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-table [dataSource]="relatedOrders">
          <ng-container matColumnDef="order">
            <mat-header-cell *matHeaderCellDef>{{'PROJECT'| translate}} </mat-header-cell>
            <mat-cell *matCellDef="let order">
              <span class="link-text" (click)="navigateToOrder(order.id)">
                {{order.orderType ? (order.orderType === orderTypes.selection ? 'Atranka ' : 'Laikinas įdarbinimas ') : ' '}}
                {{order.orderPeriodFrom}} - {{order.orderPeriodTo}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef>{{'CREATED_BY'| translate}} </mat-header-cell>
            <mat-cell *matCellDef="let order">
              <span>{{order.createdBy?.user?.firstName}} {{order.createdBy?.user?.lastName}}</span>
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>{{'STATUS'| translate}} </mat-header-cell>
            <mat-cell *matCellDef="let order">
              <span class="status {{order.status}}">{{order.status}}</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['order', 'createdBy' ,'status']; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['order', 'createdBy' ,'status'];"></mat-row>
        </mat-table>
      </mat-expansion-panel>

    </mat-accordion>
  </form>

</div>
