import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AbsencesRevisionComponent } from './absences-revision/absences-revision.component';
import { AbsencesImportComponent } from './absences-import/absences-import.component';
import { AbsencesCalendarComponent } from './absences-calendar/absences-calendar.component';

const routes: Routes = [
  { path: 'absence-calendar', component: AbsencesCalendarComponent }, // ToDo: remove after links update in LIVAS
  { path: 'absence-import', component: AbsencesImportComponent }, // ToDo: remove after links update in LIVAS
  { path: 'absences', children: [
    { path: 'calendar', component: AbsencesCalendarComponent },
    { path: 'import', component: AbsencesImportComponent },
    { path: 'revision', component: AbsencesRevisionComponent },
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AbsencesRoutingModule { }
