import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './uploader/uploader.component';
import {SharedModule} from '../shared/shared.module';
import {UploaderRoutingModule} from './uploader-routing.module';
import {AppMaterialModule} from '../app-material/app-material.module';
import {NgxUploaderModule} from 'ngx-uploader';
import { PreviewDialogComponent } from './uploader/preview-dialog/preview-dialog.component';
import { DateTypeDialogComponent } from './uploader/date-type-dialog/date-type-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UploaderComponent, PreviewDialogComponent, DateTypeDialogComponent],
  imports: [
    CommonModule,
    NgxUploaderModule,
    AppMaterialModule,
    UploaderRoutingModule,
    SharedModule,
    TranslateModule
  ],
  entryComponents: [
    PreviewDialogComponent,
    DateTypeDialogComponent,
  ]
})
export class UploaderModule { }

