import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from '../app.component';
import { GraphQLModule } from '../graphql.module';
import { AuthService } from '../auth/auth.service';
import { DashboardComponent } from './dashboard.component';
import { OnDutyDashboardRoutingModule } from './on-duty-dashboard-routing.module';
import { SelectedByClientComponent } from './selected-by-client/selected-by-client.component';
import { EdlaAlertsComponent } from './edla-alerts/edla-alerts.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { InventaPrivateEmployeesComponent } from './inventa-private-employees/inventa-private-employees.component';
import { TranslateModule } from '@ngx-translate/core';
import { LduFormsComponent } from './ldu-forms/ldu-forms.component';

@NgModule({
  declarations: [
    DashboardComponent,
    SelectedByClientComponent,
    EdlaAlertsComponent,
    InventaPrivateEmployeesComponent,
    LduFormsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    GraphQLModule,
    HttpClientModule,
    FlexLayoutModule,
    OnDutyDashboardRoutingModule,
    MatTableModule,
    MatDialogModule,
    MatTreeModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
  entryComponents: [
    SelectedByClientComponent,
    EdlaAlertsComponent
  ]
})
export class OnDutyDashboardModule { }
