import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/notification.service';
import {GetOrderDocumentsGQL, OrderDocumentFieldsFragment, ResendOrderForSigningGQL} from '../../../../generated/graphql';

@Component({
  selector: 'app-documents-history',
  templateUrl: './documents-history.component.html',
  styleUrls: ['./documents-history.component.scss']
})
export class DocumentsHistoryComponent implements OnInit {
  documents: { node?: OrderDocumentFieldsFragment }[] = [];
  loadingDocuments = true;
  private orderId$;

  @Input() set orderId(value: string) {
    this.orderId$ = value;
  }

  @Input() positionTitle: string;

  constructor(
    private documentGql: GetOrderDocumentsGQL,
    private resendOrderForSigningGQL: ResendOrderForSigningGQL,
    private notify: NotificationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

  loadDocuments() {
    this.documentGql.watch({id: this.orderId$}, {fetchPolicy: 'cache-and-network'}).valueChanges.subscribe(
      documents => {
        if (documents.data) {
          this.documents = documents.data.documents.edges;
          this.loadingDocuments = false;
        }
      }
    );
  }

  downloadFile(url, fileName?) {
    const pathArray = new URL(url).pathname.split('/');
    fileName = fileName ?? pathArray[pathArray.length - 1];
    fileName = fileName.replace(/\./g, '-');
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((href) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        // a.setAttribute('target', '_blank');
        a.style.setProperty('display', 'none');
        a.href = href;
        a.download = fileName;
        a.click();
        document.body.appendChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  resendOrderSigning(node) {
    if(!node.isSignedClient) {
      this.resendOrderForSigningGQL.mutate({id: node.id}).subscribe((r: any) => {
        if(r.data?.resendOrderSigning?.ok) {
          this.notify.notify(this.translate.instant('ORDER_RESENT_SUCCESSFULLY'));
        }
      })
    }
  }
}
