<div class="container">

    <mat-spinner class="container-loader" diameter="36" *ngIf="isLoading"></mat-spinner>

    <div class="grid" [ngClass]="{'is-loading': isLoading}">

        <div class="headline">
            <form [formGroup]="calendarForm" class="calendar-form">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'EMPLOYER' | translate }}</mat-label>
                    <mat-select formControlName="employerId" required>
                        <mat-option *ngIf="employers.length > 1" [value]="0">{{ 'ALL EMPLOYERS' | translate }}</mat-option>
                        <mat-option *ngFor="let employer of employers" [value]="employer.value">{{ employer.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <h2 class="employee-name">{{ employeeName }}</h2>
        </div>

        <div class="calendar">
            <full-calendar [options]='calendarOptions'></full-calendar>
        </div>

        <div class="additional">
          <p class='icon-info' [matTooltip]="'REMAINING_VACATION_DAYS_TOOLTIP' | translate">
            {{ unusedVacations || 0 }} - {{ 'REMAINING_VACATION_DAYS' | translate }}
          </p>
          <p class="icon-info">
            <mat-icon aria-hidden="false" aria-label="Example home icon">today</mat-icon>
            - {{ 'EMPLOYEE_CONTAINS_A_SHIFT' |translate }}
          </p>
          <p class="icon-info">
            <mat-icon aria-hidden='false' aria-label='Example home icon'>euro</mat-icon>
            - {{ 'ABSENCE_IS_PAID' | translate }}
          </p>
        </div>
    </div>

</div>
