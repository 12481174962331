<div class="dialog" fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner *ngIf="isShiftDataLoading" diameter="180"></mat-spinner>
  <div *ngIf="!isShiftDataLoading && shiftData" class="wrapper">
    <div class="head" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="6px">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
          <span><span class="hl-title">Order: </span>{{ shiftData.project_title }}</span>
          <mat-icon class="link-icon" (click)="openOrderCard(shiftData.project_id)">open_in_new</mat-icon>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
          <span><span class="hl-title">Position: </span>{{ shiftData.position_title }}</span>
          <mat-icon class="link-icon" (click)="openOrderCard(shiftData.project_id)">open_in_new</mat-icon>
        </div>
      </div>

      <div class="date hl-title" fxLayout="column" fxLayoutAlign="space-between center">
        Date: {{ moment(shiftData.shift_start).format('YYYY-MM-DD') }}
      </div>
    </div>

    <div class="time-details" fxLayout="row">
      <div class="detail" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <span class="hl-title">Plan</span>
        <span class="hl-title">{{ moment(shiftData.shift_start).format('HH:mm') + ' - ' + moment(shiftData.shift_end).format('HH:mm') }}</span>
        <span>{{ moment(shiftData.shift_lunch_start).format('HH:mm') + ' - ' + moment(shiftData.shift_lunch_end).format('HH:mm') }}</span>
      </div>

      <div class="detail" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <span class="hl-title">Fact</span>

        <ng-container *ngIf="data.timeManagementType === '0'">
          <span *ngIf="livasWorkedData" class="hl-title">{{ moment(livasWorkedData.fact_time_start).format('HH:mm') + ' - ' + moment(livasWorkedData.fact_time_end).format('HH:mm') }}</span>
          <span *ngIf="livasWorkedData">{{ moment(livasWorkedData.fact_lunch_start).format('HH:mm') + ' - ' + moment(livasWorkedData.fact_lunch_end).format('HH:mm') }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedFromToData" class="hl-title">{{ moment(noConfirmedWorkedFromToData.workFrom).format('HH:mm') + ' - ' + moment(noConfirmedWorkedFromToData.workTo).format('HH:mm') }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedFromToData">{{ moment(noConfirmedWorkedFromToData.lunchFrom).format('HH:mm') + ' - ' + moment(noConfirmedWorkedFromToData.lunchTo).format('HH:mm') }}</span>
        </ng-container>

        <ng-container *ngIf="data.timeManagementType === '1'">
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Day: </span>{{ livasWorkedData.worked_hours_normal_day }}</span>
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Night: </span>{{ livasWorkedData.worked_hours_normal_night }}</span>
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Holidays Day: </span>{{ livasWorkedData.worked_hours_holiday_day }}</span>
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Holidays Night: </span>{{ livasWorkedData.worked_hours_holiday_night }}</span>
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Overtime Day: </span>{{ livasWorkedData.worked_hours_normal_day_overtime }}</span>
          <span *ngIf="livasWorkedData" class="hours"><span class="hours">Overtime Night: </span>{{ livasWorkedData.worked_hours_normal_night_overtime }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Day: </span>{{ noConfirmedWorkedHoursSumData.hrsDay }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Night: </span>{{ noConfirmedWorkedHoursSumData.hrsNight }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Holidays Day: </span>{{ noConfirmedWorkedHoursSumData.holidaysDay }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Holidays Night: </span>{{ noConfirmedWorkedHoursSumData.holidaysNight }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Overtime Day: </span>{{ noConfirmedWorkedHoursSumData.overtimeDay }}</span>
          <span *ngIf="!livasWorkedData && noConfirmedWorkedHoursSumData" class="hours"><span class="hours">Overtime Night: </span>{{ noConfirmedWorkedHoursSumData.overtimeNight }}</span>
        </ng-container>

        <span *ngIf="!livasWorkedData && !noConfirmedWorkedFromToData && !noConfirmedWorkedHoursSumData" class="hl-title">No data</span>
      </div>

      <div class="detail" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <span class="hl-title">Excel</span>

        <ng-container *ngIf="data.timeManagementType === '0' && excelFromToData">
          <span class="hl-title" [class.error]="isWorkDisplayExcelErr">
            <mat-icon *ngIf="isWorkDisplayExcelErr">warning</mat-icon>
            {{ (excelFromToData.workFrom || '/') + ' - ' + (excelFromToData.workTo || '/') }}
          </span>

          <span [class.error]="isLunchDisplayExcelErr">
            <mat-icon *ngIf="isLunchDisplayExcelErr">warning</mat-icon>
            {{ (excelFromToData.lunchFrom || '/') + ' - ' + (excelFromToData.lunchTo || '/') }}
          </span>
        </ng-container>

        <ng-container *ngIf="data.timeManagementType === '1' && excelHoursSumData">
          <span class="hours"><span class="hours">Day: </span>{{ excelHoursSumData.hrsDay }}</span>
          <span class="hours"><span class="hours">Night: </span>{{ excelHoursSumData.hrsNight }}</span>
          <span class="hours"><span class="hours">Holidays Day: </span>{{ excelHoursSumData.holidaysDay }}</span>
          <span class="hours"><span class="hours">Holidays Night: </span>{{ excelHoursSumData.holidaysNight }}</span>
          <span class="hours"><span class="hours">Overtime Day: </span>{{ excelHoursSumData.overtimeDay }}</span>
          <span class="hours"><span class="hours">Overtime Night: </span>{{ excelHoursSumData.overtimeNight }}</span>
        </ng-container>

        <span *ngIf="!excelFromToData && !excelHoursSumData" class="hl-title">No data</span>
      </div>
    </div>

    <div class="rate">
      <span class="hl-title">Client rate {{ shiftData.rate_normal_day }}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [disabled]="!!livasWorkedData || (!livasWorkedData && (!noConfirmedWorkedFromToData && !noConfirmedWorkedHoursSumData || isConfirmedStatus))"
        (click)="onConfirmClick()"
      >Confirm Fact</button>

      <button mat-stroked-button [disabled]="!isSaveActionAvailable() || isConfirmedStatus" (click)="onSaveClick()">Save from Excel</button>
    </div>
  </div>
</div>
