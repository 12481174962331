import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {map, switchMap} from 'rxjs/operators';
import {Observable, of as observableOf, merge, BehaviorSubject} from 'rxjs';
import {OperationsTaskFieldsFragment} from '../../../generated/graphql';
import {TaskServiceService} from '../task-service.service';
import {FilterInterface} from '../../shared/filter-bar/filter-bar.component';
import moment from 'moment';
import { RelayIdService } from 'src/app/shared/relay-id.service';

/**
 * Data source for the PlannerTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class PlannerTableDataSource extends DataSource<OperationsTaskFieldsFragment> {
  data = new BehaviorSubject<OperationsTaskFieldsFragment[]>([]);
  private dataSubject = new BehaviorSubject<OperationsTaskFieldsFragment[]>([]);
  paginator: MatPaginator;
  sort: MatSort;
  filters = new BehaviorSubject<FilterInterface>({});
  totalCount = 0;
  loading = new BehaviorSubject<boolean>(true);
  constructor(
    private taskService: TaskServiceService,
    private relayService: RelayIdService
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OperationsTaskFieldsFragment[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page,
      this.sort.sortChange
    ];

    const fetchData = () => {
      this.loading.next(true);
      const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
      const params = {
        orderBy: this.sort.active ? (this.sort.direction === 'desc' ? '-' + this.sort.active : this.sort.active) : undefined,
        first: this.paginator.pageSize,
        after: endCursor,
        ...this.getFilters(this.filters.value)
      };

      return this.taskService.getTasks(params).pipe(
        map(v => {
          this.loading.next(v.loading);
          this.totalCount = v.data.tasks.totalCount;
          return v.data.tasks.edges.map(e => e.node);
        }),
      );
    };

    const data$ = merge(...dataMutations).pipe(
      switchMap(fetchData)
    );

    const initialValue = [];


    data$.subscribe(this.dataSubject);

    return this.dataSubject.asObservable();
    // return this.data.asObservable();
  }

  upsertTask(task: OperationsTaskFieldsFragment) {
    const data = this.dataSubject.value;
    const ind = data.findIndex(t => t.id === task.id);
    if(ind > -1) {
      data.splice(ind, 1, task);
      this.dataSubject.next(data);
      return;
    }
    this.dataSubject.next([task, ...data]);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */

  getFilters(event: FilterInterface) {
    const filters: any = {};
    if (event.search) { filters['search'] = event.search; }
    if (event.company?.id) {
      filters['company'] = this.relayService.convertRelayTypes(event.company.id, 'CompanyType');
    }
    if (event.taskStatuses?.length > 0) { filters['statuses'] = event.taskStatuses.join(','); }
    if (event.country) { filters['country'] = event.country; }
    if (event.category) { filters['category'] = event.category; }
    if (event.owners) { filters['owners'] = event.owners;}  //.map(it => this.relayService.decodeSingleIdForType(parseInt(it, 10), 'UserProfile')); }
    if (event.firstTimeCall) { filters['firstTimeCall'] = event.firstTimeCall }
    const dateRange = event.dateRange;
    if (dateRange?.dateFrom) {
      const dateFrom = moment(dateRange?.dateFrom)?.format('YYYY-MM-DD');
      filters.date_Gte = dateFrom;
    }
    if (dateRange?.dateTo) {
      const dateTo = moment(dateRange?.dateTo)?.format('YYYY-MM-DD');
      filters.date_Lte = dateTo;
    }
    return filters;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
