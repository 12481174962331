import {Component, OnInit, ViewChild} from '@angular/core';
import {CompanyInsightly, InsightlyService} from '../insightly.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CompanyDetailFieldsFragment,
  ContractorSelectFieldsFragment,
  CreateCompanyGQL,
  GetContractorsForSelectGQL, UpdateCompanyGQL
} from '../../../generated/graphql';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {LivasService} from '../../livas/livas.service';
import {RelayIdService} from '../../shared/relay-id.service';
import {NotificationService} from '../../shared/notification.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-company-importer-dialog',
  templateUrl: './company-importer-dialog.component.html',
  styleUrls: ['./company-importer-dialog.component.scss']
})
export class CompanyImporterDialogComponent implements OnInit {
  companies: CompanyInsightly[];
  mode = 'indeterminate';
  loading = false;
  selectedCompany: CompanyDetailFieldsFragment;

  contractCompanies;
  companyContractTitle = '';
  contractors: ContractorSelectFieldsFragment[] = [];
  selectedContractor: ContractorSelectFieldsFragment;
  @ViewChild('stepper') stepper: MatStepper;
  get companyTitle(): AbstractControl { return this.importerForm && this.importerForm.get('companySearch'); }
  get importedCompany(): AbstractControl { return this.importerForm && this.importerForm.get('importedCompany'); }
  importerForm: FormGroup;
  constructor(
    private insightly: InsightlyService,
    private createCompanyGQL: CreateCompanyGQL,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CompanyImporterDialogComponent>,
    private getContractorsGQL: GetContractorsForSelectGQL,
    private livas: LivasService,
    private updateCompanyGql: UpdateCompanyGQL,
    private relayService: RelayIdService,
    private notifications: NotificationService,
  ) { }

  ngOnInit() {
    this.importerForm = new FormGroup({
        companySearch: new FormControl('', [Validators.required]),
        importedCompany: new FormControl('', [Validators.required]),
      });
    this.loadContractors();
  }

  searchCompany(title) {
    if (this.companyTitle.value) {
      this.loading = true;
      this.insightly.searchCompanies(title).subscribe(
        resp => {
          this.companies = resp;
          this.loading = false;
        });
    }
  }
  searchCompanyContracts(title) {
    if (this.companyTitle.value) {
      this.loading = true;
      this.livas.getCompanyWithContracts(
        title, this.selectedContractor.externalId, this.selectedContractor.country.isoCode
      ).subscribe(
        resp => {
          this.contractCompanies = resp;
          this.loading = false;
        });
    }
  }

  loadContractors() {
    this.getContractorsGQL.watch({}, {fetchPolicy: 'cache-and-network'}).valueChanges.subscribe(
      resp => {
        if (resp.data) {
          this.contractors = resp.data.contractors.edges.map(it => it.node);
          this.selectedContractor = this.contractors.length  ? this.contractors[0] : undefined;
        }
      }
    );
  }

  importCompany(c: CompanyInsightly) {
    const countryMap = {
      Lithuania: 'lt',
      Latvia: 'lv',
      Estonia: 'ee'
    };

    const companyInput = {
      name: c.name,
      companyId: c.companyId?.toString(),
      country: countryMap[c.country],
      insightlyId: c.insightlyId,
      status: c.status
    };

    this.createCompanyGQL.mutate({company: companyInput}).subscribe(
      resp => {
        const s = this.snackBar.open(
          `Imported successfully ${c.name}`,
          'close'
        );
        s.onAction().subscribe(() => s.dismiss());
        // this.selectedCompany = resp.data.company;
        this.importerForm.get('importedCompany').setValue(resp.data.createCompany.company);
        this.companyContractTitle = this.importedCompany?.value?.name;
        this.stepper.next();
        this.selectedCompany = resp.data.createCompany.company;
        this.selectedCompany.id = this.relayService.parseIdAsCompanyType(this.selectedCompany.id, 'CompanyModelType');
        // this.dialogRef.close( this.selectedCompany);
      },
      err => {
        const s = this.snackBar.open(
          `Error ${err}.  Make sure company has "Imones kodas" set in Insightly. Otherwise Try to find company in list, not from Insightly. `,
          'close'
        );
        s.onAction().subscribe(() => s.dismiss());
      }
    );
  }
  importContract(companyFromLivas) {

    this.updateCompanyGql.mutate({
      id: this.relayService.parseId(this.selectedCompany.id),
      livasId: companyFromLivas.id,
      contractorId: this.selectedContractor.id
    }).subscribe(
      r => {
        // this.selectedCompany = r.data.updateCompany.company;
        this.notifications.notify('Imported successfully.');
      }
    );
  }
}
