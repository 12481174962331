import {Injectable} from '@angular/core';
import {Mutation, Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {FvOrderChangesTableItem} from '../fv-order-changes-table/fv-order-changes-table-datasource';


@Injectable({
  providedIn: 'root',
})
export class GetChangeLogGQL extends Query<{orderChangeLog: FvOrderChangesTableItem[]}> {
  document = gql`
    query getOrderChangeLog($orderId: ID) {
      orderChangeLog(orderId: $orderId) {
        oldValue
        newValue
        fieldName
      }
    }
  `;
}
