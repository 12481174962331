<mat-card>
  <mat-card-content>
    <div class="description-field">
      <span class="title">{{ 'COMPANY' | translate }}: </span>
      <span class="description">{{ task.company?.name }}</span>
    </div>

    <div class="description-field">
      <span class="title">{{ 'CATEGORY' | translate }}: </span>
      <span class="description">{{ task.category?.title }}</span>
    </div>

    <div class="description-field">
      <span class="title">{{ 'DESCRIPTION' | translate }}: </span>
      <span class="description">{{ task.comment }}</span>
    </div>

    <mat-divider></mat-divider>

    <div class="footer">
      <div class="date">
        <mat-icon>calendar_today</mat-icon> {{ task.date }}
      </div>

      <div class="time">
        <mat-icon>timer</mat-icon> {{ convertMinutesToTime(task.durationMinutes || 0) }} {{ 'SPECIFICATION.HOURS' | translate }}
      </div>

      <app-popup-select-complete
        [selected]="task.completedBy"
        [removeAvailable]="false"
        [addAvailable]="false"
      ></app-popup-select-complete>
    </div>
  </mat-card-content>
</mat-card>
