import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobAdCreationDialogComponent } from './job-ad-creation-dialog/job-ad-creation-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { QuillModule } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    JobAdCreationDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    QuillModule
  ]
})
export class JobAdsModerationModule {
}
