import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { AbsencesService } from '../absences.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AbsencesDialogComponent } from '../absences-dialog/absences-dialog.component';
import { ActivatedRoute, Params } from '@angular/router';

import moment from 'moment';

@Component({
  selector: 'app-absences-revision',
  templateUrl: './absences-revision.component.html',
  styleUrls: ['./absences-revision.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbsencesRevisionComponent implements OnInit {
  isLoading = false;

  revisionForm: FormGroup;
  employers = [];
  period = {
    from: '',
    to: '',
  };
  rows = [];
  absences = [];
  regions = [];
  initial = [];
  isUpdated = false;

  constructor(
    private fb: FormBuilder,
    private absencesService: AbsencesService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
    this.openAbsencesDialog = this.openAbsencesDialog.bind(this);
    this.updateLocalData = this.updateLocalData.bind(this);
    this.loadMissingData = this.loadMissingData.bind(this);

    this.revisionForm = this.fb.group({
      employerId: 0,
      from: moment().subtract(7, 'days'),
      to: moment(),
      regionId: '',
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.manager_region !== '' && !isNaN(params.manager_region)) {
        this.revisionForm.patchValue({
          regionId: parseInt(params.manager_region, 10),
        });
      }
    });

    this.absencesService.getRegions().subscribe(res => {
      this.regions = res;
    });

    this.absencesService.getAbsences().subscribe(res => {
      this.absences = res;
    });

    this.revisionForm.valueChanges.subscribe(this.loadMissingData);

    this.initiateForm();
  }

  updateLocalData(action: string, form: any, periodAbsences?: Array<any>, shifts?: any) {
    const data = this.rows.find(r => r.employeeId.toString() === form.employeeId.toString());

    if (!data) {
      return;
    }

    const fromDate = moment(form.from);
    const toDate = moment(form.to);
    const diff = toDate.diff(fromDate, 'days');
    let date = '';

    for (let i = 0; i <= diff; i++) {
      date = moment(form.from).add(i, 'days').format('YYYY-MM-DD');

      if (action === 'delete') {

        if (data[date]?.absences?.[form.employerId]) {
          data[date].absences[form.employerId] = data[date]?.existing?.absences?.[form.employerId] || [];
        } else {
          delete data[date];
        }
      } else {
        const absence = [{
          employee_id: form.employeeId,
          from: date,
          to: date,
          unpaid: periodAbsences[date].unpaid,
          absence_type: form.absence_type,
          reason_code: form.reason_code,
          reason_text: form.reason_text,
          shifts,
        }];

        if (!data[date].absences) {
          data[date].absences = {
            [form.employerId]: absence
          }
        } else {
          data[date].absences[form.employerId] = absence;
        }
      }
    }

    this.setUpdated();

    this.cdr.markForCheck();
  }

  getAbsenceRow(employeeId: any) {
    return this.rows.find(r => r.employeeId === employeeId);
  }

  openAbsencesDialog({ employeeId, employerId, date, absence }) {
    if (absence && absence.id && absence.is_fixed !== false) {
      return;
    }

    const revisionForm = this.revisionForm.getRawValue();
    const selectedEmployerId = parseInt(employerId || revisionForm.employerId, 10);

    const row = this.getAbsenceRow(employeeId);
    const range = this.absencesService.getAbsencesRange(row, absence, date, selectedEmployerId);

    const absenceForm  = this.fb.group({
      id: null,
      employeeId: parseInt(employeeId, 10),
      employerId: selectedEmployerId,
      from: moment(range.from).format('YYYY-MM-DD'),
      to: moment(range.to).format('YYYY-MM-DD'),
      min: moment(range.from).format('YYYY-MM-DD'),
      max: moment(range.to).format('YYYY-MM-DD'),
      unpaid: absence && absence.unpaid === false ? false : true,
      absence_type: absence ? absence.absence_type : '',
      reason_code: absence ? absence.reason_code : '',
      reason_text: absence ? absence.reason_text : '',
    });

    const dialog = this.dialog.open(AbsencesDialogComponent, {
      data: {
        title: this.translate.instant('MODIFY_MISSING_ABSENCE'),
        headline: row.name,
        absenceForm,
        absences: this.absences,
        employers: this.employers.filter((e) => e.value === selectedEmployerId),
        records: {},
        isUpdatePreSave: true,
      },
      width: '1200px',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      if (res.event === 'create') {
        this.updateLocalData('update', res.form, res.periodAbsences, res.shifts);
      } else if (res.event === 'delete') {
        this.updateLocalData('delete', res.form);
      }
    });
  }

  importAbsences() {
    this.isLoading = true;

    const revisionForm = this.revisionForm.getRawValue();
    const selectedEmployerId = parseInt(revisionForm.employerId, 10);

    const absences = [];
    const range = this.absencesService.getRange(this.period.from, this.period.to);

    for (const row of this.rows) {

      if (!row.employeeId) {
        continue;
      }

      for (const date of range) {

        if (!row[date]?.absences?.[selectedEmployerId]) {
          continue;
        }

        row[date].absences[selectedEmployerId].forEach(absence => {
            if (!absence.id) {
              absences.push({
                employee_id: row.employeeId,
                from: date,
                to: date,
                absence_type: absence.absence_type,
                unpaid: absence.unpaid === false ? false : true,
                shifts: absence.shifts || this.absencesService.getDefaultShifts(row[date].shifts[selectedEmployerId]),
                reason_code: absence.reason_code || null,
                reason_text: absence.reason_text || null
              });
            }
        });
      }
    }

    this.absencesService.sendAbsencesData(selectedEmployerId, absences).subscribe(res => {
      this.isLoading = false;

      if (res.status === 'success') {

        this.snackBar.open(this.translate.instant('ABSENCES_CREATED_SUCCESSFULLY'), this.translate.instant('CLOSE'), {
          duration: 10000,
        });

        this.loadMissingData(revisionForm);

      } else if (res.status === 'error') {
        this.isLoading = false;

        const message = this.absencesService.getErrorMessage(res);

        this.snackBar.open(message, this.translate.instant('CLOSE'), {
          duration: 5000,
        });

        this.isLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  async loadMissingData(form) {
    const { from, to, employerId, regionId } = form;

    if (!from || !to || !employerId) {
      return;
    }

    this.isLoading = true;

    const period = {
      from: this.absencesService.getDate(from),
      to: this.absencesService.getDate(to),
    };

    const { results = {} } = await this.absencesService.getMissingData({
      ...period,
      employerId,
      regionId,
    });

    if (!results.rows) {
      return;
    }

    this.period = this.absencesService.getPeriod(results.rows, period);

    this.rows = [];

    for (const employeeId in results.rows) {
      if (employeeId) {
        const row = results.rows[employeeId];

        this.rows.push({
          employeeId,
          name: `${row?.employee?.name || ''} ${row?.employee?.surname || ''}`,
          ...Object.keys(row.calendar).reduce((res, date) => {
            res[date] = {
              absences: {},
              ...row.calendar[date]
            };
            return res;
          }, {}),
        });
      }
    }

    this.isUpdated = false;
    this.initial = this.rows.slice(0);

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  async initiateForm() {
    this.employers = await this.absencesService.getEmployers();

    if (this.employers.length) {
      this.revisionForm.patchValue({
        employerId: this.employers[0].value,
      });
    }
  }

  setUpdated() {
    this.isUpdated = JSON.stringify(this.initial) === JSON.stringify(this.rows);
  }

  ngOnInit(): void {
  }
}
