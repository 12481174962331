import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateOrderCommentGQL, GetOrderCommentsGQL, OrderCommentFieldsFragment} from '../../../generated/graphql';

@Component({
  selector: 'app-orders-comments-dialog',
  templateUrl: './orders-comments-dialog.component.html',
  styleUrls: ['./orders-comments-dialog.component.scss']
})
export class OrdersCommentsDialogComponent implements OnInit {

  comments: {node?: OrderCommentFieldsFragment }[] = [];
  newComment: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {orderId: string},
    private dialogRef: MatDialogRef<OrdersCommentsDialogComponent>,
    private commentsGQL: GetOrderCommentsGQL,
    private createCommentGQL: CreateOrderCommentGQL
  ) { }

  ngOnInit(): void {
    this.commentsGQL
      .watch({orderId: this.data.orderId}, {fetchPolicy: 'cache-and-network'})
      .valueChanges.subscribe(
        resp => {
          if (!resp.loading) {
            this.comments = resp.data.orderComments.edges;

          }

        }
      );
  }

  saveComment() {
    this.createCommentGQL.mutate(
      {comment: this.newComment, orderId: this.data.orderId},
      { refetchQueries: ['getOrderComments', 'getPaginatedOrderForTable'] }
      ).subscribe(
      value => {
        this.comments.unshift({ node: value.data.createOrderComment.orderComment });
        this.dialogRef.close(this.comments);
      }
    );
    // this.comments.push(
    //   {node: {comment: this.newComment, id: null}}
    // )


  }

}
