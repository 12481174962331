<form [formGroup]="salaryForm" fxLayout="row" class="input-row" fxLayoutGap="8px">

  <mat-form-field [appearance]="appearance" fxFlex="100px" class="salary-field" [class.standard]="appearance === 'standard'"
                  [ngClass]="{'edited': fromEdited, 'warn': (fromEdited === inputStatuses.warning), 'error': (fromEdited === inputStatuses.error)}"
                  [class.disabled]="disabled"
                  [matTooltip]="getTooltipText(fromEdited) | translate">
    <mat-label>{{'COMMON.FROM' | translate}}</mat-label>
    <mat-icon matPrefix *ngIf="(fromEdited===inputStatuses.edited) || (fromEdited===inputStatuses.saved)">
      check
    </mat-icon>
    <mat-icon matPrefix *ngIf="fromEdited==inputStatuses.warning" class="warn">warning</mat-icon>
    <input matInput formControlName="from" type="number" min="0">
  </mat-form-field>

  <mat-form-field [appearance]="appearance" fxFlex="100px" class="salary-field" [class.standard]="appearance === 'standard'"
                  [ngClass]="{'edited': toEdited, 'warn': (fromEdited === inputStatuses.warning), 'error': (toEdited === inputStatuses.error)}"
                  [class.disabled]="disabled"
                  [matTooltip]="getTooltipText(toEdited) | translate">
    <mat-label>{{'COMMON.TO' | translate}}</mat-label>
    <mat-icon matPrefix *ngIf="toEdited==inputStatuses.edited  || (toEdited===inputStatuses.saved)" >check</mat-icon>
    <mat-icon matPrefix *ngIf="toEdited==inputStatuses.warning" class="warn" >warn</mat-icon>

    <input matInput  formControlName="to" type="number" min="0">
  </mat-form-field>

<!--  <mat-form-field appearance="outline">-->
<!--    <mat-label>{{'TO' | translate}}</mat-label>-->
<!--    <input matInput>-->
<!--  </mat-form-field>-->
</form>
