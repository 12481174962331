<!-- metric-table.component.html -->
<div class='overdue-tasks-container'>
  <div class='header-line' fxLayout fxLayoutAlign='start center' fxLayoutGap='4px'>
    <ng-template #descriptionTemplate>
      <div [innerHTML]="cardDescription | linkify"></div>
    </ng-template>

    <div class='table-title'>{{ cardTitle }}</div>

    <mat-icon
      [matTooltip]="'OPEN_DESCRIPTION' | translate"
      [matTooltipPosition]="'above'"
      (click)='openDescription(descriptionTemplate)'
    >info</mat-icon>
  </div>
  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="isLoading" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <table mat-table [dataSource]='tableData' class='mat-elevation-z0'>
    <!-- Manager Column -->
    <ng-container matColumnDef='manager'>
      <th mat-header-cell *matHeaderCellDef>{{ 'MANAGER' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <app-popup-select-complete
          [selected]='element.completedBy'
          [addAvailable]='!element.completedBy?.length'
          [removeAvailable]='false'
        ></app-popup-select-complete>
      </td>
    </ng-container>

    <!-- Planned Date Column -->
    <ng-container matColumnDef="planned_date" matSort>
      <th mat-header-cell *matHeaderCellDef mat-sort-header="date">{{ 'PLANNED_DATE' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <app-custom-date-picker
          [initValue]="element.date"
          [bcColor]="getOverdueStatusColor(element.date)"
          (valueChange)="onDateChange($event, element)"
        ></app-custom-date-picker>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef='status'>
      <th mat-header-cell *matHeaderCellDef>{{ 'STATUS' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <app-task-status-pill
          [task]='element'
          (statusChange)='onStatusChange($event, element)'
        ></app-task-status-pill>
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef='description'>
      <th mat-header-cell *matHeaderCellDef>{{ 'DESCRIPTION' | translate }}</th>
      <td mat-cell *matCellDef='let element' (click)='openTaskDialog(element)'>{{ element.comment }}</td>
    </ng-container>

    <!-- Next Action Column -->
    <ng-container matColumnDef='nextAction'>
      <th mat-header-cell *matHeaderCellDef>{{ 'NEXT_ACTION' | translate }}</th>
      <td mat-cell *matCellDef='let element' (click)='openTaskDialog(element)'>{{ element.nextActionCategory?.title }}
      </td>
    </ng-container>

    <!-- Metric Column -->
    <ng-container matColumnDef='metric'>
      <th mat-header-cell *matHeaderCellDef>{{ 'METRIC' | translate }}</th>
      <td mat-cell *matCellDef='let element' (click)='openTaskDialog(element)'
      >{{ getCurrentMetricValue(element)?.metricGroup.title }}</td>
    </ng-container>

    <!-- Reason/Comment Column -->
    <ng-container matColumnDef='reasonComment'>
      <th mat-header-cell *matHeaderCellDef>{{ 'REASON_WITH_COMMENT' | translate }}</th>
      <td mat-cell *matCellDef='let element'>
        <div class='reasons-container'>
          <app-reason-with-comment
            *ngFor='let reason of element.reasonSet'
            [reason]='reason'
            [managerName]='getCurrentMetricValue(element)?.manager?.fullName'
            [metricValueCandidate]="{ firstName: getCurrentMetricValue(element)?.candidate?.firstName,
                                      lastName: getCurrentMetricValue(element)?.candidate?.lastName,
                                      livasId: getCurrentMetricValue(element)?.candidate?.livasId,
                                      employeeLivasId: getCurrentMetricValue(element)?.candidate?.employeeLivasId }"
            [metricValueClient]="{ name: getCurrentMetricValue(element)?.client?.name || '',
                                   id: getCurrentMetricValue(element)?.client?.id }"
            [metricGroup]='getCurrentMetricValue(element)?.metricGroup?.title'
          ></app-reason-with-comment>
        </div>
      </td>
    </ng-container>

    <!-- Metric Date Column -->
    <ng-container matColumnDef='metricDate'>
      <th mat-header-cell *matHeaderCellDef>{{ 'METRIC_DATE' | translate }}</th>
      <td mat-cell *matCellDef='let element'>{{ getCurrentMetricValue(element)?.date }}</td>
    </ng-container>

    <!-- Client Column -->
    <ng-container matColumnDef='client'>
      <th mat-header-cell *matHeaderCellDef>{{ 'CLIENT.CLIENT' | translate }}</th>
      <td mat-cell *matCellDef='let element'>{{ element.company?.name }}</td>
    </ng-container>

    <!-- Candidate Column -->
    <ng-container matColumnDef='candidate'>
      <th mat-header-cell *matHeaderCellDef>{{ 'CANDIDATE' | translate }}</th>
      <td mat-cell *matCellDef='let element'>{{ element.candidate?.firstName }} {{ element.candidate?.lastName }}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
  </table>
</div>
