import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {AllCompaniesGQL, Company} from '../graphql/graphql';
import {map} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {


  constructor(auth: AuthService) {

  }

  ngOnInit() {


  }

}

