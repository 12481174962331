import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { PlannerTableDataSource } from './planner-table-datasource';
import { OperationsTaskFieldsFragment, TaskStatus } from '../../../generated/graphql';
import { TaskServiceService } from '../task-service.service';
import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { RelayIdService } from 'src/app/shared/relay-id.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskSchedulingDialogComponent } from '../task-scheduling-dialog/task-scheduling-dialog.component';
import { NotificationService } from '../../shared/notification.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export const taskStatuses = ['PLANNED', 'IN_PROGRESS', 'DONE', 'CANCELLED'];

@Component({
  selector: 'app-planner-table',
  templateUrl: './planner-table.component.html',
  styleUrls: ['./planner-table.component.scss']
})
export class PlannerTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OperationsTaskFieldsFragment>;
  dataSource: PlannerTableDataSource;
  newTaskDate: string | null = null;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'date', 'status', 'category', 'country', 'companyorder', 'description', 'employeeCalls', 'duration', 'owner', 'actions'
  ];
  statuses = taskStatuses;

  @Input() set filters(value: FilterInterface|null) {
    this.dataSource?.filters.next(value);
  }

  @Output() selectItem = new EventEmitter<OperationsTaskFieldsFragment>();

  constructor(
    private taskService: TaskServiceService,
    private relayIdService: RelayIdService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {

    // livas.getProjectShifts(64071).subscribe(
    //   v => console.log(v)
    // );
  }
  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource = new PlannerTableDataSource(this.taskService, this.relayIdService);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.cdr.detectChanges();
  }

  onSelectedProfilesChange($event: string[], task) {
    this.updateTask(task, undefined, $event);
  }

  onStatusChange(status: TaskStatus, task) {
    this.updateTask(task, undefined, undefined, status);
  }

  updateTask(task: OperationsTaskFieldsFragment, duration?: number, completedBy?: string[], status?: TaskStatus) {
    const newTask = {
      taskId: task.id,
      id: task.id,
      order: task.order?.id,
      date: task.date,
      company: task.company?.id,
      category: task.category?.id,
      durationMinutes: duration || task.durationMinutes,
      completedBy: completedBy?.map(it => it) || task.completedBy.map(it => it.id),
      status: status || task.status,
      comment: task.comment
    };

    this.taskService.updateTask(newTask, ['GetOperationsTasks']).subscribe(
      res => this.taskService.callScheduledTaskCreateMessage(res)
    );
  }

  updateDuration(task, $event: number) {
    this.updateTask(task, $event);
  }

  select(task: OperationsTaskFieldsFragment) {
    this.selectItem.emit(task);
  }

  addTaskToDatasource(task) {
    this.dataSource.upsertTask(task);
  }

  openSchedulingDialog(task, schedule?) {
    if (task.createdBySchedule) {
      schedule = task.createdBySchedule;
    }
    this.dialog.open(TaskSchedulingDialogComponent, {
      data: {taskId: task.id, date: task.date, schedule, isEditable: true}
    });
  }

  isTaskStatusChangeDisabled(task: any): boolean {
    return task.status === 'DONE' && (task.schedules.edges.length || task.createdBySchedule);
  }

  isScheduleIconGreen(task: any): boolean {
    return this.hasFutureEndDate(task) || this.isCreatedBySchedule(task);
  }

  isCreatedBySchedule(task: any): boolean {
    return task.createdBySchedule;
  }

  hasFutureEndDate(task: any): boolean {
    const today = new Date();
    return (
      task.schedules.edges.some(
        (schedule: any) => new Date(schedule.node.endDate) > today || !schedule.node.endDate
      )
    );
  }

  hasPastEndDate(task: any): boolean {
    const today = new Date();
    if (task.schedules.edges.length === 0) {
      return false;
    }
    return (
      task.schedules.edges.every(
        (schedule: any) => new Date(schedule.node.endDate) < today && schedule.node.endDate
      )
    );
  }

  getScheduleStatus(task: any): string {
    let status = '';
    if (this.isCreatedBySchedule(task)) {
      status = 'CREATED_BY_SCHEDULE';
    } else if (this.hasFutureEndDate(task)) {
      status = 'FUTURE_END_DATE';
    } else if (this.hasPastEndDate(task)) {
      status = 'PAST_END_DATE';
    }
    return status;
  }
}
