import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CancellationServiceService} from '../cancellation-reason-dialog/cancellation-service.service';
import {GetCountriesGQL} from '../../../generated/graphql';

interface CancellationReasonNode {
  id: string;
  title: string;
  children?: CancellationReasonNode[];
}


@Component({
  selector: 'app-canellation-reason-select',
  templateUrl: './canellation-reason-select.component.html',
  styleUrls: ['./canellation-reason-select.component.scss']
})
export class CanellationReasonSelectComponent implements OnInit {

  searchString: any;

  private FULL_TREE_DATA: CancellationReasonNode[];

  treeControl = new NestedTreeControl<CancellationReasonNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CancellationReasonNode>();

  selection = new SelectionModel<CancellationReasonNode>(true, []);
  countryId: string;
  @Input() offerPropositionId: number;
  @Input() country: string;
  @Input() getCommentsByID: string;

  @Output() selected = new EventEmitter();
  private preselectedIds: string[];

  constructor(
    // @Inject(MAT_DIALOG_DATA) private data: {offerPropositionId: number, country: string},
    private cancellationReasonService: CancellationServiceService,
    private countriesGQL: GetCountriesGQL
  ) {


  }

  ngOnInit() {
    this.countriesGQL.fetch()
    .subscribe((v) => {
      this.countryId = v.data.countries.edges.find(c => c.node.isoCode === this.country)?.node?.id;
      this.cancellationReasonService.getGroups(this.countryId).subscribe(
        re => {
          if (re.data) {
            this.FULL_TREE_DATA = this.parseGroupData(re.data.cancellationReasonGroups.edges);
            this.selectSavedCancellationReasons(this.preselectedIds, this.FULL_TREE_DATA);
            this.dataSource.data = this.FULL_TREE_DATA;
          }
        }
      );
    });

    if (this.offerPropositionId) {
      this.loadSavedAssignments(this.offerPropositionId);
    }

    this.selection.changed.subscribe(
      value => {
        this.selected.emit(this.selection.selected.map(it => it.id));
      }
    );
  }

  hasChild = (_: number, node: CancellationReasonNode) => !!node.children && node.children.length > 0;

  filterTreeNodes() {
    const search = this.searchString.toLowerCase();
    this.dataSource.data = this.FULL_TREE_DATA.map(
      node => {
        return {
          id: node.id,
          title: node.title,
          children: node.children.filter(n => n.title.toLowerCase().includes(search))
        };
      }
    ).filter(
      node => {
        return node.children.length > 0;
      }
    );

    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();

  }

  isSelected(node) {
    return this.selection.isSelected(node);
  }

  select(node) {
    this.selection.toggle(node);
    // if (this.selection.isSelected(node)) {
    //   this.selection.deselect(node);
    // } else {
    //   const values = [...this.selection.selected, node];
    //   this.selection.select(values);
    // }

  }

  private parseGroupData(cancellationReasonsGroupData): CancellationReasonNode[] {
    return cancellationReasonsGroupData.map(it => {
      const group = JSON.parse(JSON.stringify(it.node));
      group.children = group.cancellationReasons.edges.map(r => r.node);
      return group;
    });
  }

  private loadSavedAssignments(offerPropositionId: number) {
    this.cancellationReasonService.getSavedAssignments(offerPropositionId).subscribe(
      re => {
        if (re.data?.cancellationReasonAssignments) {
          this.preselectedIds = re.data.cancellationReasonAssignments.edges.map(it => it.node?.cancellationReason.id);
          this.selectSavedCancellationReasons(this.preselectedIds, this.FULL_TREE_DATA);
        }
      }
    );
  }

  private selectSavedCancellationReasons(preselectedIds: any, treeData) {

    if (treeData && preselectedIds) {
      this.selection.clear();
      treeData.forEach(group => {
        group.children.forEach(reason => {
          if (preselectedIds.indexOf(reason.id) > -1 && !this.selection.isSelected(reason)) {
            this.selection.select(reason);
          }
        });
      });
    }
  }

  saveAssignment() {
    if (this.offerPropositionId) {
      this.cancellationReasonService
        .saveAssignments(this.offerPropositionId, this.selection.selected.map(it => it.id)).subscribe(
        re => {
          if (re.data.createCancellationReasonAssignment.ok) {

          }
        }
      );
    } else {

    }
  }

}
