import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RequestActivitiesComponent } from './request-activities.component';

const routes: Routes = [
  { path: 'request-activities', component: RequestActivitiesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestActivitiesRoutingModule { }
