import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FreeVacanciesListComponent } from './free-vacancies-list/free-vacancies-list.component';

import {FreeVacanciesRoutingModule} from './free-vacancies-routing.module';
import { FreeVacanciesTableComponent } from './free-vacancies-table/free-vacancies-table.component';
import {SharedModule} from '../shared/shared.module';
import {AppMaterialModule} from '../app-material/app-material.module';
import {GroupByPipe, NgPipesModule} from 'ngx-pipes';
import { FreeVacanciesDetailComponent } from './free-vacancies-detail/free-vacancies-detail.component';
import { SpecificationsModule } from '../specification/specifications.module';
import { ListerDialogComponent } from './lister-dialog/lister-dialog.component';
import { FreeVacanciesFilterComponent } from './free-vacancies-filter/free-vacancies-filter.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FvPlanningDialogComponent } from './fv-planning-dialog/fv-planning-dialog.component';
import { FvPlaningDialogActionItemComponent } from './fv-planing-dialog-action-item/fv-planing-dialog-action-item.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import { FvMarketingCalendarComponent } from './fv-marketing-calendar/fv-marketing-calendar.component';
import { FvOrderChangesDialogComponent } from './fv-order-changes-dialog/fv-order-changes-dialog.component';
import { FvOrderChangesTableComponent } from './fv-order-changes-table/fv-order-changes-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MarketingIconsComponent } from './marketing-icons/marketing-icons.component';
import { SalaryInfoComponent } from './free-vacancies-detail/salary-info/salary-info.component';
import { SalaryRangeComponent } from './free-vacancies-detail/salary-range/salary-range.component';
import { TranslateModule } from '@ngx-translate/core';
import { CompaniesModule } from '../companies/companies.module';
// import { adapterFactory } from 'angular-calendar/date-adapters/moment';



@NgModule({
  declarations: [
    FreeVacanciesListComponent,
    FreeVacanciesTableComponent,
    FreeVacanciesDetailComponent,
    ListerDialogComponent,
    FvPlanningDialogComponent,
    FvPlaningDialogActionItemComponent,
    FvMarketingCalendarComponent,
    FreeVacanciesFilterComponent,
    FvOrderChangesDialogComponent,
    FvOrderChangesTableComponent,
    MarketingIconsComponent,
    SalaryInfoComponent,
    SalaryRangeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppMaterialModule,
    FreeVacanciesRoutingModule,
    SpecificationsModule,
    ClipboardModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgPipesModule,
    TranslateModule,
    CompaniesModule,
   // CalendarModule.forRoot({
    // provide: DateAdapter,
    // useFactory: adapterFactory
    // })
    // MatTableModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatIconModule,

  ],
  providers: [
    GroupByPipe
  ],
  entryComponents: [
    ListerDialogComponent,
    FvPlanningDialogComponent,
    FvOrderChangesDialogComponent
  ]
})
export class FreeVacanciesModule { }
