
<mat-dialog-content >
  <div fxLayout="column">
    <div *ngFor="let comment of comments" class="comment-container">
      <div> {{comment.node.comment}}</div>
      <span class="time"> {{comment.node.createdAt | date: "yyyy-MM-dd"}} </span>
<!--      <input matInput [(ngModel)]="comment.node.comment">-->
    </div>
  </div>



  <div>
      <mat-form-field appearance="outline" class="new-comment">
        <mat-label>{{'NEW_COMMENT' | translate}}</mat-label>
        <textarea matInput [(ngModel)]="newComment"></textarea>

      </mat-form-field>

      <button mat-icon-button (click)="saveComment()"> <mat-icon>save</mat-icon></button>
  </div>

</mat-dialog-content>
