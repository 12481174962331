import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestActivitiesService {

  constructor(
    private http: HttpClient
  ) {

  }

}
