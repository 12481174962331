<div fxLayout="row" fxLayoutAlign="space-between center" class="close-container">
    <h3>{{title}}</h3>
    <button mat-dialog-close mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div fxLayout="column" fxLayoutGap="16px">
    <mat-table [dataSource]="data.extraValues">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ 'NAME' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let lduFormData"> {{lduFormData.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isNew">
            <mat-header-cell *matHeaderCellDef> {{'NEW' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let lduFormData"> {{lduFormData.isNew ? 'Yes' : 'No'}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="openLink(row.url)"></mat-row>

    </mat-table>
</div>