import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AllUserProfilesGQL } from '../../../../graphql/graphql';

@Component({
  selector: 'app-popup-select-complete',
  templateUrl: './popup-select-complete.component.html',
  styleUrls: ['./popup-select-complete.component.scss']
})
export class PopupSelectCompleteComponent implements OnInit {
  users: any;
  selectedProfiles = [];
  search: any;
  filteredProfiles = [];
  hide = false;

  avatarsByUserId: {[key: string]: string} = { };

  @Input() set selected(value) {
    this.selectedProfiles.length = 0;
    if (value) {
      value.forEach(val => this.selectedProfiles.push(Object.assign({ }, val)));
    } else {
      this.selectedProfiles = [];
    }

    if (value?.length === 1 && value[0] === null) {
      this.hide = true;
    } else {
      this.hide = false;
    }
  }

  @Input() removeAvailable = true;
  @Input() addAvailable = true;
  @Input() disabled = false;
  @Input() largeSpace = false;

  @Output() changed = new EventEmitter<string[]>();

  constructor(
    private allUserProfilesGQL: AllUserProfilesGQL,
    public dialogRef: MatDialogRef<PopupSelectCompleteComponent>
  ) { }

  ngOnInit(): void {
    this.allUserProfilesGQL.fetch({ }).subscribe(res => {
      this.users = res.data.userprofiles;
      this.users.forEach(user => this.avatarsByUserId[user.id] = user.profileImageUrl);
      this.filteredProfiles = this.users;
    });
  }

  close() {
    this.dialogRef.close();
  }

  getAvatarLetters(fullName: string) {
    return fullName?.split(' ').map(word => word[0]?.toUpperCase()).join('');
  }

  onModelChange($event: any) {
    this.filteredProfiles = this.users.filter(u => {
      return u.user.firstName?.toLowerCase().indexOf($event) > -1 || u.user.lastName?.toLowerCase().indexOf($event) > -1;
    });
  }

  removeProfile(profile: any) {
    const prof = this.selectedProfiles.filter(p => p.id === profile.id)[0];
    const idx = this.selectedProfiles.indexOf(prof);
    this.selectedProfiles.splice(idx, 1);
    this.emitChanges();
  }

  emitChanges() {
    this.changed.emit(this.selectedProfiles.map(it => it.id));
  }

  addProfile(profile: any) {
    this.selectedProfiles.push({
      id: profile.id,
      fullName: `${profile.user.firstName} ${profile.user.lastName}`,
      profileImageUrl: profile.profileImageUrl,
      color: profile.color
    });
    this.emitChanges();
  }
}
