import { Component, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Moment } from 'moment';
import moment from 'moment/moment';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss']
})
export class CustomDatePickerComponent {
  moment = moment;

  bcColorMap = {
    regular: '#BFBFBF',
    warn: '#FFC107',
    alert: '#D50000'
  };

  textColorMap = {
    regular: '#000',
    warn: '#000',
    alert: '#FFF'
  };

  @Input() initValue: string | Moment | Date;
  @Input() bcColor: 'regular' | 'warn' | 'alert';

  @Output() valueChange = new EventEmitter<moment.Moment>();

  constructor(private renderer: Renderer2) { }

  openDatepicker(picker: any, event: MouseEvent) {
    picker.open();
    const datepickerElement = document.querySelector('.mat-datepicker-popup');

    if (datepickerElement) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const pickerX = event.clientX;
      const pickerY = event.clientY;

      window.setTimeout(() => {
        this.renderer.setStyle(datepickerElement, 'position', 'fixed');
        this.renderer.setStyle(datepickerElement, 'top', `${ pickerY  + 360 > viewportHeight ? pickerY - 360 : pickerY }px`);
        this.renderer.setStyle(datepickerElement, 'left', `${ pickerX + 300 > viewportWidth ? pickerX - 300 : pickerX }px`);
      }, 0);
    }
  }
}
