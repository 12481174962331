import { Component, OnInit } from '@angular/core';
import {UploaderFileTypes} from '../uploader-file-types';

@Component({
  selector: 'app-date-type-dialog',
  templateUrl: './date-type-dialog.component.html',
  styleUrls: ['./date-type-dialog.component.scss']
})
export class DateTypeDialogComponent implements OnInit {
  uploaderFileTypes = UploaderFileTypes;
  nextHealthcheck: Date;
  fileType = this.uploaderFileTypes.medical;

  constructor() { }

  ngOnInit() {
  }

}
