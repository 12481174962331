import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { SpecificationsListDataSource } from './specifications-list-datasource';
import {SpecificationsListGQL, SpecificationsListItem} from './graphql';

@Component({
  selector: 'app-specifications-list',
  templateUrl: './specifications-list.component.html',
  styleUrls: ['./specifications-list.component.scss']
})
export class SpecificationsListComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<SpecificationsListItem>;
  dataSource: SpecificationsListDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'positionTitle', 'company', 'address'];

  constructor(
    private listGQL: SpecificationsListGQL,
  ) {

  }

  ngOnInit() {
    this.dataSource = new SpecificationsListDataSource();

    this.listGQL.watch().valueChanges.subscribe(
      resp => this.dataSource.data.next(resp.data.specifications)
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
