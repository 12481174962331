import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
  // providers: [NotificationService]
})
export class CopyToClipboardService {

  constructor(
    private notificationSerivce: NotificationService
  ) {
  }

  public copyToClipboard(text: string, isHTML: boolean = false): void {
    const el = document.createElement(isHTML ? 'div' : 'textarea');
    if (isHTML) {
      el.innerHTML = text;
    } else {
      el.textContent = text;
    }
    document.body.appendChild(el);

    if (isHTML) {
      const range = document.createRange();
      range.selectNodeContents(el);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      (el as HTMLTextAreaElement).select();
    }

    document.execCommand('copy');
    document.body.removeChild(el);

    this.notificationSerivce.notify('Copied to clipboard!', 0);
  }
}
