import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';

import { NewTaskDialogComponent } from '../new-task-dialog/new-task-dialog.component';
import { TaskServiceService } from '../task-service.service';
import { ProjectManagerGQL } from '../../pm-stats-dashboard/graphql';
import { OperationsTaskFieldsFragment, TaskDetailViewFieldsFragment } from '../../../generated/graphql';
import { convertMinutesToTime } from '../../shared/functions/time-functions';
import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';

interface TasksByDateItem {
  tasks?: OperationsTaskFieldsFragment[];
  durationMinutes: number;
  isLoading: boolean;
}

@Component({
  selector: 'app-task-buckets',
  templateUrl: './task-buckets.component.html',
  styleUrls: ['./task-buckets.component.scss']
})
export class TaskBucketsComponent implements OnInit {
  convertMinutesToTime = convertMinutesToTime;
  subscription = new Subscription();

  filtersConfig = ['search', 'company', 'owners', 'country', 'category'];
  filters: FilterInterface = {};
  filtersSubject = (new BehaviorSubject<FilterInterface>(this.filters));
  bucketsData: {
    today?: TasksByDateItem,
    week?: TasksByDateItem,
    nextWeek?: TasksByDateItem,
    done?: TasksByDateItem,
    doneAll?: TasksByDateItem
  } = { };

  constructor(
    public dialogRef: MatDialogRef<TaskBucketsComponent>,
    private dialog: MatDialog,
    private taskService: TaskServiceService,
    private pmGQL: ProjectManagerGQL,
  ) { }

  ngOnInit(): void {
    this.pmGQL.fetch().subscribe(res => {
      this.filters = { owners: [res.data.currentUser.id] };
      this.getData('today');
      this.getData('week');
      this.getData('nextWeek');
      this.getData('done');
    });

    this.subscription.add(
      this.filtersSubject.pipe(debounceTime(500)).subscribe(filtersValue => this.reloadData(filtersValue))
    );
  }

  getData(target: 'today' | 'week' | 'nextWeek' | 'done' | 'doneAll', filters?: FilterInterface) {
    this.bucketsData[target] = { isLoading: true, durationMinutes: 0 };

    let params = {
      ...(filters || this.filters),
      company: filters?.company ? filters?.company.id : null,
      category: filters?.category || null,
      orderBy: 'date',
      statuses: target.includes('done') ? 'DONE' : 'PLANNED',
      date_Gte: moment().startOf('day').format('YYYY-MM-DD'),
      date_Lte: moment().endOf('day').format('YYYY-MM-DD')
    };

    switch (target) {
      case 'week':
        params = {
          ...params,
          date_Gte: moment().startOf('day').add('day', 1).format('YYYY-MM-DD'),
          date_Lte: moment().endOf('week').format('YYYY-MM-DD')
        };
        break;
      case 'nextWeek':
        params = {
          ...params,
          date_Gte: moment().startOf('week').add('week', 1).format('YYYY-MM-DD'),
          date_Lte: moment().endOf('week').add('week', 1).format('YYYY-MM-DD')
        };
        break;
      case 'done':
        params = {
          ...params,
          orderBy: '-date',
          date_Gte: moment().startOf('week').format('YYYY-MM-DD'),
          date_Lte: moment().endOf('week').format('YYYY-MM-DD')
        };
        break;
      case 'doneAll':
        params = {
          ...params,
          orderBy: '-date',
          date_Gte: null,
          date_Lte: null
        };
    }

    this.taskService.getTasks(params).subscribe(
      res => {
        if (this.bucketsData[target]) {
          const tasks = res.data.tasks.edges.map(data => data.node);
          const totalMinutes = tasks.filter(task => task.durationMinutes).map(task => task.durationMinutes);

          this.bucketsData[target].tasks = tasks;
          this.bucketsData[target].durationMinutes = totalMinutes.length ? totalMinutes.reduce((a, b) => a + b) : 0;
          this.bucketsData[target].isLoading = false;
        }
      },
      () => this.bucketsData[target].isLoading = false
    );
  }

  reloadData(filtersValue: FilterInterface) {
    const keysToReload = Object.keys(this.bucketsData);
    keysToReload.forEach((key: 'today' | 'week' | 'nextWeek' | 'done' | 'doneAll') => this.getData(key, filtersValue));
  }

  updateFilters(event: FilterInterface) {
    this.filtersSubject.next(event);
  }

  openNewTaskDialog(task?: TaskDetailViewFieldsFragment) {
    const config: MatDialogConfig<any> = {
      panelClass: 'rounded-dialog-16',
      width: '600px',
      height: '84vh'
    };
    if (task) {
      config.data = { task };
    }
    this.dialog.open(NewTaskDialogComponent, config);
  }

  changeDoneMod(value: 'done' | 'doneAll') {
    if (value === 'done') {
      this.getData('done');
      delete this.bucketsData.doneAll;
    } else {
      this.getData('doneAll');
    }
  }

  close() {
    this.dialogRef.close();
  }
}
