<h2 mat-dialog-title>
  <div fxLayoutAlign="space-between center">
    <span>{{'ORDER.REQUIREMENT.CREATE' | translate}}</span>

    <button mat-icon-button mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h2>
<mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field appearance="fill">
      <mat-label> {{'ORDER.REQUIREMENT.LOCAL_VALUE' | translate }} </mat-label>
      <input matInput [formControl]="localValue">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field appearance="fill">
      <mat-label> {{'ORDER.REQUIREMENT.ENGLISH_VALUE' | translate }} </mat-label>
      <input matInput [formControl]="englishValue">
    </mat-form-field>
  </div>

  <h5 *ngIf="created"> {{'COMMON.CREATED'  | translate }}: </h5>
  <span>{{created}}</span>

  <h5> {{'ORDER.REQUIREMENT.SIMILAR' | translate}}</h5>
  <div fxLayout="column" class="req-container">
    <span *ngFor="let req of requirements | async">
      {{req.requirement}}
    </span>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button [disabled]="localValue.invalid || englishValue.invalid" mat-flat-button (click)="createRequirement()" color="accent">
    {{'COMMON.CREATE' | translate}}
  </button>
</mat-dialog-actions>