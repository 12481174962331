import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, BehaviorSubject } from 'rxjs';

export class ExcelWidgetDatasource extends DataSource<any> {
  fullData: any[] = [];
  data = new BehaviorSubject<any[]>([]);
  needDataLoadEmitter = new BehaviorSubject(undefined);
  hasNextPage: boolean;
  pageCache: number;

  constructor(
    private initData: any[],
    private paginator: MatPaginator
  ) {
    super();
  }

  connect(): Observable<any[]> {
    this.fullData = this.initData;
    this.paginator.length = this.fullData.length;
    this.setInitialPaginator();
    this.paginator?.page.subscribe( () => {
      if (this.pageCache !== this.paginator.pageIndex) {
        this.pageCache = this.paginator.pageIndex;
        this.data.next(this.fullData.slice(
            this.paginator.pageIndex * this.paginator.pageSize,
            this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize
        ));
        this.needDataLoadEmitter.next(true);
      }
    });
    return this.data.asObservable();
  }

  setInitialPaginator() {
    this.paginator.length = this.fullData.length;
    this.data.next(this.fullData.slice(0, this.paginator.pageSize));
  }

  disconnect() { }
}
