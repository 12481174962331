import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import {OffersTableItem} from '../../../interview-widget/offers-table/offers-table-datasource';
import {OrderOfferStatsTableItem} from '../order-offer-stats-table/types';
import { Subscription } from 'rxjs';
import {
  EventResolutionStatus,
  OfferPropositionInterested,
  OfferPropositionInterestedOnCall,
  OffersGQL,
  OrderStatsFieldsFragment,
  OrderStatsGQL
} from '../../../../generated/graphql';
import { FilterInterface } from '../../../shared/filter-bar/filter-bar.component';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { OrderSummariesSenderComponent } from '../order-summeries-sender/order-summaries-sender.component';
import { CommentDialogComponent } from 'src/app/interview-widget/comment-dialog/comment-dialog.component';
import { OldOrderOfferStatsTableDataSource } from './old-order-offer-stats-table-datasource';

const CALL_OFFER_RESPONSES = {
  [OfferPropositionInterestedOnCall.Interested]: 'Susidomėjo skambučio metu',
  [OfferPropositionInterestedOnCall.NotInterested] : 'Siūlė, bet nesudomino skambučio metu',
  [OfferPropositionInterestedOnCall.Undecided] : 'Skambučio metu neturėjo nuomonės'
};


const OFFER_RESPONSES = {
  [OfferPropositionInterested.Interested]: 'Susidomėjo, bet neįvestas interviu laiko',
  [OfferPropositionInterested.NotInterested] : 'Netiko',
  [OfferPropositionInterested.Undecided] : 'Kazkas ne taip'
};

const WAS_NOT_OFFERED = 'Nepasiūlyta skambučio metu';


@Component({
  selector: 'app-old-order-offer-stats-table',
  templateUrl: './old-order-offer-stats-table.component.html',
  styleUrls: ['./old-order-offer-stats-table.component.scss']
})
export class OldOrderOfferStatsTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OrderOfferStatsTableItem>;

  @Input() orderId: number;
  @Input() companyId: string;
  @Input() hideFilters = false;
  @Input() filters: FilterInterface;
  dataSource: OldOrderOfferStatsTableDataSource;

  offersSubscription: Subscription;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'livas-status', 'phone', 'interview', 'clientInterview', 'contract', 'comment', 'cancellation-reasons'];
  respTypesCall = OfferPropositionInterestedOnCall;
  respTypes = OfferPropositionInterested;
  resolutionStatuses = EventResolutionStatus;

  dateRange = [];

  stats: OrderStatsFieldsFragment = {
    interestedCount: 0,
    notInterestedCount: 0,
    signed: 0,
    totalOffered: 0,
    interestedCallCount: 0,
    notInterestedCallCount: 0
  };

  constructor(
    private offersGql: OffersGQL,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private orderStatsGql: OrderStatsGQL
  ) {

  }

  ngOnInit() {
    const lang = this.translateService.currentLang;
    this.dataSource = new OldOrderOfferStatsTableDataSource(this.offersGql, this.orderId, lang);
    if (this.filters) {
      this.onFilterChange({dateRange: this.filters.dateRange});
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  getTooltipText(row: OffersTableItem) {
      return CALL_OFFER_RESPONSES[row.interestedOnCall] || WAS_NOT_OFFERED;

  }
  getTooltipTextForOffice(row: OffersTableItem) {
      return OFFER_RESPONSES[row.interested] || WAS_NOT_OFFERED;

  }

  onFilterChange($event: FilterInterface) {
    const dateRange = $event.dateRange;
    if (dateRange?.dateFrom && dateRange?.dateTo) {
      const dateFrom = moment(dateRange?.dateFrom)?.format('YYYY-MM-DD');
      const dateTo = moment(dateRange?.dateTo)?.format('YYYY-MM-DD');
      if (this.dataSource) {
        this.dataSource.filters.next({ createdAt_Gte: dateFrom, createdAt_Lte: dateTo });
      }
      this.dateRange = [dateFrom, dateTo];
    } else {
      this.dateRange = [];
    }
  }


  getClientInterviewTootip(row) {
    if (row.clientInterviewStatus) {
      return this.translateService.instant(`CLIENT_INTERVIEW_PROGRESS_TOOLTIPS.${row.clientInterviewStatus}`, { date: moment(row.clientInterviewDate).format('YYYY-MM-DD') });
    }
  }

  getCandidateUrl(row) {

    const country = this.dataSource.country;

    const route = 'kontaktai';
    const id = row.candidateId;

    return `${environment.livasAPIBase}/${country}/${route}.php?id=${id}`;
  }

  sendOrderSummaries() {
    this.dialog.open(OrderSummariesSenderComponent, {
      data: {
        dateRange: this.dateRange,
        orderId: this.orderId,
        companyId: this.companyId
      },
      autoFocus: false
    });
  }

  openCommentDialog(row) {
    console.log(row)
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      panelClass: ['padding-16-dialog', 'width-650-dialog'],
      data: {
        propositionID: row.id
      },
    });


  }
}
