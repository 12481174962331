import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ColorHistoryChange} from '../../../graphql/company-detail-gql';

@Component({
  selector: 'app-color-change-log-dialog',
  templateUrl: './color-change-log-dialog.component.html',
  styleUrls: ['./color-change-log-dialog.component.scss']
})
export class ColorChangeLogDialogComponent implements OnInit {
  changes: ColorHistoryChange[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {historyChange: ColorHistoryChange[]}
  ) {
    this.changes = data.historyChange;
  }

  ngOnInit() {

  }

}
