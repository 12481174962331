import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
// import { GetJobAdAiVersionsGQL } from '../../generated/graphql';
import { first } from 'rxjs/operators';
import {
  CreateJobAdLivasGQL,
  GetJobAdAiVersionsGQL, JobAdDialogMetaGQL,
  LivasJobAdsGQL,
  MutationCreateJobAdLivasArgs
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class JobAdModerationService {

  constructor(
    private http: HttpClient,
    private getJobAdsGql: GetJobAdAiVersionsGQL,
    private livasJobAdsGQL: LivasJobAdsGQL,
    private createJobAdLivasGQL: CreateJobAdLivasGQL,
    private jobAdCatsGQL: JobAdDialogMetaGQL
  ) {
  }


  getJobAds(orderId: any) {
    return this.getJobAdsGql.watch({ orderId }, { fetchPolicy: 'no-cache' }).valueChanges;
  }

  createLivasJobAd(input: MutationCreateJobAdLivasArgs) {
   return this.createJobAdLivasGQL.mutate(input).pipe(first());
  }

  getLivasJobAds(orderId: string) {
    return this.livasJobAdsGQL.fetch({ orderId }).pipe(first());
  }

  getJobAdCat() {
    return this.jobAdCatsGQL.fetch();
  }
}
