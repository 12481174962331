import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import moment from 'moment/moment';

import {
  EmployeeWorkedTimeWorkHours, ExcelWidgetService, SingleShiftDataResp, WorkedHoursByShiftCrateUpdateInput,
  WorkedHoursByShiftEmployeeData
} from '../excel-widget.service';

@Component({
  selector: 'app-shift-params-dialog',
  templateUrl: './shift-params-dialog.component.html',
  styleUrls: ['./shift-params-dialog.component.scss']
})
export class ShiftParamsDialogComponent implements OnInit {
  moment = moment;
  isShiftDataLoading = false;
  shiftData: SingleShiftDataResp;

  livasWorkedData: WorkedHoursByShiftEmployeeData;
  noConfirmedWorkedFromToData: {
    workFrom?: string;
    workTo?: string;
    lunchFrom?: string;
    lunchTo?: string;
  };
  noConfirmedWorkedHoursSumData: {
    hrsDay?: number;
    hrsNight?: number;
    overtimeDay?: number;
    overtimeNight?: number;
    holidaysDay?: number;
    holidaysNight?: number;
  };
  excelFromToData: {
    workFrom?: string;
    workTo?: string;
    lunchFrom?: string;
    lunchTo?: string;
  };
  excelHoursSumData: {
    hrsDay?: number;
    hrsNight?: number;
    overtimeDay?: number;
    overtimeNight?: number;
    holidaysDay?: number;
    holidaysNight?: number;
  };
  isWorkDisplayExcelErr = false;
  isLunchDisplayExcelErr = false;
  isConfirmedStatus = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      shiftStatus: string,
      shiftId: number,
      employeeId: number,
      decryptedDataForm: FormGroup,
      companyCountry: string,
      timeManagementType: '0' | '1',
      workHours: EmployeeWorkedTimeWorkHours
    },
    private excelWidgetService: ExcelWidgetService,
    private dialog: MatDialogRef<ShiftParamsDialogComponent>,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.shiftStatus === 'confirmed') {
      this.isConfirmedStatus = true;
    }

    const {
      fact_time_start, fact_time_end, fact_lunch_start, fact_lunch_end, day, night, holiday_day, holiday_night,
      overtime_day, overtime_night
    } = this.data.workHours;

    if (this.data.shiftId && this.data.employeeId) {
      this.getShiftDataById(this.data.shiftId, this.data.employeeId);
    }

    if (this.data.timeManagementType === '0') {
      this.excelFromToData = this.data?.decryptedDataForm?.get('fromTo')?.value || null;
    } else  if (this.data.timeManagementType === '1') {
      this.excelHoursSumData = this.data?.decryptedDataForm?.get('hoursSum')?.value || null;
    }

    if (this.data.timeManagementType === '0' && (fact_time_start || fact_time_end || fact_lunch_start || fact_lunch_end)) {
      this.noConfirmedWorkedFromToData = {
        workFrom: fact_time_start,
        workTo: fact_time_end,
        lunchFrom: fact_lunch_start,
        lunchTo: fact_lunch_end
      };
    }

    if (this.data.timeManagementType === '1' && ((day !== null || undefined) || (night !== null || undefined) ||
      (holiday_day !== null || undefined) || (holiday_night !== null || undefined) ||
      (overtime_day !== null || undefined) || (overtime_night !== null || undefined))) {
      this.noConfirmedWorkedHoursSumData = {
        hrsDay: day,
        hrsNight: night,
        holidaysDay: holiday_day,
        holidaysNight: holiday_night,
        overtimeDay: overtime_day,
        overtimeNight: overtime_night
      };
    }
  }

  getShiftDataById(shiftId: number, employeeId: number): void {
    this.isShiftDataLoading = true;
    forkJoin({
      shift: this.excelWidgetService.getShiftById(shiftId, this.data.companyCountry),
      workedData: this.excelWidgetService.getWorkedHoursByShift(shiftId, this.data.companyCountry)
    }).subscribe(
      data => {
        this.livasWorkedData = data.workedData.results.find(dataObj => dataObj.employee_id === employeeId);
        this.shiftData = data.shift;
        this.isShiftDataLoading = false;
        this.checkExcelDisplayErr();
      },
      () => this.isShiftDataLoading = false
    );
  }

  openOrderCard(orderId: number): void {
    this.excelWidgetService.openOrderCard(orderId, this.data.companyCountry);
  }

  checkExcelDisplayErr(): void {
    let dataToCompare;
    if (this.livasWorkedData && this.excelFromToData) {
      dataToCompare = {
        shift_start: this.livasWorkedData.fact_time_start,
        shift_end: this.livasWorkedData.fact_time_end,
        shift_lunch_start: this.livasWorkedData.fact_lunch_start,
        shift_lunch_end: this.livasWorkedData.fact_lunch_end
      };
    } else if (this.shiftData && this.excelFromToData) {
      dataToCompare = {
        shift_start: this.shiftData.shift_start,
        shift_end: this.shiftData.shift_end,
        shift_lunch_start: this.shiftData.shift_lunch_start,
        shift_lunch_end: this.shiftData.shift_lunch_end
      };
    }

    if (dataToCompare) {
      const { shift_start, shift_end, shift_lunch_start, shift_lunch_end } = dataToCompare;
      const { workFrom, workTo, lunchFrom, lunchTo } = this.excelFromToData;
      this.isWorkDisplayExcelErr = moment(shift_start).format('HH:mm') !== workFrom
        || moment(shift_end).format('HH:mm') !== workTo;
      this.isLunchDisplayExcelErr = moment(shift_lunch_start).format('HH:mm') !== lunchFrom
        || moment(shift_lunch_end).format('HH:mm') !== lunchTo;
    }
  }

  isSaveActionAvailable(): boolean {
    if (this.excelFromToData) {
      const { workFrom, workTo, lunchFrom, lunchTo } = this.excelFromToData;
      const isExcelFilled = workFrom && workTo && lunchFrom && lunchTo;
      return !!((!this.livasWorkedData && isExcelFilled) || (this.livasWorkedData && isExcelFilled
        && (this.isWorkDisplayExcelErr || this.isLunchDisplayExcelErr)));
    } else if (this.excelHoursSumData) {
      return !this.livasWorkedData;
    } else {
      return false;
    }
  }

  onConfirmClick(): void {
    this.excelWidgetService.setWorkedHoursAsConfirmed(this.data.shiftId, this.data.companyCountry).subscribe(
      () => this.getShiftDataById(this.data.shiftId, this.data.employeeId),
      err => this.openErrorSnackbar(err.error.message)
    );
  }

  onSaveClick(): void {
    // 'YYYY-MM-DD HH:mm:ss'
    const { shift_start, shift_end, shift_lunch_start, shift_lunch_end} = this.shiftData;
    const dataObj: WorkedHoursByShiftCrateUpdateInput = {
      employee_id: this.data.employeeId,
      type: 'hours',
      source: 0
    };

    if (this.data.timeManagementType === '0') {
      const { workFrom, workTo, lunchFrom, lunchTo } = this.excelFromToData;
      dataObj.start_datetime = `${ moment(shift_start).format('YYYY-MM-DD') } ${ workFrom }:00`;
      dataObj.end_datetime = `${ moment(shift_end).format('YYYY-MM-DD') } ${ workTo }:00`;
      dataObj.lunch_start_datetime = `${ moment(shift_lunch_start).format('YYYY-MM-DD') } ${ lunchFrom }:00`;
      dataObj.lunch_end_datetime = `${ moment(shift_lunch_end).format('YYYY-MM-DD') } ${ lunchTo }:00`;
    } else if (this.data.timeManagementType === '1') {
      const { hrsDay, hrsNight, holidaysDay, holidaysNight, overtimeDay, overtimeNight } = this.excelHoursSumData;
      dataObj.normal_day = hrsDay;
      dataObj.normal_night = hrsNight;
      dataObj.holiday_day = holidaysDay;
      dataObj.holiday_night = holidaysNight;
      dataObj.normal_day_overtime = overtimeDay;
      dataObj.normal_night_overtime = overtimeNight;
      dataObj.holiday_day_overtime = this.data.workHours.overtime_holiday_day;
      dataObj.holiday_night_overtime = this.data.workHours.overtime_holiday_night;
    }

    if (this.livasWorkedData) {
      this.excelWidgetService.updateWorkedHoursByShift(
        dataObj,
        this.data.shiftId,
        this.data.companyCountry
      ).subscribe(
        () => this.dialog.close({ needUpdate: true }),
        err => this.openErrorSnackbar(err.error)
      );
    } else {
      this.excelWidgetService.createWorkedHoursByShift(
        dataObj,
        this.data.shiftId,
        this.data.companyCountry
      ).subscribe(
        () => this.dialog.close({ needUpdate: true }),
        err => this.openErrorSnackbar(err.error)
      );
    }
  }

  openErrorSnackbar(errString: any): void {
    this.snackbar.open(this.translate.instant('COMMON.ERRORS.GENERAL') + ' ' + errString, undefined, { duration: 10000 });
  }
}
