import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';
import { map, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { CandidatesService } from '../candidates.service';
import { CandidatesTableDataQuery, EventEventType } from '../../../generated/graphql';

/**
 * Data source for the CandidatesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CandidatesTableDatasource extends DataSource<any> {
  paginator: MatPaginator;
  filters = new BehaviorSubject<FilterInterface>({});
  private dataSubject = new BehaviorSubject<any[]>([]);
  loading = new BehaviorSubject<boolean>(true);
  totalCount = 0;

  constructor(
    private candidatesService: CandidatesService
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any> {
    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page
    ];

    const fetchData = () => {
      this.loading.next(true);
      const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
      const params = {
        first: this.paginator.pageSize,
        after: endCursor,
        manager: this.filters.value.owners || [],
        country: this.filters.value.country,
        company: this.filters.value.company?.id,
        search: this.filters.value.search
      };

      return this.candidatesService.getTableData(params).pipe(
        map(data => {
          this.loading.next(false);
          this.totalCount = data.data.offers.totalCount;
          return this.processResponse(data);
        }),
      );
    };

    const data$ = merge(...dataMutations).pipe(
      switchMap(fetchData)
    );

    data$.subscribe(this.dataSubject);

    return this.dataSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  private processResponse(data: ApolloQueryResult<CandidatesTableDataQuery>) {
    const offers = data.data.offers.edges.map((it) => it.node);

    return offers.map(offer => {
      const candidateEvents = offer.candidateEvents.edges.map((it) => it.node);

      return {
        id: offer.id,
        candidate: offer.candidate?.firstName + ' ' + offer.candidate?.lastName,
        manager: offer.order.projectManager.fullName,
        companyName: offer.order.company.name,
        companyAddress: offer.order.company.address,
        interviewEvent: this.findEvent(candidateEvents, EventEventType.Interview),
        healthcheckEvent: this.findEvent(candidateEvents, EventEventType.HealthCheckPending),
        employmentEvent:  this.findEvent(candidateEvents, EventEventType.Employment),
        comments: offer.offerpropositioncommentSet?.edges.map((it) => it.node)
      };
    });
  }

  private findEvent(edges, eventType) {
    return edges.find((edge) => edge.eventType === eventType);
  }
}
