import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { AllCompaniesGQL, Company } from '../../graphql/graphql';
import { CompaniesListDatasource } from './companies-list.datasource';
import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { ManagerAssignComponent } from '../manager-assign/manager-assign.component';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Company>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: CompaniesListDatasource;
  displayedColumns = [
    'company', 'manager', 'substituteManager', 'recruiter', 'substituteRecruiter', 'color', 'last-color-change', 'checkbox'
  ];
  selectionList: Company[] = [];

  filters;

  constructor(
    private companiesGQL: AllCompaniesGQL,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new CompaniesListDatasource(this.companiesGQL, this.selectionList);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  onFilterChanges(event: FilterInterface) {
    const filters = {};
    if (event.search) { filters['name'] = event.search; }
    if (event.country) { filters['country'] = event.country; }
    if (event.projectManager) { filters['projectManager'] = event.projectManager; }
    if (event.substituteManager) { filters['substituteManager'] = event.substituteManager; }
    if (event.recruiter) { filters['recruiter'] = event.recruiter; }
    if (event.substituteRecruiter) { filters['substituteRecruiter'] = event.substituteRecruiter; }
    this.filters = filters;
    this.dataSource.filters.next(filters);
  }

  selectRow(company: Company) {
    if (!!this.selectionList.find(item => item.id === company.id)) {
      const idx = this.selectionList.findIndex(item => item.id === company.id);
      this.selectionList.splice(idx, 1);
    } else {
      this.selectionList.push(company);
    }
  }

  isSelected(company: Company): boolean {
    return !!this.selectionList.find(item => item.id === company.id);
  }

  openManagerAssign() {
    this.dialog.open(ManagerAssignComponent, {
      data: {
        companies: this.selectionList,
        preselect: this.selectionList.length === 1 ? this.selectionList[0] : undefined
      },
      autoFocus: false
    }).afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.selectionList.splice(0, this.selectionList.length);
        this.dataSource.filters.next(this.filters);
      }
    });
  }
}
