import { Component, Input, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import { RequirementsGQL } from 'src/app/graphql/graphql';

@Component({
  selector: 'app-order-summary-table',
  templateUrl: './order-summary-table.component.html',
  styleUrls: ['./order-summary-table.component.scss']
})
export class OrderSummaryTableComponent implements OnInit {
  // form: FormGroup;

  @Input() form: FormGroup;
  get specificationCtrl() {return this.form && this.form.get('specificationForm'); }
  get clientAndContactCtrl() {return this.form && this.form.get('clientAndContactForm'); }
  get descriptionCtrl() { return this.clientAndContactCtrl && this.clientAndContactCtrl.get('description'); }

  requirementsMap = {};
  constructor(private requirementsGQL: RequirementsGQL) { }

  ngOnInit(): void {
    this.requirementsGQL.fetch().subscribe(res => {
      const requirements = res.data.requirements;
      requirements.forEach(requirement => {
        this.requirementsMap[requirement.id] = requirement.requirement;
      });
    });
  }

  copyTable(element) {

    const elTable = document.querySelector('table');
    const copyEl = elToBeCopied => {
      let range, sel;

      // Ensure that range and selection are supported by the browsers
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        // unselect any element in the page
        sel.removeAllRanges();
        try {
          range.selectNodeContents(elToBeCopied);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(elToBeCopied);
          sel.addRange(range);
        }

        document.execCommand('copy');
      }
      sel.removeAllRanges();
    };
    copyEl(elTable);
  }

}
