<!-- hyperlink-customfield.component.html -->
<div *ngIf='valueController'>

  <a [href]="valueController.get('urlValue').value">
    <mat-icon inline>open_in_new</mat-icon>
    {{valueController.get('textValue').value}}
  </a>

  <button mat-icon-button [matMenuTriggerFor]='editMenu' inline='true'>
    <mat-icon inline>edit</mat-icon>
  </button>

  <mat-menu #editMenu='matMenu'>
    <div style='padding: 0 16px'>
      <mat-form-field appearance='outline' (click)='$event.stopImmediatePropagation()'>
        <mat-label>{{'LINK_URL' | translate}}</mat-label>
        <input matInput [formControl]="valueController.get('urlValue')" />
      </mat-form-field>
      <mat-form-field appearance='outline' (click)='$event.stopImmediatePropagation()'>
        <mat-label>{{'LINK_DISPLAY_TEXT' | translate}}</mat-label>
        <input matInput [formControl]="valueController.get('textValue')" />
      </mat-form-field>
      <button mat-flat-button (click)='editMenu.close()' color='primary'>
        <mat-icon>save</mat-icon>
        {{'SAVE' | translate}}
      </button>
    </div>

  </mat-menu>

</div>
