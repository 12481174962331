import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import {AppMaterialModule} from '../app-material/app-material.module';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { ProjectManagerSelectorComponent } from './project-manager-selector/project-manager-selector.component';
import {ImageWithErrorComponent} from './image-with-error/image-with-error.component';
import { DropdownMultipleFilterComponent } from './filter-bar/dropdown-filter/dropdown-filter.component';
import { ProjectManagerChipSelectorComponent } from './project-manager-chip-selector/project-manager-chip-selector.component';
import { CompanyColorSwitchComponent } from './company-color-switch/company-color-switch.component';
import { ContactBubbleComponent } from './contact-bubble/contact-bubble.component';
import { ContactSelectDialogComponent } from './contact-bubble/contact-select-dialog/contact-select-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import { SalaryRangeInputComponent } from './salary-range-input/salary-range-input.component';
import { TextInputWithHintsComponent } from './text-input-with-hints/text-input-with-hints.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';

import { FormContainerComponent } from './form-container/form-container.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { FilesUploaderComponent } from './files-uploader/files-uploader.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { DurationPipe } from './pipes/duration.pipe';
import {
  PopupSelectCompleteComponent
} from '../orders/order-actions-log/actions-log-dialog/popup-select-complete/popup-select-complete.component';
import { MatDialogRef } from '@angular/material/dialog';
import { EditableTimeValueComponent } from './editable-time-value/editable-time-value.component';
import { LinkifyPipe } from './linkify.pipe';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterCategorySelectComponent } from './filter-bar/filter-category-select/filter-category-select.component';
import { ReasonWithCommentComponent } from './reason-with-comment/reason-with-comment.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { ReasonsDialogComponent } from './reason-with-comment/reasons-dialog/reasons-dialog.component';
import { TaskStatusPillComponent } from '../planner/task-status-pill/task-status-pill.component';

export const MOMENT_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    FilterBarComponent,
    CompanySelectorComponent,
    ProjectManagerSelectorComponent,
    ImageWithErrorComponent,
    DropdownMultipleFilterComponent,
    ProjectManagerChipSelectorComponent,
    CompanyColorSwitchComponent,
    CompanyColorSwitchComponent,
    ContactBubbleComponent,
    ContactSelectDialogComponent,
    SalaryRangeInputComponent,
    TextInputWithHintsComponent,
    FormContainerComponent,
    FilesUploaderComponent,
    MonthPickerComponent,
    TooltipDirective,
    DurationPipe,
    PopupSelectCompleteComponent,
    EditableTimeValueComponent,
    LinkifyPipe,
    CategorySelectorComponent,
    FilterCategorySelectComponent,
    ReasonWithCommentComponent,
    CustomDatePickerComponent,
    ReasonsDialogComponent,
    TaskStatusPillComponent
  ],
  exports: [
    FilterBarComponent,
    CompanySelectorComponent,
    ProjectManagerSelectorComponent,
    ImageWithErrorComponent,
    ProjectManagerChipSelectorComponent,
    CompanyColorSwitchComponent,
    DropdownMultipleFilterComponent,
    ContactBubbleComponent,
    TranslateModule,
    AppMaterialModule,
    SalaryRangeInputComponent,
    TextInputWithHintsComponent,
    NgxMatSelectSearchModule,
    FormContainerComponent,
    FilesUploaderComponent,
    MonthPickerComponent,
    PopupSelectCompleteComponent,
    FilterCategorySelectComponent,
    TooltipDirective,
    DurationPipe,
    EditableTimeValueComponent,
    LinkifyPipe,
    CategorySelectorComponent,
    ReasonWithCommentComponent,
    CustomDatePickerComponent,
    ReasonsDialogComponent,
    TaskStatusPillComponent
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    NgxUploaderModule,
    MatSlideToggleModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'lt-LT' },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS },
    CanDeactivateGuard,
    {
      provide: MatDialogRef,
      useValue: { }
    }
  ]
})
export class SharedModule { }
