// boolean-customfield.component.ts
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-boolean-customfield',
  templateUrl: './boolean-customfield.component.html',
  styleUrls: ['./boolean-customfield.component.scss']
})
export class BooleanCustomfieldComponent {
  @Input() label: string;
  @Input() valueController: FormGroup;
}
