<ngx-charts-line-chart
  *ngIf="periodCounts"
  [results]="periodCounts"
  [xAxis]="true"
  [yAxis]="true"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  [yAxisLabel]="'Poreikis'"
  [xAxisTicks]="ticks"
  [scheme]="colorScheme"
  [legend]="true"
  [legendTitle]="' '">
  <ng-template #tooltipTemplate let-model="model">
    <p>{{model.name}}</p>
    <p>{{model.series}} - {{model.value}}</p>
  </ng-template>
  <ng-template #seriesTooltipTemplate let-model="model">
    <p>{{model[0].name}}</p>
    <p>{{model[0].series}} - {{model[0].value}}</p>
    <p>{{model[1].series}} - {{model[1].value}}</p>
    
  </ng-template>
</ngx-charts-line-chart>
