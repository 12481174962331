import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderPeriodComponent } from './order-period/order-period.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { OrdersRoutingModule } from './orders-routing.module';
import { SpecificationsModule } from '../specification/specifications.module';

import { OrderStatsComponent } from './order-stats/order-stats.component';

import { CompanyDescriptionComponent } from './company-description/company-description.component';
import { CancelOrderDialogComponent } from './order-list/cancel-order-dialog/cancel-order-dialog.component';
import { OrderOfferStatsTableComponent } from './order-stats/order-offer-stats-table/order-offer-stats-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { OrdersCommentsDialogComponent } from '../order/orders-comments-dialog/orders-comments-dialog.component';
import { SelectPersonDialogComponent } from './select-person-dialog/select-person-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { ClientsAndContactsComponent } from './order-create/clients-and-contacts/clients-and-contacts.component';
import { ClientNeedComponent } from './order-create/client-need/client-need.component';
import { OrderSpecificationComponent } from './order-create/order-specification/order-specification.component';
import { ContractSelectFieldComponent } from './contract-select-field/contract-select-field.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { SalaryMatrixComponent } from './order-create/salary-matrix/salary-matrix.component';
import { WorkplaceDescriptionComponent } from './order-create/order-specification/workplace-description/workplace-description.component';
import { OrderSelectListComponent } from './order-create/clients-and-contracts/order-select-list/order-select-list.component';
import {WorkerDescriptionComponent} from './order-create/order-specification/worker-description/worker-description.component';
import { SalaryCellComponent } from './order-list/salary-cell/salary-cell.component';
import { OrderCandidateComponent } from './order-list/order-candidate/order-candidate.component';
import { ExtraSelectionFieldsComponent } from '../order/order-create/extra-selection-fields/extra-selection-fields.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ClipboardModule} from 'ngx-clipboard';
import { OrderSummaryTableComponent } from './order-summary-table/order-summary-table.component';

import {OrderActionsLogModule} from './order-actions-log/order-actions-log.module';
import {NgArrayPipesModule} from 'ngx-pipes';
import { SelectionStatusPillComponent } from './selection-status-pill/selection-status-pill.component';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentsHistoryComponent } from './order-edit/documents-history/documents-history.component';
import { OrderDocumentCreateBtnComponent } from './order-document-create-btn/order-document-create-btn.component';
import { CandidateEvaluationsComponent } from './candidate-evaluations/candidate-evaluations.component';
import { CopyToClipboardService } from '../shared/copy-to-clipboard.service';
import { OrderSummariesSenderComponent } from './order-stats/order-summeries-sender/order-summaries-sender.component';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  OldOrderOfferStatsTableComponent
} from './order-stats/old-order-offer-stats-table/old-order-offer-stats-table.component';
import { InterviewWidgetModule } from '../interview-widget/interview-widget.module';
import { MetricsPopupComponent } from './order-list/metrics-popup/metrics-popup.component';
import { OrderDataErrorReportsComponent } from './order-data-error-reports/order-data-error-reports.component';

@NgModule({
  declarations: [
    OrdersComponent,
    OrderCreateComponent,
    OrderListComponent,
    OrderEditComponent,
    OrderPeriodComponent,
    OrderStatsComponent,
    CompanyDescriptionComponent,
    CancelOrderDialogComponent,
    OrderOfferStatsTableComponent,
    OldOrderOfferStatsTableComponent,
    OrdersCommentsDialogComponent,
    SelectPersonDialogComponent,
    ClientsAndContactsComponent,
    ClientNeedComponent,
    OrderSpecificationComponent,
    ContractSelectFieldComponent,
    SalaryMatrixComponent,
    WorkplaceDescriptionComponent,
    WorkerDescriptionComponent,
    OrderSelectListComponent,
    SalaryCellComponent,
    OrderCandidateComponent,
    ExtraSelectionFieldsComponent,
    OrderSummaryTableComponent,
    SelectionStatusPillComponent,
    DocumentsHistoryComponent,
    OrderDocumentCreateBtnComponent,
    CandidateEvaluationsComponent,
    OrderSummariesSenderComponent,
    MetricsPopupComponent,
    OrderDataErrorReportsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppMaterialModule,
    OrdersRoutingModule,
    SpecificationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatGridListModule,
    SpecificationsModule,
    MatSlideToggleModule,
    ClipboardModule,
    TranslateModule,
    OrderActionsLogModule,
    NgArrayPipesModule,
    MatChipsModule,
    BrowserModule,
    MatAutocompleteModule,
    InterviewWidgetModule
  ],
  providers: [
    CopyToClipboardService
  ],
  exports: [
    OrderPeriodComponent,
    OrderListComponent,
    OrderActionsLogModule
  ],
  entryComponents: [
    CancelOrderDialogComponent
  ]
})
export class OrdersModule { }
