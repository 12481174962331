import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CreateOfferPropositionCommentGQL, CreateOrderCommentGQL, GetOrderCommentsGQL,
  OfferPropositionCommentsGQL,
  UpdateOfferPropositionCommentGQL
} from '../../../generated/graphql';
import moment from 'moment';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {
  moment = moment;
  commentText = '';
  comments = [];
  idOfListEditItem: string | null = null;
  isLoading = true;
  @Input() getCommentsByID: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {comment?: string, propositionID?: string, orderID?: string},
    private offerPropositionCommentsGQL: OfferPropositionCommentsGQL,
    private createOfferPropositionCommentGQL: CreateOfferPropositionCommentGQL,
    private updateOfferPropositionCommentGQL: UpdateOfferPropositionCommentGQL,
    private orderCommentsGQL: GetOrderCommentsGQL,
    private createOrderCommentGQL: CreateOrderCommentGQL
  ) {
    this.commentText = data.comment;
    this.comments = [];
  }

  ngOnInit() {
    if (this.data.propositionID || this.data.orderID || this.getCommentsByID) {
      this.getComments();
    }
  }

  getComments() {
    if (this.data.propositionID || this.getCommentsByID) {
      this.offerPropositionCommentsGQL.watch(
        {offerId: this.data.propositionID  || this.getCommentsByID},
        {fetchPolicy: 'no-cache'}
      ).valueChanges.subscribe(res => {
        this.isLoading = false;
        this.comments = res.data.offerPropositionComments.edges
          .sort((a, b) => moment(b.node.createdAt).unix() - moment(a.node.createdAt).unix());
      });
    } else if (this.data.orderID) {
      this.orderCommentsGQL.watch({orderId: this.data.orderID}, {fetchPolicy: 'no-cache'}).valueChanges
        .subscribe(res => {
          this.isLoading = false;
          this.comments = res.data.orderComments.edges
            .sort((a, b) => moment(b.node.createdAt).unix() - moment(a.node.createdAt).unix());
        });
    }
  }

  createComment() {
    this.isLoading = true;
    if (this.data.propositionID || this.getCommentsByID) {
      this.createOfferPropositionCommentGQL.mutate({
        input: {
          offerProposition: this.data.propositionID || this.getCommentsByID,
          text: this.commentText
        }
      }).subscribe(() => this.getComments());
    } else if (this.data.orderID) {
      this.createOrderCommentGQL.mutate({orderId: this.data.orderID, comment: this.commentText})
        .subscribe(() => this.getComments());
    }
    this.refresh();
  }

  updateComment() {
    this.isLoading = true;
    this.updateOfferPropositionCommentGQL.mutate({input: {id: this.idOfListEditItem, text: this.commentText}})
      .subscribe(() => this.getComments());
    this.refresh();
  }

  isCommentChangingPossible(): boolean {
    return !!this.comments.find(comment => comment.node.id === this.idOfListEditItem && comment.node.text !== this.commentText);
  }

  refresh() {
    this.idOfListEditItem = null;
    this.commentText = '';
  }
}
