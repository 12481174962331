import { Component, OnInit, Input } from '@angular/core';
import { RawCompany, UpdateCompanyDescriptionGQL, Company } from 'src/app/graphql/graphql';
import { FormGroup, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-company-description',
  templateUrl: './company-description.component.html',
  styleUrls: ['./company-description.component.scss']
})
export class CompanyDescriptionComponent {
  private $description: FormControl;
  @Input() set description(value: FormControl) {
    this.$description = value;
  }
  get description() { return this.$description; }

  @Input()
  set company(value: Company) {
    this.companyData = value;
    // this.description = new FormControl(value.description || '');
    // this.description.valueChanges.subscribe(() => {
    //   this.saved = false;
    // });
  }
  get company() { return this.companyData; }
  private companyData;
  saved = true;
  // description: FormControl;

  constructor(
    private updateCompanyDescriptionGQL: UpdateCompanyDescriptionGQL,
    private notify: NotificationService
  ) {}

  updateCompanyDescription() {
    this.updateCompanyDescriptionGQL.mutate({id: this.company.id, description: this.description.value}).subscribe(res => {
      this.saved = true;
      this.notify.notify('Įmonės aprašymas išsaugotas');
    }, err => {
      this.notify.notify('Klaida bandant išsaugoti įmonės aprašymą', 2);
    });
  }

}
