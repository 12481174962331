<button
  class="task-status-pill {{ task.status }}"
  [matMenuTriggerFor]="menu"
  [disabled]="disableStatusChange"
  (click)="onStatusChangeClick(task)"
>
  {{ ('TASK_STATUSES.' + task.status) | translate }}
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let status of statuses" (click)="statusChange.emit(status);">
    <div class="task-status-pill {{ status }}">
      {{ ('TASK_STATUSES.' + status) | translate }}
      <div *ngIf="showNewTaskDate && newTaskDate" class="new-task">
        {{ ('NEW_TASK_FOR' | translate) + ' ' + newTaskDate }}
      </div>
    </div>
  </button>
</mat-menu>
