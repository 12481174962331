<h1 mat-dialog-title>
  <div class="title-wrapper">
    <span>{{ 'TASK_SCHEDULING.' + (data.schedule ? 'EDIT_SCHEDULE' : 'CREATE_SCHEDULE') | translate }}</span>
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
  </button>
  </div>
</h1>
<div mat-dialog-content>
  <form [formGroup]="scheduleForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'TASK_SCHEDULING.RECURRENCE_PERIOD' | translate }}</mat-label>
      <mat-select formControlName="recurrencePeriod" (selectionChange)="recurrencePeriodChanged()">
        <mat-option value="DAILY">{{ 'TASK_SCHEDULING.DAILY' | translate }}</mat-option>
        <mat-option value="WEEKLY">{{ 'TASK_SCHEDULING.WEEKLY' | translate }}</mat-option>
        <mat-option value="MONTHLY">{{ 'TASK_SCHEDULING.MONTHLY' | translate }}</mat-option>
<!--        <mat-option value="BIANNUALLY">{{ 'TASK_SCHEDULING.BI_ANNUALLY' | translate }}</mat-option>-->
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="scheduleForm.controls['recurrencePeriod'].value">
      <mat-form-field appearance='outline'>
        <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'DAILY'">
          {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_DAYS' | translate }}
        </mat-label>
        <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'WEEKLY'">
          {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_WEEKS' | translate }}
        </mat-label>
        <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'MONTHLY'">
          {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_MONTHS' | translate }}
        </mat-label>
        <input matInput type='number' formControlName='intervalCount' />
      </mat-form-field>
    </ng-container>


    <ng-container *ngIf="scheduleForm.get('recurrencePeriod').value === 'MONTHLY'">

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TASK_SCHEDULING.WEEK_OF_MONTH' | translate }}</mat-label>
        <input matInput type="number" formControlName="weekOfMonth" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'TASK_SCHEDULING.DAY_OF_WEEK' | translate }}</mat-label>
        <input matInput type="number" formControlName="dayOfWeek" />
      </mat-form-field>
    </ng-container>

    <p>
      <strong>{{ 'TASK_SCHEDULING.START_DATE' | translate }}:</strong> {{ data.date | date }}
    </p>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'TASK_SCHEDULING.END_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" [min]="todayDate" formControlName="endDate"/>
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">{{ 'TASK_SCHEDULING.CANCEL' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!scheduleForm.valid">
    {{ 'TASK_SCHEDULING.SAVE' | translate }}
  </button>
</div>
