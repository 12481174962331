import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { BaseOrderFieldsFragment, CompanySelectFieldsFragment, CompanyTypeFieldsFragment, SearchCompaniesGQL } from '../../../generated/graphql';
import { CompanyImporterDialogComponent } from 'src/app/insightly/company-importer-dialog/company-importer-dialog.component';
import { Company } from 'src/app/graphql/graphql';
import { OrderCreateService } from '../../order/order-create/order-create.service';
import { RelayIdService } from '../relay-id.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html'
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  ctrl: FormControl;
  filteredCompanies =  new BehaviorSubject<{node?: CompanySelectFieldsFragment}[]>([]);

  private subscriptions: Subscription[] = [];
  private companies$ = [];
  private $country: string;
  private disabled$ = false;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() set country(value: string) {
    this.$country = value;
  }
  get country() { return this.$country; }

  @Input() set companyCtrl(value: FormControl) {
    this.ctrl = value;
  }
  get companyCtrl() { return this.ctrl; }

  @Input() set companies(companies: Company[]) {
    this.companies$ = companies;
  }
  get companies(): Company[] { return this.companies$; }

  selectedCompanyId;
  @Input() set selectedCompany(company: Company) {
    if (company) {
      if (!this.companyCtrl) {
        this.initiCompanyCtrl();
      }
      this.ctrl.setValue(company || '');
      this.selectedCompanyId = company.id;
    } else {
      this.ctrl?.enable();
    }
  }

  preselectCompanyByID;
  @Input() set selectedCompanyById(companyId: string) {
    if (companyId) {
      this.preselectCompanyByID = companyId;
      this.ctrl.setValue('');
    }
  }

  @Input() set disabled(disabled: boolean) {
    this.disabled$ = disabled;
    if (disabled) {
      this.ctrl?.disable();
    } else {
      this.ctrl?.enable();
    }
  }
  get disabled() { return this.disabled$; }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (this.orderCreateService.editOrderId.value && val){
      this.setCompany(val.company);
    }
  }
  get order() { return this.$order; }

  @Input() appearance = 'outline';
  @Input() hidePrefix = false;
  @Input() clearable = false;
  @Input() hideLabel = false;

  @Output() companyAdded = new EventEmitter<Company>();
  @Output() companyChange = new EventEmitter<string | Company>();
  @Output() clearInput = new EventEmitter<string | Company>();
  @Output() selectedCompanyCountry = new EventEmitter<{ id: string, isoCode?: string }>();

  constructor(
    private dialog: MatDialog,
    private companyGql: SearchCompaniesGQL,
    private orderCreateService: OrderCreateService,
    private relayIdService: RelayIdService,
  ) { }

  ngOnInit() {
    if (!this.companyCtrl) {
      this.initiCompanyCtrl();
    }
    this.initCompanyLookup();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setCompany(comp: CompanyTypeFieldsFragment){
    if (comp) {
      comp = Object.assign({}, comp);
      comp.id = btoa(`CompanyModelType:${this.relayIdService.parseId(comp.id)}`);
      this.ctrl.setValue(comp);
      this.ctrl.disable();
      this.orderCreateService.setCompany(comp);
    }
  }

  insightlyImportCompany() {
    const dialogRef = this.dialog.open(CompanyImporterDialogComponent);
    dialogRef.afterClosed().subscribe(
      (c: Company) => {
        if (c) {
          this.companyChange.emit(c);
          this.ctrl.setValue(c);
          this.orderCreateService.setCompany(c);
        }
      }
    );
  }

  displayFn(company?: CompanySelectFieldsFragment): string | undefined {
    return company ? `${company.status || ''} ${company.name || ''} (${company.country?.isoCode.toUpperCase() || ''})` : undefined;
  }

  private initCompanyLookup() {
    this.subscriptions.push(
      this.ctrl.valueChanges.pipe(debounceTime(300))
        .subscribe(newVal => {
          if (typeof newVal === 'string') {
            this.destroy$.next();
            this.companyGql.watch({ search: newVal, country: this.country }).valueChanges
              .pipe(
                takeUntil(this.destroy$),
                switchMap(resp => {
                  const selectedCompany = resp.data.companyInstances.edges.find(
                    c => c.node?.id === this.selectedCompanyId || c.node?.id === this.preselectCompanyByID
                  );
                  if (selectedCompany) {
                    this.ctrl.setValue(selectedCompany.node);
                    this.companyChange.emit(selectedCompany.node as unknown as Company);
                    this.selectedCompanyCountry.emit(selectedCompany.node.country);
                    this.preselectCompanyByID = undefined;
                  }
                  this.filteredCompanies.next(resp.data.companyInstances.edges);
                  return EMPTY;
                })
              )
              .subscribe();
          }
      })
    );
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.companyChange.emit($event.option.value);
    this.orderCreateService.setCompany($event.option.value);
  }

  clear() {
    this.ctrl.setValue('');
    this.selectedCompanyId = null;
    this.clearInput.emit();
  }

  private initiCompanyCtrl() {
    this.companyCtrl = new FormControl({ value: '', disabled: this.disabled });
  }
}
