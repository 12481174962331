import { Component, Input, OnInit } from '@angular/core';
import tippy from 'tippy.js';

@Component({
  selector: 'app-cancellation-reasons-icon-with-tooltip',
  templateUrl: './cancellation-reasons-icon-with-tooltip.component.html',
  styleUrls: ['./cancellation-reasons-icon-with-tooltip.component.scss']
})
export class CancellationReasonsIconWithTooltipComponent implements OnInit {
  @Input() cancellationReasonsAssignments: any[];

  constructor() {
  }

  ngOnInit(): void {
  }

  showCancellationReasonsTooltip(currentTarget: EventTarget, templ: any) {
    const element = currentTarget as HTMLElement;
    // debugger
    const t: any = tippy(element, {
      content: () => {
        const template = templ;
        return template.innerHTML;
      },
      allowHTML: true,
      arrow: true,
      interactive: true,
      theme: 'material'
    });
    t?.show();

  }
}
