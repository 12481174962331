import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsightlyService {

  constructor(
    private http: HttpClient
  ) { }


  searchCompanies(companyTitle): Observable<CompanyInsightly[]> {
    return this.http.get<CompanyInsightlyI[]>(`${environment.url}/api/v1/insightly/company_list/`, {
      headers: this.getAuthHeaders(),
      params: {title: companyTitle}
    }).pipe(
      map(c => c.map(it => this.parseInsightlyCompany(it)))
    );
  }

  private parseInsightlyCompany(c: CompanyInsightlyI): CompanyInsightly {
    const statusField = c.CUSTOMFIELDS.filter(it => it.FIELD_NAME === 'statusas__c');
    const countryCompanyId = c.CUSTOMFIELDS.filter(it => it.FIELD_NAME === 'Imones_kodas__c');
    const companyId = countryCompanyId.length>0 ? countryCompanyId[0].FIELD_VALUE : null;
    return new CompanyInsightly(
      c.ORGANISATION_NAME,
      c.ORGANISATION_ID,
      statusField.length > 0 ? statusField[0].FIELD_VALUE : '',
      companyId ? parseInt(companyId, 10) : null,
      c.ADDRESS_SHIP_COUNTRY,
    );
  }

  private getAuthHeaders() {
    return new HttpHeaders( {Authorization: `Bearer ${localStorage.getItem('token')}`});
  }
}


export interface InsightlyCustomfield {
  FIELD_NAME: string;
  FIELD_VALUE: string;
}

export interface CompanyInsightlyI {
  ORGANISATION_ID: number;
  ORGANISATION_NAME: string;
  CUSTOMFIELDS: InsightlyCustomfield[];
  ADDRESS_SHIP_COUNTRY: string;
}

export class CompanyInsightly {
  constructor(
    public name: string,
    public insightlyId: number,
    public status?: string,
    public companyId?: number,
    public country?: string
  ) {}
}
