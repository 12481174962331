import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import {
  CompanyModelType,
  OrderType,
  CountryFieldsFragment,
  CreateTaskCopyGQL,
  CustomFieldDefinitionFieldType,
  GetCountriesGQL,
  GetOrderForPrefillGQL,
  MeFieldsFragment,
  TaskDetailViewFieldsFragment,
  TaskStatus,
  GetOrderForTaskCreateSearchGQL, OverdueTaskTableFieldsFragment
} from 'src/generated/graphql';
import { TaskServiceService } from '../task-service.service';
import { OrderActionsLogService } from '../../orders/order-actions-log/order-actions-log.service';
import { RelayIdService } from '../../shared/relay-id.service';
import { UserprofileService } from '../../shared/userprofile.service';
import { environment } from '../../../environments/environment';
import { CreateTaskFromPrefill } from '../planner/planner.component';
import { ReasonChipsData } from '../../shared/reason-with-comment/reason-with-comment.component';

@Component({
  selector: 'app-new-task-dialog',
  templateUrl: './new-task-dialog.component.html',
  styleUrls: ['./new-task-dialog.component.scss']
})
export class NewTaskDialogComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  newTaskForm: FormGroup;

  foundOrders: OrderType[];
  categoryInputCtrl: FormControl = new FormControl('');
  categories;
  selectedCategories;
  country: any;
  // Keep this specific order of statuses
  statuses = [
    TaskStatus.Planned, TaskStatus.Announce, TaskStatus.InProgress, TaskStatus.Done, TaskStatus.Cancelled
  ];
  selectedCompany;
  selectedCompanyWithOldId;
  countries: CountryFieldsFragment[] = [];
  selectedCategoryConfig: any; // Fix me
  fieldTypes = CustomFieldDefinitionFieldType;
  newTaskDate: string | null = null;
  task;
  public user: MeFieldsFragment;

  get orderCtrl() {
    return this.newTaskForm.controls['order'];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      task?: TaskDetailViewFieldsFragment,
      prefill?: CreateTaskFromPrefill,
      dashboardId?: string,
      category?: any,
      nextActionCategory?: string,
      createCompletedTask?: boolean;
      user?: MeFieldsFragment,
      refetchQueries?: string[],
      clientId?: string,
      candidateId?: string,
      metricvalueSet: any,
      metricvalueLevel2: any
    },
    private formBuilder: FormBuilder,
    private taskService: TaskServiceService,
    private actionLogService: OrderActionsLogService,
    private dialogRef: MatDialogRef<NewTaskDialogComponent>,
    private relayService: RelayIdService,
    private countriesGQL: GetCountriesGQL,
    private userprofileService: UserprofileService,
    private getOrderForPrefillGQL: GetOrderForPrefillGQL,
    private ordersGQL: GetOrderForTaskCreateSearchGQL,
    private createTaskCopyGQL: CreateTaskCopyGQL
  ) {}

  ngOnInit(): void {
    this.newTaskForm = this.formBuilder.group({
      date: new Date(),
      category: new FormControl(this.data?.category || ''),
      durationMinutes: new FormControl(null),
      comment: new FormControl(''),
      status: new FormControl(this.statuses[0]),
      company: new FormControl('', [Validators.required]),
      country: new FormControl(this.data?.user?.region?.country.id || ''),
      order: new FormControl(''),
      completedBy: new FormControl(null),
      sendNotificationToAssignee: new FormControl(false),
      dashboard: new FormControl(this.data?.dashboardId)
    });

    if (this.data?.nextActionCategory) {
      this.newTaskForm.addControl('nextActionCategory', new FormControl(this.data.nextActionCategory));
    }

    if (typeof this.data?.createCompletedTask === 'boolean') {
      this.newTaskForm.get('status').setValue(this.data.createCompletedTask ? TaskStatus.Done : TaskStatus.Planned);
    }

    if (this.data?.user?.id) {
      this.newTaskForm.get('completedBy').setValue([this.data.user.id]);
    }

    if (this.data?.category) {
      this.categoryInputCtrl.setValue(this.data.category);
      this.selectedCategoryConfig = this.data.category;

      if (this.data.category.showCompany === false) {
        this.newTaskForm.get('company').removeValidators(Validators.required);
      }

      if (this.data?.category.customFields?.edges.length) {
        this.buildDynamicCustomFieldsForm();
      }
    }

    if (this.data?.prefill) {
      const prefill = {
        company: this.data.prefill.company,
        comment: this.data.prefill.title,
        country: btoa(`CountryType:${ this.data.prefill.country }`)
      };
      this.newTaskForm.patchValue(prefill);

      if (prefill.country) {
        this.onCountryValueChange(prefill.country);
      }

      this.selectedCompanyWithOldId = {
        id: this.relayService.convertRelayTypes(this.data.prefill.company, 'CompanyModelType')
      };

      if (this.data.prefill.order) {
        this.getOrderForPrefillGQL.fetch({id: btoa(`OrderMType:${this.data.prefill.order}`)}).pipe(take(1)).subscribe(data => {
          const order = data.data.orderModel;
          this.newTaskForm.controls['order'].setValue(order);
          this.newTaskForm.controls['company'].setValue(order.company.id);
          this.selectedCompanyWithOldId = {
            name: order?.company?.name || '',
            id: this.relayService.convertRelayTypes(order.company.id, 'CompanyModelType')
          };
        });
      }
    }

    if (this.data?.task) {
      this.task = {
        ...this.data.task,
        reasonSet: this.data.task.metricvalueSet.edges[0] || this.data.task?.metricvalueLevel2.edges[0]
          ? this.groupReasonSet(
            (this.data.task.metricvalueSet.edges[0] || this.data.task?.metricvalueLevel2.edges[0]).node.metricvaluecommentSet
          )
          : null,
      };
      const task = {
        ...this.task,
        company: this.task.company?.id,
        country: this.task.country?.id,
        completedBy: this.task.completedBy?.map(user => user.id)
      };
      this.newTaskForm.patchValue(task);

      if (this.task.order) {
        this.newTaskForm.get('company').disable();
        this.newTaskForm.get('country').disable();
      }

      if (this.task.company?.id) {
        this.selectedCompanyWithOldId = {
          ...this.task.company,
          id: this.task.company?.id ? this.relayService
            .convertRelayTypes(this.task.company.id, 'CompanyModelType') : undefined
        };
      }

      if (this.task.country?.id) {
        this.newTaskForm.get('company').removeValidators(Validators.required);
      }

      if (this.isTaskStatusDisabled(this.task)) {
        this.newTaskForm.controls['status'].disable();
      }
    }

    this.newTaskForm.get('country').valueChanges.subscribe(this.onCountryValueChange.bind(this));
    this.getCustomFieldValuesAndCategories(this.task?.id);
    this.setCategoryListener();
    this.getCountries();
    this.setCountryFromProfile();
    this.initOrderSelect();
  }

  groupReasonSet(set): ReasonChipsData[] {
    const grouped: ReasonChipsData[] = [];

    set.edges.forEach(({ node }) => {
      const existReason = grouped.find(reason => reason.metricClientReason.id === node.metricClientReason.id);

      if (existReason) {
        existReason.multiplier = existReason.multiplier + 1;
        existReason.reasonGroup.push({ ...node });
      } else {
        grouped.push({ ...node, multiplier: 1, reasonGroup: [{ ...node }] });
      }
    });

    return grouped;
  }

  getCurrentMetricValue(element: OverdueTaskTableFieldsFragment) {
    return element.metricvalueSet?.edges.length ? element.metricvalueSet.edges[0].node : element.metricvalueLevel2.edges[0]?.node;
  }

  private buildDynamicCustomFieldsForm() {
    if (this.selectedCategoryConfig) {
      const customFieldsGroup = this.selectedCategoryConfig.customFields.edges.reduce(
        (fields, customFieldNode) => {
          fields[customFieldNode.node.id] = this.createFormElement(customFieldNode.node);
          return fields;
        }, {});
      this.newTaskForm.addControl('customFields', this.formBuilder.group(customFieldsGroup));
    }
  }

  private createFormElement(customField): FormControl | FormGroup {
    switch (customField.fieldType.fieldType) {
      case CustomFieldDefinitionFieldType.RatingWithComment:
        return this.formBuilder.group({
          numericValue: null,
          textValue: null
        });
      case CustomFieldDefinitionFieldType.Boolean:
        return this.formBuilder.group({
          boolValue: null
        });
      case CustomFieldDefinitionFieldType.Date:
        return this.formBuilder.group({
          dateValue: null
        });
      case CustomFieldDefinitionFieldType.Heperlink:
        return this.formBuilder.group({
          urlValue: null,
          textValue: null
        });
      case CustomFieldDefinitionFieldType.Numeric:
        return this.formBuilder.group({
          numericValue: null
        });
      default:
        return this.formBuilder.control(null);
    }
  }

  private initOrderSelect() {
    this.subscription.add(
      this.orderCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
        if (typeof value === 'string') {
          this.destroy$.next();
          this.ordersGQL.watch({ search: value, first: 500 }, { fetchPolicy: 'no-cache' }).valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              resp => this.foundOrders = resp.data.ordersPaginated.edges.map(item => item.node as OrderType),
              () => this.foundOrders = null
            );
        } else if (value?.id) {
          this.onCompanyChange(value.company);
          this.onSelectedCompanyCountry(value.company?.country);
          this.selectedCompanyWithOldId = {
            ...value.company,
            id: value.company?.id
              ? this.relayService.convertRelayTypes(value.company.id, 'CompanyModelType')
              : undefined
          };
        }
    })
    );
    // change to disable because decided to allow change order for tasks for contacting employss
    // if (this.task?.order) {
    //   this.orderCtrl.disable();
    // }
  }

  isTaskStatusDisabled(task: any): boolean {
    return task.status === 'DONE' && (task.schedules.edges.length || task.createdBySchedule);
  }

  onNewStatusSelected(event) {
    this.newTaskDate = null;
    if ((this.task?.schedules.edges.length || this.task?.createdBySchedule) && event.value === 'DONE') {
      this.taskService.getNextTaskDate(this.task.id).pipe(take(1)).subscribe(res => {
        const newTaskDate = res.data.nextTaskDate.date;
        if (newTaskDate !== 'None') {
          this.newTaskDate = newTaskDate;
        }
      });
    }
  }

  onSubmit() {
    const category = this.newTaskForm.getRawValue().category;

    const task = {
      ...this.newTaskForm.getRawValue(),
      date: new Date(this.newTaskForm.getRawValue().date).toLocaleDateString('lt-LT'),
      comment: this.newTaskForm.getRawValue().comment,
      category: category?.id,
      order: this.newTaskForm.getRawValue().order?.id || null,
      sendNotificationToAssignee: this.newTaskForm.getRawValue().sendNotificationToAssignee
    };

    if (this.data?.prefill) {
      task.order = this.data.prefill.order ? btoa('OrderType:' + this.data.prefill.order) : '';

      if (this.data.prefill.customFields) {
        this.data.prefill.customFields.forEach(field => {
          const pureField = { ...field };
          delete pureField.customFieldId;
          task.customFields[field.customFieldId] = pureField;
        });
      }
    }

    if (this.task?.id) {
      this.taskService.updateTask({
        ...task,
        id: this.task.id
      }, ['GetOperationsTasks']).subscribe(response => {
        this.taskService.callScheduledTaskCreateMessage(response);
        this.saveCustomFields(response.data.updateOperationsTask.task.id);
        this.dialogRef.close(response.data.updateOperationsTask.task);
      });
    } else {
      if (this.data?.dashboardId) {
        task.dashboard = this.data.dashboardId;
      }

      this.taskService.createTask(task, ['GetOperationsTasks']).subscribe(
        response => {
          this.saveCustomFields(response.data.createOperationsTask.task.id);
          this.dialogRef.close(response.data?.createOperationsTask.task);
        }
      );
    }
  }

  getCustomFieldValuesAndCategories(taskId?) {
    if (taskId) {
      this.taskService.getTaskCustomFieldValuesAndCategories(taskId).subscribe(
        response => {
          this.setCategories(response.data.selectionActionCategoryGroups);
          if (response.data.task) {
            this.setCustomFieldValues(response.data.task.customFieldValues);
          }
        });
    } else {
      this.actionLogService.getSACategoriesGroupsList().subscribe(
        v => {
          this.categories = v.data?.selectionActionCategoryGroups.edges.map(it => {
            return {
              id: it.node.id,
              title: it.node.title,
              country: it.node.country,
              department: it.node.department,
              children: it.node.sacategorySet.edges.map(it2 => {
                const category = it2.node;
                if (this.task?.category?.id === category.id || this.data?.prefill?.category === category.id) {
                  this.newTaskForm.get('category').setValue(category);
                  this.categoryInputCtrl.setValue(category);
                  this.selectedCategoryConfig = category;
                  this.buildDynamicCustomFieldsForm();
                }
                return category;
              })
            };
          }) || [];

          this.selectedCategories = this._filterGroup(
            this.task?.category?.title ? this.task.category?.title : ''
          );

          if (this.data?.prefill?.category) {
            let prefilledCategory;

            this.categories.forEach(categoryGroup => {
              const search = categoryGroup.children.find(category => {
                return this.decode(category.id).split(':')[1] === this.data.prefill.category;
              });
              if (search) { prefilledCategory = search; }
            });

            this.categoryInputCtrl.setValue(prefilledCategory);
            this.onCategoryChange(prefilledCategory);
          }
        }
      );
    }
  }

  onCompanyChange($event: any) {
    const newCompanyId = $event?.id ? this.relayService.convertRelayTypes($event?.id || '', 'CompanyType') : null;
    this.newTaskForm.get('company').setValue(newCompanyId);
    this.selectedCompany = {
      id: newCompanyId,
      name: $event?.name
    };
  }

  getCountries() {
    this.countriesGQL.fetch().subscribe(res =>
      this.countries = res.data.countries.edges.map(n => n.node)
    );
  }

  onCountryValueChange(event: string) {
    if (event) {
      this.newTaskForm.get('company').removeValidators(Validators.required);
    } else {
      this.newTaskForm.get('company').addValidators(Validators.required);
    }
    this.newTaskForm.get('company').updateValueAndValidity();
  }


  private _filterGroup(value: string) {
    if (value) {
      return this.categories?.map(group => ({title: group.title, children: this.categoryFilter(group.children, value)}))
        .filter(group => group.children?.length > 0);
    }

    return this.categories;
  }

  private categoryFilter(opt, value: string): string[] {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return opt.filter(item => item.title.toLowerCase().includes(filterValue));
    }
  }

  private saveCustomFields(taskId: string) {
    // if (this.newTaskForm.value.category.title === 'Plan number of calls') {
    //   let key = Object.keys(this.newTaskForm.value.customFields);
    //   this.taskService.setCustomPlanValue(this.newTaskForm.value.customFields[key[0]].numericValue);
    // }
    if (this.isAddedCustomFields() || this.data?.prefill?.customFields) {
      const customFields = this.newTaskForm.value.customFields || {};
      const customFieldsValues = Object.keys(customFields).map(key => {
        return {
          customFieldId: key,
          ...customFields[key]
        };
      });

      if (this.data?.prefill?.customFields) {
        this.data.prefill.customFields.forEach(field => {
          const idx = customFieldsValues.findIndex(v => v.customFieldId === field.customFieldId);
          if (idx !== -1) {
            customFieldsValues[idx] = field;
          } else {
            customFieldsValues.push(field);
          }
        });
      }
      this.taskService.saveTaskCustomFieldValues(taskId, customFieldsValues).subscribe();
    }
  }

  setCategoryListener() {
    this.categoryInputCtrl.valueChanges.subscribe(str => {
      // this.selectedCategories = this._filterGroup(str);
    });
  }

  selectCategory(event: MatAutocompleteSelectedEvent) {
    this.newTaskForm.get('category').setValue(event.option.value);
    const durationControl = this.newTaskForm.get('durationMinutes');
    if (durationControl.value === null || durationControl.value === '') {
      durationControl.setValue(event.option.value.durationMinutes);
    }
    this.selectedCategoryConfig = event.option;
  }

  onSelectedProfilesChange($event: string[]) {
    this.newTaskForm.patchValue({ completedBy: $event });
  }

  getGroupTitle(group: any): string {
    if (group.department) {
      return `${group.title} (${group.department})`;
    } else {
      return group.title;
    }
  }

  onSelectedCompanyCountry($event: { id: string, isoCode?: string }) {
    if (!this.newTaskForm.get('country').value) {
      this.newTaskForm.get('country').setValue($event.id);
    }
  }

  private setCountryFromProfile() {
    this.userprofileService.getMe().subscribe(profile => {
      if (profile.data?.me?.region?.country?.id && !this.data?.prefill?.country) {
        this.newTaskForm.get('country').setValue(profile.data.me.region.country.id);
      }
      if (!this.newTaskForm.controls['completedBy'].value) {
        this.newTaskForm.controls['completedBy'].setValue([profile.data?.me.id]);
      }
      this.user = profile.data?.me;
    });
  }

  isAddedCustomFields() {
    return this.newTaskForm.get('customFields');
  }

  private setCategories(selectionActionCategoryGroups) {
    this.categories = selectionActionCategoryGroups.edges.map(it => {
      return {
        id: it.node.id,
        title: it.node.title,
        country: it.node.country,
        department: it.node.department,
        children: it.node.sacategorySet.edges.map(it2 => {
          const category = it2.node;
          if (this.task?.category?.id === category.id) {
            this.newTaskForm.get('category').setValue(category);
            this.categoryInputCtrl.setValue(category);
            this.selectedCategoryConfig = category;
            this.buildDynamicCustomFieldsForm();
          }
          return category;
        })
      };
    }) || [];
    this.selectedCategories = this._filterGroup(this.task?.category?.title ? this.task.category?.title : '');
  }

  private setCustomFieldValues(customFieldValues) {
    const customFieldObj = {};
    customFieldValues.edges.forEach(it => {
      customFieldObj[it.node.customField.id] = it.node;
    });
    this.newTaskForm.patchValue({ customFields: customFieldObj });
  }

  getOrderEditUrl() {
    const orderId = this.relayService.parseId(this.task?.order.id) || this.data?.prefill?.order;
    return `orders/edit/${orderId}`;
  }

  getCandidateEmployeeLivasUrl(employee = false) {
    let country = 'lt';
    if (this.newTaskForm.get('country').value) {
      country = this.countries.filter(it => it.id === this.newTaskForm.get('country').value)[0].isoCode;

    } else if (this.task.order?.company?.country) {
      country = this.task.order.company.country.isoCode;
    } else if (this.data?.prefill?.order) {
      country = this.newTaskForm.get('order').value.company.country.isoCode;
    }

    const route = employee ? 'personalas' : 'kontaktai';
    const id = employee ? this.task.candidate.employeeLivasId : this.task.candidate.livasId;

    return `${environment['livasAPIBase' + country.toUpperCase()]}/${country}/${route}.php?id=${id}`;
  }

  onCategoryChange($event: any) {
    this.newTaskForm.get('category').setValue($event);
    this.selectedCategoryConfig = $event;
    this.buildDynamicCustomFieldsForm();
  }

  deleteTask(id) {
    this.taskService.deleteTask(id, ['GetOperationsTasks']).subscribe(() => {
      this.dialogRef.close({ delete: true });
    });
  }

  copyTask(task) {
    this.createTaskCopyGQL.mutate({ input: { id: task.id } }).subscribe(
      resp => {
        this.dialogRef.close(resp.data.createTaskCopy.task);
      },
      err => {}
    );
  }

  orderDisplayFn(order: OrderType): string | undefined {
    return order ? `${ atob(order.id).split(':')[1] }: ${order.specification?.positionTitle || '' } (${ order.company?.name || '' })` : undefined;
  }

  decode(item: string): string {
    return atob(item);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
}
