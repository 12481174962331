import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { ProjectManagerGQL } from '../../pm-stats-dashboard/graphql';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {
  filtersConfig = ['search', 'owners', 'country', 'company'];
  filters: FilterInterface = {};
  filtersSubject = (new BehaviorSubject<FilterInterface>(this.filters));
  filterObservable = this.filtersSubject.pipe(debounceTime(500));

  constructor(
    private pmGQL: ProjectManagerGQL,
  ) { }

  ngOnInit(): void {
    this.getCurrentProjectManager();
  }

  updateFilters(event: FilterInterface) {
    this.filtersSubject.next(event);
  }

  getCurrentProjectManager() {
    this.pmGQL.fetch().subscribe(res => {
      this.filters = {owners: [res.data.currentUser.id]};
    });
  }
}
