import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { FvOrderChangesTableDataSource, FvOrderChangesTableItem } from './fv-order-changes-table-datasource';

@Component({
  selector: 'app-fv-order-changes-table',
  templateUrl: './fv-order-changes-table.component.html',
  styleUrls: ['./fv-order-changes-table.component.scss']
})
export class FvOrderChangesTableComponent implements AfterViewInit, OnInit {
  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<FvOrderChangesTableItem>;
  dataSource: FvOrderChangesTableDataSource = new FvOrderChangesTableDataSource([]);


  @Input() set data(value) {
    this.dataSource.data.next(value);
  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['field', 'oldValue', 'newValue',];

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
