<div class="main" fxLayout="column" fxLayoutGap="16px">
  <mat-card *ngIf="marketingData" >
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
      <h3>{{ 'BRIEF_INFORMATION' | translate }}</h3>
      <app-marketing-icons [order]="order"></app-marketing-icons>
    </div>
    <div fxLayout="row">
      <div fxLayout="150px" fxLayout="column">
        <h4>{{ 'FUNCTIONS' | translate }}</h4>
        <span class="functions">{{order?.specification?.workFunctions || '-'}}</span>
      </div>
    </div>
  </mat-card>
<mat-card fxFlex="100" class="first" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutGap="16px">
    <div fxFlex="33">
      <h3>{{ 'WORK' | translate }}</h3>
      <div fxLayout="column">
        <div fxLayout="row">
          <span>
            {{(order.specification?.allFunctionsFromFirstDay ? 'ALL_FUNCTIONS_FROM_FIRST_DAY' : 'NOT_ALL_FUNCTIONS_FROM_FIRST_DAY') | translate }}.
            <span *ngIf="order.specification?.onboardingDuration">&nbsp;{{'TRAINING_DURATION' | translate}}:  {{order.specification?.onboardingDuration}}</span>
          </span>
        </div>

        <div fxLayout="row">
          <span *ngIf="order.specification?.minExperienceMonths">
            Minimali {{order.specification?.experienceType === experienceTypes.same ? ' analogiška' : order.specification?.experienceType === experienceTypes.similar ? ' panaši' : ' betkokia'}} darbinė patirtis - {{order.specification?.minExperienceMonths}} mėn.
          </span>
          <span *ngIf="!order.specification?.minExperienceMonths">
            {{'NO_WORK_EXPERIENCE_REQUIRED'| translate}}
          </span>
        </div>

        <div *ngIf="order.company?.description" id="companyDescription" class="company-description-container">
          <h4 class="header">{{'COMPANY_DESCRIPTION' | translate}}</h4>
          <div class="company-description" #description
               [style]="{ maxHeight: showDescription ? '1000px': '120px'}" [innerHTML]="order.company.description | linkify"></div>
          <div fxLayout="row" fxLayoutAlign="center">
            <button mat-icon-button (click)="showDescription = !showDescription">
              <mat-icon>more_horizontal</mat-icon>
            </button>
          </div>

<!--          <mat-form-field fxFlex="100">-->
<!--            <textarea matInput disabled="true"-->
<!--                [(ngModel)]="order.company.description" [ngModelOptions]="{standalone: true}" rows="5">-->
<!--            </textarea>-->
<!--          </mat-form-field>-->
        </div>


      </div>
    </div>
    <div fxFlex="{{order.additionalClientContacts.edges?.length > 0 ? '33' : '66'}}">
      <h3>{{'FREE_VACANCIES.REMUNERATION' | translate}}</h3>
      <div fxLayout="row" fxLayoutGap="12px">
        <div fxLayout="column" class="salary-container" fxFlex="100">
          <app-salary-info
            [comment]="order?.salaryInfo"
            [salaryInfo]="order?.specification?.salary"
            [canIncreaseSalary]="order?.canIncreaseSalary"
            [canGetBonuses]="order?.canGetBonuses"
          ></app-salary-info>
        </div>

      </div>
    </div>

    <div fxFlex='33'>
      <h3>{{'CLIENT.CLIENT_CONTACT' | translate}}</h3>
      <div fxLayout='row' class='additional-contacts' fxLayoutGap='8px'>
        <app-contact-bubble [contact]='order.clientContact'></app-contact-bubble>
      </div>
      <h3 *ngIf='order?.specification?.additionalRecruitementInformation'>
        {{'SPECIFICATION.ADDITIONAL_RECRUITEMENT_INFORMATION' | translate}}
      </h3>
      <span style='white-space: pre-line'>{{order?.specification?.additionalRecruitementInformation}}</span>
    </div>

  </div>


  <div fxLayout="row" fxLayoutGap="16px">
    <div fxFlex="33" class="bordered">
      <div fxLayout="column" class="schedule-container">
        <h4 class="header">{{'SCHEDULE.SCHEDULE' | translate }}</h4>
        <app-schedule-setup [schedule]="schedule" [readonly]="true"></app-schedule-setup>
      </div>
    </div>

      <div fxFlex="33">
        <h4 class="header">{{'ORDER.DEMAND' | translate}}</h4>
        <div fxLayout="column" fxLayoutGap="8px" class="container period" >

          <span *ngIf="order.shortage">{{order.shortage === 1 ? 'CURRENT_SHORTAGE_SINGLE' : 'CURRENT_SHORTAGE_MULTI'  | translate:{count: order.shortage} }}</span>
          <div *ngFor="let period of periods"
               fxLayout="column" fxLayoutGap="2px">

            <div fxLayout="row" fxLayoutGap="4px">
              <span fxLayoutAlign="flex-start center">
                <mat-icon svgIcon="calendar-blank"></mat-icon>
                <span *ngIf="period.longTerm; else notLongTerm"> Ilgalaikis nuo {{period.dateFrom}}</span>
                <ng-template #notLongTerm>
                  <span> {{period.dateFrom}} / {{period.dateTo}}</span>
                </ng-template>
              </span>
              <span fxLayoutAlign="flex-start center">
                <mat-icon svgIcon="human-male"></mat-icon>
                <span>{{period.demand}}</span>
              </span>
            </div>

          </div>
        </div>
      </div>
      <div fxFlex="33">

        <h4 class="header">{{ 'WORKPLACE_BENEFITS' | translate }}</h4>
        <div fxLayout="column" class="container">
          <div *ngIf="order.specification; else noBenefits">
            <div *ngFor="let benefit of order.specification.tagassignmentSet | filterBy: ['tag.group.key'] : tagGroupKeys.workplaceBenefits"
                 fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="flex-start center">
              <mat-icon svgIcon="check" class="success"></mat-icon>
              <span>{{benefit.tag.title | translate}}. <mat-hint *ngIf="benefit.comment">Comment: {{benefit.comment}}</mat-hint></span>
            </div>
          </div>
          <ng-template #noBenefits>
            {{'NO_BENEFITS' | translate}}
          </ng-template>
        </div>
      </div>
  </div>
</mat-card>

<div fxLayout="row" fxLayoutGap="16px">

  <mat-card fxFlex="50">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 style="margin-top: 0">{{ 'WORKER' | translate }}</h3>

      <div fxLayout="row" fxLayoutGap="4px">
        <mat-icon svgIcon="account-card-details"
                  matTooltip="{{order.specification?.certificates?.length > 0 ? 'Pažymėjimai' : 'Nenurodyti jokie reikalingi pažymėjimai'}}"
                  class="mat-icon notranslate mat-icon-no-color {{order.specification?.certificates?.length > 0 ? 'clickable info' : 'disabled'}}"
                  (click)="order.specification?.certificates?.length > 0 ? openListerDialog('Reikalingi pažymėjimai', order.specification?.certificates) : ''">
        </mat-icon>
        <mat-icon svgIcon="hospital-box"
                  matTooltip="{{order.specification?.riskFactors?.length > 0 ? 'Rizikos faktoriai' : 'Nenurodyti jokie rizikos faktoriai'}}"
                  class="mat-icon notranslate mat-icon-no-color {{order.specification?.riskFactors?.length > 0 ? ' clickable alert' : 'disabled'}}"
                  (click)="order.specification?.riskFactors?.length > 0 ? openListerDialog('Rizikos faktoriai', order.specification?.riskFactors) : ''">
        </mat-icon>
      </div>


    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <div fxFlex="50">
        <h4 class="header">{{ 'ORDER.MANDATORY_REQUIREMENTS' | translate }}</h4>
        <div fxLayout="column" class="container">
          <app-candidate-requirements *ngIf="order.specification?.mandatoryRequirements.length > 0; else noRequirements"
                                      [initialize]="order.specification?.mandatoryRequirements"
                                      [readonly]="true">
          </app-candidate-requirements>
          <ng-template #noRequirements>
            {{ 'NO_REQUIREMENTS_SPECIFIED' | translate }}
          </ng-template>
        </div>
      </div>
      <div fxFlex="50">
        <h4 class="header">{{ 'ORDER.PREFERRED_REQUIREMENTS' | translate }}</h4>
        <div fxLayout="column" class="container">
          <app-candidate-requirements *ngIf="order.specification?.optionalRequirements.length > 0; else noRequirements"
                                      [initialize]="order.specification?.optionalRequirements"
                                      [readonly]="true">
          </app-candidate-requirements>
        </div>
      </div>
    </div>

     <div *ngIf="order.specification.ageFrom || order.specification.ageTo">
        <b>{{ 'ORDER.AGE_RESTRICTION' | translate }}</b><br/>
        <span *ngIf="order.specification.ageFrom"> {{'FROM' | translate}} {{order.specification.ageFrom}}</span>
        <span *ngIf="order.specification.ageTo"> {{'TO' | translate}} {{order.specification.ageTo}}</span>

     </div>
  </mat-card>
  <mat-card fxFlex="50" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 style="margin-top: 0;">{{'ADDITIONAL_INFORMATION'| translate}}</h3>
      <div fxLayout="row" fxLayoutGap="4px">
        <mat-icon *ngIf="smsTag"
                  ngxClipboard [cbContent]="smsTag.comment"
                  svgIcon="email"
                  class="warning clickable"
                  (click)="onSmsClick()"
                  matTooltip="Kopijuoti sms šabloną">
        </mat-icon>
        <mat-icon *ngIf="order.createdBy?.user.firstName || order.createdBy?.user.lastName"
                  svgIcon="account-tie"
                  matTooltip="PV - {{order.createdBy?.user?.firstName}} {{order.createdBy?.user?.lastName}}"
                  class="info">
        </mat-icon>
      </div>
    </div>
    <div>
        <div fxLayout="column" class="extra-info-container larger">
          <ng-template #no1>
            <mat-icon svgIcon="close" class="alert"></mat-icon>
          </ng-template>
          <ng-container *ngFor="let tag of tags">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
              <mat-icon svgIcon="check" class="success" *ngIf="tag.assigned; else no1"></mat-icon>
              <span>
                {{tag.label | translate}}. <mat-hint *ngIf="tag.comment">Komentaras: {{tag.comment}}</mat-hint>
              </span>
            </div>

          </ng-container>

<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="getCvObject(order); else no1"></mat-icon>-->
<!--            <span>-->
<!--                Klientas reikalauja CV. {{getCvObject(order)?.comment}}-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="getWorkFairObject(order); else no1"></mat-icon>-->
<!--            <span>-->
<!--                Darbo mugė. {{getWorkFairObject(order)?.comment}}-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isWorkplaceVideoAllowed; else no1"></mat-icon>-->
<!--            <span>-->
<!--              Filmuoti darbo vietas ir rodyti video kandidatams-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isWorkplaceVisitAllowed; else no1"></mat-icon>-->
<!--            <span>-->
<!--              Prieš įsidarbinant galimybė apžiūrėti darbo vietas-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isLogoPublicAllowed; else no1"></mat-icon>-->
<!--            <span>-->
<!--              Logo rodyti viešai-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isCompanyModern; else no1"></mat-icon>-->
<!--            <span>-->
<!--                Įmonė moderni-->
<!--            </span>-->
<!--          </div>-->
<!--          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">-->
<!--            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isCompanyInternational; else no1"></mat-icon>-->
<!--            <span>-->
<!--                Įmonė tarptautinė-->
<!--            </span>-->
<!--          </div>-->
        </div>
      </div>
  </mat-card>
</div>
</div>
