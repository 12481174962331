import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {InvitedDataSource, InvitedItem, PMsDict} from './invited-datasource';
import {HttpClient} from "@angular/common/http";
import {Region, RegionService} from '../region.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { GetReservedTimesGQL } from 'src/generated/graphql';

@Component({
  selector: 'app-invited',
  templateUrl: './invited.component.html',
  styleUrls: ['./invited.component.scss']
})
export class InvitedComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<InvitedItem>;
  dataSource: InvitedDataSource;

  pms = PMsDict;
  cities = Object.keys(PMsDict);
  private $selectedDate: string;

  regionsArray: Region[] = [];

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['hour', 'Vilnius', 'Kaunas',
      'Klaipėda',
    'Šiauliai',
    'Plungė',
    'Panevėžys',
    'Tauragė',
    'Ukmergė',
    'Mažeikiai',
    'Marijampolė',
    'Utena',
    'Šilutė',
    'Alytus',

    'Užsienis'
  ];
  @Input() set selectedDate(value) {
    this.$selectedDate = value;

    if (this.dataSource) {
      this.dataSource.changeDate(value);
    }
  }

  constructor(
    private  http: HttpClient,
    private regions: RegionService,
    private snackbar: MatSnackBar,
    private changeRef: ChangeDetectorRef,
    private getReservedTimesGQL: GetReservedTimesGQL
  ) {

  }

  ngOnInit() {
    this.dataSource = new InvitedDataSource(this.http, this.getReservedTimesGQL);
    if (this.$selectedDate) {
      this.dataSource.changeDate(this.$selectedDate);
    }
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  loadData() {
    this.regions.getRegions().subscribe(
      regions => {
        regions.forEach(
          reg => {
            PMsDict[reg.region_title] = reg.value;
          }
        );

        this.regionsArray = regions;
      }
    );
  }

  save(cityName) {

    const filtered = this.regionsArray.filter(r => r.region_title === cityName);
    if (filtered.length === 1) {

      this.regions.updatePMCount(this.pms[cityName], filtered[0].region).subscribe(
        r => {
          PMsDict[r.region_title] = r.value;
          this.dataSource.updateCity(cityName);
          this.snackbar.open(`Išsaugota sėkmingai: ${r.region_title}, ${r.value}`, undefined, {duration: 3000});
        },
        err => {
          this.snackbar.open(`Neišsaugojo. PRANEŠK: ${cityName}`, undefined, {duration: 3000});
        }
      );
    } else {
      this.snackbar.open(`Negeras miestas: ${cityName}`, undefined, {duration: 3000});
    }


  }

}
