import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CandidateRequirementsEvaluationRecordType,
  GetCandidateNameGQL, GetOrderRequirementsAndCandidateEvaluationsGQL, OrderLanguage,
  SaveRequirementsMutationGQL
} from '../../../generated/graphql';
import { RelayIdService } from '../../shared/relay-id.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../shared/notification.service';
import { CopyToClipboardService } from '../../shared/copy-to-clipboard.service';

@Component({
  selector: 'app-candidate-evaluations-dialog',
  templateUrl: './candidate-evaluations-dialog.component.html',
  styleUrls: ['./candidate-evaluations-dialog.component.scss']
})
export class CandidateEvaluationsDialogComponent implements OnInit {

  public requirements: { id: string, requirement?: string }[] = []; // Replace the type with the actual requirements object type
  public additionalInfo: string;
  public comments = {};
  public candidateFullName: string;
  workExperience: string;
  public startsWorking: string;
  private language: OrderLanguage;

  constructor(
    public dialogRef: MatDialogRef<CandidateEvaluationsDialogComponent>,
    private getOrderRequirementsAndEvals: GetOrderRequirementsAndCandidateEvaluationsGQL,
    @Inject(MAT_DIALOG_DATA) public data: {
      candidate: { livasId: number },
      offerProposition: { responseId: string, order: string }
    },
    private translate: TranslateService,
    private createCandidateRequirementsGQL: SaveRequirementsMutationGQL,
    private relay: RelayIdService,
    private notificationSerivce: NotificationService,
    private candidateGql: GetCandidateNameGQL,
    private copyService: CopyToClipboardService
  ) {
    const orderMTypeId = this.relay.convertRelayTypes(data.offerProposition.order, 'OrderMType');
    this.getOrderRequirementsAndEvals.fetch({
      orderId: orderMTypeId,
      offerPropositionId: this.relay.decodeSingleIdForType(data.offerProposition.responseId, 'OfferProposition')
    }, { fetchPolicy: 'no-cache' }).subscribe(
      (result) => {
        this.requirements = result.data.orderModel.specification?.mandatoryRequirements;
        this.requirements.map((req) => {
          this.comments[req.id] = '';
        });
        this.language = result.data.orderModel.language;
        result.data.candidateEvaluations.edges.forEach((edge) => {
          const node = edge.node;
          if (node.recordType === CandidateRequirementsEvaluationRecordType.Requirement) {
            this.comments[node.requirement.id] = node.description;
          } else if (node.recordType === CandidateRequirementsEvaluationRecordType.WorkExperience) {
            this.workExperience = node.description;
          } else if (node.recordType === CandidateRequirementsEvaluationRecordType.OtherInfo) {
            this.additionalInfo = node.description;
          } else if (node.recordType === CandidateRequirementsEvaluationRecordType.StartsWorking) {
            this.startsWorking = node.description;
          }
        });
      }
    );

    this.candidateGql.fetch({ livasId: data.candidate.livasId }).subscribe((result) => {
      this.candidateFullName = result.data.candidate.firstName + ' ' + result.data.candidate.lastName;
    });
  }

  ngOnInit(): void {
  }

  onSave(): void {
    const responseId = this.relay.decodeSingleIdForType(this.data.offerProposition.responseId, 'OfferProposition');

    const inputList = this.requirements.map((req) => ({
      description: this.comments[req.id],
      offerPropositionId: responseId,
      recordType: CandidateRequirementsEvaluationRecordType.Requirement,
      requirementId: this.relay.decodeSingleIdForType(req.id, 'RequirementType')
    }));

    inputList.unshift({
      description: this.workExperience,
      offerPropositionId: responseId,
      recordType: CandidateRequirementsEvaluationRecordType.WorkExperience,
      requirementId: null
    });

    inputList.push({
      description: this.additionalInfo,
      offerPropositionId: responseId,
      recordType: CandidateRequirementsEvaluationRecordType.OtherInfo,
      requirementId: null
    });

    inputList.push({
      description: this.startsWorking,
      offerPropositionId: responseId,
      recordType: CandidateRequirementsEvaluationRecordType.StartsWorking,
      requirementId: null
    });

    this.createCandidateRequirementsGQL.mutate({ input: inputList })
      .subscribe(
        () => {
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error saving candidate requirements:', error);
        }
      );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  async onCopyToClipboard() {
    let fullNameHeader = this.translate.instant('FULL_NAME');
    let workExperienceHeader = this.translate.instant('WORK_EXPERIENCE');
    let additionalInfoHeader = this.translate.instant('ADDITIONAL_INFO_ABOUT_CANDIDATE');
    let startsWorkingHeader = this.translate.instant('STARTS_WORKING');
    let complianceHeaders = this.translate.instant('COMPLIANCE_WITH_REQUIREMENTS');
    if (this.language === OrderLanguage.LanguageEn) {
      const enTranslation = await this.translate.getTranslation('en').toPromise();
      workExperienceHeader = enTranslation['WORK_EXPERIENCE'];
      fullNameHeader = enTranslation['FULL_NAME'];
      additionalInfoHeader = enTranslation['ADDITIONAL_INFO_ABOUT_CANDIDATE'];
      startsWorkingHeader = enTranslation['STARTS_WORKING'];
      complianceHeaders = enTranslation['COMPLIANCE_WITH_REQUIREMENTS'];
    }
    const header = `
      <tr>
        <td rowspan="2"><strong>${fullNameHeader}</strong></td>
        <td colspan="${this.requirements.length + 3}" style="text-align: center;">${complianceHeaders}</td>
      </tr>
      <tr>
        <td>${workExperienceHeader}</td>
        ${this.requirements.map((requirement) => '<td>' + requirement.requirement + '</td>')}
        <td><strong>${additionalInfoHeader}</strong></td>
        <td><strong>${startsWorkingHeader}</strong></td>
      </tr>
    `;
    const columnStyle = 'style="max-width: 150px; width: 150px"';
    const nameColumnStyle = 'style="max-width: 110px; width: 110px"';
    const workExpColumnStyle = 'style="max-width: 190px; width: 190px"';
    const content = `
      <tr>
        <td ${nameColumnStyle}>${this.candidateFullName}</td>
        <td ${workExpColumnStyle}>${this.workExperience || ''}</td>
        ${this.requirements.map((requirement) => `<td ${columnStyle}>` + (this.comments[requirement.id] || '') + '</td>')}
        <td ${columnStyle}>${this.additionalInfo || ''}</td>
        <td ${columnStyle}>${this.startsWorking || ''}</td>
    `;
    const htmlTable = `
      <table border="1" cellpadding="5" cellspacing="0" style="font-family: Calibri; font-size: 12px !important;">
        ${header}
        ${content}

      </table>`;
    this.copyService.copyToClipboard(htmlTable, true);
  }


}
