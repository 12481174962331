import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Order, OrderEdit, OrderEditGQL, Region} from '../../graphql/graphql';
import {GetOrderForPrefillGQL} from '../../../generated/graphql';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit {
  orderId: string;

  constructor(
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this.orderId = this.route.snapshot.paramMap.get('id');
  }


}
