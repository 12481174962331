<mat-form-field style="width: 100%;" [appearance]="appearance">
  <mat-label *ngIf="!hideLabel">{{'COMPANY' | translate}}</mat-label>
  <input type="text" matInput [formControl]="ctrl" [matAutocomplete]="companyAutoCmpl">

  <mat-autocomplete #companyAutoCmpl="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="onOptionSelected($event)">

    <mat-option *ngFor="let company of filteredCompanies.asObservable() | async"
                [value]="company.node">
      {{company.node.status}} {{company.node.name}} ({{company.node.country?.isoCode.toUpperCase()}})
    </mat-option>
  </mat-autocomplete>

  <button mat-icon-button matPrefix *ngIf="!hidePrefix && ctrl.enabled" (click)="$event.stopPropagation(); insightlyImportCompany()">
    <mat-icon>add_box</mat-icon>
  </button>

  <button mat-icon-button matSuffix *ngIf="clearable">
    <mat-icon inline="true" (click)="$event.stopPropagation(); clear()" matSuffix>close</mat-icon>
  </button>
</mat-form-field>
