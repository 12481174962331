import { Component, OnInit, Input } from '@angular/core';
import { TagGroupKey, ExperienceTypes } from 'src/app/specification/specification-edit/specification-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { ListerDialogComponent } from '../lister-dialog/lister-dialog.component';
import { TagAssignment } from 'src/app/specification/tag-assignment-table/tag';
import { NotificationService } from 'src/app/shared/notification.service';
import {FreeVacanciesTableItem} from '../free-vacancies-table/free-vacancies-table-datasource';
import {FreeVacanciesSpecificationFieldsFragment} from '../../../generated/graphql';
import { ScheduleChangeEvent } from 'src/app/specification/schedule-setup/schedule-setup.component';

export const SMSTagName = 'TAG.SMS_TEMPLATE'; //'SMS Šablonas';
export const CVTagName = 'TAG.CLIENT_REQUIRES_CV';//'Klientas reikalauja CV';
export const ClientApproveTagName = 'TAG.CLIENT_INTERVIEWS_CANDIDATES';//'Klientas kalbinsis kandidatus';
export const WorkFairTagName = 'TAG.WORK_FAIR';//'Darbo mugė';
export const ExtendContract = 'TAG.OPTION_TO_EXTEND_CONTRACT'; //'Galimybė pratęsti užsakymą';

@Component({
  selector: 'app-free-vacancies-detail',
  templateUrl: './free-vacancies-detail.component.html',
  styleUrls: ['./free-vacancies-detail.component.scss']
})
export class FreeVacanciesDetailComponent implements OnInit {

  private order$;
  periods;
  schedule: ScheduleChangeEvent;

  tagGroupKeys = TagGroupKey;
  experienceTypes = ExperienceTypes;

  smsTag;

  @Input()
  set order(value: FreeVacanciesTableItem) {
    if (value) {


      this.periods = ( value && value.orderperiodSet) || [];

      if (value.specification) {
        // if (value.specification.groupedAssignments ) {console.log(value.specification.groupedAssignments)}
        let shiftPattern = [];
        shiftPattern = JSON.parse(value.specification.shiftPattern);
        if (! Array.isArray(shiftPattern) ) {
          shiftPattern = [shiftPattern];
        }
        this.schedule = {
          shiftDuration: value.specification.shiftDuration,
          breakDuration: value.specification.breakDuration,
          scheduleTypes: value.specification.scheduleTypes,
          scheduleType: value.specification.scheduleType,
          shiftPattern,
          scheduleText: value.specification.scheduleText
        };

        this.updateTags(value);

        // const extraInfo = [
        //   {
        //     title: ClientApproveTagName,
        //     assignments: tagAssignments.filter(it => it.tag.title === ClientApproveTagName)
        //   },
        //   {
        //     title: CVTagName,
        //     comment: },
        //   {title: '', comment: undefined},
        //   {title: '', comment: undefined},
        //   {title: '', comment: undefined},
        //   {title: '', comment: undefined},
        // ]
      }
      this.order$ = value;


      //TODO uncomment thsi stuff
      // Object.keys(value.specification.groupedAssignments).map(group => {
      //   if ([TagGroupKey.workplaceInto, TagGroupKey.transportInfo, TagGroupKey.perspectives].indexOf(group) > -1) {
      //     value.specification.groupedAssignments[group].map(
      //       assignment => {
      //         this.tags.push({comment: assignment.comment, label: assignment.tag.title, assigned: true, order: 1});
      //       }
      //     );
      //
      //     // this.tags.push({
      //     //   comment
      //     // })
      //   }
      // });

      this.checkSms(value);
    }
  }

  get order() {
    return this.order$;
  }

  @Input() marketingData = false;
  tags: {comment?: string, assigned: boolean, label: string}[] = [];

  constructor(
    private dialog: MatDialog,
    private notify: NotificationService
  ) { }

  ngOnInit() {
  }

  openListerDialog(title: string, list: {title: string}[]) {
    this.dialog.open(ListerDialogComponent, {
      data: {title, list: list.map(e => e.title)}
    });
  }

  checkSms(order: FreeVacanciesTableItem) {

    if (order) {
      const smses = order.specification.tagassignmentSet.filter(it => it.tag.key === SMSTagName);
      this.smsTag = smses.length > 0 ? smses[0] : null;
    }
    // if (order.specification && order.specification.groupedAssignments[TagGroupKey.perspectives]) {
    // //   return order.specification.groupedAssignments[TagGroupKey.perspectives].filter(e => (e as TagAssignment).tag.title === SMSTagName)[0];
    // // }

    // TODO fix this

  }

  // getCvObject(order: FreeVacanciesTableItem) {
  //   if (order.specification && order.specification.groupedAssignments[TagGroupKey.perspectives]) {
  //     return order.specification.groupedAssignments[TagGroupKey.perspectives].filter(e => (e as TagAssignment).tag.title === CVTagName)[0];
  //   }
  //   return false;
  // }
  //
  // getClientApprovalObject(order: FreeVacanciesTableItem) {
  //   if (order.specification && order.specification.groupedAssignments[TagGroupKey.perspectives]) {
  //     return order.specification.groupedAssignments[TagGroupKey.perspectives].filter(e => (e as TagAssignment).tag.title === ClientApproveTagName)[0];
  //   }
  //   return false;
  // }
  //
  // getWorkFairObject(order: FreeVacanciesTableItem) {
  //   if (order.specification && order.specification.groupedAssignments[TagGroupKey.perspectives]) {
  //     return order.specification.groupedAssignments[TagGroupKey.perspectives].filter(e => (e as TagAssignment).tag.title === WorkFairTagName)[0];
  //   }
  //   return false;
  // }

  onSmsClick() {
    this.notify.notify('Šablonas nukopijuotas');
  }




//
//   -            <mat-icon svgIcon="check" class="success" *ngIf="getClientApprovalObject(order); else no1"></mat-icon>
// -            <span>
// -                Klientas tvirtinsis kandidatus. {{getClientApprovalObject(order)?.comment}}
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="getCvObject(order); else no1"></mat-icon>
// -            <span>
// -                Klientas reikalauja CV. {{getCvObject(order)?.comment}}
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="getWorkFairObject(order); else no1"></mat-icon>
// +              <mat-icon svgIcon="check" class="success" *ngIf="tag.assigned; else no1"></mat-icon>
//                <span>
// -                Darbo mugė. {{getWorkFairObject(order)?.comment}}
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isWorkplaceVideoAllowed; else no1"></mat-icon>
// -            <span>
// -              Filmuoti darbo vietas ir rodyti video kandidatams
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isWorkplaceVisitAllowed; else no1"></mat-icon>
// -            <span>
// -              Prieš įsidarbinant galimybė apžiūrėti darbo vietas
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isLogoPublicAllowed; else no1"></mat-icon>
// -            <span>
// -              Logo rodyti viešai
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isCompanyModern; else no1"></mat-icon>
// -            <span>
// -                Įmonė moderni
// -            </span>
// -          </div>
// -          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="4px">
// -            <mat-icon svgIcon="check" class="success" *ngIf="order.specification?.isCompanyInternational; else no1"></mat-icon>
// -            <span>
// -                Įmonė tarptautinė
  showDescription =false;


  private updateTags(order: FreeVacanciesTableItem): void {
    const tagAssignments = order.specification.tagassignmentSet;
    this.tags.length = 0;
    [ClientApproveTagName, CVTagName, WorkFairTagName, ].forEach(
      title => {
        const matches = tagAssignments.filter(it => it.tag.key === title);
        this.tags.push({label: title, assigned: matches.length > 0, comment: matches.length > 0 ? matches[0].comment : ''})
      }
    );

    this.tags.push({label: ExtendContract, assigned: order.orderCanBeExtended, comment: order.orderCanBeExtendedComment});
    this.tags.push({label: 'TAG.WORKPLACE_VIDEO_ALLOWED', assigned: order.specification?.isWorkplaceVideoAllowed});//'Filmuoti darbo vietas ir rodyti video kandidatams'
    this.tags.push({label: 'TAG.WORKPLACE_VISIT_ALLOWED', assigned: order.specification?.isWorkplaceVisitAllowed});//'Prieš įsidarbinant galimybė apžiūrėti darbo vietas'
    this.tags.push({label: 'TAG.LOGO_PUBLIC_ALLOWED', assigned: order.isLogoPublicAllowed}); //'Logo rodyti viešai'
    this.tags.push({label: 'TAG.COMPANY_IS_MODERN', assigned: order.isCompanyModern}); //'Įmonė moderni'
    this.tags.push({label: 'TAG.COMPANY_INTERNATIONAL', assigned: order.isCompanyInternational}); //'Įmonė tarptautinė'

  }
}

