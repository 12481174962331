import {SalaryCalculatorService} from '../salary-matric/salary-calculator.service';

export enum SalaryTypes {
  hourlyBruto = 0,
  hourlyNetto = 1,
  monthlyBruto = 2,
  monthlyNetto = 3,
}
export enum SalaryRangeTypes {
  from = 0,
  to = 1
}

export enum SalarySizeTypes {
  bruto = 0,
  netto = 1
}

const coeficiets = [
  [1, 0.65, 168, 0.65 * 168],
  [1 / 0.65, 1, 168 / 0.65, 168],
  [1 / 168, 0.65 / 168, 1, 0.65],
  [1 / (0.65 * 168), 1 / 168, 1 / 0.65, 1],
];

export enum InputValueStatus {
  clean = 0,
  edited = 1,
  warning = 2,
  saved = 3,
  error = 4,
}

export interface SalaryRangeValues {
  fromValue: number;
  toValue: number;
  fromEdited: InputValueStatus;
  toEdited: InputValueStatus;
}

export interface SalaryRangeChangeEvent {
  value: SalaryRangeValues;
  lastChangedType: SalaryRangeTypes;
  lastChangedValue: number;
}

export class SalaryMatrixValues {
  values = [[undefined, undefined], [undefined, undefined], [undefined, undefined], [undefined, undefined]];
  edits = [
    [InputValueStatus.clean, InputValueStatus.clean],
    [InputValueStatus.clean, InputValueStatus.clean],
    [InputValueStatus.clean, InputValueStatus.clean],
    [InputValueStatus.clean, InputValueStatus.clean],
  ];

  conversionFunctions = [ // order is relevant for this!!!!!!
    [
      (x) => x, // brutto hr to brutto hr
      (x) => (this.salaryService.getMonthlyNetto(x * 168) / 168).toFixed(2), // hr br -> hr net,
      (x) => (x * 168).toFixed(2), // hr br -> month br
      (x) => this.salaryService.getMonthlyNetto(x * 168).toFixed(0)
    ],
    [
      (x) => (this.salaryService.getMonthlyBruttoFromNetto(x * 168) / 168).toFixed(2), // netto hr to brutto hr
      (x) => x, // hr br -> hr net,
      (x) => this.salaryService.getMonthlyBruttoFromNetto(x * 168).toFixed(2), // hr br -> month br
      (x) => (x * 168).toFixed(0)
    ],
    [
      (x) => (x / 168).toFixed(2), // brutto month to brutto hr
      (x) => (this.salaryService.getMonthlyNetto(x) / 168).toFixed(2), // hr br -> hr net,
      (x) => x, // hr br -> month br
      (x) => this.salaryService.getMonthlyNetto(x).toFixed(0)
    ],
    [
      (x) => (this.salaryService.getMonthlyBruttoFromNetto(x) / 168).toFixed(2), // netto month to brutto hr
      (x) => (x / 168).toFixed(2), // ,
      (x) => this.salaryService.getMonthlyBruttoFromNetto(x).toFixed(2), // hr br -> month br
      (x) => x
    ],
  ];



  constructor(
    private salaryService: SalaryCalculatorService,
    public hourlyBrutoFrom?: number,
    public hourlyBrutoTo?: number,
    public hourlyNettoFrom?: number,
    public hourlyNettoTo?: number,
    public monthlyBrutoFrom?: number,
    public monthlyBrutoTo?: number,
    public monthlyNettoFrom?: number,
    public monthlyNettoTo?: number,

    public hourlyBruttoFromFilled?: boolean,
    public hourlyBruttoToFilled?: boolean,
    public hourlyNettoFromFilled?: boolean,
    public hourlyNettoToFilled?: boolean,
    public monthlyBruttoFromFilled?: boolean,
    public monthlyBruttoToFilled?: boolean,
    public monthlyNettoFromFilled?: boolean,
    public monthlyNettoToFilled?: boolean,
  ) {
    this.values = [
      [hourlyBrutoFrom, hourlyBrutoTo],
      [hourlyNettoFrom, hourlyNettoTo],
      [monthlyBrutoFrom, monthlyBrutoTo],
      [monthlyNettoFrom, monthlyNettoTo]
    ];

    this.edits = [
      [this.getInputStatus(hourlyBrutoFrom, hourlyBruttoFromFilled, true), this.getInputStatus(hourlyBrutoTo, hourlyBruttoToFilled, true)],
      [this.getInputStatus(hourlyNettoFrom, hourlyNettoFromFilled, true), this.getInputStatus(hourlyNettoTo, hourlyNettoToFilled, true)],
      [this.getInputStatus(monthlyBrutoFrom, monthlyBruttoFromFilled, true), this.getInputStatus(monthlyBrutoTo, monthlyBruttoToFilled, true)],
      [this.getInputStatus(monthlyNettoFrom, monthlyNettoFromFilled, true), this.getInputStatus(monthlyNettoTo, monthlyNettoToFilled, true)]
    ];
    this.calculateValues(true);

  }

  getInputValues(valueType: SalaryTypes): SalaryRangeValues {

    return {
      fromValue: this.values[valueType][0],
      toValue: this.values[valueType][1],
      fromEdited: this.edits[valueType][0],
      toEdited: this.edits[valueType][1]
    };
  }

  private getInputStatus(value, filled = false, initial = false) {
    return value && filled ?  ( initial ? InputValueStatus.saved : InputValueStatus.edited) : InputValueStatus.clean;
  }


  updateValues(newValue, filled: boolean, valueType, valueRangeType, initial = false) {
    this.edits[valueType][valueRangeType] = this.getInputStatus(newValue, filled);
    this.values[valueType][valueRangeType] = newValue;

    this.values.forEach((value, index) => {
      //update not edited values based on coeficients
      if (!this.edits[index][valueRangeType] && !(initial && this.values[index][valueRangeType])) {
        this.values[index][valueRangeType] = newValue ? this.conversionFunctions[valueType][index](newValue) : null;
      } else if (this.edits[index][valueRangeType] === InputValueStatus.saved && (valueType !== index) ) {
        if (! initial) {
          this.edits[index][valueRangeType] = InputValueStatus.warning;
        }
      }

    });

    //update edited fields with warnings to show that
  }

  calculateValues(initial = false) {
    if (this.hourlyBrutoFrom) {
      this.updateValues(this.hourlyBrutoFrom, this.hourlyBruttoFromFilled, SalaryTypes.hourlyBruto, SalaryRangeTypes.from, initial);
    }
    else if (this.monthlyBrutoFrom) {
      this.updateValues(this.monthlyBrutoFrom, this.monthlyBruttoFromFilled, SalaryTypes.monthlyBruto, SalaryRangeTypes.from, initial);
    }
    else if (this.hourlyNettoFrom) {
      this.updateValues(this.hourlyNettoFrom, this.hourlyNettoFromFilled, SalaryTypes.hourlyNetto, SalaryRangeTypes.from, initial);
    }
    else if (this.hourlyBrutoFrom) {
      this.updateValues(this.monthlyNettoFrom, this.monthlyNettoFromFilled, SalaryTypes.monthlyNetto, SalaryRangeTypes.from, initial);
    }

    // From
    if (this.hourlyBrutoTo) {
      this.updateValues(this.hourlyBrutoTo, this.hourlyBruttoToFilled, SalaryTypes.hourlyBruto, SalaryRangeTypes.to, initial);
    }
    else if (this.monthlyBrutoTo) {
      this.updateValues(this.monthlyBrutoTo, this.monthlyBruttoToFilled, SalaryTypes.monthlyBruto, SalaryRangeTypes.to, initial);
    }
    else if (this.hourlyNettoTo) {
      this.updateValues(this.hourlyNettoTo, this.hourlyNettoToFilled, SalaryTypes.hourlyNetto, SalaryRangeTypes.to, initial);
    }
    else if (this.monthlyNettoTo) {
      this.updateValues(this.monthlyNettoTo, this.monthlyNettoToFilled, SalaryTypes.monthlyNetto, SalaryRangeTypes.to, initial);
    }
  }
}
