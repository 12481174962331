<div fxLayout="row" fxLayoutGap="10px" class="status">
  <!-- Traffic generation -->

  <mat-icon [matTooltip]="tooltips[order?.trafficGeneration]"
            [class.active]="order?.trafficGeneration === orderTrafficGeneration.activeWithMarketing"
            [class.primary]="order?.trafficGeneration === orderTrafficGeneration.activeNoMarketing"
  >{{iconsDict[order?.trafficGeneration]}}</mat-icon>

  <!-- Sex generation -->
  <mat-icon
           svgIcon="{{order?.specification?.male ? order?.specification?.female ? 'human-male-female' : 'human-male' : (order?.specification?.female ? 'human-female' : 'human-male-female') }}"
           matTooltip="{{order?.specification?.male ? order?.specification?.female ? 'Kandidatuoti gali ir vyrai, ir moterys' : 'Kandidatuoti gali tik vyrai' : 'Kandidatuoti gali tik moterys'}}"
           class="gender"

           [class.male]="order?.specification?.male"
           [class.female]="order?.specification?.female">
  </mat-icon>

  <!-- Intensity -->
  <mat-icon svgIcon="{{iconsDict[order?.specification?.intensity]}}"

            [matTooltip]="order?.specification?.intensity? (('ORDER.WORK_POSE_OPTIONS.' + order.specification.intensity) | translate) : ''"
             class="intensity mat-icon notranslate {{iconsDict[order?.specification?.intensity]}}">
   </mat-icon>

  <!-- Transportation -->
  <mat-icon svgIcon="{{transportation ? 'car' : 'car-off'}}"

            [class.active]="transportation"
            class="transportation"
         matTooltip="{{transportation || 'Nenurodytas joks transportas'}}"
         [matTooltipClass]="'tooltip-multiline'">
  </mat-icon>

  <!-- Calendar -->

  <ng-template #staffing>
    <mat-icon svgIcon="{{  !order.isLongTerm ? 'calendar-remove' : 'calendar-check'}}"
           matTooltip="{{!order.isLongTerm ? 'Trumpalaikis darbas' : 'Ilgalaikis darbas'}}"
           class="duration"
           [class.long-term]="order.isLongTerm">
    </mat-icon>
  </ng-template>

  <ng-template #selection>
    <mat-icon

      matTooltip="{{'Atranka'}}"
           class="duration blue">
      search
    </mat-icon>
  </ng-template>

  <ng-container *ngTemplateOutlet=" isSelection ? selection:  staffing "></ng-container>


  <!-- Perspectives Todo: fix-->
  <mat-icon

    (click)='$event.stopPropagation(); orderActionLogService.openChangeLogDialog(order.id, order.company.country.id);'
    [class.active]='perspectives'
    class='perspectives'
    matTooltip="{{perspectives || 'Nenurodytos jokios perspektyvos'}}"
    [matTooltipClass]="'tooltip-multiline'">
    list
  </mat-icon>

  <mat-icon inline
            [matBadge]='order.statsFutureInterview'
            [matBadgeColor]="'accent'"
            [matBadgeSize]='"small"'

            matTooltip='{{"PLANNED_INTERVIEWS_INCLUDING_TODAY" | translate }}'
  >
    meeting_room
  </mat-icon>
</div>
