import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) {
      return value;
    }

    const urlRegex = /(\s+|^)(https?:\/\/[^\s]+)(\s+|$)/g;

    return value.replace(urlRegex, (match, before, url, after) => {
      const domain = new URL(url).hostname;
      return `${before} <a href="${url}" target="_blank" rel="noopener noreferrer" style="margin-left:2px">${domain}</a> ${after}`;
    });
  }


}
