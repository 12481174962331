import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProgressActionsServiseService} from '../progress-actions-servise.service';
import {EventEventType, EventResolutionStatus, OfferPropositionInterested} from '../../../../generated/graphql';
import {LivasStatuses} from '../../../livas/livas.service';
import * as moment from 'moment';
import {ProgressBarData, ProgressStages} from '../progress-component/types';
import {CancellationServiceService} from '../../cancellation-reason-dialog/cancellation-service.service';
import { OrderTypeEnum } from 'src/app/shared/types/order-types';
import {RelayIdService} from '../../../shared/relay-id.service';

@Component({
  selector: 'app-progress-action-dialog',
  templateUrl: './progress-action-dialog.component.html',
  styleUrls: ['./progress-action-dialog.component.scss']
})
export class ProgressActionDialogComponent implements OnInit {
  loading = false;
  countryIsoId: string = 'lt';
  stages = ProgressStages;
  resolutions = EventResolutionStatus;
  livasResolutions = LivasStatuses;
  interested = OfferPropositionInterested;

  eventId: string;
  date = new Date();
  hour = '10:00';
  eventTypes = EventEventType;

  showNextContactDate = false;
  extraDate = moment().add(moment().day() === 5 ? 3 : 1, 'day');
  selectedCancellationAnswer: OfferPropositionInterested;

  selectedLivasStatus: any;
  selectedCancellationReasons: string[] = [];
  livasStatusOptions = [
    { label: 'LIVAS_STATUSES.WILL_NOT_OFFER', id: LivasStatuses.wontOffer },
    { label: 'LIVAS_STATUSES.CURRENLTY_NOT_ACTIVE', id: LivasStatuses.currentlyNotActive },
    { label: 'LIVAS_STATUSES.CANDIDATE_WAITS_OFFER', id: LivasStatuses.candidateWaitsOffer },
    { label: 'LIVAS_STATUSES.CANNOT_CONTACT', id: LivasStatuses.cannotContact },
    { label: 'LIVAS_STATUSES.WAIT_FOR_DECISION', id: LivasStatuses.waitingDecision },
    { label: 'LIVAS_STATUSES.REDIRECTED_TO_WORKIS', id: LivasStatuses.redirectedToWorkis },
  ];
  doResolvePreviousEvents = true;
  OrderTypeEnum = OrderTypeEnum;
  selectedNegativeResolutionStatus: EventResolutionStatus;

  selectedInterviewNoShow = false;
  selectedResolutionStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      events: ProgressBarData,
      stage: ProgressStages,
      personId: number,
      countryIsoId: string,
    },
    public progressService: ProgressActionsServiseService,
    private dialog: MatDialogRef<ProgressActionDialogComponent>,
    private cancellationService: CancellationServiceService,
    private relayService: RelayIdService
  ) {
    const event = data.events[data.stage];
    this.eventId = event?.id;
    console.log(this.data);
    this.countryIsoId = this.data.countryIsoId;
    if (event?.startDatetime) {
      this.date = new Date(event.startDatetime);
      this.hour = moment(this.date).format('HH:mm');
    }
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  isBtnDisable() {
    return this.loading || !this.eventId;
  }

  private getDatetime() {
    const hourParts = this.hour.split(':');

    if (hourParts.length === 2){
      //needed to convert moment object to date object. Moment objects comes from datepickerl
      this.date = new Date(this.date);
      const datetime = this.date.setHours(parseInt(hourParts[0], 10), parseInt(hourParts[1], 10));
      return this.date.toISOString();
    } else {
      return;
    }


  }

  eventUpdate(resolution: EventResolutionStatus, eventType, autoclose= true, updateOfferSigned = false) {
    this.loading = true;
    const startDatetime = this.getDatetime();

    const livasStatusId = this.getLivasStatus(this.data.stage, resolution);
    const updateLivasOfficeInterviewDate = (eventType === EventEventType.Interview);
    const updateLivasClientInterviewDate = (eventType === EventEventType.ClientInterview);
    const updateLivasEmploymentDate = (eventType === EventEventType.Employment);
    const nextContactDate = this.getNextContactDate(eventType, startDatetime);
    if (this.doResolvePreviousEvents) {
      this.resolvePreviousEvents(this.data.stage, false);
    }

    if (!resolution && this.eventId) {
      // if resolution is null, we need to update status of event as unresolved
      this.eventStatusUpdate(this.eventId, resolution, false);
    }

    this.progressService.createUpdateEvent(
      this.data.events[this.data.stage].id,
      this.data.events.responseId,
      eventType,
      startDatetime,
      this.doResolvePreviousEvents ? livasStatusId : null,
      nextContactDate,
      updateLivasOfficeInterviewDate,
      updateLivasClientInterviewDate,
      updateLivasEmploymentDate
    ).subscribe(() => {
      if (nextContactDate || startDatetime) {
        window.parent.postMessage({
          contactDate: moment(nextContactDate || startDatetime).format('YYYY-MM-DD')
        },
          '*'
        );
      }

      if (autoclose) {
        this.dialog.close();
      }
    });
    if (updateOfferSigned) {
      this.progressService.setOfferPropositionSigned(this.data.events.responseId).subscribe();
    }

  }

  eventStatusUpdate(eventId, resolution, autoclose = true) {
    this.loading = true;
    this.progressService.changeEventStatus(
      eventId, resolution
    ).subscribe(next => {
      if (autoclose) {
          this.dialog.close();
      }
    });
  }

  employmentEventStatusUpdate(eventId, resolution, livasStatus, autoclose = true) {
    if (livasStatus) {
      this.progressService.updateLivasCandidateStatusByOfferProposition(
        livasStatus, btoa(`OfferProposition:${this.data.events.responseId}`)
      ).subscribe(() => {
        if (autoclose) {
          this.dialog.close();
        }
      });
    }
  }


  hasEventId(stage?: ProgressStages) {
    return this.eventId;
  }

  setInterviewInterest(interested) {
    this.progressService.updateCreateOfferProposition(
      this.data.events.responseId ? btoa('OfferPropositionType:' + this.data.events.responseId) : undefined,
      this.data.events.order,
      this.data.personId,
      interested
    ).subscribe(resp => {

      let offerId = this.data.events.responseId;

      if (! offerId) {
        const idStr = resp.data.offerPropositionCreate?.offerProposition?.id;
        offerId = idStr ? atob(idStr).split(':')[1] : null;
      }

      if (this.selectedCancellationReasons.length) {
        this.updateLivasStatusAndCancellationReasons(offerId);
      }

      if ((interested === this.interested.Interested) || this.selectedLivasStatus) {
        const newStatusId = interested === this.interested.Interested ? LivasStatuses.waitingDecision : this.selectedLivasStatus;
        if (newStatusId) {
          this.cancellationService.updateLivasStatus(btoa(`OfferProposition:${offerId}`), newStatusId).subscribe();
        }
      }

      const officeInterviewEvent = this.data.events['officeInterviewEvent'];
      if (officeInterviewEvent.id && (officeInterviewEvent.status !== 'SUCCESS')) {
        this.eventStatusUpdate(this.data.events['officeInterviewInvitationEvent'].id, this.resolutions.Complete);
      }

      this.dialog.close();
      }, err => {});
  }

  private getLivasStatus(stage: ProgressStages, resolution: EventResolutionStatus) {

    const stagesMap = {
      [ProgressStages.presentToClientEvent]: LivasStatuses.introducingToClient,
      [ProgressStages.trialDay]: LivasStatuses.trialDay,
      [ProgressStages.clientInterviewEvent]: LivasStatuses.clientInterview,
      [ProgressStages.healthcheckEvent]: (resolution === EventResolutionStatus.Complete) ? LivasStatuses.healthCheckCompleted :  LivasStatuses.healthCheckInProgress,
      [ProgressStages.employmentEvent]: LivasStatuses.healthCheckCompleted // if we decided to employ person, his currect status is "health check completed and waiting for employment
    };

    if (stage in stagesMap) {
      return stagesMap[stage];
    } else {
      return;
    }
  }

  checkNoshow(eventId: string) {
    this.showNextContactDate = true;

  }

  updateLivasNextContactDate(eventId, eventType) {
    this.updateLivasStatusAndCancellationReasons(this.data.events.responseId);
    this.progressService.changeEventStatus(eventId, this.resolutions.Failed).subscribe(
      re => {
        this.progressService.createUpdateEvent(
          eventId,
          this.data.events.responseId,
          eventType,
          undefined,
          undefined,
          moment(this.extraDate).format('YYYY-MM-DD')
        ).subscribe(re => this.dialog.close());
      }
    );

  }

  private getNextContactDate(eventType, startDatetime: string) {
    if ([EventEventType.ToBePresented, EventEventType.HealthCheckPending].indexOf(eventType) > -1) {
      return startDatetime.substr(0, 10);
    } else {
      return ;
    }
  }

  private resolvePreviousEvents(stage, autoclose) {
    let stagesToComplete;

    switch (stage) {
      case ProgressStages.employmentEvent: {
        stagesToComplete = [ProgressStages.healthcheckEvent, ProgressStages.clientInterviewEvent, ProgressStages.presentToClientEvent]
        break;
      }
      case ProgressStages.healthcheckEvent: {
        stagesToComplete = [ProgressStages.clientInterviewEvent, ProgressStages.presentToClientEvent];
        break;
      }
      case ProgressStages.clientInterviewEvent: {
        stagesToComplete = [ProgressStages.presentToClientEvent];
        break;
      }
      default: {
        stagesToComplete = [];
      }
    }
    stagesToComplete.map( stageName => {
      if (this.data.events[stageName]?.status === 'PENDING' ) {
        this.eventStatusUpdate( this.data.events[stageName].id, EventResolutionStatus.Complete, autoclose );
      }
    });

  }

  private updateLivasStatusAndCancellationReasons(offerId) {
    this.cancellationService
      .saveAssignments(offerId, this.selectedCancellationReasons)
      .subscribe();
    const globalId = this.relayService.getRelayId(offerId, 'OfferProposition')
    if (this.selectedLivasStatus) {
      this.cancellationService.updateLivasStatus(globalId, this.selectedLivasStatus).subscribe();
    }
  }

  onCancelationReasonsChange(ids: string[]) {
    this.selectedCancellationReasons = ids;
  }

  setEventNegativeOutcome(eventId, resolution, selectedCancellationAnswer: OfferPropositionInterested) {
    this.updateLivasStatusAndCancellationReasons(this.data.events.responseId);
    this.eventStatusUpdate(this.data.events[this.data.stage].id, resolution, true);
  }

}
