import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-unfilled-field-indicator',
  templateUrl: './unfilled-field-indicator.component.html',
  styleUrls: ['./unfilled-field-indicator.component.scss']
})
export class UnfilledFieldIndicatorComponent implements OnInit {

  constructor() { }

  @Input() unfilled

  ngOnInit() {
  }

}
