import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AbsencesDialogComponent } from '../absences-dialog/absences-dialog.component';
import { AbsencesService } from '../absences.service';

import moment from 'moment';

@Component({
  selector: 'app-absences-import',
  templateUrl: './absences-import.component.html',
  styleUrls: ['./absences-import.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbsencesImportComponent implements OnInit {
  isLoading = false;

  absenceForm: FormGroup;
  data = [];
  period = {};
  rows = [];

  userfile: any;
  absences: any;
  employer: any;
  employers = [];
  range = [];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialog: MatDialog,
    private absencesService: AbsencesService,
  ) {
    this.onReaderLoad = this.onReaderLoad.bind(this);
    this.getAbsenceRow = this.getAbsenceRow.bind(this);
    this.openAbsencesDialog = this.openAbsencesDialog.bind(this);
    this.updateLocalData = this.updateLocalData.bind(this);

    this.absencesService.getAbsences().subscribe(res => {
      this.absences = res;
    });

    this.absenceForm = this.fb.group({
      id: null,
      employeeId: 0,
      employerId: 0,
      from: null,
      to: null,
      min: null,
      max: null,
      unpaid: false,
      absence_type: '',
      reason_code: '',
      reason_text: '',
    });
  }

  setRange(startDate: any, endDate: any) {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, 'days');
    for (let i = 0; i <= diff; i++) {
      this.range[moment(startDate).add(i, 'days').format('YYYY-MM-DD')] = '';
    }
  }

  parseResults(response: any) {
    const from = response?.file_data?.dates?.min_date;
    const to = response?.file_data?.dates?.max_date;
    const employer = response?.file_data?.employer;

    this.period = {
      from,
      to,
    };

    if ( !from || !to ) {
      return;
    }

    if (employer) {
      this.employer = { label: employer.name, value: employer.system_id };
      this.employers = [this.employer];
    }

    this.absencesService.getRecords(Object.keys(response.results.rows), from, to).subscribe(res => {
      const existing = res?.results?.rows || {};
      const importing = response?.results?.rows || {};

      for (const employeeId in importing) {

        if (employeeId) {
          this.data.push({
            employeeId,
            name: `${response.results.rows[employeeId].employee.name} ${response.results.rows[employeeId].employee.surname}`,
            ...this.absencesService.getMergedData(existing[employeeId], response.results.rows[employeeId].calendar),
          });
        }
      }

      this.setRange( from, to );

      for (const indx in response.not_found_employees) {
        if (indx) {
          this.data.push({
            employeeId: null,
            name: `${response.not_found_employees[indx].name} ${response.not_found_employees[indx].surname}`,
            error: 'EMPLOYEE_NOT_FOUND',
            ...this.range,
          });
        }
      }

      this.rows = this.data;

      this.cdr.markForCheck();
    });
  }

  async onReaderLoad(data: any) {
    this.userfile = data;

    this.parseResults(await this.absencesService.parseAbsencesFile(data));
  }

  importAbsences() {
    this.isLoading = true;

    const absences = [];

    for (const row of this.data) {

      if (!row.employeeId) {
        continue;
      }

      for (const date in this.range) {
        if (!row[date]?.absences?.[this.employer.value]) {
          continue;
        }

        row[date].absences[this.employer.value].forEach(absence => {
            if (!absence.id) {
              absences.push({
                employee_id: row.employeeId,
                from: date,
                to: date,
                absence_type: absence.absence_type,
                unpaid: absence.unpaid === false ? false : true,
                shifts: absence.shifts || this.absencesService.getDefaultShifts(row[date].shifts[this.employer.value]),
                reason_code: absence.reason_code || null,
                reason_text: absence.reason_text || null
              });
            }
        });
      }
    }

    this.absencesService.logSodraFile(this.userfile);

    this.absencesService.sendAbsencesData(this.employer.value, absences).subscribe(res => {
      this.isLoading = false;

      if (res.status === 'success') {

        this.snackBar.open(this.translate.instant('ABSENCES_IMPORTED_SUCCESSFULLY'), this.translate.instant('CLOSE'), {
          duration: 10000,
        });
      } else if (res.status === 'error') {
        this.isLoading = false;

        const message = this.absencesService.getErrorMessage(res);

        this.snackBar.open(message, this.translate.instant('CLOSE'), {
          duration: 5000,
        });
      }

      this.data = [];
      this.range = [];

      this.period = {};
      this.rows = [];


      this.cdr.markForCheck();
    });
  }

  updateLocalData(action: string, form: any, periodAbsences?: Array<any>, shifts?: any) {
    const data = this.rows.find(r => r.employeeId.toString() === form.employeeId.toString());

    if (!data) {
      return;
    }

    const fromDate = moment(form.from);
    const toDate = moment(form.to);
    const diff = toDate.diff(fromDate, 'days');
    let date = '';

    for (let i = 0; i <= diff; i++) {
      date = moment(form.from).add(i, 'days').format('YYYY-MM-DD');

      if (action === 'delete') {
        data[date].absences[form.employerId] = data[date]?.existing?.absences?.[form.employerId] || [];
      } else {
        data[date].absences[form.employerId] = [{
          employee_id: form.employeeId,
          from: date,
          to: date,
          unpaid: periodAbsences[date].unpaid,
          absence_type: form.absence_type,
          reason_code: form.reason_code,
          reason_text: form.reason_text,
          shifts,
        }];
      }
    }

    this.cdr.markForCheck();
  }

  getAbsenceRow(employeeId: any) {
    return this.data.find(r => r.employeeId === employeeId);
  }

  openAbsencesDialog({ employeeId, employerId, date, absence }) {
    if (absence && absence.id && absence.is_fixed !== false) {
      return;
    }

    const row = this.getAbsenceRow(employeeId);
    const range = this.absencesService.getAbsencesRange(row, absence, date, employerId);

    const absenceForm  = this.fb.group({
      id: null,
      employeeId: parseInt(employeeId, 10),
      employerId: parseInt(employerId, 10),
      from: moment(range.from).format('YYYY-MM-DD'),
      to: moment(range.to).format('YYYY-MM-DD'),
      min: moment(range.from).format('YYYY-MM-DD'),
      max: moment(range.to).format('YYYY-MM-DD'),
      unpaid: absence.unpaid === false ? false : true,
      absence_type: absence.absence_type || '',
      reason_code: absence.reason_code || '',
      reason_text: absence.reason_text || '',
    });

    const dialog = this.dialog.open(AbsencesDialogComponent, {
      data: {
        title: this.translate.instant('MODIFY_IMPORTING_ABSENCE'),
        headline: row.name,
        absenceForm,
        absences: this.absences,
        employers: this.employers,
        records: row[date],
        isUpdatePreSave: true,
      },
      width: '1200px',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      if (res.event === 'create') {
        this.updateLocalData('update', res.form, res.periodAbsences, res.shifts);
      } else if (res.event === 'delete') {
        this.updateLocalData('delete', res.form);
      }
    });
  }

  ngOnInit(): void {}

}
