import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, merge, BehaviorSubject, interval, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { GetRequestActivitiesGQL, RequestsActivityType, RequestsActivityTypeConnection } from '../../generated/graphql';

export interface RequestActivitiesFilter {
  phone?: string;
  createdBy?: string;
}
/**
 * Data source for the OrderList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class RequestActivitiesDatasource extends DataSource<RequestsActivityType> {
  data = new BehaviorSubject<RequestsActivityType[]>([]);
  paginator: MatPaginator;
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  filters = new BehaviorSubject<RequestActivitiesFilter>({});
  loadingSubscription: Subscription;
  totalCount = 0;
  loading = new BehaviorSubject<boolean>(true);
  filterLangeCache: number;

  constructor(
    private getRequestActivitiesGQL: GetRequestActivitiesGQL
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<RequestsActivityType[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.filters.asObservable(),
      this.sort.sortChange,
      this.paginator.page
    ];

    merge(...dataMutations).pipe(debounce(() => interval(500))).subscribe(
      () => {
        this.loading.next(true);
        const orderBy = this.getOrderByParam(this.sort);
        let endCursor;

        if (this.filterLangeCache >= 0 && this.filterLangeCache !== Object.keys(this.filters.value).length) {
          endCursor = btoa(`arrayconnection:-1`);
          this.paginator.pageIndex = 0;
        } else {
          endCursor = btoa(`arrayconnection:${ (this.paginator.pageIndex) * this.paginator.pageSize - 1 }`);
        }

        this.filterLangeCache = Object.keys(this.filters.value).length;

        const queryParams = Object.assign(
          { }, { first: this.paginator.pageSize, after: endCursor }, this.filters.value, orderBy
        );

        if ( this.loadingSubscription ) {
          this.loadingSubscription?.unsubscribe?.();
        }

        this.loadingSubscription = this.getRequestActivitiesGQL.watch(
          queryParams, { fetchPolicy: 'cache-and-network' }
        ).valueChanges.subscribe(r => {
          if (r.data && !r.loading) {
            const activities = this.parseEdges(r.data.requestsActivities as RequestsActivityTypeConnection);

            this.data.next(activities);
            this.totalCount = r.data.requestsActivities.totalCount;
            this.endCursor = r.data.requestsActivities.pageInfo.endCursor;
            this.loading.next(false);
          }
        });
      }
    );
    return this.data.asObservable();
  }

  private parseEdges(input: RequestsActivityTypeConnection) {
    return input.edges.map(it => it.node);
  }

  private getOrderByParam(sort: MatSort) {
    let orderBy = { };

    if (sort.active && ['created_at', 'created_by', 'phone', 'reason'].indexOf(sort.active) > -1) {
      orderBy = {
        orderBy: (sort.direction === 'desc' ? '-' : '') + this.sort.active
      };
    }

    return orderBy;
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }
}
