<div  fxLayout="column" class="host">
  <div>
    {{valueFrom}}
    <div *ngIf="valueTo" style="display: inline-block;">
      - {{valueTo}}
    </div>
  </div>



  <div class="mat-hint" *ngIf="valueFrom"> {{(hourly ? 'EUR_HOURLY' : 'EUR_MONTHLY') | translate }}</div>
<!--  <div *ngIf="salary.monthlyBruttoFrom">-->
<!--    <span>From:</span> {{salary.monthlyBruttoFrom}}-->
<!--    <span>To:</span> {{salary.monthlyBruttoTo}}-->
<!--  </div>-->
<!--    <span *ngIf="order.hourRateBruttoPermanent">{{order.hourRateBruttoPermanent}}</span>-->
<!--    <span *ngIf="order.hourRateBruttoPermanent" class="long-term-label">eur/val</span>-->
</div>
