import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToolbarService } from '../navigation/toolbar.service';
import { UnseenOrdersService } from '../orders/unseen-orders.service';
import { SidenavService } from '../shared/sidenav.service';

const SUPPORTED_LOCATIONS = ['lt', 'lv', 'ee'];

@Component({
  selector: 'app-offer-proposition-widget',
  templateUrl: './offer-proposition-widget.component.html',
  styleUrls: ['./offer-proposition-widget.component.scss']
})
export class OfferPropositionWidgetComponent implements OnInit {

  personId: string;
  locale: string;

  // officeInterviewColumns = ['name', 'offerOnCallStatus', 'offer','interview','darbinimas', 'comment'];
  // callInterviewColumns = ['name', 'offerCall', 'client-interview', 'comment'];

  officeInterviewColumns = ['createdAt', 'name', 'progress', 'cancellationReasons', 'comment'];
  // callInterviewColumns = ['name', 'offerCall',  'comment'];


  columnsToDisplay = this.officeInterviewColumns;
  country = 'lt';
  constructor(
    private route: ActivatedRoute,
    private sidenav: SidenavService,
    private toolbarService: ToolbarService,
    private unseenOrdersService: UnseenOrdersService
  ) {
    this.toolbarService.hide();
  }

  ngOnInit() {
    this.unseenOrdersService.stopInterval();
    this.route.queryParams.subscribe((v) => {
      if (v.country && SUPPORTED_LOCATIONS.indexOf(v.country)) {
        this.country = v.country;
      }

      if (v.locale) {
        this.locale = v.locale;
      }
    });
    this.route.params.subscribe((v) => {
      this.personId = v.personId;
    });
    this.sidenav.close();
  }

  close() {}

}
