<mat-table [dataSource]='candidates' class='mat-elevation-z8'>
  <!-- Full Name Column -->
  <ng-container matColumnDef='fullName'>
    <mat-header-cell *matHeaderCellDef>{{ 'FULL_NAME' | translate }}</mat-header-cell>
    <mat-cell *matCellDef='let candidate'>
      <a mat-icon-button [href]='getLivasCandidateUrl(candidate.livasId)'>
        <mat-icon inline>open_in_new</mat-icon>
      </a>
      {{ candidate.fullName }}</mat-cell>
  </ng-container>

  <!-- Work Experience Column -->
  <ng-container matColumnDef='workExperience'>
    <mat-header-cell *matHeaderCellDef>{{ 'WORK_EXPERIENCE' | translate }}</mat-header-cell>
    <mat-cell *matCellDef='let candidate'>{{ candidate.workExperience || '' }}</mat-cell>
  </ng-container>

  <!-- Requirement Columns -->
  <ng-container *ngFor='let requirement of requirements; let i = index' [matColumnDef]="'requirement' + i">
    <mat-header-cell *matHeaderCellDef>{{ requirement.requirement }}</mat-header-cell>
    <mat-cell *matCellDef='let candidate'>{{ candidate.comments[requirement.id] || '' }}</mat-cell>
  </ng-container>

  <!-- Additional Info Column -->
  <ng-container matColumnDef='additionalInfo'>
    <mat-header-cell *matHeaderCellDef><strong>{{ 'ADDITIONAL_INFO_ABOUT_CANDIDATE' | translate }}</strong>
    </mat-header-cell>
    <mat-cell *matCellDef='let candidate'>{{ candidate.additionalInfo || '' }}</mat-cell>
  </ng-container>

  <!-- Starts Working Column -->
  <ng-container matColumnDef='startsWorking'>
    <mat-header-cell *matHeaderCellDef><strong>{{ 'STARTS_WORKING' | translate }}</strong>
    </mat-header-cell>
    <mat-cell *matCellDef='let candidate'>{{ candidate.startsWorking || '' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef='actions'>
    <mat-header-cell *matHeaderCellDef><strong></strong>
    </mat-header-cell>
    <mat-cell *matCellDef='let candidate'>
      <button mat-icon-button (click)='copyToClipboard(candidate, requirements)'>
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
  <mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
</mat-table>
