<mat-card class="mat-typography">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="16px">
      <app-company-selector fxFlex="200px"
                            [companies]="companies"
                            (companyChange)="onCompanyChange($event)"
                            (companyAdded)="onCompanyAdd($event)"
                            [disabled]="newSpecification">
      </app-company-selector>
      <mat-form-field fxFlex="500px" *ngIf="selectedCompanyId">
        <mat-label>{{ 'SPECIFICATION_NAME' | translate }}</mat-label>
        <input matInput [(ngModel)]="newSpecificationName" [disabled]="newSpecification">
        <mat-hint>{{ 'SPECIFICATION.HINT' | translate }}</mat-hint>
      </mat-form-field>
      <button mat-stroked-button
              *ngIf="selectedCompanyId && !newSpecification"
              [disabled]="!newSpecificationName"
              (click)="createSpecification()">
        {{ 'COMMON.CREATE' | translate }}
      </button>
    </div>
    <app-specification-edit [specificationId]="newSpecification.id"
                            *ngIf="newSpecification" [isNew]="true"></app-specification-edit>

    <button mat-stroked-button
            *ngIf="newSpecification"
            (click)="updateSpecification()"
            class="save-button">
            {{ 'COMMON.SAVE' | translate }}
    </button>
  </mat-card-content>
</mat-card>
