import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Tag } from 'src/app/specification/tag-assignment-table/tag';
import { TagGroupsGQL } from 'src/generated/graphql';
import { TagGroupKey } from '../types/order-types';

@Component({
  selector: 'app-text-input-with-hints',
  templateUrl: './text-input-with-hints.component.html',
  styleUrls: ['./text-input-with-hints.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputWithHintsComponent),
      multi: true
    }
  ]
})
export class TextInputWithHintsComponent implements OnInit, ControlValueAccessor {

  tags: Tag[] = [];

  $tagGroup: TagGroupKey;
  $showHints = true;

  @Input() selectedCountry;

  @Input() hintAsPlaceholder: boolean;
  @Input() readonly: boolean;

  @Input() set showHints(value: boolean) {
    this.$showHints = value;
  }
  get showHints() {return this.$showHints; }

  @Input() set tagGroup(value: TagGroupKey) {
    this.$tagGroup = value;
    if (this.selectedCountry) {
      this.tagGroupsGQL.fetch({country: this.selectedCountry?.id}).subscribe(res => {
        const tagGroups = res.data.tagsGroups
          .filter(e => e.key === this.tagGroup);

        this.tags = tagGroups.length ? tagGroups[0].tagSet : [];

        if (this.hintAsPlaceholder) {
          this.value = this.tags.map(it => it.title).join('\n');
        }
      });
    }
  }
  get tagGroup() { return this.$tagGroup; }

  selectedTag = {};
  disabled = false;

  onChange: any = () => {};
  onTouch: any = () => {};
  val = '';
  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(){
    return this.val;
  }

  constructor(private tagGroupsGQL: TagGroupsGQL) {}

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
