import { Component, Input, OnChanges } from '@angular/core';
import {FormControl} from '@angular/forms';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

import { ActivatedRoute, Params } from '@angular/router';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY MMMM',
    monthYearLabel: 'YYYY MMMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthPickerComponent implements OnChanges  {
  formControl = new FormControl(new Date());

  @Input() date: any;
  @Input() updateDate: any;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const locale = params.locale === 'ee' ? 'et' : params.locale;

      if (locale) {
        this.dateAdapter.setLocale(locale);
      }
    });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = new FormControl(moment(this.formControl.value)).value;
    ctrlValue.year(normalizedYear.year());
    this.updateDate('date', ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = new FormControl(moment(this.formControl.value)).value;
    ctrlValue.month(normalizedMonth.month());
    this.updateDate('date', ctrlValue);
    datepicker.close();
  }

  ngOnChanges(): void {
    this.formControl.setValue(this.date);
  }
}


