import {Injectable} from '@angular/core';
import {Mutation, Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Requirement, Specification, SpecificationResponse} from '../../graphql/graphql';
import {TagGroup} from '../tag-assignment-table/tag';
import {PersonalProtectiveEquipment} from '../ppe-issuance-table/ppe';

export interface SpecificationEditResponse {
  specification: Specification;
  tagsGroups: TagGroup[];
  personalProtectiveEquipment: PersonalProtectiveEquipment[];

}
export interface NewSpecificationResponse {
  tagsGroups: TagGroup[];
  personalProtectiveEquipment: PersonalProtectiveEquipment[];

}

const SpecificationEdit = {
  fragments: {
    ppe: gql`
      fragment PPEFields on PersonalProtectiveEquipmentType {
        id
        title
      }
    `,
    tags: gql `
      fragment TagFields on TagType {
        id
        title
        hint
        group {
          key
        }
      }
    `,
    specification: gql`
      fragment SpecificationFields on SpecificationType  {
        id
        positionTitle
        workFunctions
        address
        intensity
        onboardingDuration
        male
        female
        ageFrom
        ageTo
        competences {
          edges {
            node {
              id
              title
              color
            }
          }
        }
        certificates {
          id
          title
        }
        riskFactors {
          id
          title,
          isDefault
        }
        mandatoryRequirements {
          id
          requirement
        }
        optionalRequirements {
          id
          requirement
        }
        allFunctionsFromFirstDay
        isWorkplaceVideoAllowed
        isWorkplaceVisitAllowed
        isLogoPublicAllowed
        isCompanyModern
        isCompanyInternational
        shiftDuration
        scheduleTypes {
          id
          title
        }
        breakDuration
        scheduleType
        shiftPattern
        scheduleText
        minExperienceMonths
        experienceType
        isSecurityPostPresent
        isMagneticKeyIssued
        whoIssuesMagneticKey
        tagassignmentSet {
          id
          tag {
            id
            group {
              id
              key
            }
          }
          comment
        }
        personalProtectiveEquipmentBiuro {
          id
        }
        personalProtectiveEquipmentClient {
          id
        }
      }`,
  }
};

@Injectable({
  providedIn: 'root',
})
export class SpecificationEditGQL extends Query<SpecificationEditResponse> {
  document = gql`
    query specifications($id: Int!) {
      specification(id: $id) {
        ...SpecificationFields
      }
      tagsGroups {
        key
        tagSet {
          ...TagFields
        }
      }
      personalProtectiveEquipment {
        ...PPEFields
      }
    }
    ${SpecificationEdit.fragments.specification}
    ${SpecificationEdit.fragments.tags}
    ${SpecificationEdit.fragments.ppe}
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DataForNewSpecificationGQL extends Query<NewSpecificationResponse> {
  document = gql`
    query getTagsGroups($country: ID) {
      tagsGroups(country: $country) {
        key
        tagSet {
          ...TagFields
        }
      }
      personalProtectiveEquipment {
        ...PPEFields
      }
    }
    ${SpecificationEdit.fragments.tags}
    ${SpecificationEdit.fragments.ppe}
  `;
}



@Injectable({
  providedIn: 'root'
})
export class CreateRequirementGQL extends Mutation<Requirement> {
  document = gql`
    mutation createRequirement($input: String!) {
      createRequirement(input: $input) {
        requirement {
          id
          requirement
        }
      }
    }
  `;
}
