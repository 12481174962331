export const environment = {
  production: true,
  oauth_id: 'DFCE1imiTddXQvFfIVf0D5wuhNeVq8DUSlBQWs23',
  oauth_secret: 'mBxiABY2mZmIUbn8Ds2qNzrZJP0OjZ5N3AmNIUXjNixmDeeS10msbHmZkj4lhoqQqgs6psyasbRdd4ffokAv09otKaWXjoXagBjpGMWpwWznEix3y8hnQzeMMMkK4j5B',
  url: 'https://boris-api.workis.lt',
  livasAPIBase: 'https://livas.biuro.lt',
  livasAPIBaseLT: 'https://livas.biuro.lt/',
  livasAPIBaseLV: 'https://livas.biuro.lv/',
  livasAPIBaseEE: 'https://livas.biuro.ee/',
  livasAPIBaseLTV3: 'https://livas.biuro.lt/lt/jobs/api/v3',
  livasAPIBaseLVV3: 'https://livas.biuro.lv/lv/jobs/api/v3',
  livasAPIBaseEEV3: 'https://livas.biuro.ee/ee/jobs/api/v3',
  livasAPIBaseGiGroupV3: 'https://livas.biuro.lt/gi-group/jobs/api/v3',
  edlaAPIBase: 'https://livas.biuro.lt',
  env: 'prod'
};
