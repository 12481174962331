<div fxFill fxLayout="column" class="component">
    <div *ngIf="isLoading" class="component-loader">
        <mat-spinner diameter="36" ></mat-spinner>
    </div>

    <div [ngClass]="{ 'is-loading': isLoading }" class="component-content">
        <h3>{{ headline | translate }}</h3>

        <ng-content></ng-content>
    </div>
</div>
