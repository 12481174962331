import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

import { ExcelWidgetComponent } from './excel-widget.component';
import { SharedModule } from '../shared/shared.module';
import { ExcelWidgetRoutingModule } from './excel-widget-routing.module';
import { ShiftParamsDialogComponent } from './shift-params-dialog/shift-params-dialog.component';

@NgModule({
  declarations: [
    ExcelWidgetComponent,
    ShiftParamsDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ExcelWidgetRoutingModule,
    NgxUploaderModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    ScrollingModule,
    TableVirtualScrollModule,
    MatSnackBarModule,
    TranslateModule
  ],
  exports: [
    ExcelWidgetComponent
  ]
})
export class ExcelWidgetModule { }
