import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface AgeRestriction {
  from: number;
  to: number;
}

@Component({
  selector: 'app-age-restriction',
  templateUrl: './age-restriction.component.html',
  styleUrls: ['./age-restriction.component.scss']
})
export class AgeRestrictionComponent implements OnInit {

  @Input() ageFrom;
  @Input() ageTo;
  @Input() appearance;
  @Output() restrictionChange = new EventEmitter<AgeRestriction>();
  restriction: AgeRestriction = {from: 0, to: 100};

  constructor() { }

  ngOnInit() {
    this.restriction.from = this.ageFrom;
    this.restriction.to = this.ageTo;
  }

  onAgeRestrictionChanges() {
    this.restriction.from = this.ageFrom;
    this.restriction.to = this.ageTo;
    this.restrictionChange.emit(this.restriction);
  }
  //
  // addRestriction() {
  //   if (this.ageRestriction.length === 0) {
  //     this.ageRestriction.push({type: '0', value: null});
  //   } else {
  //     this.ageRestriction[0].type === '0' ?
  //     this.ageRestriction.push({type: '1', value: null}) : this.ageRestriction.push({type: '0', value: null});
  //   }
  // }
  //
  // removeRestriction(item: AgeRestriction) {
  //   this.ageRestriction = this.ageRestriction.filter(e => e !== item);
  //   this.restrictionChange.emit(this.ageRestriction);
  // }
  //
  // restrictionDisabled(type: '0' | '1') {
  //   return this.ageRestriction.filter(e => e.type === type)[0];
  // }
  //
  // onInput(item: AgeRestriction) {
  //   if (!item.value) {
  //     this.restrictionChange.emit(this.ageRestriction.filter(e => e !== item));
  //   } else {
  //     this.restrictionChange.emit(this.ageRestriction);
  //   }
  // }
  //

}
