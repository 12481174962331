import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { OrderActionsLogService } from '../../../orders/order-actions-log/order-actions-log.service';

@Component({
  selector: 'app-filter-category-select',
  templateUrl: './filter-category-select.component.html',
  styleUrls: ['./filter-category-select.component.scss']
})
export class FilterCategorySelectComponent implements OnInit {
  categories;

  selection = { allSelections: false, allOperations: false };

  @Input() filterBarView: boolean;
  @Input() categoryCtrl: FormControl;

  @Output() clearFilter = new EventEmitter<void>();

  constructor(
    private actionLogService: OrderActionsLogService
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.actionLogService.getSACategoriesGroupsList().subscribe(
      v => {
        this.categories = v.data?.selectionActionCategoryGroups.edges.map(it => {
          return {
            id: it.node.id,
            title: it.node.title,
            department: it.node.department,
            children: it.node.sacategorySet.edges.map(it2 => {
              return {
                id: it2.node.id,
                title: it2.node.title,
                durationMinutes: it2.node.durationMinutes
              };
            })
          };
        }) || [];
      }
    );
  }

  onGroupSelectionChanged() {
    const currentSelected = [];
    if (this.selection.allSelections) {
      const selected = this.categories.filter(category => category.department === 'SELECTIONS');
      selected.forEach(group => currentSelected.push(...group.children.map(category => category.id)));
    }

    if (this.selection.allOperations) {
      const selected = this.categories.filter(category => category.department === 'OPERATIONS');
      selected.forEach(group => currentSelected.push(...group.children.map(category => category.id)));
    }

    this.categoryCtrl.setValue(currentSelected);
  }
}
