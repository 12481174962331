import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Requirement} from '../../graphql/graphql';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';

import {map, startWith, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AddRequirementDialogComponent} from '../add-requirement-dialog/add-requirement-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { OrderCreateService } from 'src/app/order/order-create/order-create.service';
import { RequirementsGQL } from 'src/generated/graphql';

@Component({
  selector: 'app-candidate-requirements',
  templateUrl: './candidate-requirements.component.html',
  styleUrls: ['./candidate-requirements.component.scss']
})
export class CandidateRequirementsComponent implements OnInit, OnDestroy {


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  requirementCtrl = new FormControl();
  filteredRequirements = new BehaviorSubject<Requirement[]>([]);
  requirements$: Requirement[] = [];

  @Input() set requirements(val: Requirement[]){
    if (val) {
      this.requirements$ = val;
      this.emit();
    }
  }
  get requirements(){
    return this.requirements$;
  }


  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() title: string;
  @Input() readMoreCount: number;
  @Input() readonly = false;
  @Input() initialize: Requirement[];

  protected _onDestroy = new Subject<void>();
  selectedCountry$: string;
  selectedLang$: string;
  @Input() set selectedCountry(val) {
    this.selectedCountry$ = val;
    if (val && this.selectedLang$) {
      this.fetchRequirements();
    }
  }

  @Input() set selectedLang(val) {
    this.selectedLang$ = val;
    if (val && this.selectedCountry$) {
      this.fetchRequirements();
    }
  }

  @Output() selected = new EventEmitter<Requirement[]>();

  constructor(
    private requirementsGQL: RequirementsGQL,
    private dialog: MatDialog
  ) {
    // this.filteredRequirements = this.requirementCtrl.valueChanges.pipe(
    //   map( v => this.allFruits)
    // )




        // map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  fetchRequirements() {
    this.requirementCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(
      value => {
        this.requirementsGQL.fetch({requirement: value, language: this.selectedLang$, country: this.selectedCountry$}, {fetchPolicy: 'no-cache'}).subscribe(
          resp => this.filteredRequirements.next(resp.data.requirements)
        );
      }
    );
  }


  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.requirements.push();
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.requirementCtrl.setValue(null);
    }
  }

  remove(req: Requirement): void {
    const index = this.requirements.indexOf(req);

    if (index >= 0) {
      this.requirements.splice(index, 1);
      this.emit();
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent): void {

    this.requirements.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.requirementCtrl.setValue(null);
    this.emit();
  }

  ngOnInit() {
    if (this.selectedCountry$ == undefined && this.selectedLang$ == undefined) {
      this.requirementCtrl.valueChanges.subscribe(
        value => {
          this.requirementsGQL.fetch({requirement: value})
          .pipe(takeUntil(this._onDestroy))
          .subscribe(
            resp => this.filteredRequirements.next(resp.data.requirements)
          );
        }
      );
    }
    this.requirements = this.initialize;
  }



  private emit() {
    this.selected.emit(this.requirements);
  }

  addRequirement(event) {
    event.stopPropagation();
    this.dialog.open(AddRequirementDialogComponent, {
      minWidth: '450px',
      height: '80%',
      data: {
        selectedCountry: this.selectedCountry$,
        selectedLang: this.selectedLang$
      }
    });
  }
}
