import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget/widget.component';
import {InterviewWidgetRoutingModule} from './interview-widget-routing.module';
import { OffersTableComponent } from './offers-table/offers-table.component';
import {AppMaterialModule} from '../app-material/app-material.module';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import {SharedModule} from '../shared/shared.module';
import { ScheduleEventDialogComponent } from './schedule-event-dialog/schedule-event-dialog.component';
import { CancellationReasonDialogComponent } from './cancellation-reason-dialog/cancellation-reason-dialog.component';
import {MatTreeModule} from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressComponentComponent } from './offers-table/progress-component/progress-component.component';
import { ProgressBarSegmentComponent } from './offers-table/progress-component/progress-bar-segment/progress-bar-segment.component';
import { ProgressActionsComponent } from './offers-table/progress-actions/progress-actions.component';
import { ProgressActionDialogComponent } from './offers-table/progress-action-dialog/progress-action-dialog.component';
import { CanellationReasonSelectComponent } from './canellation-reason-select/canellation-reason-select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  CandidateEvaluationsDialogComponent
} from './candidate-evaluations-dialog/candidate-evaluations-dialog.component';
import { CandidateEvaluationsBtnComponent } from './candidate-evaluations-btn/candidate-evaluations-btn.component';
import {
  CancellationReasonsIconWithTooltipComponent
} from './cancellation-reasons-icon-with-tooltip/cancellation-reasons-icon-with-tooltip.component';

@NgModule({
  declarations: [
    CommentDialogComponent,
    WidgetComponent,
    OffersTableComponent,
    ScheduleEventDialogComponent,
    CancellationReasonDialogComponent,
    ProgressComponentComponent,
    ProgressBarSegmentComponent,
    ProgressActionsComponent,
    ProgressActionDialogComponent,
    CanellationReasonSelectComponent,
    CandidateEvaluationsDialogComponent,
    CandidateEvaluationsBtnComponent,
    CancellationReasonsIconWithTooltipComponent
  ],
  imports: [
    CommonModule,
    InterviewWidgetRoutingModule,
    AppMaterialModule,
    SharedModule,
    MatTreeModule,
    TranslateModule,
    MatSlideToggleModule
  ],
  exports: [
    ScheduleEventDialogComponent,
    CancellationReasonDialogComponent,
    ProgressComponentComponent,
    ProgressBarSegmentComponent,
    ProgressActionsComponent,
    ProgressActionDialogComponent,
    CanellationReasonSelectComponent,
    CandidateEvaluationsDialogComponent,
    CandidateEvaluationsBtnComponent,
    CancellationReasonsIconWithTooltipComponent
  ]
})
export class InterviewWidgetModule { }
