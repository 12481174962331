import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { PPEIssuanceDataSource } from './ppe-issuance-table-datasource';

import {SelectionModel} from '@angular/cdk/collections';
import {PersonalProtectiveEquipment} from './ppe';

export interface PPESelectionEvent {
  agency: PersonalProtectiveEquipment[];
  client: PersonalProtectiveEquipment[];
}


@Component({
  selector: 'app-ppe-issuance-table',
  templateUrl: './ppe-issuance-table.component.html',
  styleUrls: ['./ppe-issuance-table.component.scss']
})
export class PPEIssuanceTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<PersonalProtectiveEquipment>;

  preselectedClient: string[] = [];
  preselectedAgency: string[] = [];

  dataSource = new PPEIssuanceDataSource();
  private assignedDict$: {[id: string]: string} = {};
  selectionAgency = new SelectionModel<PersonalProtectiveEquipment>(true, []);
  selectionClient = new SelectionModel<PersonalProtectiveEquipment>(true, []);


  @Output() selected = new EventEmitter<PPESelectionEvent>();

  @Input()
  set ppes(value: PersonalProtectiveEquipment[]) {
    if (value) {
      this.dataSource.data.next(value);
    }
    this.updateSelection();
  }

  @Input()
  set issuedAgency(value: string[]) {
    this.preselectedAgency = value;
    this.updateSelection();
  }

  @Input()
  set issuedClient(value: string[]) {
    this.preselectedClient = value;
    this.updateSelection();
  }

  @Input() disabled;

  displayedColumns = ['name', 'selectAgency', 'selectClient'];

  private updateSelection() {
    this.selectionAgency.clear();
    this.selectionClient.clear();

    this.dataSource.data.value.forEach(ppe => {
      // this.selectionAgency.select()
      if (this.preselectedAgency.indexOf(ppe.id) > -1 ) {
        this.selectionAgency.select(ppe);
      }

      if (this.preselectedClient.indexOf(ppe.id) > -1 ) {
        this.selectionClient.select(ppe);
      }
    });
    this.emitSelection();
  }




  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */


  constructor() {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.table.dataSource = this.dataSource;
  }

  isAllSelected() {
    // const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.value.length;
    // return numSelected === numRows;
    return false;
  }

  /** Selects all rows if they are not all onSelect; otherwise clear selection. */

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    // if (!row) {
    //   return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    // }
    // return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    return '';
  }




  toggle(row: PersonalProtectiveEquipment,
         primarySelection: SelectionModel<PersonalProtectiveEquipment>,
         secondarySelection: SelectionModel<PersonalProtectiveEquipment>) {
    primarySelection.toggle(row);
    if (primarySelection.isSelected(row) && secondarySelection.isSelected(row)) {
      secondarySelection.deselect(row);
    }

    this.emitSelection();

    return null;
  }

  private emitSelection() {
    this.selected.emit({
      agency: this.selectionAgency.selected,
      client: this.selectionClient.selected
    });
  }
}
