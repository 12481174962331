import { Injectable } from '@angular/core';
import { OrderLanguageEnum } from 'src/generated/graphql';

const MMA = 642;
const GPM = 0.2;
const PSD = 0.0698;
const VSD = 0.1252;

const LIMIT_FULL_NPD_APPLICATION_LT = 924;
const LIMIT_NPD_APPLICATION_LT = MMA;
const AVERAGE_MONTHLY_SALARY_LT = 2167;
const FULL_NPD_LT = 747;
const BASE_NPD_FOR_HIGH_SALARY_LT = 400;
const COEF_LOW_SALARY_LT = 0.50;
const COEF_HIGH_SALARY_LT = 0.18;

@Injectable({
  providedIn: 'root'
})
export class SalaryCalculatorService {

  static countryISO = '';



  private MAX_NETTO_WITH_NPD = this.getMonthlyNetto((400 / 0.18) + MMA);

  constructor() { }


  getNPD(monthlyIncome) {
    return 400 - 0.18 * (monthlyIncome - MMA);
  }

  /** Netto from Brutto */
  getMonthlyNetto(monthlyBrutto) {
    // return monthlyBrutto - (monthlyBrutto - this.getNPD(monthlyBrutto)) * GPM - monthlyBrutto * (PSD + VSD);
    // NOTE: 0.18 and 400 from forumla in NPD
    let brutto = 0;
    switch (SalaryCalculatorService.countryISO) {
      case 'lt':
        brutto = this.getMonthlyNettoForLT(monthlyBrutto);
        break;

      case 'lv':
        brutto = this.getMonthlyNettoForLV(monthlyBrutto);
        break;

      case 'ee':
        brutto = this.getMonthlyNettoForEE(monthlyBrutto);
        break;

      default:
        brutto = this.getMonthlyNettoForLT(monthlyBrutto);
        break;
    }
    return brutto;
  }

  getMonthlyNettoForLT(monthlyBrutto) {
    return monthlyBrutto - (monthlyBrutto - this.getNPDLT(monthlyBrutto)) * GPM  - monthlyBrutto * (PSD + VSD);
  }

  getMonthlyNettoForLV(monthlyBrutto) {
    return monthlyBrutto - (monthlyBrutto * 10.5 / 100) - ((monthlyBrutto - (monthlyBrutto * 10.5 / 100)) * 20 / 100);
  }

  getMonthlyNettoForEE(monthlyBrutto) {
    if (monthlyBrutto <= 1200) {
      return monthlyBrutto - ((monthlyBrutto - 500) * 20 / 100) - (monthlyBrutto * 1.6 / 100);
    } else if (monthlyBrutto >= 1201 && monthlyBrutto <= 2099) {
      return monthlyBrutto - ((monthlyBrutto - (500 - 0.55556 * (monthlyBrutto - 1200))) * 20 / 100) - (monthlyBrutto * 1.6 / 100);
    } else {
      return monthlyBrutto - (monthlyBrutto * 20 / 100) - (monthlyBrutto * 1.6 / 100);
    }
  }

  /** Netto from Brutto ends here */



  /** Brutto from Netto */
  getMonthlyBruttoFromNetto(monthlyNetto) {

    let netto = 0;

    switch (SalaryCalculatorService.countryISO) {
      case 'lt':
        netto = this.getMonthlyBruttoFromNettoForLT(monthlyNetto);
        break;

      case 'lv':
        netto = this.getMonthlyBruttoFromNettoForLV(monthlyNetto);
        break;

      case 'ee':
        netto = this.getMonthlyBruttoFromNettoForEE(monthlyNetto);
        break;

      default:
        netto = this.getMonthlyBruttoFromNettoForLT(monthlyNetto);
        break;
    }
    return netto;
  }

  getMonthlyBruttoFromNettoForLT(monthlyNetto) {
    /*
    * (Netto - NPD x Tax) / (1- Tax) = Brutto
      Psd= 6.98%
      Vsd 12.52%
      Gpm = 20%

      NPD  - 625 iki  Brutto < 840
      NPD = 625 - 0.42 x (Brutto - 840), kai brutto < 1926
       NPD = 400 - 0.18 x (Brutto - 642), kai brutto < 1926

    *
    *
    *
    * */

    const NPD_STEP_1 = FULL_NPD_LT;
    const NPD_STEP_2 = BASE_NPD_FOR_HIGH_SALARY_LT;
    const NPD_COEF_1 = COEF_LOW_SALARY_LT;
    const NPD_COEF_2 = COEF_HIGH_SALARY_LT;
    const NPD_DETR_1 = LIMIT_FULL_NPD_APPLICATION_LT;
    const NPD_DETR_2 = LIMIT_NPD_APPLICATION_LT;
    const TAX = GPM + PSD + VSD;
    let brutto = 0;

    if (monthlyNetto <= 549.65) {
      // return (monthlyNetto - 92) / (1 - 0.195 - 0.2);
      brutto = (monthlyNetto - GPM * NPD_STEP_1) / (1 - TAX);
    } else if (monthlyNetto <= 1072.69) {
      //Brutto = (Netto - GPM(S1 + C1 * D1)) / (1 - C1 * GPM - Tax)
      brutto = (monthlyNetto - GPM * (NPD_STEP_1 +  NPD_COEF_1 * NPD_DETR_1)) / (1 - TAX - NPD_COEF_1 * GPM);
    } else if (monthlyNetto <= 1733.32) {
      brutto = (monthlyNetto - GPM * (NPD_STEP_2 +  NPD_COEF_2 * NPD_DETR_2)) / (1 - TAX - NPD_COEF_2 * GPM);
    } else {
      brutto = monthlyNetto / ( 1 - TAX);
    }

    return brutto;
  }

  getMonthlyBruttoFromNettoForLV(monthlyNetto) {
    return monthlyNetto / (1 - 0.105 - 0.179);
  }

  getMonthlyBruttoFromNettoForEE(monthlyNetto) {
    if (monthlyNetto <= 1200) {
      return (monthlyNetto - 100) / (1-0.016-0.2);
    } else if(monthlyNetto >= 1201 && monthlyNetto <= 2099) {
      return (monthlyNetto - 233.3344) / (1 - (1.55556 * 0.2) - 0.016);
    } else {
      return monthlyNetto / (1 - 0.016 - 0.2);
    }
  }

  /** Brutto from Netto ends here */
  private getNPDLT(monthlyBrutto) {
    if (monthlyBrutto <= LIMIT_FULL_NPD_APPLICATION_LT) {
      return FULL_NPD_LT;
    } else if (monthlyBrutto <= AVERAGE_MONTHLY_SALARY_LT) {
      return FULL_NPD_LT - COEF_LOW_SALARY_LT * (monthlyBrutto - LIMIT_FULL_NPD_APPLICATION_LT);
    } else {
      return Math.max(BASE_NPD_FOR_HIGH_SALARY_LT - COEF_HIGH_SALARY_LT * (monthlyBrutto - MMA), 0);
    }

  }
}
