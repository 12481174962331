import { Component, Input,  } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-planned-calls-for-today',
  templateUrl: './planned-calls-for-today.component.html',
  styleUrls: ['./planned-calls-for-today.component.scss']
})
export class PlannedCallsForTodayComponent {
  @Input() hint: string;
  @Input() label: string;
  @Input() valueController: FormGroup;
}
