
export enum TagGroupKey {
  workplaceBenefits = 'group1',
  workplaceInto = 'group2',
  transportInfo = 'transport',
  provision = 'provision'
}
export enum OrderTypeEnum {
  selection = 'SELECTION',
  staffing = 'STAFFING',
  partialSelection = 'PARTIAL_SELECTIONS'
}
