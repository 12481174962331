<mat-form-field appearance="outline" fxFlex="100">
  <mat-label>{{'COPY_FROM_ORDER' | translate}}</mat-label>
  <mat-select (selectionChange)="selectionChange($event.value)" (ngModelChange)="onOrderChange()"
    [formControl]="orderCtrl">
    <input matInput placeholder="{{'SEARCH_ORDER'| translate}}" value="" class="search-order" (input)="updateOrderSearch($event.target.value)">
    <mat-option [value]="'CREATE_BLANK_ORDER'" style="border-bottom: 1px solid">
      <mat-icon inline="true" style="opacity: 80%">add</mat-icon>
      {{'CREATE_BLANK_ORDER'| translate}}
    </mat-option>
    <mat-option *ngFor="let order of filteredOrders" [value]="order.id">
      {{ getOrderId(order?.id) }}: {{ order?.specification?.positionTitle | json }} ({{order.createdDate | date:'yyyy/MM/dd HH:mm'}})
    </mat-option>
  </mat-select>

</mat-form-field>
