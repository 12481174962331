<div class='wrapper' fxLayout (click)='openReasonDialog()'>
  <div *ngIf='reason.multiplier > 1' class='multiplier-pill'>{{ reason.multiplier }}</div>

  <div class='reason-title' [matTooltip]='reason.metricClientReason?.title'>{{ reason.metricClientReason?.title || '---' }}</div>

  <div *ngIf='reason.reasonGroup.length' class='comment'>
    <mat-icon
      [matTooltip]='getFullComment(reason.reasonGroup)'
      #tooltip='matTooltip' (click)='tooltip.toggle()'
    >comment</mat-icon>
  </div>
</div>
