import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyDetailGQL, CompanyDetailInterface} from '../../graphql/company-detail-gql';
import {COMPANY_COLORS, UpdateCompanyDescriptionGQL} from '../../graphql/graphql';
import {ColorChangeLogDialogComponent} from './color-change-log-dialog/color-change-log-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {AssignCompanyLabelsDialogComponent} from '../assign-company-labels-dialog/assign-company-labels-dialog.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {

  private companyId: string;
  private realyCompanyId: string;
  company: CompanyDetailInterface;

  companyColors = COMPANY_COLORS;
  ngOnDestroy$ = new Subject<void>();
  descriptionChange$ = new Subject<string>();
  constructor(
    private companyGQL: CompanyDetailGQL,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private updateCompanyDescriptionGQL: UpdateCompanyDescriptionGQL,
  ) { }

  ngOnInit() {
    const companyId = this.route.snapshot.paramMap.get('id');
    this.realyCompanyId = companyId;
    this.companyId = btoa(atob(companyId).replace('CompanyType', 'CompanyModelType'));

    this.companyGQL.watch({id: this.companyId}).valueChanges.subscribe(
      resp => {
        if (!resp.loading) {
          this.company = JSON.parse(JSON.stringify(resp.data.company));
        }
      }
    );

    this.descriptionChange$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe((newDescription) => {
        this.updateCompanyDescription(newDescription);
      });
  }

  private parseId(id: string) {

    return atob(id).split(':')[1];
  }

  openColorHistory($event: MouseEvent) {
    this.dialog.open(ColorChangeLogDialogComponent, {
      data: {historyChange: this.company.historyChanges}
    });
  }

  getCovidTooltip(company: CompanyDetailInterface) {
    if (company?.labels?.edges?.length > 0) {
      const assignedLabels = company.labels.edges.map(it => it.node.title).join(', ');
      return `Assigned labels: ${assignedLabels}`;
    } else {
      return 'No Labels assigned';
    }
  }

  changeLabels() {
    this.dialog.open(AssignCompanyLabelsDialogComponent, {
      data: {
        companyId: this.companyId,
        labels: this.company?.labels?.edges.map(it => it.node) || []
      }
    });
  }

  updateCompanyDescription(description: string) {
    this.updateCompanyDescriptionGQL.mutate({id: this.companyId, description: description}).subscribe()
  }

  onDescriptionChange(newDescription: any) {
    this.descriptionChange$.next(newDescription);

  }
}
