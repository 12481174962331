import {AbstractControl, ValidatorFn} from '@angular/forms';
import { SpecificationSalaryUseInTemplate } from 'src/generated/graphql';

export function hasId(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      (control.value && control.value.id) ? null : {noId: control.value};
}

export function hasBruttoValue(): ValidatorFn {
  return (control: AbstractControl) => {
    return (control.value && 
        (control.value.hourlyBruttoFrom ||
        control.value.hourlyBruttoTo ||
        control.value.monthlyBruttoFrom ||
        control.value.monthlyBruttoTo)
        
        ) ? null : {requiredBrutto: true};
  }
}

export function hasBruttoValueForUseInDoc(): ValidatorFn {
    return (control: AbstractControl) => {
      return (control.value && 
        (
          (
            control.value.useInTemplate === SpecificationSalaryUseInTemplate.Hourly &&
            (
              control.value.hourlyBruttoFromFilled ||
              control.value.hourlyBruttoToFilled
            )
          ) ||
          (
            control.value.useInTemplate === SpecificationSalaryUseInTemplate.Monthly &&
            (
              control.value.monthlyBruttoFromFilled ||
              control.value.monthlyBruttoToFilled
            )
          )
        )) ? null : {requiredBruttoForUseInDoc: true};
    }
}
