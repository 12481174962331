<app-form-container headline="ABSENCES" [isLoading]="isLoading">

  <app-files-uploader *ngIf="!this.rows.length" accept=".xml" [onReaderLoad]="onReaderLoad"></app-files-uploader>

  <app-absences-table *ngIf="this.rows.length" [period]="period" [rows]="rows" [openAbsencesDialog]="openAbsencesDialog"></app-absences-table>

  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="this.rows.length" class="actions">
    <div>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="importAbsences()">{{ 'IMPORT_ABSENCES' | translate }}</button>
    </div>
  </div>

</app-form-container>
