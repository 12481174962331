<div [style.background]="'url(' + documentContent + ')'"
     class="image-wrapper"
     *ngIf="canImageBeRendered && !isPdf; else placeholder"
     (click)="onClick()">
  <img [src]="documentContent" (error)="onImageError($event)" hidden>
</div>


<ng-template #placeholder>
  <a href="{{documentContent}}" [style.background]="'url(\'/assets/pdfIcon.png\')'" class="image-wrapper" target="_blank"
    (click)="$event.stopPropagation()"

  ></a>
</ng-template>
