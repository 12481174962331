import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Constants} from '../../constants';
import {AuthService} from '../auth.service';
import * as LogRocket from 'logrocket';
import {UserProfileGQL} from '../graphql';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    private userprofileGQL: UserProfileGQL,
  ) { }

  ngOnInit() {

    const hash = window.location.hash;
    const queryString = window.location.search.substr(1);
    const error = this.parseQueryParams(queryString)['error'] || this.parseQueryParams(hash)['#error'];
    if (error) {
      this.router.navigate(['/'], {
        queryParams: {
          login: 1,
          error
        }
      });
    } else {
      this.exchangeTokens(hash);
    }
  }

  private exchangeTokens(hash) {
    let parts = hash.split('#');
    //
    if (parts.length > 0) {
      parts = parts[1].split('&');
      const accessToken = this.getPart(parts, 'access_token');
      const state = this.getPart(parts,  'state');
      const queryParams = JSON.parse(atob(state));
      const backend = queryParams.backend;
      const href = queryParams.href;

      delete queryParams.backend;
      this.authService.exchangeToken(accessToken, backend).subscribe(
        resp => {
          if (href) {
            this.router.navigateByUrl(href);
          } else {
            this.router.navigate(['/orders/list']);
          }

          this.identifyUser();
        }

        );
    //
    //
    } else {
      this.router.navigate(['/']);
    }
  }

  private identifyUser() {
    this.userprofileGQL.fetch().subscribe(resp => {
      if (LogRocket && LogRocket.identify) {
        LogRocket.identify(resp.data.userprofile.user.email);
      }
    });


  }

  private parseQueryParams(queryString: string) {
    const resp = {};

    if (queryString) {
      queryString.split('&').forEach(
        keyVal => {
          if (keyVal && keyVal.split) {
            const parts = keyVal.split('=');
            if (parts.length > 1) {
              resp[parts[0]] = parts[1];
            }
          }
        }
      );
    }
    return resp;

  }

  private  getPart(parts, key) {
    let filtered = parts.filter((part) =>  part.indexOf(key) > -1)[0];
    if (filtered) {
      filtered = filtered.split('=')[1];
    }
    return filtered;
  }

}
