import {
  Component,
  AfterViewChecked,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UploadOutput,
  UploadInput,
  UploadFile,
  UploaderOptions,
} from 'ngx-uploader';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Client } from './client.class';
import { ClientContract } from './clientContract.class';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { AddonsImportService } from './addons-import.service';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addons-import',
  templateUrl: './addons-import.component.html',
  styleUrls: ['./addons-import.component.scss'],
})
export class AddonsImportComponent implements AfterViewChecked {
  isLoading = false;
  isLoadingAddons = false;
  isFormValid = false;

  clientId: number;
  months: Array<string>;
  projectForm: FormGroup;
  filteredClients: Client[] = [];
  isLoadingFilteredClients = false;
  clientContracts: ClientContract[] = [];
  isLoadingClientContracts = false;
  projectFormStatus = {
    status: '',
    message: '',
  };

  options: UploaderOptions;
  formData: FormData;
  file: UploadFile;
  userfile: string;
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;

  dataSource: any;
  displayedColumns = [
    'id',
    'full_name',
    'addon_amount',
    'addon_comment',
    'position_id',
    'addon_number',
    'project_name',
    'project_number',
    'project_external_id',
    'project_external_id2',
    'project_address',
  ];
  selection: SelectionModel<number>;
  addonNumbers = [
    { value: null, title: '-' },
    { value: 1, title: 1 },
    { value: 2, title: 2 },
    { value: 3, title: 3 },
    { value: 4, title: 4 },
  ];
  positions = [];
  addonsFormStatus = {
    status: '',
    message: '',
  };
  addonsFormErrors = {};
  totalProjects = 0;

  constructor(
    private fb: FormBuilder,
    private addonsService: AddonsImportService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.months = addonsService.getMonths();

    this.projectForm = this.fb.group({
      month: addonsService.getMonth(),
      clothes_supplier: 1,
      clientInput: null,
      client_contract_id: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
    });

    this.projectForm
      .get('clientInput')
      .valueChanges.pipe(
        filter((value) => typeof value === 'string' && value.length > 1),
        tap(() => (this.isLoadingFilteredClients = true)),
        debounceTime(300),
        switchMap((value) => this.addonsService.filterClients({ name: value }))
      )
      .subscribe((clients) => {
        this.filteredClients = clients.results;
        this.isLoadingFilteredClients = false;
      });

    this.projectForm.valueChanges.subscribe(() => {
      this.isFormValid = false;
    });

    this.uploadInput = new EventEmitter<UploadInput>();

    this.selection = new SelectionModel<number>(true, []);
  } // end constructor

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  translateMessage(str: string) {
    return str ? this.translate.instant(str.toString().toUpperCase()) : '';
  }

  confirmRemoveRecords() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant('REMOVE CONFIRMATION'),
        headline: this.translate.instant(
          'ARE YOU SURE YOU WANT TO REMOVE FOLLOWING RECORDS?'
        ),
        records: this.selection.selected.sort().map((i) => {
          const record = this.dataSource.data[i];
          return record.full_name;
        }),
      },
      width: '700px',
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.removeRecords();
      }
    });
  }

  appendData(data) {
    return data.map((row, i) => ({ ...row, i }));
  }

  removeRecords() {
    const filtered = this.dataSource.data.filter(
      (row, i) => this.selection.selected.indexOf(i) === -1
    );
    this.selection.clear();

    this.dataSource = new TableVirtualScrollDataSource(
      this.appendData(filtered)
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row, i) => this.selection.select(i));
  }

  displayFilteredClients(client: Client) {
    if (client) {
      return client.name;
    }
  }

  getClientContracts(client: Client) {
    if (client.id === this.clientId) {
      return;
    }
    this.clientId = client.id;
    this.clientContracts = [];
    this.projectForm.controls.client_contract_id.setValue(null);
    this.positions = [];
    this.updateTableRows('position_id', null);

    this.isLoadingClientContracts = true;

    this.addonsService.getClientContracts(client.id).subscribe((contracts) => {
      this.clientContracts = contracts.results;
      this.isLoadingClientContracts = false;

      if (contracts.results.length) {
        this.projectForm.controls.client_contract_id.enable();
      } else {
        this.projectForm.controls.client_contract_id.disable();
      }
    });
  }

  getContractPositions(id: number) {
    this.addonsService.getContractPositions(id).subscribe((res) => {
      this.positions = res.results;

      if (!this.dataSource) {
        return;
      }

      for (const row of this.dataSource.filteredData) {
        row.position_id = this.addonsService.getPositionIdByTitle(
          row.position_title,
          this.positions
        );
      }
    });
  }

  updateTableRows(column: string, value: any): void {
    if (!this.dataSource) {
      return;
    }

    const filtered = this.selection.selected.length
      ? this.dataSource.filteredData.filter(
          (row, i) => this.selection.selected.indexOf(i) > -1
        )
      : this.dataSource.filteredData;

    for (const row of filtered) {
      row[column] = value;
    }
  }

  reuploadAddons() {
    this.file = null;
    this.dataSource = null;
    this.resetInformation();
    this.selection.clear();
  }

  resetForms() {
    this.file = null;
    this.dataSource = null;

    this.projectForm.reset();

    this.projectForm.patchValue({
      month: this.addonsService.getMonth(),
      clothes_supplier: 1,
    });

    this.filteredClients = [];
    this.clientContracts = [];
  }

  resetInformation() {
    this.addonsFormStatus = {
      status: '',
      message: '',
    };
    this.projectFormStatus = {
      status: '',
      message: '',
    };
    this.addonsFormErrors = {};
    this.totalProjects = 0;
  }

  getFormData() {
    return {
      month: this.projectForm.value.month,
      client_contract_id: this.projectForm.value.client_contract_id,
      client_id: this.clientId,
      clothes_supplier: this.projectForm.value.clothes_supplier,
      data: this.dataSource ? this.dataSource.filteredData : [],
    };
  }

  competeImport() {
    this.resetForms();
    this.resetInformation();
    this.isLoading = false;

    this.snackBar.open(
      this.translate.instant('ADDONS IMPORTED SUCCESSFULLY'),
      '',
      {
        duration: 5000,
      }
    );
  }

  submitAddonsForm() {
    const data = this.getFormData();

    this.validateAddonsForm(true);

    if (this.isFormValid) {
      this.isLoading = true;

      this.addonsService.sendAddonsForm(data, 'create').subscribe((res) => {
        if (res.status === 'success') {
          this.competeImport();
        }
      });
    }
  }

  async validateAddonsForm(importing: boolean = false) {
    const data = this.getFormData();

    this.isLoading = true;

    this.resetInformation();

    const names = [...new Set(data.data.filter((r: any) => !r.id).map((r: any) => r.full_name))];

    if (names.length) {
      try {
        const missingEmployeeIds = await this.addonsService.getMissingEmployeeIds(data.client_id, data.month, { names });

        missingEmployeeIds.filter(e => !!e.id).forEach(employee => {
          this.dataSource.filteredData.forEach(row => {
            if (row.full_name === employee.name) {
              row.id = parseInt(employee.id, 10);
            }
          });
        });
      } catch (e) {

      }
    }

    this.addonsService.sendAddonsForm(data, 'validate').subscribe(
      (res) => {
        this.totalProjects = res.project_count ? res.project_count : 0;

        if (res.status === 'error') {
          this.isFormValid = false;

          if (res.data_errors && res.data_errors.length) {
            this.projectFormStatus = {
              status: 'success',
              message: this.translate.instant('PROJECT IS VALID'),
            };

            this.addonsFormStatus = {
              status: 'error',
              message: this.translateMessage(res.message),
            };

            if (!importing) {
              this.snackBar.open(this.translateMessage(res.message), '', {
                duration: 3000,
              });
            }

            res.data_errors.forEach((row) => {
              if (row.key || row.full_name) {
                this.addonsFormErrors[
                  row.key || row.full_name
                ] = this.translateMessage(row.message);
              }
            });
          } else {
            this.projectFormStatus = {
              status: 'error',
              message: this.translateMessage(res.message),
            };

            if (!importing) {
              this.snackBar.open(this.translateMessage(res.message), '', {
                duration: 3000,
              });
            }
          }
        } else {
          this.projectFormStatus = {
            status: 'success',
            message: this.translate.instant('PROJECT IS VALID'),
          };

          this.addonsFormStatus = {
            status: 'success',
            message: this.translate.instant('ADDONS ARE VALID'),
          };

          if (!importing) {
            this.snackBar.open(
              this.translate.instant('PROJECT AND ADDONS ARE VALID'),
              '',
              {
                duration: 3000,
              }
            );
          }

          this.isFormValid = true;
        }

        if (!importing) {
          this.isLoading = false;
        }
      },
      (err) => {
        console.dir(err);

        this.isLoading = false;

        this.snackBar.open(
          this.translateMessage(err.statusText),
          this.translateMessage('Close'),
          {
            duration: 15000,
          }
        );
      }
    );
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.file = output.file;
      this.isLoadingAddons = true;

      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.addonsService
          .getDataSource(reader.result, this.positions)
          .subscribe((addons) => {
            this.dataSource = new TableVirtualScrollDataSource(
              this.appendData(addons.results)
            );
            this.isLoadingAddons = false;
          });
      };

      reader.readAsDataURL(output.file.nativeFile);
    }
  }
}
