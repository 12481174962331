import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {OrderCreateService} from '../../../order/order-create/order-create.service';
import {BaseOrderFieldsFragment, CountryFieldsFragment, GetRegionsRelayGQL, OrderSelectListFieldsFragment} from '../../../../generated/graphql';
import {BehaviorSubject, Subject} from 'rxjs';
import {ContactCreateDialogComponent} from '../../contact-create-dialog/contact-create-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '../../../shared/notification.service';
import {MatSelect} from '@angular/material/select';
import {first, takeUntil} from 'rxjs/operators';
import {OrderTypeEnum} from 'src/app/shared/types/order-types';

@Component({
  selector: 'app-clients-and-contacts',
  templateUrl: './clients-and-contacts.component.html',
  styleUrls: ['./clients-and-contacts.component.scss'],
})
export class ClientsAndContactsComponent implements OnInit, OnDestroy {
  regions: { id: string; title?: string, isActive?: boolean }[] = [];
  contacts: { id: string; firstName?: string; lastName?: string }[] = [];
  managers = [];
  selectedManagers = [];
  orders: OrderSelectListFieldsFragment[] = [];
  selectedOrderId: string;
  selecteCompanyId: string;
  selectedRegion: { id: string; title?: string };
  editOrderId: string;
  public isOrderShown = new BehaviorSubject<boolean>(true);
  contracts = [];
  private $country: CountryFieldsFragment;
  private $form: any;
  managerFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  @ViewChild('clientContactSelect') clientContactSelect: MatSelect;

  @Input() set country(value: CountryFieldsFragment) {
    this.$country = value;
    this.fetchCountryData(value?.id);
  }
  get country() { return this.$country; }

  @Input() set form(value: FormGroup) {

    this.$form = value;
  }
  get form() {
    return this.$form;
  }

  private $orderType: OrderTypeEnum;
  @Input() set orderType(value) {
    this.$orderType = value;
  }
  get orderType() {
    return this.$orderType;
  }

  @Input() order: BaseOrderFieldsFragment;

  @Output() newOrder = new EventEmitter<string>();

  get isSelection() { return this.$orderType === OrderTypeEnum.selection; }
  get isPartialSelection() { return this.$orderType === OrderTypeEnum.partialSelection; }

  @Output() selectedBaseOrder = new EventEmitter<string>();

  get descriptionCtrl() {
    return this.$form.get('description');
  }
  get companyCtrl() {
    return this.$form.get('company');
  }
  get selectedOrderCtrl() {
    return this.$form && this.$form.get('selectedOrder');
  }
  get selectedContactCtrl() {
    return this.$form && this.$form.get('clientContact');
  }
  get contactCtrl() {
    return this.$form && this.$form.get('contract');
  }
  constructor(
    private dialog: MatDialog,
    private orderService: OrderCreateService,
    private notify: NotificationService,
    private orderCreateService: OrderCreateService,
    private getRegionsRelayGQL: GetRegionsRelayGQL
  ) {}

  ngOnInit(): void {
    this.subscribeCompanyValueChanges();
    this.managerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.search();
      });
  }

  openAddContactDialog() {
    const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
      data: {
        company: {id: this.selecteCompanyId},
      },
    });

    dialogRef.afterClosed().subscribe((contact) => {
      if (contact) {
        this.contacts.push(contact);
        this.selectedContactCtrl.setValue(contact.id);
        this.notify.notify('CONTACT_SUCCESSFULLY_CREATED');
      }
    });
  }

  openEditContactDialog(contactId?: string) {
    const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
      data: {
        contactId: contactId ? contactId : this.selectedContactCtrl.value,
      },
    });

    dialogRef.afterClosed().subscribe((contact) => {
      if (contact) {
        // this.orderService.selectedCompany.value.contactSet[
        //   this.contacts.findIndex(e => e.id === contact.id)
        //   ] = contact;
        this.contacts[
          this.contacts.findIndex((e) => e.id === contact.id)
        ] = contact;
        this.notify.notify('Contact successfully edited');
      }
    });
  }

  private subscribeCompanyValueChanges() {
    this.orderCreateService?.contractSet.subscribe((contracts: any) => {
      if (contracts) {
        this.contracts.push(...contracts);
      }
    });
    this.orderCreateService?.selectedCompany.subscribe((newValue) => {
      this.selecteCompanyId = newValue?.id;
      if (newValue && newValue.id) {
        this.fetchCompanyData(newValue);
      }
    });
  }

  fetchCompanyData(newValue = this.orderCreateService?.selectedCompany?.value) {
    this.orderService
          .getCompanyData(newValue.id)
          .pipe(first())
          .subscribe((value) => {
            const usedInOrderContacts = [this.order?.clientContact, ...(this.order?.additionalClientContacts?.edges.map(edge => edge.node) || [])];
            if (value.data) {
              if (this.orderService.editOrderId.value) {
                this.editOrderId = this.orderService.editOrderId.value;
              }
              this.managers = value.data.userprofiles;
              this.selectedManagers = [...value.data.userprofiles];
              this.contacts = value.data.company.contactSet.edges
                .map(edge => edge.node)
                .filter(contact => contact.canUseInOrder || usedInOrderContacts.find(item => item?.id === contact?.id));
              this.contracts = value.data.company.contractSet;
            }
          });
        if (this.orderService.editOrderId.value) {
          this.isOrderShown.next(false);
        } else {
          this.isOrderShown.next(true);
          this.selectedOrderCtrl.reset();
        }
  }

  refetchCompanyContracts(newValue = this.orderCreateService?.selectedCompany?.value) {
    this.orderService
      .getCompanyData(newValue.id)
      .subscribe((value) => {
        if (value.data) {
          this.contracts = value.data.company.contractSet;
        }
      });
  }

  search() {
    let filter = this.managerFilterCtrl.value?.toLowerCase() || '';
    this.selectedManagers = this.managers.filter((option) => {
      return (
        (option?.user?.firstName?.toLowerCase() + ' ' + option?.user?.lastName?.toLowerCase()).includes(filter)
      );
    });
  }

  getRegions(id: string) {
    if (id) {
      this.getRegionsRelayGQL.fetch({ country: id })
        .subscribe(regions => {

          this.regions = regions.data.regionsRelay.edges.map(v => v.node);
        });
    }
  }

  // private subscribeSelectedBaseOrderChange() {
  //   this.orderService.selectedOrder.asObservable().subscribe(
  //     newOrder => {
  //       if (newOrder && (newOrder.company.id === this.companyCtrl?.value?.id)) {
  //         this.form.get('contact').setValue(newOrder.clientContact.id);
  //         this.form.get('projectManager').setValue(newOrder.projectManager.id);
  //         this.form.get('additionalContacts').setValue(newOrder.additionalClientContacts.map(it => it.id));
  //       }
  //     }
  //   );
  // }


  onOrderSelect() {
    this.isOrderShown.next(false);
  }
  onNewOrder(order: string) {
    this.selectedOrderId = order;
    this.orders = [];
    this.newOrder.emit(order);
  }

  onNewOrders(orders: OrderSelectListFieldsFragment[]) {
    if (orders) {
      this.orders = orders;
      if (orders.length <= 0) {
        this.isOrderShown.next(false);
      } else {
        if (this.orderCreateService.editOrderId.value) {
          this.isOrderShown.next(false);
        } else {
          this.isOrderShown.next(true);
        }
      }
    }
  }

  onCompanyChange(val) {
    this.selectedOrderId = undefined;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getSelectedValue(value: any) {
    let toReturn = '';
    if (Array.isArray(value)) {
      toReturn = value
        .map((it) => {
          const match = this.contacts.filter((it2) => it2.id === it)[0];
          return `${match.firstName} ${match.lastName}`;
        })
        .join(', ');
    } else {
      toReturn = this.contacts
        .filter((it2) => it2.id === value)
        .map((it) => `${it.firstName} ${it.lastName}`)
        .join(',');
    }
    return toReturn;
  }

  private fetchCountryData(value: string) {
    this.getRegions(value);

  }
}
