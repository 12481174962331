import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {Company, Contact, GetContactGQL} from 'src/app/graphql/graphql';
import { CreateContactGQL, GetCompaniesNamesGQL, UpdateContactGQL } from '../../../generated/graphql';

@Component({
  selector: 'app-contact-create-dialog',
  templateUrl: './contact-create-dialog.component.html',
  styleUrls: ['./contact-create-dialog.component.scss']
})
export class ContactCreateDialogComponent implements OnInit {

  contactForm: FormGroup;
  contact: any;

  constructor(
    private createContactGQL: CreateContactGQL,
    private updateContactGQL: UpdateContactGQL,
    private getContactGQL: GetContactGQL,
    private getCompaniesNamesGQL: GetCompaniesNamesGQL,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {company?: Company, contactId?: string},
    private dialogRef: MatDialogRef<ContactCreateDialogComponent>
  ) { }

  ngOnInit() {
    if (!this.data?.contactId) {
      this.initForm();
    } else {
      this.getContactGQL.watch({id: this.parseId(this.data.contactId)}).valueChanges.subscribe(
        resp => {
          this.contact = resp.data.contact;
          this.initForm(resp.data.contact);
        }
      );
    }
  }

  private initForm(contact?: Contact) {
    if (contact) {
      this.contactForm = this.fb.group({
        firstName: [contact.firstName],
        lastName: [contact.lastName],
        email: new FormControl({value: contact.email, disabled: !!contact.email}),
        phone: [contact.phone],
        comment: [contact.comment]
      });
    } else {
      this.contactForm = this.fb.group({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comment: '',
        companyId: ''
      });
    }
  }

  createContact() {
    if (this.data?.contactId) {
      this.updateContactGQL.mutate(
        {
          id: Number(this.parseId(this.data.contactId)),
          input: this.contactForm.getRawValue()
        },
      ).subscribe(resp => {
        this.dialogRef.close(resp.data.updateContact.contact);
      });
    } else {
      this.createContactGQL.mutate({
        input: {
          firstName: this.contactForm.get('firstName').value,
          lastName: this.contactForm.get('lastName').value,
          email: this.contactForm.get('email').value,
          phone: this.contactForm.get('phone').value,
          comment: this.contactForm.get('comment').value,
          companyId: this.parseId(this.contactForm.get('companyId')?.value || this.data.company.id)
        }
      }).subscribe(resp => {
       this.dialogRef.close(resp.data.createContact.contact);
      });
    }
  }

  private parseId(companyId) {
    return atob(companyId).split(':')[1];
  }

  selectCompany(company) {
    this.contactForm.get('companyId').setValue(company.id);
  }
}
