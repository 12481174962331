import { Component, Inject, OnInit } from '@angular/core';

import * as moment from 'moment';
import {
  CandidateEventsForTableGQL,
  EventEventType,
  EventResolutionStatus,
  EventUpdateGQL,
  InterviewWidgetCreateEventGQL,
  SmsGQL,
  UpdateGQL,
} from '../../../generated/graphql';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule-event-dialog',
  templateUrl: './schedule-event-dialog.component.html',
  styleUrls: ['./schedule-event-dialog.component.scss'],
})
export class ScheduleEventDialogComponent implements OnInit {
  hour: string = '10:00';
  sms: string;
  cancelEvents: boolean;
  isEdit: boolean = false;
  sendSms: boolean;
  eventId: string;
  eventStatus: string;
  date = moment().add(1, 'day');
  tempPresentData = [];
  presentData = [];
  companyName = '';
  companyStatus = '';
  constructor(
    private createEventGQL: InterviewWidgetCreateEventGQL,
    private updateGql: UpdateGQL,
    private updateEVentGql: EventUpdateGQL,
    private smsGql: SmsGQL,
    private dialogRef: MatDialogRef<ScheduleEventDialogComponent>,
    private orderCandidateGQL: CandidateEventsForTableGQL,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      candidate: string;
      relatedOffer: string;
      candidateId: string;
      interviewType: string;
      candidateEvent: any;
      companyName: string;
      companyStatus: string;
      interviewEvent?: any;
      healthCheckPendingEvent?: any;
      employmentEvent?: any;
      eventType: EventEventType
    }
  ) {}

  ngOnInit() {
    let event = this.data.candidateEvent?.find(
      (node: any) => node.node.eventType == this.data.interviewType
    );
    if (event) {
      const node = event.node;
      this.isEdit = true;
      this.eventId = node?.id;
      this.eventStatus = node?.resolutionStatus;
      this.date = moment(node?.startDatetime);
      this.hour = moment(node?.startDatetime).format('HH:mm');
    }
    this.getOrderCandidates(this.data.candidateId);
  }

  save() {
    let eventType;
    if (this.data.interviewType == 'CLIENT_INTERVIEW') {
      eventType = EventEventType.ClientInterview;
    } else if (this.data.interviewType == 'INTERVIEW') {
      eventType = EventEventType.Interview;
    } else if (this.data.interviewType == 'EMPLOYMENT') {
      eventType = EventEventType.Employment;
    } else if (this.data.interviewType == 'HEALTH_CHECK_PENDING') {
      eventType = EventEventType.HealthCheckPending;
    }
    this.date.set('hour', parseInt(this.hour.split(':')[0], 10));
    this.date.set('minute', parseInt(this.hour.split(':')[1], 10));
    this.date.set('second', 0);
    this.date.set('millisecond', 0);
    if (this.sendSms) {
      if (this.sms) this.sendSMS();
    } else if (this.cancelEvents) {
      this.cancelAllEvents();
    }
    if (this.isEdit) {
      this.updateEVentGql
        .mutate({
          id: this.eventId,
          startDatetime: this.date.toISOString(),
        })
        .subscribe(async (resp) => {
          if (this.eventStatus && this.eventStatus == 'CANCELLED')
            await this.updateEvent(this.eventId, null);
          this.dialogRef.close(this.date.toISOString());
        });
    } else {
      this.createEventGQL
        .mutate({
          startDatetime: this.date.toISOString(),
          eventType: eventType,
          offerPropositions: [this.data.relatedOffer],
        })
        .subscribe(async (resp) => {
          if (this.data.interviewType == 'CLIENT_INTERVIEW') {
            let event = this.data.candidateEvent?.find(
              (node: any) => node?.node?.eventType == 'INTERVIEW'
            );
            if (event) {
              const node = event.node;
              await this.updateEvent(node.id, EventResolutionStatus.Complete);
            }
          } else if (this.data.interviewType == 'EMPLOYMENT') {
            this.data.candidateEvent.map(async (node: any) => {
              if (
                node?.node?.eventType == 'INTERVIEW' ||
                node?.node?.eventType == 'CLIENT_INTERVIEW'
              )
                await this.updateEvent(
                  node.node.id,
                  EventResolutionStatus.Complete
                );
            });
          }
          this.dialogRef.close(this.date.toISOString());
        });
    }
  }
  cancelAllEvents() {
    this.presentData.map((item: any) => {
      this.updateEvent(item.id, 'CANCELLED');
    });
  }

  getOrderCandidates(candidate) {
    this.presentData = [];
    this.orderCandidateGQL
      .watch({ candidate }, { fetchPolicy: 'no-cache' })
      .valueChanges.subscribe((res) => {
        if (res.data) {
          res.data.candidateEvents.edges.forEach((entry) => {
            const node = entry.node;
            // if (moment(node.startDatetime) > moment().startOf('day')) {
            this.presentData.push(node);


            // this.companyName = this.data.companyName;
            // this.companyStatus = this.data.companyStatus;
            // }

          });
          this.showless();
        }
      });
  }
  showless() {
    this.tempPresentData = [];
    this.presentData.slice(0, 5).map((element) => {
      this.tempPresentData.push(element);
    });
  }

  async updateEvent(eventId: string, status: any) {
    if (eventId) {
      this.updateGql.mutate({ id: eventId, status: status }).subscribe((resp) => {
        this.presentData.map((data) => {
          if (data.id === eventId) {
            data.resolutionStatus = status;
          }
        });
      });
    }
  }
  sendSMS() {
    this.smsGql
      .mutate({ id: this.data.candidateId, sms: this.sms })
      .subscribe((resp) => {
        console.log(resp);
      });
  }
  showMore() {
    if (this.tempPresentData.length !== this.presentData.length) {
      this.tempPresentData = this.presentData;
    } else {
      this.showless();
    }
  }
}
