<mat-icon *ngIf='cancellationReasonsAssignments?.length'
          inline
          (mouseenter)='showCancellationReasonsTooltip($event.currentTarget, tooltip)'>
  info
</mat-icon>
<div #tooltip style='display: none;'>
  <span *ngFor='let cr of cancellationReasonsAssignments' class='cancellation-reason'>
    {{cr?.cancellationReason?.title}}
  </span>
</div>

