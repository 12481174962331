import {Component, Input, OnInit} from '@angular/core';
import {IntensityOptions, TagGroupKey} from 'src/app/specification/specification-edit/specification-edit.component';
import {OrderTrafficGeneration} from 'src/app/shared/types/order-traffic-generation';
import {FreeVacanciesListFieldsFragment, OrderOrderType} from '../../../generated/graphql';
import { OrderActionsLogService } from 'src/app/orders/order-actions-log/order-actions-log.service';

@Component({
  selector: 'app-marketing-icons',
  templateUrl: './marketing-icons.component.html',
  styleUrls: ['./marketing-icons.component.scss']
})
export class MarketingIconsComponent implements OnInit{

  iconsDict = {
    INACTIVE: 'voice_over_off',
    ACTIVE_NO_MARKETING: 'record_voice_over',
    ACTIVE_WITH_MARKETING: 'record_voice_over',
    [IntensityOptions.stationary]: 'human-handsdown',
    [IntensityOptions.sedentary]: 'seat-recline-normal',
    [IntensityOptions.stationaryFast]: 'run-fast',
    [IntensityOptions.sedentaryFast]: 'rowing',
    [IntensityOptions.sedentaryAndStationary]: 'bike'
  };
  tooltips = {
    [OrderTrafficGeneration.inactive]: 'Nesiūlyti darbuotojams',
    [OrderTrafficGeneration.activeNoMarketing]: 'Siūlyti kandidatams, marketingo nereikia',
    [OrderTrafficGeneration.activeWithMarketing]: 'Siūlyti kandidatams, naudoti marketingą',
  };

  orderTrafficGeneration = OrderTrafficGeneration;

  @Input() order: FreeVacanciesListFieldsFragment;
  transportation: any;
  perspectives: string;
  isSelection: any;

  constructor(
    public orderActionLogService: OrderActionsLogService,

  ) { }

  ngOnInit() {
    this.isSelection = (this.order.orderType === OrderOrderType.Selection);
    this.transportation  = this.order.specification.tagassignmentSet
      .filter(it => it.tag.group.key === TagGroupKey.transportInfo)
      .map(it => `${it.tag.title} ${it.comment}`)
      .join('\n');


    this.perspectives  = this.order.specification.tagassignmentSet
      .filter(it => it.tag.group.key === TagGroupKey.workplaceInto)
      .map(it => `${it.tag.title} ${it.comment}`)
      .join('\n');

  }
}
