import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-absences-table-column',
  templateUrl: './absences-table-column.component.html',
  styleUrls: ['./absences-table-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AbsencesTableColumnComponent implements OnChanges {

  absences = {};
  shifts = {};
  warnings = {};

  @Input() date: any;
  @Input() content: any;
  @Input() employeeId: any;
  @Input() openAbsencesDialog: any;

  constructor(
    private translate: TranslateService,
  ) {
  }

  getAbsenceCSS(absence: any, employerId: any, warnings: any) {
    if (!absence.id) {
      return `absence--theme-import ${warnings && warnings[employerId] ? `absence--theme-import-warning--${employerId  === '1' ? 'primary' : 'secondary'}` : ''}`;
    }

    return `absence--theme-${employerId  === '1' ? 'primary' : 'secondary'} ${absence.is_fixed === false ? 'absence--missing-data' : ''} ${absence.has_contract === false ? 'absence--missing-contract' : ''}`;
  }

  getAbsenceTooltip({ employerId }) {
    let tooltip = '';

    if (this.content && this.content.date && JSON.stringify(this.absences) === '{}') {
      return `${this.translate.instant('ABSENCE_IS_MISSING')}`;
    }

    if (this.warnings && this.warnings[employerId]) {
      return `${this.warnings[employerId].existing} ${this.translate.instant('WILL_BE_UPDATED_TO')} ${this.warnings[employerId].importing}`;
    }

    if (this.absences && this.absences[employerId] && this.absences[employerId].find(abs => abs.is_fixed === false)) {
      tooltip += `${this.translate.instant('ABSENCE_IS_MISSING_DATA')}. `;
    }

    if (this.absences && this.absences[employerId] && this.absences[employerId].find(abs => abs.has_contract === false)) {
      tooltip += `${this.translate.instant('ABSENCE_IS_MISSING_CONTRACT')}. `;
    }

    return tooltip ? tooltip : null;
  }

  ngOnChanges() {
    const { absences = null, shifts = {}, warnings = {} } = this.content || {};

    this.absences = absences;
    this.shifts = shifts;
    this.warnings = warnings;
  }
}
