<div *ngIf="mode===modes.long; else shortNetto">
  <div>
    <div fxLayout="row">
      <span class="label" fxFlex="25">{{'SPECIFICATION.BRUTTO_HOURLY' | translate}}:</span>
      <span fxFlex="75">
        <app-salary-range [value]="hourlyBrutto"></app-salary-range>
        eur
      </span><br>
    </div>
    <div fxLayout="row">
      <span class="label" fxFlex="25">{{'SPECIFICATION.BRUTTO_MONTHLY' | translate}}:</span>
      <span fxFlex="75">
        <app-salary-range [value]="monthlyBrutto"></app-salary-range>
        eur
      </span><br>
    </div>
  </div>
  <mat-divider [inset]="true" class="divider"></mat-divider>
  <div>
    <div fxLayout="row">
      <span class="label" fxFlex="25">{{'SPECIFICATION.NET_APPROX' | translate}}:</span>
      <span fxFlex="75">
        <app-salary-range [value]="monthlyNetto"></app-salary-range>
        eur
      </span><br>
    </div>

    <div *ngIf="isTypeBoolean(canIncreaseSalary)" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center" [style.margin-top]="'12px'">
      <span>{{ 'ORDER.CAN_INCREASE_SALARY' | translate }}:</span>
      <mat-icon *ngIf="canGetBonuses" class="boolean-state-icon true">check_circle_outline</mat-icon>
      <mat-icon *ngIf="!canGetBonuses" class="boolean-state-icon false">close</mat-icon>
    </div>

    <div *ngIf="isTypeBoolean(canGetBonuses)" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
      <span>{{ 'ORDER.CAN_ACCESS_ACCESSORIES' | translate }}:</span>
      <mat-icon *ngIf="canGetBonuses" class="boolean-state-icon true">check_circle_outline</mat-icon>
      <mat-icon *ngIf="!canGetBonuses" class="boolean-state-icon false">close</mat-icon>
    </div>

    <div class="salary-comment">
      <div><strong>{{'SPECIFICATION.ADDITIONAL_SALARY_COMMENT' | translate}}:</strong></div>
      <div>{{comment}}</div>
    </div>
  </div>
</div>

<ng-template #shortNetto >
  <app-salary-range [value]="monthlyNetto"></app-salary-range>
</ng-template>
