import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { OperationsTaskFieldsFragment } from '../../../generated/graphql';
import { TaskServiceService } from '../task-service.service';

export const taskStatuses = ['ANNOUNCE', 'PLANNED', 'IN_PROGRESS', 'DONE', 'CANCELLED'];

@Component({
  selector: 'app-task-status-pill',
  templateUrl: './task-status-pill.component.html',
  styleUrls: ['./task-status-pill.component.scss']
})
export class TaskStatusPillComponent {
  statuses = taskStatuses;
  newTaskDate: string;

  @Input() task: OperationsTaskFieldsFragment;
  @Input() disableStatusChange = false;
  @Input() showNewTaskDate = false;

  @Output() statusChange = new EventEmitter<string>();

  constructor(private taskService: TaskServiceService) { }

  onStatusChangeClick(task: any): void {
    this.newTaskDate = undefined;
    if (this.showNewTaskDate && (task.schedules.edges.length || task.createdBySchedule) && task.status !== 'DONE') {
      this.taskService.getNextTaskDate(task.id).pipe(take(1)).subscribe(res => {
        const newTaskDate = res.data.nextTaskDate.date;
        if (newTaskDate !== 'None') {
          this.newTaskDate = newTaskDate;
        }
      });
    }
  }
}
