<div [formGroup]="form">
  <div fxLayout="row"  fxLayoutGap="16px" fxFlexFill class="bordered-row">
    <div fxLayout="column"  fxFlex="50">
      <mat-form-field *ngIf="orderType === orderTypes.staffing" appearance="outline">
        <mat-label>{{'ORDER.POSITION_TITLE' | translate }}</mat-label>
        <input formControlName="positionTitle" matInput>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="16px" >
        <mat-form-field appearance="outline" fxFlex="calc(50%-8px)">
          <mat-label>{{'ORDER.WORK_POSE' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="intensity" name="spec">
            <mat-option *ngFor="let i of intensityOptions " [value]="i">
              {{('ORDER.WORK_POSE_OPTIONS.' + i) | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="calc(50%-8px)">
          <mat-label>{{'ORDER.TRAININGS_DURATION' | translate }}</mat-label>
          <input formControlName="onboardingDuration" type="number" matInput>
          <span matSuffix class="days">{{'COMMON.DAYS' | translate}}</span>

        </mat-form-field>
      </div>

      <mat-checkbox formControlName="isQualificationImprovementPossible">{{'ORDER.CAN_RAISE_QALIFICATION' | translate}}</mat-checkbox>
    </div>

    <div fxFlex="50" fxLayout="column">
      <mat-form-field appearance='outline' fxFlex='100' *ngIf='orderType === orderTypes.staffing'>
        <mat-label>{{'ORDER.POSITION_DESCRIPTION' | translate }}</mat-label>
        <textarea formControlName='workFunctions' matInput rows='6'></textarea>
      </mat-form-field>

      <mat-form-field appearance='outline' fxFlex='100' *ngIf='orderType === orderTypes.selection'>
        <mat-label>{{'SPECIFICATION.CAREER_POSSIBILITIES' | translate }}</mat-label>
        <textarea formControlName='careerPossibilities' matInput rows='6'></textarea>
      </mat-form-field>

      <mat-form-field appearance='outline' fxFlex='100'>
        <mat-label>{{'SPECIFICATION.ADDITIONAL_RECRUITEMENT_INFORMATION' | translate }}</mat-label>
        <textarea formControlName='additionalRecruitementInformation' matInput rows='6'></textarea>
      </mat-form-field>

      <mat-radio-group fxFlex='100' formControlName='qualification'>
        <mat-radio-button
          [value]='qualificationEnum.NonSpecialist'>{{'ORDER.QUALIFICATION.NON_SPECIALIST' | translate}}</mat-radio-button>
        <mat-radio-button
          [value]='qualificationEnum.Specialist'>{{'ORDER.QUALIFICATION.SPECIALIST' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>


  <div fxLayout="row" [formGroup]="form" fxLayoutGap="16px" fxFlexFill class="bordered-row">
    <app-schedule-setup
      [form]="form"
      fxFlexFill>
    </app-schedule-setup>
  </div>

  <div fxLayout="row" [formGroup]="form" fxLayoutGap="16px" fxFlexFill class="bordered-row">
    <div fxLayout="column"  fxFlex="50">
      <mat-form-field appearance="outline">
        <mat-label>{{'ORDER.ADDRESS' | translate }}</mat-label>
        <input formControlName="address" matInput>
      </mat-form-field>
      <mat-checkbox formControlName="isSecurityPostPresent">{{'ORDER.SECURITY_POST_PRESENT' | translate}}</mat-checkbox>
    </div>

    <app-tag-assignment-table fxFlex="50"
                              [tags]="transportInfoTags"
                              (selected)="onTagsSelection($event, tagGroupMap.transportInfo)"
                              [assigned]="tagAssignments"
    ></app-tag-assignment-table>
<!--    (selected)="onTagsSelection($event, tagGroupMap.transportInfo)" [assigned]="tagAssignments"-->

  </div>

  <div fxLayout="column" >
    <app-risk-factors-table
      fxFlex="100"
      (selected)="onRiskFactorsUpdate($event)"
      [markDefault]="markDefault"
      [preselect]="riskFactors"
      *ngIf="orderType !== orderTypes.selection"
    ></app-risk-factors-table>

    <mat-form-field appearance="outline" fxFlex="100" *ngIf="orderType === orderTypes.selection">
      <mat-label>{{'SPECIFICATION.REQUIREMENTS_OTHERS' | translate }}</mat-label>
      <textarea formControlName="requirementsOther" matInput rows="6"></textarea>
    </mat-form-field>
  </div>



</div>

