import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';
import {
  CancellationReasonAssignmentTypeConnection,
  OfferEventFieldsFragment,
  OfferPropositionInterested,
  OfferPropositionInterestedOnCall,
  OfferPropositionLongListed,
} from '../../../generated/graphql';

export enum ResponseTypes {
  agreed = 'INTERESTED',
  notInterested = 'NOT_INTERESTED',
  undecided = 'UNDECIDED',
}

// TODO: Replace this with your own data model type
export interface OffersTableItem {
  companyName: string;
  companyStatus: string;
  interested: OfferPropositionInterested | undefined;
  interestedOnCall: OfferPropositionInterestedOnCall | undefined;
  title: string;
  id: string;
  responseId: string;
  comment: string;
  signed: boolean;
  candidate: string;
  candidateEvent: any;
  interview: OfferEventFieldsFragment;
  cancellationReasonsAssignmentSet?: any[];
  createdAt?: string;
  color?: string;
  longListed?: OfferPropositionLongListed;
}

// TODO: replace this with real data from your application
const EXAMPLE_DATA: OffersTableItem[] = [];

/**
 * Data source for the OffersTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class OffersTableDataSource extends DataSource<OffersTableItem> {
  data = new BehaviorSubject<OffersTableItem[]>([]);
  paginator: MatPaginator;
  sort: MatSort;

  constructor() {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OffersTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.data.asObservable(),
      this.paginator.page,
      this.sort.sortChange,
    ];

    return merge(...dataMutations).pipe(
      map(() => {
        return this.getPagedData(this.getSortedData([...this.data.value]));
      })
    );
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: OffersTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: OffersTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name':
          return compare(a.title, b.title, isAsc);
        case 'id':
          return compare(+a.id, +b.id, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
