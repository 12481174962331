import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SalaryTableCellFieldsFragment} from '../../../../generated/graphql';

@Component({
  selector: 'app-salary-cell',
  templateUrl: './salary-cell.component.html',
  styleUrls: ['./salary-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalaryCellComponent implements OnInit {

  private $salary: SalaryTableCellFieldsFragment;
  hourly: any;
  valueFrom: number;
  valueTo: number;

  @Input() set salary(value: SalaryTableCellFieldsFragment){
    this.$salary = value;

    if (value) {
      if (value.hourlyBruttoFromFilled) {
        this.valueFrom = value.hourlyBruttoFrom;
        this.valueTo = value.hourlyBruttoTo;
        this.hourly = true;
      } else {
        this.valueFrom = value.monthlyBruttoFrom;
        this.valueTo = value.monthlyBruttoTo;
        this.hourly = false;
      }
    }



  }
  get salary() { return this.$salary; }

  constructor() { }

  ngOnInit(): void {
  }

}
