import {Component, Inject, OnInit} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CancellationServiceService} from './cancellation-service.service';
import { GetCountriesGQL } from 'src/generated/graphql';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface CancellationReasonNode {
  id: string;
  title: string;
  children?: CancellationReasonNode[];
}



@Component({
  selector: 'app-cancellation-reason-dialog',
  templateUrl: './cancellation-reason-dialog.component.html',
  styleUrls: ['./cancellation-reason-dialog.component.scss']
})
export class CancellationReasonDialogComponent  {
  searchString: any;

  private FULL_TREE_DATA: CancellationReasonNode[];

  treeControl = new NestedTreeControl<CancellationReasonNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CancellationReasonNode>();

  selection = new SelectionModel<CancellationReasonNode>(true, []);
  countryId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {offerPropositionId: number, country: string},
    private dialogRef: MatDialogRef<CancellationReasonDialogComponent>,
    private cancellationReasonService: CancellationServiceService,
    private countriesGQL: GetCountriesGQL
  ) {

    this.countriesGQL.fetch()
    .subscribe((v) => {
      this.countryId = v.data.countries.edges.find(c => c.node.isoCode === this.data.country)?.node?.id;
      cancellationReasonService.getGroups(this.countryId).subscribe(
        re => {
          if (re.data) {
            this.FULL_TREE_DATA = this.parseGroupData(re.data.cancellationReasonGroups.edges);
            this.dataSource.data = this.FULL_TREE_DATA;
          }
        }
      );
    });

    if (data.offerPropositionId) {
      this.loadSavedAssignments(data.offerPropositionId);
    }
  }

  hasChild = (_: number, node: CancellationReasonNode) => !!node.children && node.children.length > 0;

  filterTreeNodes() {
    const search = this.searchString.toLowerCase();
    this.dataSource.data = this.FULL_TREE_DATA.map(
      node => {
        return {
          id: node.id,
          title: node.title,
          children: node.children.filter(n => n.title.toLowerCase().includes(search))
        };
      }
    ).filter(
      node => {
        return node.children.length > 0;
      }
    );

    this.treeControl.dataNodes = this.dataSource.data;
    this.treeControl.expandAll();

  }

  isSelected(node) {
    return this.selection.isSelected(node);
  }

  select(node) {
    this.selection.toggle(node);
    // if (this.selection.isSelected(node)) {
    //   this.selection.deselect(node);
    // } else {
    //   const values = [...this.selection.selected, node];
    //   this.selection.select(values);
    // }

  }

  private parseGroupData(cancellationReasonsGroupData): CancellationReasonNode[] {
    return cancellationReasonsGroupData.map(it => {
      const group = { ...it.node };
      group.children = group.cancellationReasons.edges.map(r => r.node);
      return group;
    });
  }

  private loadSavedAssignments(offerPropositionId: number) {
    this.cancellationReasonService.getSavedAssignments(offerPropositionId).subscribe(
      re => {
        if (re.data?.cancellationReasonAssignments) {
          if (this.FULL_TREE_DATA) {
            const preselectedIds = re.data.cancellationReasonAssignments.edges.map(it => it.node?.cancellationReason.id);

            this.FULL_TREE_DATA.forEach(group => {
              group.children.forEach(reason => {
                if (preselectedIds.indexOf(reason.id) > -1) {
                  this.selection.select(reason);
                }
              });
            });
          }
        }
      }
    );
  }

  saveAssignment() {
    if (this.data.offerPropositionId) {
      this.cancellationReasonService
        .saveAssignments(this.data.offerPropositionId, this.selection.selected.map(it => it.id)).subscribe(
          re => {
            if (re.data.createCancellationReasonAssignment.ok) {
              this.dialogRef.close();
            }
          }
      );
    } else {
      this.dialogRef.close(this.selection.selected.map(it => it.id));
    }
  }
}




