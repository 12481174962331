import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { FilterInterface } from '../../shared/filter-bar/filter-bar.component';
import { CandidatesService } from '../candidates.service';
import { CandidatesTableDatasource } from './candidates-table-datasource';
import { EventResolutionStatus } from '../../../generated/graphql';
import { CommentDialogComponent } from '../../interview-widget/comment-dialog/comment-dialog.component';

@Component({
  selector: 'app-candidates-table',
  templateUrl: './candidates-table.component.html',
  styleUrls: ['./candidates-table.component.scss']
})
export class CandidatesTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  moment = moment;
  dataSource: CandidatesTableDatasource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['manager', 'client', 'candidate', 'interviewEvent', 'healthcheckEvent', 'employmentEvent', 'comment'];

  @Input() set filters(value: FilterInterface|null) {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.dataSource?.filters.next(value);
  }

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private candidatesService: CandidatesService
  ) {}

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.dataSource = new CandidatesTableDatasource(this.candidatesService);
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  getEventStatus(input: string): string {
    switch (input) {
      case EventResolutionStatus.Failed: return 'fail';
      case EventResolutionStatus.Cancelled: return 'fail';
      case EventResolutionStatus.Rejected: return 'fail';
      case EventResolutionStatus.Complete: return 'success';
      default: return '';
    }
  }

  getCommentsTooltip(node: any): string {
    return node.comments?.reduce((acc, comment, idx) => acc + `\n\n#${idx + 1}  ${comment.text};`, '') || '';
  }

  openCommentDialog($event, node) {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      panelClass: ['padding-16-dialog', 'width-650-dialog'],
      data: {
        propositionID: node.id
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.filters.next(this.dataSource.filters.value);
    });
  }
}
