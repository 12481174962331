{{'ORDER.WORKER' | translate}}: {{user && user['name']}} {{user && user['surname']}}

<div class="upload">
    <div class="drop-container"
         ngFileDrop
         [options]="options"
         (uploadOutput)="onUploadOutput($event)"
         [uploadInput]="uploadInput"
         [ngClass]="{ 'is-drop-over': dragOver }"
         fxLayoutAlign="center center">
      <label class="mat-h2">
        <input ngFileSelect
               type="file"
               class="file-selector"
               accept="image/*,application/pdf"
             [options]="options"
             (uploadOutput)="onUploadOutput($event)"
             [uploadInput]="uploadInput">

        <div style="padding: 10px;">{{'UPLOAD_A_PHOTO_OR_PDF' | translate}}</div>
        <div><mat-icon icon="upload" class="download-icon"></mat-icon></div>
      </label>

    </div>
</div>

  <div style="padding: 8px">
    <h5>{{docTypes.medical}}</h5>

    <div fxLayout="row wrap" fxLayoutGap="4px">
      <div *ngFor="let img of imagesGroups[docTypes.medical]">
        <app-image-with-error [document]="img.imageUrl" (click)="openPreview(img.imageUrl)"></app-image-with-error>
      </div>
    </div>
  </div>

  <div style="padding: 8px" *ngIf="imagesGroups[docTypes.hygiene]">
    <h5>{{docTypes.hygiene}}</h5>

    <div fxLayout="row wrap" fxLayoutGap="4px">
      <div *ngFor="let img of imagesGroups[docTypes.hygiene]">
        <app-image-with-error [document]="img.imageUrl" (click)="openPreview(img.imageUrl)"></app-image-with-error>
      </div>
    </div>
  </div>

  <div style="padding: 8px">
    <h5>{{docTypes.other}}</h5>
    <div fxLayout="row wrap" fxLayoutGap="4px">
      <div *ngFor="let img of imagesGroups[docTypes.other]">

        <app-image-with-error [document]="img.imageUrl" (click)="openPreview(img.imageUrl)"></app-image-with-error>
  <!--      <img [src]="img.imageUrl" class="preview-img" (click)="openPreview(img.imageUrl)">-->
      </div>
    </div>
  </div>


