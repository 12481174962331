import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import moment from "moment";
import { GroupByPipe } from "ngx-pipes";
import { BehaviorSubject, merge, Observable, Subscription } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { GetPlannerEventsByCompanyGQL, GetPlannerEventsByOrderGQL, MarketingEventsFieldsFragment } from "src/generated/graphql";
import { cloneDeep } from "@apollo/client/utilities";

export interface CompanyAdsTableItem extends MarketingEventsFieldsFragment {
    transportation?: string;
}

export class CompanyAdsTableDataSource extends DataSource<CompanyAdsTableItem> {

    data = new BehaviorSubject<CompanyAdsTableItem[]>([]);
    paginator: MatPaginator;
    filters = new BehaviorSubject<{start?: string, end?: string}>({});
    sort: MatSort;
    loading = new BehaviorSubject<boolean>(true);

    endCursor: string;
    totalCount: number;

    connection: Subscription;

    constructor(
        private groupBy: GroupByPipe,
        private getPlannerEventsByCompanyGQL: GetPlannerEventsByCompanyGQL,
        private getPlannerEventsByOrderGQL: GetPlannerEventsByOrderGQL,
        private companyId,
        private orderId,
    ) {
        super();
    }

    /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
    connect(): Observable<CompanyAdsTableItem[]> {
        const dataMutations = [
            this.filters.asObservable(),
            this.paginator.page,
            this.sort.sortChange
        ];

        this.sort.active = 'date';
        this.sort.direction = 'desc'

        const self = this;

        this.connection = merge(...dataMutations)
            .pipe(tap(it => self.loading.next(true)))
            .pipe(debounceTime(800))
            .pipe(switchMap(() => {
                const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
console.log(this.sort)
                let queryParams: any = {
                    date_Lte: this.filters.value?.end,
                    date_Gte: this.filters.value?.start,
                    first: this.paginator.pageSize,
                    after: endCursor,
                    ordering: this.sort.active === 'date' ? this.sort.direction === 'desc' ? '-date' : 'date' : null
                };

                if (this.companyId) {
                    queryParams = {...queryParams, order_Company: this.companyId}
                    const gql = this.getPlannerEventsByCompanyGQL.watch(
                        queryParams,
                        { fetchPolicy: 'cache-and-network' }
                    );
                    return gql.valueChanges.pipe(map(d => cloneDeep(d)));
                }
                if (this.orderId) {
                    queryParams = {...queryParams, orderId: this.orderId}
                    const gql = this.getPlannerEventsByOrderGQL.watch(
                        queryParams,
                        { fetchPolicy: 'cache-and-network' }
                    );
                    return gql.valueChanges.pipe(map(d => cloneDeep(d)));
                }

            })).subscribe(
                r => {

                    if (r.data) {
                        const data = JSON.parse(JSON.stringify(r.data.plannerEvents.edges)).map(it => it.node);
                        this.data.next(
                            this.getSortedData(data)
                        );
                        this.totalCount = r.data.plannerEvents.totalCount;
                        this.endCursor = r.data.plannerEvents.pageInfo.endCursor;
                    }
                    this.loading.next(r.loading);
                }
            );

        return this.data.asObservable();
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect() {
        this.filters.complete();
        if (this.connection && this.connection?.unsubscribe) { this.connection?.unsubscribe?.() }
    }

    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getPagedData(data: CompanyAdsTableItem[]) {
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: CompanyAdsTableItem[]) {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
            switch (this.sort.active) {
                case 'date': return compare(new Date(a.date).getTime(), new Date(b.date).getTime(), isAsc);
                default: return 0;
            }
        });
    }

    private parseOrder(data: CompanyAdsTableItem[]) {
        const fv = data[0];
        return data;
    }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
