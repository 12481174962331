
<mat-card *ngIf="company" [ngClass]="company.color">
  <mat-card-title fxLayout="row" fxLayoutAlign="row center">
    <h3>
      {{company.name}}
    </h3>
    <button mat-icon-button [matTooltip]="getCovidTooltip(company)" (click)="changeLabels()">
      <mat-icon>coronavirus</mat-icon>
    </button>
  </mat-card-title>

  <div fxLayoutGap="12px">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center end">
      <div fxFlex="25">
        <app-company-color-switch
        [color]="company.color" [companyId]="company.id" (colorChanged)="company.color=$event" >

        </app-company-color-switch>
        <mat-icon (click)="openColorHistory($event)">info</mat-icon>
      </div>

      <mat-form-field fxFlex>
        <mat-label>{{ 'COMPANY_DESCRIPTION' | translate }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="company.description"
          (ngModelChange)="onDescriptionChange($event)"
          (focusout)="updateCompanyDescription()"
        ></textarea>
      </mat-form-field>
    </div>
  </div>

  <div>
    <nav mat-tab-nav-bar>
      <a mat-tab-link routerLink="./orders" routerLinkActive #ord="routerLinkActive" [active]="ord.isActive">{{'ORDERS' | translate}}</a>
      <a mat-tab-link routerLink="./ads" routerLinkActive #ads="routerLinkActive" [active]="ads.isActive">{{'MARKETING' | translate}}</a>
      <a mat-tab-link routerLink="./efforts" routerLinkActive #eff="routerLinkActive" [active]="eff.isActive">{{'EFFORTS' | translate}}</a>
    </nav>
  </div>
  <router-outlet></router-outlet>
</mat-card>



