<div class="container" fxLayout="row">
  <div class="section" fxLayout="column" fxLayoutAlign="start center">
    <p class="title">{{ 'ORDER.GOODNESS.CONVERSION' | translate }}</p>
    <mat-icon [class.warning]="data.warningMap.conversions">{{ data.warningMap.conversions ? 'report' : 'check_circle_outline' }}</mat-icon>

    <div class="description">
      <div class="first-metrics">
        <span>{{ 'ORDER.ORDER' | translate }}</span>:
        <span [innerHTML]="'SUCCESSFUL_COUNT' | translate: {
            firstValue: 1,
            secondValue: data.order.ordermetrics?.effortConversion ? (1 / data.order.ordermetrics?.effortConversion).toFixed(0) : 0
          }"
        ></span>
      </div>

      <div class="first-metrics">
        <span>{{ 'CLIENT.REGION' | translate }}</span>:
        <span [innerHTML]="'SUCCESSFUL_COUNT' | translate: {
            firstValue: 1,
            secondValue: data.order.region?.regionmetrics?.effortConversion ? (1 / data.order.region?.regionmetrics?.effortConversion).toFixed(0) : 0
          }"
        ></span>
      </div>

      <div class="delimiter" (click)="conversionsDesc.classList.toggle('visible')">{{ 'MORE_INFO' | translate }}</div>
    </div>

    <div class="second-metrics" #conversionsDesc [innerHTML]="'ORDER_CONVERSIONS_POPUP_DESC' | translate: {
        orderValue: data.order.ordermetrics?.effortConversion ? (1 / data.order.ordermetrics?.effortConversion).toFixed(0) : 0,
        regionValue: data.order.region?.regionmetrics?.effortConversion ? (1 / data.order.region?.regionmetrics?.effortConversion).toFixed(0) : 0
      }"
    ></div>
  </div>

  <div class="section" fxLayout="column" fxLayoutAlign="start center">
    <p class="title">{{ 'ORDER.OFFERS' | translate }}</p>
    <mat-icon [class.warning]="data.warningMap.offers">{{ data.warningMap.offers ? 'report' : 'check_circle_outline' }}</mat-icon>

    <div class="description">
      <div class='first-metrics'
           [innerHTML]="'CURRENT_TOTAL_OFFERS' | translate: {count: data.order.ordermetrics?.totalCalls || 0 }"></div>

      <div class="first-metrics">{{ 'MIN_EXPECTED_OFFERS' | translate }}: <span class="bold-metrics">{{ data.order.region?.regionthresholdsettings?.minOffersExpected || 0 }}</span></div>

      <div class="delimiter" (click)="offersDesc.classList.toggle('visible')">{{ 'MORE_INFO' | translate }}</div>
    </div>

    <div class="second-metrics" #offersDesc [innerHTML]="'ORDER_OFFERS_POPUP_DESC' | translate: {
        current: data.order.ordermetrics?.totalCalls || 0,
        shouldBe: data.order.region?.regionthresholdsettings?.minOffersExpected || 0
      }"
    ></div>
  </div>

  <div class="section" fxLayout="column" fxLayoutAlign="start center">
    <p class="title">{{ 'ORDER.GOODNESS.LIFETIME' | translate }}</p>
    <mat-icon [class.warning]="data.warningMap.lifetime">{{ data.warningMap.lifetime ? 'report' : 'check_circle_outline' }}</mat-icon>

    <div class='description'>
      <div class='first-metrics' [innerHTML]=" 'ORDER_LIFETIME' | translate :{
            lifetime:  data.order.ordermetrics?.lifetime || 0
          }">
      </div>

      <div class='first-metrics' [innerHTML]=" 'REGION_LIFETIME' | translate :{
            lifetime:  data.order?.region?.regionmetrics?.lifetime || 0
          }">
      </div>

      <div class='delimiter' (click)="lifetimeDesc.classList.toggle('visible')">{{ 'MORE_INFO' | translate }}</div>
    </div>

    <div class="second-metrics" #lifetimeDesc [innerHTML]="'ORDER_LIFETIME_POPUP_DESC' | translate: {
        orderLifetime: data.order.ordermetrics?.lifetime || 0,
        regionLifetime: data.order.region?.regionmetrics?.lifetime || 0
      }"
    ></div>
  </div>
</div>
