import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { BaseOrderFieldsFragment, OrderPeriodFieldsFragment } from '../../../../generated/graphql';
import { OrderTypeEnum } from 'src/app/shared/types/order-types';
import { DeleteOrderPeriodGQL } from '../../../graphql/graphql';

@Component({
  selector: 'app-client-need',
  templateUrl: './client-need.component.html',
  styleUrls: ['./client-need.component.scss']
})
export class ClientNeedComponent implements OnInit {
  periods: OrderPeriodFieldsFragment[] = [];
  private $form: any;
  @Input() set form(value: FormGroup) {
    this.$form = value;
  }
  get form() { return this.$form; }

  private $orderType: OrderTypeEnum;
  @Input() set orderType(value: OrderTypeEnum) {
    this.$orderType = value;
  }
  get orderType() { return this.$orderType; }

  orderTypes = OrderTypeEnum;

  get formPeriods() {
    return this.form && this.form.get('periods');
  }
  get salaryMatrix() { return this.$form.get('salaryMatrix'); }

  @Input() countryISO: string;

  editOrderId$: string;
  @Input()
  set editOrderId(value: string) {
    if (value) {
      this.editOrderId$ = value;
    }
  }
  get editOrderId() {
    return this.editOrderId$;
  }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (val?.orderperiodSet?.length) {
      this.periods = [...val.orderperiodSet];
    }
    if (this.formPeriods) {
      this.formPeriods.setValue(this.periods);
    }
    this.calculateShortage();
  };
  get order(){
    return this.$order;
  }
  constructor(
    private deletePeriodGQL: DeleteOrderPeriodGQL
  ) { }

  ngOnInit(): void {
    this.setPeriod();
    this.calculateShortage();
  }

  setPeriod() {

    const addMonth = moment().date() > 15 ? 1 : 0;


    const nextMonthStart = moment().set('date', 1).add( addMonth, 'month');
    const nextMonthEnd = moment().set('date', 1).add( addMonth + 1, 'month').subtract(1, 'days');

    if (this.periods.length === 0 && !this.editOrderId) {
      this.periods.push({
        id: null,
        dateFrom: nextMonthStart.format('YYYY-MM-DD'),
        dateTo: nextMonthEnd.format('YYYY-MM-DD'),
        demand: 1,
        longTerm: false
      });
    }
  }

  onRemove(periodToDelete: OrderPeriodFieldsFragment) {
    this.periods = this.periods.filter(e => e !== periodToDelete);
    if (periodToDelete.id) {
      this.deletePeriodGQL.mutate({orderPeriod: periodToDelete.id}).subscribe(
        resp => console.log(resp)
      );
    }
    if (this.formPeriods) {
      this.formPeriods.setValue(this.periods);
    }
    this.calculateShortage();
  }

  addPeriod() {
    this.periods.push({
      id: null,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add(30, 'days').format('YYYY-MM-DD'),
      demand: 1,
      longTerm: false
    });
    this.formPeriods.setValue(this.periods);
    this.calculateShortage();
  }

  onPeriodChange() {
    this.formPeriods.setValue(this.periods);
    this.calculateShortage();
  }

  calculateShortage() {
    if (!this.editOrderId) {
      this.form.get('shortage')?.setValue(this.periods.map(p => p.demand || 0).reduce((a, b) => a + b, 0));
    }
  }
}
