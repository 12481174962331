import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {OrderListDataSource} from './order-list-datasource';
import {Order, UpdateOrderGQL} from '../../graphql/graphql';
import {OrdersService} from '../orders.service';
import {OrderStatusChangeGQL} from '../graphql/graphql';
import {NotificationService} from '../../shared/notification.service';
// import {OrderStatuses} from '../types/order-statuses';
import {FilterInterface} from '../../shared/filter-bar/filter-bar.component';
import {OrderTypeEnum} from '../../shared/types/order-types';
import {UnseenOrdersService} from '../unseen-orders.service';
import {RelayIdService} from '../../shared/relay-id.service';
import {CancelOrderDialogComponent} from './cancel-order-dialog/cancel-order-dialog.component';
import {first} from 'rxjs/operators';
import {
  CandidateEventType, EventEventType,
  GetPaginatedOrderForTableGQL,
  OrderCandidateStatsGQL,
  OrderPriority,
  OrdersList2FieldsFragment, OrderStatus, OrderType,
  SubmitOrder2GQL, UploadOrderForSigningGQL
} from '../../../generated/graphql';
import {OrdersCommentsDialogComponent} from '../../order/orders-comments-dialog/orders-comments-dialog.component';
import {SelectPersonDialogComponent} from '../select-person-dialog/select-person-dialog.component';
import { OrderCandidateComponent } from './order-candidate/order-candidate.component';
import {OrderActionsLogService} from '../order-actions-log/order-actions-log.service';
import { OrderTrafficGeneration } from 'src/app/shared/types/order-traffic-generation';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {FiledDownloaderService} from '../../shared/filed-downloader.service';
import { UserprofileService } from '../../shared/userprofile.service';
import { MetricsPopupComponent, WarningMap } from './metrics-popup/metrics-popup.component';
import { Moment } from 'moment/moment';


export enum OrderListModes {
  standalone = 'STANDALONE',
  dashboard = 'DASHBOARD'
}

// export enum EventType {
//   toBeInterviewed = 'toBeInterviewed',
//   toBeInterviewedClient = 'toBeInterviewedClient',
//   toBeEmployed = 'toBeEmployed'
// }


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class OrderListComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OrdersList2FieldsFragment>;

  private $companyId: number;
  @Input() set companyId(value: string) {
    this.$companyId = this.relayIdService.parseId(value);
  }
  eventTypes = EventEventType;
  priorities = OrderPriority;
  dataSource: OrderListDataSource;
  orderTypes = OrderTypeEnum;
  orderListViewModes = OrderListModes;
  statuses = OrderStatus;
  orderStats = {};
  displayedColumns = ['id', 'orderType', 'trafficGeneration', 'positionTitle', 'company', 'periodFrom', 'hourRate', 'status', 'shortage', 'actions'];

  mode = OrderListModes.standalone;
  unseenMessages;
  unseenMessagesSectionActive = false;
  @Input() set viewMode(value: OrderListModes) {
    this.mode = value;
    if (this.mode === OrderListModes.dashboard ) {
      this.displayedColumns =  ['orderType', 'company', 'priority',  'specification', 'periodFrom' , 'hourRate',
        'stats', 'alert', 'comment', 'responsible', 'lastUpdated', 'status'];
    }
  }
  @Input() showSelectionStatusFilter = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */

  editFieldMap: any = {
    shortage: {},
    trafficGeneration: {}
  };

  showEditShortageIcon: any = {};
  shortageCtl: any = {};

  showEditTrafficGenerationIcon: any = {};
  trafficGenerationCtl: any = {};

  trafficGenerationOptions = OrderTrafficGeneration;
  trafficGenerationOptionsDisplayMap: any = {};

  constructor(
    private ordersGQL: GetPaginatedOrderForTableGQL,
    private orderStatusChangeGQL: OrderStatusChangeGQL,
    private orderSendGQL: SubmitOrder2GQL,
    private uploadOrderForSigningGQL: UploadOrderForSigningGQL,
    private ordersSevice: OrdersService,
    private notifications: NotificationService,
    private unseenOrdersService: UnseenOrdersService,
    private relayIdService: RelayIdService,
    private dialog: MatDialog,
    private updateOrderGQL: UpdateOrderGQL,
    private changeDetectionRef: ChangeDetectorRef,
    private orderStatsGql: OrderCandidateStatsGQL,
    public orderActionLogService: OrderActionsLogService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.dataSource = new OrderListDataSource(this.ordersGQL, this.companyId);
    this.unseenOrdersService.getSubject().subscribe(res => {
      this.unseenMessages = res.unseenAccepted + res.unseenRejected;
    });

    this.dataSource.orderIds.subscribe(
      ids => {
        if (ids && ids.length && this.displayedColumns.indexOf('stats') > -1) {
          this.orderStatsGql.watch({
            orderIds: ids,
            dateFrom: (new Date()).toISOString().substr(0, 10)
          }, {fetchPolicy: "cache-and-network"}).valueChanges.subscribe(
            newStats => {
              newStats.data.candidateOrderStats.forEach(
                item => {
                  this.orderStats[item.orderId] = item;
                }
              );
            }
          );
        }
      }
    );

    this.trafficGenerationOptionsDisplayMap[this.trafficGenerationOptions.inactive] = 'ORDER.TRAFFIC_GENERATION_OPTIONS.DO_NOT_GENERATE_TRAFFIC';
    this.trafficGenerationOptionsDisplayMap[this.trafficGenerationOptions.activeNoMarketing] = 'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_WITHOUT_MARKETING';
    this.trafficGenerationOptionsDisplayMap[this.trafficGenerationOptions.activeWithMarketing] = 'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_AND_RUN_MARKETING_CAMPAIGNS';
    this.trafficGenerationOptionsDisplayMap[this.trafficGenerationOptions.marketingOnly] = 'ORDER.TRAFFIC_GENERATION_OPTIONS.MARKETING_ONLY';
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  openInterviewDialog(order: Order, eventType, $event) {
    $event.stopPropagation();
    this.dialog.open(OrderCandidateComponent,
      {
        width: '900px',
        data: {
          orderId: order.id,
          eventType,
          country: order.company?.country?.isoCode
        }

      }
    );
  }

  openInterviewDialogForOrdersWithinLast7Days(order: Order, eventType, $event) {
    $event.stopPropagation();
    this.dialog.open(OrderCandidateComponent,
      {
        width: '900px',
        data: {
          orderId: order.id,
          eventType: 'offeredInLast7Days',
          hideFilters: true,
          filters: {
            dateRange: {
              dateFrom: moment().subtract(7, 'days'),
              dateTo: moment()
            }
          } as FilterInterface,
          country: order.company?.country?.isoCode
        }
      }
    );
  }

  downloadOrder(orderId, positionTitle) {
    this.ordersSevice.downloadContract(this.relayIdService.parseId(orderId)).subscribe(
      r => this.downloadPDF(r, `${positionTitle} - ${this.relayIdService.parseId(orderId)}`),
      err => {
        err.error.text().then(res => {
          this.notifications.notify(err.status == 400 && res ? res : this.translate.instant('COMMON.ERRORS.GENERAL'), 1);
        });
      }
    );
  }

  downloadPDF(data: any, fileName?: string) {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName ? `${fileName}.pdf` : 'order.pdf';
    link.click();
  }


  sendOrder(order: Order) {
    this.orderSendGQL.mutate({input: this.relayIdService.parseId(order.id)}).subscribe(
      resp => {
        if (resp.data.submitOrder.ok) {
          order.status = OrderStatus.Sent;
          this.notifications.notify('EMAIL_SENT');
        } else {
          this.notifications.notify(`Error: ${resp.data}`);
        }
      },
      err => this.notifications.notify(`Error: ${err}`)
    );
  }

  markOrderAsSent(order: Order) {
    this.orderStatusChangeGQL.mutate({id: this.parseOrderId(order.id), transition: 'send'}).subscribe(
      resp => {
        if (resp.data.changeOrderStatus.ok) {
          order.status = OrderStatus.Sent;
          this.notifications.notify('Updated successfully');

        } else {
          this.notifications.notify(`Error: ${resp.data}`);
        }
      }
    );
  }


  // onSendButtonClick(order: Order)




  confirmOrder(order: any) {
    this.orderStatusChangeGQL.mutate({id: this.parseOrderId(order.id), transition: 'confirm'}).subscribe(
      resp => {
        if (resp.data.changeOrderStatus.ok) {
          order.status = OrderStatus.Confirmed;
          this.notifications.notify('Updated successfully');

        } else {
          this.notifications.notify(`Error:f ${resp.data}`);
        }
      }
    );
  }

  restoreOrder(order) {
    this.orderStatusChangeGQL.mutate(
      {id: this.parseOrderId(order.id), transition: 'restore'},
      {refetchQueries: ['getPaginatedOrderForTable']}
    ).subscribe(
      resp => {
        if (resp.data.changeOrderStatus.ok) {
          // order.status = OrderStatus.New;
          this.notifications.notify('Užsakymas atstatytas');

        } else {
          this.notifications.notify(`Error: ${resp.data}`);
        }
      }
    );
  }

  onFilterChanges(event: FilterInterface) {

    const filters = {};
    if (event.search) { filters['search'] = event.search; }
    if (event.region) { filters['region'] = event.region.join(','); }
    if (event.orderType) { filters['orderType'] = event.orderType; }
    if (event.statuses) { filters['status'] = event.statuses.join(',')  ; }
    if (event.selectionStatuses) { filters['trafficGeneration'] = event.selectionStatuses.join(',')  ; }
    if (event.keyAccountManager) { filters['keyAccountManager'] = event.keyAccountManager; }
    if (event.covidPolicy) { filters['covidPolicy'] = event.covidPolicy; }
    if (event.country) { filters['country'] = event.country; }
    if (this.$companyId) { filters['companies'] = this.$companyId; }
    this.dataSource.filters.next(filters);
    this.paginator?.firstPage();
  }

  showConfirmedRejected() {
    if (!this.unseenMessagesSectionActive) {
      const filters = this.dataSource.filters.value;
      filters['status'] = `${OrderStatus.Confirmed},${OrderStatus.Rejected}`;
      filters.unseenProcessedOrders = true;

      this.dataSource.filters.next(filters);
      const sub = this.dataSource.loadingCompleteSubject.subscribe(res => {
        this.unseenMessagesSectionActive = true;
        this.unseenOrdersService.sendReviewedDate().subscribe(res2 => {
          this.unseenOrdersService.emitData();
          if (sub && sub?.unsubscribe) {
            sub?.unsubscribe?.();
          }
        });
      });
    } else {
      const filters = this.dataSource.filters.value;
      filters['status'] = `${OrderStatus.New}`;
      filters.unseenProcessedOrders = false;
      this.dataSource.filters.next(filters);
      const sub = this.dataSource.loadingCompleteSubject.subscribe(res => {
        this.unseenMessagesSectionActive = false;
        if (sub && sub?.unsubscribe) {
          sub?.unsubscribe?.();
        }
      });
    }
  }

  parseOrderId(id: string) {
    return atob(id).split(':')[1];
  }

  openNew(s: string) {

    window.open( 'orders/edit/' + this.parseOrderId(s) , '_blank');
  }

  completeOrder(order: Order) {
    this.makeOrderStatusTransition(order, 'complete', OrderStatus.Completed);
  }

  makeOrderFilled(order: Order) {
    this.makeOrderStatusTransition(order, 'fill', OrderStatus.Filled);
  }

  cancelOrder(order: Order) {
    const dialogRef = this.dialog.open(CancelOrderDialogComponent);
    dialogRef.afterClosed().pipe(first()).subscribe(
      resp => {

        if (resp === 'cancel') {
          this.makeOrderStatusTransition(order, 'cancel', OrderStatus.Cancelled);
        }
      }
    );
  }

  private makeOrderStatusTransition(order: Order, transition: string, expectedStatus: OrderStatus) {
    this.orderStatusChangeGQL.mutate({id: this.parseOrderId(order.id), transition}).subscribe(
      resp => {
        if (resp.data.changeOrderStatus.ok) {
          order.status = expectedStatus;
          this.notifications.notify('Updated successfully');
        }
        // } else {
        //   this.notifications.notify(`Error: ${resp.data}`);
        // }
      }
    );
  }

  convertToNew(order: Order) {
    this.makeOrderStatusTransition(order, 'convert_to_new', OrderStatus.New);
  }

  addComment(order: OrdersList2FieldsFragment) {
    const commentDialog = this.dialog.open(OrdersCommentsDialogComponent, {
      data: {
        orderId: order.id
      }
    });
    commentDialog.afterClosed().subscribe(
      value => {
        if (value && value.length ){
          order.ordercommentSet.edges = value;
          this.dataSource.data.next(this.dataSource.data.value);
        }

      }
    );
  }

  changeUserprofile(order, field) {
    const dialogRef = this.dialog.open(SelectPersonDialogComponent);
    const oid = atob(order.id).split(':')[1];
    dialogRef.afterClosed().subscribe(
      val => {
        this.updateOrderGQL.mutate({
          id: oid,
          order: {[field]: val}
        }, {refetchQueries: ['getPaginatedOrderForTable']}).subscribe(
          r => {
            order.recruiter = r.data.updateOrder.order.recruiter;
            this.dataSource.data.next(this.dataSource.data.value);
            // this.changeDetectionRef.markForCheck();
          }
        );
      }
    );
  }

  updateOrderPriority(order: any, priority: OrderPriority) {
    let newPriority = order.priority === OrderPriority.High ? OrderPriority.Normal : OrderPriority.High;
    if (order.priority === priority) {
      newPriority = OrderPriority.Normal;
    } else {
      newPriority = priority;
    }

    this.updateOrderGQL.mutate(
      { id: this.parseOrderId(order.id), order: { priority: newPriority.toLowerCase() } },
      {
        refetchQueries: ['getPaginatedOrderForTable']
      }
    ).subscribe(val => {
    });
  }

  toggleEditTrafficGeneration(order) {
    this.editFieldMap['trafficGeneration'][order.id] = true;
    this.trafficGenerationCtl[order.id] = new FormControl(order.trafficGeneration, Validators.required);
  }

  toggleEditShortage(order) {
    this.editFieldMap['shortage'][order.id] = true;
    this.shortageCtl[order.id] = new FormControl(order.shortage, Validators.required);
  }

  changeField(order, field: string, value: number) {
    this.editFieldMap[field][order.id] = false;
    const oid = atob(order.id).split(':')[1];
    if(value || value === 0) {
      this.updateOrderGQL.mutate({
        id: oid,
        order: {[field]: value}
      }, {refetchQueries: ['getPaginatedOrderForTable']}).subscribe(
        r => {
          order[field] = r.data.updateOrder.order[field];
          this.dataSource.data.next(this.dataSource.data.value);
          this.notifications.notify('Updated successfully');
        });
    }
  }

  getCompanyLabelsText(edges: any) {
    return edges.map(it => it.node?.title).join(', ');
  }

  uploadForSigning(order: Order) {
    this.uploadOrderForSigningGQL.mutate({id: order.id}, {refetchQueries: ['getPaginatedOrderForTable']}).subscribe(
      resp => {
        if (resp.data.initOrderSigning.ok) {
          order.status = resp.data.initOrderSigning.order.status;

          this.notifications.notify('UPLOADING_ORDER');

          setTimeout(() => {this.dataSource.refetch();}, 3000);
        } else {
          this.notifications.notify(`Error: ${resp.data}`);
        }
      },
      err => this.notifications.notify(`Error: ${err}`)
    );
  }

  getPresentedClientTooltip(id: any) {
    const stats = this.orderStats[id];
    if (stats) {
      return this.translate.instant('PRESENTED_CLIENT_TOOLTIP', {
        total: stats.presentedClient || 0,
        success: stats.presentedClientSuccess || 0,
        failed: stats.presentedClientFailed || 0
      });
    }
    return undefined;
  }

  makeOrderLost(order) {
    this.makeOrderStatusTransition(order, 'lose', OrderStatus.Lost);
  }

  getWarningMap(order: OrderType): WarningMap {
    return {
      conversions: (order.ordermetrics?.effortConversion || 0) < (order.region?.regionmetrics?.effortConversion || 0),
      offers: (order.ordermetrics?.totalCalls || 0) < (order.region?.regionthresholdsettings?.minOffersExpected || 0),
      lifetime: (order.ordermetrics?.lifetime || 0) < (order.region?.regionmetrics?.lifetime)
    };
  }

  isOrderMetricsWarning(order: OrderType): boolean {
    const warningMap = this.getWarningMap(order);
    return Object.keys(warningMap).filter(key => warningMap[key]).length > 0;
  }

  showOrderMetrics(event, order) {
    this.dialog.open(MetricsPopupComponent, {
      data: { order, warningMap: this.getWarningMap(order) }
    });
  }

  getOrderLastClientContacted(order) {
    return order.orderclientcontactedSet?.edges
      .map(edge => edge.node)
      .sort((a, b) => moment(b.date).diff(moment(a.date)))[0];
  }

  onLastUpdatedChange(date: Moment, order, orderIdx) {
    this.ordersSevice.createOrderClientContacted(order.id, date.format('YYYY-MM-DD')).subscribe(
      res => {

        order.orderclientcontactedSet.edges = [
          ...this.dataSource.data.value[orderIdx].orderclientcontactedSet.edges,
          { node: res.data.createOrderClientContacted.orderClientContacted }
        ];
        this.dataSource.data.next(this.dataSource.data.value);
      },
      err => { }
    );
  }
}
