import {COMPANY_COLORS, Contact} from './graphql';
import {Injectable} from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';


export interface ColorHistoryChange {
  color: COMPANY_COLORS;
  historyDate: Date;
  historyUser: {
    email: string;
  };
}

export interface CompanyDetailInterface {
  id: string;
  name: string;
  description: string;
  livasId: number;
  insightlyId: number;
  color: COMPANY_COLORS;
  labels: {edges: {node: {id: string, title: string}}[]},
  contactSet: Contact[];
  historyChanges: ColorHistoryChange[];
}


@Injectable({
  providedIn: 'root',
})
export class CompanyDetailGQL extends Query<{
  company: CompanyDetailInterface
}> {
  document = gql`
    query company($id: ID!) {
      company(id: $id) {
        id
        name
        description
        color
        livasId
        insightlyId
        historyChanges {
          color
          historyDate
          historyUser {
            id
            email
          }
        }
        contactSet {
          edges {
            node {
              id
              firstName
              lastName
              email
              phone
              insightlyId
            }
          }
        }
        labels {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  `;
}
