<div class="mat-elevation-z2 d">
  <div class="interview-pill" *ngIf="clientInterview">
    <mat-hint>
      {{ 'INTERVIEW_PLANNED_FOR_CLIENT' | translate }}
      <b>{{ clientInterview.relatedOffer?.order.company.name }}</b> at
      <b>{{ clientInterview.startDatetime | date: 'yyyy-MM-dd HH:mm' }}</b>
    </mat-hint>
  </div>

  <div class="filter-container" [class.with-tip]="clientInterview">
    <mat-form-field class="filter">
      <button
        *ngIf="filterValue"
        mat-button
        matSuffix
        mat-icon-button
        type="button"
        (click)="filterValue = ''; filterTable()"
      >
        <mat-icon matSuffix>close</mat-icon>
      </button>
      <mat-icon matSuffix>search</mat-icon>
      <input
        matInput
        placeholder="{{ 'COMMON.SEARCH' | translate }}"
        [(ngModel)]="filterValue"
        (ngModelChange)="filterTable()"

      />
    </mat-form-field>
  </div>
  <mat-progress-bar mode='indeterminate' *ngIf='loading'></mat-progress-bar>
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'WORK' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [ngClass]="row.color"
        class="company-title"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <a
            href="/free-vacancies/list/?orderId={{ row.id }}"
            target="_blank"
            class="grey-icon"
            [matTooltip]="getId(row.id)"
          >
            <mat-icon class="rem-08">open_in_new</mat-icon>
          </a>
          <div fxLayout="column">
            <span>{{ row.title }}</span>
            <span class="company-name">{{ row.companyName }} </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="offerCall">
      <th mat-header-cell *matHeaderCellDef >
        {{ 'OFFER.OFFER_ON_CALL' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="0">
          <button
            mat-icon-button
            (click)="setCallAnswer(row, offerPropositionInterestedOnCall.Interested)"
          >
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'green-icon': row.interestedOnCall === offerPropositionInterestedOnCall.Interested
              }"
              [matTooltip]="'OFFER.POSITION_OFFERED_DOMINA' | translate"
              >check_circle_outline</mat-icon
            >
          </button>

          <button
            mat-icon-button
            (click)="setAnswerWithCancellationReasons(row, offerPropositionInterestedOnCall.NotInterested, true)"
          >
            <mat-icon [ngClass]="{
                        'grey-icon': true,
                        'red-icon': row.interestedOnCall === offerPropositionInterestedOnCall.NotInterested
                      }"
                      [matTooltip]="'OFFER.POSITION_OFFERED_NEDOMINA' | translate"
            >highlight_off</mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="setAnswerWithCancellationReasons(row, offerPropositionInterestedOnCall.Undecided, true)"
          >
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'blue-icon': row.interestedOnCall === offerPropositionInterestedOnCall.Undecided
              }"
              [matTooltip]="'OFFER.CANDIDATE_INTERESTED_REQUIREMENTS_NOT_MET' | translate"
              >not_interested</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="client-interview">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'CUSTOMER_INTERVIEW' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px">
          <button
            mat-icon-button
            (click)="scheduleEvent(row, $event, 'INTERVIEW')"
            [disabled]="row.interestedOnCall !== offerPropositionInterestedOnCall.Interested"
          >
            <mat-icon
              [style.color]="isCancelled(row.candidateEvent, 'INTERVIEW')"
              [matTooltip]="'OFFER.SCEHDULE_AN_INTERVIEW' | translate"
            >
              insert_invitation</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="interview">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'CUSTOMER_INTERVIEW' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px">
          <button
            mat-icon-button
            (click)="scheduleEvent(row, $event, 'CLIENT_INTERVIEW')"
            [disabled]="
              row.interested === 'NOT_INTERESTED' ||
              row.interested === null ||
              row.interested === undefined
            "
          >
            <mat-icon
              [matTooltip]="'OFFER.SCEHDULE_AN_INTERVIEW' | translate"
              [style.color]="isCancelled(row.candidateEvent, 'CLIENT_INTERVIEW')"
            >
              schedule
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>{{ 'PROGRESS.TITLE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout='row' fxLayoutAlign='start center'>
          <div>
            <app-progress-component [countryIso]='$country' [events]='row'
                                    [personId]='personId'></app-progress-component>
          </div>
          <!--- Show icon if cancellationreasonsSet is not empty --->
          <app-cancellation-reasons-icon-with-tooltip
            [cancellationReasonsAssignments]='row.cancellationreasonassignmentSet'>
          </app-cancellation-reasons-icon-with-tooltip>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="offerOnCallStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'PHONE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon
          [ngClass]="{
            green: row.interestedOnCall === offerPropositionInterestedOnCall.Interested,
            red: row.interestedOnCall === offerPropositionInterestedOnCall.NotInterested,
            blue: row.interestedOnCall === offerPropositionInterestedOnCall.Undecided
          }"
          [matTooltip]="getTooltipText(row)"
          >phone</mat-icon
        >


      </td>
    </ng-container>

    <!-- Offer status Column -->
    <ng-container matColumnDef="longList">
      <th mat-header-cell *matHeaderCellDef style="padding-left: 4px">
        <span style="margin: 0 auto">
          {{ 'OFFER.LONG_LIST' | translate }}
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row">
          <button mat-icon-button

            (click)="setAnswer(
            row,
            row.interested,
            undefined,
            undefined,
            offerPropositionLongListed.Included)">
            <mat-icon
              fontSet="material-icons-outlined"
              [ngClass]="{
                'grey-icon': true,
                'green-icon': row.longListed === offerPropositionLongListed.Included,
                'grey-icon-30-op': row.longListed === offerPropositionLongListed.RejectedWithoutCall
              }"
              [matTooltip]="'OFFER.LONG_LIST' | translate"
              >playlist_add_circle</mat-icon
            >
          </button>

          <button
            mat-icon-button
            (click)="setAnswerWithCancellationReasons(
              row,
              row.interested,
              false,
              offerPropositionLongListed.RejectedWithoutCall)"
          >
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'red-icon': row.longListed === offerPropositionLongListed.RejectedWithoutCall,
                'grey-icon-30-op': row.longListed === offerPropositionLongListed.Included
              }"
              [matTooltip]="'OFFER.REJECT_WITHOUT_CALL' | translate"
              >playlist_remove</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <!-- Offer status Column -->
    <ng-container matColumnDef="offer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'DURING_THE_INTERVIEW' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px">
          <button mat-icon-button (click)="setAnswer(row, offerPropositionInterested.Interested)">
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'green-icon': row.interested === offerPropositionInterested.Interested
              }"
              [matTooltip]="'OFFER.POSITION_OFFERED_DOMINA' | translate"
              >thumb_up_alt</mat-icon
            >
          </button>

          <button
            mat-icon-button
            (click)="setAnswerWithCancellationReasons(row, offerPropositionInterested.NotInterested, false)"
          >
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'red-icon': row.interested === offerPropositionInterested.NotInterested
              }"
              [matTooltip]="'OFFER.POSITION_OFFERED_NEDOMINA' | translate"
              >thumb_down_alt</mat-icon
            >
          </button>

          <button mat-icon-button (click)="setAnswerWithCancellationReasons(row, offerPropositionInterested.Undecided, false)">
            <mat-icon
              [ngClass]="{
                'grey-icon': true,
                'red-icon': row.interested === offerPropositionInterested.Undecided
              }"
              [matTooltip]="'OFFER.CANDIDATE_INTERESTED_REQUIREMENTS_NOT_MET' | translate"
              >tv</mat-icon>

          </button>

          <!--          <button mat-icon-button-->
          <!--                  (click)="scheduleEvent(row, $event)"-->
          <!--                  [disabled]="row.interested !== respTypes.agreed"-->
          <!--          >-->
          <!--            <mat-icon matTooltip="Suplanuoti interviu pas klientą" [color]="row.interview ? 'accent' :''">-->
          <!--              insert_invitation-->
          <!--            </mat-icon>-->
          <!--          </button>-->

          <button
            mat-icon-button
            (click)="setEmployed(row, row.signed)"
            [matTooltip]="
              row.signed ? ('OFFER.EMPLOYED_HERE' | translate) : ('OFFER.PLANNED_RECRUITMENT' | translate)
            "
          >
            <mat-icon
              [ngClass]="{ 'grey-icon': !row.signed, 'green-icon': row.signed }"
              >assignment_turned_in</mat-icon
            >
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="darbinimas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'EMPLOYMENT_HEADER' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="row" fxLayoutGap="16px">
          <button
            mat-icon-button
            (click)="scheduleEvent(row, $event, 'HEALTH_CHECK_PENDING')"
            [disabled]="
              row.interested === 'NOT_INTERESTED' ||
              row.interested === null ||
              row.interested === undefined
            "
          >
            <mat-icon
              [matTooltip]="'OFFER.SCEHDULE_AN_INTERVIEW' | translate"
              [style.color]="
                isCancelled(row.candidateEvent, 'HEALTH_CHECK_PENDING')
              "
              >enhanced_encryption</mat-icon
            >
          </button>
          <button
            mat-icon-button
            (click)="scheduleEvent(row, $event, 'EMPLOYMENT')"
            [disabled]="
              row.interested === 'NOT_INTERESTED' ||
              row.interested === null ||
              row.interested === undefined
            "
          >
            <mat-icon
              [matTooltip]="'OFFER.SCEHDULE_AN_INTERVIEW' | translate"
              [style.color]="isCancelled(row.candidateEvent, 'EMPLOYMENT')"
            >
              insert_invitation
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef >
        {{ 'COMMENT' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div style='display: inline-block; width: 65px;'>
          <button
            mat-icon-button
            [disabled]='!row.offerpropositioncommentSet && !row.offerPropositionId'
            [matTooltip]="getCommentsTooltip(row) || (('ADD' | translate) + ' ' + ('COMMENT' | translate))"
            (click)='openCommentDialog($event, row)'
          >
            <mat-icon *ngIf='!row.offerpropositioncommentSet?.totalCount' inline>add_comment</mat-icon>
            <mat-icon *ngIf='row.offerpropositioncommentSet?.totalCount' color='accent' inline>comment</mat-icon>
          </button>

          <app-candidate-evaluations-btn
            [personId]='personId'
            [offerPropositionId]='row.responseId'
            [order]='row.order'
          ></app-candidate-evaluations-btn>
        </div>
      </td>
    </ng-container>


    <tr
      mat-header-row
      *matHeaderRowDef='displayedColumns; sticky: true'
      [class.with-tip]='clientInterview'
    ></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns'></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="0"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 25, 50, 100]"
  >
  </mat-paginator>
</div>
