import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-extra-selection-fields',
  templateUrl: './extra-selection-fields.component.html',
  styleUrls: ['./extra-selection-fields.component.scss']
})
export class ExtraSelectionFieldsComponent {
  @Input() form;
  @Input() isPartialSelection;
}
