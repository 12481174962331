import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-editable-time-value',
  templateUrl: './editable-time-value.component.html',
  styleUrls: ['./editable-time-value.component.scss']
})
export class EditableTimeValueComponent implements OnInit {
  value: number = 0;
  @Input() set timeValue(value: number) {
    if (value) {
      this.value = value;
    }
  }

  @Output() timeValueChange = new EventEmitter<number>();
  editing = false;
  constructor() { }

  ngOnInit(): void {
  }

  updateDuration(row: any) {
    this.timeValueChange.emit(this.value);
    this.editing = false;
  }
}
