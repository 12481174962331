import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-orders',
  templateUrl: './company-orders.component.html',
  styleUrls: ['./company-orders.component.scss']
})
export class CompanyOrdersComponent implements OnInit {

  companyId;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe(res => {
      this.companyId = res.get('id');
    });
  }

}
