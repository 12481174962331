import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderCreateService} from '../../../../order/order-create/order-create.service';
import {BaseOrderFieldsFragment, OrderSelectListFieldsFragment} from '../../../../../generated/graphql';
import {FormControl} from '@angular/forms';


@Component({
  selector: 'app-order-select-list',
  templateUrl: './order-select-list.component.html',
  styleUrls: ['./order-select-list.component.scss']
})
export class OrderSelectListComponent implements OnInit {
  orders: OrderSelectListFieldsFragment[];
  unfilteredOrders: OrderSelectListFieldsFragment[];
  filteredOrders: OrderSelectListFieldsFragment[];

  private $orderType: any;
  @Input() set orderType(value) {
    this.$orderType = value;
    if(this.unfilteredOrders?.length > 0) {
      this.orders = this.unfilteredOrders.filter(order => order.orderType === this.orderType)
    }
  }
  get orderType() { return this.$orderType; }

  @Output() newOrders = new EventEmitter<OrderSelectListFieldsFragment[]>();
  @Output() newOrder = new EventEmitter<string>();
  @Output() newSelection = new EventEmitter<string>();
  @Input() orderCtrl: FormControl;
  @Input() set companyId(value: string) {
    if (value) {
      this.orderService.getCompanyOrders(value).subscribe(
        orders => {
          this.orders = orders;
          this.unfilteredOrders = [...orders];
          this.orders = this.orders.filter(order => order.orderType === this.orderType);
          if (this.orderService.editOrderId && this.orderService.selectedOrder.value) {
            this.orderCtrl.setValue(this.orderService.selectedOrder.value.id);
            // this.orderService.updateOrderForPrefill(this.orderCtrl.value);
          } else {
            if (this.orders.length <= 0) {
              this.orderCtrl.setValue('CREATE_BLANK_ORDER');
            }
          }
          this.newOrders.emit(this.orders);
          this.newOrder.emit(this.orderCtrl.value);
          this.assignCopy();
        }
      );
    }
  }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (this.orderService.editOrderId.value && val){
      this.newOrders.emit(this.orders);
      this.newOrder.emit(this.orderCtrl.value);
    }
  };
  get order(){
    return this.$order;
  }

  selectionChange(event) {
    this.newSelection.emit(event);
  }
  constructor(
    private orderService: OrderCreateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  onOrderChange() {
    // DO NOT delete this- for debugging purposes when select ir not working
    console.log(`DEBUGGING: on order change: ${this.orders}`);
    this.newOrders.emit(this.orders);
    this.newOrder.emit(this.orderCtrl.value);
  }

  updateOrderSearch(value) {
    // when nothing has typed
    if (!value) {
      this.assignCopy();
    }
    this.filteredOrders = Object.assign([], this.orders).filter(
      item => item.specification?.positionTitle.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }

  assignCopy() {
    this.filteredOrders = Object.assign([], this.orders);
  }

  getOrderId(id: string | undefined) {
    try {
      return atob(id).split(':')[1];
    } catch (e) {
      return '-';
    }
  }
}
