import { Injectable } from '@angular/core';
import {
  BaseOrderFieldsFragment,
  CompanyTypeFieldsFragment,
  CountryType,
  GetLastOrdersCreatedGQL, GetOrderForPrefillGQL, OrderEditCompanyDataGQL,
  OrderSelectListFieldsFragment
} from '../../../generated/graphql';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';

import {SalaryMatrixValue} from '../../orders/order-create/salary-matrix/salary-matrix.component';

@Injectable({
  providedIn: 'root'
})
export class OrderCreateService {

  selectedOrder = new BehaviorSubject<BaseOrderFieldsFragment>(undefined);
  selectedCompany = new BehaviorSubject<CompanyTypeFieldsFragment>(undefined);
  company: CompanyTypeFieldsFragment;
  selectedCountry = new BehaviorSubject<string>(undefined);
  selectedLanguage = new BehaviorSubject<string>(undefined);
  country: string;
  contractSet = new BehaviorSubject<any>(undefined);
  selectedSalaryMatrix = new BehaviorSubject<SalaryMatrixValue>(undefined);
  editOrderId = new BehaviorSubject<string>(undefined);

  constructor(
    private getCompanyOr: GetLastOrdersCreatedGQL,
    private getOrderForPrefillGQL: GetOrderForPrefillGQL,
    private getOrderEditCompanyData: OrderEditCompanyDataGQL
  ) { }

  getCompanyOrders(companyId): Observable<OrderSelectListFieldsFragment[]> {
    return this.getCompanyOr.watch({
      companyId,
      orderBy: '-created_date'
    }).valueChanges.pipe(
      map(resp => {
        if (resp.data?.orders?.edges) {
          return resp.data.orders.edges.map(it => it.node);
        } else {
          return  [];
        }
      })
    );
  }

  getCompanyData(companyId: string) {
    return this.getOrderEditCompanyData.fetch({companyId}, {fetchPolicy: 'no-cache'});
  }

  getOrder(orderId: string) {
    return this.getOrderForPrefillGQL.fetch({id: orderId});

  }

  setCompany(data) {
    this.company = data;
    this.selectedCompany.next(data);
    this.selectedCountry.next(data?.country?.id);
  }

  getCompany() {
    return this.company;
  }

  setLanguage(data: string) {
    const splitted = data.split('_');
    const lang = splitted.length ? splitted[1].toLowerCase() : splitted[0].toLowerCase();
    this.selectedLanguage.next(lang);
  }

  getLanguage() {
    return this.selectedLanguage.getValue();
  }

  setCountry(data) {
    this.country = data;
    this.selectedCountry.next(data);
  }

  getCountry() {
    return this.country;
  }
}
