import {Injectable} from '@angular/core';
import {Mutation, Query} from 'apollo-angular';

import gql from 'graphql-tag';
import {WorkwearIssuedBy} from '../orders/workwear-issued-by';
import {ScheduleTypes, ShiftInSchedule} from '../specification/schedule-setup/schedule-setup.component';
import {TagAssignmentTableItem} from '../specification/tag-assignment-table/tag-assignment-table-datasource';
import {Tag, TagAssignment, TagGroup} from '../specification/tag-assignment-table/tag';
import {AssignedPersonalProtectiveEquipment} from '../specification/ppe-issuance-table/ppe';

import {MetaData, OrderData} from '../orders/graphql/order-data';
import {OrderTrafficGeneration} from '../shared/types/order-traffic-generation';
import {OrderTypeEnum} from '../shared/types/order-types';
import { IntensityOptions } from '../specification/specification-edit/specification-edit.component';
import {ContractsMetaFieldsFragment, OrderStatus} from "../../generated/graphql";


export enum COMPANY_COLORS {
  GREEN = 'GREEN',
  GREEN_A = 'GREEN_A',
  GREEN_B = 'GREEN_B',
  GREEN_C = 'GREEN_C',
  YELLOW = 'YELLOW',
  RED = 'RED',
  BLUE = 'BLUE'
}

export interface Contact {
  id: number | string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  insightlyId?: number;
  company?: Company;
  comment?: string;
}



export interface Contract {
  id: number;
  createDate: string;
  validFromDate: string;
  validTillDate: number;
  insightlyId: number;
  contractor: {
    id: string,
    logoUrl: string
  };
}



export interface Company {
  id: any;
  status?: string;
  name: string;
  insightlyId?: number;
  description?: string;
  salesQuestionnaire?: string;
  contractSet: ContractsMetaFieldsFragment[];
  contactSet: Contact[];
  color?: COMPANY_COLORS;
  lastColorChange?: Date;
  country?: {isoCode?: string};
  projectManager1?: any;
  projectManager2?: any;
  substituteManager1?: any;
  substituteManager2?: any;
  recruiter1?: any;
  recruiter2?: any;
  substituteRecruiter1?: any;
  substituteRecruiter2?: any;
  user?: any;
}



export interface CompaniesResponse {
  companies: Company[];
}


export interface Specification {
  id: string;
  name: string;
  positionTitle: string;
  workFunctions: string;
  allFunctionsFromFirstDay: boolean;
  address: string;

  intensity: IntensityOptions;
  onboardingDuration: string;


  male: boolean;
  female: boolean;
  ageFrom: number | null;
  ageTo: number | null;

  certificates: Certificate[];
  riskFactors: RiskFactor[];

  minExperienceMonths: number;
  experienceType: string;

  mandatoryRequirements: Requirement[];
  optionalRequirements: Requirement[];

  shiftDuration: number;
  breakDuration: number;
  scheduleType: ScheduleTypes;
  shiftPattern: string;
  scheduleText: string;
  scheduleTypes: {id: string, title: string}[];

  isWorkplaceVideoAllowed: boolean;
  isWorkplaceVisitAllowed: boolean;
  isLogoPublicAllowed: boolean;
  isCompanyModern: boolean;
  isCompanyInternational: boolean;

  isSecurityPostPresent: boolean;
  isMagneticKeyIssued: boolean;
  whoIssuesMagneticKey: string;

  tagassignmentSet: TagAssignment[];

  personalProtectiveEquipmentBiuro: AssignedPersonalProtectiveEquipment[];
  personalProtectiveEquipmentClient: AssignedPersonalProtectiveEquipment[];
  isConfirmed: boolean;

}

export interface SpecificationResponse {
  specifications: Specification[];
  tagsGroups: TagGroup[];
}




export interface User {
  firstName: string;
  lastName: string;
  email: string;
}
export interface UserProfile {
  id: string;
  user: User;
  region?: {id: number};
}



export interface UserProfileResponse {
  userprofiles: UserProfile[];
}

export interface UnreadOrdersResponse {
  unseenAccepted: number,
  unseenRejected: number
}

export enum StatNames {
  sd1CountBiuro = 'sd1CountBiuro',
  sd2CountBiuro = 'sd2CountBiuro',
  sd1CountBiuroUrgent = 'sd1CountBiuroUrgent',
  biurolv1sdCount = 'biurolv1sdCount',
  biurolatvia1sdCount = 'biurolatvia1sdCount',
  sd1CountDarbius = 'sd1CountDarbius',
  sd2CountDarbius = 'sd2CountDarbius',
  sd1CountDarbiusUrgent = 'sd1CountDarbiusUrgent',
  sd1CountBiurogo = 'sd1CountBiurogo',
  selectedByClientTomorrowApplications = 'selectedByClientTomorrowApplications',
  selectedByClientTodayApplications = 'selectedByClientTodayApplications',
  contractToSignCount = 'contractToSignCount',
  contractToSignCountLv = 'contractToSignCountLv',
  medicalCertificateReviewCount = 'medicalCertificateReviewCount',
  openZohoTicketCount = 'openZohoTicketCount',
  unsignedByEmployeeContractCount = 'unsignedByEmployeeContractCount',
  unreadSmsCount = 'unreadSmsCount',
  edlaAlerts = 'edlaAlerts',
  waitingTomorrowApplications  = 'waitingTomorrowApplications',
  waitingTomorrowApplicationsLv  = 'waitingTomorrowApplicationsLv',
  unsignedGigroupContractCount = 'unsignedGigroupContractCount',
  inventaPrivateEmployees = 'inventaPrivateEmployees',
  inactiveApplications = 'inactiveApplications',
  lduForms = 'lduForms',
  lduUrgentForms = 'lduUrgentForms'
}

export interface EdlaAlertsResponse {
  name: string;
  employees: {name: string}[];
}

export interface LduFormsResponse {
  name: string;
  isNew: boolean;
}

export interface LivasStatsResponse {
  livasData: {
    [StatNames.sd1CountBiuro]: number,
    [StatNames.sd2CountBiuro]: number,
    [StatNames.sd1CountBiuroUrgent]: number,
    [StatNames.biurolv1sdCount]: number,
    [StatNames.biurolatvia1sdCount]: number,
    [StatNames.sd1CountDarbius]: number,
    [StatNames.sd2CountDarbius]: number,
    [StatNames.sd1CountDarbiusUrgent]: number,
    [StatNames.sd1CountBiurogo]: number,
    [StatNames.unreadSmsCount]: number,
    [StatNames.edlaAlerts]: EdlaAlertsResponse[],
    [StatNames.lduForms]: LduFormsResponse[]
    [StatNames.lduUrgentForms]: number[]
  };
}

export interface CompaniesPaginatedResponse {
  edges: {node: Company}[];
  totalCount: number;
  pageInfo: PageInfoResponse;
}

@Injectable({
  providedIn: 'root',
})
export class AllCompaniesGQL extends Query<{companies: CompaniesPaginatedResponse}> {
  document = gql`
    query allCompanies($first: Int, $after: String, $name: String, $country: ID, $projectManager: ID, $substituteManager: ID, $recruiter: ID, $substituteRecruiter: ID) {
      companies(first: $first, after: $after, name: $name, country: $country, projectManager: $projectManager, substituteManager: $substituteManager, recruiter: $recruiter, substituteRecruiter: $substituteRecruiter) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        totalCount
        edges {
          node {
            id
            status
            name
            description
            color
            lastColorChange
            projectManager1 {
              id
              user {
                firstName
                lastName
              }
            }
            projectManager2 {
              id
              user {
                firstName
                lastName
              }
            }
            substituteManager1 {
              id
              user {
                firstName
                lastName
              }
            }
            substituteManager2 {
              id
              user {
                firstName
                lastName
              }
            }
            recruiter1 {
              id
              user {
                firstName
                lastName
              }
            }
            recruiter2 {
              id
              user {
                firstName
                lastName
              }
            }
            substituteRecruiter1 {
              id
              user {
                firstName
                lastName
              }
            }
            substituteRecruiter2 {
              id
              user {
                firstName
                lastName
              }
            }
            contractSet {
              id
              validFromDate
              validTillDate
            }
            contactSet {
              edges {
                node {
                  id
                  firstName
                  lastName
                }
              }
            }
            specificationSet {
              id
              positionTitle
            }
          }
      }
      }
    }
  `;
}



@Injectable({
  providedIn: 'root',
})
export class AllUserProfilesGQL extends Query<UserProfileResponse> {
  document = gql`
    query allUserProfiles($departments: [String]) {
      userprofiles(departments: $departments) {
        id
        color
        user {
          id
          firstName
          lastName
          email
        }
        profileImageUrl
      }
    }
  `;
}

export interface Certificate {
  id: string;
  title: string;
}

export interface CertificateResponse {
  certificates: Certificate[];
}

export interface Requirement {
  id: string;
  requirement?: string;
}

export interface Region {
  id: number;
  title: string;
}

export interface RequirementsResponse {
  requirements: Requirement[];
}

export interface UserProfileResponse {
  userprofiles: UserProfile[];
}

@Injectable({
  providedIn: 'root',
})
export class CreateOrderGQL extends Mutation {
  document = gql`
    mutation createOrder($order: OrderInput!, $periods: [OrderPeriodInput]) {
      createOrder(input: $order, periods: $periods) {
        ok
        order {
          id
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class DeleteOrderPeriodGQL extends Mutation {
  document = gql`
    mutation deleteOrderPeriod($orderPeriod: ID!) {
      deleteOrderPeriod(id: $orderPeriod) {
        ok
      }
    }
  `;
}



@Injectable({
  providedIn: 'root',
})
export class SpecificationsGQL extends Query<SpecificationResponse> {
  document = gql`
    query specifications($companyId: Int!) {
      specifications(companyId: $companyId) {
        ...SpecificationFields
      }
      tagsGroups {
        key
        tagSet {
          ...TagFields
        }
      }
    }
    ${EditView.fragments.specification}
    ${EditView.fragments.tags}
  `;
}

@Injectable({
  providedIn: 'root',
})
export class CopySpecificationsGQL extends Mutation<SpecificationResponse> {
  document = gql`
    mutation copySpecification($id: Int!) {
      copySpecification(input: $id) {
        specification {
          id
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class TagGroupsGQL extends Query<SpecificationResponse> {
  document = gql`
    query tagGroups {
      tagsGroups {
        key
        tagSet {
          ...TagFields
        }
      }
    }
    ${EditView.fragments.tags}
  `;
}




@Injectable({providedIn: 'root'})
export class CertificateGQL extends Query<CertificateResponse> {
  document = gql`
    query certificates($country: ID!) {
    certificates(country: $country){
      id
      title
    }
  }`;
}


@Injectable({providedIn: 'root'})
export class RequirementsGQL extends Query<RequirementsResponse> {
  document = gql`
    query requirements($requirement: String) {
      requirements(requirement: $requirement){
        id
        requirement
      }
    }`;
}


export interface RiskFactor {
  id: string;
  title: string;
  isDefault: boolean;

}

export interface RiskFactorsResponse {
  riskFactors: RiskFactor[];
}

@Injectable({providedIn: 'root'})
export class RiskFactorsGQL extends Query<RiskFactorsResponse> {
  document = gql`
    query riskFactors($title: String) {
      riskFactors(title: $title) {
        id
        title
        isDefault
      }
    }
  `;
}

export type RawCompany = Pick<Company, Exclude<keyof  Company, 'contractSet' | 'contactSet'>>;

export interface OrderPeriod {
  id: number;
  dateFrom: string;
  dateTo: string;
  demand: number;
  longTerm: boolean;
}

export interface Order {
  id: string;
  orderNumber: string;
  subtype?: string;
  hourRateBruttoPermanent: string;
  salaryInfo: string;
  orderperiodSet: OrderPeriod[];
  specification: {id: string, positionTitle: string, address: string};
  createdBy: UserProfile;
  clientContact: Contact;
  additionalClientContacts: Contact[];
  company: RawCompany;
  status: OrderStatus;
  trafficGeneration: OrderTrafficGeneration;
  orderType: OrderTypeEnum;
  isLongTerm: boolean;
  orderPeriodFrom: string;
  orderPeriodTo: string;
  companyDescription: string;
  nettoFrom: number;
  nettoTo: number;
  shortage: number;
  compensationForRecruitment: string;
}

export interface OrderEdit extends  Order {
  cancellationPeriod: number;
  workwearIssuedBy: WorkwearIssuedBy;
  projectManager: UserProfile;
  contract: Contract;
  specification: Specification;
  region: Region;
  shortage: number;
  priority: OrderStatus;
  recruiter: UserProfile;
  biuroSignee: UserProfile;

}

export interface PageInfoResponse {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
}

export interface OrderPaginatedResponse {
  edges: {node: Order}[];
  pageInfo: PageInfoResponse;
  totalCount: number;
}


export interface OrdersResponse {
  ordersPaginated: OrderPaginatedResponse;
  regions: Region[];
}

@Injectable({providedIn: 'root'})
export class OrdersGQL extends Query<OrdersResponse> {

  document = gql`
  query getPaginatedOrder($status: String,
                          $first: Int, $after: String,
                          $search: String, $region: String,
                          $specification: String,
                          $unseenProcessedOrders: Boolean,
                          $companies: String, $orderBy: String) {
    regions {
      ...RegionsListFields
    }
    ordersPaginated(status: $status, first: $first, after: $after,
                    search: $search, region: $region, specification: $specification,
                    unseenProcessedOrders: $unseenProcessedOrders, companies: $companies,
                    orderBy: $orderBy) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...OrdersListFields
        }
      }
    }
  }
  ${MetaData.fragments.regionsListFields}
  ${OrderData.fragments.ordersListFields}
  `;

}



@Injectable({providedIn: 'root'})
export class RegionsGQL extends Query<{regions: Region[]}> {

  document = gql`
  query getAllRegions {
    regions(isActive: true) {
      ...RegionsListFields
    }
  }
  ${MetaData.fragments.regionsListFields}
  `;

}


@Injectable({providedIn: 'root'})
export class UpdateOrderGQL extends Mutation<{updateOrder: {order: OrderEdit}}> {
  document = gql`
    mutation updateOrder($id: ID!, $order: OrderInput!, $periods: [OrderPeriodInput]) {
      updateOrder(id: $id, input: $order, periods: $periods) {
        order {
          id
          orderType
          priority
          trafficGeneration
          hourRateBruttoPermanent
          salaryInfo
          orderPeriodFrom
          orderPeriodTo
          orderperiodSet {
            id
            longTerm
            demand
            dateFrom
            dateTo
          }
          demand
          contract {
            id
          }
          orderNumber
          subtype
          region {
            id
          }
          cancellationPeriod
          workwearIssuedBy
          projectManager {
            id
            user {
              id
              firstName
              lastName
            }
          }
          recruiter {
            id
            user {
              id
              firstName
              lastName
            }
          }
          biuroSignee {
            id
            user {
              id
              firstName
              lastName
            }
          }
          specification {
            id
            positionTitle
          }
          createdBy {
            id
            user{
              id
              email
              firstName
              lastName
            }
          }
          clientContact {
            id
            email
            firstName
            lastName
          }
          company {
            id
            status
            name
            color
          }
          specification {
            ...SpecificationFields
          }
          shortage
        }
      }
    }
    ${EditView.fragments.specification}
    `;
}





@Injectable({providedIn: 'root'})
export class OrderEditGQL extends Query<{order: OrderEdit}> {
  document = gql`
  query getOrder($id: Int!) {
    order(id: $id) {
      id
      orderNumber
      subtype
      hourRateBruttoPermanent
      salaryInfo
      orderType
      trafficGeneration
      workwearIssuedBy
      nettoTo
      nettoFrom
      shortage
      status
      contract {
        id
      }
      region {
        id
      }
      cancellationPeriod
      projectManager {
        id
        user {
          id
          firstName
          lastName
        }
      }
      specification {
        id
        positionTitle
      }
      createdBy {
        id
        user{
          email
          firstName
          lastName
        }
      }
      clientContact {
        id
        email
        firstName
        lastName
      }
      additionalClientContacts {
        id
      }
      company {
        id
        status
        name
        color
      }
      specification {
        id
        positionTitle
      }
      orderperiodSet {
        id
        dateFrom
        dateTo
        demand
        longTerm
      }
      compensationForRecruitment
    }
  }`;
}


@Injectable({providedIn: 'root'})
export class CreateCompanyGQL extends Mutation<{company: RawCompany}> {
  document = gql`
    mutation createCompany($company: CompanyInput!) {
      createCompany(input: $company) {
        ok
        company {
          id
          name
          status
          insightlyId
          color
          contractSet {
            id
            validFromDate
          }
          contactSet {
            id
            firstName
            lastName
          }
          specificationSet {
            id
            positionTitle
          }
        }
      }
    }
  `;
}


@Injectable({providedIn: 'root'})
export class UpdateCompanyGQL extends Mutation<{company: RawCompany}> {
  document = gql`
    mutation createCompany($id: Int!, $livasId: Int) {
      updateCompany(id: $id, livasId: $livasId) {
        ok
        company {
          id
          name
          status
          insightlyId
          color
          contractSet {
            id
            validFromDate
            validTillDate
          }
          contactSet {
            edges {
              node {
                id
                firstName
                lastName
              }
            }
          }
          specificationSet {
            id
            positionTitle
          }
        }
      }
    }
  `;
}




@Injectable({providedIn: 'root'})
export class UpdateCompanyDescriptionGQL extends Mutation<{company: RawCompany}> {
  document = gql`
    mutation updateCompanyDescription($id: ID!, $description: String, $salesQuestionnaire: String) {
      updateCompanyRelay(id: $id, description: $description, salesQuestionnaire: $salesQuestionnaire) {
        ok
        company {
          id
          name
          status
          description
          salesQuestionnaire
          insightlyId
          contractSet {
            id
            validFromDate
            validTillDate
          }
          contactSet {
            edges {
              node {
                id
                firstName
                lastName
              }
            }
          }
          specificationSet {
            id
            positionTitle
          }
        }
      }
    }
  `;
}


const EditView = {
  fragments: {
    tags: gql `
      fragment TagFields on TagType {
        id
        title
        hint
        order
        group {
          id
          key
        }
      }
    `,
    specification: gql`
      fragment SpecificationFields on SpecificationType  {
        id
        name
        positionTitle
        workFunctions
        address
        intensity
        onboardingDuration
        male
        female
        certificates {
          id
          title
        }
        riskFactors {
          id
          title
        }
        mandatoryRequirements {
          id
          requirement
        }
        optionalRequirements {
          id
          requirement
        }
        allFunctionsFromFirstDay
        isWorkplaceVideoAllowed
        isWorkplaceVisitAllowed
        isLogoPublicAllowed
        isCompanyModern
        isCompanyInternational
        shiftDuration
        breakDuration
        scheduleType
        shiftPattern
        minExperienceMonths
        experienceType
        isSecurityPostPresent
        isMagneticKeyIssued
        whoIssuesMagneticKey
        isConfirmed
        tagassignmentSet {
          id
          tag {
            id
            group {
              id
              key
            }
          }
          comment
        }
      }`,
      contact: gql `
          fragment ContactFields on ContactType {
            id
            firstName
            lastName
            email
            phone
            insightlyId
            comment
          }
      `
  },
};


@Injectable({providedIn: 'root'})
export class CreateSpecificationGQL extends Mutation<{input: Specification}> {
  document = gql`
    mutation createSpecification($input: SpecificationInput!, $companyId: ID!) {
      createSpecification(input: $input, companyId: $companyId) {
        specification {
          ...SpecificationFields
        }
      }
    }
    ${EditView.fragments.specification}
  `;
}

@Injectable({providedIn: 'root'})
export class ProcessedOrderViewGQL extends Mutation<{ok: boolean}> {
  document = gql`
    mutation setProcessedOrderViewDatetime {
      setProcessedOrderViewDatetime {
        ok
      }
    }
  `;
}



@Injectable({providedIn: 'root' })
export class UpdateSpecificationGQL extends Mutation<{specification: Specification}> {
  document = gql`
    mutation updateSpecification($specification: SpecificationInput!, $id: ID!) {
      updateSpecification(input: $specification, id: $id) {
        specification {
          ...SpecificationFields
        }
      }
    }
    ${EditView.fragments.specification}
  `;
}

@Injectable({providedIn: 'root' })
export class CreateContactGQL extends Mutation<{contact: Contact}> {
  document = gql`
  mutation createContact($input: ContactInput!) {
    createContact(input: $input) {
      contact {
        ...ContactFields
      }
    }
  }
    ${EditView.fragments.contact}
  `;
}

@Injectable({providedIn: 'root' })
export class UpdateContactGQL extends Mutation<{contact: Contact}> {
  document = gql`
  mutation updateContact($id: Int!, $input: ContactInput!) {
    updateContact(id: $id, input: $input) {
      contact {
        ...ContactFields
      }
    }
  }
    ${EditView.fragments.contact}
  `;
}


@Injectable({providedIn: 'root' })
export class GetContactGQL extends Query<{contact: Contact}> {
  document = gql`
  query getContact($id: Int!) {
    contact(id: $id) {
      ...ContactFields
    }
  }
    ${EditView.fragments.contact}
  `;
}
@Injectable({providedIn: 'root' })
export class GetUnreadOrdersGQL extends Query<UnreadOrdersResponse> {
  document = gql`
  query getUnreadCount {
    unseenAccepted : statsUnseenProcessedOrdersAccepted
    unseenRejected : statsUnseenProcessedOrdersRejected
  }
  `;
}
@Injectable({providedIn: 'root' })
export class GetDashboardStats extends Query<StatNames> {
  document = gql`
  query sd {
    ${StatNames.selectedByClientTomorrowApplications} {
      fullName,
      job,
      company,
      url,
      shiftDate
    }
    ${StatNames.selectedByClientTodayApplications} {
      fullName,
      job,
      company,
      url,
      shiftDate
    }
    ${StatNames.contractToSignCount}
    ${StatNames.contractToSignCountLv}
    ${StatNames.medicalCertificateReviewCount}
    ${StatNames.openZohoTicketCount}
    ${StatNames.unsignedGigroupContractCount}
    ${StatNames.unsignedByEmployeeContractCount}
    ${StatNames.waitingTomorrowApplications} {
      fullName,
      job,
      company,
      url,
      shiftDate
    }
    ${StatNames.waitingTomorrowApplicationsLv} {
      fullName,
      job,
      company,
      url,
      shiftDate
    }
    ${StatNames.inactiveApplications} {
      fullName,
      job,
      company,
      url,
      shiftDate
    }
    ${StatNames.inventaPrivateEmployees} {
      firstName,
      lastName,
      confirmedInOtherCompany
    }
  }`;
}

@Injectable({providedIn: 'root' })
export class GetLivasStats extends Query<LivasStatsResponse> {
  document = gql`
  query livasStats {
    livasData {
      ${StatNames.sd1CountBiuro}: biuro1sdCount,
      ${StatNames.sd2CountBiuro}: biuro2sdCount,
      ${StatNames.sd1CountBiuroUrgent}: biuro1sdUrgentCount,
      ${StatNames.biurolv1sdCount}: biurolv1sdCount,
      ${StatNames.biurolatvia1sdCount}: biurolatvia1sdCount,
      ${StatNames.sd1CountDarbius}: darbius1sdCount,
      ${StatNames.sd2CountDarbius}: darbius2sdCount,
      ${StatNames.sd1CountDarbiusUrgent}: darbius1sdUrgentCount,
      ${StatNames.sd1CountBiurogo}: biurogo1sdCount,
      ${StatNames.lduUrgentForms}: lduUrgentForms,
      ${StatNames.unreadSmsCount}
      ${StatNames.edlaAlerts} {
        name,
        employees {
          name
        }
      }
      ${StatNames.lduForms} {
        name
        isNew
      }
    }
  }`;
}
