<div fxLayout="column" [formGroup]="form">

  <div fxFlex="row" fxLayoutGap="16px" fxLayoutAlign="space-between">
    <div fxFlex="column">
      <h5> {{'ORDER.EXPERIENCE' | translate}} </h5>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="200px" >
          <mat-label>{{'ORDER.MINIMUM_EXPERIENCE_REQUIRED' | translate}}</mat-label>
          <input formControlName="minExperienceMonths" matInput type="number" (keypress)="numberInputControl($event)">
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>{{'ORDER.EXPERIENCE_TYPE' | translate}}</mat-label>
          <mat-select formControlName="experienceType" matNativeControl name="contact">
            <mat-option value="{{experienceTypes.same}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.ANALOGOUS' | translate}}</mat-option>
            <mat-option value="{{experienceTypes.similar}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.SIMILAR' | translate}}</mat-option>
            <mat-option value="{{experienceTypes.irrelevant}}">{{'ORDER.EXPERIENCE_TYPE_OPTIONS.DOES_NOT_MATTER' | translate}}</mat-option>
          </mat-select>

          <mat-error *ngIf="false">{{'COMMON.REQUIRED_FIELDS' | translate}}</mat-error>

        </mat-form-field>
      </div>
    </div>
    <div fxFlex="column">
      <h5>{{'ORDER.AGE_RESTRICTION' | translate}} </h5>
      <div fxLayoutAlign="start center">
        <app-age-restriction [appearance]="'outline'" [ageFrom]="ageFromInit" [ageTo]="ageToInit" (restrictionChange)="onAgeRestrictionChange($event)">
        </app-age-restriction>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between">
      <h5>{{'ORDER.ELIGIBLE_STAFF' | translate}}</h5>
      <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="space-between" [style.margin-bottom]="'21px'">
          <mat-checkbox class="example-margin" formControlName="female" >{{'COMMON.GENDER_OPTIONS.FEMALE' | translate}}</mat-checkbox>
          <mat-checkbox class="example-margin" formControlName="male" >{{'COMMON.GENDER_OPTIONS.MALE' | translate}}</mat-checkbox>
      </div>
    </div>
  </div>

  <mat-checkbox
    color="primary"
    *ngIf="isRequirementsCheckboxVisible"
    [checked]="!passMandatoryRequirementsCtrl?.value"
    (change)="requirementsSelectionChange($event)"
  >
    {{ 'ORDER.INCLUDE_MANDATORY_REQUIREMENTS_IN_ORDER' | translate }}
  </mat-checkbox>

  <div fxLayout="row"  fxFlexOffset="6px" fxLayoutAlign="space-between">
<!--    <app-candidate-requirements title="Pageidaujami reikalavimai"-->
<!--                                [initialize]="specForm.get('optionalRequirements').value"-->
<!--                                (selected)="onRequirementsSelect($event, 'optionalRequirements')"-->

<!--                                fxFlex="100">-->
<!--    </app-candidate-requirements>-->

    <app-candidate-requirements
      fxFlex="45"
      title="{{ 'ORDER.MANDATORY_REQUIREMENTS' | translate }}"
      (selected)="onRequirementsSelect($event, 'mandatoryRequirements')"
      [requirements]="mRequirements"
      [selectedCountry]="selectedCountry ? selectedCountry : ''"
      [selectedLang]="selectedLang ? selectedLang : ''"
    ></app-candidate-requirements>

    <app-candidate-requirements
      fxFlex="45"
      title="{{ 'ORDER.PREFERRED_REQUIREMENTS' | translate }}"
      (selected)="onRequirementsSelect($event, 'optionalRequirements')"
      [requirements]="pRequirements"
      [selectedCountry]="selectedCountry ? selectedCountry : ''"
      [selectedLang]="selectedLang ? selectedLang : ''"
    ></app-candidate-requirements>
  </div>

  <mat-card fxLayout="row" fxFlexOffset="20px">
    <app-certificates-table
      fxFlex="100"
      (selected)="onCertificatesUpdate($event)"
      [preselect]="[]"
      [order]="order">
    </app-certificates-table>
  </mat-card>

  <mat-card fxLayout="row" fxFlexOffset="20px">
    <app-tag-assignment-table
      [tags]="transportInfoTags"
      (selected)="onTagsSelection($event, tagGroupMap.workplaceInto)"
      [assigned]="tagAssignments"
      fxFlex="100">
    </app-tag-assignment-table>
  </mat-card>
</div>

