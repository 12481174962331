<div class="mat-elevation-z1">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDER.FIELD' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.fieldName}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDER.NEW_VALUE' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.newValue}}</td>
    </ng-container>

    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDER.OLD_VALUE' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.oldValue}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</div>
