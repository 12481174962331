import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferPropositionWidgetComponent } from './offer-proposition-widget.component';
import { OfferPropositionWidgetRoutingModule } from './offer-proposition-widget-routing.module';
import { AppMaterialModule } from '../app-material/app-material.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { OffersTableComponent } from './offers-table/offers-table.component';
import { InterviewWidgetModule } from '../interview-widget/interview-widget.module';

@NgModule({
  declarations: [
    OfferPropositionWidgetComponent,
    OffersTableComponent,
  ],
  imports: [
    CommonModule,
    OfferPropositionWidgetRoutingModule,
    InterviewWidgetModule,
    AppMaterialModule,
    SharedModule,
    TranslateModule
  ]
})
export class OfferPropositionWidgetModule { }
