import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import { ToolbarService } from '../navigation/toolbar.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-recruitment-dashboard',
  templateUrl: './recruitment-dashboard.component.html',
  styleUrls: ['./recruitment-dashboard.component.scss']
})
export class RecruitmentDashboardComponent {
  title = 'dashboard';
  today = new Date();
  selectedDate = formatDate(this.today, 'yyyy-MM-dd', 'en-US');

  constructor(
    private toolbarService: ToolbarService,
    private titleService: Title
  ) {
    this.toolbarService.hide();
    this.titleService.setTitle('Dashboard');
  }
  onDateChange(d) {
    this.selectedDate = formatDate(d.value, 'yyyy-MM-dd', 'en-US');
  }
}
