<div class="container light-background">
  <div class="top-row">
    <app-filter-bar
      menuView="true"
      [initialValue]="filters"
      [filters]="filtersConfig"
      [style]="'bordered-filters'"
      [preventString]="true"
      (filterChanges)="updateFilters($event)"
    ></app-filter-bar>

    <button mat-raised-button class="action-btn text-with-icon" (click)="openNewTaskDialog()">
      <span>{{'NEW_TASK' | translate}}</span>
      <mat-icon>add</mat-icon>
    </button>

    <button *ngIf="dialogRef.close" class="action-btn icon" mat-fab (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="buckets">
    <div class="bucket">
      <div class="title">
        <span>{{ 'TODAY' | translate }}</span>&nbsp;
        <span *ngIf="!bucketsData.today?.isLoading">
          <span>({{ bucketsData.today?.tasks?.length || 0 }} {{ ((bucketsData.today?.tasks?.length > 1 ? 'TASKS' : 'TASK') | translate).toLowerCase() }}</span>
          <span>, {{ convertMinutesToTime(bucketsData.today?.durationMinutes || 0) }} {{ ('SPECIFICATION.HOURS' | translate).toLowerCase() }})</span>
        </span>

        <mat-spinner *ngIf="bucketsData.today?.isLoading" [diameter]="22"></mat-spinner>
      </div>

      <div class="list">
        <app-task-card
          *ngFor="let task of bucketsData.today?.tasks"
          [task]="task"
          (click)="openNewTaskDialog(task)"
        ></app-task-card>

        <span *ngIf="bucketsData.today?.tasks?.length === 0" class="placeholder">{{ 'NO_TASK_HERE' | translate }}</span>
      </div>
    </div>

    <div class="bucket">
      <div class="title">
        <span>{{ 'SPECIFICATION.THIS_WEEK' | translate }}</span>&nbsp;
        <span *ngIf="!bucketsData.week?.isLoading">
          <span>({{ bucketsData.week?.tasks?.length || 0 }} {{ ((bucketsData.week?.tasks?.length > 1 ? 'TASKS' : 'TASK') | translate).toLowerCase() }}</span>
          <span>, {{ convertMinutesToTime(bucketsData.week?.durationMinutes || 0) }} {{ ('SPECIFICATION.HOURS' | translate).toLowerCase() }})</span>
        </span>

        <mat-spinner *ngIf="bucketsData.week?.isLoading" [diameter]="22"></mat-spinner>
      </div>

      <div class="list">
        <app-task-card
          *ngFor="let task of bucketsData.week?.tasks"
          [task]="task"
          (click)="openNewTaskDialog(task)"
        ></app-task-card>

        <span *ngIf="bucketsData.week?.tasks?.length === 0" class="placeholder">{{ 'NO_TASK_HERE' | translate }}</span>
      </div>
    </div>

    <div class="bucket">
      <div class="title">
        <span>{{ 'NEXT_WEEK' | translate }}</span>&nbsp;
        <span *ngIf="!bucketsData.nextWeek?.isLoading">
          <span>({{ bucketsData.nextWeek?.tasks?.length || 0 }} {{ ((bucketsData.nextWeek?.tasks?.length > 1 ? 'TASKS' : 'TASK') | translate).toLowerCase() }}</span>
          <span>, {{ convertMinutesToTime(bucketsData.nextWeek?.durationMinutes || 0) }} {{ ('SPECIFICATION.HOURS' | translate).toLowerCase() }})</span>
        </span>

        <mat-spinner *ngIf="bucketsData.nextWeek?.isLoading" [diameter]="22"></mat-spinner>
      </div>

      <div class="list">
        <app-task-card
          *ngFor="let task of bucketsData.nextWeek?.tasks"
          [task]="task"
          (click)="openNewTaskDialog(task)"
        ></app-task-card>

        <span *ngIf="bucketsData.nextWeek?.tasks?.length === 0" class="placeholder">{{ 'NO_TASK_HERE' | translate }}</span>
      </div>
    </div>

    <div class="bucket">
      <div class="title">
        <span>{{ 'DONE' | translate }}</span>&nbsp;
        <span>
          <div class="range-selector" [class.all]="bucketsData.doneAll">
            <mat-select [value]="'done'" (valueChange)="changeDoneMod($event)">
              <mat-option [value]="'done'">{{ 'SPECIFICATION.THIS_WEEK' | translate }}</mat-option>
              <mat-option [value]="'doneAll'">{{ 'ALL' | translate }}</mat-option>
            </mat-select>
          </div>
        </span>&nbsp;
        <span *ngIf="!(bucketsData.doneAll?.isLoading || bucketsData.done?.isLoading)">
          <span>({{ bucketsData.doneAll ? bucketsData.doneAll.tasks?.length : bucketsData.done?.tasks?.length }}</span>&nbsp;
          <span> {{ (((bucketsData.doneAll ? bucketsData.doneAll.tasks?.length : bucketsData.done?.tasks?.length) > 1 ? 'TASKS' : 'TASK') | translate).toLowerCase() }}</span>
          <span>
            , {{ convertMinutesToTime((bucketsData.doneAll ? bucketsData.doneAll.durationMinutes : bucketsData.done?.durationMinutes) || 0) }}
             {{ ('SPECIFICATION.HOURS' | translate).toLowerCase() }})
          </span>
        </span>

        <mat-spinner *ngIf="bucketsData.doneAll ? bucketsData.doneAll?.isLoading : bucketsData.done?.isLoading" [diameter]="22"></mat-spinner>
      </div>

      <div class="list">
        <app-task-card
          *ngFor="let task of (bucketsData.doneAll ? bucketsData.doneAll.tasks : bucketsData.done?.tasks)"
          [task]="task"
          (click)="openNewTaskDialog(task)"
        ></app-task-card>

        <span
          *ngIf="(bucketsData.doneAll ? bucketsData.doneAll.tasks?.length : bucketsData.done?.tasks?.length) === 0"
          class="placeholder"
        >
          {{ 'NO_TASK_HERE' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
