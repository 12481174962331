<h2 *ngIf="title" mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
    <h3 *ngIf="headline">{{ headline }}</h3>
    <mat-nav-list *ngIf="records.length">
        <mat-list-item *ngFor="let record of records"> {{ record }} </mat-list-item>
    </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'CONFIRM' | translate }}</button>
</mat-dialog-actions>
