import { Component, Inject, OnInit } from '@angular/core';
import { LivasService } from '../../livas/livas.service';
import { Company } from '../../graphql/graphql';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../shared/notification.service';
import { RelayIdService } from '../../shared/relay-id.service';
import {
  CompanyDetailFieldsFragment,
  ContractorSelectFieldsFragment,
  GetContractorsForSelectGQL,
  UpdateCompanyGQL,
} from '../../../generated/graphql';

@Component({
  selector: 'app-contract-importer',
  templateUrl: './contract-importer.component.html',
  styleUrls: ['./contract-importer.component.scss'],
})
export class ContractImporterComponent implements OnInit {
  loading = false;
  companies;
  companyTitle = '';
  mode = 'indeterminate';

  company: CompanyDetailFieldsFragment;
  selectedCompany: CompanyDetailFieldsFragment;
  contractors: ContractorSelectFieldsFragment[] = [];
  selectedContractor: ContractorSelectFieldsFragment;

  constructor(
    private livas: LivasService,
    private updateCompanyGql: UpdateCompanyGQL,
    private relayService: RelayIdService,
    private getContractorsGQL: GetContractorsForSelectGQL,
    private notifications: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: { company: CompanyDetailFieldsFragment }
  ) {
    this.company = data.company;
    this.companyTitle = this.company.name;
  }

  ngOnInit() {
    this.loadContractors();
  }

  searchCompany(companyTitle) {
    this.loading = true;
    this.livas
      .getCompanyWithContracts(companyTitle, this.selectedContractor.externalId,  this.selectedContractor.country.isoCode)
      .subscribe((resp) => {
        this.companies = resp;
        this.loading = false;
      });
  }

  importContract(companyFromLivas) {
    this.loading = true;
    this.updateCompanyGql
      .mutate({
        id: this.relayService.parseId(this.company.id),
        livasId: companyFromLivas.id,
        contractorId: this.selectedContractor.id,
      })
      .subscribe((r) => {
        this.selectedCompany = r.data.updateCompany.company;
        this.notifications.notify('Imported successfully.');
        this.loading = false;
      });
  }

  loadContractors() {
    this.getContractorsGQL
      .watch({}, { fetchPolicy: 'cache-and-network' })
      .valueChanges.subscribe((resp) => {
        if (resp.data) {
          this.contractors = resp.data.contractors.edges.map((it) => it.node);
          this.selectedContractor = this.contractors.length
            ? this.contractors[0]
            : undefined;
        }
      });
  }
}
