<div class="container">
    <button mat-icon-button (click)="updateDate('prev')">
        <mat-icon>navigate_before</mat-icon>
    </button>

    <mat-form-field appearance="fill">
        <mat-label>{{ 'MONTH_AND_YEAR' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="formControl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, picker)"
            panelClass="example-picker">
        </mat-datepicker>
    </mat-form-field>

    <button mat-icon-button (click)="updateDate('next')">
        <mat-icon>navigate_next</mat-icon>
    </button>
</div>
