import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, merge, BehaviorSubject, interval, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { AllContactsGQL, ContactType } from '../../../generated/graphql';

export interface ContactsFilters {
  search?: string;
  phone?: string;
  company?: string;
}
/**
 * Data source for the OrderList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ContactsListDatasource extends DataSource<ContactType> {
  data = new BehaviorSubject<ContactType[]>([]);
  paginator: MatPaginator;
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  filters = new BehaviorSubject<ContactsFilters>({});
  loadingSubscription: Subscription;
  totalCount = 0;
  loading = new BehaviorSubject<boolean>(true);

  constructor(
    private contactsGQL: AllContactsGQL
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<ContactType[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      this.filters.asObservable(),
      this.sort.sortChange,
      this.paginator.page
    ];

    merge(...dataMutations).pipe(debounce(() => interval(500))).subscribe(
      () => {
        this.loading.next(true);
        let endCursor;
        if (Object.keys(this.filters.value).length) {
          endCursor = btoa(`arrayconnection:-1`);
          this.paginator.pageIndex = 0;
        } else {
          endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
        }
        const queryParams = Object.assign(
          {}, {first: this.paginator.pageSize, after: endCursor}, this.filters.value);
        if ( this.loadingSubscription ) {
          this.loadingSubscription?.unsubscribe?.();
        }

        this.loadingSubscription = this.contactsGQL.watch(
          queryParams,
          {fetchPolicy: 'cache-and-network'}
        ).valueChanges.subscribe(
          r => {
            if ( r.data && !r.loading ) {
              this.data.next(this.parseEdges(r.data.contacts));
              this.totalCount = r.data.contacts.totalCount;
              this.endCursor = r.data.contacts.pageInfo.endCursor;
              this.loading.next(false);
            }
          }
        );
      }
    );
    return this.data.asObservable();
  }

  private parseEdges(input: any) {
    return input.edges.map(it => {
/*       const node = it.node;
      let oid;
      try {
        oid = atob(node.id).split(':')[1];
      } catch {
        oid = node.id
      }
      node.id = oid; */
      return it.node;
    });
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}
}
