import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge, Observable } from 'rxjs';

import { OrderOfferStatsTableItem } from './types';
import {
  EventEventType,
  EventResolutionStatus,
  OfferPropositionLongListed,
  OffersGQL
} from '../../../../generated/graphql';
import { ProgressBarEvent } from '../../../interview-widget/offers-table/progress-component/types';
import { ResponseTypes } from '../../../offer-proposition-widget/offers-table/offers-table-datasource';

/**
 * Data source for the OrderOfferStatsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */

export interface OrderOfferStatsFilter {
  createdAt_Gte?: string;
  createdAt_Lte?: string;
}

export class OrderOfferStatsTableDataSource extends DataSource<OrderOfferStatsTableItem> {
  data = new BehaviorSubject<OrderOfferStatsTableItem[]>([]);
  paginator: MatPaginator;
  filters = new BehaviorSubject<OrderOfferStatsFilter>({});
  sort: MatSort;

  endCursor: string;
  startCursor: string;
  hasNextPage: boolean;
  totalCount = 0;
  public country: string | undefined;

  constructor(
    private offersGql: OffersGQL,
    private orderId,
    private lang= 'lt'
  ) {
    super();
    const supportedLangs = ['lt', 'lv', 'et'];

    if (supportedLangs.indexOf(this.lang) < 0) {
      this.lang = supportedLangs[0];
    }

    if (this.lang === 'et') {
      this.lang = 'ee';
    }
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<OrderOfferStatsTableItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.

    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page,
      this.sort.sortChange
    ];

    this.getStats();
    merge(...dataMutations).subscribe(
      mutation => {
        this.getStats();
      }
    );

    return this.data.asObservable();
  }

  getStats() {
    const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);

    const orderBy = this.getSort(this.sort);
    const queryParams =
      {
        orderId: btoa('OrderMType:' + this.orderId),
        first: this.paginator.pageSize,
        after: endCursor,
        ...orderBy,
        ...this.filters.value
      };

    const sub = this.offersGql.watch(
      queryParams,
      {fetchPolicy: 'cache-and-network'}
    ).valueChanges.subscribe(
      r => {
        if ( r.data ) {
          this.data.next(this.parseEdges(r.data.offers));
          this.country = r.data.orderModel?.company?.country?.isoCode;
          this.totalCount = r.data.offers?.totalCount;
          this.endCursor = r.data.offers?.pageInfo.endCursor;
        }
        if (!r.loading && sub?.unsubscribe) {
          sub?.unsubscribe?.();
        }
      }
    );
  }

  private parseEdges(input): OrderOfferStatsTableItem[] {
    let tableData =  input?.edges?.map(
      edge => {
        const node = edge.node;
        const interview = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.Interview)[0];
        const clientInterview = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.ClientInterview)[0];
        const employment = node.candidateEvents && node.candidateEvents
          .edges.map(it => it.node)
          .filter(it => it.eventType === EventEventType.Employment)[0];
        const events = node.candidateEvents?.edges || [];

        return {
          candidateId: node.candidate?.livasId,
          name: node.candidate ? `${node.candidate.firstName} ${node.candidate.lastName}` : 'Not found',
          livasStatus: (node.candidate?.livasStatus)
                        ? node.candidate?.livasStatus['title' + this.lang.charAt(0).toUpperCase() + this.lang.slice(1).toLowerCase()]
                        : 'NA',
          interestedOnCall: node.interestedOnCall,
          interested: node.interested,
          comment: node.comment,
          interviewDatetime: interview ? interview.startDatetime : null,
          clientInterviewDatetime: clientInterview ? clientInterview.startDatetime : null,
          clientInterviewStatus: clientInterview ? clientInterview.resolutionStatus : null,
          clientTitle: 'Klientas',
          signed: node.signed,
          signedAt: node.signedAt,
          employmentDatetime: employment ? employment.startDatetime : null,
          id: node.id,
          eventSet: node.eventSet,
          cancellationReasons: node.cancellationreasonassignmentSet?.edges?.map(it => it.node) || [],
          offerpropositioncommentSet: node.offerpropositioncommentSet,
          candidateEvents: node.candidateEvents,
          longListEvent: this.getLongListedEvent(node.longListed),
          introductionCallEvent: this.getOfferEvent(node.interestedOnCall as any, true),
          officeInterviewInvitationEvent: this.getProgressBarEventFromEvent(events, EventEventType.Interview, 2),
          officeInterviewEvent: this.getOfferEvent(node.interested as any, false, node.firstInterestedAt),
          presentToClientEvent: this.getProgressBarEventFromEvent(events, EventEventType.ToBePresented, 4),
          clientInterviewEvent: this.getProgressBarEventFromEvent(events, EventEventType.ClientInterview, 5),
          healthcheckEvent: this.getProgressBarEventFromEvent(events, EventEventType.HealthCheckPending, 6),
          employmentEvent: this.getProgressBarEventFromEvent(events, EventEventType.Employment, 7),
        };
      }
    );

    tableData = this.sortByAdvancedLevel(tableData);
    return tableData;
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: OrderOfferStatsTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSort(sort: MatSort) {
    let orderBy = { orderBy: 'candidate__first_name,candidate__last_name' };

    if (this.sort?.active) {
      orderBy = { orderBy: `${this.sort.direction === 'desc' ? '-' : ''}${this.sort.active}` };
    }

    return orderBy;
  }

  private getLongListedEvent(value): ProgressBarEvent | null {
    const statusMap = {
      [OfferPropositionLongListed.Included]: 'SUCCESS',
      [OfferPropositionLongListed.RejectedWithoutCall]: 'FAILED'
    };
    const status = value in statusMap ? statusMap[value] : null;

    return {status, icon: 'playlist_add_circle', index: 0};
  }

  private getOfferEvent(value: ResponseTypes, onCall: boolean, dateTime?: Date): ProgressBarEvent | null {
    const statusMap = {
      [ResponseTypes.agreed]: 'SUCCESS',
      [ResponseTypes.undecided]: 'PENDING',
      [ResponseTypes.notInterested]: 'FAILED',
    };
    const icon = onCall ? 'check_circle_outline' : 'thumb_up';

    return {status: value ? statusMap[value] : null , icon, index: onCall ? 1 : 3, startDatetime: dateTime};
  }

  private getProgressBarEventFromEvent(events, value: EventEventType, index: number): ProgressBarEvent | null {
    const eventNode = events.find(n => n.node.eventType === value)?.node;
    const icons = {
      [EventEventType.Interview]: 'meeting_room',
      [EventEventType.ToBePresented]: 'co_present',
      [EventEventType.ClientInterview]: 'event',
      [EventEventType.HealthCheckPending]: 'fact_check',
      [EventEventType.Employment]: 'receipt_long',
      [EventEventType.TrialDay]: 'forklift',
    };
    const statusMap = {
      [EventResolutionStatus.Complete]: 'SUCCESS',
      [EventResolutionStatus.Failed]: 'FAILED',
      [EventResolutionStatus.Cancelled]: 'FAILED',
      [EventResolutionStatus.Rejected]: 'REJECTED'
    };
    const status = eventNode ? (eventNode.resolutionStatus ? statusMap[eventNode.resolutionStatus] : 'PENDING') : null;

    return {status, icon: icons[value], index, id: eventNode?.id, startDatetime: eventNode?.startDatetime};
  }

  sortByAdvancedLevel(list: any[]): any[] {
    return list.sort((a, b) => b.candidateEvents?.edges.length - a.candidateEvents?.edges.length);
  }
}
