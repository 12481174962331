import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddonsImportComponent } from './addons-import.component';
import { AddonsImportRoutingModule } from './addons-import-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddonsImportComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    BrowserModule,
    NgxUploaderModule,
    AddonsImportRoutingModule,
    FlexLayoutModule,
    ScrollingModule,
    SharedModule,
    TableVirtualScrollModule,
    TranslateModule
  ],
})
export class AddonsImportModule {}
