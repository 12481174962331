import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserProfile } from 'src/app/graphql/graphql';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-project-manager-selector',
  templateUrl: './project-manager-selector.component.html',
  styleUrls: ['./project-manager-selector.component.scss']
})
export class ProjectManagerSelectorComponent {

  managerCtrl = new UntypedFormControl('', Validators.required);
  filteredManagers: Observable<UserProfile[]>;

  private managers$ = [];

  @Input()
  set managers(managers: UserProfile[]) {
    this.managers$ = managers;
    this.filteredManagers = this.managerCtrl.valueChanges
      .pipe(
        map(value => this._filter(value))
      );
  }
  get managers(): UserProfile[] {
    return this.managers$;
  }

  @Input()
  set selectedManager(manager: UserProfile) {
    if (manager) {
      this.managerCtrl.setValue(manager);
    }
  }

  @Output() managerChange = new EventEmitter<string | boolean>();

  constructor() { }

  displayFn(manager?: UserProfile): string | undefined {
    return manager ? `${manager.user.firstName} ${manager.user.lastName}` : undefined;
  }

  private _filter(value): UserProfile[] {
    this.managerChange.emit(value && value.id);
    if (!value.id) {
      return this.managers.filter(manager => 
        `${manager.user.firstName} ${manager.user.lastName}`.toLowerCase().includes(value.toLowerCase()));
    }
    return this.managers;
  }

}
