<div fxLayout="row" fxLayoutAlign="space-between" class="mat-typography">
  <h4>{{ 'SCHEDULE_AN_EVENT' | translate }} {{data.eventType}} {{data.relatedOffer}}</h4>
  <button mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayout="column  ">
  <div *ngIf="presentData.length > 0 && false" class="mat-popup-border">
    <span>{{ 'OTHER_PLANNED_EVENTS' | translate }}:</span>
    <div fxLayout="column">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" *ngFor="let data of tempPresentData" class="popup-head">
        <h6 fxFlex="20">{{ data.eventType }}</h6>
  <!--      <h6>{{ data.company.name }}</h6>-->
  <!--      <h6>{{ data.company.status }}</h6>-->
        <h6 fxFlex="20">{{ data.candidate.firstName }} {{ data.candidate.lastName }}</h6>
        <h6 fxFlex="30">{{ data.startDatetime | date: 'yyyy-MM-dd H:mm' }}</h6>

        <div class="actions" fxFlex="30">
          <mat-icon
            *ngIf="
               data.resolutionStatus == null
            "
            (click)="updateEvent(data.id, 'CANCELLED')"
            style="cursor: pointer;"
            matTooltip="{{ 'CANCEL' | translate }}"
            >close</mat-icon
          >
          <mat-icon
            *ngIf="data.resolutionStatus == null"
            (click)="updateEvent(data.id, 'COMPLETE')"
            matTooltip="{{ 'COMPLETE' | translate }}"
            style="cursor: pointer;"
            >done</mat-icon
          >
          <span *ngIf="data.resolutionStatus != null" class="event-status-pill {{ data.resolutionStatus | lowercase }}">
            {{ data.resolutionStatus | lowercase }}
            <!-- {{ 'CANCELLED' | translate }} -->
          </span>
        </div>

      </div>
    </div>

    <div class="see-more-btn"  *ngIf="this.tempPresentData.length>5 && false">
      <button
        mat-flat-button
        color="accent"
        (click)="showMore()"
        class="mat-box-btn show-more-btn"
      >
        <mat-icon class="arrow-icon" *ngIf="this.tempPresentData.length != this.presentData.length"
          >keyboard_arrow_down</mat-icon
        >
        <mat-icon class="arrow-icon" *ngIf="this.tempPresentData.length == this.presentData.length"
          >keyboard_arrow_up</mat-icon
        >
        {{
          (this.tempPresentData.length != this.presentData.length
            ? 'SHOW_MORE'
            : 'SHOW_LESS'
          ) | translate
        }}
      </button>
    </div>
  </div>

  <div style="padding-left: 12px">
      <!-- <mat-checkbox  *ngIf="presentData.length > 0"
                     color="primary"
                     class="mat-col-checkbox"
                     [(ngModel)]="cancelEvents">
        {{ 'CANCEL_ALL_PLANNED_EVENTS' | translate }}
      </mat-checkbox> -->

      <div fxLayout="row" fxLayoutGap="8px" class="col-border-box">
        <mat-form-field>
          <mat-label>{{ 'CHOOSE_DATE' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="max-width: 80px;">
          <mat-label>{{ 'TIME' | translate }}</mat-label>
          <input matInput [(ngModel)]="hour" />
          <mat-icon matSuffix inline="true">schedule</mat-icon>
        </mat-form-field>
      </div>

      <!-- <mat-form-field style="width: 100%; margin-top: 8px;" appearance="outline">
        <mat-label>{{ 'SMS_TEXT' | translate }}</mat-label>
        <textarea matInput [(ngModel)]="sms"></textarea>
      </mat-form-field> -->

      <div fxLayout="row" fxLayoutGap="10px">
        <button *ngIf="data?.interviewEvent" (click)="updateEvent(data?.interviewEvent?.id, 'CANCELLED')" mat-flat-button color="warn">
          <mat-icon>close</mat-icon> Cancelled or noshow
        </button>


        <button *ngIf="data?.interviewEvent" (click)="updateEvent(data?.interviewEvent?.id, 'FAILED')" mat-flat-button color="warn">
          <mat-icon>thumb_down</mat-icon> Client refused
        </button>

        <div fxFlex>
          <!-- <mat-checkbox color="primary" [(ngModel)]="sendSms" class="mat-box-check">
            {{ 'SEND_SMS' | translate }}
          </mat-checkbox> -->
          <button mat-flat-button color="accent" (click)="save()">
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </div>
  </div>

</mat-dialog-content>
