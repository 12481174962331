import { Inject, Injectable } from '@angular/core';
import {Query} from 'apollo-angular';
import gql from 'graphql-tag';

export interface SpecificationsListItem {
  positionTitle: string;
  name: string;
  id: number;
  company: {name: string};
  workFunction: string;
  address: string;
}

const Fragments = {
  specListFields: gql`
     fragment SpecificationListFields on SpecificationType {
        id
        name
        positionTitle
        company {
          name
          color
        }
        workFunctions
        address
     }`
};


@Injectable()
export class SpecificationsListGQL extends Query<{specifications: SpecificationsListItem[]}> {
  document = gql`
    query getSpecificationList {
      specifications {
        ...SpecificationListFields
      }
    }
    ${Fragments.specListFields}
  `;
}
