<!-- rating-with-comment-input.component.html -->
<div *ngIf='valueController' class='form-group' [formGroup]='valueController'>
  <mat-label for='ratingWithCommentInput' class='text-muted'>{{label}}</mat-label>
  <div>
    <mat-icon *ngFor='let rating of [1, 2, 3, 4, 5]'
              (click)='updateRating(rating)'
              class='rating-star'
              [class.selected]="valueController.get('numericValue').value >= rating">
      star
    </mat-icon>
  </div>
  <mat-form-field appearance='outline' style='width: 100%'>
    <mat-label>{{hint | translate}}</mat-label>
    <textarea type='text' class='form-control mt-2'
              matInput
              id='ratingWithCommentInput'
              [formControlName]="'textValue'">
    </textarea>
  </mat-form-field>
</div>
