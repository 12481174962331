import {Component, ViewChild, OnInit, ChangeDetectorRef, AfterContentInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { interval, merge, Observable } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

import * as LogRocket from 'logrocket';
import { ToolbarMessageService } from 'src/app/toolbar-message.service';
import { SidenavService } from '../../shared/sidenav.service';
import { ToolbarService } from '../toolbar.service';
import { UnseenOrdersService } from 'src/app/orders/unseen-orders.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
// import {UserProfileGQL} from '../../auth/graphql';
import { MatSidenav } from '@angular/material/sidenav';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-nagivation',
  templateUrl: './nagivation.component.html',
  styleUrls: ['./nagivation.component.scss']
})
export class NagivationComponent implements OnInit, AfterContentInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  isOpened$: Observable<boolean> = merge(
    this.sidenavService.openedSubject.asObservable(),
    this.isHandset$.pipe(map(v => !v))
  ).pipe(
    debounce(() => interval(0))
  );

  @ViewChild('sidenav') sidenav: MatSidenav;

  toolbarText;
  showToolbar$: Observable<boolean>;
  unseenMessages: number;
  showAlert;
  env = environment.env;
  locales = { en: 'en-EN', lv: 'lv-LV', ee: 'et-ET', lt: 'lt-LT' };
  languages = [
    { path: 'assets/flags/lt.svg', name: 'lt' },
    { path: 'assets/flags/lv.svg', name: 'lv' },
    { path: 'assets/flags/ee.svg', name: 'et' },
    { path: 'assets/flags/gb.svg', name: 'en' }
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private toolbarMessage: ToolbarMessageService,
    private sidenavService: SidenavService,
    private toolbarService: ToolbarService,
    private unseenOrdersService: UnseenOrdersService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) {
    this.validateAuth();
    this.showToolbar$ = this.toolbarService.isOpenToolbar$.asObservable();
  }

  ngAfterContentInit() {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.screen === 'full') {
        this.toolbarService.hide();
      }

      let lang = this.languageService.getLanguage() || this.languages[0].name;
      lang = params.locale ? (params.locale === 'ee' ? 'et' : params.locale) : lang;
      this.changeLanguage(lang);

    });
    // const queryParams = this.activatedRoute.snapshot.queryParams;

  }

  validateAuth(): void {
    if (!!localStorage.getItem('token')) {
      return;
    }

    if (location.pathname.indexOf('/absences/') !== -1 && location.search.indexOf('sessionId=') !== -1) {
      return;
    }

    if (location.pathname.indexOf('login-callback') === -1) {
      this.auth.adminLogin();
    }
  }

  ngOnInit(): void {

    if (environment.production && (window.location.href.indexOf('/interview/') === -1)) {
      // LogRocket.init('biuro-baltic/boris-uhpvp');
      // this.userprofileGQL.fetch().subscribe(resp => {
      //   if (LogRocket && LogRocket.identify) {
      //     LogRocket.identify(resp.data.userprofile.user.email);
      //   }
      // });

    }

    this.toolbarMessage.messageSubject.subscribe(res => {
      this.toolbarText = res;
    });

    this.unseenOrdersService.getSubject().subscribe(res => {
      this.unseenMessages = res.unseenAccepted + res.unseenRejected;
    });

    this.subscribeToNotification();
  }


  private subscribeToNotification() {
    this.toolbarMessage.onDutyNotification.subscribe(res => {
      this.showAlert = res;
    });
  }



  onNotificationDismiss() {
    localStorage.setItem('on-duty-notif-dismiss', moment().date().toString());
    this.toolbarMessage.setOnDutyNotification(false);
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  changeLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
    this.cdr.detectChanges();
  }

  compareLanguage(lang: string){
    return this.languageService.getLanguage() === lang;
  }

}
