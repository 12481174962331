import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { UserProfile } from '../graphql/graphql';

export interface ProjecManagerResponse {
  userprofiles: UserProfile[];
  currentUser: UserProfile;
}

@Injectable({providedIn: 'root'})
export class ProjectManagerGQL extends Query<ProjecManagerResponse> {
  document = gql`
    query {
      userprofiles {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
      currentUser:userprofile {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  `;
}

@Injectable({providedIn: 'root'})
export class StatsForOrdersGQL extends Query<{statsOrderStatsForPeriod: OrderStatsResponse}> {
  document = gql`
    query($dateFrom: Date!, $dateTo: Date!, $projectManagerIds:[ID]) {
      statsOrderStatsForPeriod(dateFrom: $dateFrom, dateTo: $dateTo, projectManagerIds: $projectManagerIds) {
        created
        sent
        confirmed
        cancelled
      }
    }
  `;
}

@Injectable({providedIn: 'root'})
export class StatsForCandidateOrdersGQL extends Query<{statsOrderCandidateStatsForPeriod: OrderCandidateStatsResponse}> {
  document = gql`
    query($dateFrom: Date!, $dateTo: Date!) {
      statsOrderCandidateStatsForPeriod(dateFrom: $dateFrom, dateTo: $dateTo) {
        total,
        interested,
        signed
      }
    }
  `;
}

export interface OrderStatsResponse {
  created: number,
  sent: number,
  confirmed: number,
  cancelled: number
}

export interface OrderCandidateStatsResponse {
  total: number,
  interested: number,
  signed: number
}
