<div class="log-items-table">
  <div fxLayout="row" fxLayoutGap="16px" class="filter">
    <mat-form-field style="width: 36%;">
      <mat-label>{{'DATE_RANGE' | translate}}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate readonly formControlName="start" [placeholder]="'ORDER.START_DATE' | translate">
        <input matEndDate readonly formControlName="end" [placeholder]="'ORDER.END_DATE' | translate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>

    <app-filter-category-select
      [filterBarView]="false"
      [categoryCtrl]="categoryCtrl"
      (clearFilter)="categoryCtrl.setValue([])"
    ></app-filter-category-select>
  </div>
  <app-log-items-table
    [dateRange]="[range.get('start').value, range.get('end').value]"
    [categories]="categoryCtrl.value"
    [companyId]="companyId"
    [showActions]="false"
    [readonly]="true"
    [listenToValueChanges]="false"
  ></app-log-items-table>
</div>
