<div class="container">
    <table mat-table [dataSource]="rows" *ngIf="rows.length">
        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <ng-container matColumnDef="name" sticky>
            <th
                class="header-name"
                fxLayoutAlignmat-header-cell
                *matHeaderCellDef
            >
                {{ 'EMPLOYEE' | translate }}
            </th>
            <td class="column-name" mat-cell *matCellDef="let row">
                <div class="employee">
                    <div class="employee-name" matTooltip="{{ row.name }}" matTooltipClass="custom-tooltip">{{ row.name }}</div>
                    <mat-icon class="error-icon" matTooltip="{{ row.error | translate }}" matTooltipClass="custom-tooltip" *ngIf="row.error">error_outline</mat-icon>
                </div>
            </td>
        </ng-container>

        <ng-container
            *ngFor="let col of displayedColumns.slice(1)"
            [matColumnDef]="col"
        >
            <th class="header" fxLayoutAlignmat-header-cell *matHeaderCellDef>
                {{ col ? col.substr(5) : '' }}
            </th>
            <td mat-cell class="column" *matCellDef="let row">
                <app-absences-table-column
                    [date]="col"
                    [content]="row[col]"
                    [employeeId]="row.employeeId"
                    [openAbsencesDialog]="openAbsencesDialog"
                ></app-absences-table-column>
            </td>
        </ng-container>
    </table>
    <div *ngIf="!rows.length" class="no-records">
        {{ 'NO_ABSENCES_EXIST' | translate }}
    </div>
</div>
