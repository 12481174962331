import { Component, OnInit } from '@angular/core';
import {Form, FormBuilder, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-free-vacancies-list',
  templateUrl: './free-vacancies-list.component.html',
  styleUrls: ['./free-vacancies-list.component.scss']
})
export class FreeVacanciesListComponent implements OnInit {
  selectedTabIndex = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleService.setTitle('Free Vacancies');
  }

  ngOnInit() {
    if (this.route.snapshot.routeConfig.path === 'list/marketing-matrix') {
      this.selectedTabIndex = 1;
    } else if (this.route.snapshot.routeConfig.path === 'list/calendar') {
      this.selectedTabIndex = 2;
    }
  }

  tabsNavigator(event) {
    if (event === 0) {
      this.router.navigate(['free-vacancies/list']).then();
    } else if (event === 1) {
      this.router.navigate(['free-vacancies/list/marketing-matrix']).then();
    } else if (event === 2) {
      this.router.navigate(['free-vacancies/list/calendar']).then();
    }
  }
}
