<div class="mat-elevation-z0">
  <div fxLayout="row" fxLayoutAlign="cenet center" class="filter-bar-stats">
    <app-filter-bar fxFlexAlign="end" *ngIf="!hideFilters" [style.margin-right]="'-50px'" [filters]="['dateRange']" (filterChanges)="onFilterChange($event)"></app-filter-bar>

    <button *ngIf="dateRange.length && !filters" mat-icon-button (click)="sendOrderSummaries()">
      <mat-icon>send</mat-icon>
    </button>

    <app-order-stats [hidden]="!dateRange[0]" style="width: 100%;" [dateRange]="dateRange" [orderId]="orderId"></app-order-stats>
  </div>

  <table mat-table class="full-width-table" matSort aria-label="Elements" >
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th class="borderless" mat-header-cell *matHeaderCellDef mat-sort-header="candidate__first_name,candidate__last_name">{{'ORDER.NAME' | translate}}</th>
      <td mat-cell *matCellDef='let row'>
        <a href='{{getCandidateUrl(row)}}' target='_blank' mat-icon-button inline>
          {{row.name}}
          <mat-icon inline>open_in_new</mat-icon>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="livas-status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="candidate__livas_status__title_lt" >{{'LIVAS_STATUS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{row.livasStatus}}
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="interested_on_call">{{ 'PHONE_INTERVIEW' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.interestedOnCall===respTypesCall.Interested" class="green">
          check_circle
        </mat-icon>

        <mat-icon *ngIf="row.interestedOnCall===respTypes.NotInterested" class="red">
          highlight_off
        </mat-icon>

        <mat-icon *ngIf="row.interestedOnCall===respTypes.Undecided" class="blue">
          not_interested
        </mat-icon>

      </td>
    </ng-container>

    <ng-container matColumnDef="interview">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="interested">{{ 'OFFICE' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div fxLayout="column">
          <div *ngIf="row.interested" matTooltip="{{getTooltipTextForOffice(row)}}">
            <mat-icon *ngIf="row.interested === respTypes.Interested" class="green">check_circle</mat-icon>
            <mat-icon *ngIf="row.interested === respTypes.NotInterested" class="red">highlight_off</mat-icon>
            <mat-icon *ngIf="row.interested === respTypes.Undecided" class="blue">contact_support</mat-icon>

          </div>
          <mat-hint>
            <span class="date--info">{{row.interviewDatetime | date: 'yyyy-MM-dd HH:mm'}}</span>
          </mat-hint>
        </div>


      </td>
    </ng-container>

    <ng-container matColumnDef="clientInterview">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLIENT_INTERVIEW' | translate }}</th>
      <td mat-cell *matCellDef='let row'>
        <div *ngIf='row.clientInterviewDatetime || row.clientInterviewStatus'
             [matTooltip]='getClientInterviewTootip(row)'>
          <mat-icon *ngIf='row.clientInterviewStatus === resolutionStatuses.Complete' class='green'>check_circle
          </mat-icon>
          <mat-icon *ngIf='row.clientInterviewStatus === resolutionStatuses.Failed' class='red'>highlight_off</mat-icon>
          <mat-icon *ngIf='row.clientInterviewStatus === resolutionStatuses.Cancelled' class='blue'>contact_support
          </mat-icon>
        </div>
        <!--        <div *ngIf="row.clientInterviewDatetime" fxLayout="column">-->
        <!--          <span >-->
        <!--            {{row.clientInterviewDatetime | date: 'yyyy-MM-dd HH:mm'}}-->
        <!--          </span>-->
        <!--          <mat-hint>{{row.clientTitle}}</mat-hint>-->
        <!--        </div>-->


      </td>
    </ng-container>

    <ng-container matColumnDef="contract">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="signed_at">{{'CONTRACT' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="row.signed" fxLayout="column">
          <mat-icon *ngIf="row.signed" class="green">check_circle</mat-icon>
          <span class="date--info">
            {{row.employmentDatetime | date: 'yyyy-MM-dd'}}
          </span>
          <mat-hint class="date--info">
            {{row.signedAt | date: 'yyyy-MM-dd'}}
          </mat-hint>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef >{{'COMMENT' | translate}}</th>
      <td mat-cell *matCellDef="let row" >
<!--        <div *ngIf="row.signed" fxLayout="column">-->
          <div style="justify-content: space-between; align-items: center; width: 100%; display: flex;" >
            <span>{{row.offerpropositioncommentSet.totalCount ? row.offerpropositioncommentSet.edges[0]?.node.text : ''}}</span>
            <button *ngIf="row.offerpropositioncommentSet.totalCount" 
                    mat-icon-button 
                    >
              <mat-icon 
                    [matBadgeSize]="'small'"
                    [matBadgeColor]="'accent'"
                    [matBadge]="'+' + row.offerpropositioncommentSet.totalCount"
                    [matBadgeHidden]="row.offerpropositioncommentSet.totalCount == 1"
                    (click)="openCommentDialog(row)"
                    inline="true"
              
                >comment</mat-icon>  
            </button>

          </div>
          
<!--        </div>-->
      </td>
    </ng-container>

    <ng-container matColumnDef="cancellation-reasons">
      <th mat-header-cell *matHeaderCellDef >{{ 'REASONS' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let cr of row.cancellationReasons" class="cancellation-reason">
          {{cr?.cancellationReason?.title}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <h4 style="text-align: center; margin-bottom: 0;">
          {{ 'NO_ONE_WAS_OFFERED_THIS_POSITION' | translate }}
        </h4>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.totalCount"
      [pageIndex]="0"
      [pageSize]="50"
      [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
