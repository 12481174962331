<div [formGroup]="form" fxLayout="column">
  <mat-form-field appearance="outline" >
    <mat-label>{{'REMUNERATION_AMOUNT' | translate }}</mat-label>
    <textarea matInput formControlName="remunerationAmount" [rows]="5"></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline"  >
    <mat-label>{{'PLANNED_TERM_FOR_PROVISION' | translate }}</mat-label>
    <textarea matInput formControlName="plannedTermForProvision" [rows]="5"></textarea>
  </mat-form-field>

  <div class="include-desc">
    <mat-checkbox color="primary" [formControl]="form.get('displayPlannedTermForProvision')">
      {{'ORDER.INCLUDE_PLANNED_TERM_IN_ORDER' | translate}}
    </mat-checkbox>
  </div>

  <mat-form-field appearance="outline" fxFlex="100">
    <mat-label>{{'PROVISION_OF_INFORMATION_ON_CONCLUSION' | translate }}</mat-label>
    <textarea matInput formControlName="provisionOfInformationOnConclusion" [rows]="5"></textarea>
  </mat-form-field>

  <mat-form-field *ngIf="!isPartialSelection" appearance="outline" fxFlex="100">
    <mat-label>{{'WARRANTY_PERIOD' | translate }}</mat-label>
    <textarea matInput formControlName="warrantyPeriod" [rows]="5"></textarea>
  </mat-form-field>
</div>
