import { Injectable } from '@angular/core';
import {
  CreateCancellationReasonAssignmentsGQL,
  GetCancellationReasonsForOfferGQL,
  GetCancellationReasonsGQL, UpdateLivasCandidateStatusByOfferPropositionGQL
} from '../../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CancellationServiceService {

  constructor(
    private getAllReasons: GetCancellationReasonsGQL,
    private getSavedAssignmentsGWQL: GetCancellationReasonsForOfferGQL,
    private createAssignments: CreateCancellationReasonAssignmentsGQL,
    private updateLivasStatusByOfferGQL: UpdateLivasCandidateStatusByOfferPropositionGQL

  ) { }


  getGroups(country: string) {
    return this.getAllReasons.watch({country}, {fetchPolicy: 'cache-and-network'}).valueChanges;
  }


  getSavedAssignments(offerPropositionId: number) {
    const offerPropositionRelayId = btoa(`OfferPropositionType:${offerPropositionId}`);
    return this.getSavedAssignmentsGWQL.watch(
      {offerPropositionId: offerPropositionRelayId},
      {fetchPolicy: 'cache-and-network'})
      .valueChanges;
  }

  saveAssignments(offerPropositionId: number, reasons: string[]) {
    const offerProposition = btoa(`OfferPropositionType:${offerPropositionId}`);
    return this.createAssignments.mutate(
      {offerProposition, cancellationReasons: reasons});
  }

  updateLivasStatus(offerPropositionId: string, statusId: number) {
    return this.updateLivasStatusByOfferGQL.mutate({offerPropositionId, livasStatusId: statusId});
  }
}
