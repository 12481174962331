<div *ngIf="step === 1" fxFlex fxLayout="column">
  <h2 *ngIf="title" mat-dialog-title>{{ title }} - {{ permission && permission.label }}</h2>
  <mat-dialog-content class="mat-typography" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start">
      <h3 *ngIf="headline">{{ headline }}</h3>
      <div class="sodra-details">
        <div *ngFor='let data of sodraData'>
          ( {{ data.personal_code }}, SODRA: {{data.period_from}} - {{data.period_to}}, {{data.incapacity_reason}}
          <span *ngIf="absencesService.country === 'lt'">
            , {{ 'TO_PAY_THE_POLICYHOLDER' | translate}} {{ (data.paid ? 'YES' : 'NO') | translate | uppercase }}
          </span>
          )
        </div>
      </div>
    </div>

    <div style="padding: 0 24px 24px 0">
      <form
        [formGroup]="absenceForm"
        fxLayout="column"
        fxLayout.gt-xs="row wrap"
        fxLayoutGap="24px grid"
      >
        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>{{ 'FROM' | translate }}</mat-label>
            <input
              formControlName="from"
              [min]="minDate"
              [max]="maxDate"
              matInput
              [matDatepicker]="pickerFrom"
              (focus)="pickerFrom.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>{{ 'TO' | translate }}</mat-label>
            <input
              formControlName="to"
              [min]="minDate"
              [max]="maxDate"
              matInput
              [matDatepicker]="pickerTo"
              (focus)="pickerTo.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>{{ 'EMPLOYER' | translate }}</mat-label>
            <mat-select formControlName="employerId" required>
              <mat-option
                *ngFor="let employer of employers"
                [value]="employer.value"
                >{{ employer.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxFlex.gt-md="25%">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>{{ 'ABSENCE TYPE' | translate }}</mat-label>
            <mat-select formControlName="absence_type" required>
              <mat-option
                matTooltip="{{ absence.tooltip }}"
                matTooltipPosition="before"
                *ngFor="let absence of absences"
                value="{{ absence.value }}"
                disabled="{{ isAbsenceTypeDisabled(absence) }}"
                >{{ absence.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div
          fxFlex="50"
          fxFlex.gt-md="25%"
          [style.visibility]="reasons && reasons.length ? 'visible' : 'hidden'"
        >
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>{{ 'REASON' | translate }}</mat-label>
            <mat-select formControlName="reason_code">
              <mat-option
                matTooltip="{{ reason.title }}"
                matTooltipPosition="before"
                *ngFor="let reason of reasons"
                [disabled]='isDisabledReason(reason)'
                value='{{ reason.value }}'
                >{{ reason.title }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div
          fxFlex="50%"
          [style.visibility]="reasons && reasons.length ? 'visible' : 'hidden'"
        >
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label> {{ 'REASON_TEXT' | translate }}</mat-label>
            <input matInput formControlName="reason_text" />
          </mat-form-field>
        </div>
      </form>
    </div>

    <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutGap="24px grid" class="absence-details">
      <div fxFlex="100%" fxFlex.gt-sm="50%">
        <h4>{{ 'EXISTING SHIFTS' | translate }}</h4>
        <div *ngIf="!isLoading">
          <div *ngIf="permission && permission.shifts.length">
            {{ 'CHECK_ARRIVED_SHIFTS' | translate }}:
          </div>
          <mat-list *ngIf="permission && permission.shifts.length">
            <mat-list-item *ngFor="let shift of permission.shifts">
              <mat-slide-toggle
                (change)="$event ? toggleShiftSelection(shift.id) : null"
                [checked]="!shiftsSelection.isSelected(shift.id)"
                [disabled]="
                  (!isUpdateMode && !shift.permissions.create) ||
                  (isUpdateMode && !shift.permissions.update) ||
                  shift.permissions.readonly ||
                  isUpdatePreSave
                "
                style="width: 160px"
              >
                {{
                  (shiftsSelection.isSelected(shift.id)
                    ? 'DID NOT COME'
                    : 'ARRIVED'
                  ) | translate
                }}
              </mat-slide-toggle>
              {{ shift.date }} {{ shift.title ? '- ' + shift.title : '' }}
            </mat-list-item>
          </mat-list>
          <p *ngIf="permission && !permission.shifts.length">
            {{ 'THERE ARE NO EXISTING SHIFTS IN SELECTED PERIOD' | translate }}
          </p>
        </div>
        <mat-spinner
          class="list-loader"
          diameter="24"
          *ngIf="isLoading"
        ></mat-spinner>
      </div>
      <div fxFlex="100%" fxFlex.gt-sm="50%">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h4>{{ 'EXISTING ABSENCES' | translate }}</h4>
          <div class="checkbox-container" [style.visibility]="isPayable ? 'visible' : 'hidden'">
            <mat-checkbox
              [checked]="isFullyUnpaid"
              [indeterminate]="isPartialUnpaid"
              (change)="toggleMultipleAbsenceUnpaid($event.checked)"
            >{{ 'UNPAID' | translate }}</mat-checkbox>
          </div>
        </div>
        <div *ngIf="!isLoading">
          <div>
            {{ 'ABSENCES THAT WILL BE EFFECTED' | translate }}:
          </div>
          <mat-list>
            <mat-list-item *ngFor="let absence of periodAbsences | keyvalue; let i = index" style="height: 32px; font-size: 14px">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="absence-item">
              <span class="absence-day">
                <span class="absence-day--no">{{ i + 1 }}.</span>
                <span class="absence-day--date" [style.borderBottomColor]="daysColors?.[absence.key] || transparent">{{ absence.key }}</span>
                <span class="absence-day--weekday">({{ getWeekday(absence.key) }})</span>
                <span *ngIf="periodAbsences[absence.key].absence_type"> - <b>{{ periodAbsences[absence.key].absence_type }}</b></span>
              </span>
              <mat-checkbox
                [style.visibility]="isPayable ? 'visible' : 'hidden'"
                [checked]="isAbsenceUnpaid(absence.key)"
                (change)="toggleAbsenceUnpaid(absence.key)"
              >{{ 'UNPAID' | translate }}</mat-checkbox>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <mat-spinner
          class="list-loader"
          diameter="24"
          *ngIf="isLoading"
        ></mat-spinner>
      </div>
    </div>
  </mat-dialog-content>
</div>

<form [formGroup]="deleteForm" *ngIf="step === 2">
  <h2 mat-dialog-title>{{ 'DELETE ABSENCES' | translate }}</h2>
  <mat-dialog-content class="mat-typography">
    <h3>
      {{ 'ARE YOU SURE YOU WANT TO REMOVE ABSENCES FOR' | translate }}
      {{ headline }} - {{ permission.label }} <b>{{ getRemoveDates() }}</b>
    </h3>


    <div
      fxLayout="column"
      fxLayout.gt-xs="row wrap"
      fxLayoutGap="24px grid"
      *ngIf="permission && permission.shifts.length"
    >
      <div fxFlex="100%" fxFlex.gt-sm="50%">
        <h4>{{ 'CHOOSE AN ACTION' | translate }}</h4>
        <mat-radio-group formControlName="shiftsAction">
          <mat-list>
            <mat-list-item>
              <mat-radio-button [value]="false">{{
                'LEAVE EMPLOYEE IN SHIFTS, CHANGE TO ARRIVALS' | translate
              }}</mat-radio-button>
            </mat-list-item>
            <mat-list-item>
              <mat-radio-button [value]="true">{{
                'REMOVE EMPLOYEE FROM SHIFTS WITH ABSENCE' | translate
              }}</mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>
      </div>
      <div fxFlex="100%" fxFlex.gt-sm="50%">
        <h4>{{ 'EXISTING SHIFTS' | translate }}</h4>
        <mat-list>
          <ng-container *ngFor="let shift of permission.shifts">
            <mat-list-item>
              {{ shift.date }} {{ shift.title ? '- ' + shift.title : '' }}
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
    </div>
  </mat-dialog-content>
</form>

<mat-dialog-actions
  fxLayout="{{ step === 1 ? 'row' : 'row-reverse' }}"
  fxLayoutAlign="space-between center"
>
  <div *ngIf="showVacations" [style.margin-right]="'auto'">
    <span [matTooltip]="'ACCUMULATED_VACATION_FOR_DATE' | translate">
      {{ 'UNUSED_VACATION_FOR_DATE' | translate }} - {{ unusedVacations || 0 }}
    </span>
  </div>
  <div>
    <button
      mat-stroked-button
      *ngIf="isUpdateMode || isUpdatePreSave"
      (click)="handleDeleteAbsence()"
      [disabled]="!isDeletable"
    >
      {{ (step === 1 ? 'DELETE' : 'CONFIRM DELETE') | translate }}
    </button>
  </div>
  <div>
    <button mat-button mat-dialog-close [disabled]="isLoading">
      {{ 'CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      color='primary'
      *ngIf='isUpdateMode && step === 1'
      (click)='handleCreateAbsence()'
      [disabled]='isLoading || !isUpdatable || isActionButtonDisabled || isMissingComment()'
    >
      {{ 'UPDATE ABSENCE' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="!isUpdateMode && step === 1"
      (click)="handleCreateAbsence()"
      [disabled]='isLoading || !isCreatable && !isUpdatePreSave || isActionButtonDisabled || isMissingComment()'
    >
      {{ (isUpdatePreSave ? 'UPDATE_IMPORT_DATA' : 'CREATE ABSENCE') | translate }}
    </button>
  </div>
</mat-dialog-actions>

