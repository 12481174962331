<mat-form-field class="example-chip-list" fxFlex="100" *ngIf="!readonly; else readonlyChips">
  <mat-chip-list #chipList aria-label="Fruit selection" fxFlex="100">
    <mat-chip
      *ngFor="let requirement of requirements"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(requirement)">
      {{requirement.requirement}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input fxFlex="100"
      [placeholder]="title"
      #fruitInput
      [formControl]="requirementCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelect($event)">
    <mat-option *ngFor="let req of filteredRequirements | async" [value]="req">
      {{req.requirement}}
    </mat-option>
  </mat-autocomplete>

  <button mat-icon-button (click)="addRequirement($event)">
    <mat-icon>add</mat-icon>
  </button>
</mat-form-field>

<ng-template #readonlyChips>
  <div fxLayout="column" fxLayoutGap="4px">
    <mat-chip-list #chipList aria-label="Fruit selection" fxFlex="100">
      <mat-chip *ngFor="let requirement of requirements"
                [selectable]="false"
                [removable]="false"
                class="readonly-chip">
        {{requirement.requirement}}
      </mat-chip>
      <mat-chip *ngIf="readMoreCount > 0" class="readonly-chip">
        +{{readMoreCount}}
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-template>
