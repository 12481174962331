import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarMessageService {

  messageSubject = new BehaviorSubject('');
  onDutyNotification = new BehaviorSubject(false);

  constructor() { }

  setMessage(message: string) {
    this.messageSubject.next(message);
  }

  clearMessage() {
    this.messageSubject.next('');
  }

  setOnDutyNotification(visible: boolean) {
    this.onDutyNotification.next(visible);
  }


}
