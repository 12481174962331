import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmStatsDashboardComponent } from './pm-stats-dashboard.component';
import { StandupDashboardComponent } from './standup-boards/standup-dashboard/standup-dashboard.component';

const routes: Routes = [
  { path: 'pm-dashboard', component: PmStatsDashboardComponent },
  { path: 'standups', component: StandupDashboardComponent },
  { path: 'standups/:id', component: StandupDashboardComponent },
  { path: 'standups/:id/:dateRange', component: StandupDashboardComponent },
  { path: 'standups/:id/:dateRange/:selectedMetric', component: StandupDashboardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PmStatsDashboardRoutingModule { }
