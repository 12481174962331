<mat-card
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngIf="new"
  class="new"
>
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="action.date"
      placeholder="Choose a date"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{'EXTERNAL_ID' | translate}}</mat-label>
    <input
      matInput
      [(ngModel)]="action.externalPlatformId"
      (ngModelChange)="unsavedChanges = true"
      type="number"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{'LIVAS_ID' | translate}}</mat-label>
    <input
      matInput
      [(ngModel)]="action.adId"
      (ngModelChange)="unsavedChanges = true"
      type="number"
    />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'CHANNELS' | translate }}</mat-label>
    <mat-select [(ngModel)]="action.channels" multiple>
      <mat-option *ngFor="let channel of channels" [value]="channel.id">{{
        channel.title
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'CATEGORY' | translate }}</mat-label>
    <mat-select [(ngModel)]="action.category">
      <mat-option *ngFor="let c of categories" [value]="c.id">{{
        c.title
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <app-popup-select-complete (changed)="onUserChange($event)"></app-popup-select-complete>
  </div>

  <button mat-icon-button fxFlex="50px" (click)="onSave()">
    <mat-icon>save</mat-icon>
  </button>
</mat-card>
