<button (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="sendingOptions"
        [disabled]="disabled"
        mat-icon-button>
  <mat-icon>send</mat-icon>
</button>

<mat-menu #sendingOptions="matMenu">
  <div style="margin-left: 16px" (click)="$event.stopImmediatePropagation()">
    <mat-form-field style="width: 200px">
      <input matInput [matDatepicker]="picker" placeholder="{{'DOCUMENT_DATE' | translate}}" [(ngModel)]="documentDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field style="width: 200px">
      <mat-select placeholder="{{'ORDER_PERIODS' | translate }}" [(ngModel)]="selectedOrderPeriods" multiple>
        <mat-option *ngFor="let period of orderPeriods" [value]="period.id">
          {{ period.dateFrom | date: 'dd.MM.yyyy' }} - {{ period.dateTo | date: 'dd.MM.yyyy' }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="hint-text"> {{ 'SELECTED_PERIODS' | translate: {count: selectedOrderPeriods.length} }}</div>
  </div>

  <mat-divider></mat-divider>
  <button mat-menu-item (click)="createDocument(confirmationMethods.OffSystem);" [disabled]="disabledMenuOption">
    <mat-icon>cloud_download</mat-icon>{{'DOWNLOAD_PDF' | translate}}
  </button>

  <button mat-menu-item
          (click)="createDocument(confirmationMethods.Dokobit);" [disabled]="disabledMenuOption">
    <img src="/assets/dokobit-favicon.png" width="24" height="24" style="padding-top: 12px; margin-right: 16px">
    <span style="vertical-align: top">{{'ELECTRONIC_SIGN_PDF' | translate}}</span>
  </button>

  <button mat-menu-item (click)="createDocument(confirmationMethods.WebConfirm);" [disabled]="disabledMenuOption">
    <mat-icon>mail_outline</mat-icon>
    {{'SEND_EMAIL_FOR_CONFIRMATION' | translate}}
  </button>
</mat-menu>
