
<mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)='tabsNavigator($event)'>
  <mat-tab [label]="'FREE_VACANCIES.TITLE' | translate">
    <ng-template matTabContent>
      <app-free-vacancies-table mode="recruitment"></app-free-vacancies-table>
    </ng-template>

  </mat-tab>

  <mat-tab [label]="'MARKETING_MATRIX' | translate">
    <ng-template matTabContent>
      <app-free-vacancies-table mode="marketing"></app-free-vacancies-table>
    </ng-template>

  </mat-tab>
  <mat-tab [label]="'CALENDAR' | translate">
    <ng-template matTabContent>
      <app-fv-marketing-calendar></app-fv-marketing-calendar>
    </ng-template>

  </mat-tab>
</mat-tab-group>
