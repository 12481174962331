import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-hyperlink-customfield',
  templateUrl: './hyperlink-customfield.component.html',
  styleUrls: ['./hyperlink-customfield.component.scss']
})
export class HyperlinkCustomfieldComponent {
  @Input() label: string;
  @Input() valueController: FormGroup;

  constructor() {
  }
}
