<h4>{{ candidateFullName }}</h4>

<mat-dialog-content>


  <div class='row'>
    <div class='column left'>{{ 'WORK_EXPERIENCE' | translate }}</div>
    <div class='column right'>
      <div class='textarea-container'>
        <textarea [(ngModel)]='workExperience'></textarea>
      </div>
    </div>
  </div>

  <div *ngFor='let requirement of requirements; let i = index' class='row'>
    <div class='column left'>{{ requirement.requirement | translate }}</div>
    <div class='column right'>
      <div class='textarea-container'>
        <textarea [(ngModel)]='comments[requirement.id]'></textarea>
      </div>
    </div>
  </div>

  <div class='row'>
    <div class='column left'>{{ 'ADDITIONAL_INFO_ABOUT_CANDIDATE' | translate }}</div>
    <div class='column right'>
      <div class='textarea-container'>
        <textarea [(ngModel)]='additionalInfo'></textarea>
      </div>
    </div>
  </div>

  <div class='row'>
    <div class='column left'>{{ 'STARTS_WORKING' | translate }}</div>
    <div class='column right'>
      <div class='textarea-container'>
        <textarea [(ngModel)]='startsWorking'></textarea>
      </div>
    </div>
  </div>

  <div class='action-buttons'>
    <button mat-icon-button (click)='onCopyToClipboard()'
            [matTooltip]="'COPY_TO_CLIPBOARD' | translate">
      <mat-icon>content_copy</mat-icon>
    </button>

    <button mat-flat-button (click)='onClose()'>{{'CLOSE' | translate}}</button>
    <button mat-flat-button color='primary' (click)='onSave()'>{{'SAVE' |translate}}</button>
  </div>

</mat-dialog-content>
