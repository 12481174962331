<div fxLayoutAlign="space-between center">
  <h4>{{ 'ASSIGN_LABELS' | translate}}</h4>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <section class="example-section">
    <span class="example-list-section">
      <mat-checkbox class="example-margin" (change)="changeAll()"
                    [indeterminate]="isIndeterminate()"
                    [checked]="selection.selected.length === labels.length"
      >
        {{ 'COVID_LABELS' | translate}}
        <mat-icon inline [matTooltip]="tooltip">info</mat-icon>
      </mat-checkbox>
    </span>
    <span class="example-list-section">
      <ul>
        <li *ngFor="let label of labels">
          <mat-checkbox [checked]="selection.isSelected(label.id)" (change)="selection.toggle(label.id)">
              {{label.title}}
          </mat-checkbox>
        </li>
      </ul>
    </span>
  </section>

  <section>
    <span>
      {{'UPDATE_IN_OTHER_SYSTEMS' | translate}}
      <mat-icon inline [matTooltip]="tooltip2">info</mat-icon>
    </span>
    <ul>
      <li>
        <mat-checkbox [(ngModel)]="updateOrdersInWorkis">{{'UPDATE_WORKIS_ORDERS' | translate}}</mat-checkbox>
      </li>

      <li>
        <mat-checkbox [(ngModel)]="updateOrdersInLivas">{{'UPDATE_LIVAS_ORDERS' | translate}}</mat-checkbox>
      </li>

      <li>
        <mat-form-field style="margin-top: 12px">
          <mat-label>{{'SHIFT_DATE_FROM' | translate}}</mat-label>
          <input matInput
                     readonly
                     [(ngModel)]="dateFrom"
                     (click)="pickerDateFrom.open()"
                     [matDatepicker]="pickerDateFrom">
          <mat-datepicker #pickerDateFrom></mat-datepicker>
        </mat-form-field>
      </li>
    </ul>


  </section>

<!--  <mat-dialog-content>-->
<!--    <mat-checkbox *ngFor="let label of labels">-->

<!--    </mat-checkbox>-->

<!--  </mat-dialog-content>-->
</mat-dialog-content>
<mat-error *ngIf="errors">{{errors | translate}}</mat-error>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-stroked-button mat-dialog-close> {{'CANCEL' | translate}}</button>
  <button mat-flat-button color="primary" (click)="saveSelections()">{{'SAVE' | translate}}</button>
</mat-dialog-actions>
