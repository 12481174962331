import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsLogDialogComponent } from './actions-log-dialog/actions-log-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import { LogListComponent } from './log-list/log-list.component';
import { LogItemsTableComponent } from './log-items-table/log-items-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ActionCategoriesTreeComponent } from './action-categories-tree/action-categories-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({

  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTreeModule,
    DragDropModule,
    MatSlideToggleModule,
  ],
  declarations: [ActionsLogDialogComponent, LogListComponent, LogItemsTableComponent, ActionCategoriesTreeComponent],
  exports: [LogItemsTableComponent]
})
export class OrderActionsLogModule { }
