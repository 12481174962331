<div fxLayout="column" fxLayoutGap="24px" class="container">
  <div fxLayout="row" fxLayoutGap="16px">
    <div fxFlex="100">
      <mat-card fxLayout="column" class="number-stat vertically-lined">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
          <h2>{{'SPECIFICATION.THIS_WEEK' | translate}}</h2>
          <app-project-manager-chip-selector *ngIf="projectManagers && currentUser"
                                             [pms]="projectManagers"
                                             [preselectPm]="currentUser"
                                             (selectedManagerChange)="loadWeekStatsData($event)">
          </app-project-manager-chip-selector>
        </div>
        <div fxLayout="row" class="content">
          <div fxFlex="25" fxLayoutAlign="center" class="clickable">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
              <span>{{stats.created}}</span>
              <span>{{'COMMON.NEW'| translate}}</span>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div  fxFlex="25" fxLayoutAlign="center" class="success clickable">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
              <span>{{stats.confirmed}}</span>
              <span>{{'CONFIRM'| translate}}</span>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="25" fxLayoutAlign="center" class="warn clickable">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
              <span>{{stats.sent}}</span>
              <span>{{'SPECIFICATION.AWAITING_APPROVAL' | translate}}</span>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex="25" fxLayoutAlign="center" class="alert clickable">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
              <span>{{stats.cancelled}}</span>
              <span>{{'COMMON.DISCARDED'| translate}}</span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

  </div>
  <div fxLayout="row">
    <mat-card fxLayout="column" class="dashboard">
      <div class="header">
        <div fxFlex="33" fxLayoutAlign="center" class="clickable">
          <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
            <span class="count">{{candidateStats.total}}</span>
            <span>{{'SPECIFICATION.AS_MUCH_AS_SUGGESTED' | translate}}</span>
          </div>
        </div>
        <div fxFlex="33" fxLayoutAlign="center" class="clickable">
          <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
            <span class="count">{{candidateStats.interested}}</span>
            <span>{{'SPECIFICATION.HOW_MUCH_INTRESTED' | translate}}</span>
          </div>
        </div>
        <div fxFlex="34" fxLayoutAlign="center" class="clickable">
          <div fxFlexFill fxLayout="column" fxLayoutAlign="space-between center">
            <span class="count">{{candidateStats.signed}}</span>
            <span>{{'SPECIFICATION.HOW_MANY_SIGNED' | translate}}</span>
          </div>
        </div>
      </div>

      <div class="date-container" fxLayout="row" fxLayoutGap="8px">
        <mat-form-field>
          <input matInput
                 readonly
                 [(ngModel)]="dateFrom"
                 (click)="pickerDateFrom.open()"
                 [matDatepicker]="pickerDateFrom"
                 [max]="dateTo"
                 [placeholder]="'Data nuo'"
                 (dateChange)="emitDateChange()">
          <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerDateFrom disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 readonly
                 [(ngModel)]="dateTo"
                 (click)="pickerDateTo.open()"
                 [matDatepicker]="pickerDateTo"
                 [min]="dateFrom"
                 [placeholder]="'Data iki'"
                 (dateChange)="emitDateChange()">
          <mat-datepicker-toggle matSuffix [for]="pickerDateTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerDateTo disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <app-demand-chart [filterSubject]="filterSubject"></app-demand-chart>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <app-daily-card></app-daily-card>
    </mat-card>
  </div>
</div>
