import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-company-efforts',
  templateUrl: './company-efforts.component.html',
  styleUrls: ['./company-efforts.component.scss']
})
export class CompanyEffortsComponent implements OnInit {
  companyId: string;
  range = new FormGroup({
    start: new FormControl(moment().startOf('month')),
    end: new FormControl(moment()),
    category: new FormControl([])
  });

  get categoryCtrl(): FormControl { return this.range.get('category') as FormControl; }

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.companyId = this.activatedRoute.snapshot.parent.paramMap.get('id');
  }
}
