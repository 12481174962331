import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { f, T } from '@fullcalendar/core/internal-common';
import { AllDayContentArg } from '@fullcalendar/core';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar,

  ) { }

  notify(msg, lvl = 0, duration = 3000, fromComponent?: {component: ComponentType<any>, data?: any}) {
    let s;
    if (fromComponent) {
      s = this.snackBar.openFromComponent(fromComponent.component, {
        duration,
        horizontalPosition: 'left',
        panelClass: ['snackbar-white-bg',  ['ok', 'warn', 'error'][lvl]],
        data: fromComponent.data
      });
    } else {
      s = this.snackBar.open(
        msg,
        '',
        {
          duration,
          horizontalPosition: 'left',
          panelClass: ['snackbar-white-bg',  ['ok', 'warn', 'error'][lvl]]
        }
      );
    }

    s.onAction().subscribe(() => s.dismiss());
  }
}
