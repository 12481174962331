import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ReasonsDialogComponent } from './reasons-dialog/reasons-dialog.component';
import { MetricValueCommentType } from '../../../generated/graphql';

export interface ReasonChipsData extends MetricValueCommentType {
  multiplier: number;
  reasonGroup: MetricValueCommentType[];
}

@Component({
  selector: 'app-reason-with-comment',
  templateUrl: './reason-with-comment.component.html',
  styleUrls: ['./reason-with-comment.component.scss']
})
export class ReasonWithCommentComponent {
  @Input() reason: ReasonChipsData;
  @Input() managerName: string;
  @Input() metricValueCandidate: { firstName: string, lastName: string, livasId: number, employeeLivasId: number };
  @Input() metricValueClient: { name: string, id: string, livasId: number };
  @Input() metricGroup: string;

  constructor(
    private dialog: MatDialog
  ) { }

  getFullComment(set: MetricValueCommentType[]): string {
    return set.map(reason => reason.comment).join(' | ');
  }

  openReasonDialog() {
    this.dialog.open(ReasonsDialogComponent, {
      data: {
        reasonSet: this.reason.reasonGroup,
        managerName: this.managerName,
        metricGroup: this.metricGroup,
        metricValueCandidate: (this.metricValueCandidate.firstName || this.metricValueCandidate.lastName) ? this.metricValueCandidate : null,
        metricValueClient: this.metricValueClient.name ? this.metricValueClient : null
      },
      autoFocus: false
    });
  }
}
