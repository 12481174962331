<div class="mat-elevation-z0">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef  >
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row"  >
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? onTagSelectionChange(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SPECIFICATION.NAME' | translate}}</th>
      <td mat-cell *matCellDef="let row" >{{row.title}}</td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SPECIFICATION.COMMENT' | translate}}</th>
      <td mat-cell *matCellDef="let row" >
        <mat-form-field *ngIf="selection.isSelected(row)">
          <mat-label>{{row.hint}}</mat-label>
          <input matInput (ngModelChange)="commentInputChanges($event, row)" [(ngModel)]="row.comment" (focus)="onInputFocus()">
        </mat-form-field>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
