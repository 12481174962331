import {Injectable} from '@angular/core';
import {Mutation, Query} from 'apollo-angular';
import gql from 'graphql-tag';
import {Company, Region, UserProfile, UserProfileResponse, CompaniesPaginatedResponse} from '../../graphql/graphql';
import { MetaData } from './order-data';




export interface OrderEditMetadataResponse {
  userprofiles: UserProfile[];
  regions: Region[];
  companies: CompaniesPaginatedResponse;


}

@Injectable({
  providedIn: 'root',
})
export class OrderStatusChangeGQL extends Mutation<{changeOrderStatus: {ok: boolean}}> {
  document = gql`
    mutation changeStatus($id: ID!, $transition: String!) {
      changeOrderStatus(id: $id, transition: $transition) {
        ok
      }
    }`;
}




@Injectable({
  providedIn: 'root',
})
export class OrderEditMetaGQL extends Query<OrderEditMetadataResponse> {
  document = gql`
    query OrderEditMetadata($companyId: ID!) {
      userprofiles {
        id
        user {
          id
          firstName
          lastName
          email
        }
        region {
          id
        }
      }
      regions {
        ...RegionsListFields
      }
      companies(id: $companyId) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        totalCount
        edges {
          node {
            id
          status
          name
          description
          contractSet {
            id
            validFromDate
            validTillDate
            contractor {
              id
              logoUrl
            }
          }
          contactSet {
            id
            firstName
            lastName
          }
          specificationSet {
            id
            positionTitle
          }
          }
      }
      }
    }
    ${MetaData.fragments.regionsListFields}
  `;
}
