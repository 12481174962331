import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SidenavService {
    private sidenav: MatSidenav;
    public openedSubject = new Subject<boolean>();

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        return this.sidenav.open();
    }


    public close() {
        return this.openedSubject.next(false);
    }

    public toggle(): void {
      this.sidenav.toggle();
   }


}
