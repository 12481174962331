import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import moment from 'moment';

import { environment } from '../../environments/environment';

export interface EmployeeAllAbsencesByRangeResp {
  [key: string]: {
    absences: {
      date: string;
      absence_type: string;
      absence_unpaid: number;
    }[]
  };
}

@Injectable({
  providedIn: 'root'
})
export class AbsencesService {
  headers: {};

  private locale: string;
  private apiBase: string;
  private apiV3Base: string;
  public country: string;
  private apiBases: { lv: string, ee: string, lt: string, gigroup: string };
  private apiV3Bases: { lv: string, ee: string, lt: string, gigroup: string };

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.apiBases = {
      lv: environment.livasAPIBaseLVV3,
      ee: environment.livasAPIBaseEEV3,
      lt: environment.livasAPIBaseLTV3,
      gigroup: environment.livasAPIBaseGiGroupV3
    };
    this.apiV3Bases = {
      lv: environment.livasAPIBaseLVV3,
      ee: environment.livasAPIBaseEEV3,
      lt: environment.livasAPIBaseLTV3,
      gigroup: environment.livasAPIBaseGiGroupV3
    };

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const host = window.location.host;
      let country = '';

      if (params.country) {
        country = params.country;
      } else if (host.includes('.lt')) {
        country = 'lt';
      } else if (host.includes('.lv')) {
        country = 'lv';
      } else if (host.includes('.ee')) {
        country = 'ee';
      } else {
        country = 'lt';
      }

      this.headers = {
        Authorization: `LivasSession ${ params.sessionId ? params.sessionId : (cookieService.get('PHPSESSIONID') || cookieService.get('PHPSESSID') || '') }`,
      };

      this.locale = params.locale || '';
      this.apiBase = this.apiBases[country];
      this.apiV3Base = this.apiV3Bases[country];
      this.country = params.country || 'lt';
    });
  }

  getDatesRange(startDate: any, endDate: any) {
    const range = [];
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, 'days');

    for (let i = 0; i <= diff; i++) {
      range[moment(startDate).add(i, 'days').format('YYYY-MM-DD')] = '';
    }

    return range;
  }

  getDate(date: any, format = 'YYYY-MM-DD') {
    return moment(date).format(format);
  }

  getEmployers() {
    return this.http.get<object>(`${this.apiBase}/employers.php`, { headers: this.headers }).pipe(
      map((response: any) => {
        return (response || []).map(employer => ({
          label: employer.name,
          value: employer.system_id
        }));
      })
    ).toPromise();
  }

  getMissingData({ from, to, employerId, regionId }) {
    return this.http.post<any>(`${this.apiBase}/absences.php?action=get_not_fixed_sodra&absence_date_from=${from}&absence_date_to=${to}&employer_id=${employerId}&region_id=${regionId}&page=1&page_size=1000`, {},
      { headers: this.headers }).toPromise();
  }

  parseAbsencesFile(userfile: any) {
    return this.http.post<any>(`${this.apiBase}/absences.php?action=parse_file`, { userfile },
      { headers: this.headers }).toPromise();
  }

  getRegions() {
    return this.http.get<object>(`${this.apiBase}/region.php`,
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getAbsences(locale = this.locale) {
    return this.http.get<object>(`${this.apiBase}/absences.php?action=types&lang=${locale}`,
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  isAbsencesSimilar(absence: any, absences: any) {
    return !absence && !absences.length ||
      absence && !absence.id && absences.find(abs => !abs.id && abs.absence_type === absence.absence_type) ||
      absence && absence.id && absences.find(abs => abs.id && abs.is_fixed === false && abs.absence_type === absence.absence_type);
  }

  getAbsencesRange(row: any, absence: any, date: any, employerId: any) {
    let from = moment(date);
    let to = moment(date);

    let prev = moment(from).subtract(1, 'days').format('YYYY-MM-DD');

    while (prev) {

      if (row[prev]) {
        const absences = row[prev]?.absences?.[employerId] || [];

        if (this.isAbsencesSimilar(absence, absences)) {
          from = moment(prev);
          prev = moment(prev).subtract(1, 'days').format('YYYY-MM-DD');
        } else {
          prev = null;
        }
      } else {
        prev = null;
      }
    }

    let next = moment(to).add(1, 'days').format('YYYY-MM-DD');

    while (next) {

      if (row[next]) {
        const absences = row[next]?.absences?.[employerId] || [];

        if (this.isAbsencesSimilar(absence, absences)) {
          to = moment(next);
          next = moment(next).add(1, 'days').format('YYYY-MM-DD');
        } else {
          next = null;
        }
      } else {
        next = null;
      }
    }

    return {
      from,
      to,
    };
  }

  mergeData(existing: any, importing: any) {
    const warnings = {};

    if (importing.absences && existing.absences) {
      for (const employerId in importing.absences) {
        const absence = existing.absences[employerId]?.find((a: any) => !!a.absence_type);

        if (absence) {
          warnings[employerId] = {
            existing: absence.absence_type,
            importing: 'L',
          };
        }
      }
    }

      return {
        absences: {
          ...existing.absences,
          ...importing.absences,
        },
        permissions: {
          ...existing.permissions,
          ...importing.permissions,
        },
        shifts: {
          ...existing.shifts,
          ...importing.shifts,
        },
        warnings,
        existing,
      };
  }

  getMergedData(existing: any, importing: any) {
    if (!importing) {
      return existing;
    }

    return Object.keys(existing).reduce((res, date) => {
      if (importing?.[date]?.absences || existing?.[date]?.absences) {
        res[date] = importing[date] ? this.mergeData(existing[date], importing[date]) : existing[date];
      }
      return res;
    }, {});
  }

  sendAbsencesData(employerId: number, absences: Array<any>): Observable<any> {
    return this.http.post<any>(`${this.apiBase}/absences.php?employer_id=${employerId}`, { absences }, { headers: this.headers }).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  getEmployee(employeeId: number): Observable<{ name: string, surname: string, email: string }> {
    return this.http.get<object>(`${this.apiBase}/employee.php?id=${employeeId}`,
      { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getRecords(employeesId: any, from: string, to: string): Observable<{ results: any }> {
    return this.http.post<object>(`${this.apiBase}/absences.php?absence_date_from=${from}&absence_date_to=${to}&action=get_absences&page=1&page_size=1000`,
    { employee_id: employeesId }, { headers: this.headers }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  sendAbsenceForm(employerId: number, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiBase}/absences.php?employer_id=${employerId}`, data, { headers: this.headers }).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteAbsence(employeeId: number, data: any): Observable<any> {
    return this.http.request<any>('delete', `${this.apiBase}/absences.php?employee_id=${employeeId}`,
      { headers: this.headers, body: data }).pipe(
        tap((response: any) => {
          return response;
        })
    );
  }

  getPeriod(rows: any, period: any) {

    for (const id in rows) {
      if (id) {
        const arr = Object.keys(rows[id]?.calendar || []).sort();
        const from = arr[0];
        const to = arr.pop();

        if (period.from > from) {
          period.from = from;
        }
        if (period.to < to) {
          period.to = to;
        }
      }
    }

    return period;
  }

  getRange(startDate: any, endDate: any) {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, 'days');
    const range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
    }

    return range;
  }

  getDefaultShifts(shifts = []) {
    const result = shifts.reduce((res, shift) => {
      if (shift.permissions && shift.permissions.readonly) {
        return res;
      }

      res[shift.id] = !shift.arrival;

      return res;
    }, {});

    return result;
  }

  getErrorMessage(res: any) {
    return `${this.translate.instant('ERRORS ACCURED')}: ${res.data_errors ? res.data_errors.map((e: any) => e.message).join(' ') : res.message ? res.message : ''}`;
  }

  logSodraFile(userfile: any) {
    return this.http.post<any>(`${this.apiBase}/absences.php?action=save_sodra_file`, { userfile }, {
          headers: this.headers,
      }).toPromise();
  }

  getSodraData(from: any, to: any, employerId: any, employeeId: any): Observable<any> {
    return this.http.post<any>(`${this.apiBase}/absences.php?action=get_sodra_data&absence_date_from=${from}&absence_date_to=${to}&employer_id=${employerId}`,
      { employee_id: employeeId },
      { headers: this.headers, }).pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getUnusedVacations(employeeIds: number[], calculationDate?: string): Observable<{ unsedVacations: number }[]> {
    const data = { employeeIds };
    if (calculationDate) {
      data['calculationDate'] = calculationDate;
    }
    const encodedData = btoa(JSON.stringify(data));
    return this.http.get<object>(`${this.apiBase}/unusedvacations.php?base64data=${encodedData}`,
      { headers: this.headers }).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  getEmployeeAllAbsencesByRange(dateFrom: string, dateTo: string, employeeIds: number[], companyCountry): Observable<EmployeeAllAbsencesByRangeResp> {
    return this.http.get<EmployeeAllAbsencesByRangeResp>(
      `${ this.configureUrlWithClientCountry(companyCountry) }/employeeCenter.php?action=absences&base64data=${ btoa(unescape(encodeURIComponent(JSON.stringify({ dateFrom, dateTo, employeeIds })))) }`,
      { headers: this.headers }
    );
  }

  configureUrlWithClientCountry(companyCountry: string): string {
    const url = this.apiV3Base.split('/jobs/');
    url[0] = url[0].slice(0, url[0].length - 2) + companyCountry;
    return url.join('/jobs/');
  }
}
