import {Component, Input, OnInit} from '@angular/core';
import {DocumentConfirmationMethod, GetOrderPeriodsGQL, OrderDocumentCreateGQL} from '../../../generated/graphql';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {RelayIdService} from '../../shared/relay-id.service';
import {FiledDownloaderService} from '../../shared/filed-downloader.service';
import * as moment from 'moment';


@Component({
  selector: 'app-order-document-create-btn',
  templateUrl: './order-document-create-btn.component.html',
  styleUrls: ['./order-document-create-btn.component.scss']
})
export class OrderDocumentCreateBtnComponent implements OnInit {
  @Input() disabled: any;

  @Input() set orderDate(value: string) {
    this.documentDate = value;
  }

  @Input() set orderId(value: string) { // order ID is pk, not globalID here
    if (value) {
      this.loadOrderPeriods(this.relayService.convertRelayTypes(value, 'OrderMType'));
      this.orderId$ = value;
    }
  };

  get orderId(): string {
    return this.orderId$;
  }

  documentDate: string;
  loading = true;
  selectedOrderPeriods: string[] = [];
  orderPeriods: any = [];
  private orderId$: string;
  confirmationMethods = DocumentConfirmationMethod;
  get disabledMenuOption(): boolean { return (this.selectedOrderPeriods.length===0) || !this.documentDate; }


  constructor(
    private orderDocumentCreateGQL: OrderDocumentCreateGQL,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private getOrderPeriodsGQL: GetOrderPeriodsGQL,
    private relayService: RelayIdService,
    private fileDownloader: FiledDownloaderService
  ) { }

  ngOnInit(): void {
  }

  createDocument(confirmationMethod: DocumentConfirmationMethod): void {
    if (!this.orderId$) {
      this.snackBar.open(this.translate.instant('ERROR_CREATING_DOCUMENT'), this.translate.instant('CLOSE'), { duration: 3000 });
      return;
    }

    this.orderDocumentCreateGQL.mutate({
      orderId: this.orderId,
      documentDate: moment(this.documentDate).format('YYYY-MM-DD'),
      confirmationMethod, // Replace with the appropriate confirmation method value
      orderPeriodIds: this.selectedOrderPeriods.map(id => this.relayService.decodeSingleIdForType(id, 'OrderPeriodType'))
    }, {
      refetchQueries: ['getPaginatedOrderForTable']}
    ).subscribe(result => {
      if (result.errors) {
        this.snackBar.open(
          this.translate.instant('ERROR_CREATING_DOCUMENT'),
          this.translate.instant('CLOSE'), { duration: 3000 }
        );
      } else {
        this.snackBar.open(this.translate.instant('DOCUMENT_CREATED_SUCCESSFULLY'), this.translate.instant('CLOSE'), { duration: 3000 });
        if (confirmationMethod === DocumentConfirmationMethod.OffSystem) {
          this.fileDownloader.downloadPdfFile(
            result.data.sendOrderDocumentToClient.document.generatedDocument,
            result.data.sendOrderDocumentToClient.document.order?.specification?.positionTitle
          );
        }
      }
    }, error => {
      this.snackBar.open(
        this.translate.instant('ERROR_CREATING_DOCUMENT'),
        this.translate.instant('CLOSE'), { duration: 3000 }
      );
    });
  }

  loadOrderPeriods(orderId): void {
    this.getOrderPeriodsGQL.fetch({ orderId }).subscribe(result => {
      this.orderPeriods = result.data.orderModel.orderperiodSet;
      this.loading = false;
      this.selectedOrderPeriods = this.orderPeriods.map((orderPeriod: any) => orderPeriod.id);
    });
  }

}
