<div class="text editable-cell" (click)="editing = true" *ngIf="!editing; else editableDuration">
  {{value | duration}}
</div>

<ng-template #editableDuration>
  <div>
    <mat-form-field [style.width.px]="100" appearance="outline" class="full-width small-input">
      <input matInput type="number" [(ngModel)]="value"
             (ngModelChange)="updateDuration(value)"
             [ngModelOptions]="{updateOn: 'blur'}"
             fxFlex>
      <span matSuffix>min.</span>
    </mat-form-field>
  </div>
</ng-template>
