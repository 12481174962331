import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { EdlaAlertsResponse } from 'src/app/graphql/graphql';

@Component({
  selector: 'app-edla-alerts',
  templateUrl: './edla-alerts.component.html',
  styleUrls: ['./edla-alerts.component.scss']
})
export class EdlaAlertsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {extraValues: EdlaAlertsResponse[], title: string},
  ) { }

  treeControl = new NestedTreeControl<any>(node => node.employees);
  dataSource = new MatTreeNestedDataSource<EdlaAlertsResponse>();
  title: string;

  ngOnInit() {
    this.dataSource.data = this.data.extraValues;
    this.title = this.data.title;
  }

  hasChild = (_: number, node: EdlaAlertsResponse) => !!node.employees && node.employees.length > 0;

  onNodeClick(node) {
    if (!this.treeControl.isExpanded(node)) {
      this.treeControl.collapseAll();
      this.treeControl.expand(node);
    } else {
      this.treeControl.collapse(node);
    }
  }

}
