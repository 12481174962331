import { Component, AfterViewInit, ViewChild, ChangeDetectorRef  } from '@angular/core';

import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';


import { CalendarOptions } from '@fullcalendar/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CalendarService } from './calendar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit {
  isLoading = true;

  date = moment().format('YYYY-MM-DD');

  isLocked = false;
  dayType = '';
  types = [];

  calendarAPI: any;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: '',
      center: 'title',
      end: ''
    },
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    fixedWeekCount: false,
    unselectAuto: false,
    contentHeight: 520,
    locale: 'lt',
    firstDay: 1,
    showNonCurrentDates: false,
    select: ({ start, end}) => {
      this.calendarAPI.addEvent({
        start,
        end,
        allDay: true,
        display: 'background',
        temporary: true,
      });
    },
    selectOverlap: (event) => {
      if (event.extendedProps.temporary) {
        event.remove();
        return false;
      }

      return true;
    },
    datesSet: (info: any) => {
      this.date = moment(info.view.getCurrentData().currentDate).format('YYYY-MM-DD');

      this.getDates();
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private calendarService: CalendarService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.locale) {
        const locale = params.locale === 'ee' ? 'et' : params.locale;
        this.calendarOptions.locale = locale;
      }
    });

    this.updateDate = this.updateDate.bind(this);
    this.getDates = this.getDates.bind(this);

    this.calendarService.getDayTypes().subscribe(res => {
      this.types = res;
    });
  }

  getDates(date = this.date) {
    this.isLoading = true;

    const month = moment(date).format('YYYY-MM');

    this.router.navigate([], {
      queryParams: {
        month,
      },
      queryParamsHandling: 'merge'
    });

    this.calendarService.getMonthLock(month).subscribe(res => {
      this.isLocked = res;
      this.calendarAPI.setOption('selectable', !res);
    });

    this.calendarService.getCalendarDays(month).subscribe(res => {
      const events = this.calendarAPI.getEvents();

      this.calendarAPI.unselect();

      events.forEach(event => {
        event.remove();
      });

      for (const day in res) {
        if (res[day]) {
          this.calendarAPI.addEvent({
            start: day,
            allDay: true,
            display: 'background',
            color: this.calendarService.getTypeColor(res[day]),
          });
        }
      }

      this.setDayType('');
      this.isLoading = false;
    });
  }

  updateDate(type: string, date?: any): void {
    const events = this.calendarAPI.getEvents();

    events.forEach(event => {
      event.remove();
    });

    switch (type) {
    case 'next':
      return this.calendarAPI.next();
    case 'prev':
      return this.calendarAPI.prev();
    case 'date':
      return this.calendarAPI.gotoDate(date.format('YYYY-MM-DD'));
    }
  }

  toggleIsLocked(): void {
    const locked = this.isLocked ? 0 : 1;
    const events = this.calendarAPI.getEvents();

    this.isLoading = true;

    this.calendarAPI.unselect();

    events.filter(event => event.extendedProps.temporary).forEach(event => {
      event.remove();
    });

    this.calendarService.setMonthLock(moment(this.date).format('YYYY-MM'), locked).subscribe(res => {
      const message = locked ? 'MONTH_LOCKED_SUCCESSFULLY' : 'MONTH_UNLOCKED_SUCCESSFULLY';

      if (res?.status === 'ok') {
        this.isLocked = !!locked;

        this.calendarAPI.setOption('selectable', !this.isLocked);

        this.snackBar.open(this.translate.instant(message), this.translate.instant('CLOSE'), {
          duration: 10000,
        });
      }

      this.isLoading = false;
    },
    err => {
      const error = err?.error?.message || '';

      if (error) {
        this.snackBar.open(error, this.translate.instant('CLOSE'), {
          duration: 10000,
        });
      }

      this.isLoading = false;
    });
  }

  setDayType(type): void {
    this.dayType = type;
  }

  updateCalendarDays(): void {
    const events = this.calendarAPI.getEvents();
    const periods = events
      .filter(event => event.extendedProps.temporary)
      .map(event => ({ startStr: event.startStr, endStr: event.endStr }));

    if (!periods.length) {
      return;
    }

    this.isLoading = true;

    this.calendarService.updateCalendarDays(periods, this.dayType).subscribe(res => {
      if (res?.status === 'ok') {
        this.snackBar.open(this.translate.instant('DAYS_UPDATED_SUCCESSFULLY'), this.translate.instant('CLOSE'), {
          duration: 10000,
        });

        this.getDates();
      }
    },
    err => {
      this.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    this.calendarAPI = this.calendarComponent.getApi();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.month) {
        this.calendarAPI.gotoDate(moment(params.month).format('YYYY-MM-DD'));
      }
    });

    this.cdr.detectChanges();
  }
}
