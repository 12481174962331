import { Injectable, EventEmitter } from '@angular/core';
import Pusher from 'pusher-js';

import { Constants } from '../constants';

export interface PusherTaskUpdateEvent { task_id: string; }

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private pusher: Pusher;
  private dashboardTaskUpdateChannel: any;
  public dashboardTaskUpdateEmitter: EventEmitter<PusherTaskUpdateEvent> = new EventEmitter();

  constructor() {
    this.connectPusher();
    this.connectDashboardTaskUpdate();
  }

  connectPusher() {
    this.pusher = new Pusher(Constants.pusherAppKey, { cluster: Constants.pusherCluster });
  }

  connectDashboardTaskUpdate() {
    this.dashboardTaskUpdateChannel = this.pusher.subscribe('daily_dashboard');
    this.dashboardTaskUpdateChannel.bind('daily_dashboard_event', (event: PusherTaskUpdateEvent) => {
      this.dashboardTaskUpdateEmitter.emit(event);
    });
  }
}
