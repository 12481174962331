import { Component, Input, OnInit } from '@angular/core';
import {
  CandidateEvaluationsDialogComponent
} from '../candidate-evaluations-dialog/candidate-evaluations-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-candidate-evaluations-btn',
  templateUrl: './candidate-evaluations-btn.component.html',
  styleUrls: ['./candidate-evaluations-btn.component.scss']
})
export class CandidateEvaluationsBtnComponent implements OnInit {
  @Input() personId: number;
  @Input() offerPropositionId: string;
  @Input() order: string;

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  openCandidateEvaluation() {
    console.log(this.personId, this.offerPropositionId, this.order);
    this.dialog.open(CandidateEvaluationsDialogComponent, {
      data: {
        candidate: { livasId: this.personId }, offerProposition: {
          responseId: this.offerPropositionId,
          order: this.order
        }
      }
    });
  }

}
