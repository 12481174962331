import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SpecificationsListComponent} from './specifications-list/specifications-list.component';
import { SpecificationCreateComponent } from './specification-create/specification-create.component';

const routes: Routes = [
  {path: 'specifications', children: [
    {path: 'list', component: SpecificationsListComponent},
    {path: 'create', component: SpecificationCreateComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpecificationsRoutingModule { }
