<form *ngIf="orderForm" [formGroup]="orderForm" class="mat-typography" fxLayout="column" fxLayoutAlign="start center" [fxFlex]="100">
  <mat-card *ngIf="editOrderId" style="max-height: 100px">
    <mat-card-title fxLayout="space-between">
      <h3 style="margin: 0 0 0 12px;">
        {{ specificationCtrl?.get('positionTitle')?.value }}
      </h3>

      <div *ngIf='editOrderId' class='order-error'>
        <mat-icon svgIcon='report' [class.error]='orderErrors.length' (click)='onOrderErrorClick()'></mat-icon>
      </div>

      <div style='display: inline-block; margin-left: auto'>
        <app-documents-history [orderId]='relayOrderId' [positionTitle]="specificationCtrl?.get('positionTitle')?.value"
        ></app-documents-history>

        <button
          mat-icon-button
          [matTooltip]="'SELECTION_ACTIONS_LOG' | translate"
          (click)='openSelectionLogDialog()'
        >
          <mat-icon>list</mat-icon>
        </button>

        <button
          mat-icon-button
          [matTooltip]="'SHOW_INFO_FOR_SUMMARY' | translate"
          (click)='showInfoForCopy=!showInfoForCopy'
        >
          <mat-icon>info</mat-icon>
        </button>

        <!--        <app-order-document-create-btn *ngIf="order.status === statuses.New"-->
        <!--                                       [disabled]="!order.clientContact || !order.contract"-->
        <!--                                       [orderId]="order.id" [orderDate]="order.orderDate">-->
        <!--        </app-order-document-create-btn>-->

        <button
          *ngIf="editOrderId"
          mat-icon-button
          matTooltip="{{ 'DOWNLOAD_PDF' | translate }}"
          (click)="downloadOrder(editOrderId, specificationCtrl?.get('positionTitle')?.value)"
        >
          <mat-icon>download</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </mat-card>

  <mat-card *ngIf='editOrderId' (click)='statsDetail = !statsDetail' [ngClass]='{expanded: statsDetail}'>
    <mat-card-title>
      <h4 style='margin-left: 12px;'>{{'QUICK_STATS' | translate}}</h4>
    </mat-card-title>

    <app-order-stats [orderId]='editOrderId'></app-order-stats>

    <mat-tab-group *ngIf='statsDetail' (click)='$event.stopImmediatePropagation()'>
      <mat-tab [label]="'Stats OLD'">
        <app-old-order-offer-stats-table (click)='$event.stopImmediatePropagation()'
                                     [orderId]='editOrderId'
                                     [companyId]='orderCreateService.selectedCompany.value.id'>
        </app-old-order-offer-stats-table>
      </mat-tab>

      <mat-tab [label]="'Stats'">
        <app-order-offer-stats-table (click)='$event.stopImmediatePropagation()'
                                     [orderId]='editOrderId'
                                     [companyId]='orderCreateService.selectedCompany.value.id'>
        </app-order-offer-stats-table>
      </mat-tab>

      <mat-tab [label]="'Candidates'">
        <app-candidate-evaluations [orderId]='relayOrderId'></app-candidate-evaluations>
      </mat-tab>
    </mat-tab-group>

    <div fxLayoutAlign='center center'>
      <button *ngIf='!statsDetail' class='show-more-btn'>{{'SHOW_MORE_STATS' | translate }} </button>
    </div>
  </mat-card>

  <mat-card *ngIf="showInfoForCopy" >
    <app-order-summary-table [form]="orderForm"></app-order-summary-table>
  </mat-card>

  <div fxLayout="row" fxLayout.lt-md="column-reverse">
    <mat-card [fxFlex.gt-sm]="'932px'">
      <mat-card-title fxLayout="space-between">
        <h4 style="margin-right: auto; margin-left: 12px">{{'ORDER.ORDER' | translate}}</h4>
      </mat-card-title>

      <mat-card-content>
        <div fxLayout="row"  fxLayoutAlign="start center">
          <mat-form-field appearance="outline">
            <mat-label>{{'ORDER.ORDER_TYPE' | translate}}</mat-label>
            <mat-select matNativeControl formControlName="orderType" name="orderType">
              <mat-option [value]="orderTypes.staffing"> {{'ORDER.ORDER_TYPE_OPTIONS.STAFFING' | translate}}</mat-option>
              <mat-option [value]="orderTypes.selection"> {{'ORDER.ORDER_TYPE_OPTIONS.SELECTION' | translate}}</mat-option>
              <mat-option [value]="orderTypes.partialSelection"> {{'ORDER.ORDER_TYPE_OPTIONS.PARTIAL_SELECTION' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'ORDER.SELECTION_STATUS' | translate}}</mat-label>

            <mat-select matNativeControl formControlName="trafficGeneration" name="trafficGeneration" >
              <mat-option [value]="trafficGenerationOptions.inactive">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.DO_NOT_GENERATE_TRAFFIC' | translate}}</mat-option>
              <mat-option [value]="trafficGenerationOptions.activeNoMarketing">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_WITHOUT_MARKETING' | translate}}</mat-option>
              <mat-option [value]="trafficGenerationOptions.activeWithMarketing">{{'ORDER.TRAFFIC_GENERATION_OPTIONS.OFFER_AND_RUN_MARKETING_CAMPAIGNS' | translate}}</mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'COUNTRY' | translate}}</mat-label>

            <mat-select matNativeControl formControlName="country" [disabled]="clientAndContactCtrl.get('company')?.value">
              <mat-option *ngFor="let country of countries" [value]="country.id" >
                {{ ('COUNTRIES.' + country.isoCode.toUpperCase()) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{'LANGUAGE.LABEL' | translate}}</mat-label>

            <mat-select matNativeControl formControlName="language" >
              <mat-option *ngFor="let l of availableLanguages" [value]="l?.value">
                {{('LANGUAGE' + '.' + l.key) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="selectedCountry?.isoCode === 'lv'" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="47">
            <mat-label>{{'ORDER.ORDER_NUMBER' | translate}}</mat-label>
            <input formControlName="orderNumber" matInput>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="47">
            <mat-label>{{'ORDER.SUBTYPE' | translate}}</mat-label>

            <mat-select matNativeControl formControlName="subtype" [disabled]="editOrderId">
              <mat-option [value]="OrderSubtype.New">{{'NEW' | translate}}</mat-option>
              <mat-option [value]="OrderSubtype.Amendment">{{'AMENDMENT' | translate}}</mat-option>
              <mat-option [value]="OrderSubtype.Prolongation">{{'PROLONGATION' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="selectedCountry?.isoCode === 'ee'" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="47">
            <mat-label>{{'ORDER.ORDER_NUMBER' | translate}}</mat-label>
            <input formControlName="orderNumber" matInput>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="47">
            <mat-label>{{'ORDER.SUBTYPE' | translate}}</mat-label>

            <mat-select matNativeControl formControlName="subtype" [disabled]="editOrderId">
              <mat-option [value]="OrderSubtype.New">{{'NEW' | translate}}</mat-option>
              <mat-option [value]="OrderSubtype.TryNHire">{{'TRY_AND_HIRE' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!--- Order creation content below----->

  <mat-card>
    <mat-horizontal-stepper [linear]="isLinear" (selectionChange)="onSelectedChange()">
      <mat-step [stepControl]="clientAndContactCtrl">
        <ng-template matStepLabel>
          <div class="stepper-label" [style.color]="invalidControls.step1 ? 'red' : 'unset'">
            {{'ORDER.CLIENT_AND_CONTACTS' | translate}}
          </div>
        </ng-template>

        <div fxLayout="column">
          <app-clients-and-contacts
            [country]="selectedCountry"
            [form]="clientAndContactCtrl"
            [orderType]="orderTypeCtrl?.value"
            [order]="selectedOrder"
            (newOrder)="onNewOrder($event)">
          </app-clients-and-contacts>

          <app-extra-selection-fields
            style="margin-top: 24px"
            *ngIf='getShowExtraSelectionFields()'
            [form]="clientAndContactCtrl"
            [isPartialSelection]="isPartialSelection">
          </app-extra-selection-fields>
        </div>

        <div *ngIf="invalidControls.step1" [style.color]="'red'" [style.text-align]="'right'" [style.margin-top]="'12px'">
          <span>{{'COMMON.REQUIRED_FIELDS' | translate}}:  <span [style.font-weight]="500">{{invalidControls.step1}}</span></span>
        </div>

        <div fxLayoutAlign="end" fxLayoutGap="16px" class="action-btn">
          <button mat-flat-button  (click)="cleanForm()" [disabled]="editOrderId">
            {{'COMMON.RESET' | translate}}
          </button>

          <button mat-raised-button color="primary" matStepperNext [disabled]="!clientAndContactCtrl.valid">
            {{'COMMON.NEXT' | translate}}
          </button>

          <button
            *ngIf="editOrderId"
            [disabled]="!!invalidControls.step1 || !!invalidControls.step2 || !!invalidControls.step3"
            (click)="submitForm()"
            fxLayoutAlign="end"
            mat-raised-button
            color="primary"
          >{{'COMMON.SUBMIT' | translate}}</button>
        </div>
      </mat-step>

      <mat-step fxLayout="column" [stepControl]="clientNeedCtrl">
        <ng-template matStepLabel>
          <div class="stepper-label" [style.color]="invalidControls.step2 ? 'red' : 'unset'">
            {{'ORDER.CLIENT_NEED' | translate}}
          </div>
        </ng-template>

        <div fxLayout="column">
          <app-client-need
            [form]="clientNeedCtrl"
            [orderType]="orderTypeCtrl?.value"
            [countryISO]="selectedCountry?.isoCode"
            [editOrderId]="editOrderId"
            [order]="selectedOrder">
          </app-client-need>
        </div>

        <div *ngIf="invalidControls.step2" [style.color]="'red'" [style.text-align]="'right'" [style.margin-top]="'12px'">
          <span>{{'COMMON.REQUIRED_FIELDS' | translate}}:  <span [style.font-weight]="500">{{invalidControls.step2}}</span></span>
        </div>

        <div fxLayoutAlign="end" fxLayoutGap="16px">
          <button
            matStepperNext
            [disabled]="!clientNeedCtrl.valid"
            mat-raised-button
            color="primary"
            class="action-btn"
          >{{'COMMON.NEXT' | translate}}</button>
          <button
            *ngIf="editOrderId"
            (click)="submitForm()"
            fxLayoutAlign="end"
            mat-raised-button
            color="primary"
            class="action-btn"
            [disabled]="!!invalidControls.step1 || !!invalidControls.step2 || !!invalidControls.step3"
          >{{'COMMON.SUBMIT' | translate}}</button>
        </div>
      </mat-step >


      <mat-step [stepControl]="specificationCtrl">
        <ng-template matStepLabel>
          <div class="stepper-label" [style.color]="invalidControls.step3 ? 'red' : 'unset'">
            {{'SPECIFICATION.SPECIFICATION' | translate}}
          </div>
        </ng-template>

        <div fxLayout="column">
          <app-order-specification
            [form]="specificationCtrl"
            [clientAndContactForm]="clientAndContactCtrl"
            [orderType]="orderTypeCtrl?.value"
            [order]="selectedOrder"
            [selectedCountry]="selectedCountry"
          ></app-order-specification>
        </div>

        <div *ngIf="invalidControls.step3" [style.color]="'red'" [style.text-align]="'right'" [style.margin-top]="'12px'">
          <span>{{'COMMON.REQUIRED_FIELDS' | translate}}:  <span [style.font-weight]="500">{{invalidControls.step3}}</span></span>
        </div>

        <div fxLayoutAlign="end">
          <button
            (click)="submitForm()"
            fxLayoutAlign="end"
            mat-raised-button
            color="primary"
            class="action-btn"
            [disabled]="!!invalidControls.step1 || !!invalidControls.step2 || !!invalidControls.step3"
          >{{'COMMON.SUBMIT' | translate}}</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card>
</form>
