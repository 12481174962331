<div fxLayout="column">
  <div>
    <mat-label class="reason-label"> {{'SELECTED_REASONS'| translate}}: </mat-label>
    <div *ngIf="!selection.selected.length" class="no-reason-placeholder">{{'PROGRESS.NO_REASON_SELECTED' | translate}}</div>
    <mat-chip-list #chipList >
      <mat-chip *ngFor="let n of selection.selected" [removable]="true" (removed)="selection.deselect(n)" >
        {{n.title }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <div fxLayout="row">
    <mat-form-field>
      <mat-label> {{'COMMON.SEARCH' | translate}}</mat-label>
      <input matInput [(ngModel)]="searchString" (ngModelChange)="filterTreeNodes()">
    </mat-form-field>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <mat-checkbox [checked]="isSelected(node)" (change)="select(node)">{{node.title}}</mat-checkbox>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.title">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.title}}
          </div>
          <!-- There is inline padding applied to this div using styles.
              This padding value depends on the mat-icon-button width.  -->
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <app-comment-dialog *ngIf='getCommentsByID' [getCommentsByID]='getCommentsByID'></app-comment-dialog>
</div>
